import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import SearchInput from "components/inputs/SearchInput";
import PopularProvidersListComponent from "components/link_account/PopularProvidersListComponent";
import {
  fetchPopularProviders,
  searchProviders,
  popularProvidersSelector,
  store,
  accountLinkingService,
  isPlaidSupportedForCountry,
  isCryptoLinkingService,
  statusConfigSelector,
  currentPortfolioSelector,
  qrBasedProviderIds
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponentExports";
import SearchProvidersComponent from "components/link_account/SearchProvidersComponent";
import PrimaryButton from "components/button/PrimaryButton";
import QrLinkAccountComponent from "./QrLinkAccountComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PageLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
`;

const ProviderContainer = styled.div`
  position: relative;
  height: 35px;
  margin-bottom: ${props => props.marginBottom};
`;

const ProviderName = styled.div`
  height: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ProviderLogo = styled.img`
  width: auto;
  height: 100%;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 46px;
  text-align: left;
  position: absolute;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 19px;
`;

const Tabs = styled.div`
  display: inline-block;
  margin-right: 16px;
  color: ${props => (props.isDisabled ? "rgba(0, 0, 0, 0.50)" : "#000000")};
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

const ProviderSearchBox = styled(SearchInput)`
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: #ffffff;
`;

const PopularProviders = styled(PopularProvidersListComponent)``;

const ProviderSearchResults = styled(SearchProvidersComponent)`
  display: ${props => (props.isVisible === true ? "flex" : "none")};
  position: absolute;
  top: 81px;
  width: 100%;
`;

const InstructionsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  margin-top: 28px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const InstructionStep = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
`;

const InstructionText = styled.div`
  margin-top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
`;

const InstructionButton = styled(PrimaryButton)`
  margin-top: 28px;
`;

const ProviderIssueBanner = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  background: #ffe600;
`;

class ProvidersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
      popularProviders: null,
      searchText: null,
      isSearchPending: false,
      searchResults: null,
      error: null,
      selectedTab: 0,
      selectedProviderDetails: null,
      showConnectInstructions: false,
      hideProviderLogo: !this.searchIcon === true,
      hideProviderText: false,
      showQrInstructions: false
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.selectCrypto = this.selectCrypto.bind(this);
    this.handleProviderSelection = this.handleProviderSelection.bind(this);
    this.handleInstructionsButtonClick = this.handleInstructionsButtonClick.bind(this);
    this.handleContinueButtonClick = this.handleContinueButtonClick.bind(this);
  }

  componentDidMount() {
    this.getPopularProviders();
  }

  get searchIcon() {
    if (!this.state) {
      return "";
    }

    const providerDetails = this.state.selectedProviderDetails;
    return providerDetails.searchIcon || providerDetails.icon;
  }

  get isCrypto() {
    return isCryptoLinkingService(this.props.linkingService);
  }

  getPopularProviders() {
    const providers = popularProvidersSelector(store.getState(), this.props.category, this.props.linkingService);
    if (providers) {
      this.setState({
        ...this.state,
        isPending: false,
        popularProviders: providers,
        error: null
      });
      return;
    }

    this.setState({
      ...this.state,
      isPending: true,
      error: null,
      currentScreen: null,
      popularProviders: null,
      linkedAccounts: null,
      showYodleeAlert: false
    });
    this.props.fetchPopularProviders(
      this.props.linkingService,
      this.props.category,
      providers => {
        this.setState({
          ...this.state,
          isPending: false,
          popularProviders: providers,
          error: null
        });
      },
      apiError => {
        this.setState({
          ...this.state,
          isPending: false,
          popularProviders: null,
          searchText: null,
          error: apiError
        });
      }
    );
  }

  handleSearchInputChange(searchText) {
    if (!this.timer === false) {
      clearTimeout(this.timer);
    }

    if (!searchText === true) {
      this.setState({ ...this.state, searchText: searchText, isSearchPending: false, searchResults: null });
      return;
    } else {
      this.setState({ ...this.state, searchText: searchText, isSearchPending: true });
    }

    this.timer = setTimeout(() => {
      this.props.searchProviders(
        this.props.linkingService,
        this.props.category,
        searchText,
        this.props.aggregatorToBeSkippedOnProviderSearchScreen,
        this.props.linkProviderIdToBeSkippedOnProviderSearchScreen,
        (inputText, results) => {
          if (inputText !== this.state.searchText) {
            return;
          }
          this.setState({ ...this.state, isSearchPending: false, searchResults: results });
        },
        apiError => {
          this.setState({ ...this.state, isSearchPending: false });
        }
      );
      this.timer = null;
    }, 300);
  }

  handleLoaderRefreshClick() {
    this.getPopularProviders();
  }

  selectCrypto() {
    this.setState({
      selectedTab: 0
    });
  }

  handleProviderSelection(providerDetails) {
    if (!providerDetails === true) {
      return;
    }

    this.setState({ hideProviderLogo: false, hideProviderText: false });
    if (!providerDetails.helpText === false) {
      this.setState({ selectedProviderDetails: providerDetails, showConnectInstructions: true });
    } else if (qrBasedProviderIds.includes(providerDetails.linkProviderId)) {
      this.setState({ selectedProviderDetails: providerDetails, showQrInstructions: true });
    } else {
      this.props.onProviderSelection(providerDetails);
    }
  }

  handleInstructionsButtonClick(e) {
    window.kuberaOpen(this.state.selectedProviderDetails.helpUrl);
  }

  handleContinueButtonClick(e) {
    this.props.onProviderSelection(this.state.selectedProviderDetails);
  }

  handleLogoImageError = e => {
    this.setState({ hideProviderLogo: true, hideProviderText: false });
  };

  handleImageLoaded = e => {
    this.setState({ hideProviderLogo: false, hideProviderText: true });
  };

  render() {
    if (this.state.showQrInstructions === true) {
      const providerDetails = this.state.selectedProviderDetails;
      return (
        <QrLinkAccountComponent
          portfolioId={this.props.currentPortfolio.id}
          linkType={providerDetails.linkType}
          linkProviderName={providerDetails.name}
          linkProviderId={providerDetails.linkProviderId}
          category={this.props.category}
          mode={this.props.mode}
          custodianId={this.props.custodianId}
        />
      );
    }

    if (this.state.showConnectInstructions === true) {
      const providerDetails = this.state.selectedProviderDetails;

      return (
        <Container>
          <ProviderContainer>
            {this.state.hideProviderLogo === false && (
              <ProviderLogo
                src={process.env.PUBLIC_URL + "/images/connection_icons/" + this.searchIcon}
                onError={this.handleLogoImageError}
                onLoad={this.handleImageLoaded}
              />
            )}
            {this.state.hideProviderText === false && <ProviderName>{providerDetails.name}</ProviderName>}
          </ProviderContainer>
          <InstructionsBox>
            <InstructionStep>{i18n.t("linkAccount.instructionStep").replace("%s1%", "1")}</InstructionStep>
            <InstructionText>{providerDetails.helpText}</InstructionText>
            {!providerDetails.helpUrl === false && (
              <InstructionButton title={i18n.t("instructions")} onClick={this.handleInstructionsButtonClick} />
            )}
          </InstructionsBox>

          <InstructionsBox>
            <InstructionStep>{i18n.t("linkAccount.instructionStep").replace("%s1%", "2")}</InstructionStep>
            <InstructionText>{i18n.t("linkAccount.instructionContinue")}</InstructionText>
            <InstructionButton title={i18n.t("continue")} onClick={this.handleContinueButtonClick} />
          </InstructionsBox>
        </Container>
      );
    }

    const error = this.state.error;
    const isPending = this.state.isPending;
    const popularProviders = this.state.popularProviders;
    const searchText = this.state.searchText;
    const searchResults = this.state.searchResults;
    const isSearchPending = this.state.isSearchPending;
    const isPlaidSupported =
      !this.props.onTryPlaidConnection === false &&
      this.props.linkingService !== accountLinkingService.ZABO &&
      isPlaidSupportedForCountry(this.props.userCountryCode);

    return (
      <Container>
        {isPending === true && (
          <PageLoader
            errorMessage={!error === false ? error.errorMessage : null}
            onRefresh={this.handleLoaderRefreshClick}
          />
        )}
        {isPending === false && !error === true && (
          <ProviderScreenContainer>
            <Title>
              <Tabs isDisabled={this.state.selectedTab !== 0} onClick={this.selectCrypto}>
                {this.props.category === category.ASSET
                  ? this.isCrypto
                    ? i18n.t("linkAccount.cryptoTitle")
                    : i18n.t("linkAccount.assetsTitle")
                  : this.props.category === category.DEBT
                  ? this.isCrypto
                    ? i18n.t("linkAccount.defiTitle")
                    : i18n.t("linkAccount.debtsTitle")
                  : i18n.t("llinkAccount.debtsTitle")}
              </Tabs>
            </Title>
            {this.state.selectedTab === 0 && (
              <>
                <ProviderSearchBox
                  autoFocus={true}
                  isLoading={isSearchPending}
                  placeholder={
                    this.props.category === category.ASSET
                      ? this.isCrypto
                        ? i18n.t("linkAccount.searchCryptoAssetPlaceholder")
                        : i18n.t("linkAccount.searchAssetPlaceholder")
                      : this.props.category === category.DEBT
                      ? this.isCrypto
                        ? i18n.t("linkAccount.searchDefiLoansPlaceholder")
                        : i18n.t("linkAccount.searchDebtPlaceholder")
                      : i18n.t("linkAccount.searchDebtPlaceholder")
                  }
                  value={searchText}
                  onChange={this.handleSearchInputChange}
                  loaderThickness={3}
                />
                {!popularProviders === false && popularProviders.length > 0 && (
                  <PopularProviders
                    disabled={!searchText === false}
                    providers={popularProviders}
                    onClick={this.handleProviderSelection}
                    searchHint={
                      this.props.category === category.ASSET
                        ? this.isCrypto
                          ? i18n.t("linkAccount.popularProviderCryptoAssetSearchHint")
                          : i18n.t("linkAccount.popularProviderAssetSearchHint")
                        : ""
                    }
                  />
                )}
                {!this.props.statusConfig === false &&
                  !this.props.statusConfig.linkOutageMessage === false &&
                  this.isCrypto === false && (
                    <ProviderIssueBanner>{this.props.statusConfig.linkOutageMessage}</ProviderIssueBanner>
                  )}
                {!this.props.statusConfig === false &&
                  !this.props.statusConfig.cryptoLinkOutageMessage === false &&
                  this.isCrypto === true && (
                    <ProviderIssueBanner>{this.props.statusConfig.cryptoLinkOutageMessage}</ProviderIssueBanner>
                  )}
                <ProviderSearchResults
                  isVisible={!searchText === false && searchText.trim().length > 0 && !searchResults === false}
                  category={this.props.category}
                  searchResults={searchResults}
                  onClick={this.handleProviderSelection}
                  linkingService={this.props.linkingService}
                  isPlaidSupported={isPlaidSupported}
                  onTryPlaidConnection={this.props.onTryPlaidConnection}
                  showAggregator={!this.isCrypto}
                  isCrypto={this.isCrypto}
                  onTryCryptoTicker={this.props.onTryCryptoTicker}
                  searchText={searchText}
                />
              </>
            )}
          </ProviderScreenContainer>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusConfig: statusConfigSelector(state),
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  fetchPopularProviders: fetchPopularProviders,
  searchProviders: searchProviders
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProvidersComponent);
