import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  updateCustodianHistory,
  updateCustodian,
  getCustodianHistoryFormattedDateString,
  convertCustodianHistoryApiDateFormatToUIFormat,
  guessDateInCustodianHistoryUIFormat,
  getTickerUsingId,
  tickerTypes,
  getCustodianLastUpdateDetails,
  getSortKeyBetween,
  fetchTickerDetails,
  deleteCustodianHistory,
  isAppInViewMode,
  showToastTip,
  fetchCustodianDetailsHistory,
  getDateStringForExchangeRateDate,
  formatNumberAsCurrency,
  getSanitizedExchangeRate,
  getExchangeRate,
  getExchangeRateDetails,
  getTickerUsingShortName,
  fetchCustodianDetails,
  copyCustodianHistoryAfterDate,
  isCustodianHistoryRefreshingSelector,
  converUIDateFormatToCustodianHistoryApiDateFormat,
  guessDateFromUserInput
} from "@kubera/common";
import { connect } from "react-redux";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import Loader from "components/loader/Loader";
import InViewPort from "components/in_viewport/InViewPort";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LastUpdated = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${props => (props.isStale === true ? "rgba(255, 0, 0)" : "rgba(0, 0, 0, 0.4)")};
`;

const GridContainer = styled.div`
  margin-top: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCellsContainer = styled.div`
  display: flex;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: ${props => (props.isPrivateTicker ? "default" : "pointer")};
  user-select: ${props => (props.isPrivateTicker ? "none" : null)};
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

class CustodianDebtHistoryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.onScrollToBottom = this.onScrollToBottom.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);

    props.history.sort((a, b) => b.date.localeCompare(a.date));
    this.state = { gridData: this.getGridData(props.currency, props.history) };
  }

  componentDidUpdate(oldProps) {
    if (
      this.state.gridData.currency !== this.props.currency ||
      this.props.history.length !== oldProps.history.length ||
      this.props.rate !== oldProps.rate ||
      (this.props.isHistoryRefreshing === false && oldProps.isHistoryRefreshing === true)
    ) {
      this.setState({ gridData: this.getGridData(this.props.currency, this.props.history), showRateCellDialog: false });
    }
  }

  getOwnershipPercentageForDate(date) {
    if (!date === true || !this.props.ownership === true || this.props.ownership.length === 0) {
      return 100;
    }

    this.props.ownership.sort((a, b) => b.date.localeCompare(a.date));

    for (const ownership of this.props.ownership) {
      const ownershipDate = new Date(ownership.date);
      ownershipDate.setHours(0, 0, 0, 0);

      if (date.getTime() >= ownershipDate.getTime()) {
        return ownership.percentage;
      }
    }
    return 100;
  }

  getRateForDate = date => {
    if (!date === true || !this.props.rate === true || this.props.rate.length === 0) {
      return undefined;
    }

    this.props.rate.sort((a, b) => b.date.localeCompare(a.date));

    for (const rate of this.props.rate) {
      const rateDate = new Date(rate.date);
      rateDate.setHours(0, 0, 0, 0);

      if (date.getTime() >= rateDate.getTime() && rate.rate) {
        return JSON.parse(rate.rate);
      }
    }
    return undefined;
  };

  getGridData(currency, history) {
    var rows = [];
    let nextSortKey = null;
    for (const [, historyEntry] of history.entries()) {
      if (!historyEntry.value) {
        continue;
      }
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey, historyEntry);
      row.id = historyEntry.id;
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(historyEntry.date);
      row.cells[1].value = historyEntry.value ? historyEntry.value * this.props.valueMultiplier : historyEntry.value;
      row.cells[1].ownership = this.getOwnershipPercentageForDate(new Date(historyEntry.date));
      row.cells[1].rate = this.getRateForDate(new Date(historyEntry.date));

      if (historyEntry.valueTickerId) {
        row.cells[1].currency = getTickerUsingId(historyEntry.valueTickerId).shortName;
        row.cells[1].valueTickerId = historyEntry.valueTickerId;
      }
      row.cells[1].exchangeRateDate = new Date(historyEntry.date);
      row.cells[1].showDecimal = false;

      if (historyEntry.valueTickerId === 171) {
        row.cells[1].showDecimal = true;
        row.cells[1].decimalPlaces = 4;
      }

      row.tsModified = historyEntry.lastModified;
      rows.push(row);
    }
    if (rows.length === 0) {
      rows.push(this.getEmptyRow("1"));
    }

    const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
    if (history.length === 0 || rows[0].cells[0].value !== dateStringForToday) {
      const row = this.getEmptyRow("1");
      row.cells[0].value = dateStringForToday;

      row.cells[1].value = this.props.value * this.props.valueMultiplier;
      row.cells[1].currency = getTickerUsingId(this.props.valueTickerId).shortName;
      row.cells[1].ownership = this.getOwnershipPercentageForDate(new Date());
      row.cells[1].rate = this.getRateForDate(new Date());

      const rate = getExchangeRate(row.cells[1].currency, this.props.currency);
      row.cells[1].exchangeRateDetails = getExchangeRateDetails(getTickerUsingShortName(this.props.currency).id, rate);
      row.cells[1].exchangeRateDate = new Date();

      rows.unshift(row);
    }

    if (rows[0].cells[0].value === dateStringForToday) {
      rows[0].cells[0].value = i18n.t("today");
      rows[0].cells[0].isEditable = false;
      rows[0].cells[0].hideCellFocusHighlight = this.props.details.valueTickerId === 171;

      if (rows[0].cells[1].rate && this.props.details.valueTickerId === 171) {
        const rateParsed = rows[0].cells[1].rate;
        rows[0].cells[1].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
      }
    }

    rows[0].cells[1].isEditable = this.props.details.valueTickerId !== 171 && this.props.isCustodianLinked === false;

    for (let i = 0; i < Math.min(rows.length, 1); i++) {
      rows[i].cells[2].hide = true;
    }

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const valueColumn = new GridColumnData("Balance", true, this.props.isReadOnly === false, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, valueColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 1, 1, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyRow(sortKey, historyEntry) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    dateCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const valueCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1];
      if (
        this.props.currency === valueCell.currency ||
        !valueCell.exchangeRateDate === true ||
        valueCell.loading === true
      ) {
        return null;
      }

      if (historyEntry?.valueTickerId === 171) {
        const parsedExchangeRate = JSON.parse(historyEntry.valueExchangeRate);
        if (parsedExchangeRate) {
          parsedExchangeRate.rate = valueCell.rate ? parsedExchangeRate.rate * valueCell.rate.p : 0;
          valueCell.exchangeRateDetails = JSON.stringify(parsedExchangeRate);
        }
      } else if (historyEntry?.valueExchangeRate) {
        valueCell.exchangeRateDetails = historyEntry.valueExchangeRate;
      }

      const cellData = {
        exchangeRateDate: valueCell.exchangeRateDate,
        fromCurrency: valueCell.currency,
        toCurrency: this.props.currency,
        rate: valueCell.getCellExchangeRate(this.props.currency),
        sanitizedRate: getSanitizedExchangeRate(
          valueCell.getCellExchangeRate(this.props.currency),
          this.props.currency
        ),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 1
      };

      if (!isNaN(cellData.sanitizedRate) && valueCell.currency !== "PVST") {
        return (
          <ExchangeRateCellsContainer>
            <ExchangeRateCell
              onClick={e => this.handleRateCellClick(e, cellData)}
              isPrivateTicker={this.props.details.valueTickerId === 171}
            >
              <ExchangeCurrencies>{`${valueCell.currency.replace(".CC", "")}/${this.props.currency.replace(
                ".CC",
                ""
              )}`}</ExchangeCurrencies>
              <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.currency)}</ExchangeRate>
            </ExchangeRateCell>
          </ExchangeRateCellsContainer>
        );
      }

      return null;
    };

    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Balance", null, this.props.currency);
    valueCell.useRateFromExchangeRateDetails = true;
    if (this.props.details.valueTickerId === 171) {
      valueCell.showDecimal = true;
      valueCell.decimalPlaces = 4;
      valueCell.allowPVST = true;
      valueCell.isEditable = false;
      valueCell.hideCellFocusHighlight = true;
      dateCell.isEditable = false;
      dateCell.hideCellFocusHighlight = true;
    }
    valueCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const padCell = new GridCellData(cellType.PADDING, "", null);
    const cells =
      this.props.details.valueTickerId !== 171 ? [dateCell, valueCell, optionsCell] : [dateCell, valueCell, padCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      const date = cells[0].value;
      const balance = cells[1].value;
      const guessedDate = guessDateInCustodianHistoryUIFormat(date);

      if (guessedDate.isInvalid === false && balance !== null && balance !== undefined) {
        return true;
      }
      return false;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true || rowIndex === 0) {
        return null;
      }
      return [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.COPY_VALUE_ABOVE], [contextMenuItemType.DELETE]];
    };
    rowData.onUpdateDelay = 3000;
    return rowData;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      (row, index) => rowIndex !== index && row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      return;
    }

    this.entryForTodayDone = false;
    this.handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow);
  }

  handleHistoryUpdate = (sheetIndex, sectionIndex, rowIndex, updatedRow) => {
    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }

    const valueCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[1];
    if (
      valueCell.currency !== this.props.currency &&
      getDateStringForExchangeRateDate(valueCell.exchangeRateDate) !==
        getDateStringForExchangeRateDate(guessedDate.date)
    ) {
      valueCell.invalidInputText = valueCell.currency;
      valueCell.exchangeRateDate = guessedDate.date;
      this.handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, 1);
      return;
    }

    valueCell.rate = this.getRateForDate(new Date(guessedDate.dateString));
    if (valueCell.rate && updatedRow.cells[1].getTickerId() === 171) {
      const rateParsed = valueCell.rate;
      valueCell.exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
    }

    const isNewHistoryEntry = updatedRow.tsModified === 0;
    if (isNewHistoryEntry === true) {
      updatedRow.tsModified = new Date().getTime() / 1000;
    }

    const updateHistory = () => {
      this.props.updateHistory(isNewHistoryEntry, this.props.sectionId, this.props.custodianId, {
        id: updatedRow.id,
        date: guessedDate.dateString,
        value: updatedRow.cells[1].value,
        valueTickerId: updatedRow.cells[1].getTickerId(),
        valueExchangeRate: updatedRow.cells[1].exchangeRateDetails
      });

      if (
        this.entryForTodayDone === false &&
        this.isTodaysHistoryEntryPresent() === false &&
        guessedDate.dateString !== getCustodianHistoryFormattedDateString(new Date().getTime()) &&
        new Date(guessedDate.dateString).getTime() >=
          new Date(convertCustodianHistoryApiDateFormatToUIFormat(this.props.history[0].date)).getTime()
      ) {
        this.entryForTodayDone = true;
        this.handleHistoryUpdate(
          sheetIndex,
          sectionIndex,
          0,
          this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0]
        );
      }
    };

    const shouldUpdateCustodian = this.shouldUpdateCustodian(updatedRow.cells[0].value);
    if (shouldUpdateCustodian) {
      const propertiesToUpdate = {
        value: updatedRow.cells[1].value,
        valueTickerId: updatedRow.cells[1].getTickerId(),
        valueExchangeRate: updatedRow.cells[1].exchangeRateDetails,
        past: 0
      };
      updateHistory();
      this.props.updateCustodian(false, this.props.custodianId, propertiesToUpdate);
      this.props.onCustodianUpdate(propertiesToUpdate);
    } else {
      updateHistory();
    }
  };

  isTodaysHistoryEntryPresent() {
    const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
    const history = this.props.history;

    if (history.length === 0) {
      return false;
    }
    return dateStringForToday === convertCustodianHistoryApiDateFormatToUIFormat(history[0].date);
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.DELETE.id) {
      // If user tries to delete the top most history row and a history entry
      // for today is not present make an entry for today so that the top history
      // value and the custodian value are in sync
      if (rowIndex === 1 && this.isTodaysHistoryEntryPresent() === false) {
        const todaysRow = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0];
        this.props.updateHistory(
          todaysRow.tsModified === 0,
          this.props.sectionId,
          this.props.custodianId,
          {
            id: todaysRow.id,
            date: todaysRow.cells[0].value,
            value: todaysRow.cells[1].value,
            valueTickerId: todaysRow.cells[1].getTickerId(),
            valueExchangeRate: todaysRow.cells[1].exchangeRateDetails
          },
          false
        );
      }
      this.props.deleteHistory(this.props.sectionId, this.props.custodianId, row.id);
    } else if (menuItem.id === contextMenuItemType.COPY_VALUE_ABOVE.id) {
      const todaysRow = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0];
      const valueAndDate = {
        date: converUIDateFormatToCustodianHistoryApiDateFormat(row.cells[0].value),
        value: row.cells[1].value,
        valueTickerId: row.cells[1].getTickerId(),
        valueExchangeRate: todaysRow.cells[1].exchangeRateDetails
      };
      this.props.copyHistoryAfterDate(this.props.sectionId, this.props.custodianId, valueAndDate, custodian => {
        const propertiesToUpdate = {
          value: custodian.value,
          valueTickerId: custodian.valueTickerId,
          valueExchangeRate: custodian.valueExchangeRate
        };
        this.props.onCustodianUpdate(propertiesToUpdate, true);
      });
    }
  }

  shouldUpdateCustodian(updatedRowDateString) {
    if (!updatedRowDateString === true) {
      return false;
    }

    const updatedRowDate = guessDateInCustodianHistoryUIFormat(updatedRowDateString);
    if (updatedRowDate.isInvalid === true) {
      return false;
    }

    for (const row of this.state.gridData.sheets[0].sections[0].rows) {
      if (!row.cells[0].value === false) {
        const rowDate = guessDateFromUserInput(row.cells[0].value);
        if (rowDate.getTime() > updatedRowDate.date.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      var dateString = guessedDate.dateString;
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        row.cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        row.cells[0].invalidInputText = cellValue;
      } else {
        row.cells[0].invalidInputText = null;
      }

      const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
      row.cells[0].value = dateString === dateStringForToday ? i18n.t("today") : dateString;

      row.cells[1].ownership = this.getOwnershipPercentageForDate(guessedDate.date);
      row.cells[1].rate = this.getRateForDate(guessedDate.date);
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
    }
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      const date = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;
      this.props.fetchTickerDetails(
        cell.invalidInputText,
        date,
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.exchangeRateDetails = result.exchangeRateDetails;
          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  onScrollToBottom() {
    if (!this.props.isFetchPending && !this.props.isHistoryLoaded) {
      const detailsHistory = this.props.history;

      if (detailsHistory && detailsHistory[detailsHistory.length - 1]) {
        const { timestamp } = detailsHistory[detailsHistory.length - 1];
        this.props.fetchDetailsHistory(this.props.custodianId, timestamp);
      }
    }
  }

  handleRateCellClick(e, cellData) {
    if (this.props.details.valueTickerId === 171 || this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.handleRowUpdate(rateData.sheetIndex, rateData.sectionIndex, rateData.rowIndex, row, false);
  }

  render() {
    const gridData = this.state.gridData;
    const lastUpdateDetails = getCustodianLastUpdateDetails(this.props.custodianId);

    return (
      <Container className={this.props.className}>
        <LastUpdated isStale={lastUpdateDetails.isStale}>{lastUpdateDetails.dateString}</LastUpdated>
        <GridContainer>
          <Grid
            title={i18n.t("assetsComponent.title")}
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onAddNewRow={this.handleAddNewRow}
            onCellBlur={this.handleCellBlur}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            addRowBtnTxt={
              isAppInViewMode() || this.props.isReadOnly || this.props.details.valueTickerId === 171
                ? null
                : this.props.isHistoryLoaded && i18n.t("custodianDetails.addHistoryBtnTxt")
            }
            onRowContextMenuSelection={this.handleRowContextMenuSelection}
          />
          {this.props.isHistoryRefreshing === true && (
            <LoaderOverlay>
              <RefreshLoader />
            </LoaderOverlay>
          )}
        </GridContainer>
        {this.props.isFetchPending && !this.props.isHistoryLoaded && <Loader />}
        <InViewPort onVisible={this.onScrollToBottom} />
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
          />
        )}
      </Container>
    );
  }
}

CustodianDebtHistoryComponent.defaultProps = {
  valueMultiplier: 1
};

const mapStateToProps = state => ({
  isHistoryRefreshing: isCustodianHistoryRefreshingSelector(state)
});

const mapDispatchToProps = {
  fetchDetailsHistory: fetchCustodianDetailsHistory,
  updateHistory: updateCustodianHistory,
  updateCustodian: updateCustodian,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  deleteHistory: deleteCustodianHistory,
  fetchDetails: fetchCustodianDetails,
  copyHistoryAfterDate: copyCustodianHistoryAfterDate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianDebtHistoryComponent);
