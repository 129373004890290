import { store } from "../../store";
import { ApiClient } from "../../api/ApiClient";
import { getUuid } from "../../utilities/Number";
import { supportedLinkingServiceTypesSelector } from "../reducers/AuthReducer";
import { apiErrorCodes } from "../../api/ApiResponse";
import { getTickersForIds } from "./TickerActions";
import {
  isCryptoLinkingService,
  SET_BACKGROUND_LINK_FAILURE_DATA,
  SET_POPULAR_PROVIDERS,
  accountLinkingService,
  isZaboToInHouseApiCandidate,
  isZaboToInHouseOauthCandidate
} from "./Common";
import { UNKNOWN_TICKER_SHORT_NAME, getTickerUsingId } from "../reducers/Common";

export const qrBasedProviderIds = ["jpmel_oauth_client_gb", "23875", "revolut_client_oauth_gb"];

export const setBackgroundLinkFailureDataAction = failureData => ({
  type: SET_BACKGROUND_LINK_FAILURE_DATA,
  failureData
});

export const setPopularProvidersAction = (category, providerEntries) => ({
  type: SET_POPULAR_PROVIDERS,
  category,
  providerEntries
});

export const getAggregatorName = linkType => {
  switch (linkType) {
    case 1:
      return "Yodlee";
    case 2:
      return "Plaid";
    case 3:
      return "Zabo";
    case 4:
    case 5:
      return "Salt Edge";
    case 6:
      return "Zillow";
    case 7:
      return "Estibot";
    case 8:
      return "Vinaudit";
    case 9:
      return "Zerion";
    case 10:
    case 11:
      return "Crypto";
    case 12:
    case 13:
      return "Flinks";
    case 14:
      return "Akahu";
    case 15:
      return "Lean";
    case 16:
      return "Mastercard";
    case 17:
      return "SnapTrade";
    case 18:
      return "MX";
    case 19:
      return "Kubera Portfolio";
    case 20:
      return "Oauth";
    default:
      return null;
  }
};

export const accountLinkingContainers = {
  NFT: "nft",
  INVESTMENT: "investment"
};

const hardwareWalletsProviders = ["trezor", "ledger", "phantomwallet", "keplrwallet"];

export const isHardwareWalletProvider = (linkType, linkProviderId) => {
  return (
    (linkType === accountLinkingService.IN_HOUSE_CRYPTO_API || linkType === accountLinkingService.ZABO) &&
    hardwareWalletsProviders.includes(linkProviderId)
  );
};

export const isZaboReconnectionCandidate = (linkType, linkProviderId) => {
  if (!linkProviderId === true || linkType !== accountLinkingService.ZABO) {
    return false;
  }
  return ["coinbase-wallet", "atomicwallet", "trust", "exodus"].includes(linkProviderId.toLowerCase());
};

export const isZaboManualEntryCandidate = (linkType, linkProviderId) => {
  if (!linkProviderId === true || linkType !== accountLinkingService.ZABO) {
    return false;
  }
  return (
    isZaboToInHouseApiCandidate(linkProviderId) === false &&
    isZaboToInHouseOauthCandidate(linkProviderId) === false &&
    isZaboReconnectionCandidate(linkProviderId) === false
  );
};

export const zaboMigrateProviderId = linkProviderId => {
  if (!linkProviderId === true) {
    return linkProviderId;
  }
  if (linkProviderId.toLowerCase() === "xpub-key") {
    return "xpubbitcoin";
  }
  return linkProviderId.toLowerCase();
};

export const coinsPopularProvidersList = [
  { id: 194, name: "Bitcoin", shortName: "BTC.CC", symbol: "BTC", icon: "btc.png" },
  { id: 229, name: "Ether", shortName: "ETH.CC", symbol: "ETH", icon: "ether.png" },
  { id: 339, name: "Tether", shortName: "USDT.CC", symbol: "USDT", icon: "tether.png" },
  { id: 338, name: "USD Coin", shortName: "USDC.CC", symbol: "USDC", icon: "usdcoin.png" },
  { id: 189, name: "Binance Coin", shortName: "BNB.CC", symbol: "BNB", icon: "binancecoin.png" },
  { id: 366, name: "XRP", shortName: "XRP.CC", symbol: "XRP", icon: "xrp.png" },
  { id: 200, name: "Binance USD", shortName: "BUSD.CC", symbol: "BUSD", icon: "binanceusd.png" },
  { id: 172, name: "Cardano", shortName: "ADA.CC", symbol: "ADA", icon: "cardano.png" },
  { id: 7279, name: "Solana", shortName: "SOL.CC", symbol: "SOL", icon: "solana.png" },
  { id: 823, name: "Polkadot", shortName: "DOT.CC", symbol: "DOT", icon: "polkadot.png" },
  { id: 219, name: "Dogecoin", shortName: "DOGE.CC", symbol: "DOGE", icon: "dogecoin.png" },
  { id: 7283, name: "Avalanche", shortName: "AVAX.CC", symbol: "AVAX", icon: "avalanche.png" },
  { id: 244237, name: "Shiba Inu", shortName: "SHIB.CC", symbol: "SHIB", icon: "shibainu.png" },
  { id: 217112, name: "Lido Staked Ether", shortName: "STETH.CC", symbol: "STETH", icon: "steth.png" },
  { id: 210, name: "Dai", shortName: "DAI.CC", symbol: "DAI", icon: "dai.png" }
];

export const stocksPopularProvidersList = [
  { id: 28819, name: "Apple Inc.", shortName: "AAPL.NA", symbol: "AAPL" },
  { id: 70178, name: "Microsoft Corp.", shortName: "MSFT.NA", symbol: "MSFT" },
  { id: 31085, name: "Amazon.com Inc.", shortName: "AMZN.NA", symbol: "AMZN" },
  { id: 72679, name: "NVIDIA Corp.", shortName: "NVDA.NA", symbol: "NVDA" },
  { id: 54318, name: "Alphabet Inc - Class A", shortName: "GOOGL.NA", symbol: "GOOGL" },
  { id: 35586, name: "Berkshire Hathaway Inc. - Class B", shortName: "BRK.B.NY", symbol: "BRK.B" },
  { id: 92721, name: "Exxon Mobil Corp.", shortName: "XOM.NY", symbol: "XOM" },
  { id: 339023, name: "Meta Platforms Inc.", shortName: "META.NA", symbol: "META" }
];

export const isLinkingServiceSupported = linkingService => {
  const supportedLinkingServiceTypes = supportedLinkingServiceTypesSelector(store.getState());

  if (linkingService && supportedLinkingServiceTypes) {
    return supportedLinkingServiceTypes.includes(linkingService);
  }
  return false;
};

export const isPlaidSupportedForCountry = countryCode => {
  if (!countryCode === true) {
    return false;
  }

  const plaidSupportedCountries = ["US", "CA"];
  return plaidSupportedCountries.includes(countryCode.toUpperCase());
};

export const getAccountLinkingServiceType = (countryCode = null) => {
  if (isLinkingServiceSupported(accountLinkingService.PLAID) && isPlaidSupportedForCountry(countryCode)) {
    return accountLinkingService.PLAID;
  }
  return accountLinkingService.YODLEE;
};

export const getServiceNameForCountry = countryCode => {
  return getAggregatorName(getAccountLinkingServiceType(countryCode));
};

export const prefetchPopularProviders = categories => {
  return dispatch => {
    for (const category of categories) {
      dispatch(
        fetchPopularProviders(
          null,
          category,
          providers => {
            var linkingServiceToProviderMap = {};

            for (const [index, provider] of providers.entries()) {
              var linkingService = null;

              provider.index = index;

              if (provider.aggregatorOptions) {
                const primaryAggregator = provider.aggregatorOptions.find(option => option.plan === "a");
                if (primaryAggregator) {
                  linkingService = primaryAggregator.linkType;
                }
              }

              if (linkingService) {
                provider.linkType = linkingService;

                if (!linkingServiceToProviderMap[linkingService] === false) {
                  const serviceProviders = linkingServiceToProviderMap[linkingService];
                  serviceProviders.push(provider);
                } else {
                  linkingServiceToProviderMap[linkingService] = [provider];
                }
              }
            }

            var providerEntries = [];
            for (const popularProviderService in linkingServiceToProviderMap) {
              const providersEntry = {
                providers: linkingServiceToProviderMap[popularProviderService],
                category: category,
                linkingService: parseInt(popularProviderService)
              };

              providerEntries.push(providersEntry);
            }
            dispatch(setPopularProvidersAction(category, providerEntries));
          },
          null
        )
      );
    }
  };
};

export const fetchPopularProviders = (linkType, category, onSuccess, onError) => {
  return dispatch => {
    var providerType = "any";
    if (isCryptoLinkingService(linkType)) {
      providerType = "crypto";
    } else if (!linkType === false) {
      providerType = "fiat";
    }

    ApiClient.getPopularProviders(getUuid(), providerType, category)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        if (onError) {
          onError(apiError);
        }
      });
  };
};

export const searchProviders = (
  linkType,
  category,
  searchText,
  aggregatorToBeSkipped,
  linkProviderIdToBeSkipped,
  onSuccess,
  onError
) => {
  return dispatch => {
    var providerType = "fiat";
    if (isCryptoLinkingService(linkType)) {
      providerType = "crypto";
    }

    ApiClient.searchProviders(
      getUuid(),
      providerType,
      "Any",
      searchText,
      aggregatorToBeSkipped,
      linkProviderIdToBeSkipped
    )
      .then(apiData => {
        onSuccess(searchText, apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getJwtToken = (linkType, category, action, providerId, providerName, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getJwtToken(getUuid(), linkType, category, action, providerId, providerName)
      .then(apiData => {
        onSuccess("Bearer " + apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getPublicTokenForCustodian = (linkType, custodianId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getPublicTokenForCustodian(getUuid(), linkType, custodianId)
      .then(apiData => {
        onSuccess(apiData.payload.publicToken);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getLinkTokenForCustodian = (linkType, linkDetails, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getLinkTokenForCustodian(getUuid(), linkType, linkDetails)
      .then(apiData => {
        onSuccess(apiData.payload.linkToken);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const setPublicTokenForCustodian = (linkType, custodianId, params, onSuccess, onError) => {
  return dispatch => {
    ApiClient.setPublicTokenForCustodian(getUuid(), linkType, custodianId, params)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getLinkedAccounts = (
  linkType,
  category,
  params,
  onSuccess = () => null,
  onError = () => null,
  shouldRetry = () => true
) => {
  return dispatch => {
    const maxRetry = linkType !== accountLinkingService.SALTEDGE ? 10 : 20;
    var retryCount = 0;

    const fetchAccounts = (error = null) => {
      retryCount++;
      if (shouldRetry() === false) {
        onError(error);
        return;
      }
      if (retryCount > maxRetry) {
        onError(error);
        return;
      }

      ApiClient.getLinkedAccountList(getUuid(), linkType, category, params)
        .then(apiData => {
          const accounts = apiData.payload;

          var unknownTickerIds = new Set();
          for (const account of accounts) {
            if (getTickerUsingId(account.tickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
              unknownTickerIds.add(account.tickerId);
            }
          }

          if (unknownTickerIds.size === 0) {
            onSuccess(accounts);
          } else {
            dispatch(
              getTickersForIds(
                Array.from(unknownTickerIds),
                newTickers => {
                  onSuccess(accounts, newTickers);
                },
                () => {
                  onSuccess(accounts);
                }
              )
            );
          }
        })
        .catch(apiError => {
          if (
            apiError.errorCode === apiErrorCodes.PRODUCT_NOT_READY ||
            apiError.httpStatus === 504 ||
            apiError.httpStatus === 499
          ) {
            setTimeout(() => {
              fetchAccounts(apiError);
            }, 10000);
          } else if (apiError.errorCode === apiErrorCodes.TIMEOUT) {
            onError(apiError);
          } else {
            onError(apiError);
          }
        });
    };
    fetchAccounts();
  };
};

export const getProviderDetails = (linkType, providerId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getProviderDetails(getUuid(), linkType, providerId)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const deleteProviderAccount = (linkType, providerAccountId) => {
  return dispatch => {
    ApiClient.deleteProviderAccount(getUuid(), linkType, providerAccountId)
      .then(apiData => {})
      .catch(apiError => {});
  };
};

export const getLinkedConnectionsList = (groupId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getLinkedConnectionsList(getUuid(), groupId)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getLinkConnectionUrl = (linkType, linkProviderId, onSuccess, onError) => {
  return dispatch => {
    var params = {};
    if (process.env.REACT_APP_ENV === "local") {
      params.test = 1;
    }

    ApiClient.getLinkConnectionUrl(getUuid(), linkType, linkProviderId, params)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getRefreshConnectionUrl = (linkType, linkProviderAccountId, onSuccess, onError) => {
  return dispatch => {
    var params = {};
    if (process.env.REACT_APP_ENV === "local") {
      params.test = 1;
    }

    ApiClient.getRefreshConnectionUrl(getUuid(), linkType, linkProviderAccountId, params)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getReconnectConnectionUrl = (linkType, linkProviderAccountId, onSuccess, onError) => {
  return dispatch => {
    var params = {};
    if (process.env.REACT_APP_ENV === "local") {
      params.test = 1;
    }

    ApiClient.getReconnectConnectionUrl(getUuid(), linkType, linkProviderAccountId, params)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const searchTickerInfo = (term, type) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.searchTickerInfo(getUuid(), term, type)
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const searchHomesInfo = (term, custodianId, type = "all") => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.searchHomesInfo(getUuid(), term.trim(), custodianId, type)
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const linkAndGetAddressDetails = (custodianId, selected) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        portfolioId: custodianId,
        providerId: "zillow",
        providerName: "zillow",
        metaData: JSON.stringify(selected)
      };

      ApiClient.linkAndGetAddressDetails(getUuid(), body)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getVehicleDetails = vinNumber => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getVehicleDetails(getUuid(), vinNumber)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const linkAndGetVehicleDetails = (custodianId, selected) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        portfolioId: custodianId,
        providerId: "vinaudit",
        providerName: "vinaudit",
        metaData: JSON.stringify(selected)
      };

      ApiClient.linkAndGetVehicleDetails(getUuid(), body)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getDomainDetails = vinNumber => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getDomainDetails(getUuid(), vinNumber)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const linkAndGetDomainDetails = (custodianId, selected) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        portfolioId: custodianId,
        providerId: "estibot",
        providerName: "estibot",
        metaData: JSON.stringify(selected)
      };

      ApiClient.linkAndGetDomainDetails(getUuid(), body)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const utilityStatus = logProps => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.utilityStatus(getUuid(), logProps)
        .then(apiData => {
          resolve(apiData ? apiData.payload : null);
        })
        .catch(apiError => {
          reject(apiError);
        });
    }).catch(apiError => {
      console.log(apiError);
    });
  };
};

export const getConnectionInfo = (linkType, providerId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getConnectionInfo(getUuid(), linkType, providerId)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getReConnectionInfo = (linkType, providerAccountId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.getReConnectionInfo(getUuid(), linkType, providerAccountId)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const setLinkingProviderStatus = (linkType, groupId, providerId, status) => {
  return dispatch => {
    ApiClient.setLinkingProviderStatus(getUuid(), linkType, groupId, providerId, status)
      .then(apiData => {})
      .catch(apiError => {});
  };
};
