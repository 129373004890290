import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import {
  fundScheduleTypes,
  fetchCustodianDetails,
  custodianDetailsIsPendingSelector,
  custodianDetailsErrorSelector,
  custodianDetailsSelector,
  resetCustodianDetailsAction,
  getPortfolioFundScheduleData
} from "@kubera/common";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FundScheduleComponent from "./FundScheduleComponent";
import Loader from "components/loader/Loader";

const scheduleTabs = {
  CAPITAL_CALL: "capital_call",
  DISTRIBUTION: "distribution"
};

const ScheduleDialog = styled(Dialog)`
  width: 800px;
  min-height: 790px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Description = styled.div`
  margin-top: 12px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
`;

const ScheduleTabs = styled(Tabs)`
  margin-top: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ScheduleTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const ScheduleTab = styled(Tab)`
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 6px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 3px solid rgba(0, 0, 0, 1);
  }
`;

const ScheduleTabPanel = styled(TabPanel)`
  margin-top: -10px;
  padding-top: 12px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  &.is-selected {
    display: flex;
    flex: 1;
  }
`;

const ScheduleTabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScheduleHeader = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.7;
`;

const CashflowLink = styled.a`
  margin-left: 5px;
  text-decoration: ${props => (props.disabled ? "none" : "underline")};
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};

  &:visited {
    color: #000000;
  }
`;

class FundScheduleDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleOnDismiss = this.handleOnDismiss.bind(this);
    this.handleScheduleUpdated = this.handleScheduleUpdated.bind(this);

    this.isScheduleUpdated = false;
  }

  componentDidMount() {
    if (this.props.shouldFetchDetails === true) {
      this.props.fetchDetails(this.props.custodian.id, undefined, false);
    }
  }

  handleTabChange(tabKey) {
    const tabs = this.getTabs();
    const tabIndex = tabs.findIndex(tab => tab.key === tabKey);
    if (tabIndex !== -1) {
      this.setState({ tabIndex });
    }
  }

  handleScheduleUpdated() {
    this.isScheduleUpdated = true;
  }

  handleOnDismiss() {
    if (this.isScheduleUpdated) {
      this.props.getPortfolioFundScheduleData();
    }
    if (this.props.shouldFetchDetails === true) {
      this.props.resetCustodianDetailsAction();
    }
    this.props.onDismiss();
  }

  getTabs() {
    return [
      {
        key: scheduleTabs.CAPITAL_CALL,
        title: i18n.t("capitalCall"),
        panelComponent: (
          <ScheduleTabPanelContainer>
            <ScheduleHeader>
              {i18n.t("capitalCallHeader")}
              <CashflowLink
                disabled={!this.props.onCashflowLinkClick === true}
                onClick={this.props.onCashflowLinkClick}
              >
                {i18n.t("cashflowBreadcrumb")}
              </CashflowLink>
              <span>{" table"}</span>
            </ScheduleHeader>
            <FundScheduleComponent
              type={fundScheduleTypes.CAPITAL_CALL}
              isReadOnly={this.props.isReadOnly}
              currency={this.props.currency}
              custodian={this.props.custodian}
              scheduleUpdated={this.handleScheduleUpdated}
            />
          </ScheduleTabPanelContainer>
        )
      },
      {
        key: scheduleTabs.DISTRIBUTION,
        title: i18n.t("distribution"),
        panelComponent: (
          <ScheduleTabPanelContainer>
            <ScheduleHeader>
              {i18n.t("distributionHeader")}
              <CashflowLink
                disabled={!this.props.onCashflowLinkClick === true}
                onClick={this.props.onCashflowLinkClick}
              >
                {i18n.t("cashflowBreadcrumb")}
              </CashflowLink>
            </ScheduleHeader>
            <FundScheduleComponent
              type={fundScheduleTypes.DISTRIBUTION}
              isReadOnly={this.props.isReadOnly}
              currency={this.props.currency}
              custodian={this.props.custodian}
              scheduleUpdated={this.handleScheduleUpdated}
            />
          </ScheduleTabPanelContainer>
        )
      }
    ];
  }

  render() {
    if (
      this.props.shouldFetchDetails === true &&
      (this.props.isFetchPending === true || !this.props.details === true)
    ) {
      return (
        <DialogOverlay onDismiss={this.props.onDismiss}>
          <ScheduleDialog>
            <Container>
              <Loader />
            </Container>
          </ScheduleDialog>
        </DialogOverlay>
      );
    }

    const tabs = this.getTabs();
    return (
      <DialogOverlay onDismiss={this.handleOnDismiss}>
        <ScheduleDialog>
          <Container>
            <Title>{this.props.custodian.name}</Title>
            <Description>{i18n.t("scheduleFor")}</Description>

            <ScheduleTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={tabIndex => this.setState({ tabIndex })}
            >
              <ScheduleTabList>
                {tabs.map((tab, index) => (
                  <ScheduleTab data-cy={"ScheduleTab" + index} key={index}>
                    {tab.title}
                  </ScheduleTab>
                ))}
              </ScheduleTabList>
              {tabs.map((tab, index) => (
                <ScheduleTabPanel key={`ScheduleTabPanel${index}`}>{tab.panelComponent}</ScheduleTabPanel>
              ))}
            </ScheduleTabs>
          </Container>
        </ScheduleDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  isFetchPending: custodianDetailsIsPendingSelector(state),
  fetchError: custodianDetailsErrorSelector(state),
  details: custodianDetailsSelector(state)
});

const mapDispatchToProps = {
  fetchDetails: fetchCustodianDetails,
  resetCustodianDetailsAction: resetCustodianDetailsAction,
  getPortfolioFundScheduleData: getPortfolioFundScheduleData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundScheduleDialog);
