import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  userPreferencesSelector,
  accountSubscriptionStatusSelector,
  accountPlanSelector,
  accountCurrentTsSelector,
  accountEndTsSelector,
  getSubscriptionEndDateString,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS
} from "@kubera/common";

const AccountSubscribeActionTxt = ({ className, remainingDays = 0, ...otherProps }) => {
  const userPreferences = useSelector(userPreferencesSelector);
  const accountCurrentTs = useSelector(accountCurrentTsSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const accountPlan = useSelector(accountPlanSelector);

  const currentDate = getSubscriptionEndDateString(accountCurrentTs);
  const oneLessEndTs = getSubscriptionEndDateString(accountEndTs - 86400);

  const isPastDue = accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE;

  const [actionVisible, setActionVisible] = useState(false);

  useEffect(() => {
    const isActionVisible =
      (remainingDays <= 10 && remainingDays >= 1) ||
      remainingDays === 2 ||
      remainingDays === 1 ||
      remainingDays <= 0 ||
      isPastDue;

    setActionVisible(isActionVisible);
  }, [remainingDays, isPastDue, userPreferences.bannerPreference, accountSubscriptionStatus]);

  if (
    !actionVisible ||
    accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
    accountPlan !== SUBSCRIPTION_PLANS.TRIAL ||
    isPastDue
  ) {
    return null;
  }

  if (currentDate === oneLessEndTs) {
    return (
      <span className={className} {...otherProps}>
        {i18n.t("accountSubscribeActionTxt.subscribeExpiryTomorrow")}
      </span>
    );
  }

  if (remainingDays <= 10 && remainingDays >= 1) {
    const txt =
      remainingDays !== 1
        ? "accountSubscribeActionTxt.subscribeExpiryDays"
        : "accountSubscribeActionTxt.subscribeExpiryDay";
    return (
      <span className={className} {...otherProps}>
        {i18n.t(txt).replace("%s%", remainingDays)}
      </span>
    );
  }

  if (remainingDays === 0 && accountSubscriptionStatus !== SUBSCRIPTION_STATUS.EXPIRED) {
    return (
      <span className={className} {...otherProps}>
        {i18n.t("accountSubscribeActionTxt.subscribeExpiryToday")}
      </span>
    );
  }

  if (remainingDays <= 0 && accountSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
    return (
      <span className={className} {...otherProps}>
        {i18n.t("accountSubscribeActionTxt.subscribeExpired")}
      </span>
    );
  }

  return null;
};

export default AccountSubscribeActionTxt;
