import { useSelector } from "react-redux";

import { multiuserListSelector, accountPlanSelector, SUBSCRIPTION_PLANS } from "@kubera/common";

const useDefaultSelectedFrequency = () => {
  const accountPlan = useSelector(accountPlanSelector);
  const multiuserList = useSelector(multiuserListSelector);

  return accountPlan !== SUBSCRIPTION_PLANS.TRIAL
    ? accountPlan
    : multiuserList && multiuserList.length > 1
    ? SUBSCRIPTION_PLANS.YEARLY_BLACK
    : SUBSCRIPTION_PLANS.YEARLY;
};

export default useDefaultSelectedFrequency;
