import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "i18next";

import { getUserAttributes } from "@kubera/common";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import VerifyScreen from "components/twoFA/VerifyScreen";
import SmsAuth from "components/twoFA/SmsAuth";
import SaveBackupCodes from "components/twoFA/SaveBackupCodes";
import Authenticator from "components/twoFA/Authenticator";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const SaveBackupConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 450px;
`;

const TwoFAModal = ({
  isOpen = false,
  onDismiss = () => null,
  displayOption,
  displayStep = 0,
  initUserAttrs,
  isCodesDismissOptOne = true,
  isCodesDismissOptTwo = false
}) => {
  const [step, setStep] = useState(1);
  const [option, selectOption] = useState(2);
  const [userAttrs, setUserAttrs] = useState(void 0);
  const [isBackupDismissConfirmOpen, setIsBackupDismissConfirmOpen] = useState(false);

  isCodesDismissOptOne = !isCodesDismissOptTwo;

  const onDialogDismiss = () => {
    setStep(displayStep);
    onDismiss();
  };

  const onVerifySuccess = async data => {
    let attrs = await getUserAttributes();
    setUserAttrs(attrs);
    setStep(2);
  };

  const onSmsVerifySuccess = () => {
    setStep(3);
  };

  const onTOTPVerifySuccess = () => {
    setStep(3);
  };

  const goBack = () => {
    onDismiss();
  };

  const onBakupCodesDismiss = () => {
    setIsBackupDismissConfirmOpen(true);
  };

  const onBakupCodesConfirmPositiveClick = () => {
    setIsBackupDismissConfirmOpen(false);
  };

  useEffect(() => {
    if (displayOption) {
      selectOption(displayOption);
    }
  }, [displayOption]);

  useEffect(() => {
    if (displayStep) {
      setStep(displayStep);
    }
  }, [displayStep]);

  useEffect(() => {
    setUserAttrs(initUserAttrs);
  }, [initUserAttrs]);

  if (!isOpen || !userAttrs) {
    return null;
  }

  if (step === 0 || step === 1) {
    return (
      <DialogOverlay onDismiss={onDialogDismiss}>
        <VerifyScreen onVerifySuccess={onVerifySuccess} />
      </DialogOverlay>
    );
  }

  if (step === 3) {
    return (
      <DialogOverlay onDismiss={onBakupCodesDismiss}>
        <SaveBackupCodes onDownload={onDialogDismiss} />
        {isBackupDismissConfirmOpen && isCodesDismissOptOne && (
          <SaveBackupConfirmationDialog
            title={i18n.t("twoFA.saveBackupConfirmationTitle")}
            description={i18n.t("twoFA.saveBackupConfirmationDesc")}
            positiveButtonTitle={i18n.t("goBackLink")}
            handlePositiveButtonClick={onBakupCodesConfirmPositiveClick}
          />
        )}
        {isBackupDismissConfirmOpen && isCodesDismissOptTwo && (
          <SaveBackupConfirmationDialog
            title={i18n.t("twoFA.saveBackupConfirmationTitle")}
            description={i18n.t("twoFA.saveBackupConfirmationDesc2")}
            positiveButtonTitle={i18n.t("goBackLink")}
            handlePositiveButtonClick={onBakupCodesConfirmPositiveClick}
          />
        )}
      </DialogOverlay>
    );
  }

  if (option === 1 && step === 2) {
    return (
      <DialogOverlay onDismiss={onDialogDismiss}>
        <SmsAuth onSmsVerifySuccess={onSmsVerifySuccess} goBack={goBack} userAttrs={userAttrs} />
      </DialogOverlay>
    );
  }

  if (option === 2 && step === 2) {
    return (
      <DialogOverlay onDismiss={onDialogDismiss}>
        <Authenticator onTOTPVerifySuccess={onTOTPVerifySuccess} goBack={goBack} userAttrs={userAttrs} />
      </DialogOverlay>
    );
  }

  return null;
};

export default TwoFAModal;
