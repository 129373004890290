import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import LabelUpdateHandler from "components/labels/LabelUpdateHandler";

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  color: ${props => (!props.isEditing ? "transparent !important" : null)};
`;

const CurrencyCellUpdateHandler = ({
  className,
  value: displayValue,
  isEditing: isEditingMode = false,
  isEditable = true,
  inputProps = {}
}) => {
  const inputRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [labelValue, setLabelValue] = useState(false);
  const [preventAnimation, setPreventAnimation] = useState(false);

  const { other: otherInpProps, ...inpProps } = inputProps;
  const { onChange = () => null, onFocus = () => null, onBlur = () => null } = inpProps;

  const setInputRef = ref => {
    inputRef.current = ref;
  };

  const handleOnChange = e => {
    setIsEditing(true);
    setPreventAnimation(true);
    onChange(e);
  };

  const handleOnFocus = e => {
    inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    setPreventAnimation(true);
    onFocus(e);
  };

  const handleOnBlur = e => {
    setIsEditing(false);
    setTimeout(() => {
      setPreventAnimation(false);
    }, 300);
    onBlur(e);
  };

  useEffect(() => {
    setLabelValue(displayValue);
  }, [displayValue]);

  return (
    <>
      <Input
        {...otherInpProps}
        {...inpProps}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        isEditing={isEditing || isEditingMode}
        ref={setInputRef}
        autoComplete="off"
        data-protonpass-ignore
        data-1p-ignore
      />
      <LabelUpdateHandler
        className={className}
        value={labelValue}
        preventAnimation={preventAnimation || isEditing || !isEditable}
        isEditing={isEditing || isEditingMode}
      />
    </>
  );
};

export default CurrencyCellUpdateHandler;
