import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  searchTickerInfo,
  currentPortfolioSelector,
  getTickersForText,
  coinsPopularProvidersList,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  addTickerInfoAction,
  tickerTypes,
  setLinkType,
  utilityStatus,
  useHistory
} from "@kubera/common";

import requestIdleCallback from "utilities/requestIdleCallback";
import AutocompleteSearchInp from "components/inputs/AutocompleteSearchInp";
import PopularProvidersListComponent from "components/link_account/PopularProvidersListComponent";
import NumberInput from "components/inputs/NumberInput";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import NothingFoundComponent from "./NothingFoundComponent";

import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const SearchInpWrapper = styled.div``;

const SearchListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 12px 9px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 500;

  &.is-active {
    background: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const SearchSymbol = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
  font-weight: 400;
`;

const TickerContainer = styled.div`
  margin-top: 20px;
`;

const TickerHead = styled.div`
  position: relative;
  padding: 17px 17px 16px;
  background: #ebebeb;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
`;

const RemoveIconComponent = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
  padding: 10px;
  cursor: pointer;
`;

const TickerInput = styled(NumberInput)`
  padding: 18px 14px 15px;
  box-sizing: border-box;
  width: 100%;
  border: ${props => (props.isError ? "1px solid #ff0000" : "1px solid rgba(0, 0, 0, 0.4)")};
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const TickersForm = styled.form`
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SearchMoreTxt = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  margin-top: 21px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
`;

const CoinsConnectComponent = props => {
  const { searchInputPlaceholder = "", renderSelectedTicker = null } = props;

  const portfolio = useSelector(currentPortfolioSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchTimeoutId = useRef(null);
  const formRef = useRef();
  const searchInpRef = useRef();

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const getSearchTickerInfo = async value => {
    const response = await dispatch(searchTickerInfo(value, tickerTypes.CRYPTO)).catch(err => {
      setIsSearchLoading(false);
    });

    const { payload: results } = response || {};

    if (value && (!results || results.length === 0)) {
      setSearchResults([
        {
          i: "0",
          n: "Nothing found",
          nf: true
        }
      ]);
    } else {
      setSearchResults(results || []);
    }
    setIsSearchLoading(false);
  };

  const getSearchInput = () => {
    if (searchInpRef.current && searchInpRef.current.querySelector("input")) {
      return searchInpRef.current.querySelector("input");
    }

    return {
      onFocus: () => null,
      onBlur: () => null
    };
  };

  const onSearchChange = value => {
    setSearchText(value);
    setSearchResults([]);

    if (value) {
      setIsSearchLoading(true);
    } else {
      setIsSearchLoading(false);
      clearTimeout(searchTimeoutId.current);
      return;
    }

    clearTimeout(searchTimeoutId.current);
    searchTimeoutId.current = setTimeout(() => {
      getSearchTickerInfo(value);
    }, 300);
  };

  const onRenderList = item => {
    if (searchText && isSearchFocused && !item.nf) {
      return (
        <SearchListItem key={item.i}>
          {item.n}
          <SearchSymbol>{item.c}</SearchSymbol>
        </SearchListItem>
      );
    } else if (searchText && isSearchFocused && item.nf) {
      return <NothingFoundComponent key={item.i} />;
    }

    return null;
  };

  const onSelect = selected => {
    if (selected && !selected.nf) {
      const { id = selected.i, name = selected.n, symbol = selected.c, shortName = selected.s } = selected || {};
      const tickerData = { id, name, code: symbol, shortName };

      requestIdleCallback(() => {
        setSelectedTickers([...selectedTickers, tickerData]);
      });

      dispatch(
        getTickersForText(
          shortName,
          ticker => {
            ticker.forEach(each => {
              dispatch(addTickerInfoAction(each.info, each.rate));
            });
          },
          () => {},
          false
        )
      );
    }

    setTimeout(() => {
      setSearchText("");
    }, 100);
  };

  const removeTickerFromIndex = index => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers.splice(index, 1);
    setSelectedTickers(tempSelectedTickers);
  };

  const onTickerQuantityChange = (value, index) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    setSelectedTickers(tempSelectedTickers);
  };

  const onRenderSelectedTicker = (ticker, index) => {
    if (!ticker) {
      return null;
    }

    if (renderSelectedTicker) {
      return renderSelectedTicker(ticker);
    } else {
      const { inputVal = "" } = selectedTickers[index];
      const isError = isSubmitted ? !inputVal : false;

      return (
        <TickerContainer key={index}>
          <TickerHead>
            {`${ticker.name} (${ticker.code})`}
            <RemoveIconComponent
              onClick={() => {
                removeTickerFromIndex(index);
              }}
            >
              <RemoveIcon />
            </RemoveIconComponent>
          </TickerHead>
          <TickerInput
            placeholder={i18n.t("quantity")}
            value={inputVal}
            onChange={e => {
              onTickerQuantityChange(e.target.value, index);
            }}
            required
            autoFocus
            isError={isError}
          ></TickerInput>
        </TickerContainer>
      );
    }
  };

  const onTickerSubmit = e => {
    e.preventDefault();

    setIsSubmitted(true);
    let custodianId = props.custodianId;
    let sortKey = null;

    if (formRef.current.checkValidity()) {
      selectedTickers.forEach(ticker => {
        if (!ticker.inputVal === false) {
          const newCustodian = {
            name: ticker.name,
            value: ticker.inputVal,
            valueTickerId: ticker.id,
            past: 0
          };
          const nextValues = dispatch(
            insertTickerCustodianInSection(
              portfolio.id,
              currentCustodian.sectionId,
              custodianId,
              newCustodian,
              sortKey,
              custodian => {
                if (!custodian.id) {
                  dispatch(
                    utilityStatus({
                      errorMessage: "Failed to link custodian",
                      ...newCustodian,
                      linkType: "coins"
                    })
                  );
                }
              },
              ticker.shortName
            )
          );

          custodianId = nextValues.custodianId;
          sortKey = nextValues.sortKey;
        }
      });

      dispatch(setLinkType("coins"));
      DialogOverlay.forceDismiss(history, location);
    } else {
      setErrorMessage(i18n.t("linkAccount.coinErrorMsg"));
    }
  };

  const onAddMoreClick = () => {
    getSearchInput().focus();
  };

  const onSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const onSearchBlur = () => {
    setIsSearchFocused(false);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  useEffect(() => {
    setIsSubmitted(false);
    setErrorMessage("");
  }, [searchText, searchResults, selectedTickers]);

  if (currentCustodian) {
    return (
      <Container className={props.className}>
        <SearchInpWrapper ref={searchInpRef}>
          <AutocompleteSearchInp
            inputPlaceholder={searchInputPlaceholder}
            searchValue={searchText}
            options={searchResults}
            onSearchChange={onSearchChange}
            onRenderList={onRenderList}
            onSelect={onSelect}
            isLoading={isSearchLoading}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
          />
        </SearchInpWrapper>
        {selectedTickers.length === 0 ? (
          <PopularProvidersListComponent
            disabled={!searchText === false && isSearchFocused}
            providers={coinsPopularProvidersList}
            onClick={onSelect}
            searchHint={i18n.t("linkAccount.coinSearchHint")}
            isProviderTypeCoin={true}
          />
        ) : (
          <TickersForm
            ref={formRef}
            onSubmit={onTickerSubmit}
            disabled={isSearchFocused}
            noValidate
            data-exitconfirm="coins"
          >
            {selectedTickers.map(onRenderSelectedTicker)}
            <SearchMoreTxt onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</SearchMoreTxt>
            <TickerFormFooter>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} />
            </TickerFormFooter>
          </TickersForm>
        )}
      </Container>
    );
  }

  return null;
};

export default CoinsConnectComponent;
