import React, { useImperativeHandle } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  userPriceOptionSelector,
  accountAllPlansSelector,
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  isMobile
} from "@kubera/common";
import RadioButton from "components/inputs/RadioButton";

import MobileAccountSubscriptionOptions from "./MobileAccountSubscriptionOptions";
import useOptionsRadioButtonSelectionHandler from "./useOptionsRadioButtonSelectionHandler";

const isMobileDevice = isMobile();

const RadioButtonsContainer = styled.div``;

const RadioOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  row-gap: 20px;
`;

const RadioOption = styled(RadioButton)`
  display: flex;
  align-items: center;

  div {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
`;

const PriceNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 3px;
  padding-left: 26px;
  white-space: pre-wrap;

  a {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const AccountSubscriptionOptions = React.forwardRef(({ selectedFrequencyValue, handleRadioButtonSelection }, ref) => {
  const userPrice = useSelector(userPriceOptionSelector);
  const accountAllPlans = useSelector(accountAllPlansSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);

  useImperativeHandle(ref, () => ({
    onSubmitShowModals: () => {
      return true;
    }
  }));

  useOptionsRadioButtonSelectionHandler(handleRadioButtonSelection);

  return (
    <RadioButtonsContainer>
      <RadioOptionContainer>
        {accountAllPlans.map(plan => {
          const subscriptionPlan = SUBSCRIPTION_PLANS[plan.toUpperCase()];
          return userPrice[plan] ? (
            <div key={plan}>
              <RadioOption
                label={i18n.t(`accountSettings.subscriptionOption_${plan}`).replace("%s%", userPrice[plan])}
                value={subscriptionPlan}
                checked={selectedFrequencyValue === subscriptionPlan}
                disabled={
                  accountPlan !== SUBSCRIPTION_PLANS.TRIAL &&
                  accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
                  userPrice[plan] < userPrice[accountPlan]
                }
                onChange={handleRadioButtonSelection}
              />
              <PriceNote
                dangerouslySetInnerHTML={{
                  __html: i18n.t(`accountSettings.subsciptionNote_${plan}`)
                }}
              />
            </div>
          ) : null;
        })}
      </RadioOptionContainer>
    </RadioButtonsContainer>
  );
});

export default isMobileDevice ? MobileAccountSubscriptionOptions : AccountSubscriptionOptions;
