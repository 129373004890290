import React from "react";
import styled from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import { hashParams, modalValues } from "routes";
import i18n from "i18next";
import { store, userStorageLimit, convertBytesToString } from "@kubera/common";

const SizeLimitDialog = styled(GenericMessageDialog)``;

class StorageFullDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.UPLOAD_TOO_LARGE}`
    });
  };

  render() {
    const description = i18n
      .t("storageFullDialog.description")
      .replace("%s%", convertBytesToString(userStorageLimit(store.getState())));

    return <SizeLimitDialog title={i18n.t("storageFullDialog.title")} description={description} />;
  }
}

export default StorageFullDialog;
