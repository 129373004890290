export class ApiResponse {
  constructor(
    rawResponse = {
      status: 404,
      errorMessage: "Api Failed",
      data: {}
    }
  ) {
    this.httpStatus = rawResponse.status;
    this.errorCode = rawResponse.data.errorCode;
    this.errorMessage = rawResponse.data.errorMessage;
    this.extra = rawResponse.data.extra;
    this.data = rawResponse.data;
  }

  isError() {
    return this.errorCode !== 0;
  }
}

export const apiErrorCodes = {
  NO_RESPONSE: -10001,
  INVALID_REQUEST: -10002,
  EMPTY_RESPONSE: -10003,
  UNKNOWN_ERROR: -10004,
  DUPLICATE_ENTRY: 1062,
  SESSION_EXPIRED: 1002,
  INVALID_INPUT: 1003,
  PRODUCT_NOT_READY: 1022,
  UNRECOVERABLE_ERROR: 1023,
  REFRESH_NOT_AVAILABLE: 1029,
  CONNECTION_NOT_AVAILABLE: 1030,
  TIMEOUT: 1035,
  LIMIT_REACHED: 1020,
  EMAIL_IN_USE: 1036,
  ACCOUNT_SUSPENDED: 1037,
  WL_DOMAIN_DISABLED: 1033,
  SERVICES_UNAVAILABLE: 1039,
  CRYPTO_PROVIDER_NOT_SUPPORTED_WITH_AGGREGATOR: 1040,
  FETCH_PORTFOLIOS: 1041,
  AUTO_UPDATE_DENIED: 1027,
  PROVIDER_NOT_SUPPORTED: 1044,
  FORBIDDEN_REQUEST: 1042,
  CLIENT_BREAKING_CHANGES: 1046
};

export class ApiData {
  constructor(apiResponse) {
    this.httpStatus = apiResponse.httpStatus;
    this.payload = apiResponse.data.data;
  }
}

export class ApiError {
  constructor(apiResponse) {
    this.httpStatus = apiResponse.httpStatus;
    this.errorCode = apiResponse.errorCode;
    this.errorMessage = apiResponse.errorMessage;
    this.extra = apiResponse.extra;
  }
}
