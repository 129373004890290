import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import DeferredPromise from "utilities/DeferredPromise";
import {
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType,
  getGridDataFromPortfolio
} from "components/grid/GridDataModel";
import {
  updateCustodian,
  getUuid,
  insertCustodian,
  insertSection,
  updateSection,
  updateSheet,
  lastDashboardUpdateTsSelector,
  dashboardEntitesToUpdateSelector,
  currentPortfolioSelector,
  archiveCustodian,
  bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus,
  bulkActionSource,
  archiveSection,
  archiveSheet,
  insertSheet,
  unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians,
  ApiClient,
  userPreferencesSelector,
  updateUserPreferences,
  moveCustodian,
  deleteCustodian,
  deleteCustodianAction,
  getTickerUsingId,
  tickerTypes,
  insertCustodianAtEndOfSection,
  userCountryNameSelector,
  getHashParams,
  isInitialTipShown,
  getCustodianLastUpdateDetails,
  shouldShowLinkingErrorForCustodian,
  custodiansLinkedToSameAccount,
  showToastTip,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountGraceTsSelector,
  accountStartTsSelector,
  accountSubscriptionStatusSelector,
  accountClientCurrentTsSelector,
  accountSubscriptionIsActiveSelector,
  dismissToastAction,
  isAssetCustodian,
  fetchCustodianTickerDetails,
  accountLinkingService,
  isAppInViewMode,
  isMobile,
  isReadOnlyWlClient,
  currentPortfolioCurrencySelector,
  custodianLinkingHintWaitTime,
  siteConfigSelector,
  store,
  custodianSelector,
  fetchPortfolioChangeData,
  currentPortfolioChangeDataSelector,
  isCryptoLinkingService,
  custodiansWithSameParentIdSelector,
  getOwnershipValueForCustodian,
  tickerSubTypes,
  accountLinkingContainers,
  getSortKeyBetween,
  getTickerUsingShortName,
  userMaskAllValuesSelector,
  isCustodianAddedToday,
  isAppInWhiteLabelMode,
  connectivityCenterDataForPortfolioSelector,
  getConnectivityCenterData,
  unlinkAndManuallyTrackAllConnectedCustodians,
  sectionSelector,
  sheetSelector,
  isInternalUserSelector,
  portfolioSelector,
  capitalizeStringWithSpaces
} from "@kubera/common";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import LinkAccountComponentExports, { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import ArchivedComponent from "components/archived/ArchivedComponent";
import {
  contextMenuItemType,
  getLinkContextMenuItem,
  getUpdateValuesContextMenuItem,
  getTickerDescriptionContextMenuItem,
  getLinkedAccountDescriptionMenuItem,
  getManagedAssetContextMenuItem,
  getStartOverContextMenuItem
} from "components/contextmenu/ContextMenu";
import { downloadFile } from "utilities/FileUtils";
import EmptyGridComponent from "components/grid/EmptyGridComponent";
import SheetUpdateFrequencyDialog from "components/grid/SheetUpdateFrequencyDialog";
import PickLinkAccountCountry from "components/link_account/PickLinkAccountCountry";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import LinkAccountCommonExports from "components/link_account/LinkAccountCommonExports";
import { category } from "components/dashboard/DashboardComponentExports";
import CustodianDetailsWrapperComponent from "components/custodian_details/CustodianDetailsWrapperComponent";
import GridPageHandleSheetIdHash from "components/grid/GridPageHandleSheetIdHash";
import EmptyGridViewDebtsComponent from "./EmptyGridViewDebtsComponent";
import MobileDebtsWrapperComponent from "./MobileDebtsWrapperComponent";
import AddModal from "./AddModal";
import MoveItemDialog, { moveItemType } from "components/grid/MoveItemDialog";

const isMobileDevice = isMobile();

const Container = styled.div`
  width: 100%;
  padding-bottom: 44px;
`;

const Grid = styled(GridComponentWrapper)`
  width: 100%;
`;

const EmptyGridMessage = styled(EmptyGridComponent)`
  margin-top: 50px;
`;
class DebtsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gridData: this.getGridData(props.portfolio),
      hideEmptyState: false,
      showUnlinkAllConnectionsDialog: false,
      unlinkAllConnectionsForRow: null,
      reconnectDialogRow: null,
      showReconnectDialog: false,
      showDisconnectAndTrackManuallyDialog: false,
      rowToDisconnectAndTrackManually: null,
      isRemoveFlow: false,
      moveDialogForData: null
    };

    this.handleInitialTipClick = this.handleInitialTipClick.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleAddNewSection = this.handleAddNewSection.bind(this);
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this);
    this.handleSheetUpdate = this.handleSheetUpdate.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleToggleSectionStarStatus = this.handleToggleSectionStarStatus.bind(this);
    this.handleToggleSectionUpdatedStatus = this.handleToggleSectionUpdatedStatus.bind(this);
    this.handleToggleSheetUpdatedStatus = this.handleToggleSheetUpdatedStatus.bind(this);
    this.handleToggleSheetStarStatus = this.handleToggleSheetStarStatus.bind(this);
    this.handleRemoveSection = this.handleRemoveSection.bind(this);
    this.handleRemoveSheet = this.handleRemoveSheet.bind(this);
    this.getEmptySheet = this.getEmptySheet.bind(this);
    this.handleAddNewSheet = this.handleAddNewSheet.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleGridContextMenuSelection = this.handleGridContextMenuSelection.bind(this);
    this.handleSheetContextMenuSelection = this.handleSheetContextMenuSelection.bind(this);
    this.handleSectionContextMenuSelection = this.handleSectionContextMenuSelection.bind(this);
    this.handleReorderRowsForSections = this.handleReorderRowsForSections.bind(this);
    this.handleSectionMove = this.handleSectionMove.bind(this);
    this.handleUnlinkAllConnectionsDialogPositiveButtonClick = this.handleUnlinkAllConnectionsDialogPositiveButtonClick.bind(
      this
    );
    this.handleUnlinkAllConnectionsDialogOnDismiss = this.handleUnlinkAllConnectionsDialogOnDismiss.bind(this);
    this.getPlainGridData = this.getPlainGridData.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleLinkErrorClick = this.handleLinkErrorClick.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleReconnectDialogPositiveButtonClick = this.handleReconnectDialogPositiveButtonClick.bind(this);
    this.handleReconnectDialogNegativeButtonClick = this.handleReconnectDialogNegativeButtonClick.bind(this);
    this.handleReconnectDialogDismissButtonClick = this.handleReconnectDialogDismissButtonClick.bind(this);
    this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick = this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick.bind(
      this
    );
    this.handleDisconnectAndTrackManuallyDialogOnDismiss = this.handleDisconnectAndTrackManuallyDialogOnDismiss.bind(
      this
    );
    this.handleMoveDialogDismiss = this.handleMoveDialogDismiss.bind(this);

    this.custodianRefreshTsMap = {};
    this.CustodianDetailsWrapperRef = React.createRef();
    this.addModalRef = React.createRef();
    this.gridDataRef = React.createRef();
    this.isComingFromEmptyScreenRef = React.createRef(false);
    this.deleteConfirmationPromise = new DeferredPromise();
    this.updateTimeoutId = null;
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.portfolio.details !== this.props.portfolio.details ||
      oldProps.portfolioChangeData !== this.props.portfolioChangeData
    ) {
      this.setState({ gridData: this.getGridData(this.props.portfolio), hideEmptyState: false });
    }
    if (
      this.state.gridData.currency !== this.props.portfolio.currency ||
      oldProps.accountClientCurrentTs !== this.props.accountClientCurrentTs
    ) {
      this.setState({ gridData: this.getGridData(this.props.portfolio) });
    }
    if (oldProps.lastDashboardUpdateTs !== this.props.lastDashboardUpdateTs) {
      const entitiesToUpdate = this.props.dashboardEntitesToUpdate;
      const newGridData = this.getGridData(this.props.portfolio, this.state.gridData, entitiesToUpdate);

      if (newGridData.isEmpty() === false && newGridData.numberOfRowsWithLinkingInProgress() === 0) {
        const userPreferences = this.props.userPreferences;
        if (userPreferences.isDebtInitialTipShownForPortfolio.includes(this.props.portfolio.id) === false) {
          this.props.updateUserPreferences({
            isDebtInitialTipShownForPortfolio: [
              ...userPreferences.isDebtInitialTipShownForPortfolio,
              this.props.portfolio.id
            ]
          });
        }
      }

      this.setState({ gridData: newGridData, hideEmptyState: newGridData.isEmpty() === false });
    }

    // If the user is coming from link account flow
    // check whether we need to show the onboarding tips
    const previousModal = getHashParams(oldProps.location) ? getHashParams(oldProps.location)[hashParams.MODAL] : null;
    const currentModal = getHashParams(this.props.location)
      ? getHashParams(this.props.location)[hashParams.MODAL]
      : null;

    if (previousModal === modalValues.ACCOUNT_SETTINGS && !currentModal) {
      this.setState({ gridData: this.getGridData(this.props.portfolio), hideEmptyState: false });
    }

    if (
      previousModal &&
      (previousModal === modalValues.LINK_ACCOUNT ||
        (previousModal === modalValues.PICK_LINK_COUNTRY) | (previousModal === modalValues.CONNECT_PORTFOLIOS)) &&
      !currentModal === true
    ) {
      setTimeout(() => {
        if (this.state.gridData.isEmpty() === true) {
          this.setState({ hideEmptyState: false });
        }
      }, 0);
    }
  }

  getPlainGridData(category) {
    return getGridDataFromPortfolio(
      this.props.portfolio,
      category,
      this.props.portfolio.currency,
      (sheet, sections) => {
        const currentSheet = this.getEmptySheet(sheet.sortKey);
        currentSheet.id = sheet.id;
        currentSheet.name = sheet.name;
        currentSheet.sections = sections;
        currentSheet.category = sheet.category;
        return currentSheet;
      },
      (index, section, rows) => {
        const currentSection = this.getEmptySection(index, section.sortKey, this.isReadOnly());
        currentSection.id = section.id;
        currentSection.name = section.name;
        if (rows.length > 0) {
          currentSection.rows = rows;
        }
        return currentSection;
      },
      custodian => {
        const currentRow = this.getEmptyRow(custodian.sortKey, this.isReadOnly());
        return currentRow;
      }
    );
  }

  getGridData(portfolio, currentGridData = null, entitiesToUpdate = []) {
    const gridData = getGridDataFromPortfolio(
      portfolio,
      category.DEBT,
      this.props.portfolio.currency,
      (sheet, sections) => {
        const currentSheet = this.getEmptySheet(sheet.sortKey);
        currentSheet.id = sheet.id;
        currentSheet.name = sheet.name;
        currentSheet.sections = sections;
        currentSheet.category = sheet.category;
        return currentSheet;
      },
      (index, section, rows) => {
        const currentSection = this.getEmptySection(index, section.sortKey, this.isReadOnly());
        currentSection.id = section.id;
        currentSection.name = section.name;
        currentSection.columnSortKey = section.columnSortKey;
        currentSection.columnSortOrder = section.columnSortOrder;
        if (rows.length > 0) {
          currentSection.rows = rows;
        }
        return currentSection;
      },
      custodian => {
        const currentRow = this.getEmptyRow(custodian.sortKey, this.isReadOnly());
        currentRow.id = custodian.id;
        currentRow.isLinked =
          (this.props.accountSubscriptionIsActive || this.isReadOnly()) && !custodian.linkType === false;
        currentRow.isVerified = custodian.isVerified === 1 && this.props.siteConfig.showVerified === "show";
        currentRow.linkType = custodian.linkType;
        currentRow.linkStatus = custodian.status;
        currentRow.linkStatusInfo = custodian.statusInfo;
        currentRow.linkedHoldingsCount = custodian.holdingsCount;
        currentRow.linkContainer = custodian.linkContainer;
        currentRow.linkProviderName = custodian.linkProviderName;
        currentRow.linkAccountName = custodian.linkAccountName;
        currentRow.linkAccountMask = custodian.linkAccountMask;
        currentRow.isLinking = this.props.accountSubscriptionIsActive && custodian.isLinking;
        currentRow.linkingFailed = custodian.linkingFailed;
        currentRow.linkingAccountsData = custodian.linkingAccountsData;
        currentRow.valueTickerId = custodian.valueTickerId;
        currentRow.tsStart = custodian.tsStart;
        currentRow.linkProviderAccountId = custodian.linkProviderAccountId;
        currentRow.availableCredit = custodian.availableCredit;
        currentRow.actualValue = custodian.value;

        const linkedAccountDescription = currentRow.getLinkedAccountDescription();
        currentRow.cells[1].value = custodian.name;
        currentRow.cells[1].linkType = custodian.linkType;
        currentRow.cells[1].toolTip = linkedAccountDescription ? `Connected: ${linkedAccountDescription}` : null;

        currentRow.cells[1].description = custodian.description;
        if (this.props.accountSubscriptionIsActive && custodian.isRefreshing === true) {
          clearTimeout(this.updateTimeoutId);
          this.updateTimeoutId = setTimeout(() => {
            this.isUpdateClicked = false;
          }, 300);
          if (shouldShowLinkingErrorForCustodian(custodian) === false) {
            currentRow.cells[3] = new GridCellData(cellType.LOADER, "", null);
          }
        } else if (this.props.accountSubscriptionIsActive && custodian.isLinking === true) {
          if (!custodian.linkingAccountsData === false) {
            currentRow.cells[1].description = i18n.t("connected");
          } else if (custodian.linkingTs && new Date().getTime() - custodian.linkingTs > custodianLinkingHintWaitTime) {
            currentRow.cells[1].description = i18n.t("connectingStill");
          } else {
            currentRow.cells[1].description = i18n.t("connecting");
          }
        }
        if (isAssetCustodian(custodian.linkContainer, custodian.linkType)) {
          currentRow.cells[2].value = -custodian.value;
          currentRow.cells[2].isAssetCustodianForDebt = true;
        } else {
          currentRow.cells[2].value = custodian.value;
        }
        currentRow.cells[2].pastValueDetails = custodian.pastValue;
        currentRow.cells[2].canShowDayChange = currentRow.linkType !== accountLinkingService.ZILLOW;
        currentRow.cells[2].ownership = this.props.getOwnershipValueForCustodian(custodian.id);
        if (custodian.valueTickerId) {
          currentRow.cells[1].currency = getTickerUsingId(custodian.valueTickerId).shortName;
          currentRow.cells[2].currency = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[2].exchangeRateDetails = custodian.valueExchangeRate;
        }

        currentRow.cells[2].invalidInputText = custodian.valueInvalidInputText;
        currentRow.cells[2].tickerInfo = custodian.valueTickerInfo;

        currentRow.cells[2].valueTickerId = custodian.valueTickerId;
        currentRow.cells[2].rate = custodian.rate;

        currentRow.cells[2].loading = custodian.isLinking === true || custodian.fetchingValueTickerInfo === true;
        currentRow.cells[2].isEditable =
          (currentRow.isLinked === false ||
            [accountLinkingService.ZILLOW, accountLinkingService.DOMAINS, accountLinkingService.CARS].includes(
              custodian.linkType
            ) ||
            (custodian.parentId !== null &&
              [
                accountLinkingService.ZERION,
                accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
                accountLinkingService.IN_HOUSE_CRYPTO_API
              ].includes(custodian.linkType) &&
              custodian.linkContainer === accountLinkingContainers.NFT)) &&
          this.props.maskAllValues === false;

        currentRow.cells[2].allowPVST = true;
        if (custodian.rate && custodian.valueTickerId === 171) {
          const rateParsed = JSON.parse(custodian.rate);
          currentRow.cells[2].useRateFromExchangeRateDetails = true;
          currentRow.cells[2].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
        }
        if (custodian.isLinking === true) {
          var linkingCell = new GridCellData(cellType.LOADER, "", null);
          if (!custodian.linkingAccountsData === false) {
            linkingCell = new GridCellData(cellType.LINKING_INPUT_NEEDED, "", null);
          } else if (custodian.linkingFailed === true) {
            linkingCell = new GridCellData(cellType.LINKING_FAILURE, "", null);
          }

          currentRow.cells[3] = linkingCell;
        } else if (!custodian.valueTickerInfo === false) {
          const tickerErrorCell = new GridCellData(cellType.TICKER_ERROR, "", null);
          currentRow.cells[3] = tickerErrorCell;
        } else if (currentRow.isLinked === true && shouldShowLinkingErrorForCustodian(custodian) === true) {
          const linkErrorCell = new GridCellData(cellType.LINK_ERROR, "", null);
          linkErrorCell.toolTip = i18n.t("gridCell.linkErrorToolTip");
          currentRow.cells[4] = linkErrorCell;
          currentRow.cells[4].isReadOnly = this.isReadOnly();
        }
        return currentRow;
      },
      currentGridData,
      entitiesToUpdate
    );

    gridData.isEditable = this.isReadOnly() === false && this.props.maskAllValues === false;
    gridData.forceShowArchivedItems = this.props.isReadOnlyWlClient;
    gridData.onTotalChange = () => {
      this.props.fetchPortfolioChangeData(this.props.portfolio.id);
    };
    return gridData;
  }

  getEmptySheet(sortKey) {
    const sheetData = new GridSheetData(getUuid(), sortKey, null, []);

    sheetData.getNewActionContextMenuItems = () => {
      return [
        [
          {
            ...contextMenuItemType.NEW_ACTION_SECTION,
            description: "To group similar debts"
          }
        ],
        [
          {
            ...contextMenuItemType.NEW_ACTION_SECTION_ROW,
            label: contextMenuItemType.NEW_ACTION_SECTION_ROW.label.replace(/%s%/, category.DEBT)
          }
        ]
      ];
    };

    return sheetData;
  }

  getEmptySection = (forIndex, sortKey, isViewOnlyMode = false) => {
    const padColumn = new GridColumnData(null, false, false, true);
    const nameColumn = new GridColumnData("Debt", true, isViewOnlyMode === false, false);
    const balanceColumn = new GridColumnData("Balance", true, isViewOnlyMode === false, false);
    const detailsColumn = new GridColumnData(null, true, false, true);
    const optionsColumn = new GridColumnData(null, true, false, true);

    var columns = [padColumn, nameColumn, balanceColumn, detailsColumn, optionsColumn];
    if (isViewOnlyMode === true) {
      columns = [padColumn, nameColumn, balanceColumn, detailsColumn, padColumn];
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [
        this.getEmptyRow("1", this.isReadOnly()),
        this.getEmptyRow("2", this.isReadOnly()),
        this.getEmptyRow("3", this.isReadOnly())
      ],
      columns,
      2,
      2,
      false
    );
    sectionData.getContextMenuItems = section => {
      return [[contextMenuItemType.RENAME, contextMenuItemType.MOVE_SECTION, contextMenuItemType.REMOVE_SECTION]];
    };
    sectionData.getFooterContextMenuItems = () => {
      return null;
    };
    return sectionData;
  };

  getEmptyRow(sortKey, isViewOnlyMode = false) {
    const detailsCell = new GridCellData(cellType.DETAILS, "", null);
    detailsCell.showHint = false;
    detailsCell.toolTip = i18n.t("gridCell.detailsButtonToolTip");
    const nameCell = new GridCellData(cellType.TEXT, category.DEBT, null);
    const balanceCell = new CurrencyCellData(cellType.CURRENCY, "Balance", null, this.props.portfolio.currency);
    balanceCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    balanceCell.isDebt = true;
    const padCell = new GridCellData(cellType.PADDING, "", null);
    padCell.toolTip = i18n.t("gridCell.starButtonToolTip");
    padCell.padding = "0 14px 0 0";
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    optionsCell.toolTip = i18n.t("gridCell.optionsButtonToolTip");

    var cells = [padCell, nameCell, balanceCell, detailsCell, optionsCell];
    if (isViewOnlyMode === true || this.props.maskAllValues === true) {
      const rightPadCell = new GridCellData(cellType.PADDING, "", null);
      rightPadCell.padding = "0 14px 0 0";
      cells = [padCell, nameCell, balanceCell, detailsCell, rightPadCell];
    }

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      const name = cells[1].value;
      const balance = cells[2].value;

      if (name && name.length > 0 && (balance !== null && balance !== undefined)) {
        return true;
      }
      return false;
    });
    rowData.getContextMenuItems = row => {
      if (isViewOnlyMode === true || this.props.maskAllValues === true) {
        return [];
      }

      let removeOption = contextMenuItemType.REMOVE;
      if (!isCustodianAddedToday(row.id)) {
        removeOption = contextMenuItemType.ARCHIVE;
      }
      removeOption = [
        {
          ...removeOption,
          label: `${removeOption.label} ${
            row.linkType === accountLinkingService.KUBERA_PORTFOLIO ? i18n.t("linkedPortfolio") : ""
          }`,
          description: row.getLinkedAccountRemoveDescription()
        }
      ];

      const isErrorCellShown = row.cells[4].type === cellType.LINK_ERROR;

      if (!row.isComplete()) {
        const items = [[contextMenuItemType.INSERT_ABOVE], removeOption];
        if (!row.isLinking === true && this.props.isReadOnlyWlClient === false) {
          items.push([getLinkContextMenuItem(category.DEBT)]);
        }
        return items;
      } else if (row.isLinked === true) {
        const connectivityCenterDataForCurrentPortfolio = this.props.connectivityCenterDataForPortfolio;
        const connectivityData =
          connectivityCenterDataForCurrentPortfolio &&
          connectivityCenterDataForCurrentPortfolio.find(
            data => data.providerAccountId === rowData.linkProviderAccountId
          );

        const items = [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.MOVE_ROW, ...removeOption]];
        if (row.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
          items[1].pop();
          items.push([
            ...removeOption,
            {
              ...contextMenuItemType.UNLINK,
              label: i18n.t("unlinkPortfolio"),
              description: capitalizeStringWithSpaces(
                portfolioSelector(store.getState(), row.linkProviderAccountId)?.name
              )
            }
          ]);
          return items;
        }

        if (
          row.linkStatus === 1 &&
          ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(row.linkStatusInfo)
        ) {
          if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
            if (!connectivityData.reconnectInfo) {
              // show Reconnect if there is not active recommendation to reconnect already
              items.unshift([contextMenuItemType.RECONNECT]);
            }
            if (!connectivityData.disconnect && !row.parentId) {
              items.unshift([contextMenuItemType.TRACK_MANUALLY]);
            }
            items.unshift([getStartOverContextMenuItem(rowData.linkProviderAccountId, connectivityData, true)]);
          } else {
            if (!row.parentId) {
              items.unshift([contextMenuItemType.TRACK_MANUALLY]);
            }
            items.unshift([contextMenuItemType.RECONNECT]);
          }
          items.unshift([
            getUpdateValuesContextMenuItem(
              getCustodianLastUpdateDetails(row.id),
              { is2FAError: true },
              connectivityData
            )
          ]);
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            }
          ]);
          if (isAppInWhiteLabelMode() === true) {
            items.splice(1, 0, [getManagedAssetContextMenuItem(row.isManaged)]);
          }
          return items;
        }

        if (isErrorCellShown) {
          const badConnectionContextMenuItems = [
            {
              ...contextMenuItemType.BAD_CONNECTION,
              descriptionText: row.getLinkedAccountDescription(),
              lastUpdatedDetails: getCustodianLastUpdateDetails(row.id)
            }
          ];
          const activeRecommendationContextMenuItem = [];
          const passiveRecommendationReconnectContextMenuItem = [];
          const passiveRecommendationTrackManuallyContextMenuItem = [];
          if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
            // Active Recommendation
            if (!isCryptoLinkingService(row.linkType)) {
              activeRecommendationContextMenuItem.push(
                getStartOverContextMenuItem(rowData.linkProviderAccountId, connectivityData, false)
              );
              if (!connectivityData.reconnectInfo) {
                // show Reconnect if there is not active recommendation to reconnect already
                passiveRecommendationReconnectContextMenuItem.push({
                  ...contextMenuItemType.RECONNECT,
                  doNotShowReconnectOptionsDialog: true
                });
              }
            }
            // show Track Manually if there is not active recommendation to disconnect already
            if (!connectivityData.disconnect && !row.parentId) {
              passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
            }
          } else {
            // passive Recommendation when no active recommendation is present
            if (!isCryptoLinkingService(row.linkType)) {
              passiveRecommendationReconnectContextMenuItem.push({
                ...contextMenuItemType.RECONNECT,
                doNotShowReconnectOptionsDialog: true
              });
            }
            if (!row.parentId) {
              passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
            }
          }
          items.unshift(
            badConnectionContextMenuItems,
            activeRecommendationContextMenuItem,
            passiveRecommendationReconnectContextMenuItem,
            passiveRecommendationTrackManuallyContextMenuItem
          );
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            }
          ]);
          return items;
        }

        if (
          !row.isLinking === true &&
          [
            accountLinkingService.ZERION,
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(row.linkType)
        ) {
          items.push(
            !row.parentId
              ? [
                  getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id)),
                  contextMenuItemType.TRACK_MANUALLY,
                  {
                    ...contextMenuItemType.UNLINK,
                    label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                    description: row.getLinkedAccountDescription()
                  }
                ]
              : [
                  getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id)),
                  {
                    ...contextMenuItemType.UNLINK,
                    label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                    description: row.getLinkedAccountDescription()
                  }
                ]
          );
        } else if (!row.isLinking === true) {
          items.push([getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id))]);
          if (!row.parentId) {
            items.push([contextMenuItemType.RECONNECT], [contextMenuItemType.TRACK_MANUALLY]);
          } else {
            items.push([contextMenuItemType.RECONNECT]);
          }
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            },
            getLinkedAccountDescriptionMenuItem(row.linkType)
          ]);
        }
        return items;
      } else if (row.isLinking === true) {
        if (!row.linkingAccountsData === true) {
          return [[contextMenuItemType.INSERT_ABOVE, ...removeOption]];
        } else {
          return [
            [contextMenuItemType.INSERT_ABOVE],
            [
              {
                ...contextMenuItemType.UNLINK,
                label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove"
              }
            ]
          ];
        }
      } else if (row.isLinked === false) {
        const ticker = getTickerUsingId(row.valueTickerId);
        let basicOptions;
        if (ticker.type === "fiat" && ticker.subType !== tickerSubTypes.PRECIOUS_METALS) {
          basicOptions = [
            [
              contextMenuItemType.INSERT_ABOVE,
              row.isUpdated === true ? contextMenuItemType.MARK_UNUPDATED : contextMenuItemType.MARK_UPDATED
            ],
            [contextMenuItemType.MOVE_ROW, ...removeOption]
          ];
        } else {
          basicOptions = [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.MOVE_ROW, ...removeOption]];
        }
        if (row.wasRowEverComplete === false) {
          basicOptions.splice(2, 2);
        }

        const items = basicOptions;
        if (
          ticker.type !== tickerTypes.FUND &&
          ticker.type !== tickerTypes.STOCK &&
          ticker.type !== tickerTypes.CRYPTO &&
          ticker.type !== tickerTypes.BOND &&
          ticker.type !== tickerTypes.DERIVATIVE &&
          ticker.type !== tickerTypes.INDEX &&
          ticker.subType !== tickerSubTypes.PRECIOUS_METALS &&
          this.props.isReadOnlyWlClient === false
        ) {
          items.push([contextMenuItemType.CONNECT_PORTFOLIOS]);
        }
        const rowDescriptionItem = getTickerDescriptionContextMenuItem(ticker);
        if (!rowDescriptionItem === false) {
          items.push([rowDescriptionItem]);
        }
        return items;
      }
    };
    rowData.isDragEnabled = isViewOnlyMode === false;
    rowData.defaultCellIndexToSelect = 1;
    return rowData;
  }

  handleChange = newGridData => {
    this.setState({ ...this.state, gridData: newGridData });
  };

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    let rowValue = updatedRow.cells[2].value;
    if (isAssetCustodian(updatedRow.linkContainer, updatedRow.linkType)) {
      rowValue = -rowValue;
    }

    const portfolioTicker = getTickerUsingShortName(this.props.portfolio.currency);

    this.props.updateCustodian(isFirstEdit, updatedRow.id, {
      name: updatedRow.cells[1].value,
      value: rowValue,
      valueTickerId: updatedRow.cells[2].getTickerId() || portfolioTicker.id,
      valueExchangeRate: updatedRow.cells[2].exchangeRateDetails,
      valueInvalidInputText: updatedRow.cells[2].invalidInputText,
      valueTickerInfo: updatedRow.cells[2].tickerInfo,
      currency: updatedRow.cells[2].currency,
      star: !updatedRow.cells[0].value ? 0 : 1,
      past: updatedRow.isUpdated === true ? 0 : 1,
      sortKey: updatedRow.sortKey,
      isCompleted: updatedRow.wasRowEverComplete === true ? 1 : 0
    });
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {
    const sectionId = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].id;
    this.props.insertCustodian(this.props.portfolio.id, sectionId, row.id, row.sortKey);
  }

  handleRemoveRow(sheetIndex, sectionIndex, rowIndex, row) {
    if (row.isEmpty() === true && !row.tsModified === true) {
      this.props.deleteLocalCustodian(this.props.portfolio.id, row.id);
      return;
    }
  }

  handleSectionMove(sourceSheetId, destinationSheetId, updatedSection) {
    this.props.updateSection(this.props.portfolio.id, sourceSheetId, updatedSection.id, {
      sheetId: destinationSheetId
    });
  }

  handleSectionUpdate(sheetIndex, sectionIndex, updatedSection) {
    const sheetId = this.state.gridData.sheets[sheetIndex].id;
    this.props.updateSection(this.props.portfolio.id, sheetId, updatedSection.id, {
      name: updatedSection.name,
      sortKey: updatedSection.sortKey,
      expanded: updatedSection.isCollapsed === true ? 0 : 1,
      columnSortKey: updatedSection.columnSortKey,
      columnSortOrder: updatedSection.columnSortOrder
    });
  }

  handleAddNewSection(sheetIndex, sectionIndex, section) {
    const sheetId = this.state.gridData.sheets[sheetIndex].id;
    this.props.insertSection(this.props.portfolio.id, sheetId, section);
  }

  handleRemoveSection(sheetIndex, sectionIndex, section) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    this.props.archiveSection(sheet.id, section.id);
  }

  handleSheetUpdate(sheetIndex, updatedSheet) {
    this.props.updateSheet(this.props.portfolio.id, updatedSheet.id, {
      name: updatedSheet.name,
      sortKey: updatedSheet.sortKey,
      updateFrequency: updatedSheet.updateFrequency.value
    });
  }

  handleRemoveSheet(sheetIndex, sheet) {
    const isLastSheet = this.state.gridData.sheets.length === 0;

    this.props.archiveSheet(sheet.id, isLastSheet);
  }

  handleReorderRowsForSections(sheetIndex, sourceSection, destinationSection, updatedRow) {
    this.props.moveCustodian(sourceSection.id, destinationSection.id, updatedRow.id, updatedRow.sortKey);
  }

  handleDetailsClick(sheetIndex, sectionIndex, rowIndex, row) {
    this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row);
  }

  handleDetailsClose = (sheetIndex, sectionIndex, rowIndex, row, defaultTab) => {
    this.isUpdateClicked = true;
  };

  handleToggleSectionStarStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianStarStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSheetStarStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianStarStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleToggleSheetUpdatedStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleAddNewSheet(sheetIndex, newSheet, addCategory = category.DEBT) {
    this.props.insertSheet(addCategory, newSheet);
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.LINK.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.UNLINK.id) {
      const dialogExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];
      const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true, menuItem.isRemoveFlow)
        .custodians.length;
      if (dialogExceptionList.includes(row.linkType) === false && custodiansWithSameAccount > 0) {
        this.setState({
          showUnlinkAllConnectionsDialog: true,
          unlinkDialogRow: row,
          unlinkDialogCount: custodiansWithSameAccount,
          isRemoveFlow: menuItem.isRemoveFlow
        });
      } else {
        this.props.unlinkAccountWithCustodian(row.id, true, () => {
          this.props.getConnectivityCenterData();
        });
      }
    } else if (menuItem.id === contextMenuItemType.RECONNECT.id) {
      LinkAccountComponentExports.reconnect(
        this.props.history,
        this.props.location,
        row.id,
        menuItem.doNotShowReconnectOptionsDialog
      );
    } else if (menuItem.id === contextMenuItemType.REAUTH.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id, linkAccountMode.EDIT);
    } else if (menuItem.id === contextMenuItemType.UPDATE_VALUES.id) {
      const lastRefreshRequest = this.custodianRefreshTsMap[row.id];
      if (
        row.linkType !== accountLinkingService.PLAID &&
        row.linkType !== accountLinkingService.LEAN &&
        !isCryptoLinkingService(row.linkType) &&
        lastRefreshRequest &&
        new Date().getTime() - lastRefreshRequest <= 2 * 60 * 1000
      ) {
        this.setState({
          showReconnectDialog: true,
          reconnectDialogRow: row
        });
        this.custodianRefreshTsMap[row.id] = 0;
      } else {
        this.custodianRefreshTsMap[row.id] = new Date().getTime();
      }
      LinkAccountComponentExports.refreshAccountValues(
        this.props.history,
        this.props.location,
        row.id,
        () => null,
        () => null,
        {
          force: 1
        }
      );
      this.isUpdateClicked = true;
    } else if (menuItem.id === contextMenuItemType.REMOVE.id || menuItem.id === contextMenuItemType.ARCHIVE.id) {
      const sheet = this.state.gridData.sheets[sheetIndex];
      const section = sheet.sections[sectionIndex];

      if (section.rows.length === 1) {
        if (row.isComplete()) {
          return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row);
        }
        return;
      }

      if (!isCustodianAddedToday(row.id) || !row.parentId === false) {
        return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row, () => {
          const sectionId = section.id;
          this.props.archiveCustodian(sectionId, row.id, row.isEmpty(), { isArchiveOnDelete: true });
        });
      } else if (row.isComplete()) {
        return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row, () => {
          this.props.deleteCustodian(row.id, row.isEmpty());
        });
      } else {
        this.props.deleteCustodian(row.id, row.isEmpty());
      }
    } else if (menuItem.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      PickLinkAccountCountry.show(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.MOVE_ROW.id) {
      const custodian = custodianSelector(
        store.getState(),
        this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].id
      );
      this.setState({ moveDialogForData: { item: custodian, itemType: moveItemType.CUSTODIAN } });
    } else if (menuItem.id === contextMenuItemType.LINK_DEFI_LOANS.id) {
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        row.id,
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (menuItem.id === contextMenuItemType.MANAGE_ACCOUNTS.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id, linkAccountMode.MANAGE);
    } else if (menuItem.id === contextMenuItemType.BAD_CONNECTION.id) {
      LinkAccountComponentExports.resolveLinkError(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.START_OVER.id) {
      if (menuItem.reconnectInfo) {
        LinkAccountComponentExports.reconnect(this.props.history, this.props.location, row.id, true);
      } else {
        const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true).custodians.length;
        this.setState({
          showDisconnectAndTrackManuallyDialog: true,
          unlinkDialogCount: custodiansWithSameAccount,
          rowToDisconnectAndTrackManually: row
        });
      }
    } else if (menuItem.id === contextMenuItemType.TRACK_MANUALLY.id) {
      const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true).custodians.length;
      this.setState({
        showDisconnectAndTrackManuallyDialog: true,
        unlinkDialogCount: custodiansWithSameAccount,
        rowToDisconnectAndTrackManually: row
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_PORTFOLIOS.id) {
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${hashParams.ID}=${row.id}`
      });
    }
  }

  handleRemoveCompletedRow = (sheetIndex, sectionIndex, rowIndex, row, callback = () => null) => {
    const shouldDisconnect = () => {
      const disconnectExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];

      if (
        row.isLinked === true &&
        disconnectExceptionList.includes(row.linkType) === false &&
        isCryptoLinkingService(row.linkType) === true &&
        row.linkContainer !== "nft"
      ) {
        return true;
      }
      return false;
    };
    if (shouldDisconnect()) {
      this.deleteConfirmationPromise = new DeferredPromise();
      setTimeout(() => {
        this.deleteConfirmationPromise.resolve(false);
        this.handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, {
          ...contextMenuItemType.UNLINK,
          label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
          isRemoveFlow: true
        });
      }, 0);
      return this.deleteConfirmationPromise;
    }

    setTimeout(async () => {
      var deleteType = row.isLinked ? 2 : 1;
      let rowNameToDelete = custodianSelector(store.getState(), !row.parentId === false ? row.parentId : row.id).name;
      if (row.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        rowNameToDelete = capitalizeStringWithSpaces(
          portfolioSelector(store.getState(), row.linkProviderAccountId)?.name
        );
      }
      const rowIdToDelete = !row.parentId === false ? row.parentId : row.id;
      var numberOfRowsToDelete = 1;

      if (!row.parentId === false) {
        deleteType = 3;

        numberOfRowsToDelete = custodiansWithSameParentIdSelector(
          store.getState(),
          this.props.portfolio.id,
          row.parentId
        ).length;
      } else if (row.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        deleteType = 4;
        numberOfRowsToDelete = custodiansLinkedToSameAccount(row.id).portfolioCustodiansMap[this.props.portfolio.id]
          .length;
      }

      this.setState({
        deleteType: deleteType,
        numberOfRowsToDelete: numberOfRowsToDelete,
        rowNameToDelete: rowNameToDelete,
        rowIdToDelete: rowIdToDelete
      });
      const isConfirm = await this.deleteConfirmationPromise.catch(err => null);
      if (isConfirm) {
        callback();
      }
    });

    this.deleteConfirmationPromise = new DeferredPromise();
    return this.deleteConfirmationPromise;
  };

  handleDeleteConfirmPositiveButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: "",
      rowIdToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(true);
  };

  handleDeleteConfirmNegativeButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: "",
      rowIdToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(false);
  };

  handleGridContextMenuSelection(menuItem) {
    if (menuItem.id === contextMenuItemType.ARCHIVED.id) {
      ArchivedComponent.show(this.props.history, this.props.location);
    }
  }

  handleSheetContextMenuSelection(sheetIndex, menuItem) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    if (menuItem.id === contextMenuItemType.DOWNLOADEXCEL.id) {
      ApiClient.getSheetDownloadUrl(this.props.portfolio.id, sheet.id)
        .then(url => {
          downloadFile(url, sheet.name);
        })
        .catch(apiError => {});
    } else if (menuItem.id === contextMenuItemType.AUTO_UNUPDATE.id) {
      SheetUpdateFrequencyDialog.show(this.props.history, this.props.location, sheet.id);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSheetUpdatedStatus(sheetIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSheetStarStatus(sheetIndex);
    } else if (menuItem.id === contextMenuItemType.MOVE_SHEET.id) {
      this.setState({
        moveDialogForData: {
          item: sheetSelector(store.getState(), this.state.gridData.sheets[sheetIndex].id),
          itemType: moveItemType.SHEET
        }
      });
    }
  }

  handleAddNewContextMenuSelection = (sheetIndex, sectionIndex, menuItem) => {
    let section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

    if (!section) {
      const { sections } = this.state.gridData.sheets[sheetIndex];
      section = this.getEmptySection(sections.length, getSortKeyBetween(sections[sections.length - 1].sortKey, null));
      section.id = getUuid();
      this.state.gridData.sheets[sheetIndex].sections.push(section);
      this.handleChange(this.state.gridData);

      this.handleAddNewSection(sheetIndex, sectionIndex, section);
    }

    this.handleSectionContextMenuSelection(sheetIndex, sectionIndex, menuItem);
  };

  handleSectionContextMenuSelection(sheetIndex, sectionIndex, menuItem) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

    if (!menuItem) {
      this.gridDataRef.current.handleAddNewRow(sheetIndex, sectionIndex, section.rows.length, undefined, true, true);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSectionStarStatus(sheetIndex, sectionIndex);
    } else if (menuItem.id === contextMenuItemType.LINK.id) {
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(section)
      );
    } else if (menuItem.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      PickLinkAccountCountry.show(this.props.history, this.props.location, this.getRowIdToConnectForSection(section));
    } else if (menuItem.id === contextMenuItemType.MOVE_SECTION.id) {
      this.setState({
        moveDialogForData: {
          item: sectionSelector(store.getState(), this.state.gridData.sheets[sheetIndex].sections[sectionIndex].id),
          itemType: moveItemType.SECTION
        }
      });
    } else if (menuItem.id === contextMenuItemType.LINK_DEFI_LOANS.id) {
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(section),
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (menuItem.id === contextMenuItemType.CONNECT_PORTFOLIOS.id) {
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${
          hashParams.ID
        }=${this.getRowIdToConnectForSection(section)}`
      });
    }
  }

  handleMoveDialogDismiss() {
    this.setState({ moveDialogForData: null });
  }

  handleLinkErrorClick(sheetIndex, sectionIndex, rowIndex, row) {
    return true;
  }

  getRowIdToConnectForSection(section) {
    const firstEmptyRow = section.getFirstEmptyRow();
    var rowId = !firstEmptyRow === true ? getUuid() : firstEmptyRow.id;
    if (!firstEmptyRow === true) {
      this.props.insertCustodianAtEndOfSection(this.props.portfolio.id, section.id, rowId, 1, { isAddedForConnect: 1 });
    }
    return rowId;
  }

  getEmptyGridView() {
    return (
      <EmptyGridViewDebtsComponent
        isReadOnlyWlClient={this.props.isReadOnlyWlClient}
        handleInitialTipClick={this.handleInitialTipClick}
        isInternalUser={this.props.isInternalUser}
      />
    );
  }

  handleInitialTipClick(item) {
    const newGridData = this.state.gridData;
    newGridData.autoFocusFirstCell = !item === true;
    this.setState({ ...this.state, gridData: newGridData });

    if (!item === true) {
      this.props.updateUserPreferences({
        isDebtInitialTipShownForPortfolio: [
          ...this.props.userPreferences.isDebtInitialTipShownForPortfolio,
          this.props.portfolio.id
        ]
      });
    } else if (item === contextMenuItemType.LINK) {
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])
      );
    } else if (item === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY) {
      PickLinkAccountCountry.show(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])
      );
    } else if (item === contextMenuItemType.LINK_CRYPTO) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0]),
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (item === contextMenuItemType.CONNECT_PORTFOLIOS) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${
          hashParams.ID
        }=${this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])}`
      });
    }

    this.setState({ hideEmptyState: true });
  }

  handleUnlinkAllConnectionsDialogPositiveButtonClick(e) {
    const custodianid = this.state.unlinkDialogRow.id;

    this.props.unlinkAllConnectedCustodians(custodianid, { isRemoveFlow: this.state.isRemoveFlow });
    this.setState({ showUnlinkAllConnectionsDialog: false, unlinkDialogRow: null, isRemoveFlow: false });
  }

  handleUnlinkAllConnectionsDialogOnDismiss() {
    this.setState({
      showUnlinkAllConnectionsDialog: false,
      unlinkDialogRow: null,
      isRemoveFlow: false
    });
  }

  handleReconnectDialogPositiveButtonClick() {
    if (
      this.state.reconnectDialogRow.linkType !== accountLinkingService.ZILLOW &&
      this.state.reconnectDialogRow.linkType !== accountLinkingService.CARS &&
      this.state.reconnectDialogRow.linkType !== accountLinkingService.DOMAINS
    ) {
      LinkAccountComponentExports.reconnect(this.props.history, this.props.location, this.state.reconnectDialogRow.id);
    }
    this.handleReconnectDialogDismissButtonClick();
  }

  handleReconnectDialogNegativeButtonClick() {
    LinkAccountComponentExports.refreshAccountValues(
      this.props.history,
      this.props.location,
      this.state.reconnectDialogRow.id,
      () => null,
      () => null,
      {
        force: 1
      }
    );
    this.handleReconnectDialogDismissButtonClick();
    this.isUpdateClicked = true;
  }

  handleReconnectDialogDismissButtonClick() {
    this.setState({ showReconnectDialog: false, reconnectDialogRow: null });
  }

  handleRowClick(sheetIndex, sectionIndex, rowIndex, row) {
    if (row.isLinking === true) {
      if (!row.linkingAccountsData === false) {
        LinkAccountComponentExports.selectAccounts(
          this.props.history,
          this.props.location,
          row.id,
          row.linkingAccountsData.category
        );
        this.props.dismissToastAction();
      } else {
        LinkAccountCommonExports.reopen(row.id);
        this.props.dismissToastAction();
      }
    }
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === true) {
      setTimeout(() => {
        this.props.showToastTip("TIP", i18n.t("stockNotSupportedTip"), null, 10000);
      }, 500);
    } else {
      this.props.fetchCustodianTickerDetails(row.id, false);
    }
  }

  shouldShowEmptyState() {
    if (this.isReadOnly()) {
      return this.state.gridData.isEmpty() === true;
    }
    return this.props.isInitialTipShown === false && this.state.gridData.isEmpty() === true;
  }

  handleAddNewFooterButtonClick = (sheetIndex, sectionIndex, callback) => {
    if (!this.addModalRef.current.isOpen()) {
      this.addModalRef.current.show(sheetIndex, sectionIndex, callback);
    }
  };

  handleDisconnectAndTrackManuallyDialogPositiveButtonClick(e) {
    const custodianid = this.state.rowToDisconnectAndTrackManually.id;

    this.props.unlinkAndManuallyTrackAllConnectedCustodians(custodianid);
    this.setState({ showDisconnectAndTrackManuallyDialog: false, rowToDisconnectAndTrackManually: null });
  }

  handleDisconnectAndTrackManuallyDialogOnDismiss() {
    this.setState({ showDisconnectAndTrackManuallyDialog: false, rowToDisconnectAndTrackManually: null });
  }

  getReconnectDialogDescription(linkType) {
    if (linkType === accountLinkingService.ZILLOW) {
      return i18n.t("reconnectDialog.homes.description");
    } else if (linkType === accountLinkingService.CARS) {
      return i18n.t("reconnectDialog.cars.description");
    } else if (linkType === accountLinkingService.DOMAINS) {
      return i18n.t("reconnectDialog.domains.description");
    } else {
      return i18n.t("reconnectDialog.description");
    }
  }

  render() {
    const gridData = this.state.gridData;
    const shouldShowEmptyState = this.shouldShowEmptyState();

    if (shouldShowEmptyState === true) {
      this.isComingFromEmptyScreenRef.current = true;
      return (
        <EmptyGridMessage
          title={i18n.t("debtsComponent.emptyTitle")}
          customView={this.getEmptyGridView()}
          onClick={this.handleInitialTipClick}
          isHidden={this.state.hideEmptyState}
          isReadOnly={this.isReadOnly()}
        />
      );
    }

    const showUnlinkAllConnectionsDialog = this.state.showUnlinkAllConnectionsDialog;
    const showDisconnectAndTrackManuallyDialog = this.state.showDisconnectAndTrackManuallyDialog;

    if (this.props.detailsOnlyRef) {
      return (
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.props.detailsOnlyRef}
          category={category.DEBT}
        />
      );
    }

    let archiveOrRemove = "Remove";
    if (this.state.rowIdToDelete) {
      archiveOrRemove = isCustodianAddedToday(this.state.rowIdToDelete) ? "Remove" : "Archive";
    }

    return (
      <Container>
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.CustodianDetailsWrapperRef}
          category={category.DEBT}
          handleDetailsClose={this.handleDetailsClose}
        />
        <Grid
          title={i18n.t("debtsComponent.title")}
          ref={this.gridDataRef}
          portfolio={this.props.portfolio}
          gridData={gridData}
          getEmptyRow={this.getEmptyRow}
          getEmptySection={this.getEmptySection}
          getEmptySheet={this.getEmptySheet}
          onSectionMove={this.handleSectionMove}
          onChange={this.handleChange}
          onRowUpdate={this.handleRowUpdate}
          onAddNewRow={this.handleAddNewRow}
          onRemoveRow={this.handleRemoveRow}
          onSectionUpdate={this.handleSectionUpdate}
          onReorderRows={this.handleReorderRowsForSections}
          onAddNewSection={this.handleAddNewSection}
          onRemoveSection={this.handleRemoveSection}
          onSheetUpdate={this.handleSheetUpdate}
          onRemoveSheet={this.handleRemoveSheet}
          onDetailsClick={this.handleDetailsClick}
          onLinkErrorClick={this.handleLinkErrorClick}
          onAddNewSheet={this.handleAddNewSheet}
          onRowContextMenuSelection={this.handleRowContextMenuSelection}
          onSheetContextMenuSelection={this.handleSheetContextMenuSelection}
          onGridContextMenuSelection={this.handleGridContextMenuSelection}
          onSectionContextMenuSelection={this.handleSectionContextMenuSelection}
          userPreferences={this.props.userPreferences}
          updateUserPreferences={this.props.updateUserPreferences}
          onAddNewFooterButtonClick={this.handleAddNewFooterButtonClick}
          showToastTip={this.props.showToastTip}
          onRowClick={this.handleRowClick}
          onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
          location={this.props.location}
          history={this.props.history}
          category={category.DEBT}
        />
        {showUnlinkAllConnectionsDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? `${i18n.t("unlinkPortfolio")}?`
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.title")
                : i18n.t("unlinkAllConnectionsDialog.archive.title")
            }
            description={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? i18n.t("unlinkPortfolioDescription")
                : this.state.unlinkDialogCount > 1
                ? isCustodianAddedToday(this.state.unlinkDialogRow.id)
                  ? i18n.t("unlinkAllConnectionsDialog.multiple")
                  : i18n.t("unlinkAllConnectionsDialog.archive.multiple")
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.single")
                : i18n.t("unlinkAllConnectionsDialog.archive.single")
            }
            positiveButtonTitle={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? i18n.t("unlink")
                : this.state.unlinkDialogCount > 1
                ? isCustodianAddedToday(this.state.unlinkDialogRow.id)
                  ? i18n.t("unlinkAllConnectionsDialog.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                  : i18n.t("unlinkAllConnectionsDialog.archive.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.primaryButton")
                : i18n.t("unlinkAllConnectionsDialog.archive.primaryButton")
            }
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleUnlinkAllConnectionsDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleUnlinkAllConnectionsDialogOnDismiss}
          />
        )}
        {showDisconnectAndTrackManuallyDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("disconnectAndTrackManuallyDialog.title")}
            description={i18n.t("disconnectAndTrackManuallyDialog.description")}
            positiveButtonTitle={
              this.state.unlinkDialogCount > 1
                ? i18n.t("disconnectAndTrackManuallyDialog.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                : i18n.t("disconnectAndTrackManuallyDialog.primaryButton")
            }
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleDisconnectAndTrackManuallyDialogOnDismiss}
            handlePositiveButtonClick={this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleDisconnectAndTrackManuallyDialogOnDismiss}
          />
        )}
        {this.state.showReconnectDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("reconnectDialog.title")}
            description={this.getReconnectDialogDescription(this.state.reconnectDialogRow.linkType)}
            positiveButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? i18n.t("ok")
                : i18n.t("reconnect")
            }
            negativeButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? null
                : i18n.t("updateValues")
            }
            tertiaryButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? null
                : i18n.t("goBackLink")
            }
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleReconnectDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleReconnectDialogNegativeButtonClick}
            handleTertiaryButtonClick={this.handleReconnectDialogDismissButtonClick}
          />
        )}
        {this.state.deleteType === 1 && (
          <ConfirmationDialog
            title={i18n
              .t("removeRowTitle")
              .replace(/%category%/, category.DEBT)
              .replace("Remove", archiveOrRemove)}
            description={i18n
              .t("removeCompleteDesc")
              .replace(/%custodian_name%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 2 && (
          <ConfirmationDialog
            title={i18n
              .t("removeRowTitle")
              .replace(/%category%/, category.DEBT)
              .replace("Remove", archiveOrRemove)}
            description={i18n
              .t("removeLinkedDesc")
              .replace(/%custodian_name%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 3 && (
          <ConfirmationDialog
            title={
              i18n
                .t("removeRows")
                .replace(/%s1%/, this.state.numberOfRowsToDelete)
                .replace("Remove", archiveOrRemove) + "?"
            }
            description={i18n
              .t("removeHoldingRow.description")
              .replace(/%s1%/, this.state.numberOfRowsToDelete)
              .replace(/%s2%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n
              .t("removeRows")
              .replace(/%s1%/, this.state.numberOfRowsToDelete)
              .replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 4 && (
          <ConfirmationDialog
            title={`${i18n.t("removeLinkedPortfolioRows").replace("Remove", archiveOrRemove)}?`}
            description={i18n
              .t("removeLinkedPortfolioRows.description")
              .replace("remove", archiveOrRemove.toLowerCase())
              .replace("%s1%", this.state.rowNameToDelete)}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        <AddModal
          ref={this.addModalRef}
          isReadOnlyWlClient={this.props.isReadOnlyWlClient}
          handleMenuItemClick={this.handleAddNewContextMenuSelection}
          isEmpty={this.state.gridData.isEmpty()}
          gridData={gridData}
          isComingFromEmptyScreenRef={this.isComingFromEmptyScreenRef}
        />
        <GridPageHandleSheetIdHash isComingFromEmptyScreenRef={this.isComingFromEmptyScreenRef} />
        {!this.state.moveDialogForData === false && (
          <MoveItemDialog
            item={this.state.moveDialogForData.item}
            itemType={this.state.moveDialogForData.itemType}
            defaultCategory={category.DEBT}
            onDismiss={this.handleMoveDialogDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const portfolio = currentPortfolioSelector(state);
  return {
    lastDashboardUpdateTs: lastDashboardUpdateTsSelector(state),
    dashboardEntitesToUpdate: dashboardEntitesToUpdateSelector(state),
    portfolio,
    portfolioCurrency: currentPortfolioCurrencySelector(state),
    userPreferences: userPreferencesSelector(state),
    userCountryName: userCountryNameSelector(state),
    isInitialTipShown: isInitialTipShown(state, currentPortfolioSelector(state), category.DEBT),
    accountCurrentTs: accountCurrentTsSelector(state),
    accountEndTs: accountEndTsSelector(state),
    accountGraceTs: accountGraceTsSelector(state),
    accountStartTs: accountStartTsSelector(state),
    accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
    accountClientCurrentTs: accountClientCurrentTsSelector(state),
    accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state),
    isReadOnlyWlClient: isReadOnlyWlClient(state),
    siteConfig: siteConfigSelector(state),
    portfolioChangeData: currentPortfolioChangeDataSelector(state),
    maskAllValues: userMaskAllValuesSelector(state),
    connectivityCenterDataForPortfolio: connectivityCenterDataForPortfolioSelector(state, portfolio.id),
    getOwnershipValueForCustodian: getOwnershipValueForCustodian.bind(state),
    isInternalUser: isInternalUserSelector(state)
  };
};

const mapDispatchToProps = {
  updateCustodian: updateCustodian,
  insertCustodian: insertCustodian,
  insertCustodianAtEndOfSection: insertCustodianAtEndOfSection,
  archiveCustodian: archiveCustodian,
  deleteCustodian: deleteCustodian,
  deleteLocalCustodian: deleteCustodianAction,
  insertSection: insertSection,
  updateSection: updateSection,
  updateSheet: updateSheet,
  bulkChangeCustodianStarStatus: bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus: bulkChangeCustodianUpdatedStatus,
  archiveSection: archiveSection,
  archiveSheet: archiveSheet,
  insertSheet: insertSheet,
  unlinkAccountWithCustodian: unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians: unlinkAllConnectedCustodians,
  updateUserPreferences: updateUserPreferences,
  moveCustodian: moveCustodian,
  showToastTip: showToastTip,
  dismissToastAction: dismissToastAction,
  fetchCustodianTickerDetails: fetchCustodianTickerDetails,
  fetchPortfolioChangeData: fetchPortfolioChangeData,
  getConnectivityCenterData: getConnectivityCenterData,
  unlinkAndManuallyTrackAllConnectedCustodians: unlinkAndManuallyTrackAllConnectedCustodians
};

export default isMobileDevice
  ? MobileDebtsWrapperComponent
  : connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(DebtsComponent));
