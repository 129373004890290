import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { useSelector, useDispatch } from "react-redux";
import { userPreferencesSelector, updateUserPreferences } from "@kubera/common";

import requestIdleCallback from "utilities/requestIdleCallback";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";

const ValueItem = styled.div`
  display: ${props => (props.isHidden === true ? "none" : "flex")};
  flex-direction: column;
  margin-top: 30px;
  margin-left: 30px;
  padding-left: 6px;
  border-left: ${props => `6px solid ${props.color}`};
  cursor: pointer;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
`;

const ValueChangeLabel = styled(ChangeLabel)`
  width: fit-content;
  margin-top: -4px;
  margin-bottom: -4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const NetworthChartValueItem = ({
  index,
  dataForScenario,
  groupedData,
  colorPallete,
  selectedScenarios,
  portfolioNetWorth,
  currentLastDataPointIndex,
  preventAnimation,
  getEndValue = () => null,
  handleValueItemClick = () => null
}) => {
  const dispatch = useDispatch();
  const planningChartClickTipRef = useRef();

  const userPreferences = useSelector(userPreferencesSelector);

  useEffect(() => {
    if (index > 0) return () => null;

    if (!!userPreferences.planningChartClickTip === false && planningChartClickTipRef.current) {
      requestIdleCallback(() => {
        planningChartClickTipRef.current.show(i18n.t("planningChartClickTip"), 30, 20, () => {
          dispatch(updateUserPreferences({ planningChartClickTip: true }));
        });
      });
    } else if (
      userPreferences.planningChartClickTip === true &&
      planningChartClickTipRef.current &&
      planningChartClickTipRef.current.isVisible()
    ) {
      requestIdleCallback(() => {
        planningChartClickTipRef.current.dismiss();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userPreferences.planningChartClickTip]);

  return (
    <ValueItem
      color={colorPallete[index].color}
      isHidden={selectedScenarios.includes(dataForScenario.scenario.id) === false}
      onClick={e => handleValueItemClick(e, groupedData, index)}
      id={`networth-chart-component-${index}`}
    >
      <ValueLabel
        currency={dataForScenario.currency}
        value={getEndValue(groupedData, index, currentLastDataPointIndex)}
        currencyFontSize={16}
        valueFontSize={30}
        fontWeight={400}
        height={"36px"}
        showZero={true}
        preventAnimation={preventAnimation === true}
      />
      <ValueChangeLabel
        currency={dataForScenario.currency}
        startValue={portfolioNetWorth}
        endValue={getEndValue(groupedData, index, currentLastDataPointIndex)}
        preventAnimation={preventAnimation === true}
      />
      {index === 0 && (
        <ToolTip
          ref={planningChartClickTipRef}
          targetId={`networth-chart-component-0`}
          align={toolTipAlignment.BOTTOM_LEFT}
        />
      )}
    </ValueItem>
  );
};

export default NetworthChartValueItem;
