import React, { useEffect } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  accountCreationTsSelector,
  getMonthFromDate,
  updateUserPreferences,
  userPreferencesSelector
} from "@kubera/common";
import { GAWrapperEvent } from "utilities/GAWrapper";
import { ANALYTICS } from "analytics";

import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import twitterShareIcon from "assets/images/twitter_share_icon.png";
import fbShareIcon from "assets/images/fb_share_icon.png";
import linkedinShareIcon from "assets/images/linkedin_share_icon.png";

const ThanksSubscriptionDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 45px 50px;
  z-index: 2;
`;

const ThanksModalTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 18px;
`;

const ThanksModalMsg = styled.div`
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 38px;
  white-space: pre-wrap;
`;

const ThanksBtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ThanksSubscriptionBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
  min-width: 169px;
`;

const SocialImages = styled.img`
  width: ${props => (props.type === "linkedin" ? "30px" : "32px")};
  margin-right: 15px;
`;

const AccountThanksModal = ({ isOpen = false, isOpenForSubscriptionStates = false, onDismiss = () => null }) => {
  const dispatch = useDispatch();

  const accountCreationTs = useSelector(accountCreationTsSelector);
  const userPreferences = useSelector(userPreferencesSelector);

  const date = new Date(accountCreationTs * 1000);
  const sinceTxt = `${getMonthFromDate(date)}+${date.getFullYear()}`;

  useEffect(() => {
    if (isOpen) {
      if (userPreferences.isUserSubscribedOnce) {
        GAWrapperEvent({
          category: ANALYTICS.CATEGORY.ALL,
          action: ANALYTICS.ACTION.SUBSCRIBED_REPEAT
        });
      } else {
        dispatch(
          updateUserPreferences({
            isUserSubscribedOnce: true
          })
        );
        GAWrapperEvent({
          category: ANALYTICS.CATEGORY.ALL,
          action: ANALYTICS.ACTION.SUBSCRIBED
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (isOpen && isOpenForSubscriptionStates) {
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <ThanksSubscriptionDialog>
          <ThanksModalTitle>{i18n.t("subscription.thankyou")}</ThanksModalTitle>
          <ThanksModalMsg>
            {i18n.t("subscription.thanktxtbeforeshare")}
            <a
              target="_blank"
              href={`https://twitter.com/intent/tweet?text=Check+out+Kubera.com+-+a+simple+%26+modern+tool+to+track+your+assets+-+digital+%28crypto%29+and+traditional+%28stocks%2C+bonds%2C+real+estate%29.+Connects+to+international+bank+%26+brokerage+accounts.+Supports+all+major+currencies.+%23iamKubera+since+${sinceTxt}+%40KuberaApp`}
              rel="noopener noreferrer"
            >
              <SocialImages src={twitterShareIcon} alt="Twitter Share" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/sharer/sharer.php?u=http://kubera.com/"
              rel="noopener noreferrer"
            >
              <SocialImages src={fbShareIcon} alt="Facebook Share" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A//www.kubera.com"
              rel="noopener noreferrer"
            >
              <SocialImages src={linkedinShareIcon} alt="LinkedIn Share" type="linkedin" />
            </a>
            <span
              dangerouslySetInnerHTML={{
                __html: i18n.t("subscription.thanktxtaftershare")
              }}
            />
          </ThanksModalMsg>
          <ThanksBtnBlock>
            <ThanksSubscriptionBtn title={i18n.t("subscription.welcomebtn")} onClick={onDismiss} />
          </ThanksBtnBlock>
        </ThanksSubscriptionDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default AccountThanksModal;
