import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalValues, hashParams } from "routes";
import { useLocation } from "react-router-dom";

import { getHashParams, resetCustodianDetailsAction, custodianDetailsSelector, useHistory } from "@kubera/common";
import CustodianDetailsComponent from "components/custodian_details/CustodianDetailsComponent";
import CustodianDetailsComponentExports from "components/custodian_details/CustodianDetailsComponentExports";

const CustodianDetailsWrapperComponent = (props, ref) => {
  const dispatch = useDispatch();
  const historyUse = useHistory();
  const locationUse = useLocation();

  const history = props.history || historyUse;
  const location = props.location || locationUse;

  const { handleDetailsClose = () => null } = props;

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.MODAL];
  const id = urlHashParams[hashParams.ID];

  const details = useSelector(custodianDetailsSelector);
  const rowDetails = details ? details.info : null;

  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState(null); // eslint-disable-line no-unused-vars
  const [indexes, setIndexes] = useState(null);

  const getSheetSectionRowIndex = () => {
    if (indexes) {
      return indexes;
    }

    if (!rowDetails) {
      return;
    }

    const { sheets } = props.gridData;
    let sheetIndex = -1;
    let sectionIndex = -1;

    for (let i = 0; i < sheets.length; i++) {
      const sectionI = sheets[i].sections.findIndex(section => section.id === rowDetails.sectionId);

      if (sectionI !== -1) {
        sheetIndex = i;
        sectionIndex = sectionI;
        break;
      }
    }

    if (sectionIndex === -1) {
      return;
    }

    const currentSheet = sheets[sheetIndex];
    const currentSection = currentSheet.sections[sectionIndex];

    const rowIndex = currentSection.rows.findIndex(row => row.id === rowDetails.id);

    return { sheetIndex, sectionIndex, rowIndex };
  };

  const onMoveRowContextSelect = item => {
    const { sheetIndex, sectionIndex, rowIndex } = getSheetSectionRowIndex();

    const { sheets } = props.gridData;
    const currentSheet = sheets[sheetIndex];
    const currentSection = currentSheet.sections[sectionIndex];

    props.handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, currentSection.rows[rowIndex], item);
  };

  useImperativeHandle(ref, () => ({
    show: (sheetIndex, sectionIndex, rowIndex, row, defaultTab = undefined) => {
      CustodianDetailsComponentExports.show(history, location, row.id, undefined, undefined, {
        ...(defaultTab && { hash: `${hashParams.TAB_INDEX}=${defaultTab}` })
      });
      setIndexes({ sheetIndex, sectionIndex, rowIndex });
      setRow(row);
    }
  }));

  useEffect(() => {
    if (modalValue === modalValues.CUSTODIAN_DETAILS) {
      setTimeout(() => {
        setIsOpen(true);
      }, 50);
    }
  }, [modalValue]);

  useEffect(() => {
    if (modalValue !== modalValues.CUSTODIAN_DETAILS) {
      setIsOpen(false);
    }
  }, [modalValue]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        dispatch(resetCustodianDetailsAction());
      }, 10);
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      const { sheetIndex, sectionIndex, rowIndex } = indexes || {};
      setRow(null);
      handleDetailsClose(sheetIndex, sectionIndex, rowIndex, row);
    }

    // eslint-disable-next-line
  }, [isOpen, handleDetailsClose, row]);

  useEffect(() => {
    if (!isOpen) {
      setIndexes(null);
    }
  }, [isOpen]);

  if (isOpen && modalValue === modalValues.CUSTODIAN_DETAILS) {
    return (
      <CustodianDetailsComponent
        custodianId={id}
        handleRowContextMenuSelection={onMoveRowContextSelect}
        category={props.category}
      />
    );
  }

  return null;
};

export default forwardRef(CustodianDetailsWrapperComponent);
