import { css, keyframes } from "styled-components";

const animationSlide = {
  left: keyframes`
    0% {
      transform: translateX(90%);
    }
    100% {
      transform: translateX(0);
    } `,
  right: keyframes`
    0% {
      transform: translateX(-90%);
    }
    100% {
      transform: translateX(0);
    } `
};

const slideAnimation = props =>
  css`
    ${animationSlide[props.direction]} 0.2s ease;
  `;

export default slideAnimation;
