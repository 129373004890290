import React, { useMemo, useEffect, useRef, useCallback, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { hashParams } from "routes";
import {
  currentPortfolioSelector,
  getHashParams,
  getLongEquityData,
  savePluginDataForPluginId,
  siteConfigSelector,
  longEquityDataSelector,
  portfolioTotalForCategory,
  portfolioCashOnHand,
  getSymbolForTickerUsingShortName,
  pluginDataMapSelector,
  savePluginDataAction,
  getExchangeRate,
  currentPortfolioCurrencySelector,
  setPluginCurrencyAction,
  pluginCurrencySelector
} from "@kubera/common";

import { category } from "components/dashboard/DashboardComponentExports";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Iframe = styled.iframe`
  display: block;
  border: none;
  width: 996px;
  height: ${({ iframeHeight }) => (iframeHeight ? `${iframeHeight}px` : "100vh")};
`;

const PluginComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const iframeRef = useRef();
  const isUnmountedRef = useRef(false);
  const timeoutRef = useRef();
  const isSaveInProgress = useRef(false);

  const siteConfig = useSelector(siteConfigSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const longEquityData = useSelector(longEquityDataSelector);
  const assetsTotal = useSelector(state => portfolioTotalForCategory(state, undefined, category.ASSET));
  const cashOnHand = useSelector(portfolioCashOnHand);
  const pluginDataMap = useSelector(pluginDataMapSelector);
  const currentPortfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const pluginCurrency = useSelector(pluginCurrencySelector);

  const [iframeHeight, setIframeHeight] = useState(null);

  const portfolioId = portfolio.id;
  const currentPluginId = useMemo(() => getHashParams(location)[hashParams.ID], [location]);
  const reducerKey = `${portfolioId}${currentPluginId}`;
  const currentPluginCurrency = pluginCurrency[reducerKey] ? pluginCurrency[reducerKey] : currentPortfolioCurrency;
  const currentPluginFormCurrency =
    pluginDataMap[reducerKey] && pluginDataMap[reducerKey].currency
      ? pluginDataMap[reducerKey].currency
      : currentPortfolioCurrency;

  const iframeUrl = useMemo(() => {
    const params = getHashParams(location);
    const id = params[hashParams.ID];

    if (Array.isArray(siteConfig.plugin)) {
      const plugin = siteConfig.plugin.find(eachPlugin => eachPlugin.id === id);
      return plugin ? plugin.url : "";
    }

    return "";
  }, [location, siteConfig.plugin]);
  const longEquityDataWithCurrencyValues = useMemo(() => {
    const tempObj = { ...longEquityData[reducerKey] };

    tempObj.longEquityTotal =
      tempObj.longEquityTotal * getExchangeRate(currentPluginCurrency, currentPortfolioCurrency);

    return tempObj;
  }, [longEquityData, currentPluginCurrency, currentPortfolioCurrency, reducerKey]);

  const sendPluginDataToIframe = () => {
    if (!iframeRef.current) return () => null;
    iframeRef.current.contentWindow.postMessage(
      {
        type: "response_get_plugin_data",
        value: {
          ...longEquityDataWithCurrencyValues,
          assetsTotal,
          cashOnHand,
          currencySymbol: getSymbolForTickerUsingShortName(currentPortfolioCurrency)
        },
        exchangeRate: {
          source: currentPluginFormCurrency,
          target: currentPortfolioCurrency,
          value: getExchangeRate(currentPluginFormCurrency, currentPortfolioCurrency)
        },
        pluginData: pluginDataMap[reducerKey]
      },
      "*"
    );
  };

  const handleOnClose = useCallback(
    e => {
      if (!isSaveInProgress.current) {
        return;
      }
      e.preventDefault();
      e.returnValue = "";
      dispatch(savePluginDataForPluginId(portfolioId, currentPluginId, pluginDataMap[reducerKey]));
      return;
    },
    [dispatch, pluginDataMap, reducerKey, portfolioId, currentPluginId]
  );

  useEffect(
    () => () => {
      isUnmountedRef.current = true;
    },
    []
  );

  useEffect(() => {
    const onReceiveMessage = async e => {
      if (e.data && iframeRef.current) {
        // eslint-disable-next-line default-case
        switch (e.data.type) {
          case "get_plugin_data":
            setIframeHeight(e.data.iframeHeight);
            if (longEquityData && longEquityData[reducerKey]) {
              sendPluginDataToIframe();
            }
            await dispatch(getLongEquityData(portfolioId, currentPluginId));
            dispatch(setPluginCurrencyAction(portfolioId, currentPluginId, currentPortfolioCurrency));
            break;
          case "set_plugin_data":
            const pluginDataToSave = {
              ...e.data.value,
              currency: currentPortfolioCurrency
            };
            dispatch(savePluginDataAction(portfolioId, currentPluginId, pluginDataToSave));
            clearTimeout(timeoutRef.current);
            isSaveInProgress.current = true;
            timeoutRef.current = setTimeout(() => {
              isSaveInProgress.current = false;
              dispatch(savePluginDataForPluginId(portfolioId, currentPluginId, pluginDataToSave));
            }, 5000);
            break;
        }
      }
    };

    window.addEventListener("message", onReceiveMessage, false);

    return () => {
      window.removeEventListener("message", onReceiveMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longEquityDataWithCurrencyValues, pluginDataMap, currentPortfolioCurrency]);

  useEffect(() => {
    sendPluginDataToIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longEquityDataWithCurrencyValues, pluginDataMap, currentPluginCurrency, currentPortfolioCurrency]);

  useEffect(() => {
    return () => {
      if (isUnmountedRef.current) {
        dispatch(savePluginDataForPluginId(portfolioId, currentPluginId, pluginDataMap[reducerKey]));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, portfolioId, pluginDataMap]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleOnClose);

    return () => {
      window.removeEventListener("beforeunload", handleOnClose);
    };
  }, [handleOnClose]);

  return (
    <Container>
      <Iframe ref={iframeRef} src={iframeUrl} iframeHeight={iframeHeight} />
    </Container>
  );
};

export default PluginComponent;
