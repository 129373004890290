const vendorsPrefixes = ["ms", "moz", "webkit", "o"];
const vendorCancelAnimationFrame = vendorsPrefixes.find(
  prefix => window[`${prefix}CancelAnimationFrame`] || window[`${prefix}CancelRequestAnimationFrame`]
);

function cancelAnimationFrame(requestId) {
  const cAF = window.cancelAnimationFrame || vendorCancelAnimationFrame || clearTimeout;
  return cAF(requestId);
}

export default cancelAnimationFrame;
