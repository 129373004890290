import React, { Children, useEffect, useState } from "react";

import GridRowsInVisibleArea from "./GridRowsInVisibleArea";
import GridRowsInViewPort from "./GridRowsInViewPortArea";

const GridRowsInViewPortWrapper = ({ children, rowHeight = 40 }) => {
  const arrayChildren = Children.toArray(children);

  const [observer, setObserver] = useState();
  const [draggableId, setDraggableId] = useState(null);

  useEffect(() => {
    // Handling drag event listeners
    const handleBeforeCaptureEvent = event => {
      setDraggableId(event.detail.draggableId);
    };

    const handleDragEndEvent = () => {
      setDraggableId(null);
    };

    window.addEventListener("onBeforeCaptureEvent", handleBeforeCaptureEvent);
    window.addEventListener("onDragEndEvent", handleDragEndEvent);

    // Intersection observer setup
    const intersectionCallback = entries => {
      entries.forEach(entry => {
        entry.target.setItemVisibility(entry.isIntersecting);
      });
    };

    var observerRef;
    const handleSheetsObserver = () => {
      const observerOptions = {
        rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`
      };
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        observerRef = new IntersectionObserver(intersectionCallback, observerOptions);
      }
    };

    handleSheetsObserver();
    setObserver(observerRef);

    return () => {
      observerRef.disconnect();
      window.removeEventListener("onBeforeCaptureEvent", handleBeforeCaptureEvent);
      window.removeEventListener("onDragEndEvent", handleDragEndEvent);
    };
  }, []);

  return (
    <>
      {Children.map(arrayChildren, child =>
        child.props.isWithinVisibleArea || child.props?.entry?.viewId === draggableId ? (
          <GridRowsInVisibleArea {...child.props}>{child}</GridRowsInVisibleArea>
        ) : (
          <GridRowsInViewPort {...child.props} observer={observer} rowHeight={rowHeight}>
            {child}
          </GridRowsInViewPort>
        )
      )}
    </>
  );
};

export default React.memo(GridRowsInViewPortWrapper);
