import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import EmailInput from "components/inputs/EmailInput";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import { addWlManager, isEmailValid, userTypes, userTypeToString } from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import { ReactComponent as DropDownIcon } from "assets/images/expandable_indicator.svg";
import ContextMenu from "components/contextmenu/ContextMenu";

const AddUserDialog = styled(Dialog)`
  position: relative;
  width: 416px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.form`
  display: flex;
  margin: 45px 50px 45px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  font-weight: normal;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const UserRoleContainer = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  cursor: pointer;
`;

const UserRole = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  text-transform: capitalize;
  pointer-events: none;
`;

const UserRoleDropDownIndicator = styled(DropDownIcon)`
  width: 8px;
  height: 8px
  margin-right: 3px;
  transform: rotate(0deg);
  pointer-events: none
`;

const SubmitErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const AddButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

class AddUserDialogComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      nameErrorMessage: null,
      email: "",
      emailErrorMessage: null,
      message: "",
      role: userTypes.MANAGER,
      isSubmitting: false,
      submitErrorMessage: null
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleMessageInputChange = this.handleMessageInputChange.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.handleAddUserButtonClick = this.handleAddUserButtonClick.bind(this);
    this.handleRoleDropDownClick = this.handleRoleDropDownClick.bind(this);
    this.handleDropDownSelection = this.handleDropDownSelection.bind(this);

    this.dropDownContextMenuRef = React.createRef();
  }

  handleOverlayDismiss() {
    this.props.onDialogDismiss();
  }

  handleNameInputChange(e) {
    this.setState({ name: e.target.value, nameErrorMessage: null });
  }

  handleMessageInputChange(e) {
    this.setState({ message: e.target.value });
  }

  handleEmailInputChange(e) {
    this.setState({ email: e.target.value, emailErrorMessage: null });
  }

  handleAddUserButtonClick(e) {
    e.preventDefault();
    if (!this.state.name === true) {
      this.setState({ nameErrorMessage: i18n.t("emptyName") });
      return;
    }
    if (!this.state.email === true) {
      this.setState({ emailErrorMessage: i18n.t("emptyEmail") });
      return;
    }
    if (isEmailValid(this.state.email) === false) {
      this.setState({ emailErrorMessage: i18n.t("wrongEmailFormat") });
      return;
    }

    this.setState({ isSubmitting: true, submitErrorMessage: null });

    this.props.addWlManager(
      this.state.name,
      this.state.email,
      this.state.role,
      user => {
        this.handleOverlayDismiss();
      },
      apiError => {
        this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
      }
    );
  }

  getRoleContextMenuItems() {
    return [
      [
        {
          id: userTypes.MANAGER,
          label: this.getRoleString(userTypes.MANAGER),
          selected: userTypes.MANAGER === this.state.role
        },
        {
          id: userTypes.ADMIN,
          label: this.getRoleString(userTypes.ADMIN),
          selected: userTypes.ADMIN === this.state.role
        }
      ]
    ];
  }

  handleRoleDropDownClick(event) {
    if (this.dropDownContextMenuRef.current.isVisible() === true) {
      this.dropDownContextMenuRef.current.dismiss();
      return;
    }

    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = this.getRoleContextMenuItems();
    this.dropDownContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  }

  handleDropDownSelection(item) {
    this.setState({ role: item.id });
  }

  getRoleString(role) {
    return userTypeToString(role).replace("Account ", "");
  }

  render() {
    const name = this.state.name;
    const nameErrorMessage = this.state.nameErrorMessage;
    const email = this.state.email;
    const emailErrorMessage = this.state.emailErrorMessage;
    const roleString = this.getRoleString(this.state.role);

    const isSubmitting = this.state.isSubmitting;
    const submitErrorMessage = this.state.submitErrorMessage;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <AddUserDialog>
          <Container>
            <Title>{i18n.t("addUser")}</Title>
            <FieldTitle>{i18n.t("fullname")}</FieldTitle>
            <NameField
              placeholder={i18n.t("fullname")}
              value={name}
              errorMessage={nameErrorMessage}
              onChange={this.handleNameInputChange}
              autoFocus
              tabIndex={0}
            />
            <FieldTitle>{i18n.t("email")}</FieldTitle>
            <EmailField
              placeholder={i18n.t("emailId")}
              value={email}
              errorMessage={emailErrorMessage}
              onChange={this.handleEmailInputChange}
              tabIndex={0}
            />

            <FieldTitle>{i18n.t("role")}</FieldTitle>
            <UserRoleContainer onClick={this.handleRoleDropDownClick}>
              <UserRole>{roleString}</UserRole>
              <UserRoleDropDownIndicator />
            </UserRoleContainer>

            {!submitErrorMessage === false && (
              <SubmitErrorMessage
                dangerouslySetInnerHTML={{
                  __html: submitErrorMessage
                }}
              />
            )}
            <AddButton title={i18n.t("addUser")} onClick={this.handleAddUserButtonClick} isLoading={isSubmitting} />
          </Container>

          <ContextMenu ref={this.dropDownContextMenuRef} width={316} onSelection={this.handleDropDownSelection} />
        </AddUserDialog>
      </DialogOverlay>
    );
  }
}

const mapDispatchToProps = {
  addWlManager: addWlManager
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AddUserDialogComponent));
