import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { updateUserPreferences, siteConfigSelector } from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.mobileBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const WelcomeTxt = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 20px;
  margin-bottom: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  margin-left: 20px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const MessageBlock = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColor};
  padding: 0 20px;
  margin-bottom: 46px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const IUnderstandBtn = styled(PrimaryButton)`
  font-size: 15px;
  line-height: 18px;
  margin-left: 20px;
  padding: 16px 26px;
`;

const HeadsUpComponent = ({ user }) => {
  const dispatch = useDispatch();
  const siteConfig = useSelector(siteConfigSelector);

  const handleClick = () => {
    dispatch(updateUserPreferences({ isHeadsUpShown: true }));
  };

  const siteName = siteConfig ? siteConfig.name : "";

  return (
    <Container>
      <ContentContainer>
        <WelcomeTxt>Hi {user.name},</WelcomeTxt>
        <Title>{i18n.t("headsUpMsg.msg").replace(/%s%/g, siteName)}</Title>
        <MessageBlock>{i18n.t("headsUpMsg")}</MessageBlock>
        <IUnderstandBtn title={i18n.t("headsUpMsg.btn")} data-cy="iUnderstandBtn" onClick={handleClick} />
      </ContentContainer>
    </Container>
  );
};

export default React.memo(HeadsUpComponent);
