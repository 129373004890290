import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import { useStripe } from "@stripe/react-stripe-js";
import {
  accountCardDetailsSelector,
  createSubscription,
  detachSubscriptionCard,
  accountPaymentMethodSelector,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";

import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountSubscriptionOptions from "./AccountSubscriptionOptions";
import useDefaultSelectedFrequency from "./useDefaultSelectedFrequency";
import NFTDiscountMessage from "./NFTDiscountMessage";

const Container = styled.div``;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: #e3e3e3;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 40px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const CardDetails = styled.div`
  font-size: 16px;
  line-height: 125%;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  color: #342a2a80;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 251px;
  height: 50px;
  color: #fff;
  background: ${props => props.theme.mobileColorBtn};
  font-size: 16px;
  line-height: 110%;
`;

const AccountCancelledCard = ({
  setIsSubmitted = () => null,
  captureError = () => null,
  addVipPassClick = () => null,
  invoiceUrlRef
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const paymentMethodId = useSelector(accountPaymentMethodSelector);
  const defaultSelectedFrequency = useDefaultSelectedFrequency();

  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(defaultSelectedFrequency);

  const handleSubscribeClick = useCallback(
    async e => {
      e.preventDefault();
      setIsLoading(true);

      const paymentIntent = await dispatch(setupPaymentIntent(selectedFrequencyValue)).catch(() => {
        setIsLoading(false);
      });
      if (paymentIntent) {
        stripe
          .confirmCardSetup(paymentIntent.payload.clientSecret, {
            payment_method: paymentMethodId
          })
          .then(async result => {
            if (result.error) {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
              setPaymentErrorMsg(result.error.message);
              setIsLoading(false);
              setIsDeclinedError(true);
            } else if (result.setupIntent.status === "succeeded") {
              const { payload = {} } = await dispatch(
                createSubscription(result.setupIntent.payment_method, selectedFrequencyValue, null)
              ).catch(err => {
                setIsLoading(false);
                if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
                  setIsDeclinedError(true);
                }
              });

              if (payload.paymentAuth && payload.paymentAuth.invoiceUrl) {
                invoiceUrlRef.current = payload.paymentAuth.invoiceUrl;
              }
            } else {
              captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
              setPaymentErrorMsg(result.setupIntent.last_setup_error);
              setIsDeclinedError(true);
            }
          });
      }

      setIsSubmitted(true);
    },
    [dispatch, stripe, paymentMethodId, selectedFrequencyValue, setIsSubmitted, captureError, invoiceUrlRef]
  );

  const handleRemoveCardAction = useCallback(
    e => {
      e.preventDefault();
      setIsRemoveLoading(true);
      dispatch(detachSubscriptionCard(selectedFrequencyValue));
    },
    [dispatch, selectedFrequencyValue]
  );

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const handleRadioButtonSelection = value => {
    setSelectedFrequencyValue(value);
  };

  return (
    <Container>
      <form onSubmit={handleSubscribeClick}>
        <AccountSubscriptionOptions
          selectedFrequencyValue={selectedFrequencyValue}
          handleRadioButtonSelection={handleRadioButtonSelection}
        />
        <CardContainer>
          <CardDetails>
            <CardInfo>
              {accountCardDetails.cardBrand} xxxx {accountCardDetails.cardLast4} • Exp{" "}
              {accountCardDetails.cardExpiryMonth}/{accountCardDetails.cardExpiryYear}
            </CardInfo>
          </CardDetails>
          <ChangeAction
            title="✕"
            data-cy="removeCardBtn"
            onClick={handleRemoveCardAction}
            isLoading={isRemoveLoading}
          />
        </CardContainer>
        <SubscribeBtn
          title={i18n.t("subscribe")}
          data-cy="subscribeNowButton"
          onClick={handleSubscribeClick}
          isLoading={isLoading}
        />
        <NFTDiscountMessage accountPlan={selectedFrequencyValue} />
      </form>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </Container>
  );
};

export default AccountCancelledCard;
