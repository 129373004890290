import React, { useState, useMemo } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import i18n from "i18next";
import defaultTheme, { useTheme, dialogGridTheme } from "theme";

import {
  formatNumberWithCurrency,
  getExchangeRate,
  getTickerUsingId,
  UNKNOWN_TICKER_SHORT_NAME,
  getTickerUsingShortName
} from "@kubera/common";

import { CurrencyCellData, cellType } from "components/grid/GridDataModel";
import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";
import TextInput from "components/inputs/TextInput";
import GridCell from "components/grid/GridCell";
import Spinner from "components/loader/Spinner";

import MortgageInputList from "./MortgageInputList";

const TickerContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const TickerHead = styled.div`
  position: relative;
  padding: 11px 14px 9px;
  background: #ebebeb;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  border-bottom: ${props => (props.isBalanceAvailable ? 0 : null)};
  min-height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TickerHeadAddress = styled.div`
  line-height: 150%;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TickerHeadFooter = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RemoveIconComponent = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const TickerBalanceContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  text-align: right;
`;

const TickerBalanceConverted = styled.div`
  font-size: 14px;
  line-height: 150%;
`;

const TickerBalance = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.isHidden ? "none" : null)};
`;

const ValueNotFoundContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  font-feature-settings: "ss01" on, "calt" off;
  text-align: right;
  font-weight: 400;
`;

const ValueNotFound = styled(TickerBalance)`
  color: ${props => props.theme.errorCLR};
`;

const EnterManuallyText = styled(ValueNotFound)`
  text-decoration: underline;
  cursor: pointer;
`;

const EditableTickerHead = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: ${({ showMortgage }) => (showMortgage ? null : "1px solid rgba(0, 0, 0, 0.4)")};
`;

const Input = css`
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  border-right: 0;
  height: 50px;
  padding: 0 14px;
`;

const EditableTextInput = styled(TextInput)`
  ${Input}
`;

const EditableValueWrapper = styled.div`
  width: 134px;
  max-width: calc(50% - 35px);
  display: flex;
  align-items: stretch;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  border-right: 0;

  input {
    z-index: 0;
    box-sizing: border-box;
    border: 0;

    :focus {
      outline: none;
    }
  }

  div,
  input {
    width: 100%;
    box-sizing: border-box;
    border: 0;
  }
`;

const EditableRemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  background: #fff;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  text-align: right;
`;

const ValueSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const splitAddress = address => {
  return address ? address.split(/,(.+)/) : ["", ""];
};

const EditableTicker = ({
  ticker,
  index = 0,
  portfolio,
  onTickerQuantityChange = () => null,
  removeTickerFromIndex = () => null,
  onMortgageSelect = () => null,
  removeNoValTickerFromIndex = () => null,
  isValueLoading = false,
  placeholder = "",
  isEditMode: forceEditMode = false,
  showMortgage = true,
  onAddressChange = () => null,
  onValueChange = () => null,
  isCar = false
}) => {
  const theme = useTheme();
  const { inputVal = "" } = ticker;
  const splitSelected = splitAddress(ticker.address);
  const exchangeRate = getExchangeRate(ticker.currency, portfolio.currency);
  const tickerValue = ticker.balance ? ticker.balance * exchangeRate : "";
  const tickerShortName = getTickerUsingId(ticker.tickerId).shortName;
  const currency =
    tickerShortName && tickerShortName !== UNKNOWN_TICKER_SHORT_NAME ? tickerShortName : portfolio.currency;
  const modifiedTheme = { ...defaultTheme, ...dialogGridTheme(theme.mode) };
  const valueCell = useMemo(() => {
    const cell = new CurrencyCellData(cellType.CURRENCY, "Value", null, portfolio.currency);
    cell.value = ticker.balance;
    cell.ownership = 100;
    cell.isEditable = true;
    cell.currency = currency;
    cell.textAlignment = "right";

    return cell;
  }, [portfolio.currency, ticker.balance, currency]);

  const [isEditMode, setIsEditMode] = useState(forceEditMode);
  const [tickerAddress, setTickerAddress] = useState(ticker.address);

  const handleRemoveTickerFromIndex = () => {
    removeTickerFromIndex(index);
  };

  const handleAddressChange = e => {
    ticker.address = e.target.value;
    setTickerAddress(ticker.address);
    onAddressChange(ticker.address);
  };

  const setEditMode = () => {
    setIsEditMode(true);
  };

  const handleValueCellUpdate = (cellIndex, newCell) => {
    removeNoValTickerFromIndex(index);
    ticker.balance = newCell.value;
    ticker.tickerId = getTickerUsingShortName(newCell.currency).id;
    onValueChange(newCell.value);
  };

  const moveToNextInput = e => {
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 1].focus();
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      moveToNextInput(e);

      return false;
    }

    return true;
  };

  if (isEditMode) {
    return (
      <ThemeProvider theme={modifiedTheme}>
        <TickerContainer>
          <EditableTickerHead showMortgage={showMortgage}>
            <EditableTextInput
              stretch
              value={tickerAddress}
              onChange={handleAddressChange}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
            />
            <EditableValueWrapper>
              <GridCell
                isEditable={true}
                cell={valueCell}
                cellIndex={index}
                cellIndexToStretch={0}
                currency={portfolio.currency}
                onFocus={() => null}
                onBlur={() => null}
                wasRowEverComplete={true}
                isRowUpdated
                onCellUpdate={handleValueCellUpdate}
                onKeyDown={handleKeyDown}
                isEnterModeOnFocus
              />
            </EditableValueWrapper>
            <EditableRemoveContainer onClick={handleRemoveTickerFromIndex}>
              <RemoveIcon />
            </EditableRemoveContainer>
          </EditableTickerHead>
          {showMortgage && (
            <MortgageInputList
              index={index}
              ticker={{
                ...ticker,
                currency: portfolio.currency
              }}
              isValueLoading={isValueLoading}
              inputPlaceholder={i18n.t(`linkAccount.${isCar ? "carsQuantity" : "homesQuantity"}`)}
              inputVal={inputVal}
              onTickerQuantityChange={onTickerQuantityChange}
              onMortgageSelect={onMortgageSelect}
              onKeyDown={handleKeyDown}
            />
          )}
        </TickerContainer>
      </ThemeProvider>
    );
  }

  if (ticker) {
    return (
      <TickerContainer>
        <TickerHead isBalanceAvailable={showMortgage && ticker.balance}>
          <TickerHeadAddress>{splitSelected[0]}</TickerHeadAddress>
          <TickerHeadFooter>{(splitSelected[1] || "").trim()}</TickerHeadFooter>
          <RemoveIconComponent onClick={handleRemoveTickerFromIndex}>
            <RemoveIcon />
          </RemoveIconComponent>
          {ticker.balance ? (
            <TickerBalanceContainer>
              <TickerBalanceConverted>
                {formatNumberWithCurrency(Math.kuberaFloor(tickerValue), portfolio.currency)}
              </TickerBalanceConverted>
              <TickerBalance isHidden={ticker.currency === portfolio.currency}>
                {formatNumberWithCurrency(ticker.balance.toFixed(2), ticker.currency, false, 4)}
              </TickerBalance>
            </TickerBalanceContainer>
          ) : !ticker.tickerBalanceFound ? (
            <ValueNotFoundContainer>
              <ValueNotFound>{i18n.t("valueNotFound")}</ValueNotFound>
              <EnterManuallyText onClick={setEditMode}>{i18n.t("enterManually")}</EnterManuallyText>
            </ValueNotFoundContainer>
          ) : (
            <SpinnerWrapper>
              <ValueSpinner />
            </SpinnerWrapper>
          )}
        </TickerHead>
        {showMortgage && !!ticker.balance && (
          <MortgageInputList
            index={index}
            ticker={{
              ...ticker,
              currency: portfolio.currency
            }}
            isValueLoading={isValueLoading}
            inputPlaceholder={i18n.t(`linkAccount.${isCar ? "carsQuantity" : "homesQuantity"}`)}
            inputVal={inputVal}
            onTickerQuantityChange={onTickerQuantityChange}
            onMortgageSelect={onMortgageSelect}
          />
        )}
      </TickerContainer>
    );
  }

  return null;
};

export default EditableTicker;
