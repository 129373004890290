import React from "react";
import styled from "styled-components";
import {
  parseNumberStringToFloat,
  getCharacterForDecimal,
  kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";
import { connect } from "react-redux";

const Container = styled.div`
  flex: 1;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 13px 14px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: ${props => (props.hasError === true ? props.theme.errorCLR : props.color)}
  outline: none;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
  }
`;

const InvalidInputMessage = styled.p`
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

class PercentageInput extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.state = {
      value:
        props.value || (this.props.showZero && props.value === 0)
          ? formatNumberWithKuberaNumberFormatSettings(props.value, { maximumFractionDigits: 4 })
          : "",
      hasError: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.value !== this.props.value &&
        parseNumberStringToFloat(this.props.value) !== parseNumberStringToFloat(this.state.value)) ||
      prevProps.kuberaNumberFormat !== this.props.kuberaNumberFormat
    ) {
      this.setState({
        value: formatNumberWithKuberaNumberFormatSettings(this.props.value, { maximumFractionDigits: 4 })
      });
    }
  }

  componentDidMount() {
    if (this.ref.current && this.state.value.length > 0) {
      this.ref.current.setSelectionRange(this.state.value.length, this.state.value.length);
    }
  }
  handleInputChange(e) {
    let value = e.target.value;
    var input = this.state.clear ? "" : value.trim();
    this.setState({ clear: false });
    input = input.replace("%", "");

    const parsedInput = input === getCharacterForDecimal() ? 0 : parseNumberStringToFloat(input);
    if (!input === false && input !== getCharacterForDecimal() && parsedInput === null) {
      return;
    }

    const decimalIndex = input.indexOf(getCharacterForDecimal());
    if (!input === false && input.length - (decimalIndex + 1) > 4) {
      return;
    }
    this.setState({ value: input });

    if (this.isInvalidInput(parsedInput) === false) {
      this.props.onChange(e, parsedInput);
    }
  }

  handleKeyDown(e) {
    if (
      e.key === "Backspace" &&
      (this.state.value.length === 1 || this.ref.current.selectionStart > this.state.value.length)
    ) {
      return this.props.callOnChangeOnClear ? this.setState({ clear: true }) : this.setState({ value: "" });
    }

    if (
      this.state.value.length === 0 ||
      this.state.value === 0 ||
      this.state.value === "0" ||
      this.state.value.slice(-1) === getCharacterForDecimal() ||
      (this.ref.current.selectionStart === 0 && this.ref.current.selectionEnd > this.state.value.length)
    ) {
      setTimeout(
        () =>
          this.ref?.current &&
          this.state?.value &&
          this.ref.current.setSelectionRange(this.state.value.length, this.state.value.length),
        0
      );
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  getDisplayString() {
    const value = this.state.value;
    return value === null || value === "" ? "" : value + "%";
  }

  isInvalidInput(value) {
    if (!this.props.inputValidation === false) {
      return this.props.inputValidation(value);
    }
    return this.props.allowMoreThanHundredPercent ? value < 0 : value > 100 || value < 0;
  }

  render() {
    const { className, onChange, disabled, placeholder, ...other } = this.props;
    const hasError = this.isInvalidInput(this.state.value);
    return (
      <Container>
        <Input
          {...other}
          ref={this.ref}
          className={className}
          value={this.getDisplayString()}
          hasError={hasError}
          disabled={disabled === true}
          placeholder={placeholder}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          data-protonpass-ignore
          data-1p-ignore
        />
        {hasError === true && <InvalidInputMessage>{this.props.errorMessage}</InvalidInputMessage>}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  kuberaNumberFormat: kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PercentageInput);
