import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";

import { isMobile, getAppStatus, setAppMaintenanceStatus } from "@kubera/common";
import { ReactComponent as WhaleIcon } from "assets/images/whale.svg";
import AppLogo from "components/labels/AppLogo";
import defaultTheme from "theme";

const isMobileDevice = isMobile();

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: ${props => (props.isMobile ? "-70%" : "0px")};
`;

const Logo = styled(AppLogo)`
  width: 97px;
  margin-top: 20px;
`;

const Title = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const Description = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const WhaleIconComponent = styled(WhaleIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

class MaintenanceModeComponent extends React.Component {
  constructor(props) {
    super(props);

    const checkAppStatus = () => {
      this.props.getAppStatus(isAppInMaintenanceMode => {
        if (isAppInMaintenanceMode === false) {
          this.props.setAppMaintenanceStatus(false);
          window.location.reload();
        }
      });
    };

    this.appStatusCheckTimer = setInterval(() => {
      if (document.hidden === false) {
        checkAppStatus();
      }
    }, 60 * 1000);

    document.addEventListener("visibilitychange", () => {
      if (document.hidden === false) {
        checkAppStatus();
      }
    });
  }

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Container>
          <ContentContainer isMobile={isMobileDevice}>
            <WhaleIconComponent />
            <Logo regularSize={true} forceDefault />
            <Title>{i18n.t("maintenanceMode.title")}</Title>
            <Description>
              {isMobileDevice ? i18n.t("maintenanceMode.mobileDescription") : i18n.t("maintenanceMode.description")}
            </Description>
          </ContentContainer>
        </Container>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  getAppStatus: getAppStatus,
  setAppMaintenanceStatus: setAppMaintenanceStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceModeComponent);
