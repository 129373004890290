import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import { isMobile, accountPlanSelector, SUBSCRIPTION_PLANS } from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";

import confetti from "assets/images/confetti.png";
import partypopper from "assets/images/partypopper.png";
import { useSelector } from "react-redux";

const isMobileDevice = isMobile();

const ConditionalContainerStyled = isMobileDevice
  ? styled(DialogOverlay)`
      width: 100vw;
      height: 100vh;
      & > div {
        padding: 0 36px;
      }
      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const GenericDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 163px;
  width: ${isMobileDevice ? null : "380px"};
  background-image: url(${confetti});
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${isMobileDevice ? "0" : "61px 46px 52px"};
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 46px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-bottom: 11px;
`;

const PartyPopperImg = styled.img`
  width: 60px;
  height: 60px;
`;

const DevsForRes = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-bottom: 42px;
`;

const DevsForResLink = styled.a`
  display: inline-block;
  margin-top: 8px;
  color: inherit;
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-bottom: 60px;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CloseModalBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
  width: 134px;
`;

const DiscountPopup = ({ className = "", discountAccount = {}, onDismiss = () => null }) => {
  const accountPlan = useSelector(accountPlanSelector);

  const { discount, discountInfo = {} } = discountAccount;
  const parsedDiscountInfo = JSON.parse(discountInfo);
  const yearOrYears = parsedDiscountInfo.remain > 1 ? "years" : "year";
  const forYearsTxt = parsedDiscountInfo.remain > 0 ? `, for ${parsedDiscountInfo.remain} ${yearOrYears}.` : ".";

  const handleOverlayDismiss = () => {
    onDismiss();
  };

  // Internally it will give discount but hide for monthly and yearly family plan
  if (
    [SUBSCRIPTION_PLANS.YEARLY_FAMILY, SUBSCRIPTION_PLANS.MONTHLY, SUBSCRIPTION_PLANS.MONTHLY_FAMILY].includes(
      accountPlan
    )
  ) {
    return null;
  }

  return (
    <ConditionalContainerStyled onDismiss={handleOverlayDismiss}>
      <GenericDialog className={className}>
        <Container>
          <Title>
            {i18n.t("discountPopup.title")} <PartyPopperImg src={partypopper} />
          </Title>
          <DevsForRes>
            {i18n.t("discountPopup.devsForRes")}
            <br />
            <DevsForResLink href={parsedDiscountInfo.url} target="_blank">
              {parsedDiscountInfo.name}
            </DevsForResLink>
          </DevsForRes>
          <Desc>
            {i18n.t("discountPopup.desc").replace(/\{discount\}/, discount)}
            {forYearsTxt}
            <br />
          </Desc>
          <BtnBlock>
            <CloseModalBtn title={i18n.t("discountPopup.positiveBtn")} onClick={handleOverlayDismiss} />
          </BtnBlock>
        </Container>
      </GenericDialog>
    </ConditionalContainerStyled>
  );
};

export default DiscountPopup;
