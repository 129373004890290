import React from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { wlCardStrings, wlPaymentMethodStatus } from "@kubera/common";

import SecondaryButton from "components/button/SecondaryButton";
import { ReactComponent as Exit } from "assets/images/exit.svg";

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 51px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 11px 20px;
  box-sizing: border-box;
  margin-bottom: 12px;
  overflow: hidden;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Roboto Mono";
  font-size: 14px;
  row-gap: 10px;
`;

const CardInfo = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000;
`;

const ActionBlock = styled.div`
  display: flex;
  font-size: 11px;
  line-height: 14.51px;
`;

const ActionTxt = styled.div`
  color: #ff0000;
  text-transform: uppercase;
  font-weight: 400;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: -1px;
  transform: translateY(1%);
  right: 34px;
  font-family: "Roboto Mono";
  font-style: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 87, 255, 0.8);
  background: transparent;
  border: 0;
  outline: 0;
  width: 44px;
  min-width: 44px;
  white-space: nowrap;
`;

const VerifyAction = styled(ChangeAction)`
  position: static;
  font-size: inherit;
  height: 14.51px;
  transform: translateY(0);
  width: auto;
  font-weight: 400;
`;

const ExitIcon = styled(Exit)`
  position: absolute;
  top: 17px;
  right: 19px;
  width: 9px;
  margin-top: 1px;
  cursor: pointer;
  transform: translateY(1%);
`;

const AdminStripeSetCard = ({ client, details = {}, isUpdating = false, onUpdate = () => null }) => {
  const wlRecurringChargeSchedule = client.recurringChargeSchedule || {};

  const handleUpdateCardAction = () => {
    onUpdate();
  };

  const handleOnVerify = () => {
    window.open(details.verificationUrl, "_blank");
  };

  const wlCardStr = wlCardStrings(details);

  return (
    <CardContainer>
      <CardDetails>
        <CardInfo>
          {wlCardStr.adminCardPrefixStr}
          {details.cardBrand} {details.cardLast4Digits}
          {wlCardStr.expDateString}
        </CardInfo>
        {details.status === wlPaymentMethodStatus.VERIFY_WITH_MICRODEPOSITS && (
          <ActionBlock>
            <ActionTxt>{i18n.t("pendingVerification")}</ActionTxt>
            <VerifyAction
              title={i18n.t("verifyNow")}
              data-cy="verifyBankBtn"
              onClick={handleOnVerify}
              isLoading={isUpdating}
            />
          </ActionBlock>
        )}
      </CardDetails>
      <ChangeAction
        title={i18n.t(wlRecurringChargeSchedule.status !== "card_error" ? "update" : "addCard")}
        data-cy="removeCardBtn"
        onClick={handleUpdateCardAction}
        isLoading={isUpdating}
      />
      <ExitIcon onClick={handleUpdateCardAction} />
    </CardContainer>
  );
};

export default AdminStripeSetCard;
