import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { userEmailSelector, signInWithEmailPassword, isAppInWhiteLabelMode } from "@kubera/common";
import { Dialog } from "components/dialog/DialogOverlay";
import EmailInput from "components/inputs/EmailInput";
import PasswordInput from "components/inputs/PasswordInput";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import KuberaPoweredLogo from "components/labels/KuberaPoweredLogo";

const ComponentDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 416px;
  margin-top: 80px;
  padding: 45px 48px 50px 50px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 6px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 27px;
`;

const FieldTitle = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  width: 318px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 25px;
`;

const PasswordField = styled(PasswordInput)`
  height: 45px;
  margin-bottom: 135px;
`;

const BtnWrapper = styled.div`
  display: flex;
`;

const VerifyButton = styled(PrimaryButton)`
  min-width: 137px;
  margin-right: 15px;
`;

const CancelBtn = styled(SecondaryButton)`
  min-width: 137px;
`;

const PoweredByKuberaLogo = styled(KuberaPoweredLogo)`
  margin-top: 20px;
`;

const VerifyScreen = ({ onVerifySuccess = () => null, onCancel }) => {
  const email = useSelector(userEmailSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handlePasswordInput = e => {
    setPassword(e.target.value);
  };

  const handleSignInClick = e => {
    e.preventDefault();
    setIsLoading(true);
    setIsSubmitted(true);
    signInWithEmailPassword(
      email,
      password,
      data => {
        onVerifySuccess(data, {
          email,
          password
        });
      },
      error => {
        setPassword("");
        setIsLoading(false);
      }
    );
  };

  const onCancelAction = e => {
    e.preventDefault();

    onCancel();
  };

  return (
    <ComponentDialog>
      <form onSubmit={handleSignInClick}>
        <Title>{i18n.t("twoFA.verifyTitle")}</Title>
        <Desc>{i18n.t("twoFA.verifyDesc")}</Desc>
        <FieldTitle>{i18n.t("email")}</FieldTitle>
        <EmailField data-private disabled={true} value={email} />
        <FieldTitle>{i18n.t("password")}</FieldTitle>
        <PasswordField
          id="password"
          data-cy="password"
          placeholder={i18n.t("password")}
          inputError={isSubmitted && password === ""}
          value={password}
          onChange={handlePasswordInput}
          hidePasswordStrength={true}
          autoComplete="current-password"
          autoFocus
        />
        <BtnWrapper>
          <VerifyButton
            id="verify"
            data-cy="verify"
            title={i18n.t("verify")}
            onClick={() => null}
            isLoading={isLoading}
          />
          {onCancel && <CancelBtn id="cancel" data-cy="cancel" title={i18n.t("cancel")} onClick={onCancelAction} />}
        </BtnWrapper>
        {isAppInWhiteLabelMode() === true && <PoweredByKuberaLogo />}
      </form>
    </ComponentDialog>
  );
};

export default VerifyScreen;
