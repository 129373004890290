import React from "react";
import styled, { keyframes } from "styled-components";

const ProgressBar = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: ${props => props.theme.topLoaderBG};
`;

const ProgessAnimation = keyframes`
  0% {
    transform: scaleX(0); 
  }
  4% {
    transform: scaleX(0.6); 
  }
  10% {
    transform: scaleX(0.8); 
  }
  100% {
    transform: scaleX(0.9); 
  } `;

const Progress = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.topLoaderColor};
  transform-origin: 0%;
  animation: ${ProgessAnimation} 60s linear forwards;
`;

class TopLineLoader extends React.PureComponent {
  render() {
    return (
      <ProgressBar>
        <Progress />
      </ProgressBar>
    );
  }
}

export default TopLineLoader;
