import React from "react";
import { connect } from "react-redux";
import { store } from "../store";
import { syncStateSelector } from "../redux/reducers/SyncReducer";
import { isAmcUser } from "../redux/reducers/AuthReducer";
import { userSelector } from "../redux/reducers/Common";
import { shouldToastBlockReload } from "../redux/reducers/ToastReducer";
import {
  startSyncAction,
  stopSyncAction,
  dequeueItemAction,
  markSyncQueueAsStuckAction
} from "../redux/actions/SyncActions";
import { fetchWlDashboard } from "../redux/actions/WhiteLabelActions";
import { fetchPortfolios, getUpdatedIrr } from "../redux/actions/PortfolioActions";
import { apiErrorCodes } from "../api/ApiResponse";
import { Toast, showToastAction, showAppUpdateToastIfNeeded } from "../redux/actions/ToastActions";
import { getClientInfo, fetchUser } from "../redux/actions/AuthActions";
import { getAppVersion } from "../utilities/Number";
import { refreshTickerData } from "../redux/actions/TickerActions";
import { toastType } from "../redux/actions/Common";
import * as Sentry from "@sentry/browser";
import { isMobile } from "../utilities/Location";
import { captureError } from "../utilities";

const isMobileDevice = isMobile();

class ConnectedSyncComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onUnload = this.onUnload.bind(this);

    this.state = {
      currentItem: null,
      isProcessing: false,
      isShowingError: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("beforeunload", this.onUnload);
    this.processQueue();
    this.startBackgroundSync();
    this.startBackgroundAppVersionCheck();
  }

  startBackgroundAppVersionCheck() {
    if (this.backgroundAppVersionCheckTimer) {
      return;
    }

    this.backgroundAppVersionCheckTimer = setInterval(() => {
      if (this.isComponentMounted === false) {
        clearInterval(this.backgroundAppVersionCheckTimer);
        return;
      }

      this.props.showAppUpdateToastIfNeeded();
    }, 65 * 60 * 1000);
  }

  startBackgroundSync() {
    if (!this.backgroundPortfolioFetchTimer === true) {
      this.backgroundPortfolioFetchTimer = setInterval(() => {
        if (this.isComponentMounted === false) {
          clearInterval(this.backgroundPortfolioFetchTimer);
          return;
        }

        if (this.props.syncState.syncQueue.length === 0) {
          this.props.fetchUser(() => {
            this.props.fetchPortfolios(true);
          });
        }
      }, 65 * 60 * 1000);
    }

    if (!this.backgroundTickerDataFetchTimer === true) {
      this.backgroundTickerDataFetchTimer = setInterval(() => {
        if (this.isComponentMounted === false) {
          clearInterval(this.backgroundTickerDataFetchTimer);
          return;
        }

        this.props.refreshTickerData();
        this.props.getUpdatedIrr();
      }, 15 * 60 * 1000);
    }

    if (this.props.isAmcUser === true && !this.backgroundWlDashboardFetchTimer === true) {
      this.backgroundWlDashboardFetchTimer = setInterval(() => {
        if (this.isComponentMounted === false) {
          clearInterval(this.backgroundWlDashboardFetchTimer);
          return;
        }

        this.props.fetchWlDashboard(true);
      }, 15 * 60 * 1000);
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload(event) {
    if (
      this.props.syncState.isSyncing === true ||
      this.props.syncState.pendingDocumentUploads.length > 0 ||
      shouldToastBlockReload(store.getState())
    ) {
      event.preventDefault();
      event.returnValue = "Don't leave";
    }
  }

  componentDidUpdate(oldProps) {
    const isSyncing = this.props.syncState.isSyncing;
    const syncQueue = this.props.syncState.syncQueue;

    if (syncQueue.length === 0 && isSyncing === true) {
      this.props.stopSync();
    } else if (syncQueue.length > 0 && (isSyncing === false || this.state.isProcessing === false)) {
      this.props.startSync();
      if (this.state.isProcessing === false) {
        this.processQueue();
      }
    }
  }

  processQueue() {
    if (this.isComponentMounted === false) {
      return;
    }
    if (this.props.syncState.syncQueue.length === 0) {
      this.setState({ currentItem: null, isProcessing: false });
      return;
    }

    var queueHead = this.props.syncState.syncQueue[0];
    var requestAge = Date.now() - queueHead.requestTimestamp;
    var waitTime = requestAge >= queueHead.delay ? 0 : queueHead.delay;

    this.setState({ isProcessing: true, currentItem: queueHead });

    setTimeout(() => {
      queueHead = this.props.syncState.syncQueue[0];
      if (this.state.currentItem === queueHead) {
        this.setState({ currentItem: queueHead });

        try {
          queueHead
            .requestInstance(queueHead.idempotentId)
            .then(apiData => {
              if (this.state.isShowingError === true) {
                this.showSyncResumingToast();
              }
              // K-1955: If any error happens here within any action dequeueItem and processQueue won't get called
              queueHead.onSuccess(apiData);
              this.props.dequeueItem(queueHead);
              this.processQueue();
            })
            .catch(apiError => {
              console.error(apiError);
              if (apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY || apiError.isCancel) {
                this.props.dequeueItem(queueHead);
              } else if (queueHead.onError) {
                queueHead.onError(apiError);
                this.props.dequeueItem(queueHead);
              } else {
                if (apiError.errorCode === apiErrorCodes.INVALID_INPUT) {
                  this.props.markSyncQueueAsStuck();
                } else {
                  captureError(apiError);
                  this.showSyncErrorToast();
                }
              }
              queueHead.retryCount = queueHead.retryCount + 1;
              setTimeout(() => this.processQueue(), this.getRetryWaitTime(queueHead.retryCount));

              if (queueHead.retryCount > 2) {
                Sentry.withScope(scope => {
                  scope.setExtras({
                    apiErrorCode: apiError.errorCode,
                    httpStatus: apiError.httpStatus,
                    userId: userSelector(store.getState()) ? userSelector(store.getState()).id : "",
                    appVersion: getAppVersion()
                  });
                  Sentry.captureMessage("SYNCING_ISSUE");
                });
              }
            });
        } catch (e) {
          console.log("SyncComponent error");
          console.log(e);

          this.props.markSyncQueueAsStuck();

          Sentry.withScope(scope => {
            scope.setExtras({
              message: e.message,
              userId: userSelector(store.getState()) ? userSelector(store.getState()).id : "",
              appVersion: getAppVersion()
            });
            Sentry.captureMessage("SYNCING_ISSUE");
          });
        }
      } else {
        this.processQueue();
      }
    }, waitTime);
  }

  getRetryWaitTime(retryCount) {
    if (retryCount <= 3) {
      return retryCount * 1000;
    }
    return Math.pow(2, retryCount - 2) * 1000;
  }

  showSyncErrorToast() {
    if (isMobileDevice === true) {
      return;
    }
    const errorToast = new Toast(
      toastType.SYNC_ERROR,
      "Something’s not right. Changes made now will not be saved. Please wait. 🤕",
      -1,
      null,
      null
    );
    errorToast.dismissOnOutsideClick = false;
    errorToast.showDismissButton = false;
    this.setState({ ...this.state, isShowingError: true });
    this.props.showToastAction(errorToast);
  }

  showSyncResumingToast() {
    if (isMobileDevice === true) {
      return;
    }
    const errorToast = new Toast(
      toastType.SYNC_RESUMING,
      "All is well. Auto saving changes as usual. 👍🏼",
      5000,
      null,
      null
    );
    this.setState({ ...this.state, isShowingError: false });
    this.props.showToastAction(errorToast);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  syncState: syncStateSelector(state),
  isAmcUser: isAmcUser(state)
});

const mapDispatchToProps = {
  startSync: startSyncAction,
  stopSync: stopSyncAction,
  dequeueItem: dequeueItemAction,
  showToastAction: showToastAction,
  fetchPortfolios: fetchPortfolios,
  fetchUser: fetchUser,
  getClientInfo: getClientInfo,
  refreshTickerData: refreshTickerData,
  markSyncQueueAsStuck: markSyncQueueAsStuckAction,
  fetchWlDashboard: fetchWlDashboard,
  showAppUpdateToastIfNeeded: showAppUpdateToastIfNeeded,
  getUpdatedIrr: getUpdatedIrr
};

export const SyncComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSyncComponent);
