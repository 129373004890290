import React from "react";
import styled from "styled-components";

import LabelUpdateHandler from "./LabelUpdateHandler";
import { formatNumberWithKuberaNumberFormatSettings } from "@kubera/common";

const getLabelColor = ({ darkBackground, theme, updated: isUpdated, value: unparsedVal = 0 }) => {
  const value = parseFloat(unparsedVal);
  const updated = isUpdated === true;

  if (darkBackground === true) {
    if (value >= 0) {
      return theme.gridGreenColorForDarkBackground;
    } else {
      return theme.gridRedColorForDarkBackground;
    }
  } else {
    if (value >= 0) {
      return updated === true
        ? theme.gridGreenColorForLightBackground
        : theme.gridUnupdatedGreenColorForLightBackground;
    } else {
      return updated === true ? theme.gridRedColorForLightBackground : theme.gridUnupdatedRedColorForDarkBackground;
    }
  }
};
const Label = styled.div`
  color: ${props => getLabelColor(props)};
`;

const LabelUpdateHandlerComponent = styled(LabelUpdateHandler)`
  left: unset;
  ${props => props.alignPosition || "right"}: 0;
`;

class PercentageLabel extends React.Component {
  render() {
    const { className, value, darkBackground, updated, showZero, ...other } = this.props;
    const valueSymbol = !value === false ? (value > 0 ? "+" : "-") : "";
    const valueString =
      valueSymbol +
      (value > 10000000 ? value.toExponential(2) : formatNumberWithKuberaNumberFormatSettings(Math.abs(value))) +
      "%";

    if (value === null || (parseFloat(value) === 0 && !showZero === true)) {
      return null;
    }

    return (
      <Label {...other} className={className} darkBackground={darkBackground} updated={updated} value={value}>
        <LabelUpdateHandlerComponent
          value={valueString}
          preventAnimation={this.props.preventAnimation}
          alignPosition={this.props.alignPosition}
        />
      </Label>
    );
  }
}

export default PercentageLabel;
