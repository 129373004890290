import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  checkIfDateIsGreaterThanPortfolioStartDate,
  currentPortfolioChangeDataSelector,
  pastValueInterval,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  useHistory
} from "@kubera/common";
import { hashParams, modalValues } from "routes";
import { useSelector } from "react-redux";
import { category } from "components/dashboard/DashboardComponentExports";
import GlowingBadgeTip from "components/tooltip/GlowingBadgeTip";
import ChangeLabel from "components/labels/ChangeLabel";
import ClickableLink from "components/labels/DelayLink";
import { ReactComponent as RightArrow } from "assets/images/right_arrow_change.svg";

const TotalChangeWrapper = styled.div`
  margin-top: -5px;
  margin-left: 17px;
  margin-bottom: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  contain: layout;
  overflow: auto;
  white-space: nowrap;
`;

const TotalChangeContainer = styled(ClickableLink)`
  display: inline-block;
  align-items: center;
  padding: 5px 10px 5px 5px;
`;

const TotalChangeTitleContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const TotalChangeBadgeTip = styled(GlowingBadgeTip)`
  margin-left: 2px;
`;

const TotalChangeTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.mobileTxtColorChangeTitle};
`;

const TotalChangeFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalChangeLabel = styled(ChangeLabel)`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.015em;
  margin-right: 5px;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : "")};
`;

const RightArrowOpenChange = styled(RightArrow)`
  path {
    fill: ${props => props.theme.mobileTxtColorChangeArrow};
  }
`;

const MobileGridTotalChange = ({
  category: selectedCategory = category.ASSET,
  portfolio,
  direction,
  isRefreshing = false
}) => {
  const history = useHistory();
  const location = useLocation();

  const disableTotalChangeColor = selectedCategory === category.DEBT;

  const handleTotalChangeClick = forInterval => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${forInterval}`
    });
  };

  const showChangeBadge = false;
  const changeData = useSelector(currentPortfolioChangeDataSelector);
  const changeTotalsAndContributors = useSelector(state =>
    getChangeTotalsWithContributorsForCurrentPortfolio(state, selectedCategory)
  );
  const totalValue = changeTotalsAndContributors.currentTotal;
  const oneDayAgoValue = changeTotalsAndContributors.changes.day.total;
  const oneWeekAgoValue = changeTotalsAndContributors.changes.week.total;
  const oneMonthAgoValue = changeTotalsAndContributors.changes.month.total;
  const oneYearAgoValue = changeTotalsAndContributors.changes.year.total;
  const getWeekOrMonthlyOrYearlyChangelabel = () => {
    if (
      checkIfDateIsGreaterThanPortfolioStartDate(portfolio.id, pastValueInterval.YEAR) &&
      (!oneYearAgoValue === false || (changeData && changeData.isAllChangeFetching === true))
    ) {
      return (
        <TotalChangeContainer onClick={e => handleTotalChangeClick(pastValueInterval.YEAR)} direction="left">
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("yearChange")}</TotalChangeTitle>
            {showChangeBadge === true && <TotalChangeBadgeTip />}
          </TotalChangeTitleContainer>
          <TotalChangeFooter>
            <TotalChangeLabel
              currency={portfolio.currency}
              startValue={oneYearAgoValue}
              endValue={totalValue}
              disableColor={disableTotalChangeColor}
              isUpdating={!oneYearAgoValue === false && changeData.isAllChangeFetching}
              placeholder={!oneYearAgoValue === true && changeData.isAllChangeFetching}
              isAnimationAllowed={false}
            />
            <RightArrowOpenChange />
          </TotalChangeFooter>
        </TotalChangeContainer>
      );
    } else if (
      checkIfDateIsGreaterThanPortfolioStartDate(portfolio.id, pastValueInterval.MONTH) &&
      (!oneMonthAgoValue === false || (changeData && changeData.isAllChangeFetching === true))
    ) {
      return (
        <TotalChangeContainer onClick={e => handleTotalChangeClick(pastValueInterval.MONTH)} direction="left">
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("monthChange")}</TotalChangeTitle>
            {showChangeBadge === true && <TotalChangeBadgeTip />}
          </TotalChangeTitleContainer>
          <TotalChangeFooter>
            <TotalChangeLabel
              currency={portfolio.currency}
              startValue={oneMonthAgoValue}
              endValue={totalValue}
              disableColor={disableTotalChangeColor}
              isUpdating={!oneMonthAgoValue === false && changeData.isAllChangeFetching}
              placeholder={!oneMonthAgoValue === true && changeData.isAllChangeFetching}
              isAnimationAllowed={false}
            />
            <RightArrowOpenChange />
          </TotalChangeFooter>
        </TotalChangeContainer>
      );
    } else if (
      checkIfDateIsGreaterThanPortfolioStartDate(portfolio.id, pastValueInterval.WEEK) &&
      (!oneWeekAgoValue === false || (changeData && changeData.isAllChangeFetching === true))
    ) {
      return (
        <TotalChangeContainer onClick={e => handleTotalChangeClick(pastValueInterval.WEEK)} direction="left">
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("weekChange")}</TotalChangeTitle>
            {showChangeBadge === true && <TotalChangeBadgeTip />}
          </TotalChangeTitleContainer>
          <TotalChangeFooter>
            <TotalChangeLabel
              currency={portfolio.currency}
              startValue={oneWeekAgoValue}
              endValue={totalValue}
              disableColor={disableTotalChangeColor}
              isUpdating={!oneWeekAgoValue === false && changeData.isAllChangeFetching}
              placeholder={!oneWeekAgoValue === true && changeData.isAllChangeFetching}
              isAnimationAllowed={false}
            />
            <RightArrowOpenChange />
          </TotalChangeFooter>
        </TotalChangeContainer>
      );
    }
  };

  return (
    <TotalChangeWrapper>
      {checkIfDateIsGreaterThanPortfolioStartDate(portfolio.id, pastValueInterval.DAY) && (
        <TotalChangeContainer onClick={e => handleTotalChangeClick(pastValueInterval.DAY)} direction="left">
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("dayChange")}</TotalChangeTitle>
            {showChangeBadge === true && <TotalChangeBadgeTip />}
          </TotalChangeTitleContainer>
          <TotalChangeFooter>
            <TotalChangeLabel
              currency={portfolio.currency}
              startValue={oneDayAgoValue}
              endValue={totalValue}
              disableColor={disableTotalChangeColor}
              isUpdating={!oneDayAgoValue === false && changeData && changeData.isDayChangeFetching}
              placeholder={!oneDayAgoValue === true && changeData && changeData.isDayChangeFetching}
              isAnimationAllowed={false}
            />
            <RightArrowOpenChange />
          </TotalChangeFooter>
        </TotalChangeContainer>
      )}
      {getWeekOrMonthlyOrYearlyChangelabel()}
    </TotalChangeWrapper>
  );
};

export default React.memo(MobileGridTotalChange);
