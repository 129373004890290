import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { isEmailValid, forgotPassword, getQueryParams, isAppInWhiteLabelMode } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import Logo from "components/labels/AppLogo";
import EmailInput from "components/inputs/EmailInput";
import ClickableLink from "components/labels/ClickableLink";
import { routes } from "routes";
import { withRouter } from "@kubera/common";
import { queryParams } from "routes";
import KuberaPoweredLogo from "components/labels/KuberaPoweredLogo";
import RecaptchaV2Render from "./RecaptchaV2Render";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${props => props.theme.mobileSignInBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const SignInForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 528px;
  padding: 0 30px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
`;

const EmailField = styled(EmailInput)`
  font-size: 16px;
  line-height: 19px;
  height: 50px;
  font-weight: normal;
`;

const ResetPasswordButton = styled(PrimaryButton)`
  margin-top: 15px;
  min-width: 184px;
  font-size: 15px;
  line-height: 18px;
`;

const GoBackLink = styled(ClickableLink)`
  font-size: 13px;
  line-height: 16px;
  margin-top: 27px;
`;

const PoweredByKuberaLogo = styled(KuberaPoweredLogo)`
  position: absolute;
  right: 50px;
  bottom: 50px;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 32px;
`;

const SignUpTxt = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-top: 30px;
`;

class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);

    const prefilledEmail = getQueryParams(this.props.location)[queryParams.EMAIL_ID];

    this.state = {
      email: !prefilledEmail === true ? null : prefilledEmail,
      isEmailError: false,
      errorMessage: null,
      isRequestPending: false
    };
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    this.recaptchaComponentRef = React.createRef();
  }

  handleEmailInput(e) {
    this.setState({
      ...this.state,
      email: e.target.value.trim().toLowerCase(),
      isEmailError: false,
      errorMessage: null
    });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleResetPasswordClick();
    }
  }

  handleResetPasswordClick() {
    const email = this.state.email;
    let isEmailError = false;
    let errorMessage = null;

    if (!email) {
      errorMessage = i18n.t("emptyEmailForgotPassword");
      isEmailError = true;
    } else if (isEmailValid(email) === false) {
      errorMessage = i18n.t("wrongEmailFormat");
      isEmailError = true;
    }

    if (isEmailError === false) {
      this.resetPassword();
    } else {
      this.setState({
        ...this.state,
        isEmailError,
        errorMessage
      });
    }
  }

  handleRecaptchaClick = () => {
    this.setState({
      ...this.state,
      errorMessage: null
    });
  };

  resetPassword() {
    this.setState({ ...this.state, isRequestPending: true });

    const email = this.state.email;
    forgotPassword(
      email,
      () => {
        this.showForgotPasswordConfirmationScreen();
      },
      error => {
        this.recaptchaComponentRef.current.showRecaptchaV2Widget(error);
        if (error.code === "UserNotFoundException") {
          this.showForgotPasswordConfirmationScreen();
          return;
        }
        this.setState({ ...this.state, isRequestPending: false, errorMessage: error.message });
      }
    );
  }

  showForgotPasswordConfirmationScreen() {
    this.props.history.push({
      ...this.props.location,
      pathname: routes.FORGOT_PASSWORD_CONFIRMATION,
      hash: "",
      search: `${queryParams.EMAIL_ID}=${encodeURIComponent(this.state.email)}`
    });
  }

  render() {
    const isRequestPending = this.state.isRequestPending;
    const isEmailError = this.state.isEmailError;
    const email = this.state.email;
    const errorMessage = this.state.errorMessage;

    return (
      <Container>
        <ContentContainer>
          <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
          <SignInForm>
            <Title>{i18n.t("forgotPassword").replace(" ", "\n")}</Title>
            <ErrorMessage visible={!errorMessage === false}>
              {!errorMessage === true ? "&nbsp;" : errorMessage}
            </ErrorMessage>
            <EmailField
              placeholder={i18n.t("yourEmail")}
              inputError={isEmailError}
              value={email}
              onChange={this.handleEmailInput}
              onKeyDown={this.handleKeyDown}
              autoFocus={true}
            />
            <RecaptchaV2Render
              ref={this.recaptchaComponentRef}
              action="forgot_password"
              onClick={this.handleRecaptchaClick}
            />
            <ResetPasswordButton
              title={i18n.t("resetPassword")}
              onClick={this.handleResetPasswordClick}
              isLoading={isRequestPending}
            />
            <GoBackLink onClick={() => window.history.back()}>{i18n.t("goBackLink")}</GoBackLink>
            {isWhiteLabelledApp === false && (
              <SignUpTxt>
                New to Kubera?
                <br />
                Visit Kubera.com on desktop web, sign up and come back here.
              </SignUpTxt>
            )}
            {isWhiteLabelledApp === true && <PoweredByKuberaLogo />}
          </SignInForm>
        </ContentContainer>
      </Container>
    );
  }
}

export default withRouter(ForgotPasswordComponent);
