import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  popularTickersSelector,
  nonPopularTickersSelector,
  getTickerUsingShortName,
  getTickerUsingId,
  tickerTypes,
  portfolioTickerShortcutsSelector,
  userPreferencesSelector,
  updateUserPreferences,
  tickerSubTypes
} from "@kubera/common";
import requestIdleCallback from "utilities/requestIdleCallback";
import cancelIdleCallback from "utilities/cancelIdleCallback";

const TickerListContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 495px;
  overflow-y: auto;
`;

const TickerList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: min-content;
  border-right: ${props => (props.showRightBorder ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
`;

const PopularTickerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`;

const TickerName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-style: normal;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  font-size: 13px;
  line-height: 16px;
  color: "#000000";
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TickerSymbol = styled.div`
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  padding-top: 2px;
  margin-left: 5px;
`;

const TickerMenuItem = styled.div`
  height: 35px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

class CurrencyPickerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleTickerSelection = this.handleTickerSelection.bind(this);
    this.updateOnceRef = React.createRef();
  }

  handleTickerSelection(ticker) {
    this.props.onTickerSelection(ticker);
  }

  getApplicableTickers() {
    const portfolioTickerId = getTickerUsingShortName(this.props.currentPortfolio.currency).id;
    const portfolioCustodians = this.props.currentPortfolio.details.custodian;
    const popularTickers = this.props.popularTickers;

    var usedTickerIds = new Set();
    var shortcutTickerIds = new Set();

    // Get tickers used in portfolio
    usedTickerIds.add(portfolioTickerId);
    for (const custodian of portfolioCustodians) {
      if (custodian.costTickerId) {
        usedTickerIds.add(custodian.costTickerId);
      }
      if (custodian.valueTickerId) {
        usedTickerIds.add(custodian.valueTickerId);
      }
    }

    // Get tickers that were previously used
    // and stored in preferences as shortcuts
    const portfolioTickerShortcuts = this.props.portfolioTickerShortcuts;
    if (portfolioTickerShortcuts) {
      for (const tickerId of portfolioTickerShortcuts) {
        shortcutTickerIds.add(tickerId);
      }
    }

    // Find non-popular tickers used in the portfolio
    // and add them to shortcut tickers if not already present
    usedTickerIds.forEach(tickerId => {
      const candidateTicker = getTickerUsingId(tickerId);
      if (
        candidateTicker.type === tickerTypes.FIAT ||
        !popularTickers.find(ticker => ticker.id === candidateTicker.id) === false
      ) {
        shortcutTickerIds.add(tickerId);
      }

      // Add SATS ticker if BTC is added
      if (candidateTicker.shortName === "BTC.CC") {
        shortcutTickerIds.add(getTickerUsingShortName("SATS.CC").id);
      }
    });

    // If the shortcuts stored in preferences are different from
    // shortcuts calculated just now save the new shortcuts.
    // Shortcuts are never removed once added.
    const shortTickerIdsArray = Array.from(shortcutTickerIds);
    var hasShortcutsChanged = false;
    for (const tickerId of shortTickerIdsArray) {
      if (!portfolioTickerShortcuts === true && shortTickerIdsArray.length > 0) {
        hasShortcutsChanged = true;
        break;
      }
      if (portfolioTickerShortcuts.includes(tickerId) === false) {
        hasShortcutsChanged = true;
        break;
      }
    }

    if (hasShortcutsChanged === true) {
      const map = this.props.userPreferences.portfolioTickerShortcutMap;
      map[this.props.currentPortfolio.id] = shortTickerIdsArray;
      cancelIdleCallback(this.updateOnceRef.current);
      this.updateOnceRef.current = requestIdleCallback(() => {
        this.props.updateUserPreferences({ portfolioTickerShortcutMap: map });
      });
    }

    // Show tickers in the order
    // 1) Current portfolio currency, 2) Shortcuts, 3) Popular Fiat ones with some crypto tickers
    const popularTickerIds = this.props.popularTickers
      .filter(ticker => ticker.shortName !== "SATS.CC")
      .map(ticker => ticker.id);
    var tickerListIds = new Set([portfolioTickerId, ...shortcutTickerIds, ...popularTickerIds]);
    const tickers = Array.from(tickerListIds)
      .map(id => getTickerUsingId(id))
      .filter(
        ticker =>
          ticker.shortName !== "UNKNOWN" &&
          (ticker.subType !== tickerSubTypes.PRECIOUS_METALS || ticker.shortName === "XAU") &&
          ticker.id !== 171
      );
    return tickers;
  }

  render() {
    const currentPortfolio = this.props.currentPortfolio;
    const applicableTickers = this.getApplicableTickers();

    return (
      <TickerListContainer>
        <PopularTickerListContainer>
          <TickerList showRightBorder={true}>
            {applicableTickers.map((ticker, index) => {
              return (
                <TickerMenuItem key={index} onClick={() => this.handleTickerSelection(ticker)}>
                  <TickerName isSelected={ticker.shortName === currentPortfolio.currency}>{ticker.name}</TickerName>
                  <TickerSymbol>{ticker.symbol[0]}</TickerSymbol>
                </TickerMenuItem>
              );
            })}
          </TickerList>
        </PopularTickerListContainer>
      </TickerListContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  popularTickers: popularTickersSelector(state),
  nonPopularTickers: nonPopularTickersSelector(state),
  portfolioTickerShortcuts: portfolioTickerShortcutsSelector(state, props.currentPortfolio.id),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyPickerComponent);
