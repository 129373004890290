import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { useSelector } from "react-redux";
import {
  userEmailSelector,
  userSelector,
  fetchBeneficiaries,
  currentPortfolioSelector,
  hasValidPortfolioSelector,
  beneficiarySelector,
  beneficiariesSelector,
  updateBeneficiaries,
  exportPortfolio,
  userMaskAllValuesSelector,
  fetchUser,
  updateUser,
  sendTestMail,
  isAmcUser,
  ApiClient,
  isSharedPortfolioUserSelector,
  getAccountOwnerNameSelector,
  portfoioTreeReverseLinkedIdMapSelector
} from "@kubera/common";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import ModifyBeneficiaryComponent from "components/beneficiary/ModifyBeneficiaryComponent";
import YellowBoxDismissIcon from "assets/images/yellow_box_dismiss_icon.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import PortfolioDownloadDialog from "components/portfolio/PortfolioDownloadDialog";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import VLineIcon from "assets/images/beneficiary_line.png";
import VLineIconDark from "assets/images/beneficiary_line_dark.png";
import RoundIconPng from "assets/images/beneficiary_round.png";
import { downloadFile } from "utilities/FileUtils";
import { SHOW_DETAIL_TYPE } from "./BeneficiaryComponentExports";
import { useParentBeneficiaryDetails } from "utilities/CustomHooks";

const ContentContainer = styled.div`
  padding-bottom: 44px;
`;

const Container = styled.div`
  display: block;
  justify-content: top;
  align-items: top;
  height: auto;
  padding: 50px 55px 50px 55px;
  border: ${props => props.theme.beneficiaryComponentContainerBR};
  margin-top: 24px;
  background: ${props => props.theme.beneficiaryComponentContainerBG};
`;

const EmptyContainer = styled.div`
  display: block;
  justify-content: top;
  align-items: top;
  height: auto;
  padding: 50px 55px 50px 55px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19.6px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.beneficiarySubTitleCLR};
  margin-top: 3px;
  white-space: pre-wrap;
`;

const TextBox = styled.div`
  display: flex;
  align-items: left;
  font-size: 14px;
  padding: 0px 0px 7px 0px;
  font-weight: 600;
`;

const WrapperBox = styled.div`
  background-position: 0 0, 0px 0px;
  background-repeat: no-repeat, repeat-y;
  background-size: 25px;
  background-image: url(${RoundIconPng}), url(${props => (props.theme.mode === "default" ? VLineIcon : VLineIconDark)});
  padding-left: 60px;
`;

const WrapperBoxEnd = styled(WrapperBox)`
  background-image: url(${RoundIconPng});
`;

const GrayBox = styled.div`
  align-items: left;
  padding: 26px;
  background-color: ${props =>
    !props.isEmptyState ? props.theme.beneficiaryComponentGrayBoxBGVTA : props.theme.beneficiaryComponentGrayBoxBG};
  font-size: 14px;
  border: 1px solid ${props => props.theme.checkboxBorderColor};
`;

const SepBox = styled.div`
  display: block;
  padding: 25px;
`;

const AddEditButton = styled.div`
  cursor: pointer;
  align-items: center;
  font-size: 11px;
  color: ${props => props.theme.beneficiarySubTitleCLR};
  display: inline-block;

  &:hover {
    background-color: transparent;
  }
`;

const AddTrustedAngelButton = styled(AddEditButton)`
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
  padding-top: 23px;
`;

const ContentCollection = styled.div`
  padding-top: 0px;
  display: block;
  padding-bottom: 0px;
  white-space: pre-wrap;
`;

const ActionCollection = styled.div`
  padding-top: 12px;
  padding-bottom: 0px;
  display: block;
`;

const UserInfo = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
`;

const UserLine = styled.p`
  margin-top: 6px;
  padding: 0;
  margin-bottom: 0;
`;
const UserSpan = styled.span`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
`;

const Name = styled.div`
  padding-top: 35px;
  display: block;
  font-size: 16px;
  font-weight: 600;
`;

const Private = styled.span`
  font-size: 12px;
`;

const YellowBoxContainer = styled.div`
  padding-bottom: 23px;
  width: 100%;
`;

const YellowBox = styled.div`
  width: 100%;
  left: 524px;
  top: 461px;

  background: #ffe600;
  border: 1px solid #000000;
  box-sizing: border-box;

  padding: 15px;
`;

const SelectDaysContainer = styled.span`
  font-weight: bold;
  border-bottom: 1px solid ${({ theme: { mode } }) => (mode === "dark" ? "#EEE" : "#000")};
  cursor: ${props => (props.isDisabled === true ? "auto" : "pointer")};
`;

const YellowBoxText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-bottom: 12px;
  color: #000;
`;

const YellowBoxDismissButton = styled.button`
  border: 1px solid red;
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  width: 8px;
  height: 8px;
  float: right;
  background: url(${YellowBoxDismissIcon}) no-repeat right;
  background-position: right center;
`;

const DaysOptionMenuItem = styled.div`
  height: 40px;
  padding-right: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const DaysOptionLabel = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: "#000000";
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
`;

const DaysSelectedOptionLabel = styled(DaysOptionLabel)`
  font-weight: bold;
`;

const SubtitleWrapper1 = styled.div`
  max-width: 340px;
`;

const SubtitleWrapper2 = styled.div`
  max-width: 585px;
`;

const days = i18n.t("days");

const daysOptions = [{ value: 15 }, { value: 30 }, { value: 45 }, { value: 60 }, { value: 90 }];

const TEST_MAIL_TYPE_BENEFICIARY = 0;
const TEST_MAIL_TYPE_TRUSTED_ANGEL = 1;

const SubtitleComponent = () => {
  const { id: currentPortfolioId } = useSelector(currentPortfolioSelector);
  const reversePortfolioLinkedIdMap = useSelector(portfoioTreeReverseLinkedIdMapSelector);
  const parentBeneficiaryDetails = useParentBeneficiaryDetails(currentPortfolioId);
  const beneficiaryNamesJoined = parentBeneficiaryDetails.beneficiaryNames.join(", ");

  return (
    <SubTitle>
      {i18n
        .t(
          !reversePortfolioLinkedIdMap[currentPortfolioId] || beneficiaryNamesJoined === i18n.t("notSet")
            ? "beneficiary.subTitle"
            : "beneficiary.subTitleWhenLinked"
        )
        .replace(/%beneficiaries%/, beneficiaryNamesJoined)}
    </SubTitle>
  );
};

class BeneficiaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddEditBeneficiaryClick = this.handleAddEditBeneficiaryClick.bind(this);
    this.handleAddEditTrustedAngelClick = this.handleAddEditTrustedAngelClick.bind(this);
    this.handleBeneficiarySelfMailClick = this.handleBeneficiarySelfMailClick.bind(this);
    this.handleTrustedAngelSelfMailClick = this.handleTrustedAngelSelfMailClick.bind(this);
    this.handleDismissBeneficiaryYellowBox = this.handleDismissBeneficiaryYellowBox.bind(this);
    this.handleDaysOptionsButtonClick = this.handleDaysOptionsButtonClick.bind(this);
    this.handleDaysOptionsSelection = this.handleDaysOptionsSelection.bind(this);
    this.handleRemoveTrustedAngel = this.handleRemoveTrustedAngel.bind(this);
    this.handleRemoveBeneficiary = this.handleRemoveBeneficiary.bind(this);
    this.handleDismissTrustedAngelYellowBox = this.handleDismissTrustedAngelYellowBox.bind(this);
    this.handleDisableDownloadLink = this.handleDisableDownloadLink.bind(this);
    this.handleDownloadPortfolio = this.handleDownloadPortfolio.bind(this);
    this.confirmBeneficiaryRemoval = this.confirmBeneficiaryRemoval.bind(this);
    this.confirmTrustedAngelRemoval = this.confirmTrustedAngelRemoval.bind(this);
    this.getMaskedEmail = this.getMaskedEmail.bind(this);
    this.getMaskedName = this.getMaskedName.bind(this);

    this.handleCancelHome = this.handleCancelHome.bind(this);
    this.handleCancelBenAdd = this.handleCancelBenAdd.bind(this);
    this.handleEmptyPortfolioDialogDismiss = this.handleEmptyPortfolioDialogDismiss.bind(this);
    this.handleTestMailPositiveButtonClick = this.handleTestMailPositiveButtonClick.bind(this);
    this.handleRemovalClose = this.handleRemovalClose.bind(this);
    this.state = {
      isLoading: true,
      error: null,
      selectedDaysOption: 45,
      hideBeneficiaryYellowBox: true,
      hideAngelYellowBox: true,
      showDetailType: SHOW_DETAIL_TYPE.HOME,
      showEmptyPortfolioDialog: false,
      isAngelTestMailConfirm: false,
      isBeneficiaryTestMailConfirm: false,
      isBeneficiaryRemovalConfirm: false,
      isAngelRemovalConfirm: false
    };

    this.daysOptionsMenuRef = React.createRef();
  }

  handleDaysOptionsButtonClick(event) {
    if (this.props.isSharedPortfolioAccountUser === true) {
      return;
    }
    if (this.daysOptionsMenuRef.current.isVisible() === true) {
      this.daysOptionsMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.daysOptionsMenuRef.current.show(
      [],
      targetPosition.left + targetPosition.width - this.daysOptionsMenuRef.current.getWidth(),
      targetPosition.top + targetPosition.height + 10,
      true,
      event.target
    );
  }

  componentDidMount() {
    this.getBeneficiaryDetails();
  }

  componentDidUpdate(oldProps) {
    if (this.props.portfolio.id !== oldProps.portfolio.id) {
      this.setProperInitialValues();
    }
  }

  setProperInitialValues() {
    const beneficiary = this.props.beneficiary;

    if (!beneficiary) {
      this.setState({ showDetailType: SHOW_DETAIL_TYPE.BENEFICIARY_ADD });
      return;
    }

    const hideBeneficiaryYellowBox = !beneficiary.reminderCount === true;
    if (this.state.hideBeneficiaryYellowBox !== hideBeneficiaryYellowBox) {
      this.setState({ hideBeneficiaryYellowBox: hideBeneficiaryYellowBox });
    }

    const hideAngelYellowBox = !beneficiary.angelReminderCount === true;
    if (this.state.hideAngelYellowBox !== hideAngelYellowBox) {
      this.setState({ hideAngelYellowBox: hideAngelYellowBox });
    }

    const validBeneficiary = beneficiary && beneficiary.email ? true : false;
    if (validBeneficiary === false && this.state.showDetailType !== SHOW_DETAIL_TYPE.BENEFICIARY_ADD) {
      this.setState({ showDetailType: SHOW_DETAIL_TYPE.BENEFICIARY_ADD });
    } else {
      this.setState({ showDetailType: SHOW_DETAIL_TYPE.HOME });
    }
  }

  getBeneficiaryDetails() {
    const user = this.props.userSelector;
    if (user) {
      this.setState({ selectedDaysOption: user.inactivityThreshold });
    } else {
      this.props.fetchUser(
        user => {
          this.setState({ selectedDaysOption: user.inactivityThreshold });
        },
        apiError => {}
      );
    }

    const beneficiaries = this.props.beneficiaries;

    if (beneficiaries && beneficiaries.length > 0) {
      this.setState({ isLoading: false, error: null });
      this.setProperInitialValues();
    } else {
      this.props.fetchBeneficiaries(
        beneficiaries => {
          this.setState({ isLoading: false, error: null });
          this.setProperInitialValues();
        },
        apiError => {
          this.setState({ isLoading: false, error: apiError });
        }
      );
    }
  }

  handleDownloadPortfolio() {
    if (this.props.hasValidPortfolio) {
      if (this.props.isAmcUser === true) {
        ApiClient.getPortfolioDownloadUrl(this.props.portfolio.id)
          .then(apiResponse => {
            downloadFile(apiResponse.payload.url, this.props.portfolio.name);
          })
          .catch(apiError => {});
      } else {
        this.props.exportPortfolio(this.props.portfolio.id, this.props.portfolio.name);
        PortfolioDownloadDialog.show(this.props.history, this.props.location);
      }
    } else {
      this.setState({ showEmptyPortfolioDialog: true });
    }
  }

  handleDaysOptionsSelection(selectedDaysOption) {
    this.daysOptionsMenuRef.current.dismiss();
    const user = this.props.userSelector;
    if (user) {
      user.inactivityThreshold = selectedDaysOption.value;
      this.setState({ selectedDaysOption: selectedDaysOption.value });
      this.props.updateUser(user);
    } else {
      console.log("Invalid user info");
    }
  }

  handleAddEditBeneficiaryClick() {
    this.setState({ showDetailType: SHOW_DETAIL_TYPE.BENEFICIARY_EDIT });
  }

  handleAddEditTrustedAngelClick() {
    this.setState({ showDetailType: SHOW_DETAIL_TYPE.TRUSTED_ANGEL_EDIT });
  }

  handleEmptyPortfolioDialogDismiss() {
    this.setState({ showEmptyPortfolioDialog: false });
  }

  handleRemoveTrustedAngel() {
    this.handleRemovalClose();

    const currentBeneficiary = this.props.beneficiary;
    const beneficiary = Object.assign({}, currentBeneficiary);
    beneficiary["angelName"] = null;
    beneficiary["angelEmail"] = null;
    beneficiary["angelReminderCount"] = 0;
    beneficiary["angelDownloadCount"] = 0;
    beneficiary["angelDownloadUrl"] = null;
    this.props.updateBeneficiaries([beneficiary]);
  }

  handleRemoveBeneficiary() {
    this.handleRemovalClose();
    this.setState({ showDetailType: SHOW_DETAIL_TYPE.BENEFICIARY_ADD });

    const currentBeneficiary = this.props.beneficiary;
    const beneficiary = Object.assign({}, currentBeneficiary);
    beneficiary["angelName"] = null;
    beneficiary["angelEmail"] = null;
    beneficiary["angelReminderCount"] = 0;
    beneficiary["angelDownloadCount"] = 0;
    beneficiary["angelDownloadUrl"] = null;
    beneficiary["name"] = null;
    beneficiary["email"] = null;
    beneficiary["reminderCount"] = 0;
    beneficiary["downloadCount"] = 0;
    beneficiary["downloadUrl"] = null;
    this.props.updateBeneficiaries([beneficiary]);
  }

  handleBeneficiarySelfMailClick() {
    this.props.sendTestMail(this.props.portfolio.id, TEST_MAIL_TYPE_BENEFICIARY);
    this.confirmSendTestMail(false);
  }

  handleTrustedAngelSelfMailClick() {
    this.props.sendTestMail(this.props.portfolio.id, TEST_MAIL_TYPE_TRUSTED_ANGEL);
    this.confirmSendTestMail(true);
  }

  handleTestMailPositiveButtonClick() {
    this.setState({
      isBeneficiaryTestMailConfirm: false,
      isAngelTestMailConfirm: false
    });
  }

  handleDismissBeneficiaryYellowBox() {
    this.setState({ hideBeneficiaryYellowBox: true });
  }

  handleDismissTrustedAngelYellowBox() {
    this.setState({ hideAngelYellowBox: true });
  }

  handleDisableDownloadLink() {
    const beneficiary = this.props.beneficiary;

    beneficiary["downloadCount"] = 0;
    beneficiary["reminderCount"] = 0;
    beneficiary["downloadUrl"] = null;

    beneficiary["angelDownloadCount"] = 0;
    beneficiary["angelReminderCount"] = 0;
    beneficiary["angelDownloadUrl"] = null;

    this.props.updateBeneficiaries([beneficiary]);
    this.handleDismissBeneficiaryYellowBox();
  }

  // normal back
  handleCancelHome() {
    this.setState({ showDetailType: SHOW_DETAIL_TYPE.HOME });
  }

  // return from Beneficiary Add
  handleCancelBenAdd() {
    this.setState({ showDetailType: SHOW_DETAIL_TYPE.HOME }, () => {
      this.setState({ showDetailType: SHOW_DETAIL_TYPE.BENEFICIARY_ADD });
    });
  }

  confirmBeneficiaryRemoval() {
    this.confirmRemoval(false);
  }

  confirmTrustedAngelRemoval() {
    this.confirmRemoval(true);
  }

  confirmSendTestMail(isAngel) {
    if (isAngel) {
      this.setState({
        isAngelTestMailConfirm: true
      });
    } else {
      this.setState({
        isBeneficiaryTestMailConfirm: true
      });
    }
  }

  confirmRemoval(isAngel) {
    if (isAngel) {
      this.setState({
        isAngelRemovalConfirm: true
      });
    } else {
      this.setState({
        isBeneficiaryRemovalConfirm: true
      });
    }
  }

  handleRemovalClose() {
    this.setState({
      isBeneficiaryRemovalConfirm: false,
      isAngelRemovalConfirm: false
    });
  }

  getMaskedName(name) {
    return `xxxxxx`;
  }

  getMaskedEmail(email) {
    return `xxxx${email.substring(email.lastIndexOf("@"))}`;
  }

  render() {
    const isAmcUser = this.props.isAmcUser;
    const beneficiary = this.props.beneficiary;
    const validBeneficiary = beneficiary && beneficiary.email ? true : false;
    const validTrustedAngel = beneficiary && beneficiary.angelEmail ? true : false;
    let beneficiaryName;
    let trustedAngelName;
    let beneficiaryDetails = "";
    let trustedAngelDetails = "";
    let reminderCount = 0;
    let angelReminderCount = 0;
    let downloadCount = 0;
    let angelDownloadCount = 0;
    let maskPrivateData = this.props.userMaskAllValues;

    if (validBeneficiary) {
      if (beneficiary.name) {
        beneficiaryName = maskPrivateData ? this.getMaskedName(beneficiary.name) : beneficiary.name;
      }
      beneficiaryDetails = maskPrivateData ? this.getMaskedEmail(beneficiary.email) : beneficiary.email;

      reminderCount = beneficiary.reminderCount;
      downloadCount = beneficiary.downloadCount;
    }

    if (validTrustedAngel) {
      if (beneficiary.angelName) {
        trustedAngelName = maskPrivateData ? this.getMaskedName(beneficiary.angelName) : beneficiary.angelName;
      }
      trustedAngelDetails = maskPrivateData ? this.getMaskedEmail(beneficiary.angelEmail) : beneficiary.angelEmail;

      angelReminderCount = beneficiary.angelReminderCount;
      angelDownloadCount = beneficiary.angelDownloadCount;
    }

    let userInfo = (
      <UserLine>
        <UserSpan data-private fontWeight="600">
          {maskPrivateData ? this.getMaskedEmail(this.props.userEmail) : this.props.userEmail}
        </UserSpan>
      </UserLine>
    );

    if (this.state.isLoading === true) {
      return (
        <EmptyContainer>
          <Loader />
        </EmptyContainer>
      );
    } else if (this.state.showDetailType === SHOW_DETAIL_TYPE.HOME) {
      return (
        <ContentContainer>
          <Container>
            <div>
              <Title>{i18n.t("beneficiary.title")}</Title>
            </div>
            <SubtitleComponent />
            <SepBox />
            <WrapperBox>
              <TextBox>
                {i18n.t("beneficiary.generalSectionTitle")}
                &nbsp;
                <SelectDaysContainer
                  onClick={this.handleDaysOptionsButtonClick}
                  isDisabled={this.props.isSharedPortfolioAccountUser === true}
                >
                  {this.state.selectedDaysOption}&nbsp;{days}
                </SelectDaysContainer>
                <span>{"..."}</span>
                <ContextMenu ref={this.daysOptionsMenuRef}>
                  {daysOptions.map((option, index) => {
                    return (
                      <DaysOptionMenuItem key={index}>
                        {this.state.selectedDaysOption === option.value ? (
                          <DaysSelectedOptionLabel onClick={() => this.handleDaysOptionsSelection(option)}>
                            {option.value}&nbsp;{days}{" "}
                          </DaysSelectedOptionLabel>
                        ) : (
                          <DaysOptionLabel onClick={() => this.handleDaysOptionsSelection(option)}>
                            {option.value}&nbsp;{days}{" "}
                          </DaysOptionLabel>
                        )}
                      </DaysOptionMenuItem>
                    );
                  })}
                </ContextMenu>
              </TextBox>
              <GrayBox>
                <ContentCollection>
                  <span>{i18n.t("beneficiary.generalSectionSubTitlePart1")}</span>
                  {userInfo}
                </ContentCollection>
              </GrayBox>
              <SepBox />
            </WrapperBox>
            <WrapperBox>
              <TextBox>
                {i18n.t("beneficiary.beneficiarySectionTitle").replace("%s%", this.props.accountOwnerName)}
              </TextBox>
              <GrayBox>
                {validBeneficiary === false ||
                this.state.hideBeneficiaryYellowBox === true ||
                !reminderCount ||
                reminderCount === 0 ? null : (
                  <YellowBoxContainer>
                    <YellowBox>
                      <YellowBoxDismissButton onClick={this.handleDismissBeneficiaryYellowBox}></YellowBoxDismissButton>
                      <YellowBoxText>
                        {i18n.t("beneficiary.Notified")}: {reminderCount !== 1 ? `${reminderCount} times.` : "Once"}{" "}
                        <br />
                        {i18n.t("beneficiary.Downloaded")}: {downloadCount !== 1 ? `${downloadCount} times.` : "Once"}
                      </YellowBoxText>
                      <AddEditButton onClick={this.handleDisableDownloadLink}>
                        {i18n.t("beneficiary.disableDownloadLink").toUpperCase()}
                      </AddEditButton>
                    </YellowBox>
                  </YellowBoxContainer>
                )}
                <ContentCollection>
                  <SubtitleWrapper1>
                    {i18n.t("beneficiary.beneficiarySectionSubTitle")}
                    <UserInfo>{i18n.t("beneficiary")}</UserInfo>.
                  </SubtitleWrapper1>
                  <br />
                  {beneficiaryName !== undefined && <Name data-private>{beneficiaryName}</Name>}
                  {validBeneficiary === true && <Private data-private>{beneficiaryDetails}</Private>}
                </ContentCollection>
                {this.props.isSharedPortfolioAccountUser === false && (
                  <ActionCollection>
                    <AddEditButton data-cy="addEditBeneficiaryButton" onClick={this.handleAddEditBeneficiaryClick}>
                      {validBeneficiary === true
                        ? i18n.t("beneficiary.changeBeneficiaryButtonCaption").toUpperCase()
                        : i18n.t("beneficiary.addBeneficiaryButtonCaption").toUpperCase()}
                    </AddEditButton>
                    <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                    {validBeneficiary === true ? (
                      <AddEditButton data-cy="beneficiaryRemove" onClick={this.confirmBeneficiaryRemoval}>
                        {i18n.t("remove").toUpperCase()}
                      </AddEditButton>
                    ) : null}
                    {this.state.isBeneficiaryRemovalConfirm && (
                      <ConfirmationDialog
                        title={i18n.t("beneficiary.removeBeneficiaryTitle")}
                        description={i18n.t("beneficiary.areYouSure")}
                        positiveButtonTitle={i18n.t("remove")}
                        handlePositiveButtonClick={this.handleRemoveBeneficiary}
                        negativeButtonTitle={i18n.t("cancel")}
                        handleNegativeButtonClick={this.handleRemovalClose}
                      />
                    )}
                    {this.state.isAngelRemovalConfirm && (
                      <ConfirmationDialog
                        title={i18n.t("beneficiary.removeTrustedAngelTitle")}
                        description={i18n.t("beneficiary.areYouSure")}
                        positiveButtonTitle={i18n.t("remove")}
                        handlePositiveButtonClick={this.handleRemoveTrustedAngel}
                        negativeButtonTitle={i18n.t("cancel")}
                        handleNegativeButtonClick={this.handleRemovalClose}
                      />
                    )}
                    {validBeneficiary === true && isAmcUser === false ? <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span> : null}
                    {isAmcUser === false && (
                      <AddEditButton data-cy="beneficiaryTestMail" onClick={this.handleBeneficiarySelfMailClick}>
                        {i18n.t("beneficiary.selfMailButtonCaption").toUpperCase()}
                      </AddEditButton>
                    )}
                    {this.state.isBeneficiaryTestMailConfirm && (
                      <ConfirmationDialog
                        canUserDismiss
                        title={i18n.t("beneficiary.testMailTitle")}
                        description={i18n
                          .t("beneficiary.testMailSubTitleForBeneficiary")
                          .replace("{email}", this.props.userEmail)}
                        positiveButtonTitle={i18n.t("ok")}
                        handlePositiveButtonClick={this.handleTestMailPositiveButtonClick}
                        onDismiss={this.handleTestMailPositiveButtonClick}
                      />
                    )}
                    {this.state.isAngelTestMailConfirm && (
                      <ConfirmationDialog
                        canUserDismiss
                        title={i18n.t("beneficiary.testMailTitle")}
                        description={i18n
                          .t("beneficiary.testMailSubTitleForTrustedAngel")
                          .replace("{email}", this.props.userEmail)}
                        positiveButtonTitle={i18n.t("ok")}
                        handlePositiveButtonClick={this.handleTestMailPositiveButtonClick}
                        onDismiss={this.handleTestMailPositiveButtonClick}
                      />
                    )}
                  </ActionCollection>
                )}
              </GrayBox>
              <SepBox />
            </WrapperBox>
            <WrapperBoxEnd>
              <TextBox>
                If&nbsp;
                {beneficiaryName !== undefined ? (
                  <Private data-private style={{ fontSize: "14px" }}>
                    {beneficiaryName}
                  </Private>
                ) : (
                  i18n.t("beneficiary")
                )}
                &nbsp;
                {i18n.t("beneficiary.trustedAngelSectionTitle").replace("%s%", beneficiaryName)}
              </TextBox>
              <GrayBox isEmptyState={!validTrustedAngel}>
                {validTrustedAngel === false || this.state.hideAngelYellowBox || !angelReminderCount === true ? null : (
                  <YellowBoxContainer>
                    <YellowBox>
                      <YellowBoxDismissButton
                        onClick={this.handleDismissTrustedAngelYellowBox}
                      ></YellowBoxDismissButton>
                      <YellowBoxText>
                        {i18n.t("beneficiary.Notified")}:{" "}
                        {angelReminderCount !== 1 ? `${angelReminderCount} times.` : "Once"} <br />
                        {i18n.t("beneficiary.Downloaded")}:{" "}
                        {angelDownloadCount !== 1 ? `${angelDownloadCount} times.` : "Once"}
                      </YellowBoxText>
                      <AddEditButton onClick={this.handleDisableDownloadLink}>
                        {i18n.t("beneficiary.disableDownloadLink").toUpperCase()}
                      </AddEditButton>
                    </YellowBox>
                  </YellowBoxContainer>
                )}

                <ContentCollection>
                  <SubtitleWrapper2>
                    {i18n.t("beneficiary.trustedAngelSectionSubTitle")}
                    <UserInfo>{i18n.t("beneficiary.trustedAngel")}</UserInfo>.
                  </SubtitleWrapper2>
                  <br />
                  {trustedAngelName !== undefined && <Name data-private>{trustedAngelName}</Name>}
                  {validTrustedAngel === true && <Private data-private>{trustedAngelDetails}</Private>}
                </ContentCollection>

                {this.props.isSharedPortfolioAccountUser === false && validTrustedAngel === true && (
                  <ActionCollection>
                    <AddEditButton data-cy="changeTrustedAngel" onClick={this.handleAddEditTrustedAngelClick}>
                      {i18n.t("beneficiary.changeTrustedAngelButtonCaption").toUpperCase()}
                    </AddEditButton>
                    &nbsp;&nbsp;•&nbsp;&nbsp;
                    <AddEditButton data-cy="angelRemove" onClick={this.confirmTrustedAngelRemoval}>
                      {i18n.t("remove").toUpperCase()}
                    </AddEditButton>
                    {isAmcUser === false && <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>}
                    {isAmcUser === false && (
                      <AddEditButton data-cy="angelTestMail" onClick={this.handleTrustedAngelSelfMailClick}>
                        {i18n.t("beneficiary.selfMailButtonCaption").toUpperCase()}
                      </AddEditButton>
                    )}
                  </ActionCollection>
                )}

                {this.props.isSharedPortfolioAccountUser === false && validTrustedAngel !== true && (
                  <ActionCollection>
                    <AddTrustedAngelButton data-cy="angelAdd" onClick={this.handleAddEditTrustedAngelClick}>
                      {i18n.t("beneficiary.addTrustedAngelButtonCaption")}
                    </AddTrustedAngelButton>
                    <br />
                    {i18n.t("beneficiary.angelInfo")}
                    {isAmcUser === false && <br />}
                    {isAmcUser === false && <br />}
                    {isAmcUser === false && (
                      <AddEditButton data-cy="angelTestMail" onClick={this.handleTrustedAngelSelfMailClick}>
                        {i18n.t("beneficiary.selfMailButtonCaption").toUpperCase()}
                      </AddEditButton>
                    )}
                  </ActionCollection>
                )}
              </GrayBox>
            </WrapperBoxEnd>
          </Container>
          {this.state.showEmptyPortfolioDialog === true && (
            <ConfirmationDialog
              canUserDismiss={true}
              title={i18n.t("beneficiary.emptyPortfolioTitle")}
              description={i18n.t("beneficiary.emptyPortfolioDesc")}
              positiveButtonTitle={i18n.t("ok")}
              onDismiss={this.handleEmptyPortfolioDialogDismiss}
              handlePositiveButtonClick={this.handleEmptyPortfolioDialogDismiss}
            />
          )}
        </ContentContainer>
      );
    } else if (this.state.showDetailType === SHOW_DETAIL_TYPE.BENEFICIARY_ADD) {
      return (
        <ModifyBeneficiaryComponent
          portfolioId={this.props.portfolio.id}
          viewType={SHOW_DETAIL_TYPE.BENEFICIARY_ADD}
          onCancel={this.handleCancelBenAdd}
          onSave={this.handleCancelHome}
        />
      );
    } else if (this.state.showDetailType === SHOW_DETAIL_TYPE.BENEFICIARY_EDIT) {
      return (
        <ModifyBeneficiaryComponent
          portfolioId={this.props.portfolio.id}
          viewType={SHOW_DETAIL_TYPE.BENEFICIARY_EDIT}
          onCancel={this.handleCancelHome}
          onSave={this.handleCancelHome}
        />
      );
    } else if (this.state.showDetailType === SHOW_DETAIL_TYPE.TRUSTED_ANGEL_EDIT) {
      return (
        <ModifyBeneficiaryComponent
          portfolioId={this.props.portfolio.id}
          viewType={SHOW_DETAIL_TYPE.TRUSTED_ANGEL_EDIT}
          onCancel={this.handleCancelHome}
          onSave={this.handleCancelHome}
        />
      );
    } else {
      return <Container>Error</Container>;
    }
  }
}

const mapStateToProps = (state, props) => ({
  portfolio: currentPortfolioSelector(state),
  hasValidPortfolio: hasValidPortfolioSelector(state),
  userEmail: userEmailSelector(state, true),
  userSelector: userSelector(state),
  userMaskAllValues: userMaskAllValuesSelector(state),
  beneficiaries: beneficiariesSelector(state),
  beneficiary: beneficiarySelector(state, currentPortfolioSelector(state).id),
  isAmcUser: isAmcUser(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state),
  accountOwnerName: getAccountOwnerNameSelector(state)
});

const mapDispatchToProps = {
  exportPortfolio: exportPortfolio,
  fetchBeneficiaries: fetchBeneficiaries,
  fetchUser: fetchUser,
  updateUser: updateUser,
  sendTestMail: sendTestMail,
  updateBeneficiaries: updateBeneficiaries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BeneficiaryComponent));
