import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useDispatch } from "react-redux";

import { generateBackupCodes, isAppInWhiteLabelMode, store, siteConfigSelector } from "@kubera/common";
import { Dialog } from "components/dialog/DialogOverlay";
import Spinner from "components/loader/Spinner";

const ComponentDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 416px;
  margin-top: 80px;
  padding: 45px 54px 50px 57px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.45px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 12px;
  white-space: pre-wrap;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 140%;
  margin-bottom: 15px;
  width: 312px;
  white-space: pre-wrap;
`;

const CodesBlk = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 41px;
`;

const Codes = styled.div`
  font-family: Roboto Mono;
  font-weight: 500;
  font-size: 30px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  width: 100%;
  padding: 20px 0px;
`;

const BtnWrapper = styled.div`
  position: relative;
  height: 32px;
`;

const PrimarBtn = styled.a`
  min-width: 137px;
  color: #fff;
  background: #000;
  padding: 15px 37px;
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
  font-feature-settings: "ss01" on;
  text-decoration: none;
  outline: 0;
`;

const GenerateNewCodesBtn = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  line-height: 140%;
  text-align: right;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-wrap;
  cursor: pointer;
`;

const Loader = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const SaveBackupCodes = ({ onDownload = () => null }) => {
  const dispatch = useDispatch();
  const isInit = useRef(true);

  const [isLoading, setIsLoading] = useState();
  const [codes, setCodes] = useState([]);

  const getCodes = async () => {
    setIsLoading(true);
    const codes = await dispatch(generateBackupCodes());
    setCodes(codes.payload);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      getCodes();
    }
  });

  const content =
    i18n.t("twoFA.downloadedTxtContent") + codes.reduce((codeStr, code) => codeStr + code + "\n\n", "\n\n");
  const downloadHref = "data:application/txt," + encodeURIComponent(content);
  const filePrefix =
    isAppInWhiteLabelMode() === true ? siteConfigSelector(store.getState()).shortName : i18n.t("appName");

  return (
    <ComponentDialog>
      <form>
        <Title>{i18n.t("twoFA.smsShowCodesTitle")}</Title>
        <Desc>{i18n.t("twoFA.smsShowCodesDesc")}</Desc>
        <CodesBlk>
          {isLoading ? (
            <Loader />
          ) : (
            codes.map((eachCode, index) => (
              <Codes isEven={index % 2 === 0} key={eachCode} data-private>
                {eachCode.replace(/(\d{3})(\d{3})(\d{3})/g, "$1 $2 $3")}
              </Codes>
            ))
          )}
        </CodesBlk>
        <BtnWrapper>
          {isLoading ? null : (
            <>
              <PrimarBtn href={downloadHref} download={`${filePrefix}-backup-codes.txt`} onClick={onDownload}>
                {i18n.t("download")}
              </PrimarBtn>
              <GenerateNewCodesBtn onClick={getCodes}>{i18n.t("twoFA.generateNewCodesBtn")}</GenerateNewCodesBtn>
            </>
          )}
        </BtnWrapper>
      </form>
    </ComponentDialog>
  );
};

export default SaveBackupCodes;
