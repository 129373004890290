import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  repeatFrequency,
  guessDate,
  getKuberaDateString,
  userDobSelector,
  guessFutureDate,
  dateForUserAge,
  store,
  getCustodianHistoryFormattedDateString,
  planningRules,
  planningVariables,
  parseKuberaDateString,
  formatDateOfYearToMonth,
  isDateString,
  guessDateFromUserInput,
  defaultDateOfYear
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import NumberInput from "components/inputs/NumberInput";
import DropDown from "components/inputs/DropDown";
import PercentageInput from "components/inputs/PercentageInput";
import { ReactComponent as ClearIcon } from "assets/images/delete_user_icon.svg";
import DateInput from "components/inputs/DateInput";

const tillDateMode = {
  AGE: "age",
  DATE: "date"
};

const anchorMonthFreqs = [repeatFrequency.QUARTERLY, repeatFrequency.BI_ANNUALLY, repeatFrequency.YEARLY];

const RepeatDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 30px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const InputTitle = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const FrequencyDropDown = styled(DropDown)`
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
`;

const DateOfYearInput = styled(DateInput)`
  margin-top: 5px;
  label {
    height: 45px;
    padding: 12px 20px 12px 7px;
  }
`;

const ChangeByDropDown = styled(DropDown)`
  margin-top: ${props => (props.lessmargin ? "-5px" : "13px")};
  background: transparent;
  padding: 0;
  border: 0;
`;

const ChangeByContainer = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: white;
`;

const ChangeByInput = styled(PercentageInput)`
  flex: 1;
  border: 0;
  outline: 0;
  font-weight: 400;
`;

const ChangeByFrequencyDropDown = styled(DropDown)`
  border: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  width: 122px;
`;

const TillThereafterLabel = styled.div`
  margin-top: ${props => (props.extramargin === true ? "27px" : "10px")};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #0074fc;
  cursor: pointer;
`;

const TillDropDownContainer = styled.div`
  position: relative;
  margin-top: ${props => (props.lessmargin ? "-5px" : "13px")};
`;

const TillDateTitle = styled(InputTitle)`
  margin-top: 0;
`;

const TillDropDown = styled(DropDown)`
  background: transparent;
  padding: 0;
  border: 0;
`;

const TillAgeInput = styled(NumberInput)`
  margin-top: 5px;
  padding: 14px 15px 14px 15px;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: white;
  font-weight: 400;
  ::placeholder {
    font-weight: 400;
  }
`;

const ThereafterTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${props => (props.extramargin === true ? "25px" : "8px")};
  margin-bottom: -25px;
`;

const HitArea = styled.div`
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
`;

const ClearButton = styled(ClearIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ThereafterTitle = styled(InputTitle)`
  margin-top: 0;
  flex: 1;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: ${props => (props.lessMargin ? "20px" : "40px")};
`;

class RepeatsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frequency: props.data ? props.data.frequency : repeatFrequency.MONTHLY,
      dateOfYear: props.data ? formatDateOfYearToMonth(props.data.dateOfYear || defaultDateOfYear) : null,
      changeBy:
        props.data && props.data.changeBy
          ? { ...props.data.changeBy, dateOfYear: formatDateOfYearToMonth(props.data.changeBy.dateOfYear) }
          : { increasing: true, frequency: repeatFrequency.YEARLY },
      showTill:
        !props.data === false &&
        !props.data.till === false &&
        (!props.data.till.age === false || !props.data.till.date === false),
      till: props.data && props.data.till ? { ...props.data.till, date: this.formatDate(props.data.till.date) } : {},
      tillMode:
        !props.data === false &&
        !this.props.userDob === false &&
        !props.data.till === false &&
        !props.data.till.age === false
          ? tillDateMode.AGE
          : tillDateMode.DATE,
      showThereafter:
        !props.data === false && !props.data.thereafter === false && !props.data.thereafter.percentage === false,
      thereafter: props.data && props.data.thereafter ? { ...props.data.thereafter } : this.getDefaultThereafter(),
      hasError: false
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFrequencyDropDownSelection = this.handleFrequencyDropDownSelection.bind(this);
    this.handleDateOfYearInputChange = this.handleDateOfYearInputChange.bind(this);
    this.handleChangeByDropDownSelection = this.handleChangeByDropDownSelection.bind(this);
    this.handleChangeByInputChange = this.handleChangeByInputChange.bind(this);
    this.handleChangeByFrequencySelection = this.handleChangeByFrequencySelection.bind(this);
    this.handleChangeByDateOfYearInput = this.handleChangeByDateOfYearInput.bind(this);
    this.handleTillThereafterClick = this.handleTillThereafterClick.bind(this);
    this.handleTillDropDownSelection = this.handleTillDropDownSelection.bind(this);
    this.handleTillDateInput = this.handleTillDateInput.bind(this);
    this.handleTillAgeInput = this.handleTillAgeInput.bind(this);
    this.handleThereafterClick = this.handleThereafterClick.bind(this);
    this.handleThereafterDropdownSelection = this.handleThereafterDropdownSelection.bind(this);
    this.handleThereafterInputChange = this.handleThereafterInputChange.bind(this);
    this.handleThereafterFrequencySelection = this.handleThereafterFrequencySelection.bind(this);
    this.handleClearTillClick = this.handleClearTillClick.bind(this);
    this.handleClearThereafterClick = this.handleClearThereafterClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTillDateBlur = this.handleTillDateBlur.bind(this);
  }

  formatDate(dateString) {
    if (!dateString === true) {
      return null;
    }
    return getCustodianHistoryFormattedDateString(parseKuberaDateString(dateString).getTime());
  }

  getDefaultThereafter() {
    return { increasing: true, frequency: repeatFrequency.YEARLY };
  }

  handleDateOfYearInputChange(newValue) {
    this.setState({ dateOfYear: newValue });
  }

  handleChangeByDropDownSelection(item) {
    var changeBy = this.state.changeBy;
    changeBy.increasing = item.id;
    this.setState({ changeBy: changeBy });
  }

  handleChangeByInputChange(e, value) {
    var changeBy = this.state.changeBy;
    changeBy.percentage = value;
    this.setState({ changeBy: changeBy });
  }

  handleChangeByFrequencySelection(item) {
    var changeBy = this.state.changeBy;
    changeBy.frequency = item.id;
    this.setState({ changeBy: changeBy });
  }

  handleChangeByDateOfYearInput(newValue) {
    var changeBy = this.state.changeBy;
    changeBy.dateOfYear = newValue;
    this.setState({ changeBy: changeBy });
  }

  handleTillThereafterClick(e) {
    this.setState({ showTill: true });
  }

  handleTillDropDownSelection(item) {
    this.setState({ tillMode: item.id });
  }

  handleTillDateInput(newValue) {
    var till = this.state.till;
    till.date = newValue;
    this.setState({ till: till });
  }

  handleTillAgeInput(e, value) {
    var till = this.state.till;
    till.age = value;
    this.setState({ till: till });
  }

  handleThereafterClick(e) {
    this.setState({ showThereafter: true });
  }

  handleThereafterDropdownSelection(item) {
    var thereafter = this.state.thereafter;
    thereafter.increasing = item.id;
    this.setState({ thereafter: thereafter });
  }

  handleThereafterInputChange(e, value) {
    var thereafter = this.state.thereafter;
    thereafter.percentage = !value === true ? 0 : value;
    this.setState({ thereafter: thereafter });
  }

  handleThereafterFrequencySelection(item) {
    var thereafter = this.state.thereafter;
    thereafter.frequency = item.id;
    this.setState({ thereafter: thereafter });
  }

  handleClearTillClick(e) {
    this.setState({
      tillMode: tillDateMode.DATE,
      till: {},
      showTill: false,
      thereafter: this.getDefaultThereafter(),
      showThereafter: false
    });
  }

  handleClearThereafterClick(e) {
    this.setState({ thereafter: this.getDefaultThereafter(), showThereafter: false });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveClick();
    }
  };

  handleSaveClick(e) {
    if (this.state.hasError) {
      return;
    }
    const dateOfYear = guessDate(this.state.dateOfYear);
    const variablesToUpdate = {
      dateOfYear: dateOfYear.isInvalid ? defaultDateOfYear : dateOfYear.dateString,
      frequency: this.state.frequency
    };

    if (!this.props.showDateOfYearInput) variablesToUpdate.dateOfYear = null;

    if (this.state.frequency === repeatFrequency.NO_REPEAT) {
      variablesToUpdate.dateOfYear = null;
      variablesToUpdate.changeBy = null;
      variablesToUpdate.till = null;
      variablesToUpdate.thereafter = null;
    } else {
      if (!this.state.changeBy.percentage === false) {
        variablesToUpdate.changeBy = this.state.changeBy;

        var guessedChangeByDateString = guessDate(this.state.changeBy.dateOfYear).dateString;
        if (!guessedChangeByDateString === true && this.state.changeBy.frequency === repeatFrequency.YEARLY) {
          guessedChangeByDateString = getKuberaDateString(new Date(new Date().getFullYear(), 0, 1).getTime());
        }
        if (!guessedChangeByDateString === false) {
          variablesToUpdate.changeBy.dateOfYear = guessedChangeByDateString;
        }
        if (this.state.frequency === repeatFrequency.YEARLY) {
          variablesToUpdate.changeBy.dateOfYear = null;
        }
      }

      if (this.state.tillMode === tillDateMode.DATE && !this.state.till.date === false) {
        const tillDate = guessFutureDate(this.state.till.date);
        variablesToUpdate.till = { date: tillDate.isInvalid ? null : tillDate.dateString };
      } else if (this.state.tillMode === tillDateMode.AGE && !this.state.till.age === false) {
        let dateAtAge = dateForUserAge(store.getState(), this.state.till.age);
        dateAtAge = new Date(dateAtAge.getFullYear(), dateAtAge.getMonth() + 1, 0, 0, 0, 0, 0);
        if (dateAtAge.getTime() > new Date().getTime()) {
          variablesToUpdate.till = { age: this.state.till.age, date: getKuberaDateString(dateAtAge.getTime()) };
        }
      }

      if (!variablesToUpdate.till === false && !this.state.thereafter.percentage === false) {
        variablesToUpdate.thereafter = this.state.thereafter;
      }
    }

    this.props.onVariableUpdate(variablesToUpdate);
    this.props.onDismiss();
  }

  handleFrequencyDropDownSelection(item) {
    var changeBy = this.state.changeBy;
    changeBy.frequency = item.id === repeatFrequency.YEARLY ? item.id : changeBy.frequency;

    var thereafter = this.state.thereafter;
    thereafter.frequency = item.id === repeatFrequency.YEARLY ? item.id : thereafter.frequency;

    this.setState({
      frequency: item.id,
      changeBy,
      thereafter
    });
  }

  getFrequencyDropDownData() {
    const frequencyOptions = [
      {
        id: repeatFrequency.MONTHLY,
        label: i18n.t("month"),
        selected: this.state.frequency === repeatFrequency.MONTHLY
      },
      {
        id: repeatFrequency.QUARTERLY,
        label: i18n.t("3Months"),
        selected: this.state.frequency === repeatFrequency.QUARTERLY
      },
      {
        id: repeatFrequency.BI_ANNUALLY,
        label: i18n.t("6Months"),
        selected: this.state.frequency === repeatFrequency.BI_ANNUALLY
      },
      {
        id: repeatFrequency.YEARLY,
        label: i18n.t("year"),
        selected: this.state.frequency === repeatFrequency.YEARLY
      }
    ];
    if (!this.shortText()) {
      frequencyOptions.push({
        id: repeatFrequency.NO_REPEAT,
        label: i18n.t("noRepeat"),
        selected: this.state.frequency === repeatFrequency.NO_REPEAT
      });
    }

    return frequencyOptions;
  }

  getChangeByDropDownData() {
    var data = [
      {
        id: true,
        label: i18n.t("increasingBy"),
        selected: this.state.changeBy.increasing === true
      },
      {
        id: false,
        label: i18n.t("decreasingBy"),
        selected: this.state.changeBy.increasing === false
      }
    ];
    return data;
  }

  getChangeByFrequencyDropDownData() {
    var data = [
      {
        id: repeatFrequency.YEARLY,
        label: i18n.t("everyYear"),
        selected: this.state.changeBy.frequency === repeatFrequency.YEARLY
      }
    ];

    if (this.state.frequency === repeatFrequency.MONTHLY) {
      data.unshift({
        id: repeatFrequency.MONTHLY,
        label: i18n.t("everyMonth"),
        selected: this.state.changeBy.frequency === repeatFrequency.MONTHLY
      });
    }
    return data;
  }

  getTillDateDropDownData() {
    var data = [
      {
        id: tillDateMode.DATE,
        label: `${i18n.t("till")} ${i18n.t("date").toLowerCase()}`,
        selected: this.state.tillMode === tillDateMode.DATE
      }
    ];

    if (!this.props.userDob === false) {
      data.push({
        id: tillDateMode.AGE,
        label: `${i18n.t("till")} ${i18n.t("age").toLocaleLowerCase()}`,
        selected: this.state.tillMode === tillDateMode.AGE
      });
    }
    return data;
  }

  getThereafterDropDownData() {
    var data = [
      {
        id: true,
        label: i18n.t("increasingBy"),
        selected: this.state.thereafter.increasing === true
      },
      {
        id: false,
        label: i18n.t("decreasingBy"),
        selected: this.state.thereafter.increasing === false
      }
    ];
    return data;
  }

  getThereafterFrequencyDropDownData() {
    var data = [
      {
        id: repeatFrequency.YEARLY,
        label: i18n.t("everyYear"),
        selected: this.state.thereafter.frequency === repeatFrequency.YEARLY
      }
    ];

    if (this.state.frequency === repeatFrequency.MONTHLY) {
      data.unshift({
        id: repeatFrequency.MONTHLY,
        label: i18n.t("everyMonth"),
        selected: this.state.thereafter.frequency === repeatFrequency.MONTHLY
      });
    }
    return data;
  }

  disableRevisions() {
    if (this.state.frequency === repeatFrequency.NO_REPEAT) {
      return true;
    }
    if (!this.props.rule === false) {
      const ruleObject = planningRules.find(item => item.type === this.props.rule.type);
      if (!ruleObject.data === true) {
        return false;
      }

      const repeatData = ruleObject.data[planningVariables.REPEAT];
      if (!repeatData === true) {
        return false;
      }
      if (!repeatData.props === true) {
        return false;
      }
      if (!this.props.data === false && (!this.props.data.changeBy === false || !this.props.data.till === false)) {
        return false;
      }
      return repeatData.props.disableRevisions;
    }
    return false;
  }

  shortText() {
    const ruleObject = planningRules.find(item => item.type === this.props.rule.type);
    const repeatData = ruleObject?.data[planningVariables.REPEAT];
    return repeatData?.props?.shortText;
  }

  handleTillDateBlur() {
    const tillDate = guessFutureDate(this.state.till.date);
    if (!tillDate.isInvalid) {
      this.setState({
        till: {
          ...this.state.till,
          date: this.formatDate(tillDate.dateString)
        }
      });
    }
  }

  renderMonthValue(val) {
    return !isDateString(val) ? val : formatDateOfYearToMonth(val);
  }

  render() {
    const disableRevisions = this.disableRevisions();
    const shortText = this.shortText();
    const tillDateDropdownData = this.getTillDateDropDownData();
    const showChangeByDateOfYearInput =
      this.state.changeBy.frequency === repeatFrequency.YEARLY && this.state.frequency !== repeatFrequency.YEARLY;
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <RepeatDialog>
          <Container>
            <InputTitle>{shortText ? i18n.t("Every") : i18n.t("repeatsEvery")}</InputTitle>
            <FrequencyDropDown
              width={360}
              items={this.getFrequencyDropDownData()}
              onSelection={this.handleFrequencyDropDownSelection}
            />
            {this.props.showDateOfYearInput && anchorMonthFreqs.includes(this.state.frequency) && (
              <>
                <InputTitle>{i18n.t("starting")}</InputTitle>
                <DateOfYearInput
                  inputPlaceholder={formatDateOfYearToMonth(defaultDateOfYear)}
                  autoFocus={true}
                  value={this.renderMonthValue(this.state.dateOfYear) || ""}
                  onDateChange={this.handleDateOfYearInputChange}
                  onValidDate={this.handleSaveClick}
                  monthMode={true}
                  errorMessage={i18n.t("enterValidMonth")}
                />
              </>
            )}
            {disableRevisions === false && (
              <>
                <>
                  <ChangeByDropDown
                    width={360}
                    selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
                    items={this.getChangeByDropDownData()}
                    onSelection={this.handleChangeByDropDownSelection}
                    lessmargin={this.props.showDateOfYearInput && this.state.frequency !== repeatFrequency.MONTHLY}
                  />
                  <ChangeByContainer>
                    <ChangeByInput
                      supressFocusBehaviour={true}
                      inputValidation={value => value < 0}
                      placeholder={"10%"}
                      value={this.state.changeBy.percentage}
                      onChange={this.handleChangeByInputChange}
                      onKeyDown={this.handleKeyDown}
                      callOnChangeOnClear={true}
                    />
                    <ChangeByFrequencyDropDown
                      width={360}
                      items={this.getChangeByFrequencyDropDownData()}
                      onSelection={this.handleChangeByFrequencySelection}
                    />
                  </ChangeByContainer>
                </>
                {showChangeByDateOfYearInput && (
                  <>
                    <InputTitle>{i18n.t("in")}</InputTitle>
                    <DateOfYearInput
                      stretch={true}
                      inputPlaceholder={i18n.t("dateOfYearPlaceholder")}
                      value={this.renderMonthValue(this.state.changeBy.dateOfYear) || ""}
                      onDateChange={this.handleChangeByDateOfYearInput}
                      onValidDate={this.handleSaveClick}
                      monthMode={true}
                      errorMessage={i18n.t("enterValidDayMonth")}
                    />
                  </>
                )}
                {this.state.showTill === false && (
                  <TillThereafterLabel
                    onClick={this.handleTillThereafterClick}
                    extramargin={!showChangeByDateOfYearInput}
                  >
                    {i18n.t("tillThereafter")}
                  </TillThereafterLabel>
                )}
                {this.state.showTill === true && (
                  <>
                    <TillDropDownContainer lessmargin={showChangeByDateOfYearInput}>
                      <>
                        {tillDateDropdownData.length > 1 ? (
                          <TillDropDown
                            width={360}
                            selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
                            items={tillDateDropdownData}
                            onSelection={this.handleTillDropDownSelection}
                          />
                        ) : (
                          <TillDateTitle>{tillDateDropdownData[0].label}</TillDateTitle>
                        )}
                      </>
                      <HitArea onClick={this.handleClearTillClick}>
                        <ClearButton />
                      </HitArea>
                    </TillDropDownContainer>
                    {this.state.tillMode === tillDateMode.DATE && (
                      <DateOfYearInput
                        stretch={true}
                        inputPlaceholder={i18n.t("date")}
                        value={this.state.till.date || ""}
                        onDateChange={this.handleTillDateInput}
                        onValidDate={this.handleSaveClick}
                        errorMessage={
                          guessDateFromUserInput(this.state.till.date, true) > new Date().getTime()
                            ? i18n.t("enterValidDate")
                            : i18n.t("enterFutureDate")
                        }
                        allowPastDate={() => false}
                        updateErrorState={hasError => this.setState({ hasError: hasError })}
                      />
                    )}
                    {this.state.tillMode === tillDateMode.AGE && (
                      <TillAgeInput
                        placeholder={i18n.t("age")}
                        value={this.state.till.age}
                        onChange={this.handleTillAgeInput}
                        onKeyDown={this.handleKeyDown}
                      />
                    )}
                  </>
                )}
                {this.state.showTill === true && this.state.showThereafter === false && (
                  <TillThereafterLabel
                    onClick={this.handleThereafterClick}
                    extramargin={this.state.tillMode === tillDateMode.AGE}
                  >
                    {i18n.t("thereafter")}
                  </TillThereafterLabel>
                )}
                {this.state.showTill === true && this.state.showThereafter === true && (
                  <>
                    <ThereafterTitleContainer extramargin={this.state.tillMode === tillDateMode.AGE}>
                      <ThereafterTitle>{i18n.t("thereafter")}</ThereafterTitle>
                      <HitArea onClick={this.handleClearThereafterClick}>
                        <ClearButton />
                      </HitArea>
                    </ThereafterTitleContainer>
                    <ChangeByDropDown
                      width={360}
                      selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
                      items={this.getThereafterDropDownData()}
                      onSelection={this.handleThereafterDropdownSelection}
                    />
                    <ChangeByContainer>
                      <ChangeByInput
                        supressFocusBehaviour={true}
                        inputValidation={value => value < 0}
                        placeholder={"10%"}
                        value={this.state.thereafter.percentage}
                        onChange={this.handleThereafterInputChange}
                        onKeyDown={this.handleKeyDown}
                      />
                      <ChangeByFrequencyDropDown
                        width={360}
                        items={this.getThereafterFrequencyDropDownData()}
                        onSelection={this.handleThereafterFrequencySelection}
                      />
                    </ChangeByContainer>
                  </>
                )}
              </>
            )}
            <SaveButton
              lessMargin={
                disableRevisions &&
                this.state.frequency !== repeatFrequency.MONTHLY &&
                this.state.frequency !== repeatFrequency.NO_REPEAT
              }
              title={i18n.t("save")}
              onClick={this.handleSaveClick}
            />
          </Container>
        </RepeatDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userDob: userDobSelector(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatsDialog);
