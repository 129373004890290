import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { connect } from "react-redux";
import { requestAccountDeletion, signOut, isAppInWhiteLabelMode, isSharedPortfolioUserSelector } from "@kubera/common";
import SignOutFromAllDevicesComponent from "../signin/SignOutFromAllDevicesComponent";

import MFAContainer from "components/twoFA/MFAContainer";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MFADetailsContainer = styled(MFAContainer)`
  border-bottom: ${props => (props.showBorder === true ? "" : "0px")};
`;

const DeleteAccount = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 18px;
`;

const DeleteAccountButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  cursor: pointer;
  color: ${props => props.theme.errorCLR};
`;

const DeleteConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 525px;
`;

class AccountSecurityComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRequestPending: false,
      errorMessage: null,
      showConfirmationDialog: false,
      hasUserRequestedAccountDeletion: false,
      isVerifyOpen: true
    };

    this.handleDeleteAccountClick = this.handleDeleteAccountClick.bind(this);
    this.handleDialogPositiveButtonClick = this.handleDialogPositiveButtonClick.bind(this);
    this.handleDialogNegativeButtonClick = this.handleDialogNegativeButtonClick.bind(this);
    this.onVerifyDismiss = this.onVerifyDismiss.bind(this);
  }

  handleDeleteAccountClick(e) {
    this.setState({ ...this.state, showConfirmationDialog: true, errorMessage: null });
  }

  handleDialogNegativeButtonClick(e) {
    this.setState({ ...this.state, showConfirmationDialog: false });
  }

  handleDialogPositiveButtonClick(e) {
    if (this.state.hasUserRequestedAccountDeletion === true) {
      signOut();
    } else {
      this.requestAccountDeletion();
    }
  }

  requestAccountDeletion() {
    this.setState({ ...this.state, isRequestPending: true, errorMessage: null });

    this.props.requestAccountDeletion(
      () => {
        this.setState({
          ...this.state,
          isRequestPending: false,
          errorMessage: null,
          hasUserRequestedAccountDeletion: true
        });
      },
      error => {
        this.setState({ ...this.state, isRequestPending: false, errorMessage: error.errorMessage });
      }
    );
  }

  onVerifyDismiss() {
    this.setState({
      isVerifyOpen: false
    });
  }

  render() {
    const isWhiteLabelledApp = isAppInWhiteLabelMode();
    const isRequestPending = this.state.isRequestPending;
    const showConfirmationDialog = this.state.showConfirmationDialog;
    const errorMessage = this.state.errorMessage;
    const hasUserRequestedAccountDeletion = this.state.hasUserRequestedAccountDeletion;

    return (
      <Container className={this.props.className}>
        <MFADetailsContainer showBorder={isWhiteLabelledApp === false} />
        <SignOutFromAllDevicesComponent hideBorder={this.props.isSharedPortfolioAccountUser} />
        {isWhiteLabelledApp === false && !this.props.isSharedPortfolioAccountUser && (
          <>
            <DeleteAccount>{i18n.t("accountSettings.deleteAccount")}</DeleteAccount>
            <DeleteAccountButton onClick={this.handleDeleteAccountClick}>
              {i18n.t("accountSettings.deleteAccountButtonTitlee")}
            </DeleteAccountButton>
          </>
        )}
        {showConfirmationDialog === true && hasUserRequestedAccountDeletion === true && (
          <DeleteConfirmationDialog
            title={i18n.t("deleteAccountConfirmationDialogTitle")}
            description={i18n.t("deleteAccountConfirmationDialogDescription")}
            positiveButtonTitle={i18n.t("signOut")}
            negativeButtonTitle={i18n.t("goBackLink")}
            isLoading={isRequestPending}
            errorMessage={errorMessage}
            handleNegativeButtonClick={this.handleDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleDialogPositiveButtonClick}
          />
        )}
        {showConfirmationDialog === true && hasUserRequestedAccountDeletion === false && (
          <DeleteConfirmationDialog
            title={
              this.props.isSharedPortfolioAccountUser
                ? i18n.t("deleteSubuserSelfDialog.title")
                : i18n.t("deleteAccountDialogTitle")
            }
            description={
              this.props.isSharedPortfolioAccountUser
                ? i18n.t("deleteSubuserSelfDialog.description")
                : i18n.t("deleteAccountDialogDescription")
            }
            positiveButtonTitle={
              this.props.isSharedPortfolioAccountUser
                ? i18n.t("deleteSubuserSelfDialog.action")
                : i18n.t("deleteAccount")
            }
            negativeButtonTitle={i18n.t("cancel")}
            isLoading={isRequestPending}
            errorMessage={errorMessage}
            handleNegativeButtonClick={this.handleDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleDialogPositiveButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state)
});

const mapDispatchToProps = {
  requestAccountDeletion: requestAccountDeletion
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSecurityComponent);
