import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import {
  fetchCustodianDetails,
  custodianUsingIdSelector,
  custodianDetailsSelector,
  formatNumberWithCurrency,
  getTickerUsingId,
  getExchangeRate,
  accountLinkingService,
  custodianSelectorWrapper,
  convertCurrency,
  setShowRefreshing,
  shouldShowLinkingErrorForCustodian,
  isCryptoCurrency,
  custodianLinkNeedsRefresh,
  custodianLinkNeedsReconnect,
  tickerTypes,
  setSlideDirection,
  getTickerUsingShortName,
  shortFormatNumberWithCurrency,
  isAssetCustodian,
  calcCustodianOwnershipValue,
  accountLinkingContainers,
  custodianSubTypes,
  useHistory,
  custodiansWithSameParentIdSelector,
  portfolioTotalForCategory,
  isAppInViewMode,
  portfolioSelector
} from "@kubera/common";
import { queryParams } from "routes";
import MobileRowComponent from "components/mobile_table/MobileRowComponent";
import Spinner from "components/loader/Spinner";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import LinkAccountComponentExports from "components/link_account/LinkAccountComponentExports";
import { category } from "components/dashboard/DashboardComponentExports";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ClickableLink from "components/labels/DelayLink";
import slideAnimation from "utilities/slideAnimation";
import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import { ReactComponent as LinkedIcon } from "assets/images/linked_account_icon.svg";
import { ReactComponent as LinkingErrorIcon } from "assets/images/link_account_errorM.svg";

import MobileNFTComponent from "./MobileNFTComponent";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;
  font-feature-settings: "ss01" on, "calt" off;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  position: relative;
  width: 100%;
  height: 100vh
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding-top: 60px;
  animation: ${slideAnimation};
`;

const HistoryBackContainer = styled(ClickableLink)`
  position: absolute;
  left: -1px;
  top: 4px;
  padding: 20px;
  border-radius: 50%;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 16px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const ConnectedNameContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  padding-right: 14px;
`;

const CustodianName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  padding: 0 0 0 20px;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  max-height: 119px;
`;

const NFTConnectedNameContainer = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  max-height: 119px;
  margin-bottom: 8px;
`;

const CustodianValue = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  padding: 0 20px 20px;
  overflow: auto;
  width: 100%;
`;

const NFTCustodianValue = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  margin-bottom: 8px;
  overflow: auto;
  width: 100%;
`;

const Update = styled.span`
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtColor};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogCustodianValue = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  padding: 0 20px;
  margin-bottom: 4px;
  overflow: auto;
  width: 100%;
`;

const DialogCustodianActualValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  padding: 0 20px;
  margin-bottom: 8px;
  color: ${props => props.theme.mobileTxtColorDetailsActualValue};
`;

const NFTDialogCustodianActualValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  color: ${props => props.theme.mobileTxtColorDetailsActualValue};
`;

const ParentValue = styled(CurrencyLabel)`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  padding: 0 20px;
  margin-bottom: 50px;
`;

const DetailsOverlay = styled(DialogOverlay)`
  font-feature-settings: "ss01" on, "calt" off;

  & > div {
    padding: 0 34px;
  }

  * {
    box-sizing: border-box;
  }
`;

const DetailsDialog = styled(Dialog)`
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 163px;
  background: ${props => props.theme.mobileBackgroundDetailsDialog};
  padding: 40px 14px;
`;

const LinkedIconComponent = styled(LinkedIcon)`
  margin-left: 6px;

  path {
    fill: ${props => props.theme.mobileTxtColorCustodianDetailsLinkedIcon};
  }
`;

const DialogUpdate = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtColorCustodianDetails};
  padding: 10px 20px;
  text-decoration-line: underline;
`;

const UpdateSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
  margin-left: 20px;
  margin-bottom: 5pt;
`;

const HoldingsWrapper = styled.div`
  margin-top: -30px;
  width: 100%;
`;

const HoldingOfTxt = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 20px;
  margin-bottom: 7px;
`;

const LinkedCustodianName = styled(CustodianName)`
  color: ${props => props.theme.mobileTxtColor};
  padding: 0 20px;
  margin-bottom: 3px;
`;

const LinkedCustodianValue = styled(CustodianValue)`
  color: ${props => props.theme.mobileTxtColor};
`;

const DescContainer = styled.div`
  padding: 0 20px;
  margin-bottom: ${props =>
    props.isLinkedPortfolio || props.hasEquity || props.shouldShowAvailableCredit ? "16px" : "50px"};
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.hasHoldings ? props.theme.mobileTxtColor : props.theme.mobileTxtColorCustodianDetails)};
`;

const LinkedPortfolioContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const LinkedPortfolioColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const LinkedPortfolioTitle = styled.div`
  opacity: 0.5;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
`;

const LinkedPortfolioValue = styled.div`
  margin-top: 2px;
  font-weight: 400;
  font-size: 20px;
`;

const LinkedPortfolioOriginalCurrencyValue = styled.div`
  opacity: 0.5;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
`;

const LinkedPortfolioOpen = styled.div`
  margin-left: 20px;
  margin-top: 40px;
  color: ${props => props.theme.linkColor};
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  margin-bottom: 20px;
`;

const EquityContainer = styled.div`
  font-size: 14px;
  line-height: 17px;
  padding: 0 20px;
  margin-bottom: 117px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: flex-end;
`;

const UpdateSpinnerContainer = styled.div`
  height: 30px;
`;

const AvailableCreditContainer = styled.div`
  padding: 0 20px 20px;
  margin-bottom: ${props => (props.hasHoldings ? `0px` : "50px")};
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.hasHoldings ? props.theme.mobileTxtColor : props.theme.mobileTxtColorCustodianDetails)};
  border-bottom: ${props =>
    props.hasHoldings && !props.isLinkingError ? `1px solid ${props.theme.mobileSeperatorColor}` : "none"};
`;

const LinkingErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 21px;
  border-bottom: ${props => (props.hasHoldings ? `1px solid ${props.theme.mobileSeperatorColor}` : "none")};
  padding-bottom: ${props => (props.hasHoldings ? "31px" : null)};
  color: ${props => (props.hasHoldings ? props.theme.mobileTxtColor : null)};
`;

const LinkingErrorBlk = styled.div`
  margin-right: 8px;
  min-width: 32px;
  text-align: center;
`;

const LinkingError = styled(LinkingErrorIcon)`
  transform: scale(1.65) translateY(0.3px);
  transform-origin: center;
`;

const LinkingErrorTxt = styled.div`
  font-size: 11px;
  line-height: 13px;
  white-space: pre-wrap;
`;

const NFTDetailsDialog = styled(DetailsDialog)`
  margin-top: 63px;
  padding: 0;
  border: ${props => (props.theme.mode === "dark" ? 0 : null)};
`;

const MobileCustodianDetailsComponent = ({ portfolio, custodianId, category: custodianCategory, direction }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const custodian = useSelector(custodianUsingIdSelector(custodianId));
  const details = useSelector(custodianDetailsSelector);
  const needsRefresh = custodianLinkNeedsRefresh(custodianId);
  const needsReconnect = custodianLinkNeedsReconnect(custodianId);
  const detailsInfoObj = details?.info;
  const fetchedDetailsInfo = useMemo(() => (detailsInfoObj ? detailsInfoObj || {} : {}), [detailsInfoObj]);
  const detailsInfo = useMemo(() => ({ ...fetchedDetailsInfo, ...custodian }), [fetchedDetailsInfo, custodian]);
  const custodianTicker = getTickerUsingId(detailsInfo.valueTickerId);
  const exchangeRate = getExchangeRate(custodianTicker.shortName, portfolio.currency);
  const valueNeedsInversion =
    custodianCategory === category.DEBT && isAssetCustodian(detailsInfo.linkContainer, detailsInfo.linkType);
  let applicableValue =
    (valueNeedsInversion ? -1 : 1) * convertCurrency(detailsInfo.value, custodianTicker.shortName, portfolio.currency);

  if (detailsInfo.valueTickerId === 171 && detailsInfo.rate) {
    const rateParsed = JSON.parse(detailsInfo.rate);
    const tickerForPrivateTicker = getTickerUsingId(rateParsed.t);
    applicableValue =
      (valueNeedsInversion ? -1 : 1) *
      convertCurrency(detailsInfo.value * rateParsed.p, tickerForPrivateTicker.shortName, portfolio.currency);
  }
  const applicableAvailableCreditValue = detailsInfo.availableCredit * exchangeRate;
  const isPortfolioCurrencyCrypto = isCryptoCurrency(portfolio.currency);
  const actualValue = isPortfolioCurrencyCrypto
    ? applicableValue
    : applicableValue && applicableValue > 0 && applicableValue < 1
    ? applicableValue
    : Math.kuberaFloor(applicableValue);
  const custodianValue = formatNumberWithCurrency(
    calcCustodianOwnershipValue(actualValue, detailsInfo.ownership),
    portfolio.currency,
    true,
    isPortfolioCurrencyCrypto ? 4 : undefined
  );
  const showLinkedIcon = ![0].includes(detailsInfo.linkType);
  const parentCustodian = useSelector(custodianSelectorWrapper(detailsInfo.parentId));
  const holdings = useSelector(state => custodiansWithSameParentIdSelector(state, portfolio.id, custodianId));
  const holdingIds = holdings.map(item => item.id);

  const filteredDetails =
    (details && details.holdings && details.holdings.filter(item => holdingIds.includes(item.id))) || [];
  filteredDetails.sort((a, b) => {
    const exchangeRateA = getExchangeRate(getTickerUsingId(a.valueTickerId).shortName, portfolio.currency);
    const exchangeRateB = getExchangeRate(getTickerUsingId(b.valueTickerId).shortName, portfolio.currency);

    return b.value * exchangeRateB - a.value * exchangeRateA;
  });
  const isHome = detailsInfo.subType === custodianSubTypes.HOME;
  const isZillow = detailsInfo.linkType === accountLinkingService.ZILLOW;

  const linkedPortfolio = useSelector(state =>
    detailsInfo.linkType === accountLinkingService.KUBERA_PORTFOLIO
      ? portfolioSelector(state, detailsInfo.linkProviderAccountId)
      : null
  );
  const assetTotalValue = useSelector(state => portfolioTotalForCategory(state, linkedPortfolio, category.ASSET));
  const debtsTotalValue = useSelector(state => portfolioTotalForCategory(state, linkedPortfolio, category.DEBT));
  const assetsTotal = useSelector(state =>
    !linkedPortfolio === true
      ? 0
      : formatNumberWithCurrency(
          Math.kuberaFloor(convertCurrency(assetTotalValue, linkedPortfolio.currency, portfolio.currency)),
          portfolio.currency,
          true
        )
  );
  const debtsTotal = useSelector(state =>
    !linkedPortfolio === true
      ? 0
      : formatNumberWithCurrency(
          Math.kuberaFloor(convertCurrency(debtsTotalValue, linkedPortfolio.currency, portfolio.currency)),
          portfolio.currency,
          true
        )
  );

  const equity = useMemo(() => {
    if ((isZillow || isHome) && detailsInfo.relatedId) {
      const debtLinked = filterDataFromPortfolioDetails(
        portfolio.details,
        custodian => detailsInfo.relatedId.includes(custodian.id),
        void 0,
        sheet => sheet.category === category.DEBT
      );

      if (debtLinked.rows.length === 0) {
        return null;
      }

      const portfolioValueTickerId = getTickerUsingShortName(portfolio.currency).id;
      const debtLinkedRows = debtLinked.rows.length
        ? debtLinked.rows
        : [
            {
              value: 0,
              valueTickerId: portfolioValueTickerId
            }
          ];

      const cellExchangeRate = getExchangeRate(custodianTicker.shortName, portfolio.currency);

      const debtExchangeRates = debtLinkedRows.map(debtLinkedRow =>
        getExchangeRate(
          getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
          portfolio.currency
        )
      );

      const totalLoan = debtLinkedRows.reduce((acc, debtLinkedRow, index) => {
        return (
          acc + calcCustodianOwnershipValue(debtLinkedRow.value * debtExchangeRates[index], debtLinkedRow.ownership)
        );
      }, 0);
      const diff =
        calcCustodianOwnershipValue(
          cellExchangeRate ? detailsInfo.value * cellExchangeRate : detailsInfo.value,
          detailsInfo.ownership
        ) - totalLoan;

      return shortFormatNumberWithCurrency(diff, portfolio.currency, false, true);
    }

    return null;
  }, [
    isZillow,
    isHome,
    custodianTicker.shortName,
    detailsInfo.relatedId,
    detailsInfo.value,
    detailsInfo.ownership,
    portfolio.currency,
    portfolio.details
  ]);

  const showAvailableCredit =
    (detailsInfo.linkContainer === "investment" ||
      detailsInfo.linkContainer === "locked" ||
      detailsInfo.linkContainer === "credit" ||
      detailsInfo.linkContainer === "creditCard" ||
      detailsInfo.linkContainer === "loan") &&
    !!detailsInfo.availableCredit;

  const goBack = () => {
    DialogOverlay.dismiss(history, location);
    dispatch(setShowRefreshing(false));
    dispatch(setSlideDirection(null));
  };

  const update = () => {
    if (detailsInfo.isRefreshing) {
      return;
    }

    LinkAccountComponentExports.refreshAccountValues(history, location, custodianId, () => null, () => null, {
      force: 1
    });
  };

  const getParentCurrency = () => {
    return getTickerUsingId(parentCustodian.valueTickerId).shortName;
  };

  const getValue = () => {
    if (!detailsInfo.parentId) {
      return 0;
    }
    return convertCurrency(
      calcCustodianOwnershipValue(parentCustodian.value, parentCustodian.ownership),
      getParentCurrency(),
      portfolio.currency
    );
  };

  const showNFTTab = () => {
    const { extra, linkContainer } = detailsInfo;

    if (linkContainer === accountLinkingContainers.NFT && extra) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!details || fetchedDetailsInfo.id !== custodianId) {
      dispatch(fetchCustodianDetails(custodianId));
    }
  }, [dispatch, custodianId, details, fetchedDetailsInfo.id]);

  useEffect(() => {
    if (fetchedDetailsInfo.id !== custodianId && detailsInfo.holdingsCount > 0 && showLinkedIcon) {
      dispatch(setShowRefreshing(true));
    } else {
      dispatch(setShowRefreshing(false));
    }
  }, [dispatch, custodianId, fetchedDetailsInfo.id, detailsInfo.holdingsCount, showLinkedIcon]);

  const parentValue = getValue();
  const parentCategoryValue = custodianCategory === category.ASSET ? parentValue : -parentValue;
  const isLinkingError = shouldShowLinkingErrorForCustodian(detailsInfo);
  const showUpdateBtn =
    ![
      accountLinkingService.KUBERA_PORTFOLIO,
      accountLinkingService.ZILLOW,
      accountLinkingService.DOMAINS,
      accountLinkingService.CARS
    ].includes(detailsInfo.linkType) &&
    !isLinkingError &&
    showLinkedIcon &&
    !detailsInfo.linkingFailed &&
    !detailsInfo.isLinking &&
    !needsRefresh &&
    !needsReconnect;
  const isCurrencyMatching = custodianTicker.shortName === portfolio.currency;
  const doesUserHasFullOwnership = detailsInfo.ownership === 100;

  if (showNFTTab()) {
    return (
      <DetailsOverlay onDismiss={goBack}>
        <NFTDetailsDialog>
          <MobileNFTComponent detailsInfo={detailsInfo} showLinkedIcon={showLinkedIcon}>
            <NFTConnectedNameContainer>
              {detailsInfo.name}
              {showLinkedIcon && <LinkedIconComponent />}
            </NFTConnectedNameContainer>
            <NFTCustodianValue>{custodianValue}</NFTCustodianValue>
            {!isCurrencyMatching && (
              <NFTDialogCustodianActualValue>
                {formatNumberWithCurrency(
                  calcCustodianOwnershipValue(detailsInfo.value, detailsInfo.ownership) *
                    (valueNeedsInversion ? -1 : 1),
                  custodianTicker.shortName,
                  false,
                  4,
                  [
                    tickerTypes.STOCK,
                    tickerTypes.FUND,
                    tickerTypes.BOND,
                    tickerTypes.DERIVATIVE,
                    tickerTypes.INDEX
                  ].includes(custodianTicker.type)
                )}
              </NFTDialogCustodianActualValue>
            )}
          </MobileNFTComponent>
        </NFTDetailsDialog>
      </DetailsOverlay>
    );
  }

  if (detailsInfo.holdingsCount <= 0 || !showLinkedIcon) {
    const hasBlockAfterDesc =
      equity ||
      showAvailableCredit ||
      detailsInfo.parentId ||
      (showUpdateBtn || detailsInfo.isRefreshing) ||
      isLinkingError;
    return (
      <DetailsOverlay onDismiss={goBack}>
        <DetailsDialog>
          <ConnectedNameContainer>
            <CustodianName>
              {detailsInfo.name}
              {showLinkedIcon && <LinkedIconComponent />}
            </CustodianName>
          </ConnectedNameContainer>
          <DialogCustodianValue>{custodianValue}</DialogCustodianValue>
          {(!isCurrencyMatching || !doesUserHasFullOwnership) && (
            <DialogCustodianActualValue>
              {formatNumberWithCurrency(
                detailsInfo.value,
                custodianTicker.shortName,
                false,
                4,
                [
                  tickerTypes.STOCK,
                  tickerTypes.FUND,
                  tickerTypes.BOND,
                  tickerTypes.DERIVATIVE,
                  tickerTypes.INDEX
                ].includes(custodianTicker.type)
              ).replace(/PVST\s?/, "#")}
            </DialogCustodianActualValue>
          )}
          {(detailsInfo.description || hasBlockAfterDesc) && (
            <DescContainer
              isLinkedPortfolio={!linkedPortfolio === false}
              hasEquity={equity}
              shouldShowAvailableCredit={showAvailableCredit}
            >
              {detailsInfo.description}
            </DescContainer>
          )}
          {isAppInViewMode() === false &&
            portfolio.write === 1 &&
            detailsInfo.linkType === accountLinkingService.KUBERA_PORTFOLIO && (
              <>
                <LinkedPortfolioContainer>
                  <LinkedPortfolioColumn>
                    <LinkedPortfolioTitle>{i18n.t("assets")}</LinkedPortfolioTitle>
                    <LinkedPortfolioValue>{assetsTotal}</LinkedPortfolioValue>
                    {!isCurrencyMatching && assetTotalValue !== 0 && (
                      <LinkedPortfolioOriginalCurrencyValue>
                        {formatNumberWithCurrency(
                          Math.kuberaFloor(assetTotalValue),
                          linkedPortfolio.currency,
                          false,
                          2,
                          true
                        )}
                      </LinkedPortfolioOriginalCurrencyValue>
                    )}
                  </LinkedPortfolioColumn>
                  <LinkedPortfolioColumn>
                    <LinkedPortfolioTitle>{i18n.t("debts")}</LinkedPortfolioTitle>
                    <LinkedPortfolioValue>{debtsTotal}</LinkedPortfolioValue>
                    {!isCurrencyMatching && debtsTotalValue !== 0 && (
                      <LinkedPortfolioOriginalCurrencyValue>
                        {formatNumberWithCurrency(
                          Math.kuberaFloor(debtsTotalValue),
                          linkedPortfolio.currency,
                          false,
                          2,
                          true
                        )}
                      </LinkedPortfolioOriginalCurrencyValue>
                    )}
                  </LinkedPortfolioColumn>
                </LinkedPortfolioContainer>
                <LinkedPortfolioOpen
                  onClick={e => {
                    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryParams.PORTFOLIO_ID}=${linkedPortfolio.id}`;
                    window.location = url;
                  }}
                >
                  {i18n.t("openPortfolio")}
                </LinkedPortfolioOpen>
              </>
            )}
          {equity && (
            <EquityContainer>
              {i18n.t("custodianDetails.homeEquity")}: {equity}
            </EquityContainer>
          )}
          {showAvailableCredit && (
            <AvailableCreditContainer hasHoldings={false}>{`${
              detailsInfo.linkContainer === "investment"
                ? i18n.t("unvested")
                : detailsInfo.linkContainer === "credit" ||
                  detailsInfo.linkContainer === "loan" ||
                  detailsInfo.linkContainer === "creditCard"
                ? i18n.t("availableCredit")
                : null
            }: ${shortFormatNumberWithCurrency(
              applicableAvailableCreditValue,
              portfolio.currency,
              false,
              true
            )}`}</AvailableCreditContainer>
          )}

          {detailsInfo.parentId && (
            <HoldingsWrapper>
              <HoldingOfTxt>{i18n.t("custodianDetails.holdingOfTxt")}</HoldingOfTxt>
              <ConnectedNameContainer>
                <CustodianName>{parentCustodian.name}</CustodianName>
              </ConnectedNameContainer>
              <ParentValue
                value={parentCategoryValue}
                roundDown={true}
                currency={portfolio.currency}
                maxLongFormatValue={99999}
                abbreviate={false}
              />
            </HoldingsWrapper>
          )}
          {(showUpdateBtn || detailsInfo.isRefreshing || isLinkingError) && (
            <BottomContainer>
              <UpdateSpinnerContainer>
                {detailsInfo.isRefreshing ? (
                  <UpdateSpinner />
                ) : showUpdateBtn ? (
                  <DialogUpdate onClick={update}>{i18n.t("update")}</DialogUpdate>
                ) : null}
              </UpdateSpinnerContainer>
            </BottomContainer>
          )}
          {isLinkingError && (
            <LinkingErrorContainer>
              <LinkingErrorBlk>
                <LinkingError />
              </LinkingErrorBlk>
              <LinkingErrorTxt>{i18n.t("connectionError.noHoldings")}</LinkingErrorTxt>
            </LinkingErrorContainer>
          )}
        </DetailsDialog>
      </DetailsOverlay>
    );
  }

  return (
    <Container>
      <ContainerDialog direction={direction}>
        <HistoryBackContainer onClick={goBack} direction="right">
          <HistoryBackBtn />
        </HistoryBackContainer>
        <LinkedCustodianName>{detailsInfo.name}</LinkedCustodianName>
        <LinkedCustodianValue shouldShowAvailableCredit={showAvailableCredit}>{custodianValue}</LinkedCustodianValue>
        {detailsInfo.description && (
          <DescContainer hasHoldings={true} shouldShowAvailableCredit={showAvailableCredit}>
            {detailsInfo.description}
          </DescContainer>
        )}
        {showAvailableCredit && (
          <AvailableCreditContainer hasHoldings={true} isLinkingError={isLinkingError}>{`${
            detailsInfo.linkContainer === "investment" || detailsInfo.linkContainer === "locked"
              ? i18n.t("unvested")
              : detailsInfo.linkContainer === "credit" ||
                detailsInfo.linkContainer === "loan" ||
                detailsInfo.linkContainer === "creditCard"
              ? i18n.t("availableCredit")
              : null
          }: ${shortFormatNumberWithCurrency(
            applicableAvailableCreditValue,
            portfolio.currency,
            false,
            true
          )}`}</AvailableCreditContainer>
        )}
        {isLinkingError && (
          <LinkingErrorContainer hasHoldings>
            <LinkingErrorBlk>
              <LinkingError />
            </LinkingErrorBlk>
            <LinkingErrorTxt>{i18n.t("connectionError.withHoldings")}</LinkingErrorTxt>
          </LinkingErrorContainer>
        )}
        {fetchedDetailsInfo.id === custodianId &&
          filteredDetails.map(eachHolding => (
            <MobileRowComponent
              key={eachHolding.id}
              row={eachHolding}
              hideLinkedIcon
              ownership={detailsInfo.ownership}
            />
          ))}
        {showUpdateBtn && (
          <Update onClick={update}>{detailsInfo.isRefreshing ? <UpdateSpinner /> : i18n.t("update")}</Update>
        )}
      </ContainerDialog>
    </Container>
  );
};

export default React.memo(MobileCustodianDetailsComponent);
