import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { verifyEmail, validateEmail, isEmailValid } from "@kubera/common";
import { useDispatch } from "react-redux";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import EmailInput from "components/inputs/EmailInput";
import TextInput from "components/inputs/TextInput";

const ModifyEmailDialog = styled(Dialog)`
  display: flex;
  width: fit-content;
  min-height: 218px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 163px;
`;

const Form = styled.form`
  position: relative;
  width: 314px;
  padding: 50px;
`;

const FormHeader = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const EmailField = styled(EmailInput)`
  height: 50px;
  width: 314px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (!props.inputError === false ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 14px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const CodeField = styled(TextInput)`
  height: 50px;
  width: 314px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (!props.inputError === false ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 14px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const ErrorBlk = styled.div`
  font-size: 14px;
  line-height: 130%;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 14px;
`;

const BtnWrapper = styled.div`
  display: flex;
`;

const UpdateBtn = styled(PrimaryButton)`
  height: 45px;
  width: 133px;
  min-width: 133px;
`;

const ConfirmBtn = styled(PrimaryButton)`
  height: 45px;
  width: 133px;
  min-width: 133px;
  margin-right: 16px;
`;

const BackBtn = styled(SecondaryButton)`
  height: 45px;
  width: 105px;
  min-width: 105px;
`;

const Resend = styled.label`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
`;

const emailErrorMessages = {
  1036: i18n.t("accountSettings.emailTakenErr"),
  1003: i18n.t("accountSettings.validAddressErr"),
  1: i18n.t("genericError")
};

const codeErrorMessages = {
  1003: i18n.t("wrongVerificationCode"),
  1: i18n.t("genericError")
};

export default function AccountEmailComponent({
  name: currentName,
  email: currentEmail,
  thirdPartyAuthenticated,
  onEmailDismiss
}) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(currentEmail);
  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isResendLinkVisible, setIsResendLinkVisible] = useState(false);

  function validateEmailForm() {
    return email.length > 0 && email !== currentEmail && isEmailValid(email);
  }

  function validateConfirmForm() {
    return code.length > 0;
  }

  const showResend = () => {
    setTimeout(() => {
      setIsResendLinkVisible(true);
    }, 10000);
  };

  async function handleUpdateClick(e) {
    e.preventDefault();
    if (!validateEmailForm()) {
      return;
    }

    setIsSendingCode(true);

    try {
      const emailCheck = await dispatch(verifyEmail(currentName, email));
      if (emailCheck) {
        setErrorMsg(null);
        setCodeSent(true);
        setIsSendingCode(false);
        setIsResendLinkVisible(false);
        showResend();
      } else {
        const emailExitsError = { errorCode: 1 };
        throw emailExitsError;
      }
    } catch (error) {
      setErrorMsg([1036, 1003].includes(error.errorCode) ? error.errorCode : 1);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(e) {
    e.preventDefault();

    setIsConfirming(true);

    try {
      await dispatch(validateEmail(currentName, email, code));
      onEmailDismiss();
    } catch (error) {
      setErrorMsg([1003].includes(error.errorCode) ? error.errorCode : 1);
      setIsConfirming(false);
    }
  }

  const hangleEmailChange = e => {
    setErrorMsg(null);
    setEmail(e.target.value);
  };

  const hangleCodeChange = e => {
    setErrorMsg(null);
    setCode(e.target.value);
  };

  const goBack = e => {
    setEmail(currentEmail);
    setCodeSent(false);
    setErrorMsg(null);
    setCode("");
  };

  const onResendClick = e => {
    setIsResendLinkVisible(false);
    handleUpdateClick(e);
  };

  function renderUpdateForm() {
    return (
      <DialogOverlay onDismiss={onEmailDismiss}>
        <ModifyEmailDialog>
          <Form onSubmit={handleUpdateClick}>
            <FormHeader>{i18n.t("accountSettings.newEmailFieldHeader")}</FormHeader>
            <EmailField
              data-private
              disabled={thirdPartyAuthenticated}
              value={email}
              onChange={hangleEmailChange}
              inputError={errorMsg}
            />
            <ErrorBlk>{emailErrorMessages[errorMsg]}</ErrorBlk>
            {!thirdPartyAuthenticated && (
              <UpdateBtn
                title={i18n.t("save")}
                data-cy="saveButton"
                isDisabled={!validateEmailForm()}
                isLoading={isSendingCode}
                onClick={() => null}
              />
            )}
          </Form>
        </ModifyEmailDialog>
      </DialogOverlay>
    );
  }

  function renderConfirmationForm() {
    return (
      <DialogOverlay onDismiss={onEmailDismiss}>
        <ModifyEmailDialog>
          <Form onSubmit={handleConfirmClick}>
            <FormHeader>
              {i18n.t("accountSettings.confirmEmailTitle")}{" "}
              {isResendLinkVisible && <Resend onClick={onResendClick}>{i18n.t("resend")}</Resend>}
            </FormHeader>
            <CodeField
              data-private
              placeholder={i18n.t("accountSettings.confirmEmailVerificationCode")}
              inputError={errorMsg}
              value={code}
              onChange={hangleCodeChange}
            />
            <ErrorBlk>{codeErrorMessages[errorMsg]}</ErrorBlk>
            <BtnWrapper>
              <ConfirmBtn
                title={i18n.t("save")}
                data-cy="saveButton"
                isDisabled={!validateConfirmForm() || isSendingCode}
                isLoading={isConfirming}
                onClick={() => null}
              />
              <BackBtn title={i18n.t("back")} data-cy="backButton" onClick={goBack} isDisabled={isSendingCode} />
            </BtnWrapper>
          </Form>
        </ModifyEmailDialog>
      </DialogOverlay>
    );
  }

  return !codeSent ? renderUpdateForm() : renderConfirmationForm();
}
