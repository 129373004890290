import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { getKuberaDateString, getCustodianHistoryFormattedDateString, parseKuberaDateString } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import DateInput from "components/inputs/DateInput";

const DateDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ThereafterTitle = styled(Title)`
  margin-top: 17px;
  margin-bottom: 8px;
`;

const DateInputBox = styled(DateInput)`
  label {
    font-weight: 400;
  }
`;

const AddDateInputButton = styled.div`
  width: fit-content;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #0074fc;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

class MultiDateInputDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: this.getDefaultDates()
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleAddDateInputClick = this.handleAddDateInputClick.bind(this);
    this.generateDateChangeFunction = this.generateDateChangeFunction.bind(this);
    this.searchInputRef = React.createRef();
  }

  getDefaultDates() {
    if (!this.props.data === true || !this.props.data.dates === true || this.props.data.dates.length === 0) {
      return [null];
    }
    return this.props.data.dates.map(dateString =>
      getCustodianHistoryFormattedDateString(parseKuberaDateString(dateString).getTime())
    );
  }

  handleDateInputChange(e, index) {
    const dates = this.state.dates;
    dates[index] = e.target.value;
    this.setState({ dates: dates });
  }

  handleAddDateInputClick(e) {
    var dates = this.state.dates;
    dates.push(null);
    this.setState({ dates: dates });

    setTimeout(() => {
      if (!this.searchInputRef === false && !this.searchInputRef.current === false) {
        this.searchInputRef.current.focus();
      }
    }, 250);
  }

  handleSaveClick(e) {
    const dates = this.state.dates
      .filter(dateString => !dateString === false && new Date().getTime() < new Date(dateString).getTime())
      .map(dateString => getKuberaDateString(new Date(dateString).getTime()));

    this.props.onVariableUpdate({ dates: dates });
    this.props.onDismiss();
  }

  generateDateChangeFunction(index) {
    const myFunc = newValue => {
      const dates = this.state.dates;
      dates[index] = newValue;
      this.setState({ dates: dates });
    };
    return myFunc.bind(this);
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <DateDialog>
          <Container>
            {this.state.dates.map((dateString, index) => {
              return (
                <div key={index}>
                  {index !== 0 && <ThereafterTitle>{i18n.t("thereafter")}</ThereafterTitle>}
                  <Title>{i18n.t("onDate")}</Title>
                  <DateInputBox
                    inputRef={
                      this.state.dates.length > 1 && index === this.state.dates.length - 1 ? this.searchInputRef : null
                    }
                    autoFocus={index === 0}
                    inputPlaceholder={i18n.t("date")}
                    allowPastDate={() => false}
                    value={dateString}
                    onDateChange={this.generateDateChangeFunction(index)}
                    onValidDate={this.handleSaveClick}
                    errorMessage={i18n.t("enterFutureDate")}
                  />
                </div>
              );
            })}
            <AddDateInputButton onClick={e => this.handleAddDateInputClick(e)}>
              {i18n.t("thereafter")}
            </AddDateInputButton>
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </DateDialog>
      </DialogOverlay>
    );
  }
}

export default MultiDateInputDialog;
