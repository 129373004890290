import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { currentPortfolioSelector, isAppInWhiteLabelMode, isSharedPortfolioUserSelector } from "@kubera/common";
import UserListComponent from "./UserListComponent";

const ShareDialog = styled(Dialog)`
  width: 700px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 60px 80px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.6);
`;

const ShareUserListTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 25px;
`;

const ShareUserList = styled(UserListComponent)`
  margin-top: 5px;
`;

const CollaboratePortfolioHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 1);
  margin-top: 16px;
`;

class CollaboratePortfolioComponent extends React.Component {
  static show = (history, location, portfolios, userList) => {
    const isAnyUserHasOnlyPortfolioAccess = userList.some(user => user.allowAll === 0 && user.status === "accepted");
    // for shared portfolio, show collaboration if any user has only portfolio access or if there are more than one portfolios

    if (portfolios.length === 1 && !isAnyUserHasOnlyPortfolioAccess && isAppInWhiteLabelMode() === false) {
      history.push({ ...location, hash: `${hashParams.MODAL}=${modalValues.COLLABORATE_PORTFOLIO_HEADS_UP}` });
    } else {
      history.push({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.COLLABORATE_PORTFOLIO}`
      });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      showColloborateHeadsUpDialog: false
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  getSharePanel() {
    const portfolio = this.props.currentPortfolio;

    return (
      <>
        <CollaboratePortfolioHeader>{i18n.t("collaborate")}</CollaboratePortfolioHeader>
        <ContentContainer>
          <DescriptionContainer>
            <Description
              dangerouslySetInnerHTML={{
                __html: isAppInWhiteLabelMode()
                  ? this.props.isSharedPortfolioAccountUser
                    ? i18n.t("collaborateDescription.wlkClient")
                    : i18n.t("collaborateDescription.wlk")
                  : i18n
                      .t("collaborateDescription")
                      .replace("%s%", `${process.env.PUBLIC_URL}#modal=account_settings&tab=multi_user`)
              }}
            ></Description>
          </DescriptionContainer>
          <ShareUserListTitle>{i18n.t("shareUserList")}</ShareUserListTitle>
          <ShareUserList
            addUserTitle={i18n.t("sharePortfolioWith")}
            access={{ allowAll: 0, portfolioId: portfolio.id }}
          />
        </ContentContainer>
      </>
    );
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ShareDialog>
          <Container>{this.getSharePanel()}</Container>
        </ShareDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CollaboratePortfolioComponent));
