import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { configureLogger } from "logger/ConfigureLogger";
import {
  wlDashboardFetchPendingSelector,
  wlDashboardFetchErrorSelector,
  wlDashboardSelector,
  fetchWlDashboard,
  siteConfigSelector,
  getHashParams,
  wlActiveClientsSelector,
  wlInvitedClientsSelector,
  wlArchivedClientsSelector,
  capitalizeStringWithSpaces,
  userNameSelector,
  userEmailSelector,
  signOut,
  SyncComponent,
  store,
  wlSiteDefaultCurrencySelector,
  getAppVersion,
  userSelector,
  userProfilePictureTokenSelector,
  getUserProfilePhotoUrl,
  wlSetupType,
  downloadWLClientData,
  isInternalUserSelector,
  wlManagersSelector,
  whiteLabelUserInfoSelector,
  canAmcUserManageOtherUsers,
  isWlAdvisorModeOn,
  appMaintenanceModeSelector,
  getClientDashboardData,
  showBreakingChangesSelector,
  showAppUpdateToastIfNeeded,
  wlFilterClientsByManagerSelector,
  setFilterClientsByWlManagerAction,
  rehydrateUserPreferences
} from "@kubera/common";
import { hashParams, modalValues, websiteUrls } from "routes";
import InitialLoader from "components/loader/InitialLoader";
import Spinner from "components/loader/Spinner";
import KuberaLogo from "components/labels/KuberaLogo";
import SearchInput from "components/inputs/SearchInput";
import UserProfileImage from "components/inputs/UserProfileImage";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import PrimaryButton from "components/button/PrimaryButton";
import InviteClientDialogComponent from "components/white_label/InviteClientDialogComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ActiveClientsComponent from "components/white_label/ActiveClientsComponent";
import InvitedClientsComponent from "components/white_label/InvitedClientsComponent";
import ArchivedClientsComponent from "components/white_label/ArchivedClientsComponent";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";
import AccountSettingsComponent from "components/account_settings/AccountSettingsComponent";
import ToastComponent from "components/toast/ToastComponent";
import DocumentsDownloadDialog from "components/safe_deposit_box/DocumentsDownloadDialog";
import lrConditionalCall from "utilities/lrConditionalCall";
import { ReactComponent as DownloadIcon } from "assets/images/download_icon.svg";
import { ReactComponent as DropDownIcon } from "assets/images/expandable_indicator.svg";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";
import FilterClientsByAdvisorDialog from "components/white_label/FilterClientsByAdvisorDialog";
import MaintenanceModeComponent from "components/dashboard/MaintenanceModeComponent";
import MultiuserManagementComponent from "components/white_label/multiuser/MultiuserManagementComponent";
import AdminBillingModal from "./billing/AdminBillingModal";
import ImportDialog from "./ImportDialog";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import CreateClientDialogComponent from "./CreateClientDialogComponent";

const Container = styled.div`
  height: 100%;
  min-width: 1250px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.03);
`;

const RefreshLoaderContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background: rgba(252, 252, 252, 0.6);
  z-index: 1000;
`;

const RefreshLoader = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  z-index: 10;
`;

const Logo = styled(KuberaLogo)`
  pointer-events: none;
`;

const UserDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const WlSiteName = styled.div`
  max-width: 143px;
  margin-right: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
`;

const ProfileImage = styled(UserProfileImage)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
`;

const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const SearchContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin-top: 12px;
`;

const SearchUsers = styled(SearchInput)`
  height: 35px;
  width: 500px;
  min-width: 414px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  z-index: 10;
`;

const AdvisorFilterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  min-width: 228px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  z-index: 10;
  padding: 10px;
  margin-left: -1px;
`;

const AdvisorFilter = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  cursor: pointer;
`;

const AdvisorFilterIcon = styled.div`
  cursor: pointer;
`;

const AdvisorFilterDropDownIndicator = styled(DropDownIcon)`
  transform: rotate(0deg);
  padding: 5px;
  margin-top: 5px;
`;

const AdvisorFilterClearIndicator = styled(DeleteIcon)`
  padding: 5px;
  margin-top: 5px;
`;

const DashboardDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 100px;
  min-width: 1000px;
  max-width: 1400px;
  width: 80%;
  flex-direction: column;
`;

const TotalsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const TotalItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 62px;
`;

const TotalTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
`;

const TotalValue = styled(CurrencyHeaderLabel)``;

const InviteClientButton = styled(PrimaryButton)`
  margin-left: auto;
  height: 36px;
  min-width: 119px;
  text-transform: capitalize;
`;

const BottomFooter = styled.div`
  min-height: 40px;
`;

const DashboardTabs = styled(Tabs)`
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const DashboardTabListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const DashboardTabList = styled(TabList)`
  display: flex;
  flex: 1;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const DashboardTab = styled(Tab)`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.35);
  margin-right: 25px;
  border: 0;
  cursor: pointer;

  &.is-selected {
    color: black;
  }
`;

const DashboardTabPanel = styled(TabPanel)`
  display: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;
  padding: 24px 0px 0px 0px;

  &.is-selected {
    display: flex;
  }
`;

const HelpMenuButton = styled.div`
  position: absolute;
  right: 23px;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center
  background: #ffffff;
  border: 1px solid  rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 0px 5px;
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
  cursor: pointer;
`;

const HelpMenuFooter = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.contextMenuItemDescriptionColor};
`;

const HelpMenuFooterTitle = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.4);
`;

const HelpMenuFooterItem = styled.a`
  padding-top: 6px;
  padding-bottom: 0px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  text-decoration: none;
  display: inline-block;
  color: ${props => props.theme.contextMenuItemDescriptionColor};

  &: hover {
    text-decoration: underline;
  }
`;

const AppVersion = styled.div`
  font-size: 10px;
  color: ${props => props.theme.contextMenuItemDescriptionColor};
  padding-top: 4px;
  overflow-x: hidden;
`;

const PortfolioDownloadIcon = styled(DownloadIcon)`
  margin-right: 30px;
  cursor: pointer;
`;

const ImportCSVAction = styled.div`
  color: rgba(0, 0, 0, 0.74);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 3px;
  margin-right: 22px;
`;

class WhiteLabelDashboardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      searchText: null,
      profilePictureUrl: null,
      showAdvisorFilterDialog: false,
      filterClientsByWlManager: props.wlFilterClientsByManager,
      dashboardUrlFetchingForUserID: null,
      showImportCashFlowDialog: false
    };

    this.handleHelpMenuButtonClick = this.handleHelpMenuButtonClick.bind(this);
    this.handleInitialLoadFailureRefresh = this.handleInitialLoadFailureRefresh.bind(this);
    this.handleInviteClientClick = this.handleInviteClientClick.bind(this);
    this.handleTabSelection = this.handleTabSelection.bind(this);
    this.handleAccountSettingsButtonClick = this.handleAccountSettingsButtonClick.bind(this);
    this.handleHeaderContextMenuSelection = this.handleHeaderContextMenuSelection.bind(this);
    this.handleHelpContextMenuSelection = this.handleHelpContextMenuSelection.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleAdvisorFilterClick = this.handleAdvisorFilterClick.bind(this);
    this.handleAdvisorFilterOnDismiss = this.handleAdvisorFilterOnDismiss.bind(this);
    this.handleAdvisorFilterSelection = this.handleAdvisorFilterSelection.bind(this);

    this.headerContextMenuRef = React.createRef();
    this.helpContextMenuRef = React.createRef();
    this.clientDataDownloadMenuRef = React.createRef();
    this.childStripeWindowRef = React.createRef();
  }

  componentDidMount() {
    // This is needed ensure that if user opens WL dasboard from the tab
    // that's showing client's portfolio the client portfolio still
    // opens in a new tab
    window.name = null;

    document.title = i18n.t("wlDashboardPageTitle");

    this.props.fetchWlDashboard();
    this.getProfilePicture();

    if (localStorage.getItem("mfa_disabled")) {
      AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.SECURITY);
    }

    this.setupReporting(this.props.user);

    window.addEventListener("click", function() {
      window.Beacon("close");
    });
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    this.props.showAppUpdateToastIfNeeded();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.archivedClients && oldProps.archivedClients.length !== 0 && this.getArchivedClients().length === 0) {
      if (this.state.selectedTabIndex === this.getDashboardTabs().length) {
        this.setState({ selectedTabIndex: this.state.selectedTabIndex - 1 });
      }
    }

    if (this.props.user === null && oldProps.user !== null) {
      this.handleSignOut();
    }

    if (oldProps.profilePictureToken !== this.props.profilePictureToken) {
      this.getProfilePicture();
    }
  }

  handleVisibilityChange = () => {
    if (document.hidden === false) {
      if (this.childStripeWindowRef.current) {
        this.props.getClientDashboardData(this.state.dashboardUrlFetchingForUserID);
      }

      this.props.rehydrateUserPreferences();
    }
  };

  getProfilePicture() {
    this.props.getUserProfilePhotoUrl(
      url => {
        this.setState({ profilePictureUrl: url });
      },
      apiError => {}
    );
  }

  setupReporting(user) {
    if (!user === true) {
      return;
    }

    configureLogger();

    lrConditionalCall(() => {
      LogRocket.identify(user.id, {
        displayName: user.id,
        appVersion: getAppVersion()
      });

      LogRocket.getSessionURL(function(sessionURL) {
        window.Beacon("identify", {
          name: user.name,
          email: user.email,
          user_id: user.id,
          created_at: user.tsCreated,
          sessionURL: sessionURL,
          appVersion: getAppVersion()
        });

        Sentry.configureScope(function(scope) {
          scope.setUser({
            id: user.id
          });
          scope.setExtra("sessionURL", sessionURL);
          scope.setExtra("appVersion", getAppVersion());
        });
      });
    }, this.props.isInternalUser);
  }

  handleSignOut() {
    lrConditionalCall(() => {
      LogRocket.identify();
    }, this.props.isInternalUser);
    window.Beacon("logout");
    Sentry.configureScope(scope => scope.setUser(null));
  }

  handleInitialLoadFailureRefresh() {
    this.props.fetchWlDashboard();
  }

  handleHelpMenuButtonClick(e) {
    window.Beacon("close");
    if (this.helpContextMenuRef.current.isVisible() === true) {
      this.helpContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();

    var helpItems = [[contextMenuItemType.CONTACT_KUBERA]];
    const customFooterItem = contextMenuItemType.HELP_MENU_FOOTER;
    customFooterItem.onRender = () => {
      return (
        <HelpMenuFooter>
          <HelpMenuFooterTitle target="_blank" href={websiteUrls.WEB_HOMEPAGE}>
            {i18n.t("poweredByKubera")}
          </HelpMenuFooterTitle>
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.TERMS_OF_SERVICE}`}>
            Terms
          </HelpMenuFooterItem>
          ,&nbsp;
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.PRIVACY_POLICY}`}>
            Privacy
          </HelpMenuFooterItem>
          ,&nbsp;
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.SECURITY}`}>
            Security
          </HelpMenuFooterItem>
          <AppVersion>{`v${getAppVersion()}`}</AppVersion>
        </HelpMenuFooter>
      );
    };
    helpItems[0].push(customFooterItem);

    this.helpContextMenuRef.current.show(
      helpItems,
      targetPosition.left + targetPosition.width / 2 + 5,
      targetPosition.top - targetPosition.height / 2 + 60,
      false,
      e.target
    );
  }

  handleHelpContextMenuSelection(item) {
    if (item.id === contextMenuItemType.CONTACT_KUBERA.id) {
      window.Beacon("open");
    }
  }

  getActiveClients() {
    const currentManager = this.state.filterClientsByWlManager;
    if (!currentManager === true) {
      return this.props.activeClients;
    }
    return this.props.activeClients.filter(item => item.managerUserId === currentManager.userId);
  }

  getArchivedClients() {
    const currentManager = this.state.filterClientsByWlManager;
    if (!currentManager === true) {
      return this.props.archivedClients;
    }
    return this.props.archivedClients.filter(item => item.managerUserId === currentManager.userId);
  }

  getDashboardTotals() {
    const activeClients = this.getActiveClients();

    const activeClientsTotalAum = activeClients.reduce((total, client) => {
      let aumTotalAcrossPortfolios;
      if (client.portfolio && client.portfolio.length > 0) {
        aumTotalAcrossPortfolios = client.portfolio.reduce(
          (clientTotal, portfolio) => clientTotal + portfolio.aumTotal,
          0
        );
      } else {
        aumTotalAcrossPortfolios = 0;
      }
      return total + aumTotalAcrossPortfolios;
    }, 0);

    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return [
        { title: i18n.t("aum"), isCurrency: true, value: activeClientsTotalAum },
        { title: i18n.t("clients"), value: activeClients.length }
      ];
    }

    const activeClientsTotalInvestableAssets = activeClients.reduce((total, client) => {
      let investableTotalAcrossPortfolios;
      if (client.portfolio && client.portfolio.length > 0) {
        investableTotalAcrossPortfolios = client.portfolio.reduce(
          (clientTotal, portfolio) => clientTotal + portfolio.investibleTotal,
          0
        );
      } else {
        investableTotalAcrossPortfolios = 0;
      }
      return total + investableTotalAcrossPortfolios;
    }, 0);

    const activeClientsTotalNetWorth = activeClients.reduce((total, client) => {
      let netWorthTotalAcrossPortfolios;
      if (client.portfolio && client.portfolio.length > 0) {
        netWorthTotalAcrossPortfolios = client.portfolio.reduce(
          (clientTotal, portfolio) => clientTotal + portfolio.value,
          0
        );
      } else {
        netWorthTotalAcrossPortfolios = 0;
      }
      return total + netWorthTotalAcrossPortfolios;
    }, 0);

    return [
      { title: i18n.t("aum"), isCurrency: true, value: activeClientsTotalAum },
      { title: i18n.t("investable"), isCurrency: true, value: activeClientsTotalInvestableAssets },
      { title: i18n.t("networth"), isCurrency: true, value: activeClientsTotalNetWorth },
      { title: i18n.t("clients"), value: activeClients.length }
    ];
  }

  getDashboardTabs() {
    const searchText = this.state.searchText;

    var activeClients = this.getActiveClients();
    if (!searchText === false) {
      activeClients = activeClients.filter(client => {
        return (
          client.clientName.toLowerCase().includes(searchText) ||
          client.name.toLowerCase().includes(searchText) ||
          client.email.includes(searchText)
        );
      });
    }

    var invitedClients = this.props.invitedClients;
    if (!searchText === false) {
      invitedClients = invitedClients.filter(client => {
        return (
          client.clientName.toLowerCase().includes(searchText) ||
          client.name.toLowerCase().includes(searchText) ||
          client.email.includes(searchText)
        );
      });
    }

    var archivedClients = this.getArchivedClients();
    if (!searchText === false) {
      archivedClients = archivedClients.filter(client => {
        return (
          client.clientName.toLowerCase().includes(searchText) ||
          client.name.toLowerCase().includes(searchText) ||
          client.email.includes(searchText)
        );
      });
    }

    const activePanel = (
      <ActiveClientsComponent
        clients={activeClients}
        searchText={this.state.searchText}
        onInviteButtonClick={this.handleInviteClientClick}
      />
    );
    const invitedPanel = (
      <InvitedClientsComponent
        clients={invitedClients}
        searchText={this.state.searchText}
        onInviteButtonClick={this.handleInviteClientClick}
      />
    );
    const archivedPanel = (
      <ArchivedClientsComponent
        clients={archivedClients}
        searchText={this.state.searchText}
        onInviteButtonClick={this.handleInviteClientClick}
      />
    );
    const invitedClientsCount = this.props.invitedClients.length;

    var tabs = [
      {
        title: i18n.t("active"),
        panelComponent: activePanel
      }
    ];

    if (this.props.invitedClients.length > 0) {
      tabs.push({
        title: i18n.t("invited") + (invitedClientsCount > 0 ? ` (${invitedClientsCount})` : ""),
        panelComponent: invitedPanel
      });
    }
    if (this.getArchivedClients().length > 0) {
      tabs.push({
        title: i18n.t("archived"),
        panelComponent: archivedPanel
      });
    }
    return tabs;
  }

  handleTabSelection(index) {
    this.setState({ selectedTabIndex: index });
  }

  handleInviteClientClick() {
    CreateClientDialogComponent.show(this.props.history, this.props.location);
  }

  handleAccountSettingsButtonClick(event) {
    if (this.headerContextMenuRef.current.isVisible() === true) {
      this.headerContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const userDetailsMenuItem = contextMenuItemType.USER_NAME_EMAIL;
    userDetailsMenuItem.label = capitalizeStringWithSpaces(this.props.userName);
    userDetailsMenuItem.email = this.props.userEmail;
    userDetailsMenuItem.profileImageUrl = this.state.profilePictureUrl;

    let menuItems = [[userDetailsMenuItem], [contextMenuItemType.ACCOUNT_SETTINGS, contextMenuItemType.SIGN_OUT]];

    this.headerContextMenuRef.current.show(
      menuItems,
      targetPosition.right,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
  }

  handleHeaderContextMenuSelection(item) {
    if (item.id === contextMenuItemType.SIGN_OUT.id) {
      signOut();
    } else if (item.id === contextMenuItemType.ACCOUNT_SETTINGS.id) {
      AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.MANAGE_USERS);
    } else if (item.id === contextMenuItemType.USER_NAME_EMAIL.id) {
      AccountSettingsComponentExports.show(this.props.history, this.props.location);
    }
  }

  handleSearchInputChange(text) {
    if (!this.searchTimer === false) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.setState({ searchText: text.trim().toLowerCase() });
      this.searchTimer = null;
    }, 300);
  }

  setDashboardUrlFetchingForUserID = dashboardUrlFetchingForUserID => {
    this.setState({ dashboardUrlFetchingForUserID });
  };

  getModalComponent() {
    const urlHashParams = getHashParams(this.props.location);
    const modalValue = urlHashParams[hashParams.MODAL];

    switch (modalValue) {
      case modalValues.WL_CREATE_CLIENT:
        return <CreateClientDialogComponent />;
      case modalValues.WL_INVITE_CLIENT:
        return <InviteClientDialogComponent userId={urlHashParams[hashParams.ID]} />;
      case modalValues.ACCOUNT_SETTINGS:
        return <AccountSettingsComponent selectedTab={urlHashParams[hashParams.TAB]} />;
      case modalValues.DOWNLOAD_DOCUMENTS:
        return <DocumentsDownloadDialog isDataDownload />;
      case modalValues.WL_MANAGE_CLIENT:
        return <MultiuserManagementComponent userId={urlHashParams[hashParams.ID]} />;
      case modalValues.WL_ADMIN_BILLING:
        return (
          <AdminBillingModal
            childStripeWindowRef={this.childStripeWindowRef}
            userId={urlHashParams[hashParams.ID]}
            dashboardUrlFetchingForUserID={this.state.dashboardUrlFetchingForUserID}
            setDashboardUrlFetchingForUserID={this.setDashboardUrlFetchingForUserID}
          />
        );
      default:
        return null;
    }
  }

  onImportAction = e => {
    const targetClientRect = e.target.getBoundingClientRect();
    const clientDownloadItems = [
      [
        contextMenuItemType.WLK_IMPORT_ASSETS_DEBTS,
        contextMenuItemType.WLK_IMPORT_CURRENT_VALUE,
        contextMenuItemType.WLK_IMPORT_HISTORY,
        contextMenuItemType.WLK_IMPORT_CASH_FLOW
      ]
    ];

    this.clientDataDownloadMenuRef.current.show(
      clientDownloadItems,
      targetClientRect.left + targetClientRect.width / 2 + targetClientRect.width - 5,
      targetClientRect.top - targetClientRect.height / 2 + targetClientRect.height + 12,
      false,
      e.target
    );
  };

  onDownloadClientData = e => {
    const targetClientRect = e.target.getBoundingClientRect();
    const clientDownloadItems = [[contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA]];

    this.clientDataDownloadMenuRef.current.show(
      clientDownloadItems,
      targetClientRect.left + targetClientRect.width / 2 + targetClientRect.width - 5,
      targetClientRect.top - targetClientRect.height / 2 + targetClientRect.height + 12,
      false,
      e.target
    );
  };

  handleClientDataDownloadSelection = item => {
    if (item.id === contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA.id) {
      this.props.downloadWLClientData(this.props.user);
      DocumentsDownloadDialog.show(this.props.history, this.props.location);
    } else if (item.id === contextMenuItemType.WLK_IMPORT_CASH_FLOW.id) {
      this.showImportCashFlowDialog();
    } else if (item.id === contextMenuItemType.WLK_IMPORT_HISTORY.id) {
      this.showImportHistoryDialog();
    } else if (item.id === contextMenuItemType.WLK_IMPORT_ASSETS_DEBTS.id) {
      this.showImportAssetsDebtsDialog();
    } else if (item.id === contextMenuItemType.WLK_IMPORT_CURRENT_VALUE.id) {
      this.showImportCurrentValueDialog();
    }
  };

  getAdvisorFilterString() {
    if (!this.state.filterClientsByWlManager === true) {
      return i18n.t("allClients");
    }
    return this.state.filterClientsByWlManager.name;
  }

  handleAdvisorFilterClick(e) {
    this.setState({ showAdvisorFilterDialog: true });
  }

  handleAdvisorFilterOnDismiss() {
    this.setState({ showAdvisorFilterDialog: false });
  }

  handleAdvisorFilterSelection(wlManager) {
    this.setState({ showAdvisorFilterDialog: false, filterClientsByWlManager: wlManager });
    this.props.setFilterClientsByWlManager(wlManager);
  }

  showImportCashFlowDialog = () => {
    this.setState({
      showImportCashFlowDialog: true
    });
  };

  onImportCashFlowDismiss = () => {
    this.setState({
      showImportCashFlowDialog: false
    });
  };

  showImportHistoryDialog = () => {
    this.setState({
      showImportHistoryDialog: true
    });
  };

  onImportHistoryDismiss = () => {
    this.setState({
      showImportHistoryDialog: false
    });
  };

  showImportAssetsDebtsDialog = () => {
    this.setState({
      showImportAssetsDebtsDialog: true
    });
  };

  onImportAssetsDebtsDismiss = () => {
    this.setState({
      showImportAssetsDebtsDialog: false
    });
  };

  showImportCurrentValueDialog = () => {
    this.setState({
      showImportCurrentValueDialog: true
    });
  };

  onImportCurrentValueDismiss = () => {
    this.setState({
      showImportCurrentValueDialog: false
    });
  };

  render() {
    const fetchDashboardPending = this.props.fetchDashboardPending;
    const fetchDashboardError = this.props.fetchDashboardError;
    const dashboard = this.props.dashboard;

    if (this.props.isAppInMaintenanceMode) {
      return <MaintenanceModeComponent />;
    }

    if (this.props.showBreakingChanges) {
      return (
        <ConfirmationDialog
          canUserDismiss={false}
          title={i18n.t("breakingchanges.title")}
          description={i18n.t("breakingchanges.description")}
          positiveButtonTitle={i18n.t("update")}
          handlePositiveButtonClick={e => window.location.reload()}
        />
      );
    }

    if (!dashboard === true) {
      return (
        <InitialLoader
          errorMessage={!fetchDashboardError === false ? fetchDashboardError.errorMessage : ""}
          onRefresh={this.handleInitialLoadFailureRefresh}
        />
      );
    }

    const currency = this.props.wlDefaultCurrency;
    const isRefreshing = fetchDashboardPending === true && !dashboard === false;
    const profileImageUrl = this.state.profilePictureUrl;
    const siteName = this.props.siteConfig.shortName;
    const dashboardTotals = this.getDashboardTotals();
    const dashboardTabs = this.getDashboardTabs();
    const selectedTabIndex = this.state.selectedTabIndex;
    const modalComponent = this.getModalComponent();
    const filterClientsByWlManager = this.state.filterClientsByWlManager;

    return (
      <Container>
        <SyncComponent store={store} />
        <ToastComponent />

        {isRefreshing === true && (
          <RefreshLoaderContainer>
            <RefreshLoader darkTheme={true} />
          </RefreshLoaderContainer>
        )}

        <ContentContainer>
          <Header>
            <Logo />
            <UserDetailsContainer>
              <WlSiteName>{siteName}</WlSiteName>
              <ProfileImage
                imageSize={30}
                userName={this.props.userName}
                imageUrl={profileImageUrl}
                title={i18n.t("accountSettings")}
                onClick={this.handleAccountSettingsButtonClick}
              />
            </UserDetailsContainer>
          </Header>

          <Dashboard>
            <SearchContainer>
              <SearchUsers placeholder={i18n.t("search")} onChange={this.handleSearchInputChange} />
              {this.props.canWlUserManageOtherUsers === true && this.props.isWlAdvisorModeOn === true && (
                <AdvisorFilterContainer>
                  <AdvisorFilter onClick={this.handleAdvisorFilterClick}>{this.getAdvisorFilterString()}</AdvisorFilter>
                  <AdvisorFilterIcon>
                    {!filterClientsByWlManager === true && (
                      <AdvisorFilterDropDownIndicator onClick={this.handleAdvisorFilterClick} />
                    )}
                    {!filterClientsByWlManager === false && (
                      <AdvisorFilterClearIndicator onClick={e => this.handleAdvisorFilterSelection(null)} />
                    )}
                  </AdvisorFilterIcon>
                </AdvisorFilterContainer>
              )}
            </SearchContainer>
            <DashboardDetailsContainer>
              <TotalsContainer>
                {dashboardTotals.map((totalItem, index) => {
                  return (
                    <TotalItem key={index}>
                      <TotalTitle>{totalItem.title}</TotalTitle>
                      <TotalValue
                        currency={currency}
                        value={totalItem.value}
                        currencyFontSize={totalItem.isCurrency === true ? 16 : 0}
                        valueFontSize={36}
                        showZero={true}
                        abbreviate={true}
                      />
                    </TotalItem>
                  );
                })}
              </TotalsContainer>

              <DashboardTabs
                selectedTabClassName="is-selected"
                selectedTabPanelClassName="is-selected"
                selectedIndex={selectedTabIndex}
                onSelect={this.handleTabSelection}
              >
                <DashboardTabListContainer>
                  <DashboardTabList>
                    {dashboardTabs.map((tab, index) => (
                      <DashboardTab key={index}>{tab.title}</DashboardTab>
                    ))}
                  </DashboardTabList>
                  {this.props.siteConfig.showCashFlowImport === 1 && (
                    <ImportCSVAction onClick={this.onImportAction}>{i18n.t("wlkImport.action")}</ImportCSVAction>
                  )}
                  <PortfolioDownloadIcon onClick={this.onDownloadClientData} />
                  <InviteClientButton onClick={this.handleInviteClientClick} title={i18n.t("addNewClient")} />
                </DashboardTabListContainer>
                {dashboardTabs.map((tab, index) => (
                  <DashboardTabPanel key={index}>{tab.panelComponent}</DashboardTabPanel>
                ))}
              </DashboardTabs>
              <BottomFooter />
            </DashboardDetailsContainer>
            {modalComponent}
          </Dashboard>
        </ContentContainer>
        <ContextMenu
          dataPrivate
          ref={this.headerContextMenuRef}
          width={223}
          onSelection={this.handleHeaderContextMenuSelection}
        />
        <HelpMenuButton onClick={this.handleHelpMenuButtonClick}>{"?"}</HelpMenuButton>
        <ContextMenu
          ref={this.clientDataDownloadMenuRef}
          width={219}
          onSelection={this.handleClientDataDownloadSelection}
        />
        <ContextMenu ref={this.helpContextMenuRef} width={222} onSelection={this.handleHelpContextMenuSelection} />
        {this.state.showAdvisorFilterDialog === true && (
          <FilterClientsByAdvisorDialog
            managers={this.props.wlManagers}
            selectedManager={this.state.filterClientsByWlManager}
            onDismiss={this.handleAdvisorFilterOnDismiss}
            onAdvisorSelection={this.handleAdvisorFilterSelection}
          />
        )}
        {this.state.showImportCashFlowDialog === true && (
          <ImportDialog type="cashflow" onDismiss={this.onImportCashFlowDismiss} />
        )}
        {this.state.showImportHistoryDialog === true && (
          <ImportDialog type="history" onDismiss={this.onImportHistoryDismiss} />
        )}
        {this.state.showImportAssetsDebtsDialog === true && (
          <ImportDialog type="assetsdebts" onDismiss={this.onImportAssetsDebtsDismiss} />
        )}
        {this.state.showImportCurrentValueDialog === true && (
          <ImportDialog type="currentvalue" onDismiss={this.onImportCurrentValueDismiss} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  fetchDashboardPending: wlDashboardFetchPendingSelector(state),
  fetchDashboardError: wlDashboardFetchErrorSelector(state),
  dashboard: wlDashboardSelector(state),
  siteConfig: siteConfigSelector(state),
  profilePictureToken: userProfilePictureTokenSelector(state),
  activeClients: wlActiveClientsSelector(state),
  invitedClients: wlInvitedClientsSelector(state),
  archivedClients: wlArchivedClientsSelector(state),
  userName: userNameSelector(state),
  userEmail: userEmailSelector(state),
  user: userSelector(state),
  wlDefaultCurrency: wlSiteDefaultCurrencySelector(state),
  isInternalUser: isInternalUserSelector(state),
  wlManagers: wlManagersSelector(state),
  wlCurrentUserInfo: whiteLabelUserInfoSelector(state),
  canWlUserManageOtherUsers: canAmcUserManageOtherUsers(state),
  isWlAdvisorModeOn: isWlAdvisorModeOn(state),
  isAppInMaintenanceMode: appMaintenanceModeSelector(state),
  showBreakingChanges: showBreakingChangesSelector(state),
  wlFilterClientsByManager: wlFilterClientsByManagerSelector(state)
});

const mapDispatchToProps = {
  fetchWlDashboard: fetchWlDashboard,
  getUserProfilePhotoUrl: getUserProfilePhotoUrl,
  downloadWLClientData,
  getClientDashboardData,
  showAppUpdateToastIfNeeded,
  setFilterClientsByWlManager: setFilterClientsByWlManagerAction,
  rehydrateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WhiteLabelDashboardComponent));
