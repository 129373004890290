import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  currentPortfolioCurrencySelector,
  portfolioGroupedFundScheduleSelector,
  fundScheduleDurations,
  getFundScheduleTitle,
  custodianSelector,
  store
} from "@kubera/common";
import ChangeLabel from "components/labels/ChangeLabel";
import FundScheduleDialog from "components/custodian_details/FundScheduleDialog";

const ScheduleDialog = styled(Dialog)`
  width: 1000px;
  min-height: 650px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  margin-top: 2px;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.7;
  white-space: pre-wrap;
`;

const TotalHeader = styled.div`
  display: flex;
  margin-top: 25px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const TotalTitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.5;
  text-transform: uppercase;
`;

const TotalValue = styled(ChangeLabel)`
  width: fit-content;
  margin-top: 2px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const EmptyValue = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Table = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.div`
  height: 44px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.5
  text-transform: uppercase;
`;

const HeaderCell = styled.div`
  display: flex;
  flex: ${props => (props.isFirst ? 1 : "auto")};
  max-width: ${props => (props.isFirst ? "auto" : "97px")};
  justify-content: flex-end;
  margin-left: 15px;
  margin-right: 15px;
`;

const Row = styled.div`
  display: flex;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
`;

const RowCell = styled(HeaderCell)`
  justify-content: ${props => (props.isFirst ? "flex-start" : "flex-end")};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: ${props => (props.isFirst ? "left" : "right")};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-decoration-line: ${props => (props.isFirst ? "underline" : "none")};
  cursor: ${props => (props.isFirst ? "pointer" : "default")};
`;

const RowValue = styled(ChangeLabel)``;

const TableFooter = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
`;

const FooterCell = styled(HeaderCell)``;

class FundScheduleSummaryDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFundScheduleDialog: false,
      fundScheduleDialogCustodian: null
    };

    this.handleFundNameClick = this.handleFundNameClick.bind(this);
    this.handleFundScheduleDialogOnDismiss = this.handleFundScheduleDialogOnDismiss.bind(this);
  }

  handleFundNameClick(e, custodianId) {
    const custodian = custodianSelector(store.getState(), custodianId);
    if (!custodian) {
      return;
    }
    this.setState({ showFundScheduleDialog: true, fundScheduleDialogCustodian: custodian });
  }

  handleFundScheduleDialogOnDismiss() {
    this.setState({ showFundScheduleDialog: false, fundScheduleDialogCustodian: null });
  }

  getRowCells(custodianId) {
    const portfolioGroupedFundSchedule = this.props.portfolioGroupedFundSchedule.data;

    return Object.keys(fundScheduleDurations).map(key => {
      const item = portfolioGroupedFundSchedule[fundScheduleDurations[key]]
        ? portfolioGroupedFundSchedule[fundScheduleDurations[key]].data[custodianId]
        : null;
      return (
        <RowCell key={key + custodianId}>
          {item && !item.total === false ? (
            <RowValue
              currency={this.props.portfolioCurrency}
              startValue={0}
              endValue={item.total}
              disableColor={true}
              disableColorCode={"rgba(0,0,0)"}
              alignPosition={"left"}
            />
          ) : (
            ""
          )}
        </RowCell>
      );
    });
  }

  render() {
    const { portfolioGroupedFundSchedule } = this.props;
    const custodianIds = portfolioGroupedFundSchedule.custodianIds;

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <ScheduleDialog>
          <Container>
            <Title>{i18n.t("fundScheduleSummaryDialog.title")}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: i18n.t("fundScheduleSummaryDialog.description")
              }}
            />

            <TotalHeader>
              {Object.keys(fundScheduleDurations).map(key => {
                const value = this.props.portfolioGroupedFundSchedule.data[fundScheduleDurations[key]]
                  ? this.props.portfolioGroupedFundSchedule.data[fundScheduleDurations[key]].total
                  : 0;

                return (
                  <TotalContainer key={key}>
                    <TotalTitle>{getFundScheduleTitle(fundScheduleDurations[key])}</TotalTitle>
                    {value ? (
                      <TotalValue
                        currency={this.props.portfolioCurrency}
                        startValue={0}
                        endValue={value}
                        disableColor={true}
                        disableColorCode={"rgba(0,0,0)"}
                        alignPosition={"left"}
                      />
                    ) : (
                      <EmptyValue>{"--"}</EmptyValue>
                    )}
                  </TotalContainer>
                );
              })}
            </TotalHeader>

            <Table>
              <TableHeader>
                <HeaderCell isFirst={true}>{""}</HeaderCell>
                {Object.keys(fundScheduleDurations).map(key => (
                  <HeaderCell key={key}>{getFundScheduleTitle(fundScheduleDurations[key])}</HeaderCell>
                ))}
              </TableHeader>
              {custodianIds.map(custodianId => {
                const firstKey = Object.keys(portfolioGroupedFundSchedule.data).find(
                  key => !portfolioGroupedFundSchedule.data[key].data[custodianId] === false
                );
                if (!firstKey === true) {
                  return null;
                }
                const name = portfolioGroupedFundSchedule.data[firstKey].data[custodianId].items[0].custodianName;
                return (
                  <Row key={custodianId}>
                    <RowCell isFirst={true} onClick={e => this.handleFundNameClick(e, custodianId)}>
                      {name}
                    </RowCell>
                    {this.getRowCells(custodianId)}
                  </Row>
                );
              })}
              <TableFooter>
                <FooterCell isFirst={true}>{""}</FooterCell>
                {Object.keys(fundScheduleDurations).map(key => (
                  <FooterCell key={key}>
                    {portfolioGroupedFundSchedule.data[fundScheduleDurations[key]] &&
                    !portfolioGroupedFundSchedule.data[fundScheduleDurations[key]].total === false ? (
                      <RowValue
                        currency={this.props.portfolioCurrency}
                        startValue={0}
                        endValue={portfolioGroupedFundSchedule.data[fundScheduleDurations[key]].total}
                        disableColor={true}
                        disableColorCode={"rgba(255,255,255)"}
                        alignPosition={"left"}
                      />
                    ) : (
                      "--"
                    )}
                  </FooterCell>
                ))}
              </TableFooter>
            </Table>
          </Container>

          {this.state.showFundScheduleDialog && (
            <FundScheduleDialog
              shouldFetchDetails={true}
              custodian={this.state.fundScheduleDialogCustodian}
              isReadOnly={this.props.isReadOnly}
              currency={this.props.portfolioCurrency}
              onDismiss={this.handleFundScheduleDialogOnDismiss}
            />
          )}
        </ScheduleDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  portfolioGroupedFundSchedule: portfolioGroupedFundScheduleSelector(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundScheduleSummaryDialog);
