import React from "react";
import styled from "styled-components";
import { ReactComponent as OptionsIcon } from "assets/images/options.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { ReactComponent as SortIcon } from "assets/images/expandable_indicator.svg";

const WrapperContainer = styled.div``;

const Container = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.div`
  display: table-row;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
  height: 51px;
`;

const HeaderCellContainer = styled.div`
  display: table-cell;
  box-sizing: border-box;
  text-align: ${props => (props.isFirst === true ? "left" : "right")};
  margin-left: ${props => (props.isFirst === true ? "30px" : "0px")};
  min-width: 96px;
  max-width: ${props => (props.isFirst === false ? "200px" : null)};
  padding: ${props => (props.isFirst === true ? "0 30px" : "0 5px")};
  white-space: nowrap;
`;

const HeaderCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isFirst === true ? "flex-start" : "flex-end")};
`;

const TableHeaderCell = styled.div`
  cursor: pointer;
  white-space: pre;
`;

const HeaderDecreasingSortIndicator = styled(SortIcon)`
  width: 8px;
  height: 8px
  margin-right: 3px;
  transform: rotate(0deg);
  cursor: pointer;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const HeaderIncreasingSortIndicator = styled(HeaderDecreasingSortIndicator)`
  transform: rotate(180deg);
`;

const TableRow = styled.div`
  display: table-row;
  min-height: 50px;
  padding-left: 30px;
  border-bottom: ${props => (props.isLast === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  cursor: ${props => (props.clickable === true ? "pointer" : "")};
  height: 51px;

  &:hover {
    background-color: ${props => props.theme.gridRowHoverColor};
  }
`;

const TableCell = styled.div`
  display: table-cell;
  box-sizing: border-box;
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: ${props => (props.isFirst === true ? "left" : "right")};
  font-feature-settings: "ss01" on, "calt" off;
  min-width: 96px;
  max-width: ${props => (props.isFirst === false ? "200px" : null)};
  padding: ${props => (props.isFirst === true ? "0 30px" : "0 5px")};
  vertical-align: middle;
  min-height: 50px;
`;

const OptionsHeaderCell = styled.div`
  min-width: 60px;
`;

const OptionsCellContainer = styled.div`
  width: 40px;
  height: 100%;
  display: table-cell;
`;

const OptionsCell = styled.button`
  width: 60px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  verical-align: middle;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const OptionsIconComponent = styled(OptionsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

class TableComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByColumnSortKey: props.data.defaultSortByColumnSortKey,
      decreasingSortOrder: props.data.decreasingSortOrder
    };

    this.handleOptionsCellClick = this.handleOptionsCellClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);

    this.contextMenuRef = React.createRef();
    this.contextMenuShownForRow = null;
  }

  handleOptionsCellClick(e, row) {
    e.stopPropagation();

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
    }
    this.contextMenuShownForRow = row;

    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      row.getContextMenuItems(row),
      targetPosition.right - this.contextMenuRef.current.getWidth(),
      targetPosition.top + targetPosition.height,
      true,
      e.target
    );
  }

  handleContextMenuSelection(item) {
    this.props.onContextMenuSelection(item, this.contextMenuShownForRow);
  }

  handleHeaderClick(e, column) {
    var decreasingSortOrder = true;
    if (column.sortKey === this.state.sortByColumnSortKey) {
      decreasingSortOrder = !this.state.decreasingSortOrder;
    }
    this.setState({ sortByColumnSortKey: column.sortKey, decreasingSortOrder: decreasingSortOrder });

    if (this.props.onSortChange) {
      this.props.onSortChange(column.sortKey, decreasingSortOrder);
    }
  }

  handleRowClick(e, row) {
    if (this.props.onRowClick) {
      this.props.onRowClick(e, row);
    }
  }

  static getComparedValue(valueA, valueB, type = "number") {
    if (type === "number") {
      return valueB - valueA;
    }

    return valueB.localeCompare(valueA);
  }

  getTableData() {
    const tableData = this.props.data;
    const sortByColumnSortKey = this.state.sortByColumnSortKey;
    const decreasingSortOrder = this.state.decreasingSortOrder;
    const sortByColumn = this.props.data.columns.find(column => column.sortKey === sortByColumnSortKey);

    if (decreasingSortOrder === true) {
      tableData.rows.sort((a, b) => {
        const valueA = sortByColumn.getColumnCellValue(a.rowObject);
        const valueB = sortByColumn.getColumnCellValue(b.rowObject);

        return TableComponent.getComparedValue(valueA || "", valueB || "", sortByColumn.type);
      });
    } else {
      tableData.rows.sort((a, b) => {
        const valueA = sortByColumn.getColumnCellValue(a.rowObject);
        const valueB = sortByColumn.getColumnCellValue(b.rowObject);

        return TableComponent.getComparedValue(valueB || "", valueA || "", sortByColumn.type);
      });
    }
    return tableData;
  }

  render() {
    const tableData = this.getTableData();
    const sortByColumnSortKey = this.state.sortByColumnSortKey;
    const decreasingSortOrder = this.state.decreasingSortOrder;
    const onRowClick = this.props.onRowClick;

    return (
      <WrapperContainer className={this.props.className}>
        <Container>
          <TableHeader>
            {tableData.columns.map((column, index) => (
              <HeaderCellContainer key={index} isFirst={index === 0}>
                <HeaderCellWrapper isFirst={index === 0}>
                  {column.sortKey === sortByColumnSortKey && decreasingSortOrder === true ? (
                    <HeaderDecreasingSortIndicator onClick={e => this.handleHeaderClick(e, column)} />
                  ) : column.sortKey === sortByColumnSortKey && decreasingSortOrder === false ? (
                    <HeaderIncreasingSortIndicator onClick={e => this.handleHeaderClick(e, column)} />
                  ) : (
                    <>&nbsp;</>
                  )}
                  <TableHeaderCell onClick={e => this.handleHeaderClick(e, column)}>{column.title}</TableHeaderCell>
                </HeaderCellWrapper>
              </HeaderCellContainer>
            ))}
            <OptionsHeaderCell />
          </TableHeader>
          {tableData.rows.map((row, index) => (
            <TableRow
              key={index}
              isLast={index === tableData.rows.length - 1}
              clickable={!onRowClick === false}
              onClick={e => this.handleRowClick(e, row)}
            >
              {tableData.columns.map((column, index) => (
                <TableCell key={index} isFirst={index === 0}>
                  {column.renderColumnCell(row.rowObject, column.getColumnCellValue(row.rowObject))}
                </TableCell>
              ))}
              <OptionsCellContainer>
                <OptionsCell onClick={e => this.handleOptionsCellClick(e, row)}>
                  <OptionsIconComponent />
                </OptionsCell>
              </OptionsCellContainer>
            </TableRow>
          ))}
          <ContextMenu
            width={233}
            ref={this.contextMenuRef}
            onSelection={this.handleContextMenuSelection}
            preventGlobalScroll
          />
        </Container>
      </WrapperContainer>
    );
  }
}

export default TableComponent;
