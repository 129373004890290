import React from "react";
import { useTheme } from "theme";
import styled from "styled-components";
import {
  recapChartTypes,
  shortFormatNumberWithCurrency,
  RECAP_CELL_CATEGORY_TYPE_TEXT,
  recapChartOptions,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";
import { useSelector } from "react-redux";
import { currentPortfolioCurrencySelector, convertCurrency } from "@kubera/common";
import { ReactComponent as ExpandIcon } from "assets/images/exit.svg";
import { ReactComponent as ShowMoreIcon } from "assets/images/showmore.svg";
import { routes } from "routes";
import ChangeLabel from "components/labels/ChangeLabel";
// import BadgeTip from "components/tooltip/BadgeTip";
import { ReactComponent as ChartsIcon } from "assets/images/report_chart_icon.svg";
import expandableIndicatorIcon from "assets/images/expandable_indicator.svg";
import expandableIndicatorIconDark from "assets/images/expandable_indicator_dark.svg";
import i18n from "i18next";

const CellContainer = styled.div.attrs(props => ({
  tabIndex: 0
}))`  
  display: flex;
  flex: 1;
  &:focus {
    outline: ${props => (!props.isGridHeader ? `2px solid ${props.theme.gridCellSelectedOutlineColor}` : "none")} ;
    outline-offset: -2px;
  };
  min-width: ${props =>
    props.isTextCell ? "450px" : props.isChartsCell ? "28px" : props.isExpandCollapseIconCell ? "20px" : "155px"};
  max-width: ${props =>
    props.isTextCell ? "none" : props.isChartsCell ? "28px" : props.isExpandCollapseIconCell ? "20px" : "170px"};
    width: auto
  align-items: center
  position: -webkit-sticky;
  position: ${props =>
    (props.isTextCell || props.isChartsCell || props.isExpandCollapseIconCell) && props.isExpandedView
      ? "sticky"
      : "static"}
  z-index:${props => (props.isTextCell || props.isChartsCell ? 5 : props.isExpandCollapseIconCell ? 10 : 0)}
  left:${props => (props.isExpandCollapseIconCell ? "60px" : props.isChartsCell ? "80px" : "108px")}
  //left: 108px
  background-color : ${props =>
    props.isGridHeader ? props.theme.dashboardBackgroundColor : props.theme.gridRowUpdatedBackgroundColor};
  border-right: ${props =>
    (props.isLastCell && !props.isGridHeader) ||
    (props.showOverflowIndicator && props.isTextCell && props.isExpandedView)
      ? `1px solid ${props.theme.gridSectionBorderColor}`
      : "none"};
  border-left: ${props =>
    props.isFirstCell && !props.isGridHeader ? `1px solid ${props.theme.gridSectionBorderColor}` : "none"};
  box-sizing: border-box
  cursor : ${props =>
    props.isChartsCell && !props.isExpandCollapseIconCell && !props.isDisabled && !props.isGridHeader
      ? "pointer"
      : "default"}
   scroll-margin-left: 590px;
   scroll-margin-right: 100px;
   -webkit-scroll-margin-left: 550px;
   &:hover {
    background-color: ${props =>
      (props.isChartsCell || props.isExpandCollapseIconCell) && !props.isDisabled && !props.isGridHeader
        ? props.theme.focusBackgroundColor
        : ""};
    opacity : 1;
  }
`;

const CellItem = styled.div`
  // min-height: 40px;
  display:inline-flex;
  flex: 1
  align-items: ${props => (props.isTextCell ? "flex-start" : "flex-end")};
  flex-direction : column;
  padding : 10px 0px 10px 15px;
  margin-right : ${props =>
    props.isLastCell
      ? props.isGridHeader
        ? props.isExpandedView
          ? props.shouldShowIcon
            ? "5px"
            : "36px"
          : "4px"
        : "36px"
      : "25px"}
`;

const CellPrimaryValue = styled.div`
  text-overflow: ellipsis;
  overflow : hidden
  max-width: ${props => (props.isTextCell ? "400px" : "120px")};
  font-style: normal;
  font-size: ${props =>
    props.isGridHeader
      ? "11px"
      : (props.isNetWorthRow || props.category === "categoryHeader") && props.isTextCell && !props.isArchived
      ? "18px"
      : props.isTextCell && (props.isSectionHeader || props.category === "sheetHeader")
      ? "16px"
      : "14px"};
  font-feature-settings: "ss01" on;
  font-weight: ${props =>
    props.isGridHeader
      ? "700"
      : props.isTextCell &&
        (props.isNetWorthRow ||
          props.isSectionHeader ||
          props.category === "sheetHeader" ||
          props.category === "sectionHeader")
      ? "700"
      : "400"};
  text-align: ${props => (props.isTextCell ? "left" : "right")};
  // margin-bottom: 2px;
  white-space:${props => (props.isTextCell ? "normal" : "nowrap")} ;
  text-transform: ${props => (props.isGridHeader ? "uppercase" : "none")};
`;
const RecapChangeLabel = styled(ChangeLabel)`
text-overflow: ellipsis;
  overflow : hidden
  max-width: ${props => (props.isTextCell ? "400px" : "120px")};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: ${props => (props.isTextCell ? "left" : "right")};
  font-feature-settings: "ss01" on, "calt" off;
`;
const CellSecondaryValue = styled.div`
  margin-top: ${props => (props.isTextCell ? "none" : "2px")};
`;
const EmptySecondaryValue = styled.div`
  color: ${props => props.theme.columnanChartSecondaryValueCLR};
  font-size: 11px;
  line-height: 13px;
`;
const ExpandColumnarChartIconContainer = styled.div`
  width: 32px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;
const ExpandColumnarChartIcon = styled(ExpandIcon)`
  height: 9px;
  width: 9px;
  margin-right: 10px
  margin-left: 10px 
    path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;
const ShowMoreIconContainer = styled.div`
  height: 30px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;
const ShowMoreColumnarChartIcon = styled(ShowMoreIcon)`
  cursor: pointer;
  padding-right: 4px;
  padding-left: 1px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

// const DetailsCellBadge = styled(BadgeTip)`
//   position: absolute;
//   right: 8px;
//   top: 11px;
// `;

const ChartsCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: ${props => (props.isDisabled ? "default" : "pointer")};
  background-color: transparent;

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const ChartsIconComponent = styled(ChartsIcon)`
  path {
    fill: ${props => (props.disabled ? props.theme.recapReportChartIconDisabled : props.theme.recapReportChartIcon)};
  }
`;

const ExpandCollapseIconCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: ${props => (props.isDisabled ? "default" : "pointer")};
  background-color: transparent;

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const ExpandSectionIcon = styled.div`
  flex : 1;
  width: 9px;
  height: 19px
  //margin-left: 6px;
  background-color: transparent;
  background-image: url(${props =>
    props.theme.mode === "default" ? expandableIndicatorIcon : expandableIndicatorIconDark});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px 9px;
  //transform: rotate(270deg);
  display: ${props => (!props.shouldShow ? "none" : "inline")} 
`;

const CollapseSectionIcon = styled.div`
  flex : 1;
  width: 9px;
  height: 19px
  //margin-right: 3px;
  background-color: transparent;
  background-image: url(${props =>
    props.theme.mode === "default" ? expandableIndicatorIcon : expandableIndicatorIconDark});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px 9px;
  transform: rotate(270deg);
  display: ${props => (!props.shouldShow ? "none" : "inline")} 
`;
const DescriptionForUnknownClass = styled.div`
  font-weight: normal;
  font-size: 11px;
  color: ${props => props.theme.columnanChartSecondaryValueCLR};
`;

const ColumnarChartCellComponent = props => {
  const theme = useTheme();
  let {
    currency,
    cell,
    shouldShowIcon,
    noOfColumns,
    setNoOfColumns,
    isGridHeader,
    isLastCell,
    isFirstCell,
    sectionIndex,
    rowIndex,
    showOverflowIndicator,
    isMoreColumnsAvailable,
    currentValue,
    previousValue,
    isExpandedView,
    cellId,
    category,
    isCollapsed,
    sectionName,
    cellIndex
  } = props;
  currentValue = Math.kuberaFloor(currentValue);
  previousValue = previousValue === null ? previousValue : Math.kuberaFloor(previousValue);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const isCurrencyCell = cell.selectedChartType === recapChartTypes.TOTALS;
  const isTextCell = cell.isTextCell || cell.type === RECAP_CELL_CATEGORY_TYPE_TEXT;
  const isChartsCell = cell.isChartsCell;
  const isExpandCollapseIconCell = cell.isExpandCollapseIconCell;
  const isDisabled = cell.isDisabled;
  const isPercentageChangeShown = cell.isPercentageChangeShown;
  const isSectionHeader = rowIndex === 0;
  const isDebtSection =
    (sectionIndex === 2 && cell.selectedChartOptions === recapChartOptions.NETWORTH.id) ||
    (cell.selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id &&
      (sectionName === "Debts" || sectionName === "Archived Debts"));
  const isNetWorthRow = sectionIndex === 0 && rowIndex === 0;
  const isPercentageCell = cell.selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION;
  const isArchived = cell.isArchived;
  const shouldShowExpandCollapseIcon =
    category === "header" ||
    category === "sheetHeader" ||
    category === "sectionHeader" ||
    (category === "categoryHeader" && isArchived);
  const canShowPercentageChange = previousValue !== null && currentValue !== previousValue;

  const getFormattedValue = (
    value,
    currency,
    portfolioCurrency,
    includeCurrencySymbol,
    shouldIncludePositiveSign,
    alwaysShowDecimal
  ) => {
    if ((isCurrencyCell || isNetWorthRow || isSectionHeader) && !cell.isTextCell) {
      if (value === 0 && !isSectionHeader && cell.selectedChartOptions !== recapChartOptions.SHEETS_AND_SECTIONS.id) {
        return `---`;
      }
      const convertedCurrency = convertCurrency(value, currency, portfolioCurrency);
      return shortFormatNumberWithCurrency(
        convertedCurrency,
        portfolioCurrency,
        true,
        includeCurrencySymbol,
        shouldIncludePositiveSign,
        alwaysShowDecimal
      );
    } else {
      return isArchived && cell.selectedChartOptions !== recapChartOptions.SHEETS_AND_SECTIONS.id
        ? `${value} (Archived)`
        : value;
    }
  };
  const expandRecapTabInNewWindow = () => {
    window.kuberaOpen(`${process.env.PUBLIC_URL}${routes.REPORTS_EXPANDED}`);
  };
  const formattedValue = getFormattedValue(cell.value, currency, portfolioCurrency, isTextCell ? false : true, false);
  const convertedCurrentValue = convertCurrency(currentValue, currency, portfolioCurrency);
  const convertedPreviousValue = convertCurrency(previousValue, currency, portfolioCurrency);
  const showExpandOrShowMoreIcon = () => {
    if (shouldShowIcon) {
      if (isExpandedView) {
        if (isMoreColumnsAvailable) {
          return (
            <ShowMoreIconContainer
              onClick={() => {
                setNoOfColumns(noOfColumns + 3);
              }}
            >
              <ShowMoreColumnarChartIcon />
            </ShowMoreIconContainer>
          );
        }
      } else {
        return (
          <ExpandColumnarChartIconContainer
            onClick={() => {
              expandRecapTabInNewWindow();
            }}
          >
            <ExpandColumnarChartIcon />
          </ExpandColumnarChartIconContainer>
        );
      }
    }
  };
  const showCellSecondaryValue = isDebtSection => {
    if ((isCurrencyCell || isPercentageCell) && isPercentageChangeShown) {
      if (canShowPercentageChange) {
        return (
          <RecapChangeLabel
            startValue={isPercentageCell ? previousValue : convertedPreviousValue}
            endValue={isPercentageCell ? currentValue : convertedCurrentValue}
            currency={portfolioCurrency}
            isChangeCalculatedForPercentageValues={
              isPercentageCell &&
              (!isSectionHeader ||
                (cell.selectedChartOptions !== recapChartOptions.CASH_ON_HAND.id &&
                  cell.selectedChartOptions !== recapChartOptions.NETWORTH.id)) &&
              !isNetWorthRow
                ? true
                : false
            }
            disableColor={isDebtSection}
            disableColorCode={theme.gridCellSecondaryColor}
            shouldShowCurrencySymbol={false}
            showPercentageInsideParanthesis={false}
            isRecapChangeLabel={true}
          />
        );
      } else {
        if (cell.value === 0) {
          return null;
        }
        return <EmptySecondaryValue>----</EmptySecondaryValue>;
      }
    } else if (
      isTextCell &&
      category === "header" &&
      (cell.selectedChartOptions === recapChartOptions.ASSET_CLASSES.id ||
        cell.selectedChartOptions === recapChartOptions.INVESTABLE.id ||
        cell.selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id) &&
      cell.value === "Unknown"
    ) {
      return <DescriptionForUnknownClass>{i18n.t("unknownAssetClassDescription.recap")}</DescriptionForUnknownClass>;
    }
  };
  const handleFocus = e => {
    //selectText(cellId);
  };

  const handleKeyDown = e => {
    if (e.target.id !== cellId) {
      return false;
    }
    if (e.key === "Enter") {
      if (!isDisabled && isChartsCell) {
        console.log("charts modal");
        props.handleOpenChartsModalClick(e);
      }
    }
  };

  const renderCellContent = () => {
    if (isChartsCell && !isGridHeader) {
      return (
        <ChartsCell isDisabled={isDisabled}>
          <ChartsIconComponent disabled={isDisabled} />
        </ChartsCell>
      );
    } else if (isExpandCollapseIconCell) {
      return (
        <ExpandCollapseIconCell isDisabled={isDisabled}>
          {!isCollapsed
            ? !isDisabled && <ExpandSectionIcon shouldShow={shouldShowExpandCollapseIcon} />
            : !isDisabled && <CollapseSectionIcon shouldShow={shouldShowExpandCollapseIcon} />}
        </ExpandCollapseIconCell>
      );
    } else {
      return (
        <CellItem
          isTextCell={isTextCell}
          isExpandedView={isExpandedView}
          isGridHeader={isGridHeader}
          isLastCell={isLastCell}
          shouldShowIcon={shouldShowIcon && isMoreColumnsAvailable}
        >
          <CellPrimaryValue
            shouldShowIcon={shouldShowIcon}
            isGridHeader={isGridHeader}
            isLastCell={isLastCell}
            isSectionHeader={isSectionHeader}
            isNetWorthRow={isNetWorthRow}
            isTextCell={isTextCell}
            category={category}
            isArchived={isArchived}
          >
            {isPercentageCell
              ? cell.actualValue === 0
                ? isSectionHeader
                  ? `0%`
                  : `---`
                : isNetWorthRow ||
                  category === "categoryHeader" ||
                  (category === "header" && cell.selectedChartOptions === recapChartOptions.NETWORTH.id)
                ? formattedValue
                : cell.value === 0
                ? `~0.1%`
                : `${formatNumberWithKuberaNumberFormatSettings(cell.value)}%`
              : isGridHeader
              ? cell.value
              : formattedValue}
          </CellPrimaryValue>
          <CellSecondaryValue isTextCell={isTextCell}>{showCellSecondaryValue(isDebtSection)}</CellSecondaryValue>
        </CellItem>
      );
    }
  };

  return (
    <CellContainer
      isTextCell={isTextCell}
      isGridHeader={isGridHeader}
      isLastCell={isLastCell}
      isSectionHeader={isSectionHeader}
      isCurrencyCell={isCurrencyCell}
      isFirstCell={isFirstCell}
      id={`${cellId}`}
      isNetWorthRow={isNetWorthRow}
      showOverflowIndicator={showOverflowIndicator}
      isExpandedView={isExpandedView}
      onFocus={handleFocus}
      isChartsCell={isChartsCell}
      isExpandCollapseIconCell={isExpandCollapseIconCell}
      onClick={
        !isDisabled
          ? isChartsCell
            ? props.handleOpenChartsModalClick
            : isExpandCollapseIconCell
            ? () => {
                props.handleRecapExpandOrCollapse(
                  rowIndex,
                  cell.selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id,
                  cell.selectedChartOptions
                );
              }
            : () => {
                localStorage.setItem("latestCellIndexes", `${0},${sectionIndex},${rowIndex},${cellIndex}`);
              }
          : null
      }
      isDisabled={isDisabled}
      onKeyDown={handleKeyDown}
    >
      {renderCellContent()}
      {showExpandOrShowMoreIcon()}
    </CellContainer>
  );
};
export default React.memo(ColumnarChartCellComponent);
