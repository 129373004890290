import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import i18n from "i18next";

import {
  getCustodianHistoryFormattedDateString,
  getReceiptUrl,
  showToastTip,
  getDateInDDMMMYYYY
} from "@kubera/common";
import Spinner from "components/loader/Spinner";

const Container = styled.div`
  position: relative;
  display: table-row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
`;

const Cell = styled.div`
  display: table-cell;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  vertical-align: middle;
  width: ${({ width }) => (width ? width : null)};
  padding: 0 14px;
  white-space: nowrap;
`;

const CellAction = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const Loader = styled(Spinner)`
  position: absolute;
  right: 15px;
  top: 3px;
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

const PaymentsList = ({ charge = {} }) => {
  const dispatch = useDispatch();

  const [isReceiptLoading, setIsReceiptLoading] = useState(false);

  const createdDateFormatted = getCustodianHistoryFormattedDateString(new Date(charge.tsCreated * 1000));

  const handleOnActionClick = async () => {
    if (charge.receiptUrl) {
      window.kuberaOpen(charge.receiptUrl, "_blank");
      return;
    }
    setIsReceiptLoading(true);
    const receiptUrlData = await dispatch(getReceiptUrl(charge.invoiceId)).catch(() => {
      dispatch(
        showToastTip("TIP", `${i18n.t("genericExceptionTitle")} ${i18n.t("forgotPasswordContactUs")}`, null, 5000)
      );
    });
    setIsReceiptLoading(false);
    if (receiptUrlData) {
      window.kuberaOpen(receiptUrlData.url, "_blank");
    }
  };

  return (
    <Container>
      <Cell width="100%">{createdDateFormatted}</Cell>
      <Cell width="90px">
        <CellAction onClick={handleOnActionClick}>{!isReceiptLoading ? "Receipt" : <Loader />}</CellAction>
      </Cell>
    </Container>
  );
};

export default PaymentsList;
