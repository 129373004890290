import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: ${props => (props.stretch ? "1" : "")};
  flex-direction: ${props => (props.hasError ? "column" : "row")};
`;

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  background-color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.05)" : "#ffffff")};
  outline: 0;

  ::placeholder {
    color: ${props => (!props.color === true ? "rgba(0, 0, 0, 0.4)" : props.color)};
  }
`;

const ErrorMessage = styled.p`
  font-size: ${props => (!props.fontSize === true ? "11px" : props.fontSize)}
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => (!props.color === true ? props.theme.errorCLR : props.color)}
  margin-block-start: ${props => (!props.topMargin === true ? "5px !important" : `${props.topMargin} !important`)};
  margin-block-end: 0em !important;
`;

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const {
      className,
      type,
      onChange,
      value,
      disabled,
      errorMessage,
      stretch,
      autoComplete = "off",
      ...other
    } = this.props;
    const inputValue = value === null || value === undefined ? "" : value;

    return (
      <Container stretch={!stretch === false} hasError={!errorMessage === false}>
        <Input
          {...other}
          ref={this.props.inputRef}
          type="text"
          className={className}
          value={inputValue}
          onChange={this.handleInputChange}
          autoComplete={autoComplete}
          disabled={disabled === true}
          data-1p-ignore
          data-protonpass-ignore
        />
        {!errorMessage === false && (
          <ErrorMessage
            color={this.props.errorMessageColor}
            fontSize={this.props.errorMessageFontSize}
            topMargin={this.props.errorMessageTopMargin}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    );
  }
}

export default TextInput;
