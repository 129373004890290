import React from "react";
import styled from "styled-components";
import i18n from "i18next";

const EmptyViewContent = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  max-height: 270px;
  margin-top: 0px;
  padding: 50px;
`;

const EmptyContentDescription = styled.div`
  width: max-content;
  margin-top: 50px;
  padding: 12px;
  padding-left: 60px;
  padding-right: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-line;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  box-sizing: border-box;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
`;

class NetWorthChartNotAvailableComponent extends React.Component {
  render() {
    return (
      <EmptyViewContent>
        <EmptyContentDescription>{i18n.t("empthNetWorthChartDescription")}</EmptyContentDescription>
      </EmptyViewContent>
    );
  }
}

export default NetWorthChartNotAvailableComponent;
