import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  converUIDateFormatToCustodianHistoryApiDateFormat,
  getSortKeyBetween,
  showToastTip,
  guessDateInCustodianHistoryUIFormat,
  custodianDetailsIsPendingSelector,
  currentPortfolioCurrencySelector,
  getTickerUsingId,
  tickerTypes,
  getSanitizedExchangeRate,
  formatNumberAsCurrency,
  isAppInViewMode,
  fetchTickerDetails,
  tickerSubTypes,
  convertPVSTRateToValueExchangeRate,
  getDateStringForExchangeRateDate,
  refreshCustodian,
  getCustodianHistoryFormattedDateString,
  userMaskAllValuesSelector
} from "@kubera/common";
import { connect } from "react-redux";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";

const PVSTDetailsDialog = styled(Dialog)`
  width: 620px;
  min-height: 600px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 60px;
`;

const GridTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const GridContainer = styled.div`
  margin-top: 10px;
  border: ${props => (props.theme.mode === "default" ? "1px solid rgba(0, 0, 0, 0.1)" : null)};
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: ${props => (!props.showCursor ? "default" : "pointer")};
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

let prevRateRef = null;
class CustodianPVSTHistoryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);

    props.details.rate.sort((a, b) => b.date.localeCompare(a.date));
    this.state = {
      gridData: this.getGridData(props.portfolioCurrency, props.details.rate),
      showRateCellDialog: false,
      rateCellDialogData: null
    };
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
  }

  getGridData(currency, rateHistory) {
    var rows = [];
    let nextSortKey = null;
    for (const [, rateEntry] of rateHistory.entries()) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey, rateEntry);
      row.id = rateEntry.id || getUuid();
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(rateEntry.date);
      row.tsModified = rateEntry.tsModified;
      rows.push(row);
    }
    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.currentSheetIndex = 0;
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  componentDidUpdate() {
    if (this.props.details.rate !== prevRateRef) {
      this.props.details.rate.sort((a, b) => b.date.localeCompare(a.date));
      this.setState({ gridData: this.getGridData(this.props.portfolioCurrency, this.props.details.rate) });
    }
    prevRateRef = this.props.details.rate;
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const priceColumn = new GridColumnData("Price", true, this.props.isReadOnly === false, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, priceColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      columns,
      undefined,
      undefined,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = true;
    sectionData.modifyCss = css`
      height: 35px;
    `;
    return sectionData;
  }

  getEmptyRow(sortKey, rateEntry) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    let rateParsed = null;
    if (rateEntry?.rate) {
      rateParsed = JSON.parse(rateEntry.rate);
    }
    const costCell = new CurrencyCellData(
      cellType.CURRENCY,
      "Price",
      rateParsed?.p,
      rateParsed?.t ? getTickerUsingId(rateParsed?.t).shortName : this.props.portfolioCurrency
    );
    costCell.enterEditModeOnClick = false;
    costCell.useRateFromExchangeRateDetails = true;
    costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    costCell.unsupportedTickerSubTypes = [tickerSubTypes.PRECIOUS_METALS];
    costCell.textAlignment = "right";
    costCell.showDecimal = true;
    costCell.order = 2;
    if (rateEntry?.valueExchangeRate) {
      const parsedExchangeRate = JSON.parse(rateEntry.valueExchangeRate);
      if (parsedExchangeRate) {
        costCell.exchangeRateDetails = JSON.stringify(parsedExchangeRate);
      }
    }

    costCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const costGridCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1];

      const cellData = {
        exchangeRateDate: new Date(rateEntry?.date),
        useRateFromExchangeRateDetails: rateParsed?.t ? true : false,
        fromCurrency: costGridCell.currency,
        toCurrency: this.props.portfolioCurrency,
        rate: costGridCell.getCellExchangeRate(this.props.portfolioCurrency),
        sanitizedRate: getSanitizedExchangeRate(
          costGridCell.getCellExchangeRate(this.props.portfolioCurrency),
          this.props.portfolioCurrency
        ),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 1
      };
      const showExchangeRateDetails = cellData.fromCurrency !== cellData.toCurrency;
      return (
        showExchangeRateDetails && (
          <ExchangeRateCell showCursor={false}>
            <ExchangeCurrencies>{`${cellData.fromCurrency.replace(".CC", "")}/${cellData.toCurrency.replace(
              ".CC",
              ""
            )}`}</ExchangeCurrencies>
            <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.portfolioCurrency)}</ExchangeRate>
          </ExchangeRateCell>
        )
      );
    };

    const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
    const padCell = new GridCellData(cellType.PADDING, "", null);
    padCell.padding = "0px 18px";

    const cells = [
      dateCell,
      costCell,
      (!rateEntry?.date || convertCustodianHistoryApiDateFormatToUIFormat(rateEntry.date) !== dateStringForToday) &&
      this.props.details.rate.length > 1
        ? optionsCell
        : padCell
    ];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    rowData.showHint = false;

    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true) {
        return null;
      }
      return [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.DELETE]];
    };
    rowData.onUpdateDelay = 3000;
    return rowData;
  }

  handleRateCellClick = (e, cellData) => {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  };

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      (row, index) => rowIndex !== index && row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (!row.isComplete()) {
      return;
    }

    if (menuItem.id === contextMenuItemType.DELETE.id) {
      this.props.deleteRate(row.id);
    }
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      return;
    }

    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }

    this.handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow);
  }

  handleHistoryUpdate = async (sheetIndex, sectionIndex, rowIndex, updatedRow) => {
    if (!updatedRow.cells[1].exchangeRateDetails) {
      return;
    }
    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }

    const valueCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[1];
    if (
      valueCell.currency !== this.props.currency &&
      getDateStringForExchangeRateDate(valueCell.exchangeRateDate) !==
        getDateStringForExchangeRateDate(guessedDate.date)
    ) {
      valueCell.invalidInputText = valueCell.currency;
      valueCell.exchangeRateDate = guessedDate.date;
      this.handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, 1);
      return;
    }

    const guessedDateInCustodianHistoryFormat = converUIDateFormatToCustodianHistoryApiDateFormat(
      guessedDate.dateString
    );
    this.props.updateRate(updatedRow, 1, updatedRow.id, guessedDateInCustodianHistoryFormat);
  };

  updateGridRow = (newRow, sheetIndex, sectionIndex, rowIndex) => {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ ...this.state, gridData: newGridData });
  };

  handleCellInvalidTickerAdded = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
      const date = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;
      this.props.fetchTickerDetails(
        cell.invalidInputText,
        date,
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          const validTickers = result.tickers.filter(
            ticker =>
              cell.supportedTickerTypes.includes(ticker.info.type) &&
              !cell.unsupportedTickerSubTypes.includes(ticker.info.subType)
          );
          if (validTickers.length > 1) {
            cell.tickerInfo = validTickers;
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          } else if (validTickers.length === 1) {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;

            const newRow = row.clone();
            this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
            this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
          } else {
            const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
            const cell = row.cells[cellIndex];
            cell.loading = false;
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            this.props.showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000);
          }
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  };

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 1) {
      const newGridData = this.state.gridData;
      let cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue) {
        return;
      }
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1].value = cellValue;
      this.setState({ ...this.state, gridData: newGridData });
    }

    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      var dateString = guessedDate.dateString;
      const newGridData = this.state.gridData;

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = null;
      }
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].value = dateString;
      this.setState({ ...this.state, gridData: newGridData });
    }
  }

  isOldestRow = (row, sheetIndex, sectionIndex) => {
    var min = null;
    for (const row of this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows) {
      if (!row.cells[0].value === false) {
        const tempDate = new Date(row.cells[0].value);
        if (!min === true) {
          min = row.cells[0].value;
        }

        if (tempDate.getTime() < new Date(min).getTime()) {
          min = row.cells[0].value;
        }
      }
    }

    if (min === row.cells[0].value && !row.cells[1].value === false) {
      return true;
    }
    return false;
  };

  handleRateCellDialogOnDismiss = () => {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  };

  handleRateCellDialogOnChange = rate => {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
  };

  render() {
    const gridData = this.state.gridData;
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <PVSTDetailsDialog>
          <Container>
            <GridTitle>{i18n.t("stockPriceHistory")}</GridTitle>
            <GridContainer>
              <Grid
                title={i18n.t("stockPriceHistory")}
                gridData={gridData}
                getEmptyRow={this.getEmptyRow}
                onRowContextMenuSelection={this.handleRowContextMenuSelection}
                onChange={this.handleChange}
                onRowUpdate={this.handleRowUpdate}
                onCellBlur={this.handleCellBlur}
                showGridTitle={false}
                onAddNewRow={this.handleAddNewRow}
                addRowBtnTxt={
                  isAppInViewMode() || this.props.useMaskedValues ? null : i18n.t("custodianDetails.addHistoryBtnTxt")
                }
                onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
              />
            </GridContainer>
          </Container>
        </PVSTDetailsDialog>
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
            readOnly
          />
        )}
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetchPending: custodianDetailsIsPendingSelector(state),
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  convertPVSTRateToValueExchangeRate: convertPVSTRateToValueExchangeRate.bind(state),
  useMaskedValues: userMaskAllValuesSelector(state)
});

const mapDispatchToProps = {
  //   fetchDetailsHistory: fetchCustodianDetailsHistory,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  refreshCustodian: refreshCustodian
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianPVSTHistoryComponent);
