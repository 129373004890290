import ReactGA from "react-ga4";
import { ANALYTICS } from "analytics";

import requestIdleCallback from "./requestIdleCallback";
import { ApiClient } from "@kubera/common";

let appLoadTimeStart = 0;
let appLoadTimeEnd = 0;

const isValidEnv = () => {
  if (process.env.REACT_APP_ENV === "prod") {
    return true;
  }
  return false;
};

export const GAWrapperInit = () => {
  if (isValidEnv()) {
    ReactGA.initialize([
      {
        trackingId: process.env.REACT_APP_GA_TRACKING_ID
      }
    ]);
  }
};

export const GAWrapperEvent = param => {
  if (param && isValidEnv()) {
    ReactGA.event(param);
  }
};

export const GAWrapperAppLoadTime = (context, type) => {
  if (type === "start") {
    appLoadTimeStart = Date.now();
    console.log(`ALT[${context}-${type}] - ${appLoadTimeStart}`);
    return;
  }

  if (type === "ignore") {
    appLoadTimeStart = Date.now();
    appLoadTimeEnd = Date.now();
    console.log(`ALT[${context}-${type}] - ${appLoadTimeStart}/${appLoadTimeEnd}`);
    return;
  }

  if (type !== "end") {
    console.log(`ALT[${context}-${type}] - ignored, invalid type`);
    return;
  }

  if (appLoadTimeEnd) {
    console.log(`ALT[${context}-${type}] - ignored, repeated call`);
    return;
  }

  appLoadTimeEnd = Date.now();

  if (!appLoadTimeStart) {
    console.log(`ALT[${context}-${type}] - ignored, invalid appLoadTimeStart - ${appLoadTimeStart}`);
    return;
  }

  // report app load time
  const appLoadTime = Math.round(appLoadTimeEnd - appLoadTimeStart);
  const action = `${ANALYTICS.ACTION.APP_LOADED}`;
  console.log(`ALT[${context}-${type}] Added: ${action}, appLoadTime: ${appLoadTime}`);

  requestIdleCallback(() => {
    ApiClient.CapturePageLoad({
      metric: action,
      value: appLoadTime
    }).catch(apiError => {
      console.log("CapturePageLoad:", apiError);
    });
  });
};
