import React, { Fragment } from "react";
import i18n from "i18next";
import styled from "styled-components";

import { ReactComponent as OpenLink } from "assets/images/open_link.svg";

const Container = styled.div`
  width: 100%;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 345px;
  background: #000;
`;

const ImgBlk = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => `url(${props.nftImage})`};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-1px);
`;

const VideoBlk = styled.video`
  width: 100%;
`;

const DescContainer = styled.div`
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  padding: 40px;
`;

const NFTLink = styled.a`
  font-size: 14px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
`;

const OpenLinkComponent = styled(OpenLink)`
  margin-left: 4px;
`;

const NFTComponent = ({ detailsInfo = {}, children }) => {
  const extra = JSON.parse(detailsInfo.extra);
  const defaultImg = "https://cdn.kubera.com/r/clips/NFT.png";

  if (extra) {
    return (
      <Container>
        <ImgContainer>
          {/^video\//.test(extra.urlType) && extra.url ? (
            <VideoBlk autoPlay muted loop playsInline src={extra.url} />
          ) : (
            <ImgBlk nftImage={extra.url || defaultImg} />
          )}
        </ImgContainer>
        <DescContainer>
          {children}
          {extra.openseaUrl && (
            <Fragment>
              <NFTLink href={extra.openseaUrl} target="_blank" rel="noopener noreferrer">
                {i18n.t("nftComponent.openseaLink")}
              </NFTLink>
              <NFTLink href={extra.openseaUrl} target="_blank" rel="noopener noreferrer">
                <OpenLinkComponent />
              </NFTLink>
            </Fragment>
          )}
        </DescContainer>
      </Container>
    );
  }

  return null;
};

export default NFTComponent;
