import React from "react";
import styled from "styled-components";

const InputTextArea = styled.textarea`
  outline: 0;
  border: 0;
  resize: none;
  font-family: Inter, sans-serif;
  margin: 0;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const TextArea = ({ className, value, onChange = () => null, onMount = () => null, ...other }) => {
  const handleValueChange = e => {
    const value = e.target.value;
    onChange(value === null || value === undefined ? "" : value);
  };

  const setRef = ref => {
    if (ref) {
      ref.value = value;
      onMount(ref);
    }
  };

  return <InputTextArea {...other} ref={setRef} className={className} onChange={handleValueChange} data-1p-ignore />;
};

export default TextArea;
