import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  signOut,
  requestAccountDeletion,
  userSelector,
  userPriceOptionSelector,
  sharedPortfolioUsersSelector
} from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import AccountPaymentBlock from "./AccountPaymentBlock";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import sideMenuImg from "assets/images/sidemenu.png";
import topRightHeaderImg from "assets/images/topright.png";
import SwitchAccountComponent from "components/multiuser/SwitchAccountComponent";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  padding: 0 10px;
  box-sizing: border-box;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const Sidebar = styled.div`
  width: 252px;
  background: #ededed;
  min-height: 100vh;
  margin-left: -10px;
`;

const SidebarImg = styled.img`
  width: 100%;
`;

const TopRight = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  background: rgb(252, 252, 252);
`;

const TopRightImg = styled.img`
  height: 80px;
`;

const Overlay = styled(DialogOverlay)``;

const ContentContainer = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  align-items: center;
  background: #ffffff;
  border: 1px solid #000000;
  margin-top: 110px;
`;

const PaymentBlockContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-height: 581px;
  box-sizing: border-box;
`;

const FormBlk = styled.div`
  width: 500px;
  padding: 50px;
  box-sizing: border-box;
  min-width: 415px;
`;

const ImgBlk = styled.a`
  display: block;
  text-decoration: none;
  color: transparent;
  width: 500px;
  padding: 50px;
  padding-left: 0;
  box-sizing: border-box;
`;

const Img = styled.img`
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.015em;
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

const TrialForm = styled(AccountPaymentBlock)`
  margin-bottom: 39px;

  form > div {
    margin-bottom: -5px;
  }
`;

const FooterList = styled.ul`
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-wrap;
  padding-left: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 39px;
`;

const FooterListItem = styled.li`
  margin-bottom: 16px;
  list-style: none;
  font-feature-settings: "ss01" on;
`;

const FooterLinks = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: ${props => (!props.isLastItem ? "13px" : null)};
`;

const Link = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
`;

const DeleteConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 525px;
`;

const UserData = styled.div`
  font-size: 12px;
  line-height: 120%;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const UserName = styled(UserData)``;

const UserEmail = styled(UserData)`
  margin-bottom: 20px;
`;

const AccountInitialSubscription = props => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const sharedPortfolioUsers = useSelector(sharedPortfolioUsersSelector);
  const userPrice = useSelector(userPriceOptionSelector);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [hasUserRequestedAccountDeletion, setHasUserRequestedAccountDeletion] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSwitchDialog, setShowSwitchDialog] = useState(false);

  const deleteAccount = () => {
    setIsRequestPending(true);
    setErrorMessage(null);

    dispatch(
      requestAccountDeletion(
        () => {
          setIsRequestPending(false);
          setErrorMessage(null);
          setHasUserRequestedAccountDeletion(true);
        },
        error => {
          setIsRequestPending(false);
          setErrorMessage(error.errorMessage);
        }
      )
    );
  };

  const handleDialogPositiveButtonClick = e => {
    if (hasUserRequestedAccountDeletion === true) {
      signOut();
    } else {
      deleteAccount();
    }
  };

  const handleDialogNegativeButtonClick = e => {
    setShowConfirmationDialog(false);
  };

  const onDeleteClick = () => {
    setShowConfirmationDialog(true);
  };

  const onSignOut = () => {
    signOut();
  };

  let footerList2Values = {
    price: userPrice.monthly,
    familyPrice: userPrice.monthly_family,
    plan: "month"
  };

  if (!userPrice.monthly_family) {
    footerList2Values = {
      price: userPrice.yearly,
      familyPrice: userPrice.yearly_family,
      plan: "year"
    };
  }

  return (
    <Container>
      <Background>
        <Sidebar>
          <SidebarImg src={sideMenuImg} alt="Sidebar" />
        </Sidebar>
        <TopRight>
          <TopRightImg src={topRightHeaderImg} alt="Top Header" />
        </TopRight>
      </Background>
      <Overlay>
        <ContentContainer>
          <PaymentBlockContainer>
            <FormBlk>
              <Title>{i18n.t("initialSubscription.title")}</Title>
              <UserName>{user.name}</UserName>
              <UserEmail>{user.email}</UserEmail>
              <TrialForm isInitialSetup />
              <FooterList>
                <FooterListItem>{i18n.t("initialSubscription.footerListItem1")}</FooterListItem>
                <FooterListItem
                  dangerouslySetInnerHTML={{
                    __html: i18n
                      .t("initialSubscription.footerListItem2")
                      .replace(/\{price\}/, footerList2Values.price)
                      .replace(/\{familyPrice\}/, footerList2Values.familyPrice)
                      .replace(/\{plan\}/g, footerList2Values.plan)
                  }}
                />
              </FooterList>
              {sharedPortfolioUsers.length > 0 && (
                <FooterLinks>
                  <Link onClick={e => setShowSwitchDialog(true)}>{i18n.t("switchAccount")}</Link>
                </FooterLinks>
              )}
              <FooterLinks>
                <Link onClick={onSignOut}>{i18n.t("signOut")}</Link>
              </FooterLinks>
              <FooterLinks isLastItem>
                <Link onClick={onDeleteClick}>{i18n.t("accountSettings.deleteAccount")}</Link>
              </FooterLinks>
            </FormBlk>
            <ImgBlk href="https://www.reviews.io/company-reviews/store/kubera" target="_blank">
              <Img src="https://cdn.kubera.com/r/cc/cc-upfront-reviews.png" />
            </ImgBlk>
          </PaymentBlockContainer>
        </ContentContainer>
      </Overlay>
      {showConfirmationDialog === true && hasUserRequestedAccountDeletion === true && (
        <DeleteConfirmationDialog
          title={i18n.t("accountSettings.deleteAccount")}
          description={i18n.t("deleteAccountConfirmationDialogDescription")}
          positiveButtonTitle={i18n.t("signOut")}
          negativeButtonTitle={i18n.t("goBackLink")}
          isLoading={isRequestPending}
          errorMessage={errorMessage}
          handleNegativeButtonClick={handleDialogNegativeButtonClick}
          handlePositiveButtonClick={handleDialogPositiveButtonClick}
        />
      )}
      {showConfirmationDialog === true && hasUserRequestedAccountDeletion === false && (
        <DeleteConfirmationDialog
          title={i18n.t("initialSubscription.deleteAccount")}
          description={i18n.t("sure")}
          positiveButtonTitle={i18n.t("delete")}
          negativeButtonTitle={i18n.t("cancel")}
          isLoading={isRequestPending}
          errorMessage={errorMessage}
          handleNegativeButtonClick={handleDialogNegativeButtonClick}
          handlePositiveButtonClick={handleDialogPositiveButtonClick}
        />
      )}
      {showSwitchDialog === true && <SwitchAccountComponent onDismiss={() => setShowSwitchDialog(false)} />}
    </Container>
  );
};

export default AccountInitialSubscription;
