import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeSwitchComponent } from "theme";

import {
  currentThemeMode,
  kuberaNumberFormatSelector,
  kuberaNumberFormatList,
  updateUserPreferences,
  isAppInWhiteLabelMode
} from "@kubera/common";

import RadioButton from "components/inputs/RadioButton";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ThemeSelectHeader = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-bottom: 26px;
`;

const ThemeSelectTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
`;

const NumberFormatSelectionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 26px;
`;

const RadioOption = styled(RadioButton)`
  border: ${props => `1px solid ${props => props.theme.mobileBorderSeperatorColor}`};
  border-bottom: ${props => (!props.isLastItem ? 0 : null)};
  padding: 12px 0 13px;

  div {
    font-size: 16px;
    line-height: 150%;
  }

  path {
    fill: ${props => props.theme.mobileTxtColorOverlay};
  }
`;

const renderNumberFormatingSettings = (currentNumberFormat, dispatch) => {
  return kuberaNumberFormatList.map((numberFormat, index) => {
    return (
      <RadioOption
        label={numberFormat.label}
        value={numberFormat.locale}
        checked={
          !currentNumberFormat ? numberFormat.locale === "systemDefault" : currentNumberFormat === numberFormat.locale
        }
        onChange={selectedLocale => {
          dispatch(updateUserPreferences({ kuberaNumberFormat: selectedLocale }));
        }}
        key={index}
      />
    );
  });
};

const AccountGeneralSettingsComponent = ({ className = "" }) => {
  const themeMode = useSelector(currentThemeMode);
  const currentNumberFormat = useSelector(kuberaNumberFormatSelector);
  const dispatch = useDispatch();

  return (
    <Container className={className}>
      <ThemeSelectHeader>Appearance</ThemeSelectHeader>
      <ThemeSelectTitle>Dark Mode</ThemeSelectTitle>
      <ThemeSwitchComponent mode="default">
        <RadioOption label={i18n.t("off")} value="default" checked={themeMode === "default"} onChange={() => {}} />
      </ThemeSwitchComponent>
      <ThemeSwitchComponent mode="dark">
        <RadioOption label={i18n.t("on")} value="dark" checked={themeMode === "dark"} onChange={() => {}} />
      </ThemeSwitchComponent>
      <ThemeSwitchComponent mode="sts">
        <RadioOption
          label={i18n.t("mobileHeader.sts")}
          value="sts"
          checked={themeMode === "sts"}
          onChange={() => {}}
          isLastItem
        />
      </ThemeSwitchComponent>
      <ThemeSwitchComponent mode="sp">
        <RadioOption
          label={i18n.t("mobileHeader.sp")}
          value="sp"
          checked={themeMode === "sp"}
          onChange={() => {}}
          isLastItem
        />
      </ThemeSwitchComponent>

      {!isAppInWhiteLabelMode() && (
        <>
          <NumberFormatSelectionTitle>Number Format</NumberFormatSelectionTitle>
          {renderNumberFormatingSettings(currentNumberFormat, dispatch)}
        </>
      )}
    </Container>
  );
};

export default AccountGeneralSettingsComponent;
