import { getHashParams } from "@kubera/common";
import { hashParams } from "routes";

function getPrefixForDataCy() {
  var modalid = "";
  var params = getHashParams(window.location);

  if (!params[hashParams.MODAL] === false) {
    modalid = params[hashParams.MODAL];
  }

  const pathName = window.location.pathname.replace("/", "");
  return modalid + pathName;
}

export default getPrefixForDataCy;
