import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { getMonthFromDate, SUBSCRIPTION_PLANS } from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";

const AccountDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 80px;
  padding: 45px 50px;
`;

const ModalTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ModalMsg = styled.div`
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 130px;
  white-space: pre-wrap;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SubscriptionBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
  min-width: 169px;
`;

const Link = styled.a`
  color: #000;
`;

const AccountReconnectModal = ({
  isOpen = false,
  endDate = Date.now() / 1000,
  plan = SUBSCRIPTION_PLANS.TRIAL,
  onDismiss = () => null
}) => {
  const date = new Date(endDate * 1000);
  const expiryDate = `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`;

  const prevPlan =
    plan === SUBSCRIPTION_PLANS.TRIAL ? `${i18n.t("subscription.trial")}` : `${i18n.t("subscription.subscription")}`;

  if (isOpen) {
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <AccountDialog>
          <ModalTitle>{i18n.t("subscription.reconnectTitle")}</ModalTitle>
          <ModalMsg>
            {i18n
              .t("subscription.reconnectMsg")
              .replace("%plan%", prevPlan)
              .replace("%expiryDate%", expiryDate)}
            <Link href="mailto:hello@kubera.com">hello@kubera.com</Link>.
          </ModalMsg>
          <BtnBlock>
            <SubscriptionBtn title={i18n.t("subscription.iUnderstand")} onClick={onDismiss} />
          </BtnBlock>
        </AccountDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default AccountReconnectModal;
