import React from "react";
import styled from "styled-components";
import Checkbox from "components/inputs/Checkbox";

const CheckboxItemContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 16px;
  justify-content: space-between;
`;

const ResetToDefaultLink = styled.div`
  color: ${props => (!props.isChartsReset ? "#0074fc66" : "#0074FC")} 
  cursor: ${props => (props.isChartsReset ? "pointer" : "default")} ;
  text-decoration: underline;
  right: 0;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
`;

const AccountCheckbox = styled(Checkbox)``;

const SuggestedChartsTabCheckboxes = ({
  id,
  index,
  label,
  checked: isChecked,
  isChartsReseted,
  handleSuggestedChartsTabCheckBoxChange = () => null,
  handleResetToDefaultLink = () => null
}) => {
  const handleCheckboxClick = checkedFlag => {
    handleSuggestedChartsTabCheckBoxChange(checkedFlag, id);
  };

  return (
    <CheckboxItemContainer>
      <AccountCheckbox label={label} checked={isChecked} onChange={handleCheckboxClick} />
      {index === 0 && (
        <ResetToDefaultLink
          onClick={() => {
            isChartsReseted && handleResetToDefaultLink();
          }}
          isChartsReset={isChartsReseted}
        >
          {"Reset to default"}
        </ResetToDefaultLink>
      )}
    </CheckboxItemContainer>
  );
};
export default SuggestedChartsTabCheckboxes;
