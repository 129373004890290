import { setupNavigator } from "@kubera/common";
import { routes, baseRoutes } from "routes";

var HistoryRef;

export const configureNavigator = history => {
  HistoryRef = history;
  setupNavigator(Navigator, routes, baseRoutes);
};

class Navigator {
  static pushToRoute = route => {
    HistoryRef.push(route);
  };
}
