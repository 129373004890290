import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { useDispatch, useSelector } from "react-redux";

import { getClientListCharges, wlAdminListChargesSelector } from "@kubera/common";

import Spinner from "components/loader/Spinner";

import PaymentsListItem from "./PaymentsListItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000;
  margin-bottom: 3px;
`;

const ListContainer = styled.div`
  display: table;
  border-collapse: collapse;
  background: ${({ isLoading }) => (isLoading ? "transparent" : "#fff")};
`;

const Loader = styled(Spinner)`
  position: relative;
  right: 30px;
  width: 20px;
  height: 20px;
  margin-top: 1px;
  margin: 50px auto;
`;

const PaymentsList = ({
  userId,
  hideTitleOnLoad = false,
  handleAuthenticationRequired,
  handlePaymentError = () => null
}) => {
  const dispatch = useDispatch();

  const wlAdminListCharges = useSelector(wlAdminListChargesSelector);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialFetch = async () => {
      await dispatch(getClientListCharges(userId));
      setIsLoading(false);
    };
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && hideTitleOnLoad) {
    return <Loader />;
  }

  if (!isLoading && wlAdminListCharges.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>{i18n.t("payments")}</Title>
      {isLoading && <Loader />}
      {!isLoading && (
        <ListContainer>
          {wlAdminListCharges.map((charge, index) => (
            <PaymentsListItem
              key={charge.id}
              charge={charge}
              userId={userId}
              index={index}
              handleAuthenticationRequired={handleAuthenticationRequired}
              handlePaymentError={handlePaymentError}
            />
          ))}
        </ListContainer>
      )}
    </Container>
  );
};

export default PaymentsList;
