const staticMethods = {
  reopen: custodianId => {
    const tab = window.kuberaOpen("javascript:void(0)", custodianId); // eslint-disable-line
    tab.focus();
  },
  close: custodianId => {
    const tab = window.kuberaOpen("javascript:void(0)", custodianId); // eslint-disable-line
    tab.close();
  }
};

export default staticMethods;
