import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  connectivityCenterDataSelector,
  portfoliosSelector,
  unlinkAndManuallyTrackAllConnectedCustodians,
  getConnectivityCenterData,
  custodiansLinkedToSameAccount,
  portfolioSelector,
  store,
  getAggregatorName,
  currentPortfolioNameSelector,
  updateShowLoaderStatusForAConnection,
  accountLinkingService,
  custodianSelector,
  shouldShowLinkingErrorForCustodian
} from "@kubera/common";
import LinkAccountComponentExports from "components/link_account/LinkAccountComponentExports";
import { withRouter } from "@kubera/common";
import { useEffect } from "react";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ContextMenu from "components/contextmenu/ContextMenu";
import Loader from "components/loader/Loader";
import { hashParams, modalValues } from "routes";
import { accountSettingsTabs } from "components/account_settings/AccountSettingsComponentExports";

const ConnectivityCenterLoader = styled(Loader)`
  height: 20px;
`;
const ConnectionDetailsHeaderRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex: 1
  flex-direction: column;
  margin-bottom: -100px;
`;

const ConnectivityCenterForAPortfolio = styled.div`
  margin-bottom: 17px;
`;

const ConnectionStatusTilesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 10px;
`;
const ConnectionStatusTile = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height : 152px
width : 182px
box-sizing: border-box
background: ${props => props.color};
cursor: ${props => (props.isActionAvailableForConnection ? "pointer" : "auto")}
padding: 10px;
:hover {
  background: ${props => props.hoverColor}
}
`;

const ConnectionDetails = styled.div`
  display: flex;
  //flex: 1
  flex-direction: column;
  justify-content: space-between;
  //height: 132px;
`;
const ConnectionNameContainer = styled.div`
  display: flex;
`;

const ConnectionName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 110%;
  font-feature-settings: "ss01" on;
  color: #050505;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3
  display: -webkit-box;
-webkit-box-orient: vertical;
word-wrap: break-word;
`;

const ConnectionNumber = styled.div`
  font-style: normal;
  line-height: 110%;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 5px;
`;
const AggregatorName = styled.div`
  //margin-top: 38px;
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-feature-settings: "ss01" on, "calt" off;
`;
const ConnectionStatus = styled.div`
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-feature-settings: "ss01" on, "calt" off;
`;
const ConnectionScoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;
const ConnectionScore = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 110%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  font-feature-settings: "ss01" on, "calt" off;
`;
const ConnectionScoreTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;

const ConnectionScoreText = styled.div`
  //margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ConnectivityCenterTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 10px;
`;

const ConnectivityCenterDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: rgba(62, 62, 62, 0.8);
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  //padding-bottom: 100px;
`;

const KnowMore = styled.a`
  margin-left: 2px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;

const Disconnect = styled(ConnectionScoreText)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;
const Reconnect = styled(ConnectionScoreText)``;
const ReconnectionAggregatorName = styled(ConnectionScoreText)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;
const Highlighter = styled(ConnectionScoreText)`
  margin-left: 2px;
`;

const ReviveButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  font-feature-settings: "ss01" on, "calt" off;
`;

const PortfoliosTabs = styled(Tabs)`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const PortfoliosTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const PortfoliosTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const PortfoliosTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PortfolioName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 5px;
`;

const AssetsNameContextMenu = styled(ContextMenu)`
  padding: 15px 15px 0px 15px;
`;

const AssetMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
`;

const AssetName = styled.div`
  // font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
`;

const ConnectionTileHeader = styled.div`
  display: flex;
  flex: 1;
`;

const getConnectionScoreText = score => {
  if (score === 100) {
    return "Perfect Uptime score";
  }
  if (score >= 90 && score < 100) {
    return "Excellent Uptime score";
  }
  if (score >= 80 && score < 90) {
    return "Impressive Uptime score";
  }
  if (score >= 70 && score < 80) {
    return "Moderate Uptime score";
  }
  return "Poor Uptime score";
};

const BlankState = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ActionContainer = styled.div`
  display: flex;
`;
let isInitialMount = true;
const ConnectionStatusTilesComponent = props => {
  const dispatch = useDispatch();
  const connectivityCenterData = useSelector(connectivityCenterDataSelector);
  const filteredConnectivityCenterData =
    connectivityCenterData && connectivityCenterData.filter(connectionData => connectionData.connectivityCenter.length);
  const portfolios = useSelector(portfoliosSelector);
  const areMultiplePortfoliosPresent = portfolios?.length > 1;
  const shouldShowPortfolioName = portfolios?.length === 1;
  const currentPortfolioName = useSelector(currentPortfolioNameSelector);
  const shouldShowBlankState = filteredConnectivityCenterData && filteredConnectivityCenterData.length === 0;
  const [showUnlinkAllConnectionsDialog, setShowUnlinkAllConnectionsDialog] = useState(false);
  const [unlinkDialogCount, setUnlinkDialogCount] = useState(null);
  const [connection, setConnetion] = useState(null);
  const contextMenuRef = React.createRef();
  const [assetNames, setAssetNames] = useState(null);

  const getSelectedPortfolioId = () => {
    const storedPortfolioId = sessionStorage.getItem("selectedPortfolioIdForConnectivityCenter");
    if (props.selectedPortfolioId) {
      return props.selectedPortfolioId;
    } else if (storedPortfolioId) {
      return portfolios.findIndex(portfolio => portfolio.id === storedPortfolioId) !== -1 ? storedPortfolioId : null;
    }
    return null;
  };

  const selectedPortfolioId = getSelectedPortfolioId();
  const defaultTabIndex = selectedPortfolioId
    ? filteredConnectivityCenterData.findIndex(data => data.portfolioId === selectedPortfolioId) === -1
      ? 0
      : filteredConnectivityCenterData.findIndex(data => data.portfolioId === selectedPortfolioId)
    : 0;
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  useEffect(() => {
    if (isInitialMount) {
      dispatch(getConnectivityCenterData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnlinkAllConnectionsDialogPositiveButtonClick = () => {
    dispatch(updateShowLoaderStatusForAConnection(connection, selectedPortfolioId));
    dispatch(unlinkAndManuallyTrackAllConnectedCustodians(connection.custodianId, true));
    setShowUnlinkAllConnectionsDialog(false);
    isInitialMount = true;
  };

  const handleUnlinkAllConnectionsDialogOnDismiss = () => {
    setConnetion(null);
    setShowUnlinkAllConnectionsDialog(false);
  };

  const setTab = tabIndex => {
    sessionStorage.setItem(
      "selectedPortfolioIdForConnectivityCenter",
      filteredConnectivityCenterData[tabIndex].portfolioId
    );
    setTabIndex(tabIndex);
    const connectivityTabHash = `${hashParams.MODAL}=${modalValues.ACCOUNT_SETTINGS}&${hashParams.TAB}=${accountSettingsTabs.CONNECTION_STATUS}`;
    props.history.push({ ...props.location, hash: connectivityTabHash });
  };

  const showConnectionTilesActionItem = connection => {
    const canConnectionBeReconnected = !!connection.reconnectInfo;
    const canConnectionBeDisconnected = connection.disconnect;
    if (connection.status === 1 && !canConnectionBeReconnected && !canConnectionBeDisconnected) {
      return (
        <ConnectionScoreTextContainer>
          <ConnectionScoreText>{getConnectionScoreText(connection.uptimeScore)}</ConnectionScoreText>
        </ConnectionScoreTextContainer>
      );
    } else if (canConnectionBeReconnected) {
      const reconnectInfo = connection.reconnectInfo;
      return (
        <ConnectionScoreTextContainer>
          <Reconnect>{"Reconnect Via"}</Reconnect>
          <ActionContainer>
            <ReconnectionAggregatorName>
              {reconnectInfo.aggregator === "finicity" ? "mastercard" : reconnectInfo.aggregator}
            </ReconnectionAggregatorName>
            <Highlighter>*</Highlighter>
          </ActionContainer>
        </ConnectionScoreTextContainer>
      );
    } else if (canConnectionBeDisconnected) {
      return (
        <ConnectionScoreTextContainer>
          <ConnectionScoreText>{getConnectionScoreText(connection.uptimeScore)}</ConnectionScoreText>
          <ActionContainer>
            <Disconnect>Track Manually</Disconnect>
            <Highlighter>*</Highlighter>
          </ActionContainer>
        </ConnectionScoreTextContainer>
      );
    } else {
      return (
        <ConnectionScoreTextContainer>
          <ConnectionScoreText>{getConnectionScoreText(connection.uptimeScore)}</ConnectionScoreText>
        </ConnectionScoreTextContainer>
      );
    }
  };

  const onConnectionTileClick = (connection, is2FAError, shouldShowAsErrorConnection) => {
    isInitialMount = false;
    const canConnectionBeReconnected = !!connection.reconnectInfo;
    const canConnectionBeDisconnected = connection.disconnect;
    if (
      connection.status === 1 &&
      shouldShowAsErrorConnection &&
      !is2FAError &&
      (!connection.disconnect && !connection.reconnectInfo)
    ) {
      // revive action
      dispatch(updateShowLoaderStatusForAConnection(connection, selectedPortfolioId));
      LinkAccountComponentExports.resolveLinkError(props.history, props.location, connection.custodianId, true);
    } else if (canConnectionBeReconnected) {
      // reconnect action
      dispatch(updateShowLoaderStatusForAConnection(connection, selectedPortfolioId));
      LinkAccountComponentExports.reconnect(props.history, props.location, connection.custodianId, true);
    } else if (canConnectionBeDisconnected) {
      // disconnect action
      setConnetion(connection);
      setShowUnlinkAllConnectionsDialog(true);
      setUnlinkDialogCount(custodiansLinkedToSameAccount(connection.custodianId, false, true).custodians.length);
    }
  };

  const onHoverConnectionNumber = (event, assetNames) => {
    if (contextMenuRef.current.isVisible() === true) {
      contextMenuRef.current.dismiss();
      return;
    }
    setAssetNames(assetNames);
    const targetPosition = event.target.getBoundingClientRect();
    contextMenuRef.current.show([], targetPosition.left, targetPosition.top + 20, true, event.target);
  };

  const onMouseOutConnectionNumber = () => {
    if (contextMenuRef.current.isVisible() === true) {
      contextMenuRef.current.dismiss();
      setAssetNames(null);
      return;
    }
  };

  const connectivityCenterForAPortfolio = connectivityCenterData => {
    return (
      <ConnectionStatusTilesContainer>
        {connectivityCenterData.map((connection, index) => {
          const custodianData = custodianSelector(store.getState(), connection.custodianId);
          const shouldShowAsErrorConnection = shouldShowLinkingErrorForCustodian(custodianData);
          const is2FAError = ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(
            connection.statusInfo
          );
          const tileBackgroundColor =
            connection.status === 1
              ? ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(
                  connection.statusInfo
                )
                ? "#ACD99C"
                : shouldShowAsErrorConnection
                ? "#DA6565"
                : "#79D762"
              : "#79D762";
          const tileHoverBackgroundColor =
            connection.status === 1
              ? ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(
                  connection.statusInfo
                )
                ? "#9BC38C"
                : shouldShowAsErrorConnection
                ? "#C45B5B"
                : "#6DC158"
              : "#6DC158";
          const multipleEntriesPresentForSameProvider = connectivityCenterData.filter(
            data =>
              data.providerName === connection.providerName && connection.linkType !== accountLinkingService.ZERION
          );
          const aggregatorName =
            connection.aggregator === "crypto_apikey" ||
            connection.aggregator === "zerion" ||
            connection.aggregator === "crypto_oauth"
              ? "Kubera"
              : getAggregatorName(connection.linkType);
          const connectionProviderName = () => {
            if (multipleEntriesPresentForSameProvider.length > 1) {
              const connectionNumber =
                multipleEntriesPresentForSameProvider.findIndex(
                  data => data.providerAccountId === connection.providerAccountId
                ) + 1;
              return (
                <ConnectionNameContainer>
                  <ConnectionName>{connection.providerName}</ConnectionName>
                  <ConnectionNumber
                    onMouseEnter={e => onHoverConnectionNumber(e, connection.assetNames)}
                    onMouseOut={onMouseOutConnectionNumber}
                  >
                    {connectionNumber < 9 ? `0${connectionNumber}` : connectionNumber}
                  </ConnectionNumber>
                </ConnectionNameContainer>
              );
            } else {
              const connectionProviderName =
                connection.linkType === accountLinkingService.ZERION
                  ? `${connection.providerName} xxxx${connection.providerAccountId.slice(-4)}`
                  : connection.providerName;
              return <ConnectionName>{connectionProviderName}</ConnectionName>;
            }
          };
          const connectionStatusInfo = () => {
            if (connection.status === 1) {
              return is2FAError
                ? "requires 2fa code to sync"
                : shouldShowAsErrorConnection
                ? "Out of Sync"
                : "Sync Scheduled";
            } else {
              return "Sync Scheduled";
            }
          };
          const isActionAvailableForConnection =
            (connection.status === 1 && shouldShowAsErrorConnection && !is2FAError) ||
            connection.disconnect ||
            connection.reconnectInfo;
          return (
            <ConnectionStatusTile
              color={tileBackgroundColor}
              key={index}
              isActionAvailableForConnection={isActionAvailableForConnection}
              onClick={() => onConnectionTileClick(connection, is2FAError, shouldShowAsErrorConnection)}
              hoverColor={tileHoverBackgroundColor}
            >
              <ConnectionDetailsHeaderRow>
                <ConnectionTileHeader>{connectionProviderName()}</ConnectionTileHeader>
                {connection.showLoader && <ConnectivityCenterLoader size={14} thickness={2} />}
              </ConnectionDetailsHeaderRow>
              <ConnectionDetails>
                <AggregatorName>{aggregatorName}</AggregatorName>
                <ConnectionStatus>{connectionStatusInfo()}</ConnectionStatus>
                {connection.status === 1 &&
                  !is2FAError &&
                  (!connection.disconnect && !connection.reconnectInfo) &&
                  shouldShowAsErrorConnection && <ReviveButton>Sync Now</ReviveButton>}
                <ConnectionScoreContainer>
                  <ConnectionScore>{connection.uptimeScore}%</ConnectionScore>
                  {showConnectionTilesActionItem(connection)}
                </ConnectionScoreContainer>
              </ConnectionDetails>
            </ConnectionStatusTile>
          );
        })}
        <AssetsNameContextMenu dataPrivate ref={contextMenuRef}>
          {assetNames &&
            assetNames.map((assetName, index) => {
              return (
                <AssetMenuItem key={index}>
                  <AssetName>{assetName}</AssetName>
                </AssetMenuItem>
              );
            })}
        </AssetsNameContextMenu>
      </ConnectionStatusTilesContainer>
    );
  };

  return (
    <Container>
      <ConnectivityCenterTitle>{"Connectivity Center"}</ConnectivityCenterTitle>
      {shouldShowBlankState ? (
        <BlankState>
          {i18n.t("connectivityCenter.blankState")}{" "}
          <KnowMore
            target={"_blank"}
            href={"https://help.kubera.com/article/102-why-do-my-bank-and-brokerage-connections-keep-dropping"}
          >
            {i18n.t("knowMore")}
          </KnowMore>
        </BlankState>
      ) : (
        <>
          {areMultiplePortfoliosPresent ? (
            <PortfoliosTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={tabIndex}
              onSelect={setTab}
            >
              <PortfoliosTabList>
                {connectivityCenterData &&
                  connectivityCenterData
                    .filter(connectionData => connectionData.connectivityCenter.length)
                    .map((connectionData, index) => {
                      const portfolioId = connectionData.portfolioId;
                      const portfolioDetails = portfolioSelector(store.getState(), portfolioId);
                      const portfolioName = portfolioDetails && portfolioDetails.name;
                      return <PortfoliosTab key={index}>{portfolioName}</PortfoliosTab>;
                    })}
              </PortfoliosTabList>
              <ConnectivityCenterForAPortfolio>
                {connectivityCenterData &&
                  connectivityCenterData
                    .filter(connectionData => connectionData.connectivityCenter.length)
                    .map((connectionData, index) => {
                      return (
                        <PortfoliosTabPanel key={`portfoliosTabPanel${index}`}>
                          {connectivityCenterForAPortfolio(connectionData.connectivityCenter)}
                        </PortfoliosTabPanel>
                      );
                    })}
              </ConnectivityCenterForAPortfolio>
            </PortfoliosTabs>
          ) : (
            <>
              {shouldShowPortfolioName && <PortfolioName>{currentPortfolioName}</PortfolioName>}
              <ConnectivityCenterForAPortfolio>
                {connectivityCenterData &&
                  connectivityCenterData
                    .filter(connectionData => connectionData.connectivityCenter.length)
                    .map((connectionData, index) => {
                      return (
                        <React.Fragment key={index}>
                          {connectivityCenterForAPortfolio(connectionData.connectivityCenter)}
                        </React.Fragment>
                      );
                    })}
              </ConnectivityCenterForAPortfolio>
            </>
          )}
          <ConnectivityCenterDescription>
            {i18n.t("connectivityCenter.description")}{" "}
            <KnowMore
              target={"_blank"}
              href={"https://help.kubera.com/article/102-why-do-my-bank-and-brokerage-connections-keep-dropping"}
            >
              {i18n.t("knowMore")}
            </KnowMore>
          </ConnectivityCenterDescription>
          {showUnlinkAllConnectionsDialog === true && (
            <ConfirmationDialog
              canUserDismiss={true}
              title={i18n.t("disconnectAndTrackManuallyDialog.title")}
              description={i18n.t("disconnectAndTrackManuallyDialog.description")}
              positiveButtonTitle={
                unlinkDialogCount > 1
                  ? i18n.t("disconnectAndTrackManuallyDialog.primaryButton") + ` ${unlinkDialogCount} rows`
                  : i18n.t("disconnectAndTrackManuallyDialog.primaryButton")
              }
              negativeButtonTitle={i18n.t("cancel")}
              onDismiss={handleUnlinkAllConnectionsDialogOnDismiss}
              handlePositiveButtonClick={handleUnlinkAllConnectionsDialogPositiveButtonClick}
              handleNegativeButtonClick={handleUnlinkAllConnectionsDialogOnDismiss}
            />
          )}
        </>
      )}
    </Container>
  );
};
export default withRouter(ConnectionStatusTilesComponent);
