import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "assets/css/react-phone-input-2.css";

class PhoneInputComponent extends React.Component {
  componentDidMount() {}

  componentDidUpdate(oldProps) {}

  render() {
    const inputStyle = {
      width: "312px",
      height: "43px",
      border: "none",
      fontSize: "13px",
      lineHeight: "normal",
      paddingLeft: "73px",
      boxSizing: "border-box",
      backgroundColor: this.props.disabled ? "rgba(0, 0, 0, 0.05)" : null
    };
    const containerStyle = {
      width: "314px",
      height: "45px",
      boxSizing: "border-box",
      display: "table-cell",
      border: "solid 1px rgba(0, 0, 0, 0.4)"
    };

    const buttonStyle = {
      border: "none",
      borderRight: "solid 1px rgba(0, 0, 0, 0.4)",
      backgroundColor: "#F3F3F3",
      paddingLeft: "7px",
      paddingRight: "7px"
    };

    if (this.props.fontSize !== undefined) {
      inputStyle.fontSize = this.props.fontSize;
    }

    if (this.props.width !== undefined) {
      containerStyle.width = this.props.width;
    }

    if (this.props.height !== undefined) {
      containerStyle.height = this.props.height;
    }

    if (this.props.border !== undefined) {
      containerStyle.border = this.props.border;
    }

    let countryCode = "us";
    if (this.props.countryCode !== undefined) {
      countryCode = this.props.countryCode;
    }

    return (
      <PhoneInput
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
        containerStyle={containerStyle}
        country={countryCode}
        enableAreaCodes={true}
        value={this.props.phone}
        onChange={this.props.handlePhoneChange}
        onKeyDown={this.props.handleKeyDown}
        inputProps={{ autoFocus: this.props.autofocus, disabled: this.props.disabled }}
      />
    );
  }
}

export default PhoneInputComponent;
