import React from "react";
import styled from "styled-components";
import i18n from "i18next";

const Container = styled.li`
  padding: 21px 13px;
  cursor: pointer;
  pointer-events: none;
  box-sizing: border-box;
  background: #ffffff;
`;

const NothingFound = styled.div`
  margin-bottom: 23px;
`;

const ContactBlockHeader = styled.div`
  font-weight: bold;
  line-height: 130%;
  margin-bottom: 2px;
`;

const ContactBlockBody = styled.div`
  font-size: 12px;
  line-height: 130%;
`;

const NothingFoundComponent = React.forwardRef((props, ref) => {
  return (
    <Container ref={ref}>
      <NothingFound>{i18n.t("linkAccount.emptyProviderResults")}</NothingFound>
      <ContactBlockHeader>{i18n.t("nothingFound.header")}</ContactBlockHeader>
      <ContactBlockBody>{i18n.t("nothingFound.body")}</ContactBlockBody>
    </Container>
  );
});

export default NothingFoundComponent;
