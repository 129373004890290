import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import TextInput from "components/inputs/TextInput";
import {
  getMonthFromDate,
  guessDateFromUserInput,
  userPreferencesSelector,
  updateUserPreferences
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import Checkbox from "components/inputs/Checkbox";

const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  margin-top: 30px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  margin-top: 26px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;

const TextInputField = styled(TextInput)`
  height: 45px;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  outline: 0;
  padding: 10px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const ErrorMessage = styled.div`
  visibility: ${props => (!props.errorMessage === true ? "hidden" : "visible")};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
`;

const ChartCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
`;

const ChartCheckbox = styled(Checkbox)``;

class NetWorthChartDatePreferencesTabComponent extends React.Component {
  constructor(props) {
    super(props);

    const pid = this.props.portfolioId;
    this.state = {
      inputText: this.getDateString(this.props.date),
      errorMessage: null,
      showNetworthChart: this.props.userPreferences[`${pid}-showNetworthChart`],
      showInvestableAssetsChart: this.props.userPreferences[`${pid}-showInvestableAssetsChart`]
    };

    this.handleInputchange = this.handleInputchange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  handleInputchange = e => {
    this.setState({ ...this.state, inputText: e.target.value });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveButtonClick();
    }
  };

  handleSaveButtonClick = () => {
    const pid = this.props.portfolioId;
    if (
      this.props.userPreferences[`${pid}-showNetworthChart`] !== this.state.showNetworthChart ||
      this.props.userPreferences[`${pid}-showInvestableAssetsChart`] !== this.state.showInvestableAssetsChart
    ) {
      const nwKey = `${pid}-showNetworthChart`;
      const iaKey = `${pid}-showInvestableAssetsChart`;
      const updatedPref = {};
      updatedPref[nwKey] = this.state.showNetworthChart;
      updatedPref[iaKey] = this.state.showInvestableAssetsChart;
      this.props.updateUserPreferences(updatedPref);
    }

    if (!this.state.inputText === true || this.state.inputText.trim().length === 0) {
      this.setState({ ...this.state, errorMessage: i18n.t("emptyInput") });
      return;
    }
    const inputText = this.state.inputText.trim();
    const inputDate = guessDateFromUserInput(inputText);

    if (!inputDate === true || inputDate.getTime() >= new Date().getTime()) {
      this.setState({
        errorMessage: i18n.t("chartStartDatePreferenceDialog.invalidDate"),
        inputText: this.getDateString(this.props.date)
      });
      return;
    }

    if (this.props.onChange) {
      if (this.getDateString(inputDate) !== this.getDateString(this.props.date)) {
        this.props.onChange(inputDate);
      }
    }
  };

  componentWillUnmount() {
    this.handleSaveButtonClick();
  }

  getDateString(date) {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`;
  }

  render() {
    const inputText = this.state.inputText;
    const title = this.props.title;
    const description = this.props.description;
    const errorMessage = this.state.errorMessage;

    const showNetworthChart = this.state.showNetworthChart === undefined ? true : this.state.showNetworthChart;
    const showInvestableAssetsChart =
      this.state.showInvestableAssetsChart === undefined ? true : this.state.showInvestableAssetsChart;

    return (
      <Container>
        {this.props.showHideChartOption && (
          <ChartCheckboxContainer>
            <ChartCheckbox
              label="Net Worth"
              disabled={!showInvestableAssetsChart}
              checked={showNetworthChart}
              onChange={(checked, e) => {
                if (checked || (!checked && showInvestableAssetsChart)) {
                  this.setState({ ...this.state, showNetworthChart: checked });
                }
              }}
            />
          </ChartCheckboxContainer>
        )}
        {this.props.showHideChartOption && (
          <ChartCheckboxContainer>
            <ChartCheckbox
              label="Investable Asset"
              disabled={!showNetworthChart}
              checked={showInvestableAssetsChart}
              onChange={(checked, e) => {
                if (checked || (!checked && showNetworthChart)) {
                  this.setState({ ...this.state, showInvestableAssetsChart: checked });
                }
              }}
            />
          </ChartCheckboxContainer>
        )}
        <Title>{title}</Title>
        <Description>{description}</Description>
        <TextInputField
          placeholder={i18n.t("chartStartDatePreferenceDialog.placeholder")}
          value={inputText}
          onChange={this.handleInputchange}
          onKeyDown={this.handleKeyDown}
          autoFocus
        />

        <ErrorMessage errorMessage={errorMessage}>{!errorMessage === true ? "&nsbp" : errorMessage}</ErrorMessage>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetWorthChartDatePreferencesTabComponent));
