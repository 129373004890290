import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";

import {
  anniversaryCountSelector,
  updateUserPreferences,
  accountCreationTsSelector,
  getMonthFromDate,
  isMobile,
  isAppInWhiteLabelMode,
  isAnniversaryDateSelector
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import partypopper from "assets/images/partypopper.png";

import { DialogOverlay, Dialog } from "./DialogOverlay";

const isMobileDevice = isMobile();
const isWhiteLabelledApp = isAppInWhiteLabelMode();

const AnniversaryDialog = isMobileDevice
  ? styled(Dialog)`
      max-width: 400px;
      padding: 50px;
      box-sizing: border-box;
      height: 100%;
      border: 0;
      color: #000000;
    `
  : styled(Dialog)`
      max-width: 400px;
      padding: 66px 62px 74px;
      box-sizing: border-box;
      margin-top: 69px;
      color: #000000;
    `;

const PartyPopperImg = isMobileDevice
  ? styled.img`
      width: 52px;
      height: 52px;
    `
  : styled.img`
      width: 60px;
      height: 60px;
    `;

const Title = isMobileDevice
  ? styled.div`
      font-weight: 700;
      font-size: 38px;
      line-height: 49.4px;
      letter-spacing: -0.05em;
      font-feature-settings: "ss01" on;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `
  : styled.div`
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -0.05em;
      font-feature-settings: "pnum" on, "lnum" on;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `;

const AnniversaryTitle = isMobileDevice
  ? styled(Title)`
      margin-bottom: 22px;
    `
  : styled(Title)`
      margin-bottom: 20px;
    `;

const BeenWithKuberaTxt = isMobileDevice
  ? styled.div`
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      letter-spacing: -0.04em;
      font-feature-settings: "pnum" on, "lnum" on;
      margin-bottom: 6px;
      white-space: pre-wrap;
    `
  : styled.div`
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.04em;
      font-feature-settings: "pnum" on, "lnum" on;
      margin-bottom: 7px;
      white-space: pre-wrap;
    `;

const YearsTxt = isMobileDevice
  ? styled.div`
      font-weight: 700;
      font-size: 60px;
      line-height: 100%;
      letter-spacing: -0.05em;
      font-feature-settings: "pnum" on, "lnum" on, "cv01" on;
      margin-bottom: 80px;
    `
  : styled.div`
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
      letter-spacing: -0.05em;
      font-feature-settings: "pnum" on, "lnum" on, "cv01" on;
      margin-bottom: 104px;
    `;

const SinceTxt = isMobileDevice
  ? styled.div`
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.04em;
      font-feature-settings: "pnum" on, "lnum" on;
      margin-bottom: 15px;
    `
  : styled.div`
      font-weight: 400;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: -0.04em;
      font-feature-settings: "pnum" on, "lnum" on;
      margin-bottom: 28px;
    `;

const TweetBtn = isMobileDevice
  ? styled(PrimaryButton)`
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
      font-feature-settings: "ss01" on;
      max-width: 212px;
      width: 100%;
    `
  : styled(PrimaryButton)`
      font-weight: 700;
      font-size: 12px;
      line-height: 110%;
      font-feature-settings: "ss01" on;
      max-width: 168px;
      width: 100%;
    `;

const MobileClose = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 40px;
`;

const Anniversary = () => {
  const dispatch = useDispatch();

  const accountCreationTs = useSelector(accountCreationTsSelector);
  const anniversaryCount = useSelector(anniversaryCountSelector);
  const isAnniversaryDate = useSelector(isAnniversaryDateSelector);

  const sinceTxtStr = useMemo(() => {
    const date = new Date(accountCreationTs * 1000);
    return `${getMonthFromDate(date)} ${date.getFullYear()}`;
  }, [accountCreationTs]);

  const updateAnniversaryPreference = () => {
    dispatch(
      updateUserPreferences({
        prevAnniversary: new Date().getFullYear()
      })
    );
  };

  const handleClose = () => {
    updateAnniversaryPreference();
  };

  const handleTweetBtnClick = () => {
    updateAnniversaryPreference();
    window.kuberaOpen(
      `https://twitter.com/intent/tweet?text=Check+out+Kubera.com+-+a+simple+%26+modern+tool+to+track+your+assets+-+digital+%28crypto%29+and+traditional+%28stocks%2C+bonds%2C+real+estate%29.+Connects+to+international+bank+%26+brokerage+accounts.+Supports+all+major+currencies.+%23iamKubera+since+${sinceTxtStr}+%40KuberaApp`,
      "_blank"
    );
  };

  if (!isWhiteLabelledApp && isAnniversaryDate && anniversaryCount > 0) {
    const yearORYearsTxt = anniversaryCount > 1 ? "anniversary.yearsTxt" : "anniversary.yearTxt";

    return (
      <DialogOverlay onDismiss={handleClose}>
        <AnniversaryDialog>
          <Title>
            {i18n.t("anniversary.title1")}&nbsp;
            <PartyPopperImg src={partypopper} />
            <br />
          </Title>
          <AnniversaryTitle>{i18n.t("anniversary.title2")}</AnniversaryTitle>
          <BeenWithKuberaTxt>{i18n.t("anniversary.beenWithKuberaTxt")}</BeenWithKuberaTxt>
          <YearsTxt>{`${anniversaryCount} ${i18n.t(yearORYearsTxt)}`}</YearsTxt>
          <SinceTxt>{i18n.t("anniversary.userSince").replace("%s%", sinceTxtStr)}</SinceTxt>
          <TweetBtn
            title={i18n.t("anniversary.modalBtn")}
            data-cy="anniversaryTweetButton"
            onClick={handleTweetBtnClick}
          />
          {isMobileDevice && <MobileClose onClick={handleClose}>{i18n.t("anniversary.mobileClose")}</MobileClose>}
        </AnniversaryDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default Anniversary;
