import React from "react";
import styled from "styled-components";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { formatNumberWithKuberaNumberFormatSettings, shortFormatNumberWithCurrency } from "@kubera/common";
import BadgeTip from "components/tooltip/BadgeTip";
import requestIdleCallback from "utilities/requestIdleCallback";
import cancelIdleCallback from "utilities/cancelIdleCallback";
import * as d3 from "d3";

export const doughnutColors = ["#503795", "#B085FF", "#855CF8", "#CC60FF", "#C6B1FF"];

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${props => (props.hasBackgroundColor ? props.theme.netWorthContainerBG : "none")};
  border: ${props => (props.hasBorder ? props.theme.netWorthContainerBR : "none")};
  box-sizing: border-box;
  padding: 20px;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  polyline {
    stroke: ${props => (props.hasBackgroundColor ? props.theme.polylineStroke : props.theme.reportDoughnutPolyLine)};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;

const TitleContainer = styled.div`
display: flex
flex-direction : column`;

const ActionButton = styled.button`
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const DoughnutChartOptionButton = styled(ActionButton)`
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
  margin-right: -12px;
`;

const Title = styled.div`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  display: flex;
`;

const ValueSubText = styled.div`
  margin-left: 4px;
  padding-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${props => (!props.padding === false ? props.padding : "")};
  transform: translate3d(0, 0, 0);
  contain: size;

  & > svg {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
`;

const RecommendationBadge = styled(BadgeTip)`
  display: flex
  align-items: center
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-right : 18px
`;

const OptionsContainer = styled.div`
  display: flex;
`;

class DoughnutChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.contextMenuRef = React.createRef();
    this.idleCallbackRef = React.createRef();
  }
  componentDidMount() {
    requestIdleCallback(() => {
      this.drawChart();
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.chartData !== this.props.chartData || oldProps.useMaskedValues !== this.props.useMaskedValues) {
      cancelIdleCallback(this.idleCallbackRef);
      this.idleCallbackRef = requestIdleCallback(() => {
        this.drawChart();
      });
    }
  }

  drawChart() {
    const minimumPercentageToShow = 10;
    const chartData = this.getChartData();
    const shouldHideSmallSlices = chartData.length > 2;
    const containerWidth = this.props.containerWidth || this.props.chartWidth;
    const container = document.getElementById(this.props.id);
    if (!container) {
      return;
    }
    if (!d3) {
      return;
    }

    const previous = container.querySelector("svg");
    if (previous) {
      previous.remove();
    }

    let chartHoverTipDiv = d3.select("#tooltip-donut");
    if (!d3.select("#tooltip-donut").node()) {
      chartHoverTipDiv = d3
        .select("body")
        .append("div")
        .attr("id", "tooltip-donut")
        .style("opacity", 0)
        .style("display", "none");
    }

    var svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .append("g");
    svg.append("g").attr("class", "slices");
    svg.append("g").attr("class", "labels");
    svg.append("g").attr("class", "sub-labels");
    svg.append("g").attr("class", "lines");

    var width = 0.4 * this.props.chartWidth,
      height = 0.4 * this.props.chartWidth,
      radius = Math.min(width, height) / 2;

    var pie = d3
      .pie()
      .sort(null)
      .value(function(d) {
        return d.value;
      });

    var arc = d3
      .arc()
      .outerRadius(radius * 1)
      .innerRadius(radius * 0.6);

    var outerArc = d3
      .arc()
      .innerRadius(radius * 0.8)
      .outerRadius(radius * 0.9);

    svg.attr("transform", "translate(" + (width + 30) + "," + (height / 2 + 5) + ")");

    var key = function(d) {
      return d.data.index;
    };

    var color = d3.scaleOrdinal().range(doughnutColors);

    const midAngle = function(d) {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    };

    const change = data => {
      /* ------- PIE SLICES -------*/
      var slice = svg
        .select(".slices")
        .selectAll("path.slice")
        .data(pie(data), key);

      slice
        .enter()
        .append("path")
        .style("fill", function(d) {
          return color(d.data.index);
        })
        .attr("class", "slice")
        .merge(slice)
        .transition()
        .duration(0)
        .attrTween("d", function(d) {
          var interpolate = d3.interpolate(this._current, d);
          this._current = interpolate(0);
          return function(t) {
            return arc(interpolate(t));
          };
        });

      slice.exit().remove();

      /* ------- TEXT LABELS -------*/

      var text = svg
        .select(".labels")
        .selectAll("text")
        .data(pie(data), key);

      text
        .enter()
        .append("text")
        .attr("style", `font-size: 12px; font-feature-settings: "ss01", "calt" 0`)
        .attr("dy", ".35em")
        .attr("cursor", "default")
        .merge(text)
        .on("mouseover", function(event, d) {
          chartHoverTipDiv
            .transition()
            .duration(0)
            .style("opacity", 1)
            .style("display", "block");

          let num = d.data.fullLabel;
          chartHoverTipDiv
            .html(num)
            .style("z-index", 10000)
            .style("left", event.target.getBoundingClientRect().left + "px")
            .style("top", event.target.getBoundingClientRect().top + 25 + "px");
        })
        .on("mouseout", function(d, i) {
          setTimeout(() => {
            chartHoverTipDiv
              .transition()
              .duration(50)
              .style("opacity", 0)
              .style("display", "none");
          }, 100);
        })
        .transition()
        .duration(0)
        .attr("transform", function(d) {
          const offsetSlicePosition = d.data.offsetSlicePosition;
          var position = arc.centroid(d);
          position[0] = (containerWidth / 2 - 20) * (midAngle(d) < Math.PI ? 1 : -1);
          position[1] = position[1] + offsetSlicePosition;
          if (position[1] < -radius) {
            if (d.data.index === data.length - 1) {
              position[1] = position[1] - offsetSlicePosition - 5;
            } else {
              position[1] = position[1] - 20;
            }
            position[0] = -1 * position[0];
          }
          position[1] = position[1] - 9;
          return "translate(" + position + ")";
        })
        .style("text-anchor", function(d) {
          var position = arc.centroid(d);
          position[1] = position[1] + d.data.offsetSlicePosition;
          if (position[1] < -radius) {
            return "end";
          }
          return midAngle(d) < Math.PI ? "end" : "start";
        })
        .text(function(d) {
          if (shouldHideSmallSlices && d.data.percent < minimumPercentageToShow) {
            return "";
          }
          return d.data.text + "";
        });

      text.exit().remove();

      /* ------- SUB-TEXT LABELS -------*/

      var subText = svg
        .select(".sub-labels")
        .selectAll("text")
        .data(pie(data), key);

      subText
        .enter()
        .append("text")
        .attr("style", `font-size: 12px; font-feature-settings: "ss01", "calt" 0`)
        .attr("dy", ".35em")
        .merge(subText)
        .transition()
        .duration(0)
        .attr("transform", function(d) {
          const offsetSlicePosition = d.data.offsetSlicePosition;
          var position = arc.centroid(d);
          position[0] = (containerWidth / 2 - 20) * (midAngle(d) < Math.PI ? 1 : -1);
          position[1] = position[1] + offsetSlicePosition;
          if (position[1] < -radius) {
            if (d.data.index === data.length - 1) {
              position[1] = position[1] - offsetSlicePosition - 5;
            } else {
              position[1] = position[1] - 20;
            }
            position[0] = -1 * position[0];
          }
          position[1] = position[1] + 9;
          return "translate(" + position + ")";
        })
        .style("text-anchor", function(d) {
          var position = arc.centroid(d);
          position[1] = position[1] + d.data.offsetSlicePosition;
          if (position[1] < -radius) {
            return "end";
          }
          return midAngle(d) < Math.PI ? "end" : "start";
        })
        .text(function(d) {
          if (shouldHideSmallSlices && d.data.percent < minimumPercentageToShow) {
            return "";
          }
          return d.data.subText;
        });

      subText.exit().remove();

      /* ------- LINE BETWEEN TEXT AND SUBTEXT -------*/

      var lines = svg
        .select(".lines")
        .selectAll("polyline")
        .data(pie(data), key);

      lines
        .enter()
        .append("polyline")
        .attr("class", "line")
        .merge(lines)
        .transition()
        .duration(0)
        .attr("points", function(d) {
          if (shouldHideSmallSlices && d.data.percent < minimumPercentageToShow) {
            return [];
          }
          const offsetSlicePosition = d.data.offsetSlicePosition;
          var startPosition = arc.centroid(d);
          startPosition[1] = startPosition[1] + offsetSlicePosition;
          var endPosition = outerArc.centroid(d);
          endPosition[0] = (containerWidth / 2 - 20) * (midAngle(d) < Math.PI ? 1 : -1);
          endPosition[1] = startPosition[1];
          if (startPosition[1] < -radius) {
            endPosition[0] = -1 * endPosition[0];
            if (d.data.index === data.length - 1) {
              endPosition[1] = endPosition[1] - offsetSlicePosition - 5;
            } else {
              endPosition[1] = endPosition[1] - 20;
            }
            var middlePosition = outerArc.centroid(d);
            middlePosition[1] = startPosition[1] - 20;
            return [arc.centroid(d), middlePosition, endPosition];
          }
          return [startPosition, endPosition];
        })
        .attr("stroke", "gray")
        .attr("stroke-width", 1)
        .attr("fill", "none");

      lines.exit().remove();
    };

    change(chartData);
  }

  getChartData() {
    var chartData = this.props.chartData;
    var offsetCount = 0;
    // 40% of chartWidth is taken by the actual doughnut
    const containerWidth = this.props.containerWidth || this.props.chartWidth;
    const chartWidth = 0.4 * this.props.chartWidth;
    const widthForText = containerWidth - chartWidth;
    // Divide width by font-size to arrive at max characters
    // Reserve the last three characters for ... if needed
    const maxCharacters = Math.kuberaFloor(widthForText / 13) - 5;

    chartData = chartData.labels.map((label, index) => {
      const text = label && label.length > maxCharacters ? label.slice(0, maxCharacters) + "..." : label;
      const subText = `${formatNumberWithKuberaNumberFormatSettings(
        chartData.percentages[index]
      )}% • ${shortFormatNumberWithCurrency(chartData.data[index], chartData.currency, true, true)}`;
      const fullLabelText = label;
      var offsetSlicePosition = 0;

      // Offset Y position of label/line for a slice if its smaller than
      // 15% as smaller slices don't have much space above/below for the text
      // If this offset is not done, text for the smaller slice will overlap
      // with the neighbouring slices.
      const minPercentageWithoutLabelOffset = 15;
      if (index < chartData.labels.length) {
        if (chartData.percentages[index] < minPercentageWithoutLabelOffset) {
          offsetSlicePosition = -1 * Math.pow(4, offsetCount);
          offsetCount++;
        }
      }

      return {
        text: text,
        subText: subText,
        fullLabel: fullLabelText,
        value: chartData.data[index],
        index: index,
        percent: chartData.percentages[index],
        offsetSlicePosition: offsetSlicePosition
      };
    });
    return chartData;
  }

  handleDougnutContextMenuClick(event) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = this.props.hideRearrangeOption
      ? [[contextMenuItemType.REMOVE]]
      : [[contextMenuItemType.REARRANGE, contextMenuItemType.REMOVE]];

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
  }

  render() {
    const {
      className,
      chartData,
      chartWidth,
      chartHeight,
      hasBorder = true,
      shouldShowValue = true,
      hasBackgroundColor = true,
      shouldShowContextMenu = false,
      handleRemoveChartSelection,
      hideTitle,
      handleChartClick,
      chartPadding,
      handleRearrangeChartSelection
    } = this.props;
    const isRecommendationPresent = chartData.recommendationCount > 0;
    const isComparisonChart = chartData.content && chartData.content === "reports";

    const renderDoughnutChart = () => {
      return (
        <Container
          className={className}
          width={chartWidth}
          height={chartHeight}
          hasBorder={hasBorder}
          hasBackgroundColor={hasBackgroundColor}
          onClick={e => {
            handleChartClick && handleChartClick(chartData.id);
          }}
        >
          <HeaderContainer>
            <TitleContainer>
              <Title>{!hideTitle === false ? "" : chartData.title}</Title>
              {shouldShowValue && (
                <ValueContainer>
                  <ValueLabel
                    value={isComparisonChart ? chartData.value : chartData.total}
                    currency={chartData.currency}
                    currencyFontSize={13}
                    valueFontSize={24}
                    height={"29px"}
                  />
                  {!chartData.totalSubText === false && <ValueSubText>{chartData.totalSubText}</ValueSubText>}
                </ValueContainer>
              )}
            </TitleContainer>
            <OptionsContainer>
              {isRecommendationPresent && <RecommendationBadge>{chartData.recommendationCount}</RecommendationBadge>}
              {shouldShowContextMenu && (
                <DoughnutChartOptionButton
                  onClick={event => {
                    event.stopPropagation();
                    this.handleDougnutContextMenuClick(event);
                  }}
                />
              )}
            </OptionsContainer>
          </HeaderContainer>

          <ChartContainer id={this.props.id} padding={chartPadding} />
          <ContextMenu
            ref={this.contextMenuRef}
            onSelection={selectedItem => {
              if (selectedItem.id === contextMenuItemType.REMOVE.id) {
                handleRemoveChartSelection(chartData.id);
              } else if (selectedItem.id === contextMenuItemType.REARRANGE.id) {
                handleRearrangeChartSelection();
              }
            }}
          />
        </Container>
      );
    };
    return renderDoughnutChart();
  }
}

export default DoughnutChartComponent;
