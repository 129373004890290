import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  creditBalanceSelector,
  updateCreditBalance,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  accountPlanSelector,
  fetchUser,
  cancelSubscription,
  getSubscriptionEndDateString,
  accountEndTsSelector,
  useHistory
} from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import ClickableLink from "components/labels/DelayLink";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";

import AccountPaymentBlock from "./AccountPaymentBlock";
import AccountStatusMessage from "./AccountStatusMessage";

import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import { ReactComponent as HeartIcon } from "assets/images/heart.svg";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding: 20px 20px 45px;
  overflow: auto;
`;

const HistoryBackContainer = styled(ClickableLink)`
  padding: 20px;
  margin: -20px;
  margin-right: -10px;
  border-radius: 50%;
  margin-bottom: 0;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 15px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const SubscriptionTxt = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 12px;
`;

const StatusMessage = styled(AccountStatusMessage)`
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 12px;
`;

const CreditBalance = styled.div`
  min-height: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 12px;
`;

const ExtendTrialBlk = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 140%;
  padding: 30px 0;
  color: ${props => props.theme.mobileTxtColorKuberaHearts};
`;

const ExtendTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 5px;
`;

const ExtendDesc = styled.div`
  font-size: 16px;
  line-height: 140%;
  margin-bottom: ${props => (props.hasItemBelow ? "20px" : null)};

  a {
    color: ${props => props.theme.mobileTxtColorKuberaHearts};
    text-decoration: underline;
  }
`;

const HeartIconComponent = styled(HeartIcon)`
  margin-top: 1.8px;
  margin-right: 4px;
  width: 21px;
  transform: scale(1.5);

  path {
    fill: ${props => props.theme.mobileTxtColor};
    fill-opacity: 1;
  }
`;

const AccountPaymentBlockWrapper = styled.div`
  margin-top: 30px;
`;

const CancelSubscriptionAction = styled.div`
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.mobileTxtColor};
  background: ${props => props.theme.mobileBackground};
`;

const CancelSubscriptionDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 10px);
  margin-top: 163px;
  padding: 45px 30px;
`;

const CancelModalTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CancelModalMsg = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 38px;
  white-space: pre-wrap;
`;

const CancelBtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CancelSubscriptionBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
  background: #a50000;
`;

const CancelGoBackBtn = styled(SecondaryButton)`
  display: inline-flex;
`;

const MobileAccountPaymentBlock = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const creditBalance = useSelector(creditBalanceSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const endDate = getSubscriptionEndDateString(accountEndTs);

  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [isShowingVipPass, setShowingVipPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelClick = useCallback(async () => {
    setIsLoading(true);
    await dispatch(cancelSubscription());
    dispatch(fetchUser());
    setCancelModalVisible(false);
    setIsLoading(false);
  }, [dispatch]);

  const goBack = () => {
    if (isShowingVipPass) {
      setShowingVipPass(false);
      return;
    }
    history.goBack();
  };

  useEffect(() => {
    dispatch(updateCreditBalance());
  }, [dispatch]);

  const getCreditBalanceText = () => {
    if (!creditBalance === true || !creditBalance.amount === true) {
      return "";
    }
    return i18n.t("creditBalance").replace("%s1%", `$${creditBalance.amount / 100}`);
  };

  const onCancelModalActionClick = () => {
    setCancelModalVisible(true);
  };

  const onDismissCancel = () => {
    setCancelModalVisible(false);
  };

  const creditBalanceTxt = getCreditBalanceText();

  const isSubscribed =
    accountPlan !== SUBSCRIPTION_PLANS.TRIAL &&
    (accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE);
  const cancelModalMsg =
    accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE
      ? i18n.t("accountSubscribed.cancelWarn")
      : i18n.t("accountSubscribed.cancelWarnAfter").replace("%s%", endDate);
  const cancelSubscriptionBtn =
    accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE
      ? i18n.t("ok")
      : i18n.t("accountSubscribed.cancelSubscription");

  const tabTitle = i18n.t("accountSettings.subscriptionTab");

  return (
    <Container>
      <ContainerDialog>
        <div>
          <HistoryBackContainer onClick={goBack} direction="right">
            <HistoryBackBtn />
          </HistoryBackContainer>
        </div>
        {isShowingVipPass === false && (
          <>
            <SubscriptionTxt>{i18n.t(tabTitle)}</SubscriptionTxt>
            <StatusMessage />
            {creditBalanceTxt && <CreditBalance>{creditBalanceTxt}</CreditBalance>}
            <AccountPaymentBlockWrapper>
              <AccountPaymentBlock />
            </AccountPaymentBlockWrapper>
          </>
        )}
        <ExtendTrialBlk isSubscribed={isSubscribed}>
          <ExtendTitle>
            <HeartIconComponent />
            {i18n.t("accountSettings.extendTitle")}
          </ExtendTitle>
          <ExtendDesc
            dangerouslySetInnerHTML={{
              __html: i18n
                .t("accountSettings.extendDesc")
                .replace(/%s%/g, "hello@kubera.com")
                .replace("<br/>", " ")
            }}
          />
        </ExtendTrialBlk>
        {isSubscribed && (
          <CancelSubscriptionAction onClick={onCancelModalActionClick}>
            {i18n.t("accountSubscribed.cancelSubscription")}
          </CancelSubscriptionAction>
        )}
        {isSubscribed && isCancelModalVisible && (
          <DialogOverlay onDismiss={onDismissCancel}>
            <CancelSubscriptionDialog>
              <CancelModalTitle>{i18n.t("accountSubscribed.cancelSubscription")}?</CancelModalTitle>
              <CancelModalMsg
                dangerouslySetInnerHTML={{
                  __html: cancelModalMsg
                }}
              />
              <CancelBtnBlock>
                <CancelSubscriptionBtn
                  title={cancelSubscriptionBtn}
                  onClick={handleCancelClick}
                  isLoading={isLoading}
                />
                <CancelGoBackBtn
                  title={i18n.t("accountSubscribed.goBackBtn")}
                  onClick={onDismissCancel}
                  isDisabled={isLoading}
                />
              </CancelBtnBlock>
            </CancelSubscriptionDialog>
          </DialogOverlay>
        )}
      </ContainerDialog>
    </Container>
  );
};

export default MobileAccountPaymentBlock;
