import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const LinkStyle = css`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  cursor: pointer;
  color: #000000;
`;

const ExternalLink = styled.a`
  ${LinkStyle}
`;

const InternalLink = styled(Link)`
  ${LinkStyle}
`;

class ClickableLink extends React.Component {
  render() {
    const { className, ...other } = this.props;
    const externalLink = !this.props.to === true;

    if (externalLink === true) {
      return (
        <ExternalLink className={className} {...other}>
          {this.props.children}
        </ExternalLink>
      );
    } else {
      return (
        <InternalLink className={className} {...other}>
          {this.props.children}
        </InternalLink>
      );
    }
  }
}

export default ClickableLink;
