import React from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { dashboardChartsDataSelector } from "@kubera/common";
import { useSelector } from "react-redux";

const ChartsTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 150px;
  background: #f3f3f3;
  border: 1px dashed rgba(181, 181, 181, 0.4);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  margin: 14px 0px;
  text-align: center;
`;

const Column = styled.div`
  display: inline-block;
  margin-right: 16px;
`;

const ChartsRearrangementColumn = props => {
  const { chartIds, column, index } = props;
  const chartsData = useSelector(state => dashboardChartsDataSelector(state, chartIds));
  return (
    <Droppable droppableId={`${column}`} key={index}>
      {provided => (
        <Column ref={provided.innerRef} {...provided.droppableProps}>
          {chartsData.map((chartData, index) => {
            if (chartData && chartData.isChecked === true) {
              const chartName = chartData.title;
              return (
                <Draggable key={chartData.id} draggableId={`${chartData.id}`} index={index}>
                  {(provided, snapshot) => (
                    <ChartsTile ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {chartName}
                    </ChartsTile>
                  )}
                </Draggable>
              );
            } else {
              return null;
            }
          })}
          {provided.placeholder}
        </Column>
      )}
    </Droppable>
  );
};

export default ChartsRearrangementColumn;
