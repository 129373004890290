import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { ReactComponent as GhostIcon } from "assets/images/ghost.svg";
import SampleRecapReportImage from "assets/images/samplerecapreport.png";
import PrimaryButton from "components/button/PrimaryButton";
import { currentPortfolioSelector, updateUserPreferences, userPreferencesSelector } from "@kubera/common";
import { useSelector, useDispatch } from "react-redux";

const Container = styled.div`
  display : flex;
  flex-direction : column;
  width : 100%
  height : 100%
  justify-content : center;
`;

const RecapDefaultMessageContainer = styled.div`
  display : flex;
  flex-direction : column
  position: absolute;
  width: 900px;
  height: 430px;
  margin-left: 50px;
  //left: 170px;
  top: 115px;
  background: ${props => props.theme.recapDefaultMessageContainerBG};
  border: ${props => props.theme.recapDefaultMessageContainerBR};;
  box-sizing: border-box;
`;
const RecapDefaultMessageTitleContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-left: 55px;
`;
const RecapDefaultMessageTitle = styled.div`
  //position: absolute;
  width: 248px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;
const RecapDefaultMessageIcon = styled(GhostIcon)`
  margin-left: 445px;
  margin-top: -20px;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const RecapDefaultMessage = styled.div`
  position: relative;
  width: 600px;
  height: 54px;
  margin-left: 55px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const RecapDefaultNotifyMessage = styled(RecapDefaultMessage)`
  margin-left: 55px;
`;
const SampleRecapReportImageComponent = styled.img`
  //position: absolute;
  width: 824px;
  height: 489px;
  left: 87px;
  position : relative
  //top: 350px;
  top : ${props => (props.isRecapDataPresent ? "350px" : "330px")}
  //background: #ffffff;
  //box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
`;
const ShowMeButton = styled(PrimaryButton)`
width : 156px
height : 44px
//position : relative
top : 100px
 left : 55px
 margin-bottom : 28px
`;

const RecapDefaultMessageComponent = props => {
  const { isRecapDataPresent } = props;
  const dispatch = useDispatch();
  const userPreferences = useSelector(userPreferencesSelector);
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const updateIsRecapIntroScreenShown = () => {
    dispatch(
      updateUserPreferences({
        isRecapIntroScreenShownForPortfolio: [
          ...userPreferences.isRecapIntroScreenShownForPortfolio,
          currentPortfolio.id
        ]
      })
    );
  };
  return (
    <Container>
      <RecapDefaultMessageContainer>
        <RecapDefaultMessageTitleContainer>
          <RecapDefaultMessageTitle>{i18n.t("recapDefaultMessageTitle")}</RecapDefaultMessageTitle>
          <RecapDefaultMessageIcon></RecapDefaultMessageIcon>
        </RecapDefaultMessageTitleContainer>
        <RecapDefaultMessage>{i18n.t("recapDefaultMessage")}</RecapDefaultMessage>
        {isRecapDataPresent ? (
          <ShowMeButton title={i18n.t("recapShowMeButtonTitle")} onClick={updateIsRecapIntroScreenShown} />
        ) : (
          <RecapDefaultNotifyMessage>{i18n.t("recapDefaultNotifyMessage")}</RecapDefaultNotifyMessage>
        )}
      </RecapDefaultMessageContainer>
      <SampleRecapReportImageComponent isRecapDataPresent={isRecapDataPresent} src={SampleRecapReportImage} />
    </Container>
  );
};
export default RecapDefaultMessageComponent;
