import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import EmptyGridViewAssetsComponent from "./EmptyGridViewAssetsComponent";
import i18n from "locale/i18n";
import { isInternalUserSelector } from "@kubera/common";

const Container = styled(DialogOverlay)``;

const AddDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 674px;
  margin-top: 80px;
  padding: 50px 60px;
  box-sizing: border-box;
  min-height: 614px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: -13px;
`;

const AddMenu = styled(EmptyGridViewAssetsComponent)`
  margin-left: -6px;
  margin-top: 27px;
`;

const AddModal = React.forwardRef(
  (
    {
      isReadOnlyWlClient = false,
      handleMenuItemClick = () => null,
      isEmpty = false,
      gridData,
      isComingFromEmptyScreenRef
    },
    ref
  ) => {
    const isInternalUser = useSelector(isInternalUserSelector);
    const prevSectionRows = useRef(null);

    const [sheetData, setSheetData] = useState(null);

    const sectionRows = !sheetData
      ? null
      : (() => {
          const sheet = gridData.sheets[sheetData.sheetIndex];

          if (!sheet) {
            return null;
          }

          const section = sheet.sections[sheetData.sectionIndex];

          if (!section) {
            return null;
          }

          return section.rows.filter(row => !row.isEmpty());
        })();

    const handleTipClick = item => {
      if (!item) {
        setSheetData(null);
      }
      handleMenuItemClick(sheetData.sheetIndex, sheetData.sectionIndex, item);
    };

    const handleCloseModal = (linkingStarted = true) => {
      if (sheetData && sheetData.callback) {
        sheetData.callback(linkingStarted);
      }
      setSheetData(null);
      prevSectionRows.current = null;
    };

    useImperativeHandle(ref, () => ({
      isOpen: () => {
        return !!sheetData;
      },
      show: (sheetIndex, sectionIndex, callback) => {
        setSheetData({
          sheetIndex,
          sectionIndex,
          callback
        });
      },
      close: () => {
        handleCloseModal(false);
      }
    }));

    useEffect(() => {
      if (prevSectionRows.current && sectionRows && prevSectionRows.current.length !== sectionRows.length) {
        handleCloseModal(true);
      }

      prevSectionRows.current = sectionRows;
    }, [sectionRows]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!isComingFromEmptyScreenRef.current && isEmpty) {
        setSheetData({
          sheetIndex: 0,
          sectionIndex: 0
        });
      } else {
        handleCloseModal(false);
      }
    }, [isEmpty]); // eslint-disable-line react-hooks/exhaustive-deps

    if (sheetData) {
      return (
        <Container onDismiss={() => handleCloseModal(false)}>
          <AddDialog>
            <Title>{i18n.t("assetsComponent.addViewDialogTitle")}</Title>
            <AddMenu
              isReadOnlyWlClient={isReadOnlyWlClient}
              isInternalUser={isInternalUser}
              handleInitialTipClick={handleTipClick}
              isAddNewFlow
            />
          </AddDialog>
        </Container>
      );
    }

    return null;
  }
);

export default AddModal;
