import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import Spinner from "components/loader/Spinner";
import PrimaryButton from "components/button/PrimaryButton";

const defaultSize = 20;
const defaultThickness = 4;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SpinIt = styled(Spinner)`
  width: ${props => props.size + "px"};
  height: ${props => props.size + "px"};
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  white-space: pre-wrap;
`;

const RefreshButton = styled(PrimaryButton)`
  height: 44px;
  margin-top: 15px;
`;

class Loader extends React.Component {
  render() {
    const { errorMessage, onRefresh, ...other } = this.props;
    const size = this.props.size || defaultSize;
    const thickness = this.props.thickness || defaultThickness;

    if (errorMessage) {
      return (
        <Container {...other} className={this.props.className}>
          <ErrorMessage>{i18n.t("genericError")}</ErrorMessage>
          <RefreshButton title={i18n.t("refresh")} onClick={this.props.onRefresh} />
        </Container>
      );
    } else {
      return (
        <Container {...other} className={this.props.className}>
          <SpinIt darkTheme={true} size={size} thickness={thickness} />
        </Container>
      );
    }
  }
}

export default Loader;
