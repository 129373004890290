import React, { useState, useEffect } from "react";
import styled from "styled-components";

import requestIdleCallback from "utilities/requestIdleCallback";

const NumberScrollArea = styled.span`
  position: relative;
  display: inline-block;
  min-height: 100%;
  transform: ${props => (props.translateY ? `translateY(${props.translateY}%)` : `translateY(-1100)`)};
  transition: ${props => (!props.preventAnimation ? "transform 0.6s ease" : null)};
  vertical-align: top;
`;

const NumberItem = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(${props => props.num * 100}%);
  min-height: 100%;
  visibility: ${props => (!props.isAnimating && !props.isCurrent ? "hidden" : null)};
`;

const HiddenNumber = styled.span`
  visibility: hidden;
`;

const ScrollNumber = props => {
  const numbers = ["0", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0", props.value];
  const value = parseInt(props.value, 10);
  const translateVal = props.value !== "0" ? -(1100 - (value + 1) * 100) : 0;
  const [translateY, setTranslateY] = useState(!props.animateOnLoad && props.isInitChar ? translateVal : -1100);

  useEffect(() => {
    if (!props.animateOnLoad) {
      setTranslateY(translateVal);
    } else {
      setTimeout(() => {
        requestIdleCallback(() => {
          setTranslateY(translateVal);
        });
      }, 500);
    }
  }, [props.value, props.animateOnLoad, translateVal]);

  return (
    <NumberScrollArea translateY={translateY} preventAnimation={props.preventAnimation}>
      {numbers.map((num, index) => (
        <NumberItem
          key={index}
          num={index}
          isCurrent={index !== numbers.length - 1 && num === props.value}
          isAnimating={props.isAnimating}
        >
          {num}
        </NumberItem>
      ))}
      <HiddenNumber>{props.value}</HiddenNumber>
    </NumberScrollArea>
  );
};

export default ScrollNumber;
