import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import AppLogo from "components/labels/AppLogo";
import Loader from "components/loader/Loader";
import ClickableLink from "components/labels/ClickableLink";
import { withRouter } from "@kubera/common";
import { routes, websiteUrls, queryParams } from "routes";
import { getQueryParams, markUserActive, isAppInWhiteLabelMode } from "@kubera/common";
import { connect } from "react-redux";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 423px;
  height: fit-content;
`;

const Content = styled.div`
  display: flex;
  justify-content: ${props => (props.centerContent === true ? "center" : "flex-start")}
  height: 561px;
  flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
  padding: 50px;
  border: 1px solid #000000;
  box-sizing: border-box;
`;

const MarkUserActiveLoader = styled(Loader)`
  height: fit-content;
`;

const LoaderLabel = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const MarkUserActiveTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const MarkUserActiveDescription = styled.div`
  margin-top: 18px;
  margin-bottom: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const Link = styled(ClickableLink)`
  margin-top: 15px;
`;

class MarkUserActiveComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isRequestPending: false, isRequestCompleted: false, errorMessage: null };

    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
  }

  componentDidMount() {
    this.markUserActive();
  }

  markUserActive() {
    this.setState({ ...this.state, isRequestPending: true, errorMessage: null });

    const token = getQueryParams(this.props.location)[queryParams.TOKEN];
    this.props.markUserActive(
      token,
      () => {
        this.setState({
          ...this.state,
          isRequestPending: false,
          errorMessage: null,
          isRequestCompleted: true,
          errorCode: null
        });
      },
      error => {
        this.setState({
          ...this.state,
          isRequestPending: false,
          errorMessage: error.errorMessage,
          errorCode: error.errorCode
        });
      }
    );
  }

  handleLoaderRefreshClick() {
    this.markUserActive();
  }

  render() {
    const isRequestCompleted = this.state.isRequestCompleted;
    const errorMessage = this.state.errorMessage;
    if (isRequestCompleted === false) {
      const isRequestPending = this.state.isRequestPending;
      const isJwtExpiredError = this.state.errorMessage === "jwt expired" && this.state.errorCode === 1003;
      if (isJwtExpiredError === true) {
        return (
          <Container>
            <ContentContainer>
              <AppLogo />
              <Content>
                <MarkUserActiveTitle>{i18n.t("markUserActive.CheckinFailed.title")}</MarkUserActiveTitle>
                <MarkUserActiveDescription
                  dangerouslySetInnerHTML={{
                    __html: `${i18n.t("markUserActive.CheckinFailed.description")}<a href=${
                      routes.SIGNIN
                    } style='color: #000000'>Login to Kubera</a>`
                  }}
                />
                {isAppInWhiteLabelMode() === false && (
                  <Link href={websiteUrls.WEB_HOMEPAGE}>{i18n.t("appDomain")}</Link>
                )}
              </Content>
            </ContentContainer>
          </Container>
        );
      } else {
        return (
          <Container>
            <ContentContainer>
              <AppLogo />
              <Content centerContent={true}>
                <MarkUserActiveLoader errorMessage={errorMessage} onRefresh={this.handleLoaderRefreshClick} />
                {isRequestPending === true && <LoaderLabel>{i18n.t("markUserActiveLoaderMessage")}</LoaderLabel>}
              </Content>
            </ContentContainer>
          </Container>
        );
      }
    } else {
      return (
        <Container>
          <ContentContainer>
            <AppLogo />
            <Content centerContent={false}>
              <MarkUserActiveTitle>{i18n.t("markUserActive.title")}</MarkUserActiveTitle>
              <MarkUserActiveDescription>{i18n.t("markUserActive.description")}</MarkUserActiveDescription>
              {isAppInWhiteLabelMode() === false && <Link href={websiteUrls.WEB_HOMEPAGE}>{i18n.t("appDomain")}</Link>}
              <Link to={routes.SIGNIN}>{i18n.t("signIn")}</Link>
            </Content>
          </ContentContainer>
        </Container>
      );
    }
  }
}

const mapDispatchToProps = {
  markUserActive: markUserActive
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MarkUserActiveComponent));
