import React, { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Navigate as Redirect } from "react-router-dom";

import { signOut, getQueryParams } from "@kubera/common";
import { routes, queryParams } from "./Routes";
import InitialLoader from "components/loader/InitialLoader";

export function SignOut({ isSignedIn, signoutCallback }) {
  useEffect(() => {
    setTimeout(() => {
      signoutCallback();
    }, 1000);
    // eslint-disable-next-line
  }, []);
  if (isSignedIn === true) {
    signOut(true, window.location.search.substring(1));
    Sentry.withScope(function(scope) {
      scope.setTag("signout_exception", "Uncaught Exception");
      Sentry.captureException("RootComponent routes.SIGNOUT signOut triggered");
    });
  }
  if (isSignedIn === false) {
    const params = getQueryParams(window.location);
    if (params && params[queryParams.REDIRECT_URL]) {
      window.location.href = params[queryParams.REDIRECT_URL];
      return;
    }

    const signInRoute = routes.SIGNIN + window.location.search;
    return <Redirect to={signInRoute} />;
  }
  return <InitialLoader />;
}
