import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { userSelector, signOut } from "@kubera/common";

import Logo from "components/labels/AppLogo";
import ClickableLink from "components/labels/ClickableLink";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.mobileBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 32px;
`;

const WelcomeTxt = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 30px;
  margin-bottom: 15px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const MessageBlock = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColor};
  padding: 0 30px;
  margin-bottom: 37px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const ActionBtns = styled(ClickableLink)`
  font-size: 13px;
  line-height: 150%;
  color: ${props => props.theme.mobileTxtColorGrey};
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const LogoutBtn = styled(ActionBtns)`
  margin-left: 30px;
  margin-bottom: 35px;
`;

const WLKAdmin = () => {
  const user = useSelector(userSelector);

  const onLogout = () => {
    signOut();
  };

  return (
    <Container>
      <ContentContainer>
        <AppLogo hasNoLink />
        <WelcomeTxt>Hi {user && user.name},</WelcomeTxt>
        <MessageBlock
          dangerouslySetInnerHTML={{
            __html: i18n.t("wlkAdminTxt")
          }}
        />
        <LogoutBtn onClick={onLogout}>{i18n.t("logOut")}</LogoutBtn>
      </ContentContainer>
    </Container>
  );
};

export default React.memo(WLKAdmin);
