import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import {
  whiteLabelUserInfoSelector,
  siteConfigSelector,
  setUserCollaborateDocumentStatus,
  isAmcUser,
  wlClientContextSelector
} from "@kubera/common";
import Checkbox from "components/inputs/Checkbox";

const SettingsDialog = styled(Dialog)`
  position: relative;
  width: 420px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const EmailFieldValue = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
`;

const NameFieldValue = styled(EmailFieldValue)`
  text-transform: ${props => (props.capitalize === true ? "capitalize" : "")};
`;

const ConditionsIndicator = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
`;

const WarningText = styled.div`
  position: absolute;
  right: 50px;
  bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 90px;
  min-width: 120px;
`;

const CategoryCheckbox = styled(Checkbox)`
  margin-top: 10px;
`;

class WhiteLabelCollaborationSettingsDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.WL_COLLABORATE}`
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      collaborateDocumentStatus:
        this.props.isAmcUser === true ? props.wlClientContext.collaborateDocument : props.wlUserInfo.collaborateDocument
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleCategoryCheckboxOnChange = this.handleCategoryCheckboxOnChange.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  handleOverlayDismiss() {
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  handleCategoryCheckboxOnChange(checked) {
    this.setState({ collaborateDocumentStatus: checked === true ? 1 : 0 });
  }

  handleSaveButtonClick(e) {
    if (this.state.collaborateDocumentStatus !== this.props.wlUserInfo.collaborateDocument) {
      this.props.setUserCollaborateDocumentStatus(this.state.collaborateDocumentStatus);
    }
    this.handleOverlayDismiss();
  }

  getCategories() {
    const isAmcUser = this.props.isAmcUser;
    var documentsTitle = i18n.t("documents");

    if (isAmcUser === true && this.state.collaborateDocumentStatus === 0) {
      documentsTitle = i18n.t("documentNotShared");
    }

    const siteConfig = this.props.siteConfig;

    let categories = [
      { title: i18n.t("assetsComponent.title"), disabled: true, checked: true },
      { title: i18n.t("debtsComponent.title"), disabled: true, checked: true },
      { title: i18n.t("insuranceComponent.title"), disabled: true, checked: true },
      ...(siteConfig.showBeneficiary !== 0 ? [{ title: i18n.t("beneficiary"), disabled: true, checked: true }] : [])
    ];
    if (siteConfig.showDocument !== "hide") {
      categories.splice(3, 0, {
        title: documentsTitle,
        disabled: isAmcUser === true,
        checked: this.state.collaborateDocumentStatus === 1
      });
    }
    return categories;
  }

  render() {
    const siteConfig = this.props.siteConfig;
    const categories = this.getCategories();
    const isAmcUser = this.props.isAmcUser;
    const name = isAmcUser === true ? this.props.wlClientContext.name : siteConfig.name;
    const email = isAmcUser === true ? this.props.wlClientContext.email : siteConfig.supportEmail;

    console.log(isAmcUser);

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <SettingsDialog>
          <Container>
            <Title>{i18n.t("collaborateSettingsDialogTitle")}</Title>
            <FieldTitle>{isAmcUser === true ? i18n.t("by") : i18n.t("withWhom")}</FieldTitle>
            <NameFieldValue capitalize={isAmcUser === true}>
              {name}
              {isAmcUser === false && <ConditionsIndicator>{"*"}</ConditionsIndicator>}
            </NameFieldValue>
            <FieldTitle>{i18n.t("email")}</FieldTitle>
            <EmailFieldValue>{email}</EmailFieldValue>

            {categories.map((category, index) => (
              <CategoryCheckbox
                key={index}
                label={category.title}
                checked={category.checked}
                disabled={category.disabled}
                onChange={(checked, e) => {
                  this.handleCategoryCheckboxOnChange(checked);
                }}
              />
            ))}

            {isAmcUser === false && <SaveButton title={i18n.t("save")} onClick={this.handleSaveButtonClick} />}
            {isAmcUser === false && <WarningText>{i18n.t("collaborateWarning")}</WarningText>}
          </Container>
        </SettingsDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  siteConfig: siteConfigSelector(state),
  wlUserInfo: whiteLabelUserInfoSelector(state),
  wlClientContext: wlClientContextSelector(state),
  isAmcUser: isAmcUser(state)
});

const mapDispatchToProps = {
  setUserCollaborateDocumentStatus: setUserCollaborateDocumentStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WhiteLabelCollaborationSettingsDialog));
