import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigationType } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { useTheme, dialogOverlayTheme } from "theme";
import { isMobile } from "@kubera/common";
import { GridThemeInsideDialog } from "utilities/Contexts";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const isMobileDevice = isMobile();

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    isMobileDevice ? props.theme.backgroundDetailsOverlay : props.theme.popoverBackgroundColor};
  z-index: 10000;
  display: flex;
  align-items: center;
  flex-direction: column;
  contain: layout;
  box-sizing: ${isMobileDevice ? "border-box" : null};
  outline: 0;

  * {
    box-sizing: ${isMobileDevice ? "border-box" : null};
  }
`;

const DialogMainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const DialogContentContainer = styled.div`
  margin: auto;
  color: ${props => (isMobileDevice ? props.theme.mobileTxtColorOverlay : props.theme.dialogContentContainerCLR)};
  background: ${props => (isMobileDevice ? props.theme.mobileBackgroundOverlay : props.theme.dialogContentContainerBG)};
  border: ${props => props.theme.dialogContentContainerBR};
  box-shadow: ${props => props.theme.dialogContentContainerBS};
`;

let modalHistoryLength = null;
class DialogOverlay extends React.Component {
  static dismiss = (history, location) => {
    if (modalHistoryLength > 1) {
      history.goBack();
    } else {
      history.push({
        ...location,
        hash: ""
      });
    }
  };

  static forceDismiss = (history, location) => {
    history.push({
      ...location,
      hash: ""
    });
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.overlayRef = React.createRef();

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    modalHistoryLength = null;
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      // If an input element is focused and it has its mode attribute set to edit like a
      // grid cell being in edit mode don't consume that event
      if (event.target instanceof HTMLInputElement && event.target.getAttribute("mode") === "edit") {
        return false;
      }

      this.props.onDismiss();
      return true;
    }
    return false;
  }

  render() {
    return ReactDOM.createPortal(
      <Overlay className={this.props.className}>
        <DialogMainContainer
          id={"kubera-container-overlay"}
          data-cy="cyKUBERA"
          onClick={event => {
            if (event.target.id === "kubera-container-overlay") {
              if (this.props.onDismiss) {
                this.props.onDismiss();
              }
              event.stopPropagation();
            }
          }}
          ref={this.props.overlayRef}
        >
          <GridThemeInsideDialog.Provider value="default">{this.props.children}</GridThemeInsideDialog.Provider>
        </DialogMainContainer>
      </Overlay>,
      document.querySelector("body")
    );
  }
}

const DialogOverlayWrapper = React.forwardRef((props, ref) => {
  const theme = useTheme();

  const locationKey = useLocation()?.key;
  const navType = useNavigationType();
  const prevLocationKey = useRef(null);

  useEffect(() => {
    if (modalHistoryLength === null) {
      modalHistoryLength = 0;
    }
    if (prevLocationKey.current === locationKey) return () => null;
    prevLocationKey.current = locationKey;
    if (navType === "PUSH") {
      modalHistoryLength++;
    }
    if (navType === "POP") {
      modalHistoryLength--;
    }
    // eslint-disable-next-line
  }, [locationKey]);

  const modifiedTheme = { ...theme, ...dialogOverlayTheme(theme.mode) };

  return (
    <ThemeProvider theme={modifiedTheme}>
      <DialogOverlay {...props} overlayRef={ref} />
    </ThemeProvider>
  );
});

DialogOverlayWrapper.dismiss = DialogOverlay.dismiss;
DialogOverlayWrapper.forceDismiss = DialogOverlay.forceDismiss;

class Dialog extends React.Component {
  render() {
    return <DialogContentContainer className={this.props.className}>{this.props.children}</DialogContentContainer>;
  }
}

export { DialogOverlayWrapper as DialogOverlay, Dialog };
