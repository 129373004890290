import React, { useRef, useEffect } from "react";

const InViewPort = props => {
  const elementRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    const intersectionCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          props.onVisible();
        }
      });
    };

    const handleSheetsObserver = () => {
      const observerOptions = {
        rootMargin: "0px",
        threshold: [0.1, 0.5, 0.9]
      };
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        observer.current = new IntersectionObserver(intersectionCallback, observerOptions);
        observer.current.observe(elementRef.current);
      }
    };

    handleSheetsObserver();
  }, [elementRef, props]);

  return (
    <div ref={elementRef} className={props.className}>
      {props.children}
    </div>
  );
};

export default InViewPort;
