import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import TextInput from "components/inputs/TextInput";
import { createPortfolio, updatePortfolio, store, portfoliosNameSelector } from "@kubera/common";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import PrimaryButton from "components/button/PrimaryButton";

const PortfolioNameDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 163px;
`;

const NameInput = styled(TextInput)`
  height: 61px;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: transparent;
  margin: 54px 54px 5px 54px;
  border: 0;
  outline: 0;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const ErrorMessage = styled.div`
margin-left: 54px;
visibility: ${props => (!props.errorMessage === true ? "hidden" : "visible")}
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 130%;
font-feature-settings: 'ss01' on;
color: ${props => props.theme.errorCLR};
`;

const SaveButton = styled(PrimaryButton)`
  width: 134px;
  height: 44px;
  margin-left: 54px;
  margin-bottom: 42px;
  margin-top: 5px;
`;

class PortfolioNameComponent extends React.Component {
  static showEditPortfolio = (history, location, portfolioId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.EDIT_PORTFOLIO}&${hashParams.ID}=${portfolioId}`
    });
  };

  static showCreatePortfolio = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.CREATE_PORTFOLIO}`
    });
  };

  constructor(props) {
    super(props);

    const name = props.portfolio === undefined ? "" : props.portfolio.name;
    this.state = { name: name, isPending: false, errorMessage: null };

    this.handleInputchange = this.handleInputchange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleInputchange = e => {
    this.setState({ ...this.state, name: e.target.value });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveButtonClick();
    }
  };

  handleSaveButtonClick = () => {
    if (!this.state.name === true || this.state.name.trim().length === 0) {
      this.setState({ ...this.state, errorMessage: i18n.t("portfolioName.emptyNameError") });
      return;
    }
    const name = this.state.name.trim();

    if (portfoliosNameSelector(store.getState()).findIndex(temp => temp.toUpperCase() === name.toUpperCase()) !== -1) {
      this.setState({ ...this.state, errorMessage: i18n.t("portfolioName.duplicateNameError") });
      return;
    }

    if (this.props.portfolio === undefined) {
      this.createPortfolio(name);
    } else {
      const updatedPortfolio = this.props.portfolio;
      updatedPortfolio.name = name;
      this.updatePortfolio(updatedPortfolio);
    }
  };

  handleOverlayDismiss = () => {
    this.dismiss();
  };

  dismiss = () => {
    if (this.state.isPending === true) {
      return;
    }
    DialogOverlay.dismiss(this.props.history, this.props.location);
  };

  createPortfolio = name => {
    this.setState({ ...this.state, isPending: true, errorMessage: null });

    this.props.createPortfolio(
      name,
      portfolio => {
        this.setState({ ...this.state, isPending: false, errorMessage: null });
        DashboardComponentExports.showPortfolio(this.props.history, this.props.location, portfolio.id);
      },
      apiError => {
        this.setState({ ...this.state, isPending: false, errorMessage: apiError.errorMessage });
      }
    );
  };

  updatePortfolio = updatedPortfolio => {
    this.props.updatePortfolio(updatedPortfolio);
    this.dismiss();
  };

  render() {
    const name = this.state.name;
    const isPending = this.state.isPending;
    const errorMessage = this.state.errorMessage;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <PortfolioNameDialog>
          <NameInput
            data-cy="portfolioNameInput"
            placeholder={i18n.t("portfolioName.namePlaceholder")}
            value={name}
            onChange={this.handleInputchange}
            onKeyDown={this.handleKeyDown}
            autoFocus
          />
          <ErrorMessage errorMessage={errorMessage}>{!errorMessage === true ? "&nsbp" : errorMessage}</ErrorMessage>
          <SaveButton
            data-cy="portfolioNameSaveButton"
            onClick={this.handleSaveButtonClick}
            title={i18n.t("save")}
            isLoading={isPending}
          />
        </PortfolioNameDialog>
      </DialogOverlay>
    );
  }
}

const mapDispatchToProps = {
  createPortfolio: createPortfolio,
  updatePortfolio: updatePortfolio
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PortfolioNameComponent));
