import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import { sendMultiuserReInvite, deleteSubAccountuser, requestAccountDeletion } from "@kubera/common";
import UserListComponent from "./UserListComponent";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: -100px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Description = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.6);
`;

class MultiuserManagementComponent extends React.Component {
  render() {
    return (
      <Container className={this.props.className}>
        <Title>{i18n.t("accountSettings.multiuserManagementTabTitle")}</Title>
        <Description>{i18n.t("accountSettings.multiuserManagementTabDescription")}</Description>
        <UserListComponent access={{ allowAll: 1 }} />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  sendMultiuserReInvite,
  deleteSubAccountuser,
  requestAccountDeletion
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MultiuserManagementComponent));
