import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { repeatFrequency, planningVariables, displayVestingDuration, periods } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import NumberInput from "components/inputs/NumberInput";
import DropDown from "components/inputs/DropDown";
import { ReactComponent as ClearIcon } from "assets/images/delete_user_icon.svg";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const VestingDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const CliffTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const InputTitle = styled(CliffTitle)`
  margin-top: 20px;
`;

const FrequencyDropDown = styled(DropDown)`
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
`;

const DurationContainer = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: white;
  margin-top: 5px;
  height: 45px;
`;

const DurationInput = styled(NumberInput)`
  flex: 1;
  border: 0;
  outline: 0;
  font-weight: 400;
  margin-left: 10px;
  font-style: normal;
  line-height: 130%;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const DurationDropDown = styled(DropDown)`
  border: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  width: 85px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

const ShowCliffButton = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #0074fc;
  cursor: pointer;
`;

const ClearButton = styled(ClearIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

const CliffTitleContainer = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
`;

const HitArea = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 25%;
  transform: translateY(-25%);
`;

class VestingScheduleDialog extends React.Component {
  constructor(props) {
    super(props);
    const cliff = props.data?.cliff || 0;
    this.state = {
      frequency: props.data ? props.data.frequency : repeatFrequency.MONTHLY,
      value: props.data ? props.data.value : 12,
      duration: props.data ? props.data.duration : planningVariables.MONTHS,
      cliff,
      cliffDuration: props.data?.cliffDuration || planningVariables.YEARS,
      showCliff: !!cliff
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFrequencyDropDownSelection = this.handleFrequencyDropDownSelection.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleDurationSelection = this.handleDurationSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleAddCliffClick = this.handleAddCliffClick.bind(this);
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleValueChange(e, value) {
    this.setState({
      value
    });
  }

  handleDurationSelection(item, forCliff) {
    this.setState({
      [forCliff ? "cliffDuration" : "duration"]: item.id
    });
  }

  handleFrequencyDropDownSelection(item) {
    this.setState({
      frequency: item.id
    });
  }

  getFrequencyOptions() {
    const { frequency } = this.state;
    return [
      {
        id: repeatFrequency.MONTHLY,
        label: i18n.t("Month"),
        selected: frequency === repeatFrequency.MONTHLY
      },
      {
        id: repeatFrequency.QUARTERLY,
        label: i18n.t("3 Months"),
        selected: frequency === repeatFrequency.QUARTERLY
      },
      {
        id: repeatFrequency.BI_ANNUALLY,
        label: i18n.t("6 Months"),
        selected: frequency === repeatFrequency.BI_ANNUALLY
      },
      {
        id: repeatFrequency.YEARLY,
        label: i18n.t("Year"),
        selected: frequency === repeatFrequency.YEARLY
      }
    ];
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        this.handleSaveClick();
      }, 0);
    }
  }

  handleSaveClick(e) {
    const { frequency, value, duration, cliff, cliffDuration } = this.state;
    if (value > 0) {
      this.props.onVariableUpdate({
        frequency:
          duration === planningVariables.MONTHS && value < periods[frequency] ? repeatFrequency.MONTHLY : frequency,
        value,
        duration,
        cliff,
        cliffDuration
      });
      this.props.onDismiss();
    }
  }

  handleAddCliffClick() {
    this.setState({
      showCliff: true,
      cliff: 1,
      cliffDuration: planningVariables.YEARS
    });
  }

  getDurationDropDownData(forCliff) {
    const value = this.state[forCliff ? "cliff" : "value"];
    const duration = this.state[forCliff ? "cliffDuration" : "duration"];
    return [
      {
        id: planningVariables.MONTHS,
        label: displayVestingDuration({ value, duration: planningVariables.MONTHS }, true),
        selected: duration === planningVariables.MONTHS
      },
      {
        id: planningVariables.YEARS,
        label: displayVestingDuration({ value, duration: planningVariables.YEARS }, true),
        selected: duration === planningVariables.YEARS
      }
    ];
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <VestingDialog>
          <Container>
            <CliffTitle>{i18n.t("Every")}</CliffTitle>
            <FrequencyDropDown
              width={360}
              items={this.getFrequencyOptions()}
              onSelection={this.handleFrequencyDropDownSelection}
            />
            <InputTitle>{i18n.t("For")}</InputTitle>
            <DurationContainer>
              <DurationInput
                placeholder={"12"}
                value={this.state.value}
                onChange={this.handleValueChange}
                onKeyDown={this.handleKeyDown}
              />
              <DurationDropDown
                width={360}
                items={this.getDurationDropDownData()}
                onSelection={this.handleDurationSelection}
              />
            </DurationContainer>
            {!this.state.showCliff && (
              <ShowCliffButton onClick={this.handleAddCliffClick}>{i18n.t("addCliff")}</ShowCliffButton>
            )}
            {this.state.showCliff && (
              <>
                <CliffTitleContainer>
                  <CliffTitle>{i18n.t("Cliff")}</CliffTitle>
                  <HitArea onClick={() => this.setState({ showCliff: false, cliff: "" })}>
                    <ClearButton />
                  </HitArea>
                </CliffTitleContainer>
                <DurationContainer>
                  <DurationInput
                    placeholder={"1"}
                    value={this.state.cliff}
                    onChange={(e, value) => this.setState({ cliff: value })}
                    onKeyDown={this.handleKeyDown}
                  />
                  <DurationDropDown
                    width={360}
                    items={this.getDurationDropDownData(true)}
                    onSelection={event => this.handleDurationSelection(event, true)}
                  />
                </DurationContainer>
              </>
            )}
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </VestingDialog>
      </DialogOverlay>
    );
  }
}

export default VestingScheduleDialog;
