export class TableData {
  constructor(columns, rows, defaultSortByColumnSortKey, decreasingSortOrder = true) {
    this.columns = columns;
    this.rows = rows;
    this.defaultSortByColumnSortKey = defaultSortByColumnSortKey;
    this.decreasingSortOrder = decreasingSortOrder;
  }
}

export class TableColumnData {
  constructor(title, sortKey, getColumnCellValue, renderColumnCell, type = "number") {
    this.title = title;
    this.sortKey = sortKey;
    this.type = type;
    this.getColumnCellValue = getColumnCellValue;
    this.renderColumnCell = renderColumnCell;
    this.columnKey = this.title;
  }
}

export class TableRowData {
  constructor(rowObject, getContextMenuItems) {
    this.rowObject = rowObject;
    this.getContextMenuItems = getContextMenuItems;
  }
}
