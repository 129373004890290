import React from "react";
import styled from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import { hashParams, modalValues } from "routes";
import i18n from "i18next";

const DownloadDialog = styled(GenericMessageDialog)``;

class PortfolioDownloadDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.DOWNLOAD_PORTFOLIO}`
    });
  };

  render() {
    return (
      <DownloadDialog
        title={i18n.t("downloadPortfolioDialog.title")}
        description={i18n.t("downloadPortfolioDialog.description")}
      />
    );
  }
}

export default PortfolioDownloadDialog;
