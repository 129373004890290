import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  signInWithGoogle,
  isEmailValid,
  signUpWithEmailPassword,
  confirmSignup,
  confirmSignupPlanB,
  signInWithEmailPassword,
  getPasswordStrength,
  passwordStrengthLevels,
  resendSignupCode,
  setSignInWithGoogleFlowAction,
  isAppInWhiteLabelMode,
  getQueryParams,
  verifyMultiuserInvitationId,
  setMultiuserInvitationData,
  setSignUpWithGoogleFlowAction
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import AppLogo from "components/labels/AppLogo";
import signUpWithGoogleImage from "assets/images/signup_with_google.png";
import EmailInput from "components/inputs/EmailInput";
import PasswordInput from "components/inputs/PasswordInput";
import TextInput from "components/inputs/TextInput";
import ClickableLink from "components/labels/ClickableLink";
import { websiteUrls, routes, queryParams } from "routes";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import InitialLoader from "components/loader/InitialLoader";
import sanitize from "sanitize-html";
import RecaptchaV2Render from "./RecaptchaV2Render";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 423px;
  min-width: 423px;
  height: fit-content;
  background: #ffffff;
`;

const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 561px;
  margin-top: 8px;
  padding: 50px;
  border: 1px solid #000000;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const GoogleSignInButton = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: url(${signUpWithGoogleImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 321px 50px;
  margin-top: 14px;
  cursor: pointer;
`;

const OrLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  font-feature-settings: "ss01" on;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const NameField = styled(TextInput)`
  height: 50px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const EmailField = styled(EmailInput)`
  height: 50px;
  margin-top: 15px;
`;

const PasswordField = styled(PasswordInput)`
  height: 50px;
  margin-top: 15px;
`;

const SignUpTip = styled.div`
  margin-top: 9px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const SignUpButton = styled(PrimaryButton)`
  margin-top: 19px;
  min-width: 137px;
`;

const SignInLink = styled(ClickableLink)`
  margin-top: 17px;
`;

const PolicyDetails = styled.div`
  margin-top: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 143%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const UnconfirmedUserDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const VerificationCodeField = styled(TextInput)`
  height: 50px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const GoBackLink = styled(ClickableLink)`
  margin-top: 17px;
`;

const ResetPasswordDescription = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const ResetPasswordButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const VerificationCompleteMessage = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const SignInButton = styled(PrimaryButton)`
  margin-top: 29px;
`;

class SignUpComponent extends React.Component {
  constructor(props) {
    super(props);

    const params = getQueryParams(window.location);
    this.invitationId = params[queryParams.INVITATION_ID];
    this.invitationEmail = params[queryParams.EMAIL_ID];
    this.invitationPortfolioId = params[queryParams.PORTFOLIO_ID];

    this.state = {
      isVerifyingInvitationId: !this.invitationId === false,
      invitationVerificationError: null,
      isNameError: false,
      isEmailError: false,
      isPasswordError: false,
      name: null,
      email: this.invitationEmail || null,
      password: null,
      verificationCode: null,
      isVerificationCodeError: false,
      isUnconfirmedUser: false,
      isSignUpPending: false,
      showWeakPasswordDialog: false,
      showResetPasswordScreen: false,
      verifyUserUsingPlanB: false,
      isV2VerifyCompleted: false
    };

    this.handleNameInput = this.handleNameInput.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleSignUpWithGoogle = this.handleSignUpWithGoogle.bind(this);
    this.handleSignUpClick = this.handleSignUpClick.bind(this);
    this.handleVerificationCodeInput = this.handleVerificationCodeInput.bind(this);
    this.handleSignUpVerification = this.handleSignUpVerification.bind(this);
    this.showSignUpScreen = this.showSignUpScreen.bind(this);
    this.handleWeakPasswordDialogNegativeButtonClick = this.handleWeakPasswordDialogNegativeButtonClick.bind(this);
    this.handleWeakPasswordDialogPositiveButtonClick = this.handleWeakPasswordDialogPositiveButtonClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);

    this.props.setSignInWithGoogleFlowAction(false);
  }

  componentDidMount() {
    if (!this.invitationId === false) {
      this.props.verifyMultiuserInvitationId(
        this.invitationEmail,
        this.invitationId,
        isValid => {
          if (isValid === true) {
            this.setState({ isVerifyingInvitationId: false });
            this.props.setMultiuserInvitationData(this.invitationEmail, this.invitationId, this.invitationPortfolioId);
          } else {
            this.setState({
              invitationVerificationError: i18n.t("invalidMultiuserInviteLink")
            });
          }
        },
        error => {
          this.setState({ invitationVerificationError: error.errorMessage });
        }
      );
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (this.state.isUnconfirmedUser === true) {
        this.handleSignUpVerification();
      } else {
        this.handleSignUpClick();
      }
    }
  }

  handleNameInput(e) {
    this.setState({ ...this.state, name: e.target.value, isNameError: false });
  }

  handleEmailInput(e) {
    this.setState({ ...this.state, email: e.target.value.trim().toLowerCase(), isEmailError: false });
  }

  handlePasswordInput(e) {
    this.setState({ ...this.state, password: e.target.value, isPasswordError: false });
  }

  handleVerificationCodeInput(e) {
    this.setState({
      ...this.state,
      verificationCode: e.target.value.replace(/\s/g, ""),
      isVerificationCodeError: false
    });
  }

  handleSignUpClick() {
    const name = this.state.name;
    const email = this.state.email;
    const password = this.state.password;
    let isNameError = false;
    let isEmailError = false;
    let isPasswordError = false;
    let errorMessage = "";
    let errorCount = 0;

    const sanitizedName = sanitize(name, {
      allowedTags: [], // No tags allowed
      allowedAttributes: {} // No attributes allowed
    });

    if (!name) {
      errorMessage = i18n.t("emptyName");
      isNameError = true;
      errorMessage++;
    } else if (sanitizedName !== name) {
      errorMessage = i18n.t("invalidName");
      isNameError = true;
      errorCount++;
    }

    if (!email) {
      errorMessage = i18n.t("emptyEmail");
      isEmailError = true;
      errorCount++;
    } else if (isEmailValid(this.state.email) === false) {
      errorMessage = i18n.t("wrongEmailFormat");
      isEmailError = true;
      errorCount++;
    }

    if (!password) {
      errorMessage = i18n.t("emptyPassword");
      isPasswordError = true;
      errorCount++;
    } else if (password.length < PasswordInput.minPasswordLength) {
      errorMessage = i18n.t("passwordTooShort");
      isPasswordError = true;
      errorCount++;
    } else if (getPasswordStrength(password) === passwordStrengthLevels.WEAK) {
      this.setState({ ...this.state, showWeakPasswordDialog: true });
      return;
    }

    if (errorCount === 0) {
      this.signUp();
    } else {
      if (errorCount > 1) {
        errorMessage = i18n.t("multipleSignupInputError");
      }

      this.setState({
        ...this.state,
        isNameError,
        isEmailError,
        isPasswordError,
        errorMessage
      });
    }
  }

  signUp() {
    this.setState({ ...this.state, isSignUpPending: true, showWeakPasswordDialog: false });

    const name = this.state.name;
    const email = this.state.email;
    const password = this.state.password;

    signUpWithEmailPassword(
      name,
      email,
      password,
      this.invitationId,
      data => {
        if (data.kuberaUserConfirmed === false) {
          this.setState({ ...this.state, isSignUpPending: false, verifyUserUsingPlanB: true }, () => {
            this.showUnconfirmedUserScreen();
          });
        } else {
          this.handleSuccessfulSignUp();
        }
      },
      error => {
        if (error.code === "UserNotConfirmedException") {
          this.setState({ ...this.state, isSignUpPending: false });
          this.showUnconfirmedUserScreen();
        } else if (error.code === "UsernameExistsException") {
          this.setState({ ...this.state, isSignUpPending: true });

          // If user is trying to sign up using an email which he has
          // already used but never confirmed trigger a validation code.
          resendSignupCode(
            email,
            () => {
              this.setState({ ...this.state, isSignUpPending: false });
              this.showUnconfirmedUserScreen();
            },
            error => {
              if (error.code === "InvalidParameterException") {
                error.message = i18n.t("signUpEmailTaken");
              }
              this.setState({ ...this.state, isSignUpPending: false, errorMessage: error.message });
            }
          );
        } else if (error.code === "InvalidPasswordException") {
          this.setState({ ...this.state, isSignUpPending: false, errorMessage: i18n.t("passwordNotMatchingPolicy") });
        } else {
          this.setState({ ...this.state, isSignUpPending: false, errorMessage: error.message });
        }
      }
    );
  }

  signIn() {
    this.setState({ ...this.state, isSignUpPending: true });

    const email = this.state.email;
    const password = this.state.password;
    const parentAction = "signup";
    signInWithEmailPassword(
      email,
      password,
      data => {
        this.handleSuccessfulSignUp();
      },
      (error, isV2VerifyCompleted) => {
        // If user entered a different password during sign up than the previous
        // sign up attempts using the same email make user reset password
        if (error.code === "NotAuthorizedException" && this.state.isUnconfirmedUser === true) {
          this.setState({ ...this.state, showResetPasswordScreen: true });
        } else {
          this.setState({ ...this.state, isSignUpPending: false, errorMessage: error.message });
        }

        if (isV2VerifyCompleted) {
          this.setState({ ...this.state, isV2VerifyCompleted: true });
        }
      },
      parentAction
    );
  }

  handleSignUpVerification() {
    const verificationCode = this.state.verificationCode;

    const handleError = error => {
      if (error.code === "CodeMismatchException") {
        error.message = i18n.t("wrongVerificationCode");
      } else if (error.code === "NotAuthorizedException") {
        error.message = i18n.t("signUpEmailTaken");
      } else if (error.code?.length > 0 && (!error.message || error.message.length === 0)) {
        error.message = i18n.t("somethingWentWrong");
      }
      this.setState({ ...this.state, isSignUpPending: false, errorMessage: error.message });
    };

    if (!verificationCode) {
      this.setState({ ...this.state, isVerificationCodeError: true, errorMessage: i18n.t("emptyVerificationCode") });
    } else {
      this.setState({ ...this.state, isSignUpPending: true });

      if (this.state.verifyUserUsingPlanB) {
        confirmSignupPlanB(
          {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            verificationCode,
            invitationId: this.invitationId
          },
          data => {
            this.signIn();
          },
          handleError
        );
      } else {
        confirmSignup(
          this.state.email,
          verificationCode,
          data => {
            this.signIn();
          },
          handleError
        );
      }
    }
  }

  showUnconfirmedUserScreen() {
    this.setState({
      ...this.state,
      isSignUpPending: false,
      isVerificationCodeError: false,
      errorMessage: null,
      isUnconfirmedUser: true
    });
  }

  showSignUpScreen() {
    this.setState({
      ...this.state,
      isUnconfirmedUser: false,
      verificationCode: null,
      isVerificationCodeError: false
    });
  }

  handleSuccessfulSignUp() {
    this.props.history.replace(routes.SIGNIN_CALLBACK);
  }

  handleSignUpWithGoogle() {
    this.props.setSignUpWithGoogleFlowAction(true);
    signInWithGoogle();
  }

  handleWeakPasswordDialogNegativeButtonClick() {
    this.setState({ ...this.state, showWeakPasswordDialog: false });
  }

  handleWeakPasswordDialogPositiveButtonClick() {
    this.signUp();
  }

  handleSignInClick = () => {
    this.props.history.push({
      ...this.props.location,
      pathname: routes.SIGNIN,
      hash: "",
      search: `${!this.state.email === true ? "" : queryParams.EMAIL_ID + "=" + encodeURIComponent(this.state.email)}`
    });
  };

  handleResetPassword() {
    this.props.history.push({
      ...this.props.location,
      pathname: routes.FORGOT_PASSWORD,
      hash: "",
      search: `${!this.state.email === true ? "" : queryParams.EMAIL_ID + "=" + encodeURIComponent(this.state.email)}`
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    if (this.state.isVerifyingInvitationId === true) {
      return (
        <InitialLoader
          overrideGenericError={true}
          disableRefresh={true}
          errorMessage={this.state.invitationVerificationError}
        />
      );
    }

    const showResetPasswordScreen = this.state.showResetPasswordScreen;
    const isUnconfirmedUser = this.state.isUnconfirmedUser;
    const isSignUpPending = this.state.isSignUpPending;

    if (showResetPasswordScreen === true) {
      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} />
            <SignInForm onSubmit={this.onFormSubmit}>
              <Title>{i18n.t("signUp")}</Title>
              <ResetPasswordDescription>{i18n.t("signUpResetPasswordDescription")}</ResetPasswordDescription>
              <ResetPasswordButton title={i18n.t("resetPassword")} onClick={this.handleResetPassword} />
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }

    if (this.state.isV2VerifyCompleted === true) {
      return (
        <Container>
          <ContentContainer>
            <SignInForm>
              <Title>{i18n.t("verificationCompleted")}</Title>
              <VerificationCompleteMessage>{i18n.t("verificationDoneDescription")}</VerificationCompleteMessage>
              <SignInButton title={i18n.t("signIn")} onClick={this.handleSignInClick} />
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }

    if (isUnconfirmedUser === true) {
      const verificationCode = this.state.verificationCode;
      const isVerificationCodeError = this.state.isVerificationCodeError;
      const errorMessage = this.state.errorMessage;

      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} />
            <SignInForm onSubmit={this.onFormSubmit}>
              <Title>{i18n.t("signUp")}</Title>
              {!errorMessage === false && <ErrorMessage data-cy="errorMessage">{errorMessage}</ErrorMessage>}
              {!errorMessage === true && (
                <UnconfirmedUserDescription>{i18n.t("verificationCodeEmailed")}</UnconfirmedUserDescription>
              )}
              <VerificationCodeField
                placeholder={i18n.t("verificationCodeHint")}
                inputError={isVerificationCodeError}
                value={verificationCode}
                onChange={this.handleVerificationCodeInput}
                onKeyDown={this.handleKeyDown}
                data-cy="verificationCode"
                autoComplete="one-time-code"
              />
              <RecaptchaV2Render action="sign_up" />
              <SignUpButton
                title={i18n.t("signUp")}
                onClick={this.handleSignUpVerification}
                isLoading={isSignUpPending}
                data-cy="signUp"
              />
              <PolicyDetails>
                <span>{"By continuing to sign up you accept our\n"}</span>
                <ClickableLink href={websiteUrls.TERMS_OF_SERVICE} target="_blank">
                  {"Terms of Service"}
                </ClickableLink>
                <span>{" and "}</span>
                <ClickableLink href={websiteUrls.PRIVACY_POLICY} target="_blank">
                  {"Privacy Policy."}
                </ClickableLink>
              </PolicyDetails>
              <SignInLink to={`${routes.SIGNIN}${window.location.search}`}>{i18n.t("signInLink")}</SignInLink>
              <GoBackLink onClick={this.showSignUpScreen}>{i18n.t("goBackLink")}</GoBackLink>
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }

    const isNameError = this.state.isNameError;
    const isEmailError = this.state.isEmailError;
    const isPasswordError = this.state.isPasswordError;
    const errorMessage = this.state.errorMessage;
    const name = this.state.name;
    const email = this.state.email;
    const password = this.state.password;
    const showWeakPasswordDialog = this.state.showWeakPasswordDialog;

    return (
      <Container>
        <ContentContainer>
          <AppLogo hasNoLink={isWhiteLabelledApp} />
          <SignInForm onSubmit={this.onFormSubmit}>
            <Title>{i18n.t("signUp")}</Title>
            <GoogleSignInButton tabIndex={"0"} onClick={this.handleSignUpWithGoogle} data-cy="googleSignInButton" />
            {!errorMessage === true && <OrLabel>{i18n.t("or")}</OrLabel>}
            {!errorMessage === false && <ErrorMessage data-cy="errorMessage2">{errorMessage}</ErrorMessage>}
            <NameField
              id="name"
              name="name"
              placeholder={i18n.t("signupNamePlaceholder")}
              inputError={isNameError}
              value={name}
              onChange={this.handleNameInput}
              onKeyDown={this.handleKeyDown}
              data-cy="signUpName"
              autoComplete="name"
            />
            <EmailField // data-cy in Component
              id="email"
              name="email"
              placeholder={i18n.t("email")}
              inputError={isEmailError}
              value={email}
              disabled={!this.invitationId === false}
              onChange={this.handleEmailInput}
              onKeyDown={this.handleKeyDown}
              autoComplete="username"
            />
            <PasswordField // data-cy in Component
              id="password"
              name="password"
              placeholder={i18n.t("password")}
              inputError={isPasswordError}
              value={password}
              onChange={this.handlePasswordInput}
              onKeyDown={this.handleKeyDown}
              autoComplete="new-password"
              required
              aria-autocomplete="list"
            />
            <SignUpTip>{i18n.t("passwordTip")}</SignUpTip>
            <RecaptchaV2Render action="sign_up" />
            <SignUpButton
              title={i18n.t("signUp")}
              onClick={this.handleSignUpClick}
              isLoading={isSignUpPending}
              data-cy="signUp"
            />
            <PolicyDetails>
              <span>{"By continuing to sign up you accept our\n"}</span>
              <ClickableLink href={websiteUrls.TERMS_OF_SERVICE} target="_blank">
                {"Terms of Service"}
              </ClickableLink>
              <span>{" and "}</span>
              <ClickableLink href={websiteUrls.PRIVACY_POLICY} target="_blank">
                {"Privacy Policy."}
              </ClickableLink>
            </PolicyDetails>
            <SignInLink to={`${routes.SIGNIN}${window.location.search}`} data-cy="signInLink">
              {i18n.t("signInLink")}
            </SignInLink>
          </SignInForm>
        </ContentContainer>
        {showWeakPasswordDialog === true && (
          <ConfirmationDialog
            title={i18n.t("weakPasswordDialogTitle")}
            description={i18n.t("weakPasswordDialogDescription")}
            positiveButtonTitle={i18n.t("signUp")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleWeakPasswordDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleWeakPasswordDialogPositiveButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = {
  setSignInWithGoogleFlowAction,
  setSignUpWithGoogleFlowAction,
  verifyMultiuserInvitationId,
  setMultiuserInvitationData
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SignUpComponent));
