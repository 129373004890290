import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { updateCreditBalance, creditBalanceSelector } from "@kubera/common";

import AccountStatusMessage from "./AccountStatusMessage";
import AccountPaymentBlock from "./AccountPaymentBlock";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 7px;
`;

const Subscription = styled(AccountStatusMessage)`
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 4px;
  line-height: 21px;
`;

const CreditBalance = styled.div`
  min-height: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  margin-bottom: 19px;
`;

class AccountSubscriptionComponent extends React.Component {
  get subscriptionTitle() {
    return i18n.t("accountSettings.subscriptionTab");
  }

  constructor(props) {
    super(props);

    this.handleVipPassDialogDismiss = this.handleVipPassDialogDismiss.bind(this);
  }
  componentDidMount() {
    this.props.updateCreditBalance();
  }

  handleVipPassDialogDismiss() {
    this.setState({ showVipPassDialog: false });
  }

  getCreditBalanceText() {
    if (!this.props.creditBalance === true || !this.props.creditBalance.amount === true) {
      return "";
    }
    return i18n.t("creditBalance").replace("%s1%", `$${this.props.creditBalance.amount / 100}`);
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Title>{this.subscriptionTitle}</Title>
        <Subscription />
        <CreditBalance>{this.getCreditBalanceText()}</CreditBalance>
        <AccountPaymentBlock />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  creditBalance: creditBalanceSelector(state)
});

const mapDispatchToProps = {
  updateCreditBalance: updateCreditBalance
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSubscriptionComponent);
