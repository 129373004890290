import React from "react";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import i18n from "i18next";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  months,
  store,
  userAgeAtDate,
  portfolioCashOnHand,
  portfolioNetWorth,
  getFriendlyDateStringForFutureDate,
  parseKuberaDateString
} from "@kubera/common";
import NetworthBreakdownComponent from "./NetworthBreakdownComponent";
import CashBreakdownComponent from "./CashBreakdownComponent";

const BreakdownDialog = styled(Dialog)`
  position: relative;
  width: 720px;
  min-height: 790px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 40px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TimePeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.03em;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TimePeriod = styled.div``;

const TimePeriodDescription = styled.div`
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const Age = styled.span`
  font-weight: 400;
  white-space: pre;
`;

const ScenarioTabs = styled(Tabs)`
  margin-top: 28px;
  margin-bottom: 34px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ScenarioTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const ScenarioTabHeaderContainer = styled.div`
  display: flex;
`;

const ScenarioTabHeader = styled(Tab)`
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0.35;
  display: ${props => (props.isHidden === true ? "none" : "auto")};

  &.is-selected {
    opacity: 1;
  }
`;

const ScenarioTabPanel = styled(TabPanel)`
  margin-top: 30px;
  display: none;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  margin-right: 20px;
  border-left: ${props => `6px solid ${props.color}`};
`;

const ValueName = styled.div`
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
  color: black;
`;

const ValueChangeLabel = styled(ChangeLabel)`
  width: fit-content;
  margin-bottom: -4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

class NetworthCashBreakdownDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedTabIndex: this.props.showCash ? 1 : 0 };

    this.handleScenarioChange = this.handleScenarioChange.bind(this);
  }

  handleScenarioChange(index) {
    this.setState({ selectedTabIndex: index });
  }

  getTimePeriodString() {
    const date = new Date(this.props.groupedData.data[this.props.dataIndex].date);
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  getUserAgeString() {
    const date = new Date(this.props.groupedData.data[this.props.dataIndex].date);
    const ageAtDate = userAgeAtDate(store.getState(), date);
    if (!ageAtDate === true) {
      return null;
    }
    return ` • ${i18n.t("age")} ${ageAtDate}`;
  }

  getTimePeriodDescription() {
    const date = parseKuberaDateString(this.props.groupedData.data[this.props.dataIndex].date);
    return getFriendlyDateStringForFutureDate(date);
  }

  getTabs(groupedData) {
    const colorPallete = this.props.colorPallete;
    const dataIndex = this.props.dataIndex;
    var tabs = [];

    const networthHeader = (
      <ValueItem color={colorPallete.color} showBorder={true}>
        <ValueName>{i18n.t("networth")}</ValueName>
        <ValueLabel
          currency={groupedData.currency}
          value={this.props.getEndDataPoint(groupedData, dataIndex).networth}
          currencyFontSize={13}
          valueFontSize={22}
          fontWeight={700}
          height={"24px"}
          showZero={true}
          preventAnimation={true}
        />
        <ValueChangeLabel
          currency={groupedData.currency}
          startValue={this.props.portfolioNetWorth}
          endValue={this.props.getEndDataPoint(groupedData, dataIndex).networth}
          preventAnimation={true}
        />
      </ValueItem>
    );
    const networthBreakdownPanel = (
      <NetworthBreakdownComponent
        dataForScenario={this.props.dataForScenario}
        scenario={this.props.dataForScenario.scenario}
        groupedData={groupedData}
        dataIndex={this.props.dataIndex}
      />
    );

    const cashHeader = (
      <ValueItem color={colorPallete.color}>
        <ValueName>{i18n.t("cash")}</ValueName>
        <ValueLabel
          currency={groupedData.currency}
          value={Math.round(this.props.getEndDataPoint(groupedData, dataIndex).cashTotal)}
          currencyFontSize={13}
          valueFontSize={22}
          fontWeight={700}
          height={"24px"}
          showZero={true}
          preventAnimation={true}
        />
        <ValueChangeLabel
          currency={groupedData.currency}
          startValue={this.props.portfolioCashOnHand}
          endValue={this.props.getEndDataPoint(groupedData, dataIndex).cashTotal}
          preventAnimation={true}
        />
      </ValueItem>
    );
    const cashBreakdownPanel = (
      <CashBreakdownComponent
        dataForScenario={this.props.dataForScenario}
        scenario={this.props.dataForScenario.scenario}
        groupedData={groupedData}
        dataIndex={this.props.dataIndex}
      />
    );

    tabs.push({ key: "networth", header: networthHeader, panel: networthBreakdownPanel });
    tabs.push({ key: "cash", header: cashHeader, panel: cashBreakdownPanel });
    return tabs;
  }

  render() {
    const groupedData = this.props.groupedData;
    const userAgeString = this.getUserAgeString();
    const tabs = this.getTabs(groupedData);

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <BreakdownDialog>
          <Container>
            <TimePeriodContainer>
              <TimePeriod>
                {this.getTimePeriodString()} {!userAgeString === false && <Age>{userAgeString}</Age>}
              </TimePeriod>
              <TimePeriodDescription>{this.getTimePeriodDescription()}</TimePeriodDescription>
            </TimePeriodContainer>

            <ScenarioTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.selectedTabIndex}
              onSelect={this.handleScenarioChange}
            >
              <ScenarioTabList>
                {tabs.map((tab, index) => (
                  <ScenarioTabHeader data-cy={"breakdownTab" + index} key={index}>
                    <ScenarioTabHeaderContainer>{tab.header}</ScenarioTabHeaderContainer>
                  </ScenarioTabHeader>
                ))}
              </ScenarioTabList>
              {tabs.map((tab, index) => (
                <ScenarioTabPanel key={`networthCashPanel${index}`}>{tab.panel}</ScenarioTabPanel>
              ))}
            </ScenarioTabs>
          </Container>
        </BreakdownDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  portfolioNetWorth: portfolioNetWorth(state),
  portfolioCashOnHand: portfolioCashOnHand(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetworthCashBreakdownDialog));
