import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { routes, queryParams } from "routes";
import { withRouter } from "@kubera/common";

import { forgotPassword, forgotPasswordSubmit, getQueryParams } from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import PasswordInput from "components/inputs/PasswordInput";
import EmailInput from "components/inputs/EmailInput";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 323px;
  min-width: 323px;
  height: fit-content;
  background: #ffffff;
`;

const SignInForm = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss01" on;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.45px;
  white-space: pre-wrap;
`;

const Description = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  margin-bottom: -22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
`;

const ResetCodeField = styled(TextInput)`
  height: 50px;
  margin-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const PasswordField = styled(PasswordInput)`
  height: 50px;
  margin-top: 16px;
`;

const ResetPasswordButton = styled(PrimaryButton)`
  margin-top: 50px;
  margin-bottom: 5px;
  min-width: 188px;
  height: 41px;
`;

const CreatedPasswordCompleteMessage = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const EmailField = styled(EmailInput)`
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-top: 30px;
`;

const PasswordTip = styled.div`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss01" on;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.11px;
  margin-top: 12px;
`;

class CreatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      isPasswordSet: false,
      verificationCode: null,
      isPasswordError: false,
      isVerificationCodeError: false,
      errorMessage: null,
      isRequestPending: false
    };
    this.handleVerificationCodeInput = this.handleVerificationCodeInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    this.handleSignInClick = this.handleSignInClick.bind(this);

    this.handleVerificationCodeKeyDown = this.handleVerificationCodeKeyDown.bind(this);
    this.handleNewPasswordKeyDown = this.handleNewPasswordKeyDown.bind(this);

    this.newPassRef = React.createRef();
  }

  get emailAddress() {
    if (this.props.emailId) {
      return (this.props.emailId || "").toLowerCase();
    }
    const emailValue = getQueryParams(this.props.location)[queryParams.EMAIL_ID];
    return (emailValue || "").toLowerCase();
  }

  handlePasswordInput(e) {
    this.setState({ ...this.state, password: e.target.value, isPasswordError: false });
  }

  handleVerificationCodeInput(e) {
    this.setState({
      ...this.state,
      verificationCode: e.target.value.replace(/\s/g, ""),
      isVerificationCodeError: false
    });
  }

  handleVerificationCodeKeyDown(event) {
    if (event.key === "Enter") {
      this.newPassRef.current.inputRef.current.focus();
    }
    return false;
  }

  handleNewPasswordKeyDown(event) {
    if (event.key === "Enter") {
      this.handleResetPasswordClick();
    }
    return false;
  }

  handleResetPasswordClick() {
    const verificationCode = this.state.verificationCode;
    const password = this.state.password;
    const isPasswordSet = this.state.isPasswordSet;

    let isPasswordError = false;
    let isVerificationCodeError = false;
    let errorCount = 0;
    let errorMessage = "";

    if (isPasswordSet && !verificationCode) {
      isVerificationCodeError = true;
      errorMessage = i18n.t("emptyVerificationCode");
      errorCount++;
    }
    if (!isPasswordSet && !password) {
      isPasswordError = true;
      errorMessage = i18n.t("emptyPassword");
      errorCount++;
    } else if (!isPasswordSet && password.length < PasswordInput.minPasswordLength) {
      errorMessage = i18n.t("passwordTooShort");
      isPasswordError = true;
      errorCount++;
    }

    if (errorCount === 0) {
      this.resetPassword();
    } else {
      if (errorCount > 1) {
        errorMessage = i18n.t("multipleSignupInputError");
      }
      this.setState({ ...this.state, isPasswordError, isVerificationCodeError, errorMessage });
    }
  }

  resetPassword() {
    this.setState({ ...this.state, isRequestPending: true });

    const password = this.state.password;
    const verificationCode = this.state.verificationCode;
    const isPasswordSet = this.state.isPasswordSet;

    if (!isPasswordSet) {
      forgotPassword(
        this.emailAddress,
        () => {
          this.setState({
            isPasswordSet: true,
            isRequestPending: false,
            errorMessage: null
          });
        },
        error => {
          this.setState({
            errorMessage: error?.message,
            isRequestPending: false
          });
        },
        { requestSessionId: true }
      );

      return;
    }

    forgotPasswordSubmit(
      this.emailAddress,
      verificationCode,
      password,
      () => {
        this.showResetPasswordCompletedScreen();
      },
      error => {
        if (error.code === "InvalidPasswordException") {
          error.message = i18n.t("passwordNotMatchingPolicy");
        }
        this.setState({ ...this.state, isRequestPending: false, errorMessage: error.message });
      },
      {
        parentAction: "setPassword"
      }
    );
  }

  showResetPasswordCompletedScreen() {
    this.setState({ ...this.state, isRequestPending: false, isResetPasswordCompleted: true });
    if (this.props.onComplete) {
      this.props.onComplete();
    }
  }

  handleSignInClick() {
    this.props.history.push(routes.SIGNIN);
  }

  render() {
    const isResetPasswordCompleted = this.state.isResetPasswordCompleted;

    if (isResetPasswordCompleted === true) {
      return (
        <Container>
          <ContentContainer>
            <SignInForm>
              <Title>{i18n.t("resetPassword").replace(" ", "\n")}</Title>
              <CreatedPasswordCompleteMessage>{i18n.t("createPasswordCompleted")}</CreatedPasswordCompleteMessage>
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    } else {
      const isRequestPending = this.state.isRequestPending;
      const errorMessage = this.state.errorMessage;
      const isPasswordError = this.state.isPasswordError;
      const isVerificationCodeError = this.state.isVerificationCodeError;
      const verificationCode = this.state.verificationCode;
      const password = this.state.password;
      const isPasswordSet = this.state.isPasswordSet;

      return (
        <Container>
          <ContentContainer>
            <SignInForm>
              <Title>{i18n.t("createPassword").replace(" ", "\n")}</Title>
              {!errorMessage === false && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {!isPasswordSet ? (
                <>
                  <EmailField data-private disabled={true} value={this.emailAddress} />
                  <PasswordField
                    placeholder={i18n.t("newPassword")}
                    inputError={isPasswordError}
                    value={password}
                    ref={this.newPassRef}
                    onChange={this.handlePasswordInput}
                    onKeyDown={this.handleNewPasswordKeyDown}
                    autoComplete="new-password"
                    required
                    aria-autocomplete="list"
                  />
                  <PasswordTip>{i18n.t("createPassword.tip")}</PasswordTip>
                  <ResetPasswordButton
                    title={i18n.t("createPassword")}
                    onClick={this.handleResetPasswordClick}
                    isLoading={isRequestPending}
                  />
                </>
              ) : (
                <>
                  <Description>{i18n.t("forgotPasswordConfirmation").replace(/\*/, "")}</Description>
                  <ResetCodeField
                    placeholder={i18n.t("forgotPasswordCodePlaceholder")}
                    inputError={isVerificationCodeError}
                    value={verificationCode}
                    onChange={this.handleVerificationCodeInput}
                    onKeyDown={this.handleVerificationCodeKeyDown}
                    autoFocus={true}
                    autoComplete="one-time-code"
                  />
                  <ResetPasswordButton
                    title={i18n.t("createPassword")}
                    onClick={this.handleResetPasswordClick}
                    isLoading={isRequestPending}
                  />
                </>
              )}
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }
  }
}

export default withRouter(CreatePassword);
