import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { isMobile } from "@kubera/common";

const isMobileDevice = isMobile();

const ScrollToTop = props => {
  const location = useLocation();
  useEffect(() => {
    if (isMobileDevice) {
      return () => null;
    }

    const container = document.querySelector(".dashboard-container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [location.pathname]);

  return <>{props.children}</>;
};

export default ScrollToTop;
