import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import TableComponent from "components/table/TableComponent";
import { TableData, TableColumnData, TableRowData } from "components/table/TableDataModel";
import {
  shortFormatNumberWithCurrency,
  getPastDateString,
  deleteWlClient,
  reInviteWlClient,
  wlSiteDefaultCurrencySelector,
  siteConfigSelector,
  wlSetupType,
  isEmailValid,
  isWlEmailPlaceholder
} from "@kubera/common";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import InviteClientDialogComponent from "components/white_label/InviteClientDialogComponent";

import DeleteClientConfirmationDialog from "./DeleteClientConfirmationDialog";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const ClientTable = styled(TableComponent)`
  width: 100%;
`;

const ClientNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClientCellName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

const ClientDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const EmptyTableContainer = styled.div`
  padding: 18px 20px 0px 50px;
`;

const InviteClientButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const EmptyTableDescription = styled.div`
  padding-top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const NoSearchResultsDescription = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

class ArchivedClientsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showDeleteDialog: false, userToDelete: null };

    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.handleDeleteCancellation = this.handleDeleteCancellation.bind(this);
  }

  handleContextMenuSelection(item, row) {
    if (item.id === contextMenuItemType.DELETE_PERMANENTLY.id) {
      this.setState({ showDeleteDialog: true, userToDelete: row.rowObject });
    } else if (item.id === contextMenuItemType.RESTORE.id) {
      if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
        InviteClientDialogComponent.show(this.props.history, this.props.location, row.rowObject.userId);
      } else {
        this.props.reInviteWlClient(row.rowObject);
      }
    }
  }

  handleDeleteCancellation() {
    this.setState({ userToDelete: null, showDeleteDialog: false });
  }

  handleDeleteConfirmation() {
    this.setState({ showDeleteDialog: false });
    this.props.deleteWlClient(this.state.userToDelete);
  }

  getClientDescription = client => {
    var description = "";

    if (isEmailValid(client.email) && isWlEmailPlaceholder(client.email) === false) {
      const subUserLength = client.subUser ? client.subUser.length : 0;
      description += `${client.email} ${subUserLength ? `+${subUserLength}` : ""}`;
    }
    return description;
  };

  getTableData() {
    const nameColumn = new TableColumnData(
      i18n.t("client"),
      i18n.t("client"),
      client => {
        return client.clientName;
      },
      client => {
        return (
          <ClientNameContainer>
            <ClientCellName>{client.clientName}</ClientCellName>
            <ClientDescription>{this.getClientDescription(client)}</ClientDescription>
          </ClientNameContainer>
        );
      },
      "string"
    );

    const aumColumn = new TableColumnData(
      i18n.t("aum"),
      i18n.t("aum"),
      client => {
        return client.portfolio[0]?.aumTotal;
      },
      client => {
        return shortFormatNumberWithCurrency(client.portfolio[0]?.aumTotal, this.props.wlDefaultCurrency, true, true);
      }
    );

    const investableColumn = new TableColumnData(
      i18n.t("investable"),
      i18n.t("investable"),
      client => {
        return client.portfolio[0]?.investibleTotal;
      },
      client => {
        return shortFormatNumberWithCurrency(
          client.portfolio[0]?.investibleTotal,
          this.props.wlDefaultCurrency,
          true,
          true
        );
      }
    );

    const netWorthColumn = new TableColumnData(
      i18n.t("networth"),
      i18n.t("networth"),
      client => {
        return client.portfolio[0]?.value;
      },
      client => {
        return shortFormatNumberWithCurrency(client.portfolio[0]?.value, this.props.wlDefaultCurrency, true, true);
      }
    );

    const archivedColumn = new TableColumnData(
      i18n.t("archived"),
      i18n.t("archived"),
      client => {
        return client.tsArchive;
      },
      client => {
        return getPastDateString(new Date(client.tsArchive * 1000));
      }
    );

    var columns = [nameColumn, aumColumn, investableColumn, netWorthColumn, archivedColumn];
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      columns = [nameColumn, aumColumn, archivedColumn];
    }

    const clients = this.props.clients;
    const rows = clients.map(
      client =>
        new TableRowData(client, row => {
          return [[contextMenuItemType.RESTORE], [contextMenuItemType.DELETE_PERMANENTLY]];
        })
    );
    return new TableData(columns, rows, archivedColumn.sortKey);
  }

  render() {
    const clients = this.props.clients;
    if (!clients === true || clients.length === 0) {
      if (!this.props.searchText === false) {
        return (
          <Container>
            <EmptyTableContainer>
              <NoSearchResultsDescription>{i18n.t("emptyClientSearchResults")}</NoSearchResultsDescription>
            </EmptyTableContainer>
          </Container>
        );
      }

      return (
        <Container>
          <EmptyTableContainer>
            <InviteClientButton onClick={this.props.onInviteButtonClick}>{i18n.t("inviteClient")}</InviteClientButton>
            <EmptyTableDescription>{i18n.t("emptyActiveClientsDescription")}</EmptyTableDescription>
          </EmptyTableContainer>
        </Container>
      );
    }

    const tableData = this.getTableData();
    const showDeleteDialog = this.state.showDeleteDialog;

    return (
      <Container>
        <ClientTable data={tableData} onContextMenuSelection={this.handleContextMenuSelection} />
        {showDeleteDialog === true && (
          <DeleteClientConfirmationDialog
            clientName={this.state.userToDelete.clientName}
            onNegativeButtonClick={this.handleDeleteCancellation}
            onPositiveButtonClick={this.handleDeleteConfirmation}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  wlDefaultCurrency: wlSiteDefaultCurrencySelector(state),
  siteConfig: siteConfigSelector(state)
});

const mapDispatchToProps = {
  deleteWlClient: deleteWlClient,
  reInviteWlClient: reInviteWlClient
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArchivedClientsComponent));
