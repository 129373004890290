import React from "react";
import styled from "styled-components";
import { getCountryNameFromISOCode, accountLinkingService, getAggregatorName } from "@kubera/common";
import i18n from "i18next";
import ImageWithPlaceholder from "components/inputs/ImageWithPlaceholder";
import defaultProviderIcon from "assets/images/default_provider_icon.svg";
import defaultCryptoProviderIcon from "assets/images/default_crypto_search_icon.png";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  min-height: 50px;
`;

const NoResults = styled.div`
  margin-left: 13px;
  margin-top: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
`;

const AlternateActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  margin-bottom: 18px;
  margin-right: 13px;
  margin-top: 18px;
  cursor: pointer;
`;

const AlternateActionTitle = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: ${props => props.theme.linkColor};
`;

const AlternateActionDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  white-space: pre-line;
`;

const ResultsContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 0px;
`;

const ResultItem = styled.div`
  display: flex;
  height: 46px;
  align-items: center;
  padding-left: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  background: ${props => (props.isFocused === true ? "rgba(196, 196, 196, 0.4)" : "auto")};
`;

const ProviderIcon = styled(ImageWithPlaceholder)`
  height: 34px;
  width: 34px;
  margin-right: 12px;
  object-fit: contain;
`;

const ProviderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ProviderName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ProviderSubtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
`;

const ProviderAggregator = styled.span`
  text-transform: capitalize;

  &::before {
    content: " • ";
  }
`;

class SearchProvidersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentlyFocusedItemIndex: -1 };
    this.handleProviderSelection = this.handleProviderSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handlePlaidConnectionClick = this.handlePlaidConnectionClick.bind(this);
    this.handleCryptoTickerClick = this.handleCryptoTickerClick.bind(this);

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentDidMount() {
    this.preloadIcons();
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  preloadIcons() {
    const icons = [defaultProviderIcon, defaultCryptoProviderIcon];

    for (const icon of icons) {
      const img = new Image();
      img.src = icon;
    }
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey === true)) {
      this.selectPreviousItem();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown" || (e.key === "Tab" && e.shiftKey === false)) {
      this.selectNextItem();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      if (this.state.currentlyFocusedItemIndex !== -1) {
        const provider = this.props.searchResults[this.state.currentlyFocusedItemIndex];
        this.handleProviderSelection(provider);
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  selectNextItem() {
    const items = this.props.searchResults;
    if (!items) {
      return;
    }

    var nextItemIndex = this.state.currentlyFocusedItemIndex + 1;
    while (nextItemIndex < items.length && items[nextItemIndex].disabled === true) {
      nextItemIndex += 1;
    }
    if (nextItemIndex >= items.length) {
      return;
    }
    this.setState({ ...this.state, currentlyFocusedItemIndex: nextItemIndex });
  }

  selectPreviousItem() {
    const items = this.props.searchResults;
    if (!items) {
      return;
    }

    var previousItemIndex = this.state.currentlyFocusedItemIndex - 1;

    while (previousItemIndex >= 0 && items[previousItemIndex].disabled === true) {
      previousItemIndex -= 1;
    }
    if (previousItemIndex < 0) {
      return;
    }
    this.setState({ ...this.state, currentlyFocusedItemIndex: previousItemIndex });
  }

  selectItem(index) {
    this.setState({ currentlyFocusedItemIndex: index });
  }

  handleProviderSelection(provider) {
    if (this.props.onClick) {
      this.props.onClick(provider);
    }
  }

  handlePlaidConnectionClick(e) {
    if (!this.props.onTryPlaidConnection === false) {
      this.props.onTryPlaidConnection();
    }
  }

  handleCryptoTickerClick(e) {
    if (!this.props.onTryCryptoTicker === false) {
      this.props.onTryCryptoTicker();
    }
  }

  getDefaultProviderIcon() {
    if (this.props.linkingService === accountLinkingService.ZABO) {
      return defaultCryptoProviderIcon;
    }
    return defaultProviderIcon;
  }

  render() {
    const results = this.props.searchResults;
    const currentlyFocusedItemIndex = this.state.currentlyFocusedItemIndex;
    const defaultIcon = this.getDefaultProviderIcon();
    const isPlaidSupported = this.props.isPlaidSupported;
    const emptyResults = !results === true || results.length === 0;
    const showAggregator = this.props.showAggregator;
    const isCrypto = this.props.isCrypto;
    const searchText = this.props.searchText;
    const isBackdoorForPlaid = !searchText === false && searchText.toLowerCase() === "goto:plaid";

    return (
      <Container className={this.props.className}>
        {emptyResults === false && (
          <ResultsContainer>
            {results.map((provider, index) => {
              return (
                <ResultItem
                  key={index}
                  isFocused={index === currentlyFocusedItemIndex}
                  onClick={() => {
                    this.handleProviderSelection(provider);
                  }}
                  onMouseEnter={() => {
                    this.selectItem(index);
                  }}
                >
                  <ProviderIcon
                    placeholder={defaultIcon}
                    imageUrl={process.env.PUBLIC_URL + "/images/provider_search_icons/" + provider.searchIcon}
                  />
                  <ProviderDetailsContainer>
                    <ProviderName>{provider.name}</ProviderName>
                    <ProviderSubtitle>
                      {!provider.subTitle === true
                        ? getCountryNameFromISOCode(provider.countryCode)
                        : provider.subTitle}
                      {showAggregator && (
                        <ProviderAggregator>{getAggregatorName(provider.linkType)}</ProviderAggregator>
                      )}
                    </ProviderSubtitle>
                  </ProviderDetailsContainer>
                </ResultItem>
              );
            })}
          </ResultsContainer>
        )}
        {emptyResults === true && isBackdoorForPlaid === false && (
          <NoResults>{i18n.t("linkAccount.emptyProviderResults")}</NoResults>
        )}
        {isBackdoorForPlaid === true && (
          <AlternateActionContainer onClick={this.handlePlaidConnectionClick}>
            <AlternateActionTitle>{i18n.t("linkAccount.plaidBackdoorTitle")}</AlternateActionTitle>
            <AlternateActionDescription>{i18n.t("linkAccount.plaidBackdoorDescription")}</AlternateActionDescription>
          </AlternateActionContainer>
        )}
        {emptyResults === true && isPlaidSupported === false && isCrypto === true && (
          <AlternateActionContainer onClick={this.handleCryptoTickerClick}>
            <AlternateActionTitle>{i18n.t("linkAccount.searchCryptoSuggestionTitle")}</AlternateActionTitle>
            <AlternateActionDescription>
              {i18n.t("linkAccount.searchCryptoSuggestionDescription")}
            </AlternateActionDescription>
          </AlternateActionContainer>
        )}
      </Container>
    );
  }
}

export default SearchProvidersComponent;
