import React, { Fragment } from "react";
import styled from "styled-components";
import i18n from "i18next";

import HomesTabsComponent from "./HomesTabsComponent";
import CarsTabsComponent from "./CarsTabsComponent";
import DomainsConnectComponent from "./DomainsConnectComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 20px;
  padding-bottom: 2px;
  user-select: none;
`;

const Tabs = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  color: ${props => (props.isDisabled ? "rgba(0, 0, 0, 0.50)" : "#000000")};
  cursor: ${props => (props.isDisabled ? "pointer" : "default")};
`;

const ProviderTitle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
`;

const ProviderLogo = styled.img`
  position: absolute;
  top: 1px;
  right: 0;
  height: ${props => props.height || "15px"};
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

class ExtrasComponentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: props.tab || 0,
      clickedTab: props.tab || 0,
      newCustodianId: null,
      nextValues: null
    };

    this.selectHomes = this.selectHomes.bind(this);
    this.selectCars = this.selectCars.bind(this);
    this.selectDomains = this.selectDomains.bind(this);
    this.onConfirmSave = this.onConfirmSave.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.setNextValues = this.setNextValues.bind(this);
  }

  selectHomes() {
    this.setState({
      clickedTab: 0
    });
  }

  selectCars() {
    this.setState({
      clickedTab: 1
    });
  }

  selectDomains() {
    this.setState({
      clickedTab: 2
    });
  }

  onConfirmSave() {
    this.setState({
      selectedTab: this.state.clickedTab
    });
  }

  onConfirmCancel() {
    this.setState({
      clickedTab: this.state.selectedTab
    });
  }

  setNextValues(nextValues) {
    this.setState({
      nextValues
    });
  }

  render() {
    return (
      <Container>
        <ProviderScreenContainer>
          <Title>
            <Tabs isDisabled={this.state.selectedTab !== 0} onClick={this.selectHomes}>
              {i18n.t("linkAccount.homesTitle")}
            </Tabs>
            <Tabs isDisabled={this.state.selectedTab !== 1} onClick={this.selectCars}>
              {i18n.t("linkAccount.carsTitle")}
            </Tabs>
            <Tabs isDisabled={this.state.selectedTab !== 2} onClick={this.selectDomains}>
              {i18n.t("linkAccount.domainsTitle")}
            </Tabs>
          </Title>
          {this.state.selectedTab === 0 && (
            <Fragment>
              <HomesTabsComponent
                custodianId={this.props.custodianId}
                clickedTab={this.state.clickedTab}
                nextValues={this.state.nextValues}
                onConfirmSave={this.onConfirmSave}
                onConfirmCancel={this.onConfirmCancel}
                setNextValues={this.setNextValues}
              />
            </Fragment>
          )}
          {this.state.selectedTab === 1 && (
            <Fragment>
              <CarsTabsComponent
                custodianId={this.props.custodianId}
                clickedTab={this.state.clickedTab}
                nextValues={this.state.nextValues}
                onConfirmSave={this.onConfirmSave}
                onConfirmCancel={this.onConfirmCancel}
                setNextValues={this.setNextValues}
              />
            </Fragment>
          )}
          {this.state.selectedTab === 2 && (
            <Fragment>
              <ProviderTitle>
                {i18n.t("linkAccount.domainsProviderTitle")}
                <ProviderLogo
                  src={process.env.PUBLIC_URL + "/images/provider_logo_icons/estibot.png"}
                  alt="estibot"
                  height="19px"
                />
              </ProviderTitle>
              <DomainsConnectComponent
                searchInputPlaceholder={i18n.t("linkAccount.searchDomainsPlaceholder")}
                custodianId={this.props.custodianId}
                clickedTab={this.state.clickedTab}
                nextValues={this.state.nextValues}
                onConfirmSave={this.onConfirmSave}
                onConfirmCancel={this.onConfirmCancel}
                setNextValues={this.setNextValues}
              />
            </Fragment>
          )}
        </ProviderScreenContainer>
      </Container>
    );
  }
}

export default ExtrasComponentOptions;
