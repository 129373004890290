import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import TextInput from "components/inputs/TextInput";
import DOMPurify from "dompurify";

const GenericDialog = styled(Dialog)`
  width: 550px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 163px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 50px 60px 50px 60px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size:22px};
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const Description = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  margin-top: 5px;
`;

const ConfirmationInputTitle = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const ConfirmationInput = styled(TextInput)`
  height: 50px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: transparent;
  margin-top: 10px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  outline: 0;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const ErrorMessage = styled.div`
  height: 15px;
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

const PositiveButton = styled(PrimaryButton)`
  margin-right: 20px;
`;

const NegativeButton = styled(SecondaryButton)``;

class DeletePortfolioConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationInput: "",
      errorMessage: ""
    };

    this.handleConfirmationInputchange = this.handleConfirmationInputchange.bind(this);
    this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleConfirmationInputchange(e) {
    this.setState({ confirmationInput: e.target.value, errorMessage: "" });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handlePositiveButtonClick();
      return true;
    }
  }

  handlePositiveButtonClick() {
    if (this.state.confirmationInput.trim() === this.props.clientName) {
      this.props.onPositiveButtonClick();
    } else {
      this.setState({ errorMessage: i18n.t("deleteClientDialog.invalidInputError") });
    }
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onNegativeButtonClick}>
        <GenericDialog className={this.props.className}>
          <Container>
            <Title>{i18n.t("deleteClientDialog.title")}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  i18n.t("deleteClientDialog.description").replace("%s%", this.props.clientName)
                )
              }}
            />
            <ConfirmationInputTitle
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  i18n.t("deleteClientDialog.inputTitle").replace("%s%", this.props.clientName)
                )
              }}
            />
            <ConfirmationInput
              placeholder={i18n.t("deleteClientDialog.inputPlaceholder")}
              value={this.state.confirmationInput}
              onChange={this.handleConfirmationInputchange}
              onKeyDown={this.handleKeyDown}
              autoFocus
            />
            <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
            <ButtonContainer>
              <PositiveButton
                data-cy="genericPositive"
                onClick={this.handlePositiveButtonClick}
                title={i18n.t("deleteForever")}
                isDisabled={this.state.confirmationInput.trim() !== this.props.clientName}
                isDestructive={true}
              />
              <NegativeButton
                data-cy="genericNegative"
                onClick={this.props.onNegativeButtonClick}
                title={i18n.t("goBackLink")}
              />
            </ButtonContainer>
          </Container>
        </GenericDialog>
      </DialogOverlay>
    );
  }
}

export default withRouter(DeletePortfolioConfirmationDialog);
