var isEventManagerConfigured = false;
var keyDownEventListeners = [];
var contextMenuEventListeners = [];
var windowResizeEventListeners = [];

export const configureEventManager = () => {
  if (isEventManagerConfigured === true) {
    return;
  }
  // The timeout is needed to ensure that this listener gets attached after
  // all the other listeners for things like input cells are attached so
  // that they get the first chance to be fired.
  setTimeout(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("contextmenu", handleContextMenu, false);
    window.addEventListener("resize", handleWindowResize, true);
  }, 500);
  isEventManagerConfigured = true;
};

const handleKeyDown = e => {
  if (keyDownEventListeners.length === 0) {
    return;
  }

  for (var i = keyDownEventListeners.length - 1; i >= 0; --i) {
    const currentListener = keyDownEventListeners[i];
    if (currentListener(e) === true) {
      break;
    }
  }
};

export const addKeyboardEventListener = listener => {
  if (keyDownEventListeners.indexOf(listener) === -1) {
    keyDownEventListeners.push(listener);
  }
};

export const removeKeyboardEventListener = listener => {
  const listenerIndex = keyDownEventListeners.indexOf(listener);
  if (listenerIndex === -1) {
    return;
  }
  keyDownEventListeners.splice(listenerIndex, 1);
};

const handleContextMenu = e => {
  if (contextMenuEventListeners.length === 0) {
    return;
  }

  for (var i = contextMenuEventListeners.length - 1; i >= 0; --i) {
    const currentListener = contextMenuEventListeners[i];
    currentListener(e);
  }
};

export const addContextMenuEventListener = listener => {
  if (contextMenuEventListeners.indexOf(listener) === -1) {
    contextMenuEventListeners.push(listener);
  }
};

export const removeContextMenuEventListener = listener => {
  const listenerIndex = contextMenuEventListeners.indexOf(listener);
  if (listenerIndex === -1) {
    return;
  }
  contextMenuEventListeners.splice(listenerIndex, 1);
};

const handleWindowResize = e => {
  if (windowResizeEventListeners.length === 0) {
    return;
  }

  for (var i = windowResizeEventListeners.length - 1; i >= 0; --i) {
    const currentListener = windowResizeEventListeners[i];
    currentListener(e);
  }
};

export const addWindowResizeEventListener = listener => {
  if (windowResizeEventListeners.indexOf(listener) === -1) {
    windowResizeEventListeners.push(listener);
  }
};

export const removeWindowResizeEventListener = listener => {
  const listenerIndex = windowResizeEventListeners.indexOf(listener);
  if (listenerIndex === -1) {
    return;
  }
  windowResizeEventListeners.splice(listenerIndex, 1);
};
