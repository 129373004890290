import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { isMobile } from "@kubera/common";

import { ReactComponent as CloseIcon } from "assets/images/close.svg";

const isMobileDevice = isMobile();

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #ffe600;
  width: 100%;
  min-height: 50px;
  z-index: 10000;
`;

const BannerContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: ${isMobileDevice ? "-4px" : "15px"};
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  cursor: pointer;
`;

const CloseIconComponent = styled(CloseIcon)`
  width: ${isMobileDevice ? "13px" : null};
  height: ${isMobileDevice ? "13px" : null};
  color: #000;
`;

const BannerComponent = props => {
  const bannerContent = (
    <Banner className={props.className}>
      <BannerContent>
        {props.children}
        <CloseBtn onClick={props.onClose}>
          <CloseIconComponent />
        </CloseBtn>
      </BannerContent>
    </Banner>
  );

  return ReactDOM.createPortal(bannerContent, document.querySelector("body"));
};

export default BannerComponent;
