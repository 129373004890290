import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { userFirstNameSelector, getCapitalizedStr, isAppInViewMode, isAmcUser } from "@kubera/common";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import i18n from "i18next";

const INITIAL_GREETING_SHOWN_KEY = "INITIAL_GREETING_SHOWN_KEY";

const messages = ["Hello", "Hej", "Salut", "Hola", "Hi", "Ciao", "Yassou", "Namaste", "Ahlan", "Olá", "Aloha"];
const languages = [
  i18n.t("english"),
  i18n.t("swedish"),
  i18n.t("french"),
  i18n.t("spanish"),
  i18n.t("english"),
  i18n.t("italian"),
  i18n.t("greek"),
  i18n.t("hindi"),
  i18n.t("arabic"),
  i18n.t("portuguese"),
  i18n.t("hawaiian")
];
const Greeting = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 28px;
  font-feature-settings: "ss01" on, "calt" off;
  position: relative;
`;

const Message = styled.span`
  letter-spacing: -0.055em;
`;

const HashContainer = styled.div`
  font-size: 12px;
  position: absolute;
  top: -5px;
  margin-left: 4px;
  display: inline;
`;

const Hash = styled.span`
  color: ${props => props.theme.linkColor};
  &:hover {
    cursor: help;
  }
  padding: 4px;
  margin-left: -4px;
`;

class NetWorthGreetingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toolTipRef = React.createRef();
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    [this.greeting, this.language] = this.getGreetingAndLanguage();
  }

  getGreetingAndLanguage() {
    const isInitialGreetingShown = localStorage.getItem(INITIAL_GREETING_SHOWN_KEY);
    if (isInitialGreetingShown !== "true") {
      localStorage.setItem(INITIAL_GREETING_SHOWN_KEY, true);
    }

    const index = isInitialGreetingShown ? Math.floor(Math.random() * messages.length) : 0;
    return [`${messages[index]}, ${getCapitalizedStr(this.props.userFirstName)}`, languages[index]];
  }

  handleMouseEnter() {
    if (this.language && this.language !== i18n.t("english")) {
      this.toolTipRef.current.show(
        i18n
          .t("greeting.explainLanguage")
          .replace("{userFirstName}", this.props.userFirstName)
          .replace("{language}", this.language),
        0,
        -5
      );
    }
  }

  handleMouseLeave() {
    if (this.language && this.language !== i18n.t("english")) {
      this.toolTipRef.current.dismiss();
    }
  }

  render() {
    if (isAppInViewMode() || this.props.isAmcUser === true) {
      return null;
    }

    return (
      <Greeting className={this.props.className}>
        <Message>{this.greeting}</Message>
        {this.language !== i18n.t("english") && (
          <HashContainer>
            <Hash onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} id="greeting_label">
              #
            </Hash>
            <ToolTip
              ref={this.toolTipRef}
              targetId={"greeting_label"}
              noArrowNoButton={true}
              align={toolTipAlignment.BOTTOM_RIGHT}
            />
          </HashContainer>
        )}
      </Greeting>
    );
  }
}

const mapStateToProps = state => ({
  userFirstName: userFirstNameSelector(state),
  isAmcUser: isAmcUser(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetWorthGreetingComponent);
