/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import i18n from "i18next";
import DoughnutChartComponent, { doughnutColors } from "components/charts/DoughnutChartComponent";
import {
  currentPortfolioSelector,
  currentPortfolioCurrencySelector,
  recapChartOptions,
  updateTargetForReportsCustodian,
  getRecommendationForReports,
  recapReportContentsDataSelector,
  recapReportContentsDoughnutDataSelector,
  parseParams,
  RECAP_CATEGORY_TYPE_NETWORTH,
  parseNumberStringToFloat,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import styled from "styled-components";
import { getGridDataFromReportData, getRecommendationCountFromGridData } from "components/grid/GridDataModel";
import { hashParams } from "routes";
import { useSelector, useDispatch } from "react-redux";

const TabName = "contents";

const Grid = styled(GridComponentWrapper)`
  width: 100%;
  margin-top: 32px;
`;

const DoughnutChart = styled(DoughnutChartComponent)`
  margin-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const NameCell = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right:0
  z-index: 1000;
  margin: 5px;
  padding: 10px 17px 10px 10px;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
`;

const Name = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: #000000;
  text-decoration:${props => (props.type === "header" || props.type === "sheetHeader" ? "underline" : "none")} 
  cursor: ${props => (props.type === "header" || props.type === "sheetHeader" ? "pointer" : "default")}
  width: 200px
  overflow:hidden
  white-space:nowrap
  text-overflow: ellipsis;
`;

const DoughnutChartLegend = styled.div`
  min-width: 14px;
  min-height: 14px;
  margin-right: 12px;
  background: ${props => props.color};
`;

const ContentsTabComponent = props => {
  const {
    dialogTheme,
    reportId,
    handleReportNameClick,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  } = props;
  const dispatch = useDispatch();
  const reportParams = parseParams(decodeURIComponent(reportId));
  const selectedChartOptions = reportParams[hashParams.CHART_OPTION];
  const contentsData = useSelector(state =>
    recapReportContentsDataSelector(
      state,
      reportId,
      isInvestableAssetsBySheetsAndSectionsChart,
      isInvestableAssetsBySectionsChart,
      isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
      isInvestableAssetsWithoutCashBySectionsChart,
      isAssetsBySectionsChart
    )
  );
  const contentsDoughnutData = useSelector(state =>
    recapReportContentsDoughnutDataSelector(state, reportId, contentsData)
  );
  const portfolio = useSelector(currentPortfolioSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);

  const getItemDoughnutChartColor = name => {
    var itemIndex = contentsDoughnutData.labels.findIndex(item => item === name);
    if (itemIndex === -1) {
      return doughnutColors[
        contentsDoughnutData.labels.findIndex(item => item === i18n.t("reportCharts.label.theRemaining"))
      ];
    }
    return doughnutColors[itemIndex];
  };

  const getAccessoryViewForNameCell = (name, type, sectionId, sheetId, category) => {
    return () => (
      <NameCell>
        <DoughnutChartLegend color={getItemDoughnutChartColor(name)} />
        <Name onClick={() => handleReportNameClick(name, type, sectionId, sheetId, category)} type={type}>
          {name}
        </Name>
      </NameCell>
    );
  };

  const [gridDataForReports, setGridDataForReports] = useState(
    contentsData
      ? getGridDataFromReportData(reportId, contentsData, portfolio, TabName, getAccessoryViewForNameCell)
      : {}
  );

  const [lastEditedRowIndex, setLastEditedRowIndex] = useState(-1);

  const handleRowUpdate = (sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) => {
    const newGridData = gridDataForReports;
    const rows = newGridData.sheets[sheetIndex].sections[sectionIndex].rows;
    var isTargetValueSetForAnyRow = false;
    for (const row of rows) {
      if (row.cells[4].hasUserNotSetATargetValue === false) {
        isTargetValueSetForAnyRow = true;
        break;
      }
    }

    const actualValue = updatedRow.cells[2].value;
    const targetValue =
      updatedRow.cells[4].value === "" && isTargetValueSetForAnyRow === false
        ? updatedRow.cells[2].value
        : updatedRow.cells[4].value;
    dispatch(
      updateTargetForReportsCustodian(
        reportId,
        TabName,
        updatedRow.clubbingKey === "id" ? updatedRow.id : updatedRow.cells[0].value,
        updatedRow.cells[4].value
      )
    );
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = updatedRow;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[3].actualValue = actualValue;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[3].targetValue = targetValue;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[4].hasUserNotSetATargetValue =
      actualValue === targetValue || targetValue === "";
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[4].value = targetValue;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[5].value = getRecommendationForReports(
      contentsData.value,
      updatedRow.cells[1].value,
      actualValue,
      targetValue,
      portfolioCurrency
    );
    // get recommendationCOunt
    newGridData.sheets[sheetIndex].sections[
      sectionIndex
    ].columns[5].recommendationCount = getRecommendationCountFromGridData(
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows
    );
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[6].actualValue = actualValue;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[6].targetValue = targetValue;

    if (isTargetValueSetForAnyRow) {
      var targetTotal = 0;
      var setTargetInvalidError = false;
      for (var i = 0; i < rows.length; i++) {
        const row = newGridData.sheets[sheetIndex].sections[sectionIndex].rows[i];
        if (row.cells[4].hasUserNotSetATargetValue === true) {
          row.cells[4].value = "";
        } else if (isNaN(parseNumberStringToFloat(row.cells[4].value)) === false) {
          targetTotal = targetTotal + parseNumberStringToFloat(row.cells[4].value);
          if (targetTotal > 100) {
            setTargetInvalidError = true;
          }
        }
        row.cells[4].hasError = setTargetInvalidError;
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[i] = row.clone();
      }
    }

    setGridDataForReports(newGridData);
    setLastEditedRowIndex(rowIndex);
  };

  const getNextRowIndexWithoutTargetValue = (sheetIndex, sectionIndex, rowIndex) => {
    const gridData = gridDataForReports;
    const rows = gridData.sheets[sheetIndex].sections[sectionIndex].rows;
    var nextIndex = -1;

    for (var i = rowIndex; i < rows.length; i++) {
      if (rows[i].cells[4].hasUserNotSetATargetValue === true) {
        nextIndex = i;
        break;
      }
    }
    if (nextIndex === -1) {
      for (i = rowIndex; i >= 0; i--) {
        if (rows[i].cells[4].hasUserNotSetATargetValue === true) {
          nextIndex = i;
          break;
        }
      }
    }
    return nextIndex === rowIndex ? -1 : nextIndex;
  };

  const handleChange = newGridData => {
    setGridDataForReports(newGridData);
  };

  const handleCellBlur = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
    if (cellIndex === 4 && lastEditedRowIndex === rowIndex) {
      const newGridData = gridDataForReports;
      const updatedRow = newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
      const updatedTargetValue = parseNumberStringToFloat(updatedRow.cells[4].value);
      if (isNaN(updatedTargetValue)) {
        return;
      }

      var currentTargetTotal = 0;
      for (const row of newGridData.sheets[sheetIndex].sections[sectionIndex].rows) {
        if (
          row.cells[4].hasUserNotSetATargetValue === false &&
          isNaN(parseNumberStringToFloat(row.cells[4].value)) === false
        ) {
          currentTargetTotal += parseNumberStringToFloat(row.cells[4].value);
        }
      }
      if (currentTargetTotal >= 100) {
        return;
      }

      const nextRowIndex = getNextRowIndexWithoutTargetValue(sheetIndex, sectionIndex, rowIndex);
      if (nextRowIndex !== -1) {
        const nextRow = newGridData.sheets[sheetIndex].sections[sectionIndex].rows[nextRowIndex].clone();
        nextRow.cells[4].value = formatNumberWithKuberaNumberFormatSettings(100 - currentTargetTotal);
        nextRow.cells[4].hasUserNotSetATargetValue = false;
        handleRowUpdate(sheetIndex, sectionIndex, nextRowIndex, nextRow, false, false);
      }
    }
  };

  const gridOptions = {
    getRowStyle: (row, rowIndex, sectionIndex, isLastRow) => {
      return {
        borderTop: `${
          rowIndex === 0 && sectionIndex === 0 ? `1px solid ${dialogTheme.gridSectionBorderColor}` : "none"
        } `,
        borderBottom: isLastRow ? "none" : `1px solid ${dialogTheme.gridSectionBorderColor}`,
        minHeight: "40px",
        display: "flex",
        flex: 1
        //boxSizing: "border-box"
      };
    },
    getSectionStyle: (section, isLastSection, totalSections) => {
      return {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        border: `1px solid ${dialogTheme.gridSectionBorderColor}`
      };
    }
  };

  const isNetWorthReport =
    selectedChartOptions === recapChartOptions.NETWORTH.id &&
    reportParams[hashParams.REPORT_NODE_ID] === RECAP_CATEGORY_TYPE_NETWORTH;
  const canShowGrid = contentsData && contentsData.contents.length > 1 && !isNetWorthReport;

  return (
    <Container>
      {contentsDoughnutData && (
        <DoughnutChart
          id="contents-tab-doughnut"
          hideTitle={true}
          chartData={contentsDoughnutData}
          chartWidth={497}
          chartHeight={280}
          containerWidth={750}
          shouldShowValue={false}
          hasBorder={false}
          hasBackgroundColor={false}
        />
      )}
      {canShowGrid && (
        <Grid
          gridData={gridDataForReports}
          onRowUpdate={handleRowUpdate}
          onChange={handleChange}
          gridOptions={gridOptions}
          onCellBlur={handleCellBlur}
        ></Grid>
      )}
    </Container>
  );
};
export default ContentsTabComponent;
