import React from "react";
import styled from "styled-components";
import { PaymentElement } from "@stripe/react-stripe-js";

import { ReactComponent as StripeLogo } from "assets/images/stripe_logo.svg";

const Wrapper = styled.div`
  display: inline-block;
`;

const CardContainer = styled.div`
  width: 100%;
  padding: 16px 5px 0;
  background: ${props => props.theme.accountCardElementCardContainerBG};
  box-sizing: border-box;
`;

const StripeBlk = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 0;
  margin-bottom: -10px;
`;

// const StripeLink = styled.a`
//   text-decoration: none;
// `;

const StripeLogoComponent = styled(StripeLogo)`
  position: relative;
  top: 1.45px;
  transform: scale(1.1);
  margin-left: 5px;
`;

const AccountPaymentElement = props => (
  <Wrapper className={props.className} data-private>
    <CardContainer>
      <PaymentElement options={props.options} />
    </CardContainer>
    <StripeBlk>
      {/* <StripeLink href="https://stripe.com/" target="_blank" rel="noopener noreferrer"> */}
      <StripeLogoComponent />
      {/* </StripeLink> */}
    </StripeBlk>
  </Wrapper>
);

export { PaymentElement };

export default AccountPaymentElement;
