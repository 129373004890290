import React from "react";
import styled, { keyframes, css } from "styled-components";
import i18n from "i18next";

const waitingMessages = [
  "Following the white rabbit...",
  "Taking the red pill...",
  "Learning kung fu...",
  "Bending the spoon...",
  "Meeting Oracle...",
  "Making cookies...",
  "Social distancing...",
  "Freeing minds...",
  "Walking the path...",
  "Sensing déjà vu...",
  "Going back in...",
  "Fighting the agents...",
  "Dodging bullets...",
  "Doing the superman thing...",
  "Looking for the keymaker...",
  "Initializing Skynet library...",
  "Calculating inverse probability matrices...",
  "Counting backwards from Infinity...",
  "Waking up the minions...",
  "Charging Ozone layer...",
  "Looking for exact change...",
  "Initializing the initializer...",
  "Filling up the Falcon...",
  "Readying the boosters...",
  "Searching for the light switch...",
  "Watching netflix...er...I mean loading, yeah loading...",
  "Not panicking...",
  "Working very hard... Really!",
  "Waiting for winter to come...",
  "Finding Satoshi Nakamoto..."
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

const LoaderMessageAnimation = keyframes`
  0%   {opacity: 1;};
  75%  {opacity: 1;};
  87.5% {opacity: 0;};
  100% {opacity: 1;};
`;

const LoaderMessage = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  opacity: 1;
  animation: ${LoaderMessageAnimation} 4s 0s forwards infinite;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 9px;
`;

const ProgessAnimation = keyframes`
  0%   {width: 0%;};
  3%  {width: 30%;};
  20%  {width: 60%;};
  100% {width: 95%;};
`;

const Progress = styled.div`
  width: ${props => (props.progress ? props.progress + "%" : "0%")};
  height: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${props =>
    props.animate === true
      ? css`
          ${ProgessAnimation} linear 60s 0s forwards
        `
      : ""};
`;

const PlaceHolderAnimation = keyframes`
  0%   {opacity: 0;};
  100% {opacity: 1;};
`;

const PlaceHoldersContainer = styled.div`
  flex: 1;
`;

const PlaceHolderContainer = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  opacity: 0;
  animation: ${PlaceHolderAnimation} 1s ${props => props.delay}s forwards;
`;

const CheckboxPlaceHolder = styled.div`
  width: 15px;
  height: 15px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-right: 8px;
`;

const CheckboxLabelPlaceHolder = styled.div`
  width: ${props => props.width};
  height: 15px;
  background: rgba(0, 0, 0, 0.1);
`;

const HeadsUpMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

class FancyLoader extends React.Component {
  constructor(props) {
    super(props);

    this.loaderMessageRef = React.createRef();
  }

  componentDidMount() {
    // The timeouts are matched with the fade in and fade out
    // animation timings. So change those as well if you change
    // the timeouts below
    const changeLoaderMessage = () => {
      if (this.loaderMessageRef && this.loaderMessageRef.current) {
        const randomIndex = Math.floor(Math.random() * waitingMessages.length);
        this.loaderMessageRef.current.innerHTML = waitingMessages[randomIndex];
        setTimeout(() => {
          changeLoaderMessage();
        }, 4000);
      }
    };

    setTimeout(() => {
      changeLoaderMessage();
    }, 3500);
  }

  getPlaceHolders() {
    const placeHolderItemWidths = [40, 50, 60];

    return (
      <PlaceHoldersContainer>
        {placeHolderItemWidths.map((width, index) => (
          <PlaceHolderContainer key={index} delay={index * 0.5}>
            <CheckboxPlaceHolder />
            <CheckboxLabelPlaceHolder width={`${width}%`} />
          </PlaceHolderContainer>
        ))}
      </PlaceHoldersContainer>
    );
  }

  render() {
    return (
      <Container className={this.props.className}>
        <LoaderMessage ref={this.loaderMessageRef}>{this.props.initialMessage}</LoaderMessage>
        <ProgressBar>
          <Progress animate={true} />
        </ProgressBar>
        {this.getPlaceHolders()}
        <HeadsUpMessage>{i18n.t("linkAccount.selectAccountsHeadsUpMessage")}</HeadsUpMessage>
      </Container>
    );
  }
}

export default FancyLoader;
