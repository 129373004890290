import React, { useRef, useLayoutEffect, useState } from "react";
import styled from "styled-components";

import requestIdleCallback from "utilities/requestIdleCallback";
import requestAnimationFrame from "utilities/requestAnimationFrame";
import cancelIdleCallback from "utilities/cancelIdleCallback";
import cancelAnimationFrame from "utilities/cancelAnimationFrame";

const ScrollContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  pointer-events: none;
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;
  contain: layout;
  width: ${props => props.width};
  white-space: nowrap;
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex: 1;
  transform: ${props => (props.isAnimating ? "translateY(-100%)" : "translateY(0)")};
  transition: ${props => (props.isAnimating ? "transform 0.4s ease" : null)};
  position: relative;
  justify-content: flex-end;
`;

const ValueActualDisplayContaier = styled.div`
  overflow: hidden;
  color: ${props => (props.isEditing ? "transparent !important" : null)};
`;

const ValueHiddenDisplayContainer = styled.div`
  overflow: hidden;
  visibility: hidden;
  height: 1px;
  margin-bottom: -1px;
`;

const ValueDisplayContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
`;

const LabelUpdateHandler = ({
  className,
  value: displayValue,
  preventAnimation = false,
  isEditing = false,
  width = null
}) => {
  const valueAnimationId = useRef(null);
  const prevDisplayValue = useRef(displayValue);
  const isAnimatingRef = useRef(null);

  const [isAnimating, setIsAnimating] = useState(false);

  useLayoutEffect(() => {
    const cancel = valueAnimationId.current ? cancelAnimationFrame : cancelIdleCallback;
    const request = valueAnimationId.current ? requestAnimationFrame : requestIdleCallback;

    cancel(valueAnimationId.current);
    valueAnimationId.current = request(() => {
      const hasValueChanged =
        !preventAnimation && prevDisplayValue.current && prevDisplayValue.current !== displayValue ? true : false;

      setIsAnimating(hasValueChanged);

      if (hasValueChanged) {
        clearTimeout(isAnimatingRef.current);
        isAnimatingRef.current = setTimeout(() => {
          setIsAnimating(false);
        }, 380);
      }
      prevDisplayValue.current = displayValue;
    });

    return () => {
      cancel(valueAnimationId.current);
      clearTimeout(isAnimatingRef.current);
    };
  }, [displayValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ScrollContainer width={width}>
      <ChildrenContainer className="child-container" isAnimating={isAnimating}>
        <ValueActualDisplayContaier isEditing={isEditing} className={className}>
          <ValueHiddenDisplayContainer>{displayValue}</ValueHiddenDisplayContainer>
          {isAnimating ? prevDisplayValue.current : displayValue}
        </ValueActualDisplayContaier>
        {isAnimating && <ValueDisplayContainer className={className}>{displayValue}</ValueDisplayContainer>}
      </ChildrenContainer>
    </ScrollContainer>
  );
};

export default LabelUpdateHandler;
