import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PluginComponent from "./PluginComponent";

const PluginWrapper = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setPageLoading(true);
  }, [location.hash]);

  useEffect(() => {
    if (pageLoading) {
      setPageLoading(false);
    }
  }, [pageLoading]);

  if (pageLoading) return null;

  return <PluginComponent />;
};

export default PluginWrapper;
