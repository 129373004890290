import React from "react";
import Switch from "react-switch";
import { useTheme } from "theme";

class ToggleSwitch extends React.Component {
  render() {
    const { className, isChecked, onChange, ...other } = this.props;

    return (
      <Switch
        {...other}
        className={className}
        checked={isChecked}
        onChange={onChange}
        handleDiameter={16}
        height={10}
        width={28}
        boxShadow={"0px 2px 2px rgba(0, 0, 0, 0.25)"}
        onHandleColor={"#3576CB"}
        offHandleColor={this.props.disabled ? this.props.theme.toggleSwitchDisabledHandleColor : undefined}
        onColor={"#C5D3E4"}
        checkedIcon={false}
        uncheckedIcon={false}
      />
    );
  }
}

const ToggleSwitchWrapper = props => {
  const theme = useTheme();
  return <ToggleSwitch {...props} theme={theme} />;
};

export default ToggleSwitchWrapper;
