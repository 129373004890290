import React from "react";

import { category } from "components/dashboard/DashboardComponentExports";
import MobileWrapperComponent from "mobile_components/containers/MobileWrapperComponent";

const MobileDebtsWrapperComponent = props => {
  return <MobileWrapperComponent pageCategory={category.DEBT} {...props} />;
};

export default React.memo(MobileDebtsWrapperComponent);
