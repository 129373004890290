import React from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import { useTheme } from "theme";

const Container = styled.div`
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  transform: translate3d(0, 0, 0);
`;

class VerticalBarChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.chartRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.data !== nextProps.data;
  }

  handleMouseLeave() {
    if (this.props.onHoverOverSection) {
      this.props.onHoverOverSection(null);
    }
  }

  getOptions() {
    return {
      responsive: true,
      legend: {
        display: false
      },
      layout: {
        padding: this.props.chartPadding || { top: 0, left: 10, right: 10, bottom: 0 }
      },
      title: {
        display: false
      },
      animation: {
        duration: 0
      },
      tooltips: {
        enabled: false
      },
      hover: {
        animationDuration: 0
      },
      onHover: (e, element) => {
        if (this.props.onHoverOverSection && element.length > 0) {
          this.props.onHoverOverSection(element[0]._index);
        }
        e.target.style.cursor = element[0] ? "pointer" : "default";
      },
      onClick: (e, element) => {
        if (this.props.onClickOverSection && element.length > 0) {
          this.props.onClickOverSection(
            element[0]._index,
            this.chartRef.current.chartInstance.getElementAtEvent(e)[0]._datasetIndex
          );
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawTicks: false
            },
            ticks: {
              display: true,
              fontColor: this.props.theme.barChartLabelColor,
              fontSize: 8,
              fontFamily: "'Inter', sans-serif",
              fontStyle: "normal",
              padding: 5
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawTicks: false
            },
            ticks: {
              display: false,
              beginAtZero: true
            }
          }
        ]
      }
    };
  }

  getDataWithOptions(data) {
    const defaultOptions = {
      // backgroundColor: "#855CF828",
      // hoverBackgroundColor: "#855CF828",
      fill: "start"
    };
    const options = {};

    data.datasets = data.datasets.map(dataSet => {
      const temp = { ...dataSet, ...defaultOptions, ...options };
      return temp;
    });

    if (data.options) {
      data.datasets = data.datasets.map((dataSet, index) => {
        return { ...dataSet, ...data.options[index] };
      });
    }

    // This helps align bars to the left if there are fewer than max number of bars the chart
    // is expecting
    // const validLabelsCount = data.labels.length;
    // if (validLabelsCount < this.props.maxBars) {
    //   data.labels = [...data.labels, ...Array(this.props.maxBars - data.labels.length).fill("")];

    //   for (const dataset of data.datasets) {
    //     dataset.data = [...dataset.data, ...Array(this.props.maxBars - dataset.data.length).fill(0)];
    //     dataset.backgroundColor = dataset.data.map((value, index) =>
    //       index > validLabelsCount - 1 ? "transparent" : dataset.backgroundColor
    //     );
    //   }
    // }
    return data;
  }

  datasetKeyProvider() {
    return window.btoa(Math.random()).substring(0, 12);
  }

  render() {
    const { className, chartHeight, data, themeMode, onHoverOverSection, onClickOverSection, ...other } = this.props;
    if (!data === true) {
      return null;
    }

    const chartData = this.getDataWithOptions(data);
    return (
      <>
        <Container
          ref={this.containerRef}
          className={`chartjs-container ${className} ${themeMode}`}
          {...other}
          onMouseLeave={this.handleMouseLeave}
        >
          <Bar
            height={this.props.chartHeight}
            data={chartData}
            options={this.getOptions()}
            datasetKeyProvider={this.datasetKeyProvider}
            ref={this.chartRef}
          />
        </Container>
      </>
    );
  }
}

const VerticalBarChartComponentWrapper = props => {
  const theme = useTheme();

  return <VerticalBarChartComponent {...props} theme={theme} themeMode={theme.mode} />;
};

export default VerticalBarChartComponentWrapper;
