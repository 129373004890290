import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import { ReactComponent as SortDocumentsIcon } from "assets/images/sort_documents_icon.svg";
import { ReactComponent as PlusFileIcon } from "assets/images/plus.svg";
import { ReactComponent as DownloadFileIcon } from "assets/images/download_file_icon.svg";
import { ReactComponent as UploadFileIcon } from "assets/images/upload_file_icon.svg";
import FileUploadInput from "components/inputs/FileUploadInput";
import DocumentsGridComponent from "components/documents/DocumentsGridComponent";
import ContextMenu from "components/contextmenu/ContextMenu";
import { generateThumbnailForFile } from "utilities/FileUtils";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";

import EmptyDocuments from "./EmptyDocuments";

const Container = styled.div``;

const ActionButtonsContainer = styled.div`
  display: flex;
`;

const SortDocumentsButton = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.documentsButtonColor};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: left;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 3px;
  }

  path {
    fill: ${props => props.theme.documentsButtonColor};
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const DocumentActions = css`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${props => props.theme.documentsButtonColor};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: right;
  padding-left: 12px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 3px;
    height: 9px;
  }

  path {
    fill: ${props => props.theme.documentHeaderIconColor};
    fill-opacity: unset;
  }

  input {
    width: 1px;
    height: 1px;
    visibility: hidden;
  }
`;

const NewFolderButton = styled.button`
  ${DocumentActions};
`;

const UploadButtonWrapper = styled.button`
  ${DocumentActions};
  line-height: 15px;
`;

const UploadButton = styled(FileUploadInput)`
  ${DocumentActions};
  margin-left: 0;
  padding-left: 0;
`;

const DownloadAllButton = styled.button`
  ${DocumentActions};
  pointer-events: ${props => (props.isDocumentsEmpty ? "none" : null)};
  opacity: ${props => (props.isDocumentsEmpty ? "0.3" : null)};
`;

const Documents = styled(DocumentsGridComponent)`
  margin-top: ${props => (props.compactMode === true ? "21px" : "42px")};
  padding-bottom: ${props => (props.compactMode === true ? "0px" : "44px")};
`;

const EmptyDocumentsContainer = styled(EmptyDocuments)`
  margin-top: 21px;
  min-height: 320px;
`;

class DocumentsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSortOption: this.props.selectedSortOption || contextMenuItemType.DOCUMENT_SORT_LAST_UPLOADED
    };
    this.contextMenuRef = React.createRef();

    this.handleSortOptionsClick = this.handleSortOptionsClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  handleFileUpload(files) {
    if (this.props.onDocumentUpload) {
      for (const file of files) {
        generateThumbnailForFile(file, thumbnail => {
          this.props.onDocumentUpload(file, thumbnail);
        });
      }
    }
  }

  handleSortOptionsClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    var sortOptions = [
      contextMenuItemType.DOCUMENT_SORT,
      contextMenuItemType.DOCUMENT_SORT_LAST_UPLOADED,
      contextMenuItemType.DOCUMENT_SORT_ALPHA,
      contextMenuItemType.DOCUMENT_SORT_REVERSE_ALPHA
    ];

    for (const option of sortOptions) {
      if (option.id === this.state.selectedSortOption.id) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    }

    this.contextMenuRef.current.show(
      [sortOptions],
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      e.target
    );
  }

  handleContextMenuSelection(item) {
    if (this.props.handleSortPreference) {
      this.props.handleSortPreference(item);
    }

    this.setState({ ...this.state, selectedSortOption: item });
  }

  sortDocuments(documents) {
    const selectedSortOption = this.state.selectedSortOption;

    if (selectedSortOption.id === contextMenuItemType.DOCUMENT_SORT_LAST_UPLOADED.id) {
      documents.sort((a, b) => b.tsModified - a.tsModified);
    } else if (selectedSortOption.id === contextMenuItemType.DOCUMENT_SORT_ALPHA.id) {
      documents.sort((a, b) => a.name.localeCompare(b.name));
    } else if (selectedSortOption.id === contextMenuItemType.DOCUMENT_SORT_REVERSE_ALPHA.id) {
      documents.sort((a, b) => b.name.localeCompare(a.name));
    }
  }

  render() {
    const sortOption = this.state.selectedSortOption;
    const documents = this.props.documents;
    const hideDownloadAllButton = !this.props.hideDownloadAll === false || !this.props.isReadOnly === false;
    this.sortDocuments(documents);

    const isDocumentsEmpty = documents && documents.length === 0;

    return (
      <Container className={this.props.className}>
        <ActionButtonsContainer>
          <SortDocumentsButton onClick={this.handleSortOptionsClick}>
            {sortOption.label}
            <SortDocumentsIcon />
          </SortDocumentsButton>
          <Spacer />
          {!this.props.isReadOnly === true && (
            <>
              {this.props.isFolderUploadAllowed && (
                <NewFolderButton onClick={this.props.onUploadNewFolder}>
                  <PlusFileIcon />
                  New Folder
                </NewFolderButton>
              )}
              <UploadButtonWrapper>
                <UploadFileIcon />
                <UploadButton
                  data-cy="moreFileUpload"
                  label={i18n.t("documentsComponent.upload")}
                  multiple={true}
                  onChange={this.handleFileUpload}
                  disabled={this.props.isReadOnly === true}
                />
              </UploadButtonWrapper>
            </>
          )}
          {hideDownloadAllButton === false && (
            <DownloadAllButton onClick={this.props.onDownloadAllDocuments} isDocumentsEmpty={isDocumentsEmpty}>
              <DownloadFileIcon />
              {i18n.t("documentsComponent.downloadAll")}
            </DownloadAllButton>
          )}
        </ActionButtonsContainer>
        {isDocumentsEmpty ? (
          <EmptyDocumentsContainer onUploadAction={this.handleFileUpload} isReadOnly={this.props.isReadOnly === true} />
        ) : (
          <Documents
            isReadOnly={this.props.isReadOnly}
            isWlkReadOnly={this.props.isWlkReadOnly}
            maxItemsPerRow={this.props.maxItemsPerRow}
            documentCellSize={this.props.documentCellSize}
            compactMode={this.props.compactMode}
            documents={documents}
            onFileUpload={this.handleFileUpload}
            onDocumentRename={this.props.onDocumentRename}
            onDeleteDocuments={this.props.onDeleteDocuments}
            onDownloadDocuments={this.props.onDownloadDocuments}
            onRetryDocumentUpload={this.props.onRetryDocumentUpload}
            maskThumbnail={this.props.maskThumbnail}
            handleFolderClick={this.props.handleFolderClick}
          />
        )}
        <ContextMenu ref={this.contextMenuRef} onSelection={this.handleContextMenuSelection} />
      </Container>
    );
  }
}

export default DocumentsComponent;
