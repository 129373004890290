import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import GridRow from "components/grid/GridRow";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import ContextMenu from "components/contextmenu/ContextMenu";
import EditableLabel from "components/inputs/EditableLabel";
import expandableIndicatorIcon from "assets/images/expandable_indicator.svg";
import expandableIndicatorIconDark from "assets/images/expandable_indicator_dark.svg";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import { ReactComponent as ErrorIcon } from "assets/images/link_account_warning.svg";
import {
  GridRowData,
  GridCellData,
  CurrencyCellData,
  PercentageCellData,
  cellType,
  getCollapsedRowsForRecapSection
} from "components/grid/GridDataModel";
import GridHeaderRow from "components/grid/GridHeaderRow";
import GridFooterRow from "components/grid/GridFooterRow";
import {
  getPercentageChange,
  store,
  custodiansWithSameParentIdSelector,
  isCryptoCurrency,
  currentPortfolioSelector,
  getTickerUsingId,
  getExchangeRate
} from "@kubera/common";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PercentageLabel from "components/labels/PercentageLabel";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import getPrefixForDataCy from "components/grid/GridHelpers";
import GridRowsInViewPortWrapper from "components/in_viewport/GridRowsInViewPortWrapper";

const draggedTitleStyles = css`
  display: flex;
  min-height: 50px;
  align-items: center;
  background: ${props => props.theme.sectionMoveTitleWrapperBG};
  margin-bottom: 10px;
  border: 1px solid #d7d7d7;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  padding-left: 23px;
`;

const ExpandedContainer = styled.div`
  .expanded-title-container-isdragging {
    ${draggedTitleStyles}
  }

  .expanded-title-container {
    display: flex;
    align-items: center;
    padding-bottom: 3px;

    &[data-rbd-drag-handle-context-id] {
      cursor: pointer;
    }
  }
`;

const ExpandIcon = styled.div`
  width: 9px;
  height: 19px
  margin-right: 3px;
  background-color: transparent;
  background-image: url(${props =>
    props.theme.mode === "default" ? expandableIndicatorIcon : expandableIndicatorIconDark});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px 9px;
  transform: rotate(270deg);
`;

const ErrorWrapper = styled.div`
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorIconComponent = styled(ErrorIcon)`
  path {
    fill: ${props => props.theme.errorIconComponentCLR};
  }
`;

const Title = styled(EditableLabel)`
  color: ${props => props.theme.gridSectionTitleColor};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
`;

const SectionContextMenuButton = styled.div`
  position: relative;
  top: 1px;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  margin-bottom: 1px;
  margin-left: 2px;
  cursor: pointer;
  color: red;
  background-color: transparent
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0px 0px;
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)}); 

  .expanded-title-container:hover & {
    background-size: 9px 9px;
  }

  &:hover {
    background-color: ${props => props.theme.contextMenuButtonBackgroundColor};
  }

  background-size: ${props => (props.isMenuVisible ? "9px 9px" : "")};
  background-color: ${props => (props.isMenuVisible ? props.theme.contextMenuButtonBackgroundColor : "transparent")};
`;

const EntryList = styled.div`
  ${props => {
    const sectionStyles = props.sectionStyles;
    if (sectionStyles && sectionStyles.isSeparateStyle) {
      return css`
        ${sectionStyles}
      `;
    }
    return css`
      flex-direction: column;
      background-color: ${props => props.theme.gridEntryListBackgroundColor};
      border: 1px solid ${props => props.theme.gridSectionBorderColor};
      border-bottom: 0;
      margin-top: -3px;
      ${sectionStyles}

      @media print {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        background-color: #ffffff !important;
      }
    `;
  }}
`;

const Entries = styled.div`
  min-height: 40px;
`;

const CollapsedContainer = styled.div`
  .collapsed-title-container-isdragging {
    ${draggedTitleStyles}
  }

  .collapsed-title-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.gridSectionCollapsedDividerColor};

    &[data-rbd-drag-handle-context-id] {
      cursor: pointer;
    }
  }
`;

const CollapseIcon = styled(ExpandIcon)`
  transform: rotate(0deg);
`;

const CollapsedTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const CollapsedSummaryContainer = styled.div`
  display: flex;
`;

const CollapsedPercentageLabel = styled(PercentageLabel)`
  justify-content: center;
  flex-direction: column;
  text-align: right;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
  margin-left: 8px;
  position: relative;
  top: -2px;
  min-width: 45px;
  height: 16px;
`;

const CollapsedCurrencyText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
  margin-left: ${props => `${props.marginLeft}px`};
`;

const CollapsedCurrencyLabel = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => `${props.width}px`};
`;

const AddHistoryBtnWrapper = styled.div`
  padding: 17px 0 17px 10px;
  border-top: 1px solid ${props => props.theme.gridSectionBorderColor};
  background: ${props => props.theme.addHistoryBtnWrapperBG};
`;

const AddHistoryBtn = styled.span`
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const ShowPreviousEntries = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  padding-left: 15px;
  border-top: 1px solid ${props => props.theme.gridSectionBorderColor};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
  background: ${props => props.theme.gridSectionHeaderBackgroundColor};
`;

class GridSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleSectionTitleUpdate = this.handleSectionTitleUpdate.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleAddNewButtonClick = this.handleAddNewButtonClick.bind(this);
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
    this.handleContextMenuDismiss = this.handleContextMenuDismiss.bind(this);
    this.handleRemoveSectionDialogNegativeButtonClick = this.handleRemoveSectionDialogNegativeButtonClick.bind(this);
    this.handleRemoveSectionDialogPositiveButtonClick = this.handleRemoveSectionDialogPositiveButtonClick.bind(this);
    this.handleShowPreviousEntriesClick = this.handleShowPreviousEntriesClick.bind(this);
    this.handleRecapExpandOrCollapse = this.handleRecapExpandOrCollapse.bind(this);

    this.state = {
      isShowingContextMenu: false,
      showRemoveSectionDialog: false,
      removeDialogCount: 0,
      isDragDisabled: !(document.readyState === "complete")
    };

    this.titleRef = React.createRef();
    this.contextMenuRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);

    window.addEventListener("load", () => {
      this.setState({
        isDragDisabled: false
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleMenuButtonClick(e) {
    e.stopPropagation();
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    const menuItems = this.props.section.getContextMenuItems();

    for (const menuItem of menuItems.flat()) {
      if (menuItem.id === contextMenuItemType.REMOVE_SECTION.id) {
        menuItem.disabled = this.props.totalSections < 2;
      }
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height + 2,
      true,
      e.target
    );
    this.setState({ ...this.state, isShowingContextMenu: true });
  }

  handleTitleClick(e) {
    if (this.titleRef.current !== null && this.titleRef.current.isEditable() === true) {
      return;
    }
    const section = this.props.section;
    section.isCollapsed = !section.isCollapsed;
    this.props.onSectionUpdate(this.props.sheetIndex, this.props.sectionIndex, section);
  }

  getSectionNameForSheetsAndSectionsCollapsedState(section, rowIndex) {
    const selectedRow = section.rows[rowIndex];
    const selectedRowValue = selectedRow.cells[2].value;
    if (selectedRow.category === "sheetHeader") {
      return `${selectedRow.categoryKey}.${selectedRow.sheetId}`;
    } else if (selectedRow.category === "sectionHeader") {
      return `${selectedRow.categoryKey}.${selectedRow.sheetId}.${selectedRow.sectionId}`;
    } else if (selectedRow.category === "categoryHeader") {
      return selectedRowValue;
    }
  }

  handleRecapExpandOrCollapse(rowIndex, isSheetsAndSectionsReport, selectedChartOption) {
    const section = this.props.section;
    const sectionName = !isSheetsAndSectionsReport
      ? section.name
      : this.getSectionNameForSheetsAndSectionsCollapsedState(section, rowIndex);
    const collapsedState = isSheetsAndSectionsReport ? !section.rows[rowIndex].isCollapsed : !section.isCollapsed;
    section.isCollapsed = isSheetsAndSectionsReport ? false : !section.isCollapsed;
    section.rowIndexClickedOnRecapReports = rowIndex;
    if (isSheetsAndSectionsReport) {
      section.rows = getCollapsedRowsForRecapSection(
        this.props.section.rows,
        this.props.section.isSheetsAndSectionsReport,
        this.props.section.rowIndexClickedOnRecapReports
      );
    }
    this.props.onSectionUpdate(this.props.sheetIndex, this.props.sectionIndex, section);
    const recapReportPreferences = localStorage.getItem("recap_report_preferences");
    const portfolioId = currentPortfolioSelector(store.getState()).id;
    let updatedReportPreferences = {};
    if (!recapReportPreferences) {
      let recapReportPreferencesForPortfolio = {};
      recapReportPreferencesForPortfolio[selectedChartOption] = {
        ...recapReportPreferencesForPortfolio[selectedChartOption],
        [sectionName]: { isCollapsed: collapsedState }
      };
      updatedReportPreferences[portfolioId] = { ...recapReportPreferencesForPortfolio };
    } else {
      updatedReportPreferences = { ...JSON.parse(recapReportPreferences) };
      if (updatedReportPreferences[portfolioId] !== undefined) {
        updatedReportPreferences[portfolioId] = {
          ...updatedReportPreferences[portfolioId],
          [selectedChartOption]: {
            ...updatedReportPreferences[portfolioId][selectedChartOption],
            [sectionName]: { isCollapsed: collapsedState }
          }
        };
      } else {
        updatedReportPreferences[portfolioId] = {
          [selectedChartOption]: {
            [sectionName]: { isCollapsed: collapsedState }
          }
        };
      }
    }
    localStorage.setItem("recap_report_preferences", JSON.stringify(updatedReportPreferences));
  }

  handleSectionSort = (key, order) => {
    const section = this.props.section;
    section.columnSortKey = key;
    section.columnSortOrder = order;
    this.props.onSectionUpdate(this.props.sheetIndex, this.props.sectionIndex, section);
  };

  handleContextMenuDismiss() {
    this.setState({ ...this.state, isShowingContextMenu: false });
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.SECTION_ADD_ROW.id) {
      this.props.onAddNewRow(
        this.props.sheetIndex,
        this.props.sectionIndex,
        this.props.section.rows.length,
        undefined,
        true,
        true
      );
    } else if (item.id === contextMenuItemType.SECTION_ADD_SECTION.id) {
      this.props.onAddNewSection(this.props.sheetIndex, this.props.sectionIndex + 1);
    } else if (item.id === contextMenuItemType.RENAME.id) {
      this.renameTitle();
    } else if (item.id === contextMenuItemType.REMOVE_SECTION.id) {
      const rowsAffectedBySection = this.getTotalRowsToBeDeletedWithSection();
      if (rowsAffectedBySection > 1) {
        this.setState({ ...this.state, showRemoveSectionDialog: true, removeDialogCount: rowsAffectedBySection });
      } else {
        this.props.onRemoveSection(this.props.sheetIndex, this.props.sectionIndex);
      }
    }
    if (this.props.onSectionContextMenuSelection) {
      this.props.onSectionContextMenuSelection(this.props.sheetIndex, this.props.sectionIndex, item);
    }
  }

  handleSectionTitleUpdate(value) {
    const section = this.props.section;
    section.name = value;
    this.props.onSectionUpdate(this.props.sheetIndex, this.props.sectionIndex, section);
  }

  renameTitle() {
    if (this.titleRef.current !== null) {
      this.titleRef.current.edit();
    }
  }

  handleAddNewButtonClick(e) {
    const targetPosition = e.target.getBoundingClientRect();
    const menuItems = this.props.section.getFooterContextMenuItems();

    if (Array.isArray(menuItems) && menuItems.length > 0) {
      this.contextMenuRef.current.show(
        menuItems,
        targetPosition.left,
        targetPosition.top + targetPosition.height - 10,
        true,
        e.target
      );
    }

    if (this.props.onAddNewFooterButtonClick) {
      this.props.onAddNewFooterButtonClick(this.props.sheetIndex, this.props.sectionIndex);
    }
  }

  getHeaderRow() {
    var firstRow = this.props.section.rows[0];
    var cells = [];
    this.props.section.columns.forEach((column, index) => {
      if (!firstRow) {
        return;
      }

      const firstRowCell = firstRow.cells[index];
      const underlyingCellType = firstRow.cells[index].type;
      const type = column.isButton === true ? underlyingCellType : cellType.HEADER;
      const cell = new GridCellData(type, column.name, null);
      cell.padding = firstRowCell.padding;
      if (column.width) {
        cell.width = column.width;
      }
      if (column.hideTitleIfEmpty === true) {
        if (this.props.section.isColumnEmpty(this.props.sheetIndex, this.props.sectionIndex, index)) {
          cell.name = "";
        }
      }
      if (column.isRecommendationColumn) {
        cell.isRecommendationCell = true;
        cell.recommendationCount = column.recommendationCount;
      }
      cell.textAlignment = firstRow.cells[index].textAlignment;
      cells.push(cell);
    });

    return new GridRowData(
      null,
      null,
      "header-row-id-" + Math.random(),
      cells,
      firstRow ? firstRow.cellIndexToStretch : 0,
      false,
      () => {
        return true;
      }
    );
  }

  getFooterRow() {
    var firstRow = this.props.section.rows[0];
    var cells = [];

    this.props.section.columns.forEach((column, index) => {
      if (!firstRow) {
        return;
      }
      var cell;
      const columnType = firstRow.cells[index].type;
      const footerAccessoryView = column.getFooterAccessoryView(this.props.sheetIndex, this.props.sectionIndex, index);

      if (index === 0) {
        const menuItems = this.props.section.getFooterContextMenuItems();
        if (!Array.isArray(menuItems) && this.props.showAddNew === true && this.props.isEditable === true) {
          cell = new GridCellData(cellType.ADDNEW_MODAL, null, null);
        } else if (this.props.showAddNew === true && this.props.isEditable === true) {
          cell = new GridCellData(cellType.ADDNEW_BUTTON, null, null);
        } else {
          cell = new GridCellData(cellType.BLANKSPACE, null, null);
        }
      } else if (columnType === cellType.CURRENCY) {
        const total = this.props.section.getTotalForColumn(index, this.props.currency);
        cell = new CurrencyCellData(
          cellType.FOOTER_CURRENCY,
          column.name,
          total === 0 ? null : total,
          this.props.currency
        );
      } else if (columnType === cellType.PERCENTAGE) {
        const firstCellIndex = firstRow.cells[index].firstCellIndex;
        const secondCellIndex = firstRow.cells[index].secondCellIndex;
        var shouldShowPercentage = true;
        var percentage = null;

        if (firstCellIndex !== undefined && secondCellIndex !== undefined) {
          const firstCellTotal = this.props.section.getTotalForColumn(firstCellIndex, this.props.currency);
          const secondCellTotal = this.props.section.getTotalForColumn(secondCellIndex, this.props.currency);
          percentage = getPercentageChange(firstCellTotal, secondCellTotal);

          for (const row of this.props.section.rows) {
            const costCell = row.cells[this.props.section.columnIndexForCost];
            if (row.isComplete() === true && (costCell.value === null || costCell.value === undefined)) {
              shouldShowPercentage = false;
              break;
            }
          }
        } else {
          shouldShowPercentage = false;
        }

        if (percentage !== null && shouldShowPercentage === true) {
          cell = new PercentageCellData(
            cellType.FOOTER_PERCENTAGE,
            column.name,
            percentage,
            firstRow.cells[index].firstCellIndex,
            firstRow.cells[index].secondCellIndex
          );
        } else {
          cell = new GridCellData(cellType.BLANKSPACE, null, null);
          cell.getAccessoryView = () => {
            return footerAccessoryView;
          };
        }
      } else if (columnType === cellType.NUMBER) {
        cell = new GridCellData(cellType.BLANKSPACE, null, null);
      } else if (column.isButton === true) {
        cell = new GridCellData(firstRow.cells[index].type, null, null);
      } else {
        cell = new GridCellData(cellType.BLANKSPACE, column.name, null);
      }
      cells.push(cell);
    });

    return new GridRowData(
      null,
      null,
      "footer-row-id-" + Math.random(),
      cells,
      firstRow ? firstRow.cellIndexToStretch : 0,
      false,
      () => {
        return true;
      }
    );
  }

  get firstVisibleRowIndex() {
    return !this.props.section.hideRowsBeforeRowId === true
      ? 0
      : this.props.section.rows.findIndex(item => item.id === this.props.section.hideRowsBeforeRowId);
  }

  get categorySectionRows() {
    const { rowsRendered, visibleElementsInArray } = this.props;

    return this.props.section.rows.map((entry, index) => {
      const isWithinVisibleArea =
        rowsRendered + index + 1 <= visibleElementsInArray.current || this.props.section.disableRowWindowing === true;

      return (
        <GridRow
          gridId={this.props.gridId}
          sectionId={this.props.section.id}
          key={entry.id}
          portfolio={this.props.portfolio}
          entry={entry}
          isVisible={index >= this.firstVisibleRowIndex}
          columns={this.props.section.columns}
          columnIndexForValue={this.props.section.columnIndexForValue}
          columnIndexForCost={this.props.section.columnIndexForCost}
          currency={this.props.currency}
          entryIndex={index}
          sheetIndex={this.props.sheetIndex}
          sectionIndex={this.props.sectionIndex}
          isLastRow={index === this.props.section.rows.length - 1}
          lastEditableCellIndex={this.props.section.getLastSelectableColumnIndex(true)}
          onMoveToNextCell={this.props.onMoveToNextCell}
          onPaste={this.props.onPaste}
          onRowUpdate={this.handleRowUpdate}
          onAddNewRow={this.props.onAddNewRow}
          onRemoveRow={this.props.onRemoveRow}
          onDetailsClick={this.props.onDetailsClick}
          onOptionsClick={this.props.onOptionsClick}
          onLinkErrorClick={this.props.onLinkErrorClick}
          onUserLeavingCellEmpty={this.props.onUserLeavingCellEmpty}
          onRowContextMenuSelection={this.props.onRowContextMenuSelection}
          onCellBlur={this.props.onCellBlur}
          onCellInvalidTickerAdded={this.props.onCellInvalidTickerAdded}
          onRowClick={this.props.onRowClick}
          userPreferences={this.props.userPreferences}
          gridOptions={this.props.gridOptions}
          isPercentageChangeShown={this.props.isPercentageChangeShown}
          noOfColumns={this.props.section.columns.length - 1}
          onOpenChartsModalClick={this.props.onOpenChartsModalClick}
          isLastSection={this.props.isLastSection}
          handleRecapExpandOrCollapse={this.handleRecapExpandOrCollapse}
          category={this.props.category}
          sectionName={this.props.section.name}
          isDragEnabled={!this.state.isDragDisabled && entry.isDragEnabled}
          isWithinVisibleArea={isWithinVisibleArea}
        />
      );
    });
  }

  handleRemoveSectionDialogNegativeButtonClick(e) {
    this.setState({ ...this.state, showRemoveSectionDialog: false });
  }

  handleRemoveSectionDialogPositiveButtonClick(e) {
    this.props.onRemoveSection(this.props.sheetIndex, this.props.sectionIndex);
  }

  handleAddHistory = () => {
    this.props.onAddNewRow(this.props.sheetIndex, this.props.sectionIndex, this.props.section.getFirstEmptyRowIndex());
  };

  handleShowPreviousEntriesClick(e, firstVisibleIndex) {
    this.props.section.onShowPreviousClick(e, firstVisibleIndex);
  }

  getTotalRowsToBeDeletedWithSection = () => {
    const rows = this.props.section.rows;
    const rowsWithoutParent = rows.filter(row => row.isEmpty() === false && !row.parentId === true);
    var uniqueParentIds = new Set();
    for (const row of rows) {
      if (!row.parentId === false) {
        uniqueParentIds.add(row.parentId);
      }
    }

    var count = rowsWithoutParent.length;
    uniqueParentIds.forEach(parentId => {
      count += custodiansWithSameParentIdSelector(store.getState(), this.props.portfolio.id, parentId).length;
    });
    return count;
  };

  handleRowUpdate = (sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) => {
    this.props.onRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit);
  };

  get irrCash() {
    const irrDetails = this.irrDetails;
    if (!irrDetails === true) {
      return null;
    }

    const cashTicker = getTickerUsingId(irrDetails.all.cashTickerId);
    const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.portfolio.currency);
    const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
    const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

    return {
      totalCashIn,
      totalCashOut
    };
  }

  get irrDetails() {
    return this.props.section.rows.length === 1
      ? this.props.section.rows[0].getIrrDetails()
      : this.props.section.getIrrDetails();
  }

  render() {
    const isCollapsed = this.props.section.isCollapsed;
    const currency = this.props.currency;
    const showTitle = this.props.section.isRecapSection ? false : this.props.showTitle;
    const title = this.props.section.name;
    const headerRow = this.getHeaderRow();
    const footerRow = this.getFooterRow();
    const isSingleSection = this.props.totalSections === 1;
    const datacyPrefix = getPrefixForDataCy();
    const idSuffix = this.props.idSuffix || "";
    const sectionStyles =
      this.props.gridOptions !== undefined &&
      this.props.gridOptions.getSectionStyle(
        this.props.section,
        this.props.isLastSection,
        this.props.totalSections,
        this.props.sectionIndex
      );
    window.renderlog("Render: GridSection");
    if (isCollapsed) {
      if (this.props.section.isRecapSection) {
        const collapsedRows = getCollapsedRowsForRecapSection(
          this.props.section.rows,
          this.props.section.isSheetsAndSectionsReport,
          this.props.section.rowIndexClickedOnRecapReports
        );
        return (
          <Entries>
            {collapsedRows.map((entry, index) => {
              return (
                <GridRow
                  gridId={this.props.gridId}
                  sectionId={this.props.section.id}
                  key={entry.id}
                  portfolio={this.props.portfolio}
                  entry={entry}
                  isVisible={true}
                  columns={this.props.section.columns}
                  columnIndexForValue={this.props.section.columnIndexForValue}
                  columnIndexForCost={this.props.section.columnIndexForCost}
                  currency={this.props.currency}
                  entryIndex={index}
                  sheetIndex={this.props.sheetIndex}
                  sectionIndex={this.props.sectionIndex}
                  isLastRow={index === this.props.section.rows.length - 1}
                  lastEditableCellIndex={this.props.section.getLastSelectableColumnIndex(true)}
                  onMoveToNextCell={this.props.onMoveToNextCell}
                  onPaste={this.props.onPaste}
                  onRowUpdate={this.handleRowUpdate}
                  onAddNewRow={this.props.onAddNewRow}
                  onRemoveRow={this.props.onRemoveRow}
                  onDetailsClick={this.props.onDetailsClick}
                  onOptionsClick={this.props.onOptionsClick}
                  onLinkErrorClick={this.props.onLinkErrorClick}
                  onUserLeavingCellEmpty={this.props.onUserLeavingCellEmpty}
                  onRowContextMenuSelection={this.props.onRowContextMenuSelection}
                  onCellBlur={this.props.onCellBlur}
                  onCellInvalidTickerAdded={this.props.onCellInvalidTickerAdded}
                  onRowClick={this.props.onRowClick}
                  userPreferences={this.props.userPreferences}
                  gridOptions={this.props.gridOptions}
                  isPercentageChangeShown={this.props.isPercentageChangeShown}
                  noOfColumns={this.props.section.columns.length - 1}
                  onOpenChartsModalClick={this.props.onOpenChartsModalClick}
                  isLastSection={this.props.isLastSection}
                  handleRecapExpandOrCollapse={this.handleRecapExpandOrCollapse}
                  isCollapsed={isCollapsed}
                  sectionName={this.props.section.name}
                  isDragEnabled={entry.isDragEnabled}
                />
              );
            })}
          </Entries>
        );
      } else {
        const totalValue = this.props.section.getTotalValue(currency);
        const shouldShowError = this.props.section.shouldShowError();
        const isCostAndValueSame = this.props.section.columnIndexForCost === this.props.section.columnIndexForValue;

        return (
          <CollapsedContainer>
            <Draggable
              draggableId={`${this.props.section.id}${idSuffix}`}
              index={this.props.sectionIndex}
              isDragDisabled={this.state.isDragDisabled || this.props.isEditable === false}
            >
              {(provided, snapshot) => (
                <div
                  id={this.props.section.id}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  className={snapshot.isDragging ? "collapsed-title-container-isdragging" : "collapsed-title-container"}
                  onClick={this.handleTitleClick}
                >
                  <CollapsedTitleContainer>
                    {!snapshot.isDragging && (
                      <ExpandIcon
                        data-cy={
                          datacyPrefix + "SectionExpandIconH" + this.props.sheetIndex + "S" + this.props.sectionIndex
                        }
                      />
                    )}
                    <Title
                      type="text"
                      data-cy={datacyPrefix + "SectionTitleH" + this.props.sheetIndex + "S" + this.props.sectionIndex}
                      isCollapsible={isSingleSection === false}
                      isClickable={isSingleSection === false}
                      value={title}
                      onChange={this.handleSectionTitleUpdate}
                    />
                  </CollapsedTitleContainer>

                  {!snapshot.isDragging && (
                    <CollapsedSummaryContainer>
                      {shouldShowError && (
                        <ErrorWrapper>
                          <ErrorIconComponent />
                        </ErrorWrapper>
                      )}
                      {isCostAndValueSame === false && this.irrDetails && (
                        <>
                          {!!this.irrCash.totalCashIn && (
                            <>
                              <CollapsedCurrencyText marginLeft={20}>
                                {this.props.section.showCost ? i18n.t("cost") : i18n.t("in")}
                              </CollapsedCurrencyText>
                              <CollapsedCurrencyLabel
                                value={this.irrCash.totalCashIn}
                                currency={currency}
                                roundDown={!isCryptoCurrency(currency)}
                                maxLongFormatValue={999999}
                              />
                            </>
                          )}
                          {!!this.irrCash.totalCashOut && (
                            <>
                              <CollapsedCurrencyText marginLeft={8}>{i18n.t("out")}</CollapsedCurrencyText>
                              <CollapsedCurrencyLabel
                                value={this.irrCash.totalCashOut}
                                currency={currency}
                                roundDown={!isCryptoCurrency(currency)}
                                maxLongFormatValue={999999}
                              />
                            </>
                          )}
                          <CollapsedPercentageLabel
                            value={
                              this.irrDetails.all.value > -1 && this.irrDetails.all.value < 1
                                ? this.irrDetails.all.value.toFixed(2)
                                : Math.kuberaFloor(this.irrDetails.all.value)
                            }
                          />
                        </>
                      )}
                      {totalValue !== null && (
                        <CollapsedCurrencyLabel
                          value={
                            isCryptoCurrency(currency)
                              ? totalValue
                              : totalValue > 0 && totalValue < 1
                              ? totalValue
                              : Math.kuberaFloor(totalValue)
                          }
                          currency={currency}
                          width={120}
                        />
                      )}
                    </CollapsedSummaryContainer>
                  )}
                </div>
              )}
            </Draggable>
          </CollapsedContainer>
        );
      }
    } else {
      if (this.props.section.isSheetsAndSectionsReport && this.props.section.isRecapSection) {
        return (
          <Entries>
            {this.props.section.rows.map((entry, index) => {
              if (entry.shouldShow) {
                return (
                  <GridRow
                    gridId={this.props.gridId}
                    sectionId={this.props.section.id}
                    key={entry.id}
                    portfolio={this.props.portfolio}
                    entry={entry}
                    isVisible={true}
                    columns={this.props.section.columns}
                    columnIndexForValue={this.props.section.columnIndexForValue}
                    columnIndexForCost={this.props.section.columnIndexForCost}
                    currency={this.props.currency}
                    entryIndex={index}
                    sheetIndex={this.props.sheetIndex}
                    sectionIndex={this.props.sectionIndex}
                    isLastRow={index === this.props.section.rows.length - 1}
                    lastEditableCellIndex={this.props.section.getLastSelectableColumnIndex(true)}
                    onMoveToNextCell={this.props.onMoveToNextCell}
                    onPaste={this.props.onPaste}
                    onRowUpdate={this.handleRowUpdate}
                    onAddNewRow={this.props.onAddNewRow}
                    onRemoveRow={this.props.onRemoveRow}
                    onDetailsClick={this.props.onDetailsClick}
                    onOptionsClick={this.props.onOptionsClick}
                    onLinkErrorClick={this.props.onLinkErrorClick}
                    onUserLeavingCellEmpty={this.props.onUserLeavingCellEmpty}
                    onRowContextMenuSelection={this.props.onRowContextMenuSelection}
                    onCellBlur={this.props.onCellBlur}
                    onCellInvalidTickerAdded={this.props.onCellInvalidTickerAdded}
                    onRowClick={this.props.onRowClick}
                    userPreferences={this.props.userPreferences}
                    gridOptions={this.props.gridOptions}
                    isPercentageChangeShown={this.props.isPercentageChangeShown}
                    noOfColumns={this.props.section.columns.length - 1}
                    onOpenChartsModalClick={this.props.onOpenChartsModalClick}
                    isLastSection={this.props.isLastSection}
                    handleRecapExpandOrCollapse={this.handleRecapExpandOrCollapse}
                    isCollapsed={entry.isCollapsed}
                    sectionName={this.props.section.name}
                    isDragEnabled={entry.isDragEnabled}
                  />
                );
              }
              return null;
            })}
          </Entries>
        );
      } else {
        const showRemoveSectionDialog = this.state.showRemoveSectionDialog;

        return (
          <ExpandedContainer id={this.props.section.id}>
            {showTitle && (
              <Draggable
                draggableId={`${this.props.section.id}${idSuffix}`}
                index={this.props.sectionIndex}
                isDragDisabled={this.state.isDragDisabled || this.props.isEditable === false}
              >
                {(provided, snapshot) => (
                  <div
                    className={snapshot.isDragging ? "expanded-title-container-isdragging" : "expanded-title-container"}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    onClick={isSingleSection === true ? null : this.handleTitleClick}
                  >
                    {!snapshot.isDragging && isSingleSection === false && (
                      <CollapseIcon
                        data-cy={
                          datacyPrefix + "SectionCollapseIconH" + this.props.sheetIndex + "S" + this.props.sectionIndex
                        }
                      />
                    )}
                    <Title
                      type="text"
                      ref={this.titleRef}
                      value={title}
                      isClickable={isSingleSection === false}
                      onChange={this.handleSectionTitleUpdate}
                      data-cy={datacyPrefix + "SectionTitleH" + this.props.sheetIndex + "S" + this.props.sectionIndex}
                    />
                    {this.props.isEditable === true && (
                      <SectionContextMenuButton
                        id={"section_menu_button"}
                        data-cy={
                          datacyPrefix +
                          "SectionContextMenuButtonH" +
                          this.props.sheetIndex +
                          "S" +
                          this.props.sectionIndex
                        }
                        onClick={this.handleMenuButtonClick}
                        isMenuVisible={this.state.isShowingContextMenu}
                      />
                    )}
                  </div>
                )}
              </Draggable>
            )}
            <EntryList sectionStyles={sectionStyles}>
              <Droppable droppableId={this.props.section.id} type="SECTIONROWS">
                {provided => (
                  <Entries ref={provided.innerRef} {...provided.droppableProps}>
                    {this.props.section.showHeader === true && (
                      <GridHeaderRow
                        modifyCss={this.props.section.modifyCss || ""}
                        sectionId={this.props.section.id}
                        row={headerRow}
                        portfolio={this.props.portfolio}
                        userPreferences={this.props.userPreferences}
                        sectionRows={this.props.section.rows}
                        columnSortKey={this.props.section.columnSortKey}
                        columnSortOrder={this.props.section.columnSortOrder}
                        updateSortPreference={this.handleSectionSort}
                        recommendationCount={this.props.recommendationCount}
                      />
                    )}
                    {!this.props.section.hideRowsBeforeRowId === false && (
                      <ShowPreviousEntries
                        key={this.props.section.id + "-show-previous-entries"}
                        onClick={e => this.handleShowPreviousEntriesClick(e, this.firstVisibleRowIndex)}
                      >
                        {i18n.t("gridSection.showPrevious")}
                      </ShowPreviousEntries>
                    )}
                    <GridRowsInViewPortWrapper>{this.categorySectionRows}</GridRowsInViewPortWrapper>
                    {provided.placeholder}
                  </Entries>
                )}
              </Droppable>
              {this.props.addRowBtnTxt && (
                <AddHistoryBtnWrapper>
                  <AddHistoryBtn onClick={this.handleAddHistory}>{this.props.addRowBtnTxt}</AddHistoryBtn>
                </AddHistoryBtnWrapper>
              )}
              {this.props.section.showFooter === true && (
                <GridFooterRow
                  columns={this.props.section.columns}
                  sheetIndex={this.props.sheetIndex}
                  sectionIndex={this.props.sectionIndex}
                  row={footerRow}
                  onAddNewClick={this.handleAddNewButtonClick}
                  userPreferences={this.props.userPreferences}
                  category={this.props.category}
                />
              )}
            </EntryList>
            <ContextMenu
              ref={this.contextMenuRef}
              width={233}
              onSelection={this.handleContextMenuSelection}
              onDismiss={this.handleContextMenuDismiss}
            />
            {showRemoveSectionDialog === true && (
              <ConfirmationDialog
                title={i18n.t("removeRows").replace("%s1%", `${this.state.removeDialogCount}`) + "?"}
                description={i18n.t("removeSectionDialog.description")}
                positiveButtonTitle={i18n.t("removeRows").replace("%s1%", `${this.state.removeDialogCount}`)}
                negativeButtonTitle={i18n.t("cancel")}
                handleNegativeButtonClick={this.handleRemoveSectionDialogNegativeButtonClick}
                handlePositiveButtonClick={this.handleRemoveSectionDialogPositiveButtonClick}
              />
            )}
          </ExpandedContainer>
        );
      }
    }
  }
}

export default GridSection;
