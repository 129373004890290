import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { logOutFromAllDevices, userNameSelector, userEmailSelector } from "@kubera/common";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Container = styled.div`
  padding-bottom: 38px;
  margin-bottom: 26px;
  border-bottom: ${({ hideBorder }) => (!hideBorder ? "2px solid rgba(0, 0, 0, 0.1)" : null)};
`;
const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: 18px;
`;
const SignOutFromAllDevicesButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  cursor: pointer;
`;
const Description = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
`;

const SignOutFromAllDevicesComponent = props => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(null);
  const handleDialogNegativeButtonClick = e => {
    setIsConfirmModalVisible(false);
  };

  const handleDialogPositiveButtonClick = e => {
    props.logOutFromAllDevices(props.userName, props.userEmail, () => {
      setIsConfirmModalVisible(false);
    });
  };
  return (
    <Container hideBorder={props.hideBorder}>
      <Title>{i18n.t("accountSettings.signOutTitle")}</Title>
      <SignOutFromAllDevicesButton
        onClick={() => {
          setIsConfirmModalVisible(true);
        }}
      >
        {i18n.t("accountSettings.signOutFromAllDevicesButtonTitle")}
      </SignOutFromAllDevicesButton>
      <Description>{i18n.t("accountSettings.signOutFromAllDevicesDescription")}</Description>
      {isConfirmModalVisible && (
        <ConfirmationDialog
          title={i18n.t("accountSettings.signOutFromAllDevices.alert.title")}
          description={i18n.t("accountSettings.signOutFromAllDevices.alert.description")}
          positiveButtonTitle={i18n.t("accountSettings.signOutFromAllDevices.alert.primaryButton")}
          negativeButtonTitle={i18n.t("accountSettings.signOutFromAllDevices.alert.secondaryButton")}
          handleNegativeButtonClick={handleDialogNegativeButtonClick}
          handlePositiveButtonClick={handleDialogPositiveButtonClick}
        />
      )}
    </Container>
  );
};
const mapStateToProps = state => ({
  userName: userNameSelector(state),
  userEmail: userEmailSelector(state)
});

const mapDispatchToProps = {
  logOutFromAllDevices: logOutFromAllDevices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignOutFromAllDevicesComponent);
