import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  getSubscriptionEndDateString,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  accountRemainingDaysSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  blackRemainingOptinTrialDaysSelector,
  userSelector
} from "@kubera/common";

const BlackOptinTrialing = styled.div`
  font-weight: 400 !important;
  line-height: 19.5px;
  margin-bottom: -4px;
`;

const AccountTrialingStatusMessage = () => {
  const blackRemainingOptinTrialDays = useSelector(blackRemainingOptinTrialDaysSelector);
  const user = useSelector(userSelector);

  if (blackRemainingOptinTrialDays > 0) {
    return (
      <BlackOptinTrialing>
        {i18n
          .t("accountSettings.BlackOptinTrialingMsg")
          .replace("%date%", getSubscriptionEndDateString(user.tsExtendedTrialStart + 86400 * 13))
          .replace("%days%", blackRemainingOptinTrialDays)
          .replace("%s%", blackRemainingOptinTrialDays > 1 ? "s" : "")}
      </BlackOptinTrialing>
    );
  }

  return null;
};

const AccountStatusMessage = ({ className = "", isInitialSetup = false, isPriceOptionHidden = false }) => {
  const accountCurrentTs = useSelector(accountCurrentTsSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const remainingDays = useSelector(accountRemainingDaysSelector);
  const expiryDate = getSubscriptionEndDateString(accountEndTs);

  if (accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.subscriptionTrailingMobile").replace("%s%", expiryDate)}
      </span>
    );
  }

  const currentDate = getSubscriptionEndDateString(accountCurrentTs);
  const oneLessEndTs = getSubscriptionEndDateString(accountEndTs - 86400);

  let expiryDateString = `(${remainingDays === 1 ? "1 day" : `${remainingDays} days`})`;
  if (isInitialSetup) {
    return (
      <span className={className}>
        {i18n.t("initialSubscription.freeTrialTxt").replace("%s%", oneLessEndTs)} {expiryDateString}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  if (accountPlan === SUBSCRIPTION_PLANS.TRIAL && currentDate === oneLessEndTs) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.expiresTomorrow").replace("%s%", expiryDate)}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  if (
    accountPlan === SUBSCRIPTION_PLANS.TRIAL &&
    remainingDays === 0 &&
    accountSubscriptionStatus !== SUBSCRIPTION_STATUS.EXPIRED
  ) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.expiresToday").replace("%s%", expiryDate)}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  if (accountPlan === SUBSCRIPTION_PLANS.TRIAL && accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.subscriptionOnTrial").replace("%s%", oneLessEndTs)} {expiryDateString}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  if (accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.subscriptionSubscribedMobile").replace("%s%", oneLessEndTs)} {expiryDateString}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  if (
    [SUBSCRIPTION_STATUS.CANCELED, SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED].includes(accountSubscriptionStatus) &&
    remainingDays >= 0
  ) {
    return (
      <span className={className}>
        {i18n.t("accountSettings.subscriptionCanceled").replace("%s%", oneLessEndTs)} {expiryDateString}
        <AccountTrialingStatusMessage />
      </span>
    );
  }

  return null;
};

export default AccountStatusMessage;
