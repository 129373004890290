import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import { useDispatch } from "react-redux";

import {
  isMobile,
  uploadCSVDocument,
  uploadHistoryDocument,
  uploadAssetsDebtsDocument,
  uploadCurrentValueDocument
} from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import FileUploadInput from "components/inputs/FileUploadInput";
import DragDropInput from "components/inputs/DragDropInput";
import Loader from "components/loader/Loader";

const isMobileDevice = isMobile();

const ConditionalContainerStyled = isMobileDevice
  ? styled(DialogOverlay)`
      width: 100vw;
      height: 100vh;

      & > div {
        padding: 0 36px;
      }

      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const GenericDialog = styled(Dialog)`
  width: 612px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 130px;
`;

const Container = styled(DragDropInput)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${isMobileDevice ? "0" : "60px"};
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-bottom: 5px;
`;

const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  row-gap: 24px;
  margin-bottom: 10px;
`;

const UploadButton = styled(FileUploadInput)`
  display: flex;
  justify-content: center;
  width: 134px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: #ffffff;
  background: #000000;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin-bottom: 8px;
`;

const UploadHint = styled.div`
  font-size: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => (props.isDragging === true ? "100%" : "0px")};
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  height: 15px;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-wrap;
  margin-top: 144px;
`;

const VerifyLoader = styled(Loader)`
  height: 315px;

  &::after {
    content: "Verifying CSV File";
    color: #000;
    text-align: center;
    font-feature-settings: "ss01" on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 9px;
  }

  ${props =>
    props.isImporting &&
    css`
      &::after {
        content: ${({ i18nPrefix }) => i18n.t(`${i18nPrefix}.verifyLoaderTxt`)};
      }
    `};
`;

const ErrorMessage = styled.div`
  margin-top: -4px;
`;

const ErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
  padding: 14px;
  box-size: border-box;
  row-gap: 20px;
`;

const ErrorRow = styled.div`
  color: #000;
  font-family: Roboto Mono;
  font-size: 12px;
  font-style: normal;
  line-height: 150%;
  text-transform: uppercase;

  .title {
    font-weight: 700;
  }
`;

const SuccessTitle = styled.div`
  color: #000;
  font-feature-settings: "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 13px;
`;

const CloseAndDismissAction = styled.div`
  color: #0074fc;
  font-feature-settings: "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
`;

const GenericError = styled.p`
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
`;

const ImportCashFlowDialog = ({ className = "", type = "cashflow", onDismiss = () => {} }) => {
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [genericError, setGenericError] = useState(null);

  const { i18nPrefix, uploadDocumentFn } = useMemo(() => {
    switch (type) {
      case "history":
        return {
          i18nPrefix: "wlkImportHistory",
          uploadDocumentFn: uploadHistoryDocument
        };
      case "assetsdebts":
        return {
          i18nPrefix: "wlkImportAssetsDebts",
          uploadDocumentFn: uploadAssetsDebtsDocument
        };
      case "currentvalue":
        return {
          i18nPrefix: "wlkImportCurrentValue",
          uploadDocumentFn: uploadCurrentValueDocument
        };
      case "cashflow":
      default:
        return {
          i18nPrefix: "wlkImportCashFlow",
          uploadDocumentFn: uploadCSVDocument
        };
    }
  }, [type]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  const handleFileUpload = async files => {
    try {
      setGenericError(null);
      setIsVerifying(true);
      setErrorMessage(null);

      const file = files[0];
      const csvVerifyResponse = await dispatch(uploadDocumentFn(file)).catch(err => {
        setErrorMessage(JSON.parse(err.errorMessage));
      });

      if (csvVerifyResponse) {
        setIsImporting(true);

        await dispatch(uploadDocumentFn(file, true)).catch(err => {
          setErrorMessage(JSON.parse(err.errorMessage));
        });
      }

      setIsVerifying(false);
    } catch (err) {
      console.error(err);

      setTimeout(() => {
        setIsVerifying(false);
        setIsImporting(false);

        setGenericError(i18n.t("genericError"));
      }, 1000);
    }
  };

  const handleStartOver = e => {
    if (e.target.getAttribute("data-id") === "startOverAction") {
      setErrorMessage(null);
    }
  };

  return (
    <ConditionalContainerStyled onDismiss={onDismiss}>
      <GenericDialog className={className}>
        <Container onDragStart={handleDragStart} onDragStop={handleDragStop} onFileDrop={handleFileUpload}>
          <Title>{i18n.t(`${i18nPrefix}.dialogTitle`)}</Title>
          <Message>
            <div>{i18n.t(`${i18nPrefix}.dialogMessage1`)}</div>
            {!errorMessage ? (
              !isVerifying &&
              (!isImporting && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("wlkImport.dialogMessage2")
                  }}
                />
              ))
            ) : (
              <ErrorMessage
                onClick={handleStartOver}
                dangerouslySetInnerHTML={{
                  __html: i18n.t("wlkImport.dialogMessageError")
                }}
              />
            )}
          </Message>
          {!errorMessage ? (
            !isVerifying ? (
              isImporting ? (
                <>
                  <SuccessTitle>{i18n.t("wlkImport.dialogSuccessMessage")}</SuccessTitle>
                  <CloseAndDismissAction onClick={onDismiss}>
                    {i18n.t("wlkImport.closeAndDismissAction")}
                  </CloseAndDismissAction>
                </>
              ) : (
                <>
                  <UploadButton
                    data-cy="cashflowCsvUpload"
                    label={i18n.t("upload")}
                    multiple={false}
                    onChange={handleFileUpload}
                  />
                  <UploadHint>{i18n.t("wlkImport.dialogDragDropHint")}</UploadHint>
                  <DropAreaIndicator isDragging={isDragging}>
                    <DropAreaHint>{i18n.t("documentsComponent.dropFileHint")}</DropAreaHint>
                  </DropAreaIndicator>
                  <GenericError>{genericError}</GenericError>
                </>
              )
            ) : (
              <VerifyLoader isImporting={isImporting} i18nPrefix={i18nPrefix} />
            )
          ) : (
            <ErrorBlock>
              {errorMessage.map(eachError => (
                <ErrorRow key={eachError.line + eachError.client}>
                  <div className="title">
                    Row {eachError.line}: {eachError.message}
                  </div>
                  {eachError.client && `Client: ${eachError.client}`}
                  <br />
                  {eachError.item && eachError.label && `${eachError.label}: ${eachError.item}`}
                </ErrorRow>
              ))}
            </ErrorBlock>
          )}
        </Container>
      </GenericDialog>
    </ConditionalContainerStyled>
  );
};

export default ImportCashFlowDialog;
