import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { isPortfolioReadySelector, withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  planningTargetDateSelector,
  fetchPlanningData,
  currentPortfolioSelector,
  planningOptionsStringSelector,
  userAgeAtDate,
  getCustodianHistoryFormattedDateString,
  updateTargetDate,
  isPlanningIntroScreenShownForPortfolio,
  currentPortfolioCurrencySelector,
  parseKuberaDateString,
  getKuberaDateString,
  portfolioTotalForCategory,
  isAppInViewMode,
  getMonthAndYearFromDate,
  planningDataSelector,
  getDateForTimePeriod,
  timePeriods,
  getDefaultScenario,
  createPlanningScenario,
  planningReady,
  CASH_FORECAST_RELEASE_TS,
  userPreferencesSelector
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponentExports";
import ScenariosComponent from "./ScenariosComponent";
import PlanningChartComponent from "./PlanningChartComponent";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import TargetDateDialog from "./variable_dialogs/TargetDateDialog";
import PlanningIntroductionComponent from "./PlanningIntroductionComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PlanningTimestampsComponent from "./PlanningTimestampsComponent";
import { useTheme } from "theme";
import DobDialog from "./DobDialog";
import CashForecastComponent from "./CashForecastComponent";
import RedDot from "components/indicators/RedDot";

const viewModes = {
  TIMESTAMPS: "net worth projections",
  CASH_FORECAST: "cash forecast",
  CHARTS: "charts"
};

const Container = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 44px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ViewModeTabs = styled(Tabs)`
  margin-top: 14px;
  margin-bottom: 18px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ViewModeTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;

  @media print {
    display: none !important;
  }
`;

const ViewModeTabName = styled(Tab)`
  margin-right: 16px;
  padding-bottom: 5px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  opacity: ${props => (props.isselected === 1 ? "1" : "0.35")};
  border-bottom: ${props => (props.isselected === 1 ? "2px solid" : "none")};
  cursor: pointer;
`;

const ViewModeTabPanel = styled(TabPanel)`
  margin-top: 34px;
  display: none;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const ChartTabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const TargetDateContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  cursor: ${props => (props.isInViewMode ? "default" : "pointer")};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.03em;
  font-feature-settings: "ss01" on;
`;

const TargetDate = styled.div`
  font-feature-settings: "ss01" on;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -2.4px;
`;

const Age = styled.div`
  margin-left: 8px;
  opacity: 0.5;
  font-feature-settings: "ss01" on;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.96px;
  text-transform: uppercase;
`;

const TargetDateIcon = styled(DownArrowIcon)`
  width: 12px;
  height: 12px;
  margin-left: 7px;
  margin-bottom: 10px;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CashForecastRedDot = styled(RedDot)`
  position: static;
  display: inline-block;
  margin-left: 247px;
  width: 6px;
  height: 6px;
  background: "#FF6464";
`;

class PlanningComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModeIndex: 0,
      selectedScenarioIndex: 0,
      planningData: null,
      showTargetDateDialog: false,
      showDobDialog: false,
      age: null
    };

    this.handleViewModeChange = this.handleViewModeChange.bind(this);
    this.handleScenarioChange = this.handleScenarioChange.bind(this);
    this.handleTargetDateClick = this.handleTargetDateClick.bind(this);
    this.handleTargetDateChange = this.handleTargetDateChange.bind(this);
    this.handleTargetDateDialogOnDimiss = this.handleTargetDateDialogOnDimiss.bind(this);
    this.handleChartHover = this.handleChartHover.bind(this);

    this.cashForecastRedDotRef = React.createRef();
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  componentDidMount() {
    this.fetchData();
    if (
      !this.props.planningData ||
      !this.props.planningData.scenario ||
      this.props.planningData.scenario.length === 0
    ) {
      const defaultScenario = getDefaultScenario(0, `${this.props.portfolio.id}-default`);
      defaultScenario.rule = defaultScenario.rule.map(rule => {
        return {
          ...rule,
          scenarioId: defaultScenario.id,
          disabled: 0
        };
      });
      this.props.createPlanningScenario(defaultScenario);
    }
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.portfolio.id !== this.props.portfolio.id ||
      oldProps.portfolioCurrency !== this.props.portfolioCurrency ||
      oldProps.planningOptionsString !== this.props.planningOptionsString ||
      oldProps.portfolioTotalForAssets !== this.props.portfolioTotalForAssets ||
      oldProps.portfolioTotalForDebts !== this.props.portfolioTotalForDebts ||
      oldProps.planningTargetDate.getTime() !== this.props.planningTargetDate.getTime() ||
      oldProps.planningReady !== this.props.planningReady
    ) {
      this.fetchData();
    }

    if (oldProps.portfolio.id !== this.props.portfolio.id) {
      this.setState({ selectedScenarioIndex: 0 });
    }
  }

  fetchData() {
    const timestampDate = getDateForTimePeriod(timePeriods.TWO_DECADES);
    this.props.fetchPlanningData(
      undefined,
      data => {
        const targetDateGreater = timestampDate < this.props.planningTargetDate;
        const smallerDate = targetDateGreater ? timestampDate : this.props.planningTargetDate;
        const dataCopy = data.map(dataForScenario => {
          const result = window.kbStructuredClone(dataForScenario);
          const indexForTargetDate = dataForScenario.data.findIndex(
            item => item.date >= getKuberaDateString(smallerDate)
          );
          result.data = result.data.slice(0, indexForTargetDate + 1);
          return result;
        });
        this.setState({
          planningData: targetDateGreater ? data : dataCopy,
          timestampsData: targetDateGreater ? dataCopy : data
        });
      },
      timestampDate
    );
  }

  toggleDobDialog(show) {
    this.setState({ showDobDialog: show });
  }

  handleViewModeChange(newIndex) {
    this.setState({ viewModeIndex: newIndex });

    if (newIndex === 2 && this.props.userPreferences.isCashForecastTipShown === false) {
      this.cashForecastRedDotRef.current.setPreferenceFlag(true);
    }
  }

  handleTargetDateClick(e) {
    if (!this.isReadOnly()) {
      this.setState({ showTargetDateDialog: true });
    }
  }

  handleTargetDateChange(data) {
    if (data.age) {
      this.setState({ age: data.age });
    }
    this.props.updateTargetDate(parseKuberaDateString(data.date));
  }

  handleTargetDateDialogOnDimiss() {
    this.setState({ showTargetDateDialog: false });
  }

  handleScenarioChange(newIndex) {
    this.setState({ selectedScenarioIndex: newIndex });
  }

  getTargetDateDialogData() {
    return {
      date: getCustodianHistoryFormattedDateString(this.props.planningTargetDate),
      age: this.props.userAgeAtDate(this.props.planningTargetDate)
    };
  }

  handleChartHover(dataPoint) {
    this.setState({ dateHover: parseKuberaDateString(dataPoint?.date) });
  }

  getTabs(scenarioColorPalette, isInViewMode) {
    const currDate = this.state.dateHover || this.props.planningTargetDate;
    const currAge = this.props.userAgeAtDate(currDate);
    return [
      {
        index: 0,
        mode: viewModes.TIMESTAMPS,
        panelComponent: (
          <PlanningTimestampsComponent
            selectedScenarioIndex={this.state.selectedScenarioIndex}
            planningData={this.state.timestampsData}
            colorPallete={scenarioColorPalette}
          />
        )
      },
      {
        index: 1,
        mode: viewModes.CHARTS,
        panelComponent: (
          <ChartTabPanelContainer>
            <TargetDateContainer onClick={this.handleTargetDateClick} isInViewMode={isInViewMode}>
              <TargetDate>{getMonthAndYearFromDate(currDate)}</TargetDate>
              {!currAge === false && <Age>{`${i18n.t("age")} ${currAge}`}</Age>}
              {!isInViewMode && <TargetDateIcon />}
            </TargetDateContainer>
            <PlanningChartComponent
              selectedScenarioIndex={this.state.selectedScenarioIndex}
              planningData={this.state.planningData}
              colorPallete={scenarioColorPalette}
              handleChartHover={this.handleChartHover}
            />
          </ChartTabPanelContainer>
        )
      },
      {
        index: 2,
        mode: viewModes.CASH_FORECAST,
        panelComponent: (
          <CashForecastComponent
            selectedScenarioIndex={this.state.selectedScenarioIndex}
            planningData={this.state.timestampsData}
            colorPallete={scenarioColorPalette}
            onScenarioChange={this.handleScenarioChange}
          />
        )
      }
    ];
  }

  getScenerioColorPalette() {
    return [
      {
        color: "rgba(128,0,255,1)",
        lineChartBackgroundColor: "rgba(133,92,245,0.12)",
        barChartSecondaryColor: this.props.isDarkMode ? "rgba(128,0,255,0.6)" : "rgba(128,0,255,0.4)",
        barchartDefaultColor: this.props.isDarkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.2)"
      },
      {
        color: "rgba(255,0,136,1)",
        lineChartBackgroundColor: "rgba(255,0,136,0.12)",
        barChartSecondaryColor: this.props.isDarkMode ? "rgba(255,0,136,0.6)" : "rgba(255,0,136,0.4)",
        barchartDefaultColor: this.props.isDarkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.2)"
      },
      {
        color: "rgba(208,175,0,1)",
        lineChartBackgroundColor: "rgba(208,175,0,0.12)",
        barChartSecondaryColor: this.props.isDarkMode ? "rgba(208,175,0,0.6)" : "rgba(208,175,0,0.4)",
        barchartDefaultColor: this.props.isDarkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.2)"
      }
    ];
  }

  render() {
    const isInViewMode = this.isReadOnly();
    if ((this.props.isPlanningIntroScreenShown === false || !this.props.isPortfolioReady) && !isInViewMode) {
      return <PlanningIntroductionComponent />;
    }

    if (!this.state.planningData === true) {
      return null;
    }
    const scenarioColorPalette = this.getScenerioColorPalette();
    const tabs = this.getTabs(scenarioColorPalette, isInViewMode);
    return (
      <Container>
        <ContentContainer>
          <ViewModeTabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
            selectedIndex={this.state.viewModeIndex}
            onSelect={this.handleViewModeChange}
          >
            <CashForecastRedDot
              ref={this.cashForecastRedDotRef}
              preference="isCashForecastTipShown"
              showIfUserBeforeThisTs={CASH_FORECAST_RELEASE_TS}
            />
            <ViewModeTabList>
              {tabs.map((tab, index) => (
                <ViewModeTabName
                  key={index}
                  isselected={tab.index === this.state.viewModeIndex ? 1 : 0}
                  data-cy={`viewModeTabName${tab.mode}`}
                >
                  {tab.mode}
                </ViewModeTabName>
              ))}
            </ViewModeTabList>
            {tabs.map((tab, index) => (
              <ViewModeTabPanel key={`viewModeTabPanel${index}`} data-cy={`viewModeTabPanel${tab.mode}`}>
                {tab.panelComponent}
              </ViewModeTabPanel>
            ))}
          </ViewModeTabs>

          {this.state.viewModeIndex !== 2 && (
            <ScenariosComponent
              selectedScenarioIndex={this.state.selectedScenarioIndex}
              onScenarioChange={this.handleScenarioChange}
              planningData={this.state.viewModeIndex ? this.state.planningData : this.state.timestampsData}
              colorPallete={scenarioColorPalette}
            />
          )}
          {this.state.showTargetDateDialog === true && (
            <TargetDateDialog
              data={this.getTargetDateDialogData()}
              onVariableUpdate={this.handleTargetDateChange}
              onDismiss={this.handleTargetDateDialogOnDimiss}
              showDobDialog={() => this.toggleDobDialog(true)}
            />
          )}
          {this.state.showDobDialog === true && (
            <DobDialog
              onDismiss={() => this.toggleDobDialog(false)}
              data={this.getTargetDateDialogData()}
              age={this.state.age}
              onVariableUpdate={this.handleTargetDateChange}
            />
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  planningTargetDate: planningTargetDateSelector(state),
  planningData: planningDataSelector(state),
  planningOptionsString: planningOptionsStringSelector(state),
  portfolio: currentPortfolioSelector(state),
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  userAgeAtDate: date => userAgeAtDate(state, date),
  isPlanningIntroScreenShown: isPlanningIntroScreenShownForPortfolio(state, currentPortfolioSelector(state)),
  portfolioTotalForAssets: portfolioTotalForCategory(state, props.portfolio, category.ASSET),
  portfolioTotalForDebts: portfolioTotalForCategory(state, props.portfolio, category.DEBT),
  isPortfolioReady: isPortfolioReadySelector(state),
  planningReady: planningReady(state),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  fetchPlanningData: fetchPlanningData,
  updateTargetDate: updateTargetDate,
  createPlanningScenario: createPlanningScenario
};

const PlanningComponentWrapper = props => {
  const theme = useTheme();
  return <PlanningComponent {...props} isDarkMode={theme.mode === "dark"} />;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlanningComponentWrapper));
