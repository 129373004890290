import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  guessFutureDate,
  guessPastDate,
  updateUserPreferences,
  userDobSelector,
  getKuberaDateString,
  guessDate,
  parseKuberaDateString,
  wlClientContextSelector,
  isInCustodianHistoryFormat,
  isInvalidDob,
  formatMonthYearString,
  isDateString
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import NumberInput from "components/inputs/NumberInput";
import DateInput from "components/inputs/DateInput";

const DateDialog = styled(Dialog)`
  position: relative;
  width: 491px;
  height: 342px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 35px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const HeaderText = styled.div`
  font-feature-settings: "ss01" on;
  font-size: 18px;
  font-weight: 700;
  line-height: 130%;
`;

const ModeContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 11px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ModeTitle = styled.div`
  color: ${props => (props.isSelected ? "#000000" : "rgba(0, 0, 0, 0.35);")};
  cursor: pointer;
  font-feature-settings: "ss01" on;
  font-size: 22px;
  font-weight: 700;
`;

const FutureYearInput = styled(DateInput)`
  label {
    height: 53px;
    padding: 10px 20px 10px 13px;
  }
  input,
  input::placeholder {
    font-weight: 400;
    line-height: 150%;
    font-size: 22px;
    font-feature-settings: "ss01" on;
  }
`;

const TargetDateDescription = styled.div`
  color: ${props => (props.showText ? "rgba(0, 0, 0, 0.5)" : "transparent")};
  font-feature-settings: "ss01" on;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  margin-top: -10px;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgeInput = styled(NumberInput)`
  height: 31px;
  padding: 10px 20px 10px 13px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
  line-height: 150%;
  font-size: 22px;
  font-feature-settings: "ss01" on;
  font-weight: 400;
  color: ${props => (!props.hasError ? "inherit" : props.theme.errorCLR)};
  ::placeholder {
    font-weight: 400;
  }
`;

const GoButton = styled(PrimaryButton)`
  margin-top: 10px;
  height: 53px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  font-size: 15px;
  font-weight: 700;
  line-height: 110%;
  min-width: 110px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => (!props.hasError ? "transparent" : props.theme.errorCLR)};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

class TargetDateDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAgeMode: false,
      dateString: props.data && props.data.date ? formatMonthYearString(props.data.date) : null,
      age: props.data ? props.data.age : null,
      invalidAge: false,
      yearOnly: false,
      invalidMonthYear: false
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleAgeInputChange = this.handleAgeInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.allowPastDate = this.allowPastDate.bind(this);
    this.checkForInvalidMonthYear = this.checkForInvalidMonthYear.bind(this);
  }

  handleModeChange(isAgeMode) {
    if (isAgeMode === this.state.isAgeMode) {
      return;
    }
    this.setState({ isAgeMode: isAgeMode });
  }

  handleAgeInputChange(e, value) {
    this.setState({ invalidAge: false });
    this.setState({ age: value });
  }

  checkForInvalidMonthYear() {
    this.setState({ invalidMonthYear: this.state.dateString && guessFutureDate(this.state.dateString).isInvalid });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.isAgeMode === true || isInCustodianHistoryFormat(this.state.dateString)) {
        this.handleSaveClick();
      }
    }
  };

  handleSaveClick(e) {
    if (this.state.isAgeMode === true) {
      const age = this.state.age || 0;
      if (age < 0 || age > 120) {
        this.setState({ invalidAge: true });
        return;
      }
      const guessedDate = guessPastDate(this.props.userDob ? this.props.userDob : "");
      if (!isInvalidDob(guessedDate)) {
        const targetDate = parseKuberaDateString(guessedDate.dateString);
        targetDate.setFullYear(guessedDate.date.getFullYear() + this.state.age);
        const targetDateString = getKuberaDateString(targetDate.getTime());
        this.props.onVariableUpdate({ ...(this.props.data || {}), date: targetDateString, age: this.state.age });
      } else {
        this.props.onVariableUpdate({ ...(this.props.data || {}), age: this.state.age });
        this.props.showDobDialog();
      }
      this.props.onDismiss();
    } else {
      const guessedDate = this.allowPastDate()
        ? guessDate(this.state.dateString)
        : guessFutureDate(this.state.dateString);
      if (guessedDate.isInvalid === false) {
        this.props.onVariableUpdate({ ...(this.props.data || {}), date: guessedDate.dateString });
        this.props.onDismiss();
      }
    }
  }

  allowPastDate() {
    return (
      !this.props.data === false && !this.props.data.props === false && this.props.data.props.allowPastDate === true
    );
  }

  onDateChange(value = "") {
    this.setState({ invalidMonthYear: false });
    this.setState({ dateString: !isDateString(value) ? value : formatMonthYearString(value) });
  }

  render() {
    const canShowAgeOption = !this.props.wlClientContext === true;

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <DateDialog>
          <Container>
            <HeaderText>{i18n.t("fastForwardDateTitle")}</HeaderText>
            <ModeContainer>
              <ModeTitle isSelected={!this.state.isAgeMode} onClick={e => this.handleModeChange(false)}>
                {i18n.t("futureYear")}
              </ModeTitle>
              {canShowAgeOption === true && (
                <ModeTitle isSelected={this.state.isAgeMode} onClick={e => this.handleModeChange(true)}>
                  {i18n.t("futureAge")}
                </ModeTitle>
              )}
            </ModeContainer>
            {this.state.isAgeMode === false && (
              <FutureYearInput
                value={this.state.dateString}
                monthYearMode={true}
                allowPastDate={this.allowPastDate}
                onDateChange={this.onDateChange}
                onValidDate={this.handleSaveClick}
                onBlur={this.checkForInvalidMonthYear}
                errorMessage={i18n.t("enterFutureDate")}
                placeholder={i18n.t("date")}
                suggestionFontSize={"14px"}
              />
            )}
            {this.state.isAgeMode === true && (
              <AgeContainer>
                <AgeInput
                  placeholder={i18n.t("age")}
                  autoFocus={true}
                  value={this.state.age}
                  onChange={this.handleAgeInputChange}
                  onKeyDown={this.handleKeyDown}
                  hasError={this.state.age && this.state.invalidAge}
                />
                <ErrorMessage hasError={this.state.invalidAge}>{i18n.t("enterFutureAge")}</ErrorMessage>
              </AgeContainer>
            )}
            <TargetDateDescription
              dangerouslySetInnerHTML={{
                __html: i18n.t("targetDateDescription")
              }}
              showText={this.state.isAgeMode === false && !this.state.invalidMonthYear}
            />
            <GoButton title={i18n.t("go")} onClick={this.handleSaveClick} />
          </Container>
        </DateDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userDob: userDobSelector(state),
  wlClientContext: wlClientContextSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetDateDialog);
