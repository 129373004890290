import React from "react";
import styled, { css } from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import i18n from "i18next";
import firstTimeLinking from "assets/images/linking_first_time_icon.svg";

const Dialog = styled(GenericMessageDialog)`
  min-height: 629px;
  max-width: 612px;
  margin-top: 80px;
`;

const DescriptionStyle = css`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  margin-top: 8px;
  margin-bottom: 12px;
`;

const IconStyle = css`
  width: 100px;
  height: 100px;
  margin-top: -40px;
`;

class LinkAccountFirstTimeUserDialog extends React.Component {
  render() {
    return (
      <Dialog
        className={this.props.className}
        title={i18n.t("linkAccount.firstTimeLinkingDialogTitle")}
        icon={firstTimeLinking}
        iconStyle={IconStyle}
        description={i18n.t("linkAccount.firstTimeLinkingDialogDescription")}
        descriptionStyle={DescriptionStyle}
        positiveButtonTitle={i18n.t("okGotIt")}
        canUserDismiss={false}
        onPositiveButtonClick={this.props.onButtonClick}
      />
    );
  }
}

export default LinkAccountFirstTimeUserDialog;
