import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  withRouter,
  userSelector,
  sharedPortfolioUsersSelector,
  sharedPortfolioAccountTitleSelector,
  sharedPortfolioAccountSubtitleSelector,
  store,
  switchToSharedPortfolioUserAccount,
  getPortfolioSessionUserId,
  getUserProfilePhotoUrl,
  getSubscriptionEndDateString,
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountRemainingDaysSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  isAppInWhiteLabelMode,
  userTypes
} from "@kubera/common";
import { connect } from "react-redux";
import UserProfileImage from "components/inputs/UserProfileImage";

const SwitchDialog = styled(Dialog)`
  position: relative;
  width: 336px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isSelected ? "column" : "row")};
  align-items: center;
  padding: ${props => (props.isSelected ? "45px 30px 30px 30px" : "30px")};
  color: black;
  line-height: normal;
  text-align: left;
  border-top: ${props => (props.isFirst === true ? "" : "1px solid " + props.theme.contextMenuSectionSeparatorColor)};
  cursor: ${props => (props.isSelected ? "default" : "pointer")};

  &:hover {
    background-color: ${props => (props.isSelected ? "" : props.theme.contextMenuItemSelectedBackgroundColor)};
  }
`;

const ProfileImage = styled(UserProfileImage)`
  margin-right: ${props => (props.isSelected ? "0px" : "10px")};
  margin-bottom: ${props => (props.isSelected ? "14px" : "0px")};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isSelected ? "center" : "flex-start")};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: ${props => (props.isSelected ? "18px" : "16px")};
  letter-spacing: ${props => (props.isSelected ? "-0.9px" : "-0.8px")};
`;

const Description = styled.div`
  margin-top: ${props => (props.isSelected ? "2px" : "1px")};
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  text-align: ${props => (props.isSelected ? "center" : "left")};
  white-space: pre-line;
  line-height: 150%;
`;

class SwitchAccountComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profilePictureUrlUserMap: {}
    };

    this.profilePictureUrlUserMap = {};
    this.handleSharedPortfolioAccountClick = this.handleSharedPortfolioAccountClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  componentDidMount() {
    this.getProfilePicture(this.props.user.id);
    if (this.props.sharedPortfolioUsers.length > 0) {
      this.props.sharedPortfolioUsers.forEach(user => {
        this.getProfilePicture(user.id, user.picture);
      });
    }
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    } else {
      DialogOverlay.dismiss(this.props.history, this.props.location);
    }
  }

  getProfilePicture(userId, pictureToken = undefined) {
    this.props.getUserProfilePhotoUrl(
      url => {
        this.profilePictureUrlUserMap[userId] = url;
        this.setState({ profilePictureUrlUserMap: this.profilePictureUrlUserMap });
      },
      apiError => {},
      pictureToken
    );
  }

  handleSharedPortfolioAccountClick(user) {
    if (user.id === getPortfolioSessionUserId()) {
      return;
    }
    switchToSharedPortfolioUserAccount(user);
    this.handleOverlayDismiss();
  }

  getTrialLabel() {
    if (
      this.props.accountPlan === SUBSCRIPTION_PLANS.TRIAL &&
      this.props.accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE
    ) {
      const currentDate = getSubscriptionEndDateString(this.props.accountCurrentTs);
      const oneLessEndTs = getSubscriptionEndDateString(this.props.accountEndTs - 86400);
      const remainingDays = this.props.remainingDays;
      const isTodayTomorrow = remainingDays === 0 || currentDate === oneLessEndTs;
      const displayDate = isTodayTomorrow ? getSubscriptionEndDateString(this.props.accountEndTs) : oneLessEndTs;
      return i18n.t("accountSettings.subscriptionOnTrial").replace("%s%", displayDate);
    }
    return null;
  }

  render() {
    const user = this.props.user;
    var users = [
      ...(isAppInWhiteLabelMode() && this.props.user.wl?.type === userTypes.SUB_USER ? [] : [this.props.user]),
      ...this.props.sharedPortfolioUsers
    ];
    const portfolioUserId = getPortfolioSessionUserId();
    if (!portfolioUserId === false) {
      users = [users.find(item => item.id === portfolioUserId), ...users.filter(item => item.id !== portfolioUserId)];
    }
    users = users.filter(item => !item === false);

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <SwitchDialog>
          <Container>
            {users.map((item, index) => {
              const title = sharedPortfolioAccountTitleSelector(store.getState(), item);
              var subText = sharedPortfolioAccountSubtitleSelector(store.getState(), item);
              const isSelected = item.id === getPortfolioSessionUserId();
              const trialLabel = this.getTrialLabel();

              if (!trialLabel === false && isSelected === true && item.id === user.id) {
                subText += `\n${this.getTrialLabel()}`;
              }

              return (
                <UserDetailsContainer
                  key={index}
                  isSelected={isSelected}
                  isFirst={index === 0}
                  onClick={e => this.handleSharedPortfolioAccountClick(item)}
                >
                  <ProfileImage
                    isSelected={isSelected}
                    imageSize={isSelected ? 54 : 36}
                    userName={user.name}
                    imageUrl={this.state.profilePictureUrlUserMap[user.id]}
                    secondaryUserName={item.id === user.id ? null : item.wl?.clientName || item.name}
                    secondaryImageUrl={item.id === user.id ? null : this.state.profilePictureUrlUserMap[item.id]}
                  />
                  <Details isSelected={isSelected}>
                    <Title isSelected={isSelected}>{title}</Title>
                    <Description isSelected={isSelected}>{subText}</Description>
                  </Details>
                </UserDetailsContainer>
              );
            })}
          </Container>
        </SwitchDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector(state),
  sharedPortfolioUsers: sharedPortfolioUsersSelector(state),
  accountPlan: accountPlanSelector(state),
  accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
  accountCurrentTs: accountCurrentTsSelector(state),
  accountEndTs: accountEndTsSelector(state),
  remainingDays: accountRemainingDaysSelector(state)
});

const mapDispatchToProps = {
  getUserProfilePhotoUrl
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchAccountComponent));
