import React, { forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {
  userPreferencesSelector,
  updateUserPreferences,
  userSelector,
  PVST_RELEASE_TS,
  wlClientContextSelector
} from "@kubera/common";

const RedDot = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${props => props.theme.gridRedColorForLightBackground};
`;

// For now setting showIfUserBeforeThisTs to default to PVST_RELEASE_TS as this component is used for PVST flows only
const RedDotComponent = (
  { className = "", preference, childPreference, showIfUserBeforeThisTs = PVST_RELEASE_TS },
  ref
) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const wlClientContext = useSelector(wlClientContextSelector);
  const userPreferences = useSelector(userPreferencesSelector);

  useImperativeHandle(ref, () => ({
    setPreferenceFlag: (bool = true) => {
      if (childPreference) {
        dispatch(updateUserPreferences({ [preference]: { ...userPreferences[preference], [childPreference]: bool } }));
      } else {
        dispatch(updateUserPreferences({ [preference]: bool }));
      }
    }
  }));

  const userTsCreated = user?.tsCreated;
  if (
    !(userPreferences[preference] && childPreference
      ? userPreferences[preference][childPreference]
      : userPreferences[preference]) &&
    (!showIfUserBeforeThisTs || (userTsCreated && userTsCreated * 1000 < showIfUserBeforeThisTs))
  ) {
    return <RedDot className={className} />;
  }

  return null;
};

export default forwardRef(RedDotComponent);
