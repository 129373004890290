import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  guessDateInCustodianHistoryUIFormat,
  getTickerUsingId,
  tickerTypes,
  getSortKeyBetween,
  fetchTickerDetails,
  showToastTip,
  fundScheduleSelector,
  guessPastDate,
  updateFundSchedule,
  deleteFundSchedule,
  fundScheduleTypes,
  getUnfundedCommitmentForCustodian,
  moveFundScheduleToCashflow
} from "@kubera/common";
import { connect } from "react-redux";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import CurrencyLabel from "components/labels/CurrencyLabel";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 30px;

  .chartjs-buffer {
    margin-top: -3px;
  }
`;

const TotalContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalLabel = styled(CurrencyLabel)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const CommittedCapitalLabel = styled(CurrencyLabel)`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.6;
`;

const CommitedCapitalDescription = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.6;
`;

const GridContainer = styled.div`
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  position: relative;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const MoveToCashflowButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: right;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0074fc;
  text-transform: uppercase;
  cursor: pointer;
`;

class FundScheduleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleMoveToCashflow = this.handleMoveToCashflow.bind(this);

    this.props.fundSchedule.sort((a, b) => a.date.localeCompare(b.date));

    this.state = {
      gridData: this.getGridData(props.currency, this.props.fundSchedule)
    };
  }

  componentDidUpdate(oldProps) {
    if (this.state.gridData.currency !== this.props.currency) {
      this.setState({ gridData: this.getGridData(this.props.currency, this.props.fundSchedule) });
    }
  }

  getGridData(currency, fundSchedule) {
    var rows = [];
    let nextSortKey = null;
    for (const [, fundScheduleEntry] of fundSchedule.entries()) {
      if (fundScheduleEntry.value === undefined || fundScheduleEntry.value === null) {
        continue;
      }
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey, fundScheduleEntry);
      row.id = fundScheduleEntry.id;
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(fundScheduleEntry.date);
      row.cells[1].value = fundScheduleEntry.note;

      row.cells[2].value = fundScheduleEntry.value;
      if (fundScheduleEntry.valueTickerId) {
        row.cells[2].currency = getTickerUsingId(fundScheduleEntry.valueTickerId).shortName;
        row.cells[2].valueTickerId = fundScheduleEntry.valueTickerId;
      }

      row.tsModified = fundScheduleEntry.tsDate;
      rows.push(row);
    }

    rows.push(this.getEmptyRow(`${rows.length}`));

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const notesColumn = new GridColumnData("", true, this.props.isReadOnly === false, false);
    const valueColumn = new GridColumnData("Amount", true, this.props.isReadOnly === false, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, notesColumn, valueColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 1, 2, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    dateCell.textAlignment = "left";
    const notesCell = new GridCellData(cellType.TEXT, "Notes", null);
    notesCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex) => {
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
      const date = row.cells[0].value;
      const guessDate = guessPastDate(date);
      if (guessDate.isInvalid === false && row.isComplete() === true) {
        return (
          <MoveToCashflowButton onClick={() => this.handleMoveToCashflow(sheetIndex, sectionIndex, rowIndex)}>
            {i18n.t("moveToCashflow")}
          </MoveToCashflowButton>
        );
      }
      return null;
    };

    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Amount", null, this.props.currency);
    valueCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [dateCell, notesCell, valueCell, optionsCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      const date = cells[0].value;
      const value = cells[2].value;
      const guessedDate = guessDateInCustodianHistoryUIFormat(date, true);

      if (guessedDate.isInvalid === false && value !== null && value !== undefined) {
        return true;
      }
      return false;
    });
    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true) {
        return null;
      }
      const rows = this.state.gridData.sheets[0].sections[0].rows;
      if (rows.length === 1) {
        return [[contextMenuItemType.INSERT_ABOVE]];
      }
      return [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.DELETE]];
    };
    rowData.showHint = false;
    rowData.onUpdateDelay = 5000;
    return rowData;
  }

  handleMoveToCashflow(sheetIndex, sectionIndex, rowIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    this.props.moveFundScheduleToCashflow(
      this.props.type,
      this.props.custodian.id,
      row.id,
      () => {
        const newGridData = this.state.gridData;
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows.splice(rowIndex, 1);
        this.setState({ gridData: newGridData });
        this.props.showToastTip("TIP", i18n.t("addToCashflow"), null, 3000);
        this.props.scheduleUpdated();
      },
      errorMessage => {
        this.props.showToastTip("TIP", errorMessage, null, 3000);
      }
    );
  }

  handleChange(newGridData) {
    this.setState({ gridData: newGridData });
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      const rows = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows;
      if (rowIndex === rows.length - 1) {
        rows.push(this.getEmptyRow(`${rows.length}`));
        this.handleChange(this.state.gridData);
      }
      return;
    }
    this.handleFundScheduleUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow);
  }

  handleFundScheduleUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow) {
    const isNewHistoryEntry = updatedRow.tsModified === 0;
    if (isNewHistoryEntry === true) {
      updatedRow.tsModified = new Date().getTime() / 1000;
    }

    this.props.updateFundSchedule(isNewHistoryEntry, this.props.custodian.id, {
      id: updatedRow.id,
      date: updatedRow.cells[0].value,
      note: updatedRow.cells[1].value,
      value: updatedRow.cells[2].value,
      valueTickerId: updatedRow.cells[2].getTickerId(),
      type: this.props.type
    });
    this.props.scheduleUpdated();
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.DELETE.id) {
      this.props.deleteFundSchedule(this.props.custodian.id, row.id);
      this.props.scheduleUpdated();
    }
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue, true, true);
      var dateString = guessedDate.dateString;
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        row.cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        row.cells[0].invalidInputText = cellValue;
      } else {
        row.cells[0].invalidInputText = null;
      }

      row.cells[0].value = dateString;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
    }
  }

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      (row, index) => rowIndex !== index && row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      let invalidInputText = cell.invalidInputText;
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
      this.props.fetchTickerDetails(
        invalidInputText,
        new Date(),
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  render() {
    const gridData = this.state.gridData;

    return (
      <Container className={this.props.className}>
        <TotalContainer>
          <TotalLabel
            value={this.state.gridData.sheets[0].sections[0].getTotalForColumn(2, this.props.currency)}
            currency={this.props.currency}
            roundDown={true}
          />
          {this.props.type === fundScheduleTypes.CAPITAL_CALL && (
            <>
              <CommitedCapitalDescription>{"/"}</CommitedCapitalDescription>
              <CommittedCapitalLabel
                value={getUnfundedCommitmentForCustodian(this.props.custodian)}
                currency={this.props.currency}
                roundDown={true}
              />
              <CommitedCapitalDescription>{`(${i18n.t("unfunded")})`}</CommitedCapitalDescription>
            </>
          )}
        </TotalContainer>
        <GridContainer>
          <Grid
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onAddNewRow={this.handleAddNewRow}
            onCellBlur={this.handleCellBlur}
            onPaste={this.handleCellBlur}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            onRowContextMenuSelection={this.handleRowContextMenuSelection}
          />
        </GridContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  fundSchedule: fundScheduleSelector(state, props.type),
  getUnfundedCommitmentForCustodian: getUnfundedCommitmentForCustodian.bind(state)
});

const mapDispatchToProps = {
  fetchTickerDetails: fetchTickerDetails,
  showToastTip: showToastTip,
  updateFundSchedule: updateFundSchedule,
  deleteFundSchedule: deleteFundSchedule,
  moveFundScheduleToCashflow: moveFundScheduleToCashflow
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundScheduleComponent);
