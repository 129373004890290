import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { getExchangeRate, getTickerUsingId, isCryptoCurrency } from "@kubera/common";

import EditableLabel from "components/inputs/EditableLabel";
import GridSection from "components/grid/GridSection";
import GridHeaderRow from "components/grid/GridHeaderRow";
import { cellType, GridCellData, GridRowData, HEIGHT_OF_ASSET_GRID_ROW } from "./GridDataModel";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PercentageLabel from "components/labels/PercentageLabel";
import { category } from "components/dashboard/DashboardComponentExports";
import i18n from "locale/i18n";

const Container = styled.div``;

const FixedList = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.sectionMoveFixedListBG};
  z-index: 1000;
  display: ${props => (!props.sectionDragging ? "none" : "flex")}
  align-items: center;
  flex-direction: column;
  padding-left: ${props => (!props.isSidebarCollapsed ? "277px" : "115px")};
  padding-right: 55px;
  overflow-y: scroll;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;

  .fixed-section-wrapper {
    position: relative;
    width: 100%;
    min-width: 640px;
    max-width: 998px;
    margin-top: 192px;
    margin-bottom: 100px;
  }
`;

const TitleDottedPlaceholderWrapper = styled.div`
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  display: ${props => (!props.isOverValidDestination ? "none" : null)};
`;

const TitleDottedPlaceholder = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: 1px dashed #b8b8b8;
  background: ${props => props.theme.sectionMoveBackgroundColor};
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background: ${props => props.theme.sectionMoveTitleWrapperBG};
  border: 1px solid #d7d7d7;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  z-index: 1;
  transform: ${props => (props.transform ? "translate(0px, 70px) !important" : null)};
  padding-left: 23px;
  box-sizing: border-box;
`;

const Title = styled(EditableLabel)`
  color: ${props => props.theme.gridSectionTitleColor};
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  margin-bottom: 3px;
  background-color: transparent;
`;

const IrrRow = styled.div`
  display: flex;
  height: 26px;
  margin-top: ${props => !props.isLastSectionCollapsed && "16px"};
  padding-top: 10px;
  border-top: ${props => props.isLastSectionCollapsed && `1px solid ${props.theme.gridSectionBorderColor}`};
  border-bottom: 2px solid ${props => props.theme.gridSectionBorderColor};
  margin-bottom: -6px;
`;

const IrrNameColumn = styled.div`
  color: ${props => props.theme.gridCellColor};
  font-size: 13px;
  font-weight: 700;
  width: 100%;
`;

const IrrTotalValue = styled(CurrencyLabel)`
  color: ${props => props.theme.gridCellColor};
  margin-left: 8px;
  font-feature-settings: "ss01" on, "liga" off;
  font-size: 13px;
  font-weight: 700;
  min-width: 120px;
  text-align: right;
`;

const IrrPercentageChange = styled(PercentageLabel)`
  margin-top: -2px;
  font-size: 13px;
  font-weight: 700;
`;

const IrrLabel = styled.div`
  color: ${props => props.theme.gridCellColor};
  font-size: 13px;
  font-weight: 700;
  margin-right: 5px;
  margin-left: ${props => `${props.marginLeft !== undefined ? props.marginLeft : -6}px`};
`;

const IrrCostValue = styled(CurrencyLabel)`
  color: ${props => props.theme.gridCellColor};
  font-size: 13px;
  font-weight: 700;
  margin-right: 8px;
`;

const FixedListComponent = ({
  gridId,
  sections = [],
  sectionDragging,
  isSectionDragging,
  isOverValidDestination,
  isSidebarCollapsed
}) => {
  return ReactDOM.createPortal(
    <FixedList sectionDragging={sectionDragging} isSidebarCollapsed={isSidebarCollapsed}>
      <Droppable droppableId="section_droppable_fixed" type="SECTIONS">
        {provided => (
          <div className="fixed-section-wrapper" ref={provided.innerRef} {...provided.droppableProps}>
            {sections.map((section, index) => {
              return !isSectionDragging || section.id !== sectionDragging ? (
                <Draggable key={section.id} draggableId={`${section.id}-fixed`} index={index}>
                  {(provided, snapshot) => (
                    <TitleDottedPlaceholderWrapper isOverValidDestination ref={provided.innerRef}>
                      <TitleDottedPlaceholder />
                      <TitleWrapper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        transform={provided.draggableProps.style && provided.draggableProps.style.transform}
                      >
                        <Title type="text" isClickable={false} value={section.name} />
                      </TitleWrapper>
                    </TitleDottedPlaceholderWrapper>
                  )}
                </Draggable>
              ) : null;
            })}
            <TitleDottedPlaceholderWrapper isOverValidDestination={isSectionDragging && isOverValidDestination}>
              <TitleDottedPlaceholder />
            </TitleDottedPlaceholderWrapper>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </FixedList>,
    document.querySelector("body")
  );
};

class GridSheet extends React.Component {
  constructor(props) {
    super(props);

    this.visibleElementsInArray = React.createRef();
    const visibleAreaToConsider = Math.max(700, window.innerHeight);
    this.visibleElementsInArray.current = Math.ceil(visibleAreaToConsider / HEIGHT_OF_ASSET_GRID_ROW);
  }
  getHeaderRow() {
    const cells =
      this.props.sheetHeaderCells &&
      this.props.sheetHeaderCells.map(cell => new GridCellData(cellType.HEADER, cell.value, null));
    const row = new GridRowData(null, null, "header-row-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    return row;
  }

  get totalValueForSheet() {
    const sheet = this.props.sheet;
    if (!sheet) return undefined;
    const total = sheet.getTotalValue(this.props.portfolio?.currency);
    const value = isCryptoCurrency(this.props.portfolio?.currency)
      ? total
      : total > 0 && total < 1
      ? total
      : Math.kuberaFloor(total);
    return value === 0 ? undefined : value;
  }

  get irrCash() {
    const irrDetails = this.irrDetails;
    if (!irrDetails === true) {
      return null;
    }

    const cashTicker = getTickerUsingId(irrDetails.all.cashTickerId);
    const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.portfolio.currency);
    const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
    const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

    return {
      totalCashIn,
      totalCashOut
    };
  }

  get irrDetails() {
    return this.props.sheet.getIrrDetails();
  }

  render() {
    const sections = this.props.sheet ? this.props.sheet.sections : [];
    const headerRow = this.getHeaderRow();
    window.renderlog("Render: GridSheet");
    let rowsRendered = 0;
    const sheetTotal = this.totalValueForSheet;

    return (
      <Fragment>
        <Droppable droppableId="section_droppable" type="SECTIONS" isDropDisabled>
          {provided => (
            <Container ref={provided.innerRef} {...provided.droppableProps}>
              {this.props.sheet && this.props.sheet.showHeader === true && (
                <GridHeaderRow
                  sectionId={this.props.sheet.id}
                  row={headerRow}
                  portfolio={this.props.portfolio}
                  userPreferences={this.props.userPreferences}
                  sectionRows={sections[1].rows}
                  columnSortKey={this.props.sheet.columnSortKey}
                  columnSortOrder={this.props.sheet.columnSortOrder}
                  // updateSortPreference={this.handleSectionSort}
                />
              )}
              {sections.map((section, index) => {
                if (index > 0) {
                  const sectionRowsLength = sections[index - 1]?.rows?.length;
                  rowsRendered += sectionRowsLength ? sectionRowsLength : 0;
                }
                return (
                  <GridSection
                    key={section.id}
                    isEditable={this.props.isEditable}
                    gridId={this.props.gridId}
                    section={section}
                    totalSections={this.props.sheet.sections.length}
                    currency={this.props.currency}
                    sheetIndex={this.props.sheetIndex}
                    sectionIndex={index}
                    showTitle={sections.length > 1 || section.forceShowTitle === true}
                    showAddNew={section.showAddNewInFooter}
                    onMoveToNextCell={this.props.onMoveToNextCell}
                    onPaste={this.props.onPaste}
                    onRowUpdate={this.props.onRowUpdate}
                    onAddNewRow={this.props.onAddNewRow}
                    onRemoveRow={this.props.onRemoveRow}
                    onSectionUpdate={this.props.onSectionUpdate}
                    onAddNewSection={this.props.onAddNewSection}
                    onRemoveSection={this.props.onRemoveSection}
                    onDetailsClick={this.props.onDetailsClick}
                    onOptionsClick={this.props.onOptionsClick}
                    onLinkErrorClick={this.props.onLinkErrorClick}
                    onStarSection={this.props.onStarSection}
                    onChangeSectionUpdatedStatus={this.props.onChangeSectionUpdatedStatus}
                    onUserLeavingCellEmpty={this.props.onUserLeavingCellEmpty}
                    onRowContextMenuSelection={this.props.onRowContextMenuSelection}
                    onSectionContextMenuSelection={this.props.onSectionContextMenuSelection}
                    onCellBlur={this.props.onCellBlur}
                    onCellInvalidTickerAdded={this.props.onCellInvalidTickerAdded}
                    onAddNewFooterButtonClick={this.props.onAddNewFooterButtonClick}
                    onRowClick={this.props.onRowClick}
                    portfolio={this.props.portfolio}
                    userPreferences={this.props.userPreferences}
                    addRowBtnTxt={this.props.addRowBtnTxt}
                    gridOptions={this.props.gridOptions}
                    isLastSection={index === sections.length - 1}
                    onOpenChartsModalClick={this.props.onOpenChartsModalClick}
                    category={this.props.category}
                    visibleElementsInArray={this.visibleElementsInArray}
                    rowsRendered={rowsRendered}
                  />
                );
              })}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
        {this.props.category === category.ASSET && sheetTotal && sections.length > 1 && (
          <IrrRow isLastSectionCollapsed={sections[sections.length - 1].isCollapsed}>
            <IrrNameColumn>{this.props.sheet.name}</IrrNameColumn>
            {this.irrDetails && (
              <>
                {!!this.irrCash.totalCashIn && (
                  <>
                    <IrrLabel>{this.props.sheet.showCost ? i18n.t("cost") : i18n.t("in")}</IrrLabel>
                    <IrrCostValue
                      value={this.irrCash.totalCashIn}
                      currency={this.props.portfolio.currency}
                      roundDown={true}
                      maxLongFormatValue={999999}
                    />
                  </>
                )}
                {!!this.irrCash.totalCashOut && (
                  <>
                    <IrrLabel marginLeft={0}>{i18n.t("out")}</IrrLabel>
                    <IrrCostValue
                      value={this.irrCash.totalCashOut}
                      currency={this.props.portfolio.currency}
                      roundDown={true}
                      maxLongFormatValue={999999}
                    />
                  </>
                )}
                <IrrPercentageChange
                  value={
                    this.irrDetails.all.value > -1 && this.irrDetails.all.value < 1
                      ? this.irrDetails.all.value.toFixed(2)
                      : Math.kuberaFloor(this.irrDetails.all.value)
                  }
                />
              </>
            )}
            <IrrTotalValue value={sheetTotal} currency={this.props.portfolio?.currency} />
          </IrrRow>
        )}
        <FixedListComponent
          gridId={this.props.gridId}
          sections={sections}
          sectionDragging={this.props.sectionDragging}
          isSectionDragging={this.props.isSectionDragging}
          isOverValidDestination={this.props.isOverValidDestination}
          isSidebarCollapsed={this.props.userPreferences && this.props.userPreferences.isSidebarCollapsed}
        />
      </Fragment>
    );
  }
}

export default GridSheet;
