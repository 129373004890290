import React, { Fragment, useState, useCallback } from "react";
import styled from "styled-components";
import i18n from "i18next";

import HomesConnectComponent from "./HomesConnectComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 20px;
  font-feature-settings: "ss01" on;
  margin-bottom: 24px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  user-select: none;
  margin-top: -10px;
`;

const Tabs = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => (props.isDisabled ? "rgba(0, 0, 0, 0.50)" : "#000000")};
  cursor: ${props => (props.isDisabled ? "pointer" : "default")};

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 2px;
    background: ${props => (props.isDisabled ? "transparent" : "#000000")};
  }
`;

const ProviderTitle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

const HomesTabsComponent = ({ onConfirmSave = () => null, onConfirmCancel = () => null, ...props }) => {
  const [clickedTab, setClickedTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const selectTab1 = () => {
    setClickedTab(0);
  };

  const selectTab2 = () => {
    setClickedTab(1);
  };

  const handleOnConfirmSave = useCallback(() => {
    setSelectedTab(clickedTab);
    onConfirmSave();
  }, [onConfirmSave, clickedTab]);

  const handleOnConfirmCancel = useCallback(() => {
    setClickedTab(selectedTab);
    onConfirmCancel();
  }, [onConfirmCancel, selectedTab]);

  const propsToPass = {
    ...props,
    homesSelectedTab: selectedTab,
    homesClickedTab: clickedTab,
    onConfirmSave: handleOnConfirmSave,
    onConfirmCancel: handleOnConfirmCancel
  };

  return (
    <Container>
      <ProviderScreenContainer>
        <Title>
          <Tabs isDisabled={selectedTab !== 0} onClick={selectTab1}>
            {i18n.t("linkAccount.homesTab1Title")}
          </Tabs>
          <Tabs isDisabled={selectedTab !== 1} onClick={selectTab2}>
            {i18n.t("linkAccount.homesTab2Title")}
          </Tabs>
        </Title>
        {selectedTab === 0 && (
          <Fragment>
            <ProviderTitle>{i18n.t("linkAccount.homesProviderTitle")}</ProviderTitle>
            <HomesConnectComponent
              {...propsToPass}
              searchInputPlaceholder={i18n.t("linkAccount.searchHomesZillowUrlPlaceholder")}
              showSupportedFooter
            />
          </Fragment>
        )}
        {selectedTab === 1 && (
          <Fragment>
            <ProviderTitle>{i18n.t("linkAccount.homesProviderTitle")}</ProviderTitle>
            <HomesConnectComponent
              {...propsToPass}
              searchInputPlaceholder={i18n.t("linkAccount.searchHomesZillowAddrPlaceholder")}
              showAddButtonToEnterManually
            />
          </Fragment>
        )}
      </ProviderScreenContainer>
    </Container>
  );
};

export default HomesTabsComponent;
