import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "i18next";
import AccountComponent from "components/account_settings/AccountComponent";
import AccountSettingsComponentExports from "./AccountSettingsComponentExports";
import AccountSubscriptionComponent from "components/account_settings/AccountSubscriptionComponent";
import AccountSubscriptionComponentBilling from "components/account_settings/billing/AccountSubscriptionComponent";
import AccountSecurityComponent from "components/account_settings/AccountSecurityComponent";
import AccountGeneralSettingsComponent from "components/account_settings/AccountGeneralSettingsComponent.js";
import ConnectionStatusTilesComponent from "./ConnectionStatusTilesComponent.js";
import { connect } from "react-redux";
import {
  canAmcUserManageOtherUsers,
  isAmcUser,
  isAppInWhiteLabelMode,
  siteConfigSelector,
  isSharedPortfolioUserSelector,
  getMultiuserList,
  whiteLabelUserInfoSelector,
  userTypes,
  userSelector,
  userNameSelector,
  sharedPortfolioAccountTitleSelector,
  withRouter,
  currentUserMemberDetailsFromMultiuserListSelector,
  userPreferencesSelector,
  updateUserPreferences,
  showAPIBadgeTipSelector
} from "@kubera/common";
import ManageUsersComponent from "components/account_settings/ManageUsersComponent";
import AccountNotificationsComponent from "components/account_settings/AccountNotificationsComponent";
import MultiuserManagementComponent from "components/multiuser/MultiuserManagementComponent";
import AdminClientBilling from "./AdminClientBilling";
import APIAccess from "./APIAccess";
import BadgeTip from "components/tooltip/BadgeTip";
import { accountSettingsTabs } from "./AccountSettingsComponentExports";

const SettingsDialog = styled(Dialog)`
  position: relative;
  width: 873px;
  min-height: 736px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const SettingsContainer = styled.div`
  display: flex;
  margin: 60px 60px 165px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const SettingsTabs = styled(Tabs)`
  display: flex;
  flex: 1;
`;

const SettingsTabList = styled(TabList)`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 163px;
  margin: 0;
  margin-right: 38px;
  padding-inline-start: 0;
`;

const SettingsTab = styled(Tab)`
  margin-top: ${props => (props.index === 0 ? "0px" : "20px")}
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.fontSize || "14px"}
  line-height: 17px;
  border: 0;
  outline: 0;
  cursor: ${props => (props.disabled ? "auto" : "pointer")} ;
  z-index: 100;
  color: ${props => (props.disabled ? "rgba(0,0,0,0.5)" : "#000000")};
  &.is-selected {
      font-weight: bold;
  }
`;

const SettingsTabPanel = styled(TabPanel)`
  display: none;
  box-sizing: border-box;
  flex: 1;
  position: relative;

  &.is-selected {
    display: flex;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Badge = styled(BadgeTip)`
  margin-left: 5px;
`;

const AccountPanel = styled(AccountComponent)``;

const SubscriptionPanel = styled(AccountSubscriptionComponent)``;

const SubscriptionPanelBilling = styled(AccountSubscriptionComponentBilling)``;

const MultiUserPanel = styled(MultiuserManagementComponent)``;

const NotificationsPanel = styled(AccountNotificationsComponent)``;

const SecurityPanel = styled(AccountSecurityComponent)``;

const GeneralPanel = styled(AccountGeneralSettingsComponent)``;

const ManageUsersPanel = styled(ManageUsersComponent)``;

const AdminClientBillingPanel = styled(AdminClientBilling)``;
const ConnectionStatusTilesPanel = styled(ConnectionStatusTilesComponent)``;

const APIAccessPanel = styled(APIAccess)``;
class AccountSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.onSettingsTabSelect = this.onSettingsTabSelect.bind(this);
  }

  componentDidMount() {
    if (isAppInWhiteLabelMode() === false) {
      this.props.getMultiuserList();
    }
  }

  componentWillUnmount() {
    if (this.props.showAPIBadgeTip) {
      this.props.updateUserPreferences({ hideAPIDots: true });
      // never show the badge tip again
    }
  }

  handleOverlayDismiss() {
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  getTabs() {
    const accountPanel = <AccountPanel />;
    const subscriptionPanel = <SubscriptionPanel />;
    const multiuserPanel = <MultiUserPanel />;
    const notificationsPanel = <NotificationsPanel />;
    const apiAccessPanel = <APIAccessPanel />;
    const securityPanel = <SecurityPanel />;
    const generalPanel = <GeneralPanel />;
    const manageUsersPanel = <ManageUsersPanel />;
    const admingClientBillingPanel = <AdminClientBillingPanel />;
    const subscriptionPanelBilling = <SubscriptionPanelBilling />;
    const connectionStatusTilesPanel = (
      <ConnectionStatusTilesPanel selectedPortfolioId={this.props.selectedPortfolioId} />
    );

    if (this.props.isSharedPortfolioAccountUser === true) {
      const collabString = this.props.sharedPortfolioAccountTitle;

      const tabs = [
        {
          title: i18n.t("accountSettings.accountTabTitle"),
          panelComponent: accountPanel,
          tabName: accountSettingsTabs.ACCOUNT
        },
        {
          title: i18n.t("accountSettings.generalTabTitle"),
          panelComponent: generalPanel,
          tabName: accountSettingsTabs.GENERAL
        },
        {
          title: i18n.t("accountSettings.securityTabTitle"),
          panelComponent: securityPanel,
          tabName: accountSettingsTabs.SECURITY
        }
      ];

      if ((isAppInWhiteLabelMode() === true || this.props.currentUserMemberDetails?.write === 1) && collabString) {
        tabs.push({ title: collabString, isDisabled: true, fontSize: "11px" });
      }

      if (isAppInWhiteLabelMode() === false && this.props.currentUserMemberDetails?.allowAll === 1) {
        tabs.push({
          title: i18n.t("accountSettings.multiuserManagementTab"),
          panelComponent: multiuserPanel,
          tabName: accountSettingsTabs.MULTI_USER
        });
      }

      if (isAppInWhiteLabelMode() === true || this.props.currentUserMemberDetails?.write === 1) {
        tabs.push(
          ...[
            {
              title: i18n.t("accountSettings.connectionStatusTabTitle"),
              panelComponent: connectionStatusTilesPanel,
              tabName: accountSettingsTabs.CONNECTION_STATUS
            },
            {
              title: i18n.t("accountSettings.notificationsTab"),
              panelComponent: notificationsPanel,
              tabName: accountSettingsTabs.NOTIFICATIONS
            }
          ]
        );
      }
      return tabs;
    } else if (this.props.isAmcUser === false) {
      const tabs = [
        {
          title: i18n.t("accountSettings.accountTabTitle"),
          panelComponent: accountPanel,
          tabName: accountSettingsTabs.ACCOUNT
        }
      ];
      if (isAppInWhiteLabelMode() === false) {
        tabs.push({
          title: i18n.t("accountSettings.subscriptionTab"),
          panelComponent: subscriptionPanel,
          tabName: accountSettingsTabs.SUBSCRIPTION
        });
        tabs.push({
          title: i18n.t("accountSettings.multiuserManagementTab"),
          panelComponent: multiuserPanel,
          tabName: accountSettingsTabs.MULTI_USER
        });
      } else if (
        this.props.siteConfig.showBilling === 1 &&
        this.props.siteConfig.stripeConnectedAccount &&
        this.props.user.wl.type !== userTypes.SUB_USER
      ) {
        tabs.push({
          title: i18n.t("accountSettings.clientBillingAndPaymentsTab"),
          panelComponent: subscriptionPanelBilling,
          tabName: accountSettingsTabs.CLIENT_BILLING
        });
      }
      tabs.push({
        title: i18n.t("accountSettings.connectionStatusTabTitle"),
        panelComponent: connectionStatusTilesPanel,
        tabName: accountSettingsTabs.CONNECTION_STATUS
      });
      if (this.props.siteConfig.send1dMail !== 0) {
        tabs.push({
          title: i18n.t("accountSettings.notificationsTab"),
          panelComponent: notificationsPanel,
          tabName: accountSettingsTabs.NOTIFICATIONS
        });
      }
      tabs.push({
        title: i18n.t("accountSettings.generalTabTitle"),
        panelComponent: generalPanel,
        tabName: accountSettingsTabs.GENERAL
      });
      if (isAppInWhiteLabelMode() === false) {
        tabs.push({
          title: i18n.t("accountSettings.apiAccessTab"),
          panelComponent: apiAccessPanel,
          tabName: accountSettingsTabs.API_ACCESS,
          showBadge: this.props.showAPIBadgeTip
        });
      }
      tabs.push({
        title: i18n.t("accountSettings.securityTabTitle"),
        panelComponent: securityPanel,
        tabName: accountSettingsTabs.SECURITY
      });
      return tabs;
    } else {
      const tabs = [
        ...(this.props.siteConfig.showBilling === 1 && this.props.whiteLabelUserInfo.type === userTypes.OWNER
          ? [
              {
                title: i18n.t("accountSettings.adminClientBillingTabTitle"),
                panelComponent: admingClientBillingPanel,
                tabName: accountSettingsTabs.ADMIN_CLIENT_BILLING
              }
            ]
          : []),
        {
          title: i18n.t("accountSettings.accountTabTitle"),
          panelComponent: accountPanel,
          tabName: accountSettingsTabs.ACCOUNT
        },
        {
          title: i18n.t("accountSettings.securityTabTitle"),
          panelComponent: securityPanel,
          tabName: accountSettingsTabs.SECURITY
        }
      ];

      if (this.props.canAmcUserManageOtherUsers === true) {
        tabs.unshift({
          title: i18n.t("accountSettings.manageUsersTabTitle"),
          panelComponent: manageUsersPanel,
          tabName: accountSettingsTabs.MANAGE_USERS
        });
      }
      return tabs;
    }
  }

  getSelectedTabIndex() {
    const tabs = this.getTabs();
    const selectedTab = this.props.selectedTab;
    const index = tabs.findIndex(tab => tab.tabName === selectedTab);
    return index === -1 ? 0 : index;
  }

  onSettingsTabSelect(index) {
    const tabs = this.getTabs(0);
    if (tabs[index].isDisabled) return;
    AccountSettingsComponentExports.show(this.props.history, this.props.location, tabs[index].tabName);
  }

  render() {
    const tabs = this.getTabs();
    const selectedTabIndex = this.getSelectedTabIndex();

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <SettingsDialog>
          <SettingsContainer>
            <SettingsTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={selectedTabIndex}
              onSelect={this.onSettingsTabSelect}
            >
              <SettingsTabList>
                {tabs.map((tab, index) => (
                  <SettingsTab key={index} index={index} disabled={tab.isDisabled} fontSize={tab.fontSize}>
                    <LabelContainer>
                      {tab.title}
                      {tab.showBadge && <Badge />}
                    </LabelContainer>
                  </SettingsTab>
                ))}
              </SettingsTabList>
              {tabs.map((tab, index) => (
                <SettingsTabPanel key={index}>{tab.panelComponent}</SettingsTabPanel>
              ))}
            </SettingsTabs>
          </SettingsContainer>
        </SettingsDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  canAmcUserManageOtherUsers: canAmcUserManageOtherUsers(state),
  isAmcUser: isAmcUser(state),
  siteConfig: siteConfigSelector(state),
  user: userSelector(state),
  userName: userNameSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state),
  whiteLabelUserInfo: whiteLabelUserInfoSelector(state),
  sharedPortfolioAccountTitle: sharedPortfolioAccountTitleSelector(state, undefined, true),
  currentUserMemberDetails: currentUserMemberDetailsFromMultiuserListSelector(state),
  userPreferences: userPreferencesSelector(state),
  showAPIBadgeTip: showAPIBadgeTipSelector(state)
});

const mapDispatchToProps = {
  getMultiuserList: getMultiuserList,
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountSettingsComponent));
