import { useEffect } from "react";

import { queryParams } from "routes";
import { getQueryParams } from "@kubera/common";
import LinkAccountProviderPageComponentExports from "components/link_account/LinkAccountProviderPageComponentExports";

const StripeCallbackComponent = () => {
  useEffect(() => {
    const urlQueryParams = getQueryParams(window.location);
    const state = urlQueryParams[queryParams.STATE];
    const code = urlQueryParams[queryParams.CODE];

    const redirectHost = LinkAccountProviderPageComponentExports.getRedirectDomain();
    const redirectUrl = `${
      window.location.protocol
    }//${redirectHost}/admin#modal=account_settings&tab=admin_client_billing${
      state && code ? `&${queryParams.STATE}=${state}&${queryParams.CODE}=${code}` : ""
    }`;
    window.location.href = redirectUrl;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default StripeCallbackComponent;
