import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import i18n from "i18next";

import NumberInput from "components/inputs/NumberInput";
import CurrencyCell from "components/grid/CurrencyCell";
import TextCell from "components/grid/TextCell";
import EditablePercentageCell from "components/grid/EditablePercentageCell";
import PercentageLabel from "components/labels/PercentageLabel";
import { cellType } from "components/grid/GridDataModel";
import { ReactComponent as OptionsIcon } from "assets/images/options.svg";
import { ReactComponent as DetailsIcon } from "assets/images/details_icon.svg";
import { ReactComponent as StarIcon } from "assets/images/star_icon.svg";
import { ReactComponent as StarIconYellow } from "assets/images/star_icon_yellow.svg";
import { ReactComponent as ErrorIcon } from "assets/images/link_account_warning.svg";
import { ReactComponent as LinkingFailureIcon } from "assets/images/link_account_error.svg";
import { ReactComponent as AddNewIcon } from "assets/images/add_row_icon.svg";
import { ReactComponent as RightHeavyIcon } from "assets/images/right_heavy.svg";
import { ReactComponent as LeftHeavyIcon } from "assets/images/left_heavy.svg";
import { ReactComponent as PlusIcon } from "assets/images/plus.svg";
import CurrencyLabel from "components/labels/CurrencyLabel";
import {
  convertCurrency,
  getTickerUsingShortName,
  getSymbolForTickerUsingShortName,
  isCryptoCurrency,
  getExchangeRate,
  setUndoQueue,
  setRedoQueue,
  parseNumberStringToFloat,
  unsupportedTickerSubTypesArray
} from "@kubera/common";
import Loader from "components/loader/Loader";
import BadgeTip from "components/tooltip/BadgeTip";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { addWindowResizeEventListener, removeWindowResizeEventListener } from "utilities/EventManager";
import ColumnarChartCellComponent from "components/recap/ColumnarChartCellComponent";
import RedDot from "components/indicators/RedDot";
import { cellMode, CELL_MODE_DATA_ATTRIBUTE_KEY } from "components/grid/GridCellExports";

const BaseCell = css`
  text-align: ${props =>
    !props.textAlignment === false
      ? props.textAlignment
      : props.cellIndex === props.cellIndexToStretch
      ? "left"
      : "right"};
  width: ${props => (props.cellIndex === props.cellIndexToStretch ? "auto" : props.width ? props.width : "97px")};
  flex: ${props => (props.cellIndex === props.cellIndexToStretch ? 1 : "")};
  font-weight: normal;
  padding-left: 15px;
  padding-right: 15px;

  @media print {
    width: ${props =>
      props.cellIndex === props.cellIndexToStretch ? "auto" : props.width ? props.width : "70px"} !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
`;

const Input = css`
  ${BaseCell}
  font-family: ${props => (props.isLinking === true ? "Roboto mono" : "'Inter var', 'Inter', sans-serif")};
  z-index: 100;
  border: 0;
  border-right: ${props =>
    props.isReportGridCell
      ? "none"
      : props.isRowComplete
      ? "1px solid transparent"
      : "1px solid " + props.theme.gridSectionBorderColor};
  margin: 0;
  background-color: transparent;
  color: ${props => props.theme.gridCellColor};
  text-overflow: ellipsis;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  cursor: ${props => (props.mode === cellMode.EDIT || !props.enterEditModeOnClick === false ? "auto" : "default")};
  caret-color: ${props =>
    props.mode === cellMode.EDIT || props.mode === cellMode.UPDATE || !props.isInSingleCellMode === false
      ? props.theme.gridCellColor
      : "transparent"};
  font-weight: normal;

  &:focus {
    background-color: ${props => props.theme.gridCellSelectedBackgroundColor};
    outline: ${props =>
      props.hideCellFocusHighlight === true ? "0" : "2px solid" + props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }

  ::selection {
    background-color: ${props => (props.mode === cellMode.EDIT ? "" : "transparent")};
  }

  ::placeholder {
    font-weight: normal;
    color: ${props => props.theme.gridCellPlaceholderColor};
  }
  box-sizing: ${props => (props.isCurrencyInputCell ? " border-box" : "content-box")};
  width: ${props => (props.isCurrencyInputCell && props.width ? `${props.width}px` : "none")} ;

  @media print {
      font-size: 11px !important;
      color: #000000;
  }
`;

const Container = styled.div`
  display: flex;
  flex: ${props => (props.cellIndex === props.cellIndexToStretch ? 1 : "")};
`;

const Cell = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const Hint = styled.div`
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
  text-align: right;
  padding-right: 20px;
  color: ${props => props.theme.gridCellHintColor};
`;

const HintKey = styled.span`
  font-weight: bold;
`;

const PercentageCell = styled.button`
  display: flex;
  visibility: ${props => (props.hidden === true ? "hidden" : "visible")};
  align-items: center;
  justify-content: flex-end;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  padding: ${props => (props.hidden === true ? "" : "0px 10px 0px 10px")};
  text-align: right;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  text-align: right;
  border-right: ${props =>
    props.isRowComplete === true || props.value === null || props.value === undefined
      ? "1px solid transparent"
      : "1px solid " + props.theme.gridSectionBorderColor};
  cursor: ${props => (props.disabled === false ? "pointer" : "auto")};
  color: ${props => props.theme.gridCellColor};

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const PercentageValue = styled(PercentageLabel)`
  min-width: 50px
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
  font-weight: ${props => (props.updated === true ? "normal" : "normal")};
`;

const EmptyPercentageValue = styled.div`
  min-width: 50px;
`;

const FooterPercentageCell = styled(PercentageCell)`
  border: 0;
  padding-right: 12px;
`;

const FooterPercentageValue = styled(PercentageValue)`
  font-weight: normal;
  font-style: normal;
`;

const TextInputCell = styled(TextCell)`
  ${Input};
  color: ${props =>
    !props.invalidInputText === false && props.mode === cellMode.SELECT ? "red" : props.theme.gridCellColor};
  font-style: ${props => (props.updated === true || props.isLinking ? "normal" : "italic")};
  padding-bottom: ${props => (props.hasSubValue ? "12px" : null)};
  ${props =>
    !props.cellStyles === false
      ? css`
          ${props.cellStyles}
        `
      : ""};
`;

const NumberCell = styled(NumberInput)`
  ${Input};
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const PercentageCellForReports = styled(EditablePercentageCell)`
  ${Input};
  width: ${props => (props.width ? `${props.width}` : "97px")};
  text-align: right;
  color: ${props =>
    !props.hasError === false
      ? "red"
      : props.hasUserNotSetATargetValue
      ? "rgba(0, 0, 0, 0.3)"
      : props.theme.gridCellColor};
  text-overflow: clip;
  border-right: none;
`;

const CurrencyTypeCell = styled(CurrencyCell)`
  ${Input};
  color: ${props =>
    (!props.invalidInputText === false || props.hasError === true) &&
    props.mode === cellMode.SELECT &&
    props.loading === false
      ? "red"
      : props.updated === true
      ? props.theme.gridCellColor
      : props.theme.gridCellColorUnupdated};
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
  font-weight: ${props => (props.updated === true ? "normal" : "normal")};
  ${props =>
    !props.cellStyles === false
      ? css`
          ${props.cellStyles}
        `
      : ""};
`;

const OptionsCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const DetailsCellBadge = styled(BadgeTip)`
  position: absolute;
  right: 8px;
  top: 11px;
`;

const DetailsCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }

  &:hover ${DetailsCellBadge} {
    border-color: ${props => props.theme.badgeBorderHoverCLR};
  }
`;

const StarCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  width: 46px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;

  & > svg {
    padding-top: 1px;
    padding-left: 10px;
  }

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const HeavyIconCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const RecommendationBadgeCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const RecommendationBadge = styled(BadgeTip)`
  display: flex
  align-self: center
  justify-content: center;
  width: 6px;
  height: 6px;
  color: #FFFFFF;
`;

const LoaderCell = styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const ErrorCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  pointer-events: ${props => props.isReadOnly && "none"};

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const HeaderCell = styled.div`
  ${BaseCell}

  cursor: ${props => (props.onClick ? "pointer" : null)}
`;

const BlankCell = styled.div`
  ${BaseCell}
  width: auto;
  min-width: 97px;
`;

const AddNewButton = styled.button`
  color: ${props => props.theme.gridAddNewButtonColor};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  margin-left: 15px;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-transform: uppercase;
  height: 40px;
  text-align: left;
`;

const AddNewModalButton = styled(AddNewButton)`
  position: relative;
  margin-left: 31px;
`;

const AddNewIconImage = styled(AddNewIcon)`
  margin-left: 5px;
`;

const FooterCurrencyLabel = styled(CurrencyLabel)`
  ${BaseCell}
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  padding-right: 16px;
  font-feature-settings: "ss01" on, "liga" off;

  @media print {
    font-size: 11px !important;
  }
`;

const TextInputSubVal = styled.div`
  position: absolute;
  bottom: 3px;
  left: 15px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SortIconComponent = styled(SortIcon)`
  position: relative;
  top: -1px;
  margin-right: 3px;
  margin-left: ${props => (props.shouldSortIconBeShownOnRightSide ? "-7px" : "3px")};
  visibility: ${props => (!props.sortoption ? "hidden" : null)};
  transform: ${props => (props.sortoption === "ascending" ? "rotate(180deg)" : null)};

  path {
    fill: ${props => props.theme.sortIconComponentCLR};
  }
`;

const HeaderContainer = styled.div``;

const PadCell = styled.div`
  padding: ${props => props.padding || "5px"};
`;

const ErrorIconComponent = styled(ErrorIcon)`
  path {
    fill: ${props => props.theme.errorIconComponentCLR};
  }
`;

const OptionsIconComponent = styled(OptionsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DetailsIconComponent = styled(DetailsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const StarIconComponent = styled(StarIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const PlusAddIcon = styled(PlusIcon)`
  margin-right: 4px;
`;

const AddNewModalRedDot = styled(RedDot)`
  position: static;
  display: inline-block;
  margin-left: 6px;
  width: 6px;
  height: 6px;
  background: "#FF6464";
`;

let cellSelectAllTimeoutId = null;
const RightHeavyIconComponent = styled(RightHeavyIcon)``;
const LeftHeavyIconComponent = styled(LeftHeavyIcon)``;

class GridCell extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.debounce = this.debounce.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleStarClick = this.handleStarClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleLinkErrorClick = this.handleLinkErrorClick.bind(this);
    this.handleShowTickerSelection = this.handleShowTickerSelection.bind(this);
    this.handleMouseEnterEvent = this.handleMouseEnterEvent.bind(this);
    this.handleMouseEnterLeave = this.handleMouseEnterLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenChartsModalClick = this.handleOpenChartsModalClick.bind(this);
    this.state = {
      mode: this.props.cell.isInSingleCellMode === true ? cellMode.EDIT : cellMode.SELECT,
      hideHintDueToResize: false
    };
    this.pendingCellUpdate = null;
    this.pendingCellUpdateTimer = null;
    this.cellValueOnEnter = null;
    this.addNewRedDotRef = React.createRef();
  }

  componentDidMount() {
    addWindowResizeEventListener(this.handleWindowResize);
    // this.handleInvalidTicker();
  }

  handleInvalidTicker() {
    if (this.props.cell.supportedTickerTypes) {
      const cellTicker = getTickerUsingShortName(this.props.cell.currency);
      if (cellTicker && this.props.cell.supportedTickerTypes.includes(cellTicker.type) === false) {
        const newCell = this.props.cell;
        newCell.invalidInputText = null;
        newCell.tickerInfo = null;
        newCell.value = convertCurrency(newCell.value, newCell.currency, this.props.currency, newCell.exchangeRateDate);
        newCell.currency = this.props.currency;
        newCell.setExchangeRateDetails(this.props.currency);

        if (this.props.onCellUpdate) {
          this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
        }

        if (this.props.onCellInvalidTickerAdded) {
          this.props.onCellInvalidTickerAdded(this.props.cellIndex);
        }
      }
    }
  }

  // componentDidUpdate(oldProps) {
  //   if (this.props.cell) {
  //     this.handleInvalidTicker();
  //   }
  // }

  componentWillUnmount() {
    removeWindowResizeEventListener(this.handleWindowResize);
  }

  get isCellInWriteMode() {
    return [cellMode.EDIT, cellMode.UPDATE].includes(this.state.mode);
  }

  handleWindowResize(e) {
    if (window.innerWidth < window.screen.width) {
      if (this.state.hideHintDueToResize === false && !this.props.showHint === false) {
        this.setState({ ...this.state, hideHintDueToResize: true });
      }
    } else {
      if (this.state.hideHintDueToResize === true && !this.props.showHint === false) {
        this.setState({ ...this.state, hideHintDueToResize: false });
      }
    }
  }

  handleInputChange(e) {
    if (this.props.isEditable === false || this.props.disableUserInput === true) {
      return;
    }
    if (this.state.mode === cellMode.SELECT) {
      this.enterUpdateMode(e.target);
    }

    const newCell = this.props.cell;
    newCell[newCell.symbol && this.isCellInWriteMode ? "subValue" : "value"] = e.target.value;
    this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
  }

  debounce(func, timeout = 50) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  getInvalidTextFromInput(input, inputTicker) {
    if (!input === false) {
      const tickerShortName = !this.props.cell.currency === false ? this.props.cell.currency : this.props.currency;
      const symbol = getSymbolForTickerUsingShortName(tickerShortName);

      // Append the tickerName if the invalid input is due to exchange rate missing for a particular date
      if (
        !inputTicker === false &&
        inputTicker.shortName === this.props.cell.currency &&
        !getExchangeRate(inputTicker.shortName, this.props.currency, false, this.props.cell.exchangeRateDate) === true
      ) {
        return tickerShortName + input.replace(tickerShortName, "");
      } else if (symbol !== tickerShortName) {
        return input.replace(symbol, "");
      }
    }
    return input;
  }

  handleCurrencyInputChange(value, ticker, e) {
    if (this.props.isEditable === false || this.props.disableUserInput === true) {
      return;
    }
    if (this.state.mode === cellMode.SELECT) {
      this.enterUpdateMode(e.target);
    }

    if (
      (ticker?.id === 171 && this.props.cell.allowPVST !== true) ||
      ((value === null || value === undefined || value === "") && !e.target.value === false)
    ) {
      const newCell = this.props.cell;
      newCell.invalidInputText = this.getInvalidTextFromInput(e.target.value, ticker);
      newCell.value = null;
      this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
      return;
    } else if (
      !ticker === true ||
      (ticker.id !== 171 &&
        !getExchangeRate(ticker.shortName, this.props.currency, false, this.props.cell.exchangeRateDate) === true)
    ) {
      const newCell = this.props.cell;
      newCell.invalidInputText = this.getInvalidTextFromInput(e.target.value, ticker);
      newCell.value = value;
      newCell.currency = !ticker === true ? this.props.currency : this.props.cell.currency;
      newCell.exchangeRateDetails = null;
      this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
      this.pendingCellUpdate = newCell;

      if (this.pendingCellUpdateTimer !== null) {
        clearTimeout(this.pendingCellUpdateTimer);
      }

      this.pendingCellUpdateTimer = setTimeout(() => {
        if (!this.pendingCellUpdate === false) {
          this.props.onCellUpdate(this.props.cellIndex, this.pendingCellUpdate, this.props.isRowEmpty);
        }
        this.pendingCellUpdateTimer = null;
        this.pendingCellUpdate = null;
      }, 10000);
      return;
    }

    const isEmptyCell = e.target.value === null || e.target.value === undefined || e.target.value === "";
    const supportedTickerTypes = this.props.cell.supportedTickerTypes;
    const unsupportedTickerSubTypes = this.props.cell.unsupportedTickerSubTypes || unsupportedTickerSubTypesArray;
    const newCell = this.props.cell;
    newCell.invalidInputText = null;
    newCell.tickerInfo = null;

    if (
      supportedTickerTypes.includes(ticker.type) &&
      (!ticker.subType || !unsupportedTickerSubTypes.includes(ticker.subType))
    ) {
      this.pendingCellUpdate = null;
      if (ticker.id !== 171) {
        newCell.allowPVST = false;
      }
      newCell.value = value;
      if (newCell.currency !== ticker.shortName) {
        newCell.exchangeRateDetails = null;
      }
      newCell.currency = isEmptyCell === true ? this.props.currency : ticker.shortName;
      newCell.setExchangeRateDetails(this.props.currency);
      this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
    } else {
      newCell.value = convertCurrency(value, ticker.shortName, this.props.currency, newCell.exchangeRateDate);
      newCell.currency = this.props.currency;
      newCell.setExchangeRateDetails(this.props.currency);
      this.pendingCellUpdate = newCell;

      if (this.pendingCellUpdateTimer !== null) {
        clearTimeout(this.pendingCellUpdateTimer);
      }

      this.pendingCellUpdateTimer = setTimeout(() => {
        if (!this.pendingCellUpdate === false) {
          this.props.onCellUpdate(this.props.cellIndex, this.pendingCellUpdate, this.props.isRowEmpty);
        }
        this.pendingCellUpdateTimer = null;
        this.pendingCellUpdate = null;
      }, 10000);
    }
  }

  handleStarClick(e) {
    if (this.props.disableUserInput === true) {
      return;
    }

    const newValue = !this.props.cell.value ? 1 : 0;
    const newCell = this.props.cell;
    newCell.value = newValue;
    this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
  }

  handleDetailsClick(e) {
    if (this.props.disableUserInput === true) {
      return;
    }

    e.target.blur();
    this.props.onDetailsClick(e);
  }

  handleOpenChartsModalClick(e) {
    if (this.props.disableUserInput === true) {
      return;
    }

    e.target.blur();
    this.props.onOpenChartsModalClick(this.props.sheetIndex, this.props.sectionIndex, this.props.rowIndex);
  }

  handleLinkErrorClick(e) {
    if (this.props.disableUserInput === true) {
      return;
    }
    if (this.props.onLinkErrorClick) {
      this.props.onLinkErrorClick(e);
    }
  }

  handleKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
    if (e.target.id !== this.props.cellId) {
      return false;
    }

    if (this.props.disableUserInput === true) {
      return;
    }

    if (e.key === "ArrowRight" || e.key === "ArrowLeft" || e.key === "ArrowUp" || e.key === "ArrowDown") {
      // Don't allow arrow navigation to work when cell is being edited
      if (this.state.mode === cellMode.EDIT) {
        e.nativeEvent.stopImmediatePropagation();
        return true;
      } else {
        e.preventDefault();
      }
    }

    if (e.key === "Enter" && e.shiftKey === false) {
      if (this.props.isEditable === false) {
        return false;
      }
      if (this.state.mode === cellMode.EDIT) {
        e.target.blur();
        this.enterSelectMode(e.target);
        this.props.onMoveToNextCell();
      } else if (this.state.mode === cellMode.SELECT) {
        if (
          !this.props.cell.invalidInputText === true &&
          (this.props.cell.value === null || this.props.cell.value === undefined)
        ) {
          if (this.props.onUserLeavingCellEmpty) {
            this.props.onUserLeavingCellEmpty(this.props.cellIndex);
          }
          if (this.props.allowAddRow === true) {
            this.addNewRow(true, true);
          } else {
            this.props.onMoveToNextCell();
          }
        } else {
          this.enterEditMode(e.target);
        }
      } else if (this.state.mode === cellMode.UPDATE) {
        this.enterSelectMode(e.target);

        if (this.props.allowAddRow === true) {
          this.addNewRow(true, true);
        } else {
          this.props.onMoveToNextCell();
        }
      }
      return true;
    } else if (e.key === "Enter" && e.shiftKey === true) {
      if (this.props.isEditable === false) {
        return true;
      }
      if (this.state.mode === cellMode.EDIT) {
        return true;
      }
      if (e.metaKey === true || e.ctrlKey === true) {
        this.addNewRow(false, true);
      } else {
        this.addNewRow(true, true);
      }
    } else if (e.key === "Escape") {
      if (this.state.mode === cellMode.EDIT) {
        this.enterSelectMode(e.target);
        this.selectAllText(e.target);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      } else if (this.state.mode === cellMode.SELECT || this.state.mode === cellMode.UPDATE) {
        if (this.props.allowRemoveRow) {
          this.props.onRemoveRow();
        } else {
          e.target.blur();
        }
      }
      return true;
    }

    if (e.key === "Tab" && e.shiftKey === false) {
      if (this.props.isInitialRowEdit === true) {
        e.preventDefault();

        if (this.props.allowAddRow === true) {
          this.addNewRow(true, true);
        } else {
          this.props.onMoveToNextCell();
        }
      }
      return true;
    }
    return false;
  }

  addNewRow(addBelow, removeIfEmpty) {
    this.props.onAddNewRow(addBelow, removeIfEmpty);
  }

  handleFocus(e) {
    this.selectAllText(e.target);
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    this.cellValueOnEnter = this.props.cell.value;
    if (this.props.isEnterModeOnFocus) {
      const targetElem = e.target;
      setTimeout(() => {
        this.enterEditMode(targetElem);
      }, 0);
    }
  }

  handleUndo = (e, targetElem, valueToSet) => {
    if (!targetElem) {
      return;
    }

    const valueToSetOnRedo = this.props.cell.value;
    if (valueToSet && valueToSet !== this.props.cell.value) {
      setUndoQueue(() => {
        const newCell = this.props.cell;
        newCell.value = valueToSet;
        this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
        this.handleRedo(e, targetElem, valueToSetOnRedo);
        return true;
      });
    }
  };

  handleRedo(e, targetElem, valueToSet) {
    if (!targetElem) {
      return;
    }

    const valueToSetOnUndo = this.props.cell.value;
    if (valueToSet && valueToSet !== this.props.cell.value) {
      setRedoQueue(() => {
        const newCell = this.props.cell;
        newCell.value = valueToSet;
        this.props.onCellUpdate(this.props.cellIndex, newCell, this.props.isRowEmpty);
        this.handleUndo(e, targetElem, valueToSetOnUndo);
        return true;
      });
    }
  }

  handleBlur(e) {
    this.enterSelectMode(e.target);
    if (this.props.onBlur) {
      this.props.onBlur(e, this.props.cellIndex);
    }

    if (!this.pendingCellUpdate === false) {
      this.pendingCellUpdate.currency = this.props.currency;
      this.props.onCellUpdate(this.props.cellIndex, this.pendingCellUpdate, this.props.isRowEmpty);

      if (this.props.onCellInvalidTickerAdded) {
        this.props.onCellInvalidTickerAdded(this.props.cellIndex);
      }
      this.pendingCellUpdate = null;
    }
    if (this.pendingCellUpdateTimer !== null) {
      clearTimeout(this.pendingCellUpdateTimer);
    }

    this.handleUndo(e, e.target, this.cellValueOnEnter);

    this.cellValueOnEnter = null;
  }

  handleDoubleClick(e) {
    if (this.props.isEditable === false || this.props.disableUserInput === true) {
      return;
    }
    this.enterEditMode(e.target);
  }

  handleClick(e) {
    if (this.props.cell.enterEditModeOnClick === true && this.props.isEditable === true) {
      this.enterEditMode(e.target);
    }
  }

  handlePaste(e) {
    if (this.props.isEditable === false || this.props.disableUserInput === true) {
      return;
    }
    const copiedText = e.clipboardData.getData("Text");
    const shouldPreventDefault = this.props.onPaste(copiedText);
    if (shouldPreventDefault === true) {
      e.preventDefault();
    }
  }

  handleShowTickerSelection(e) {
    if (this.props.onShowTickerSelection) {
      this.props.onShowTickerSelection(e, null);
    }
  }

  handleMouseEnterEvent(e) {
    if (this.props.onShowTickerSelection && !this.props.cell.invalidInputText === false) {
      this.props.onShowTickerSelection(e, this.props.cellIndex);
    } else if (this.props.cell.type === cellType.TICKER_ERROR) {
      this.props.onShowTickerSelection(e, null);
    }
  }

  handleMouseEnterLeave(e) {
    if (this.props.onHideTickerSelection) {
      this.props.onHideTickerSelection(e);
    }
  }

  setCellModeDataAttribute(target, mode) {
    target.removeAttribute(CELL_MODE_DATA_ATTRIBUTE_KEY);
    target.setAttribute(CELL_MODE_DATA_ATTRIBUTE_KEY, mode);
  }

  enterSelectMode(target) {
    this.setCellModeDataAttribute(target, cellMode.SELECT);
    this.setState({ ...this.state, mode: cellMode.SELECT });
  }

  enterEditMode(target) {
    if (this.state.mode === cellMode.SELECT || this.state.mode === cellMode.UPDATE) {
      this.setCellModeDataAttribute(target, cellMode.EDIT);
      this.setState({ ...this.state, mode: cellMode.EDIT });
      target.selectionEnd = target.selectionStart = 0;
      window.getSelection().removeAllRanges();
      target.selectionStart = target.selectionEnd = target.value.length;

      if (this.props.onHideTickerSelection) {
        this.props.onHideTickerSelection();
      }
    }
  }

  enterUpdateMode(target) {
    this.setCellModeDataAttribute(target, cellMode.UPDATE);
    this.setState({ ...this.state, mode: cellMode.UPDATE });
  }

  selectAllText(target) {
    clearTimeout(cellSelectAllTimeoutId);
    cellSelectAllTimeoutId = setTimeout(() => {
      if (this.state.mode === cellMode.SELECT) {
        target.select();
      }
    }, 0);
  }

  getHintText() {
    if (window.innerWidth < window.screen.width) {
      return null;
    }
    if (this.state.hideHintDueToResize === true) {
      return null;
    }
    if (this.props.showHint === false) {
      return null;
    }
    if (this.props.cellIndex !== this.props.cellIndexToStretch) {
      return null;
    }
    if (
      this.props.cell.type === cellType.HEADER ||
      this.props.cell.type === cellType.ADDNEW_BUTTON ||
      this.props.cell.type === cellType.BLANKSPACE
    ) {
      return null;
    }
    if (this.props.isInitialRowEdit && this.props.cell.showHint === true) {
      return (
        <span>
          <span>{i18n.t("gridEntry.typeHint")}</span>
          <HintKey>{i18n.t("gridEntry.typeHintKey")}</HintKey>
        </span>
      );
    } else {
      return null;
    }
  }

  renderHeavyIcon(actualValue, targetValue) {
    const parsed = parseNumberStringToFloat(targetValue);
    if (actualValue === targetValue || !targetValue === true) {
      return null;
    } else if (actualValue > parsed) {
      return <LeftHeavyIconComponent />;
    } else if (parsed > actualValue) {
      return <RightHeavyIconComponent />;
    }
  }

  renderRecommendationBadge(actualValue, targetValue) {
    const parsed = parseNumberStringToFloat(targetValue);
    if (!targetValue === true) {
      return null;
    } else if (parsed <= actualValue - 5 || parsed >= actualValue + 5) {
      return <RecommendationBadge />;
    }
  }

  handleAddNewClick = e => {
    if (this.props.onAddNewClick) {
      this.addNewRedDotRef.current.setPreferenceFlag(true);
      this.props.onAddNewClick(e);
    }
  };

  render() {
    const cellId = this.props.cellId;
    const dataCyId = this.props.dataCyId;
    const maskAllValues = this.props.userPreferences ? this.props.userPreferences.maskAllValues : false;
    const cell = this.props.cell;
    if (!cell) {
      return null;
    }
    const type = this.props.cell.type;
    const mode = this.state.mode;
    const hintText = this.getHintText();
    const accessoryView = cell.getAccessoryView(
      this.props.sheetIndex,
      this.props.sectionIndex,
      this.props.rowIndex,
      this.props.cellIndex
    );

    return (
      <>
        {type === cellType.RECAP_CELL ? (
          <ColumnarChartCellComponent
            cell={cell}
            currency={this.props.currency}
            currentValue={this.props.currentValue}
            previousValue={this.props.previousValue}
            isFirstCell={this.props.isFirstCell}
            isLastCell={this.props.isLastCell}
            cellId={cellId}
            isExpandedView={this.props.noOfColumns >= 6}
            rowIndex={this.props.rowIndex}
            sectionIndex={this.props.sectionIndex}
            cellIndex={this.props.cellIndex}
            handleOpenChartsModalClick={this.handleOpenChartsModalClick}
            onDoubleClick={this.handleDoubleClick}
            category={this.props.category}
            handleRecapExpandOrCollapse={this.props.handleRecapExpandOrCollapse}
            isCollapsed={this.props.isCollapsed}
            sectionName={this.props.sectionName}
          />
        ) : (
          <Container cellIndex={this.props.cellIndex} cellIndexToStretch={this.props.cellIndexToStretch}>
            <Cell>
              {hintText !== null && <Hint>{hintText}</Hint>}
              {type === cellType.TEXT && (
                <>
                  <TextInputCell
                    id={cellId}
                    data-cy={dataCyId}
                    value={cell.symbol && this.isCellInWriteMode ? cell.subValue : cell.symbol || cell.value}
                    description={cell.description}
                    linkType={cell.linkType}
                    subType={cell.subType}
                    url={cell.url}
                    linkedIconTitle={cell.toolTip}
                    invalidInputText={cell.invalidInputText}
                    isCellLinked={
                      this.props.isRowLinked === true && this.props.cellIndex === this.props.cellIndexToStretch
                    }
                    isCellVerified={this.props.isRowVerified === true}
                    linkedHoldingsCount={this.props.linkedHoldingsCount}
                    cellIndex={this.props.cellIndex}
                    cellIndexToStretch={this.props.cellIndexToStretch}
                    onUpdate={this.debounce(this.handleInputChange, 100)}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onPaste={this.handlePaste}
                    mode={mode}
                    onDoubleClick={this.handleDoubleClick}
                    placeholder={this.props.isRowComplete === true ? "" : cell.placeholder}
                    autoComplete="new-password"
                    isRowComplete={this.props.isRowComplete}
                    updated={this.props.isRowUpdated}
                    onKeyDown={this.handleKeyDown}
                    isLinking={this.props.isLinking}
                    hasSubValue={cell.subValue && !this.isCellInWriteMode}
                    currencyCellValue={cell.currencyCellValue}
                    relatedId={cell.relatedId}
                    currency={cell.currency}
                    linkContainer={this.props.linkContainer}
                    linkProviderAccountId={this.props.linkProviderAccountId}
                    availableCredit={this.props.availableCredit}
                    accessoryView={accessoryView}
                    textAlignment={cell.textAlignment}
                    unfundedCapital={cell.unfundedCapital}
                    width={cell.width}
                    showDottedLineIfEmpty={cell.showDottedLineIfEmpty}
                    isEditable={this.props.isEditable}
                    hideCellFocusHighlight={cell.hideCellFocusHighlight}
                    cellStyles={
                      !cell.getCellStyles === false && cell.getCellStyles(this.props.rowIndex, this.props.cellIndex)
                    }
                  />
                  {cell.subValue && !this.isCellInWriteMode && <TextInputSubVal>{cell.subValue}</TextInputSubVal>}
                </>
              )}
              {type === cellType.NUMBER && (
                <NumberCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  cellIndex={this.props.cellIndex}
                  cellIndexToStretch={this.props.cellIndexToStretch}
                  onChange={this.handleInputChange}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onPaste={this.handlePaste}
                  mode={mode}
                  onDoubleClick={this.handleDoubleClick}
                  placeholder={this.props.isRowComplete === true ? "" : cell.placeholder}
                  autoComplete="new-password"
                  isRowComplete={this.props.isRowComplete}
                  updated={this.props.isRowUpdated}
                  onKeyDown={this.handleKeyDown}
                  isLinking={this.props.isLinking}
                  textAlignment={cell.textAlignment}
                />
              )}
              {type === cellType.CURRENCY && (
                <CurrencyTypeCell
                  id={cellId}
                  data-cy={dataCyId}
                  gridCell={cell}
                  value={cell.value}
                  invalidInputText={cell.invalidInputText}
                  currency={cell.currency}
                  multiCurrency={cell.multiCurrency}
                  gridCurrency={this.props.currency}
                  cellIndex={this.props.cellIndex}
                  cellIndexToStretch={this.props.cellIndexToStretch}
                  onChange={this.handleCurrencyInputChange}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onPaste={this.handlePaste}
                  mode={mode}
                  enterEditModeOnClick={cell.enterEditModeOnClick}
                  callOnChangeEverytime={cell.callOnChangeEverytime}
                  isInSingleCellMode={cell.isInSingleCellMode}
                  onDoubleClick={this.handleDoubleClick}
                  onClick={this.handleClick}
                  placeholder={
                    this.props.isRowComplete === true && this.props.isSingleCellRow === false ? "" : cell.placeholder
                  }
                  autoComplete="new-password"
                  isRowComplete={this.props.isRowComplete}
                  updated={this.props.isRowUpdated}
                  loading={cell.loading}
                  onKeyDown={this.handleKeyDown}
                  isLinking={this.props.isLinking || cell.fetchingTickerInfo === true}
                  onMouseEnter={this.handleMouseEnterEvent}
                  change={cell.getDayChange(this.props.currency)}
                  isDebt={cell.isDebt}
                  textAlignment={cell.textAlignment}
                  hasError={cell.hasError}
                  accessoryView={accessoryView}
                  hideCellFocusHighlight={cell.hideCellFocusHighlight}
                  ownership={cell.ownership}
                  rate={cell.rate}
                  isReportGridCell={cell.isReportGridCell}
                  isCurrencyInputCell={cell.isCurrencyInput}
                  showDecimal={cell.showDecimal}
                  decimalPlaces={cell.decimalPlaces}
                  width={cell.width}
                  order={cell.order ? cell.order : 0}
                  isEditable={this.props.isEditable}
                  cellStyles={
                    !cell.getCellStyles === false && cell.getCellStyles(this.props.rowIndex, this.props.cellIndex)
                  }
                />
              )}
              {type === cellType.PERCENTAGE && (
                <PercentageCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  isRowComplete={this.props.isRowComplete}
                  hidden={this.props.isSelectable === true && !cell.value === true && !accessoryView === true}
                  disabled={this.props.isSelectable === false}
                  onClick={e =>
                    cell.onClick(
                      e,
                      this.props.sheetIndex,
                      this.props.sectionIndex,
                      this.props.rowIndex,
                      this.props.cellIndex
                    )
                  }
                >
                  {accessoryView}
                  {!accessoryView === false && cell.value === 0 && (
                    <EmptyPercentageValue>{"----"}</EmptyPercentageValue>
                  )}
                  {cell.value !== 0 && (
                    <PercentageValue
                      value={cell.value}
                      darkBackground={false}
                      updated={this.props.isRowUpdated}
                    ></PercentageValue>
                  )}
                </PercentageCell>
              )}
              {type === cellType.OPTIONS && (
                <OptionsCell
                  id={cellId}
                  data-cy={dataCyId}
                  title={cell.toolTip}
                  onClick={this.props.onOptionsClick}
                  visible={!cell.hide === true && !this.props.isHeader === true && !this.props.isFooter === true}
                >
                  <OptionsIconComponent />
                </OptionsCell>
              )}
              {type === cellType.STAR && (
                <StarCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  title={cell.toolTip}
                  onClick={this.handleStarClick}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                >
                  {!cell.value === false && <StarIconYellow />}
                  {!cell.value === true && <StarIconComponent />}
                </StarCell>
              )}
              {type === cellType.LINK_ERROR &&
                (this.props.isLinking ||
                  this.props.isRowLinked ||
                  !this.props.isHeader === false ||
                  !this.props.isFooter === false) && (
                  <ErrorCell
                    id={cellId}
                    data-cy={dataCyId}
                    value={cell.value}
                    onClick={this.handleLinkErrorClick}
                    visible={!this.props.isHeader === true && !this.props.isFooter === true}
                    title={cell.toolTip}
                    isReadOnly={cell.isReadOnly}
                  >
                    <ErrorIconComponent />
                  </ErrorCell>
                )}
              {type === cellType.TICKER_ERROR && (
                <ErrorCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  onClick={this.handleShowTickerSelection}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                  onMouseEnter={this.handleMouseEnterEvent}
                >
                  <ErrorIconComponent />
                </ErrorCell>
              )}
              {type === cellType.LOADER && (
                <LoaderCell
                  id={cellId}
                  data-cy={dataCyId}
                  size={14}
                  thickness={2}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                  tabIndex={0}
                ></LoaderCell>
              )}
              {type === cellType.LINKING_FAILURE && (this.props.isLinking || this.props.isRowLinked) && (
                <ErrorCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                >
                  <LinkingFailureIcon />
                </ErrorCell>
              )}
              {type === cellType.LINKING_INPUT_NEEDED && (this.props.isLinking || this.props.isRowLinked) && (
                <ErrorCell
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                >
                  <ErrorIconComponent />
                </ErrorCell>
              )}
              {type === cellType.DETAILS && (
                <DetailsCell
                  id={cellId}
                  data-cy={dataCyId}
                  wasRowEverComplete={this.props.wasRowEverComplete}
                  onClick={this.handleDetailsClick}
                  title={cell.toolTip}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                >
                  <DetailsIconComponent />
                  {this.props.cell.showHint === true && <DetailsCellBadge />}
                </DetailsCell>
              )}
              {type === cellType.HEAVY_ICON && (
                <HeavyIconCell
                  id={cellId}
                  data-cy={dataCyId}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                  disabled={true}
                >
                  {this.renderHeavyIcon(cell.actualValue, cell.targetValue)}
                </HeavyIconCell>
              )}
              {type === cellType.RECOMMENDATION_BADGE && (
                <RecommendationBadgeCell
                  id={cellId}
                  data-cy={dataCyId}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                  disabled={true}
                >
                  {this.renderRecommendationBadge(cell.actualValue, cell.targetValue)}
                </RecommendationBadgeCell>
              )}
              {type === cellType.EDITABLE_PERCENTAGE_CELL && (
                <PercentageCellForReports
                  id={cellId}
                  data-cy={dataCyId}
                  value={cell.value}
                  isRowComplete={this.props.isRowComplete}
                  visible={!this.props.isHeader === true && !this.props.isFooter === true}
                  mode={mode}
                  onChange={this.handleInputChange}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onPaste={this.handlePaste}
                  onDoubleClick={this.handleDoubleClick}
                  onKeyDown={this.handleKeyDown}
                  width={cell.width}
                  hasUserNotSetATargetValue={cell.hasUserNotSetATargetValue}
                  hasError={cell.hasError}
                  shouldShowApproximateSymbol={cell.shouldShowApproximateSymbol}
                ></PercentageCellForReports>
              )}
              {type === cellType.ADDNEW_MODAL && maskAllValues === false && (
                <AddNewModalButton name="add_new" id="add_new" data-cy={dataCyId} onClick={this.handleAddNewClick}>
                  <PlusAddIcon />
                  {i18n.t("add")} {this.props.category}
                  <AddNewModalRedDot ref={this.addNewRedDotRef} preference="addNew" />
                </AddNewModalButton>
              )}
              {type === cellType.ADDNEW_BUTTON && maskAllValues === false && (
                <AddNewButton name="add_new" id="add_new" data-cy={dataCyId} onClick={this.handleAddNewClick}>
                  {i18n.t("gridSection.addNew")}
                  <AddNewIconImage />
                  <AddNewModalRedDot ref={this.addNewRedDotRef} preference="addNew" />
                </AddNewButton>
              )}
              {type === cellType.HEADER && (
                <HeaderCell
                  cellIndex={this.props.cellIndex}
                  cellIndexToStretch={this.props.cellIndexToStretch}
                  textAlignment={cell.textAlignment}
                  onClick={this.props.onClick}
                  width={cell.width}
                >
                  {this.props.shouldSortIconBeShownOnRightSide ? (
                    <HeaderContainer>
                      {cell.name}
                      <SortIconComponent sortoption={this.props.sortoption} />
                    </HeaderContainer>
                  ) : (
                    <>
                      <SortIconComponent sortoption={this.props.sortoption} />
                      {cell.name}
                    </>
                  )}
                </HeaderCell>
              )}
              {type === cellType.FOOTER_CURRENCY && (
                <FooterCurrencyLabel
                  data-cy={dataCyId}
                  cellIndex={this.props.cellIndex}
                  value={
                    !cell.value === true
                      ? null
                      : isCryptoCurrency(cell.currency)
                      ? cell.value
                      : cell.value > 0 && cell.value < 1
                      ? cell.value
                      : Math.kuberaFloor(cell.value)
                  }
                  currency={cell.currency}
                  hideEllipsis={true}
                />
              )}
              {type === cellType.FOOTER_PERCENTAGE && (
                <FooterPercentageCell data-cy={dataCyId} value={cell.value} isRowComplete={this.props.isRowComplete}>
                  <FooterPercentageValue value={cell.value} darkBackground={true}></FooterPercentageValue>
                </FooterPercentageCell>
              )}
              {type === cellType.BLANKSPACE && (
                <BlankCell cellIndex={this.props.cellIndex} cellIndexToStretch={this.props.cellIndexToStretch}>
                  {accessoryView}
                  {""}
                </BlankCell>
              )}
              {type === cellType.PADDING && <PadCell padding={cell.padding}>{""}</PadCell>}
            </Cell>
          </Container>
        )}
      </>
    );
  }
}

export default GridCell;
