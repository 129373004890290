import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { suggestedChartsSelector, getChartLabelFromId, parseParams } from "@kubera/common";

import SuggestedChartsTabCheckboxes from "./SuggestedChartsTabCheckboxes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SuggestedChartsContainer = styled.div`
  display: flex;
  flex: 1
  margin-top: 27px;
  flex-direction: column;
  margin-bottom: 11px;
`;

const SuggestedChartsComponent = props => {
  const { handleSuggestedChartsTabCheckBoxChange, handleResetToDefaultLink } = props;
  const defaultChartIds = useSelector(suggestedChartsSelector);

  const checkIfTheChartsAreReset = () => {
    return defaultChartIds.some(id => {
      const chartParams = parseParams(id);
      return chartParams.should_be_checked_by_default !== chartParams.is_checked;
    });
  };

  const isChartsReseted = checkIfTheChartsAreReset();
  return (
    <Container>
      <SuggestedChartsContainer>
        {defaultChartIds.map((id, index) => {
          const chartParams = parseParams(id);
          const isChecked = chartParams.is_checked === "true";
          const chartLabel = getChartLabelFromId(id, false);
          return (
            <SuggestedChartsTabCheckboxes
              key={index}
              id={id}
              index={index}
              label={chartLabel}
              checked={isChecked}
              isChartsReseted={isChartsReseted}
              handleSuggestedChartsTabCheckBoxChange={handleSuggestedChartsTabCheckBoxChange}
              handleResetToDefaultLink={handleResetToDefaultLink}
            />
          );
        })}
      </SuggestedChartsContainer>
    </Container>
  );
};
export default SuggestedChartsComponent;
