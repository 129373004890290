import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import RadioButton from "components/inputs/RadioButton";
import CustodianOwnershipComponent from "./CustodianOwnershipComponent";
import DropDown from "components/inputs/DropDown";
import {
  getTickerUsingId,
  tickerTypes,
  tickerSubTypes,
  custodianTypes,
  custodianSubTypes,
  isCryptoLinkingService,
  accountLinkingService,
  getTaxableTypeForCustodian,
  store,
  custodianTaxTypes,
  assetClassesListSelector,
  fetchNetWorthDataForPortfolio,
  currentPortfolioSelector,
  stockMarketCapListSelector,
  stockSectorListSelector,
  stockRegionListSelector,
  REPORTING_TAB_RELEASE_TS,
  updateUserPreferences,
  markHoldingsToast
} from "@kubera/common";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import RedDot from "components/indicators/RedDot";
import { useRef } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  margin-top: 25px;
`;

const InvestableAssetTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const InvestableAssetDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const InvestableAssetRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const InvestableAssetRadioButton = styled(RadioButton)`
  margin-bottom: 20px;
`;

const OwnershipComponent = styled(CustodianOwnershipComponent)`
  padding-top: ${props => (props.isFirstItem === true ? "0px" : "25px")};
`;

const TaxableAssetsTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const TaxableAssetTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const TaxableAssetDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const TaxableAssetRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const TaxableAssetRadioButton = styled(RadioButton)`
  margin-bottom: ${props => (props.isLastButton ? "0px" : "20px")};
`;

const AssetClassTitle = styled(InvestableAssetTitle)`
  margin-top: 25px;
`;

const AssetClassDescription = styled(InvestableAssetDescription)``;
const AssetClassDropDown = styled(DropDown)`
  width: ${props => (props.showFundAllocation ? "100%" : "425px")};
  margin-top: 6px;
`;
const GeographyDropDown = styled(DropDown)`
  width: 200px;
  margin-top: 6px;
  flex: 1;
`;
const SectorDropDown = styled(DropDown)`
  width: 200px;
  margin-top: 6px;
  flex: 1;
`;
const MarketCapDropDown = styled(DropDown)`
  width: 200px;
  margin-top: 6px;
  flex: 1;
`;

const InvestableAssetButtonContainer = styled.div`
  display: flex;
  margin-top: 2px;
`;

const InvestableAssetButton = styled.div`
  text-decoration: underline;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #0074fc;
  cursor: pointer;
`;

const AssetClassDropDownContainer = styled.div`
  display: flex;
  flex: 1;
`;
const AssetClassDropDownTitleContainer = styled.div`
  display: flex;
  flex: 1
  margin-top: 20px;
`;

const AssetTitleContainer = styled.div`
  display: flex;
`;

const ReportingTabRedDot = styled(RedDot)`
  position: static;
  display: inline-block;
  margin-left: -12px;
  margin-right: 5px;
  width: 6px;
  height: 6px;
  background: "#FF6464";
  margin-top: 28px;
`;

const GeographyTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  flex: 1;
`;
const SectorTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  flex: 1;
`;
const MarketCapTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  flex: 1;
`;

const custodianReportingComponent = props => {
  const dispatch = useDispatch();
  const custodian = props.detailsInfo;
  const custodianDetails = props.details;
  const taxOnUnrealizedGains = props.taxOnUnrealizedGains;
  const currentPortfolio = currentPortfolioSelector(store.getState());
  const reportingTabRedDotRef = useRef();

  const getInvestableRadioButtonOptions = () => {
    const custodian = props.detailsInfo;
    const custodianTicker = getTickerUsingId(custodian.valueTickerId);

    if (
      !custodian.parentId === false ||
      (custodian.subType === custodianSubTypes.INVESTMENT && custodian.holdingsCount === 0) ||
      (!custodian.linkType === true &&
        (custodianTicker.type === tickerTypes.STOCK ||
          custodianTicker.type === tickerTypes.FUND ||
          custodianTicker.type === tickerTypes.CRYPTO ||
          custodianTicker.type === tickerTypes.BOND ||
          custodianTicker.type === tickerTypes.DERIVATIVE ||
          custodianTicker.type === tickerTypes.INDEX))
    ) {
      return [
        {
          value: custodianTypes.CASH_IN_HAND,
          label: i18n.t("investableAsset.cash"),
          checked: custodian.type === custodianTypes.CASH_IN_HAND
        },
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      custodian.subType === custodianSubTypes.HOME ||
      custodian.linkType === accountLinkingService.CARS ||
      custodian.linkType === accountLinkingService.DOMAINS
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      custodianTicker.subType === tickerSubTypes.PRECIOUS_METALS ||
      ["wallet", "locked", "loan"].includes(custodian.linkContainer)
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (!custodian.parentId === true && custodian.subType === custodianSubTypes.CASH) {
      return [
        {
          value: custodianTypes.CASH_IN_HAND,
          label: i18n.t("investableAsset.cash"),
          checked: custodian.type === custodianTypes.CASH_IN_HAND
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      (custodian.subType === custodianSubTypes.INVESTMENT && custodian.holdingsCount > 0) ||
      isCryptoLinkingService(custodian.linkType) === true
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: isCryptoLinkingService(custodian.linkType)
            ? i18n.t("investableAsset.cryptoHoldings")
            : i18n.t("investableAsset.cashHoldings"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    return [
      {
        value: custodianTypes.CASH_IN_HAND,
        label: i18n.t("investableAsset.cash"),
        checked: custodian.type === custodianTypes.CASH_IN_HAND
      },
      {
        value: custodianTypes.INVESTABLE,
        label: i18n.t("investableAsset.investable"),
        checked: custodian.type === custodianTypes.INVESTABLE
      },
      {
        value: custodianTypes.NON_INVESTABLE,
        label: i18n.t("investableAsset.nonInvestable"),
        checked: custodian.type === custodianTypes.NON_INVESTABLE
      }
    ];
  };

  const handleInvestableAssetRadioButtonOnChange = value => {
    props.onCustodianUpdate({ type: value }, true);
  };

  const getInvestableOptionsForBrokerages = () => {
    return [
      {
        value: custodianTypes.INVESTABLE,
        id: "investable",
        label: "Mark holdings as Investable"
      },
      {
        value: custodianTypes.NON_INVESTABLE,
        id: "nonInvestable",
        label: "Mark holdings as Non Investable"
      }
    ];
  };

  const getTaxableAssetRadioButtonOptions = () => {
    const taxableAssetType = getTaxableTypeForCustodian(store.getState());
    return [
      {
        value: custodianTaxTypes.TAXABLE,
        label: i18n.t("taxableAsset.taxable"),
        checked: !taxableAssetType || custodianTaxTypes.TAXABLE === taxableAssetType
      },
      {
        value: custodianTaxTypes.TAX_DEFERRED,
        label: i18n.t("taxableAsset.taxDefered"),
        checked: custodianTaxTypes.TAX_DEFERRED === taxableAssetType
      },
      {
        value: custodianTaxTypes.TAX_FREE,
        label: i18n.t("taxableAsset.taxFree"),
        checked: custodianTaxTypes.TAX_FREE === taxableAssetType
      }
    ];
  };

  const handleInvestableButtonClick = selectedOption => {
    if (selectedOption.id === "investable") {
      props.onCustodianUpdate({ type: custodianTypes.INVESTABLE }, true);
      dispatch(markHoldingsToast(true));
    } else if (selectedOption.id === "nonInvestable") {
      props.onCustodianUpdate({ type: custodianTypes.NON_INVESTABLE }, true);
      dispatch(markHoldingsToast(false));
    }
  };

  const renderInvestableAssetButton = holdingsCount => {
    if (holdingsCount > 0) {
      return (
        <>
          <InvestableAssetTitle>{i18n.t("investableAsset.title")}</InvestableAssetTitle>
          <InvestableAssetDescription>{i18n.t("investableAsset.brokerage.description")}</InvestableAssetDescription>
          <InvestableAssetButtonContainer>
            {getInvestableOptionsForBrokerages().map(option => {
              return (
                <InvestableAssetButton
                  key={option.id}
                  onClick={() => {
                    handleInvestableButtonClick(option);
                  }}
                >
                  {option.label}
                </InvestableAssetButton>
              );
            })}
          </InvestableAssetButtonContainer>
        </>
      );
    } else {
      return (
        <>
          <InvestableAssetTitle>{i18n.t("investableAsset.title")}</InvestableAssetTitle>
          <InvestableAssetDescription>{i18n.t("investableAsset.description")}</InvestableAssetDescription>
          <InvestableAssetRadioButtonContainer>
            {getInvestableRadioButtonOptions().map((option, index) => {
              return (
                <InvestableAssetRadioButton
                  key={index}
                  label={option.label}
                  value={option.value}
                  checked={option.checked}
                  disabled={props.isReadOnly}
                  onChange={handleInvestableAssetRadioButtonOnChange}
                />
              );
            })}
          </InvestableAssetRadioButtonContainer>
        </>
      );
    }
  };

  const showAssetClassSelectionDropDown = holdingsCount => {
    return !holdingsCount;
  };

  const assetClassesList = assetClassesListSelector(store.getState());
  const stockMarkeCapList = stockMarketCapListSelector(store.getState());
  const stockSectorList = stockSectorListSelector(store.getState());
  const stockRegionList = stockRegionListSelector(store.getState());

  const getAssetClassForCustodian = () => {
    const tickerDetails = getTickerUsingId(custodian.valueTickerId);
    const tickerSubType = tickerDetails?.subType;
    const tickerType = tickerDetails?.type;
    if (custodian.type === custodianTypes.CASH_IN_HAND) {
      return { ...assetClassesList[9], editable: false };
    } else if (tickerType !== "fiat") {
      if (tickerSubType === "pm" || tickerSubType === "spm") {
        return { ...assetClassesList[5], editable: false };
      } else if (tickerSubType === "sre") {
        return { ...assetClassesList[6], editable: false };
      } else if (tickerSubType === "scr" || tickerType === "crypto") {
        return { ...assetClassesList[4], editable: false };
      } else if (tickerType === "stock") {
        return { ...assetClassesList[1], editable: false };
      } else if (tickerType === "bond") {
        return { ...assetClassesList[2], editable: false };
      } else if (tickerType === "derivative") {
        return { ...assetClassesList[7], editable: false };
      } else if (tickerType === "fund" || tickerType === "index") {
        if (tickerDetails?.countryCode === "US") {
          // US funds
          const custodianTickerMetaData = custodianDetails?.tickerMeta;
          if (custodianTickerMetaData) {
            const assetAllocationForFund = custodianTickerMetaData.allocation || {};
            let fundPercentageInStocks = 0;
            let fundPercentageInBonds = 0;
            let fundPercentageInOthers = 0;
            let fundPercentageInCashEquivalent = 0;
            let fundPercentageInRealEstate = 0;
            let fundPercentageInCrypto = 0;
            let fundPercentageInPreciousMetals = 0;
            for (const [key, value] of Object.entries(assetAllocationForFund)) {
              if (key === "nonUsStock" || key === "usStock") {
                fundPercentageInStocks = fundPercentageInStocks + (value.long - value.short);
              } else if (key === "bond") {
                fundPercentageInBonds = fundPercentageInBonds + (value.long - value.short);
              } else if (key === "cash") {
                fundPercentageInCashEquivalent = fundPercentageInCashEquivalent + (value.long - value.short);
              } else if (key === "crypto") {
                fundPercentageInCrypto = fundPercentageInCrypto + (value.long - value.short);
              } else if (key === "realty") {
                fundPercentageInRealEstate = fundPercentageInRealEstate + (value.long - value.short);
              } else if (key === "metal") {
                fundPercentageInPreciousMetals = fundPercentageInPreciousMetals + (value.long - value.short);
              } else {
                fundPercentageInOthers = fundPercentageInOthers + (value.long - value.short);
              }
            }

            const fundPercentageInStocksRounded = Math.kuberaRoundToDecimalPlaces(fundPercentageInStocks, 2);
            const fundPercentageInBondsRounded = Math.kuberaRoundToDecimalPlaces(fundPercentageInBonds, 2);
            const fundPercentageInOthersRounded = Math.kuberaRoundToDecimalPlaces(fundPercentageInOthers, 2);
            const fundPercentageInCashEquivalentRounded = Math.kuberaRoundToDecimalPlaces(
              fundPercentageInCashEquivalent,
              2
            );
            const fundPercentageInRealEstateRounded = Math.kuberaRoundToDecimalPlaces(fundPercentageInRealEstate, 2);
            const fundPercentageInCryptoRounded = Math.kuberaRoundToDecimalPlaces(fundPercentageInCrypto, 2);
            const fundPercentageInPreciousMetalsRounded = Math.kuberaRoundToDecimalPlaces(
              fundPercentageInPreciousMetals,
              2
            );

            const fundPercentageObject = {
              Stocks: fundPercentageInStocksRounded,
              Bonds: fundPercentageInBondsRounded,
              Others: fundPercentageInOthersRounded,
              "Cash Equivalent": fundPercentageInCashEquivalentRounded,
              "Real Estate": fundPercentageInRealEstateRounded,
              Crypto: fundPercentageInCryptoRounded,
              "Precious Metals": fundPercentageInPreciousMetalsRounded
            };
            let fundPercentageItems = Object.entries(fundPercentageObject).filter(([key, value]) => value !== 0);
            let assetClassDropDownLabel;
            if (fundPercentageItems.length === 0) {
              return { ...assetClassesList[3], name: "Unknown", editable: false };
            } else if (fundPercentageItems.length === 1) {
              assetClassDropDownLabel = fundPercentageItems
                .map(([key, value]) => {
                  if (key === "Cash Equivalent") {
                    key = "Cash Eqv";
                  }
                  if (key === "Miscellaneous") {
                    key = "Misc";
                  }
                  if (key === "Precious Metals") {
                    key = "Precious Metal";
                  }
                  return key;
                })
                .join("");
            } else {
              assetClassDropDownLabel = fundPercentageItems
                .map(([key, value]) => {
                  if (key === "Cash Equivalent") {
                    key = "Cash Eqv";
                  }
                  if (key === "Miscellaneous") {
                    key = "Misc";
                  }
                  if (key === "Precious Metals") {
                    key = "Precious Metals";
                  }
                  return key + " " + value + "%";
                })
                .join(", ");
            }
            return { ...assetClassesList[3], name: assetClassDropDownLabel, editable: false, showFundAllocation: true };
          }
          return { ...assetClassesList[3], name: "Unknown", editable: false };
        } else {
          // non US funds
          if (custodian.assetClassId) {
            const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.assetClassId);
            return { ...assetClass, editable: true };
          }
          return { ...assetClassesList[3], editable: true };
        }
      }
    } else {
      // handle  fiat
      if (tickerDetails.id === 171) {
        if (custodian.assetClassId) {
          const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.assetClassId);
          return { ...assetClass, editable: true };
        }
        return { ...assetClassesList[1], editable: true };
      } else if (
        custodian.linkType === accountLinkingService.CARS ||
        tickerSubType === tickerSubTypes.CARS ||
        custodian.subType === custodianSubTypes.CAR ||
        custodian.subType === "cars"
      ) {
        return { ...assetClassesList[8], editable: false };
      } else if (
        custodian.linkType === accountLinkingService.DOMAINS ||
        tickerSubType === tickerSubTypes.DOMAINS ||
        custodian.subType === custodianSubTypes.DOMAIN ||
        custodian.subType === "domains"
      ) {
        return { ...assetClassesList[10], editable: false };
      } else if (custodian.linkType === accountLinkingService.ZILLOW || custodian.subType === custodianSubTypes.HOME) {
        return { ...assetClassesList[6], editable: false };
      } else if (tickerSubType === "pm" || tickerSubType === "spm") {
        return { ...assetClassesList[5], editable: false };
      } else {
        if (custodian.assetClassId) {
          const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.assetClassId);
          return { ...assetClass, editable: true };
        }
        return { ...assetClassesList[0], editable: true };
      }
    }
  };

  // const assetClassIdForCustodian = getAssetClassIdForCustodian();
  const assetClassForCustodian = getAssetClassForCustodian();
  const handleAssetClassSelection = item => {
    props.onCustodianUpdate({ assetClassId: item.id }, true, () => {
      dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
    });
  };
  const handleGeographySelection = item => {
    props.onCustodianUpdate({ worldRegionId: item.id }, true, () => {
      dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
    });
  };
  const handleSectorSelection = item => {
    props.onCustodianUpdate({ sector: item.id }, true, () => {
      dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
    });
  };
  const handleMarketCapSelection = item => {
    props.onCustodianUpdate({ marketCapType: item.id }, true, () => {
      dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
    });
  };

  const showAssetClassSubClasses = () => {
    return assetClassForCustodian.id === 2 && assetClassForCustodian.editable;
    //return !custodian.linkType === true && assetClassForCustodian.id === 2;
  };

  const handleTaxableAssetChange = (value, taxDetailsForCustodian) => {
    props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          ...taxDetailsForCustodian,
          taxableAssetType: value
        })
      },
      true
    );
  };

  const renderAssetClassSelectionDropDown = () => {
    const assetClassesListForDropDown = assetClassesList.map(assetClass => {
      const isSelectedAssetClass = assetClass.id === assetClassForCustodian.id;

      return {
        ...assetClass,
        label: isSelectedAssetClass ? assetClassForCustodian.name : assetClass.name,
        selected: assetClass.id === assetClassForCustodian.id
      };
    });
    if (!showAssetClassSubClasses()) {
      return (
        <>
          <AssetTitleContainer>
            {(custodian.isManual === true || custodian.valueTickerId === 171) && (
              <ReportingTabRedDot
                ref={reportingTabRedDotRef}
                preference="isReportingTabTipShown"
                showIfUserBeforeThisTs={REPORTING_TAB_RELEASE_TS}
              />
            )}
            <AssetClassTitle>{i18n.t("assetClass.title")}</AssetClassTitle>
          </AssetTitleContainer>

          <AssetClassDescription>{i18n.t("assetClass.description")}</AssetClassDescription>
          <AssetClassDropDown
            items={assetClassesListForDropDown}
            width={425}
            disabled={!assetClassForCustodian.editable}
            onSelection={handleAssetClassSelection}
            greyoutindicator={!assetClassForCustodian.editable}
            showFundAllocation={assetClassForCustodian.showFundAllocation}
            hideIndicatorForDisabled={assetClassForCustodian.showFundAllocation}
          />
        </>
      );
    } else {
      const geographysListForDropDown = stockRegionList.map(region => {
        return {
          ...region,
          label: region.name,
          selected: custodian.worldRegionId ? region.id === custodian.worldRegionId : region.id === "OT"
        };
      });
      const sectorListForDropDown = stockSectorList
        .map(sector => {
          return {
            ...sector,
            label: sector.name,
            selected: custodian.sector ? sector.id === custodian.sector : sector.id === 9
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
      const marketCapListForDropDown = stockMarkeCapList.map(marketCap => {
        return {
          ...marketCap,
          label: marketCap.name,
          selected: custodian.marketCapType ? marketCap.id === custodian.marketCapType : marketCap.id === 0
        };
      });
      return (
        <>
          <AssetTitleContainer>
            {(custodian.isManual === true || custodian.valueTickerId === 171) && (
              <ReportingTabRedDot
                ref={reportingTabRedDotRef}
                preference="isReportingTabTipShown"
                showIfUserBeforeThisTs={REPORTING_TAB_RELEASE_TS}
              />
            )}
            <AssetClassTitle>{i18n.t("assetClass.title")}</AssetClassTitle>
          </AssetTitleContainer>

          <AssetClassDescription>{i18n.t("assetClass.description")}</AssetClassDescription>

          <AssetClassDropDown
            items={assetClassesListForDropDown}
            width={425}
            disabled={!assetClassForCustodian.editable}
            onSelection={handleAssetClassSelection}
            greyoutindicator={!assetClassForCustodian.editable}
          />
          <AssetClassDropDownTitleContainer>
            <GeographyTitle>{i18n.t("geography.title")}</GeographyTitle>
            <SectorTitle>{i18n.t("sector.title")}</SectorTitle>
            <MarketCapTitle>{i18n.t("marketCap.title")}</MarketCapTitle>
          </AssetClassDropDownTitleContainer>
          <AssetClassDropDownContainer>
            <GeographyDropDown
              items={geographysListForDropDown}
              onSelection={handleGeographySelection}
              borderWidth={"1px 0.5px 1px 1px"}
            />
            <SectorDropDown
              items={sectorListForDropDown}
              onSelection={handleSectorSelection}
              borderWidth={"1px 0.5px 1px 0.5px"}
            />
            <MarketCapDropDown
              items={marketCapListForDropDown}
              onSelection={handleMarketCapSelection}
              borderWidth={"1px 1px 1px 0.5px"}
            />
          </AssetClassDropDownContainer>
        </>
      );
    }
  };

  useEffect(() => {
    return () => {
      if (
        props.userPreferences.isReportingTabTipShown === false &&
        (custodian.isManual === true || custodian.valueTickerId === 171)
      ) {
        dispatch(updateUserPreferences({ isReportingTabTipShown: true }));
      }
    };
  }, []);

  return (
    <Container className={props.className}>
      {custodian.linkType !== accountLinkingService.KUBERA_PORTFOLIO && (
        <>{renderInvestableAssetButton(custodian.holdingsCount)}</>
      )}
      {showAssetClassSelectionDropDown(custodian.holdingsCount) && <>{renderAssetClassSelectionDropDown()}</>}
      {custodian.linkType !== accountLinkingService.KUBERA_PORTFOLIO && props.detailsInfo.hidden !== 1 && (
        <TaxableAssetsTypeContainer>
          <TaxableAssetTitle>{i18n.t("taxableAsset.title")}</TaxableAssetTitle>
          <TaxableAssetDescription>{i18n.t("taxableAsset.description")}</TaxableAssetDescription>
          <TaxableAssetRadioButtonContainer>
            {getTaxableAssetRadioButtonOptions(taxOnUnrealizedGains).map((option, index) => {
              return (
                <TaxableAssetRadioButton
                  key={index}
                  label={option.label}
                  value={option.value}
                  checked={option.checked}
                  disabled={props.isReadOnly}
                  onChange={() => {
                    handleTaxableAssetChange(option.value, taxOnUnrealizedGains);
                  }}
                  isLastButton={index === getTaxableAssetRadioButtonOptions(taxOnUnrealizedGains).length - 1}
                />
              );
            })}
          </TaxableAssetRadioButtonContainer>
        </TaxableAssetsTypeContainer>
      )}
    </Container>
  );
};
export default custodianReportingComponent;
