import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import EmailInput from "components/inputs/EmailInput";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import { sendMultiuserInvite, isEmailValid, apiErrorCodes, showBlackPaywallIfQualifies } from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import DropDown from "components/inputs/DropDown";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const UserDialog = styled(Dialog)`
  position: relative;
  width: 500px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: ${props => (props.hasError === true ? "red" : "black")};
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: ${props => (props.hasError === true ? "1px solid red" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  background: #ffffff;
  border: ${props => (props.hasError === true ? "1px solid red" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-weight: normal;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const AddButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

const SubmitErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
  text-transform: uppercase;
`;

const PermissionDropDown = styled(DropDown)`
  margin-top: 4px;
  text-transform: capitalize;
`;

class AddUserDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      nameErrorMessage: null,
      email: "",
      emailErrorMessage: null,
      isSubmitting: false,
      submitErrorMessage: null,
      writeEnabled: 1
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.handleInviteUserButtonClick = this.handleInviteUserButtonClick.bind(this);
    this.handlePermissionDropDownSelection = this.handlePermissionDropDownSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleInviteUserButtonClick(e);
      return true;
    }
    return false;
  }

  handleOverlayDismiss() {
    if (this.state.isSubmitting === false) {
      this.props.onDismiss();
    }
  }

  handleNameInputChange(e) {
    this.setState({ name: e.target.value, nameErrorMessage: null });
  }

  handleEmailInputChange(e) {
    this.setState({ email: e.target.value, emailErrorMessage: null });
  }

  handlePermissionDropDownSelection(item) {
    this.setState({ writeEnabled: item.id });
  }

  handleInviteUserButtonClick(e) {
    if (!this.state.name === true) {
      this.setState({ nameErrorMessage: i18n.t("fullname") });
      return;
    }
    if (!this.state.email === true) {
      this.setState({ emailErrorMessage: i18n.t("email") });
      return;
    }
    if (isEmailValid(this.state.email) === false) {
      this.setState({ emailErrorMessage: i18n.t("wrongEmailFormat") });
      return;
    }

    this.setState({ isSubmitting: true, submitErrorMessage: null });

    this.props.sendMultiuserInvite(
      this.state.name,
      this.state.email,
      { ...this.props.access, write: this.state.writeEnabled },
      () => {
        this.setState({ isSubmitting: false }, () => {
          this.handleOverlayDismiss();
        });
      },
      apiError => {
        if (apiError.errorCode === apiErrorCodes.EMAIL_IN_USE || apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY) {
          this.setState({ isSubmitting: false, emailErrorMessage: i18n.t("emailAlreadyInUse") });
        } else {
          this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
        }
      }
    );
  }

  getPermissionDropDownData() {
    return [
      {
        id: 1,
        label: i18n.t("addUserPermission.writable"),
        selected: this.state.writeEnabled === 1
      },
      {
        id: 0,
        label: i18n.t("addUserPermission.readOnly"),
        selected: this.state.writeEnabled === 0
      }
    ];
  }

  render() {
    const name = this.state.name;
    const nameErrorMessage = this.state.nameErrorMessage;
    const email = this.state.email;
    const emailErrorMessage = this.state.emailErrorMessage;
    const isSubmitting = this.state.isSubmitting;
    const submitErrorMessage = this.state.submitErrorMessage;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <UserDialog>
          <Container>
            <Title>{this.props.title || i18n.t("addUser")}</Title>
            <FieldTitle hasError={!nameErrorMessage === false}>{i18n.t("fullname")}</FieldTitle>
            <NameField
              placeholder={i18n.t("fullname")}
              value={name}
              onChange={this.handleNameInputChange}
              hasError={!nameErrorMessage === false}
              autoFocus
            />
            <FieldTitle hasError={!emailErrorMessage === false}>{emailErrorMessage || i18n.t("email")}</FieldTitle>
            <EmailField
              placeholder={i18n.t("emailId")}
              value={email}
              hasError={!emailErrorMessage === false}
              onChange={this.handleEmailInputChange}
            />
            <FieldTitle>{i18n.t("permission")}</FieldTitle>
            <PermissionDropDown
              width={380}
              items={this.getPermissionDropDownData()}
              onSelection={this.handlePermissionDropDownSelection}
              tabIndex={0}
            />
            {!submitErrorMessage === false && (
              <SubmitErrorMessage
                dangerouslySetInnerHTML={{
                  __html: submitErrorMessage
                }}
              />
            )}
            <AddButton
              title={i18n.t("sendInvite")}
              onClick={this.handleInviteUserButtonClick}
              isLoading={isSubmitting}
            />
          </Container>
        </UserDialog>
      </DialogOverlay>
    );
  }
}

const mapDispatchToProps = {
  sendMultiuserInvite,
  showBlackPaywallIfQualifies
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AddUserDialog));
