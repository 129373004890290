import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  searchTickerInfo,
  currentPortfolioSelector,
  getTickersForText,
  stocksPopularProvidersList,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  exchangeCodeToNameMap,
  addTickerInfoAction,
  tickerTypes,
  setLinkType,
  utilityStatus,
  getTickerUsingShortNameReselectFn,
  UNKNOWN_TICKER_SHORT_NAME,
  useHistory
} from "@kubera/common";

import requestIdleCallback from "utilities/requestIdleCallback";
import AutocompleteSearchInp from "components/inputs/AutocompleteSearchInp";
import StockProvidersListComponent from "components/link_account/StockProvidersListComponent";
import NumberInput from "components/inputs/NumberInput";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import NothingFoundComponent from "./NothingFoundComponent";

import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const SearchInpWrapper = styled.div`
  margin-bottom: 20px;
`;

const SearchListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 9px;
  cursor: pointer;
  box-sizing: border-box;

  &.is-active {
    background: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const SearchListHead = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
`;

const SearchListSymbol = styled.span`
  display: inline-block;
  margin-right: 5px;
  font-weight: 500;
  color: #000;
`;

const SearchListFooter = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
`;

const ProvidersList = styled(StockProvidersListComponent)`
  margin-top: -15px;
`;

const TickerContainer = styled.div`
  margin-bottom: 20px;
`;

const TickerHead = styled.div`
  position: relative;
  padding: 11px 14px 9px;
  background: #ebebeb;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
`;

const TickerHeadSymbol = styled.div`
  line-height: 150%;
`;

const TickerHeadFooter = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  padding-right: 25px;
`;

const RemoveIconComponent = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
  padding: 10px;
  cursor: pointer;
`;

const TickerInput = styled(NumberInput)`
  padding: 18px 14px 15px;
  box-sizing: border-box;
  width: 100%;
  border: ${props => (props.isError ? "1px solid #ff0000" : "1px solid rgba(0, 0, 0, 0.4)")};
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const TickersForm = styled.form`
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SearchMoreTxt = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
`;

const StocksConnectComponent = props => {
  const { searchInputPlaceholder = "", searchHint = "", type = tickerTypes.STOCK } = props;

  const portfolio = useSelector(currentPortfolioSelector);
  const getTickerUsingShortNameWithState = useSelector(getTickerUsingShortNameReselectFn);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchTimeoutId = useRef(null);
  const formRef = useRef();
  const searchInpRef = useRef();

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const getSearchTickerInfo = async value => {
    const response = await dispatch(searchTickerInfo(value, type)).catch(err => {
      setIsSearchLoading(false);
    });

    const { payload: results } = response || {};

    if (value && (!results || results.length === 0)) {
      setSearchResults([
        {
          i: "0",
          n: "Nothing found",
          nf: true
        }
      ]);
    } else {
      setSearchResults(results || []);
    }
    setIsSearchLoading(false);
  };

  const getSearchInput = () => {
    if (searchInpRef.current && searchInpRef.current.querySelector("input")) {
      return searchInpRef.current.querySelector("input");
    }

    return {
      onFocus: () => null,
      onBlur: () => null
    };
  };

  const onSearchChange = value => {
    setSearchText(value);
    setSearchResults([]);

    if (value) {
      setIsSearchLoading(true);
    } else {
      setIsSearchLoading(false);
      clearTimeout(searchTimeoutId.current);
      return;
    }

    clearTimeout(searchTimeoutId.current);
    searchTimeoutId.current = setTimeout(() => {
      getSearchTickerInfo(value);
    }, 300);
  };

  const getDisplaySymbol = item => {
    return ["NAS", "NYS", "USPAC", "USAMEX"].includes(item.m) ? item.c : item.s;
  };

  const getIsMutualFund = item => {
    if (["oef", "cef", "if"].includes(item.e)) {
      return true;
    }

    return false;
  };

  const onRenderList = item => {
    if (searchText && isSearchFocused && !item.nf) {
      return (
        <SearchListItem key={item.i}>
          <SearchListHead>
            <SearchListSymbol>{getDisplaySymbol(item)}</SearchListSymbol> {item.n}
          </SearchListHead>
          {item.m && (
            <SearchListFooter>{`${exchangeCodeToNameMap[item.m] || item.m}${
              getIsMutualFund(item)
                ? ` • ${i18n.t("linkAccount.stockTypeMF")}`
                : item.e === "et"
                ? ` • ${i18n.t("linkAccount.stockTypeETF")}`
                : ""
            }`}</SearchListFooter>
          )}
        </SearchListItem>
      );
    } else if (searchText && isSearchFocused && item.nf) {
      return <NothingFoundComponent key={item.i} />;
    }

    return null;
  };

  const onSelect = selected => {
    if (selected && !selected.nf) {
      const { id = selected.i, name = selected.n, symbol = getDisplaySymbol(selected), shortName = selected.s } =
        selected || {};
      const tickerData = { id, name, code: symbol, shortName };

      requestIdleCallback(() => {
        setSelectedTickers([...selectedTickers, tickerData]);
      });

      dispatch(
        getTickersForText(
          shortName,
          ticker => {
            ticker.forEach(each => {
              const currencyTicker = getTickerUsingShortNameWithState(each.info.currency);
              if (currencyTicker.shortName === UNKNOWN_TICKER_SHORT_NAME) {
                dispatch(
                  getTickersForText(each.info.currency, ticker => {
                    ticker.forEach(each => {
                      dispatch(addTickerInfoAction(each.info, each.rate));
                    });
                  })
                );
              }
              dispatch(addTickerInfoAction(each.info, each.rate));
            });
          },
          () => {},
          false
        )
      );
    }

    setTimeout(() => {
      setSearchText("");
    }, 100);
  };

  const removeTickerFromIndex = index => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers.splice(index, 1);
    setSelectedTickers(tempSelectedTickers);
  };

  const onTickerQuantityChange = (value, index) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    setSelectedTickers(tempSelectedTickers);
  };

  const onRenderSelectedTicker = (ticker, index) => {
    if (ticker) {
      const { inputVal = "" } = selectedTickers[index];
      const isError = isSubmitted ? !inputVal : false;

      return (
        <TickerContainer key={index}>
          <TickerHead>
            <TickerHeadSymbol>{ticker.code}</TickerHeadSymbol>
            <TickerHeadFooter>{ticker.name}</TickerHeadFooter>
            <RemoveIconComponent
              onClick={() => {
                removeTickerFromIndex(index);
              }}
            >
              <RemoveIcon />
            </RemoveIconComponent>
          </TickerHead>
          <TickerInput
            placeholder={i18n.t("linkAccount.stockQuantity")}
            value={inputVal}
            onChange={e => {
              onTickerQuantityChange(e.target.value, index);
            }}
            required
            autoFocus
            isError={isError}
          ></TickerInput>
        </TickerContainer>
      );
    }

    return null;
  };

  const onTickerSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitted(true);
    let custodianId = (props.nextValues && props.nextValues.custodianId) || props.custodianId;
    let sortKey = props.nextValues && props.nextValues.sortKey;
    let nextValues = void 0;

    if (formRef.current.checkValidity()) {
      selectedTickers.forEach(ticker => {
        if (!ticker.inputVal === false) {
          const newCustodian = {
            name: ticker.name,
            value: ticker.inputVal,
            valueTickerId: ticker.id,
            past: 0
          };
          nextValues = dispatch(
            insertTickerCustodianInSection(
              portfolio.id,
              currentCustodian.sectionId,
              custodianId,
              newCustodian,
              sortKey,
              custodian => {
                if (!custodian.id) {
                  dispatch(
                    utilityStatus({
                      errorMessage: "Failed to link custodian",
                      ...newCustodian,
                      linkType: "stocks"
                    })
                  );
                }
              },
              ticker.shortName
            )
          );

          custodianId = nextValues.custodianId;
          sortKey = nextValues.sortKey;
        }
      });

      dispatch(setLinkType("stocks"));
      if (e) {
        DialogOverlay.forceDismiss(history, location);
      } else {
        props.setNextValues(nextValues);
      }
    } else {
      setErrorMessage(i18n.t("linkAccount.stockErrorMsg"));
      props.onConfirmCancel();
    }
  };

  const onAddMoreClick = () => {
    getSearchInput().focus();
  };

  const onSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const onSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const saveConfirmModal = () => {
    onTickerSubmit();

    if (formRef.current && formRef.current.checkValidity()) {
      props.onConfirmSave();
    }
    setIsConfirmModalVisible(false);
  };

  const closeConfirmModal = () => {
    props.onConfirmCancel();
    setIsConfirmModalVisible(false);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  useEffect(() => {
    setIsSubmitted(false);
    setErrorMessage("");
  }, [searchText, searchResults, selectedTickers]);

  const { clickedTab, onConfirmSave } = props;
  useEffect(() => {
    if (clickedTab !== 0 && formRef.current) {
      setIsConfirmModalVisible(true);
    } else {
      onConfirmSave();
    }
  }, [clickedTab, onConfirmSave]);

  if (currentCustodian) {
    return (
      <Container className={props.className}>
        <SearchInpWrapper ref={searchInpRef}>
          <AutocompleteSearchInp
            inputPlaceholder={searchInputPlaceholder}
            searchValue={searchText}
            options={searchResults}
            onSearchChange={onSearchChange}
            onRenderList={onRenderList}
            onSelect={onSelect}
            isLoading={isSearchLoading}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
          />
        </SearchInpWrapper>
        {selectedTickers.length === 0 ? (
          <ProvidersList
            disabled={!searchText === false && isSearchFocused}
            providers={stocksPopularProvidersList}
            onClick={onSelect}
            searchHint={searchHint}
          />
        ) : (
          <TickersForm
            ref={formRef}
            onSubmit={onTickerSubmit}
            disabled={isSearchFocused}
            noValidate
            data-exitconfirm={i18n.t("linkAccount.modalStocksTitle")}
          >
            {selectedTickers.map(onRenderSelectedTicker)}
            <SearchMoreTxt onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</SearchMoreTxt>
            <TickerFormFooter>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} />
            </TickerFormFooter>
          </TickersForm>
        )}
        {isConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("linkAccount.saveAddTitle").replace("%s%", i18n.t("linkAccount.modalStocksTitle"))}
            description={i18n.t("linkAccount.saveStocksAddDesc").replace("%s%", i18n.t("linkAccount.modalStocksTitle"))}
            positiveButtonTitle={i18n.t("save")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={closeConfirmModal}
            handlePositiveButtonClick={saveConfirmModal}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default StocksConnectComponent;
