import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";

const Container = styled.div`
  position: relative;
`;

const Input = styled(ContentEditable)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  color: black;
  border: 0;
  border: ${props => (props.hasError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  border-radius: 0;
  appearance: none;
  box-sizing: border-box;
  line-height: normal;
  padding: 13px;
  outline: 0;
  background-color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.05)" : "#ffffff")};
  column-gap: 4px;
  white-space: pre-wrap;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 13px;
  pointer-events: none;
`;

const sanitizeConf = {
  allowedTags: [""],
  allowedAttributes: { span: [""] }
};

const MultiEmailInput = React.forwardRef(
  (
    {
      className,
      placeholder: emailPlaceholder = "Email ID",
      emails = [],
      disabled = false,
      limitTo = null,
      renderEmails = () => null,
      onChange = () => null,
      onBlur = () => null
    },
    ref
  ) => {
    const contentEditableRef = useRef();
    const isFocused = useRef(false);

    const [inputEmails, setInputEmails] = useState();

    const formatInnerHTML = innerHTML => {
      const getFormattedEmails = (emails, limitTo) => {
        const splittedEmails = emails.split(/\s+(?!$)/).filter(email => !!email.trim());
        const formattedEmails = (limitTo ? splittedEmails.slice(0, limitTo) : splittedEmails).map(
          email =>
            renderEmails(email.replace(/,/g, " "), document.activeElement === contentEditableRef.current) ||
            `<span>${email.replace(/,/g, " ")}</span>`
        );

        return formattedEmails.join(" ");
      };

      const emailsFormatted = getFormattedEmails(sanitizeHtml(innerHTML, sanitizeConf), limitTo);

      return emailsFormatted;
    };

    const getEmails = () => {
      const innerHtml = contentEditableRef.current ? contentEditableRef.current.innerHTML : inputEmails;
      return {
        list: sanitizeHtml(formatInnerHTML(innerHtml), sanitizeConf)
          .split(/\s/g)
          .reduce((result, email) => {
            const trimmedEmail = email.trim();
            if (trimmedEmail !== "") {
              result.push(trimmedEmail);
            }

            return result;
          }, [])
      };
    };

    const handleInputChange = e => {
      if (disabled === true) {
        return;
      }

      setInputEmails(contentEditableRef.current.innerHTML);
      onChange(getEmails());
    };

    const handleOnFocus = () => {
      isFocused.current = true;
      setInputEmails(sanitizeHtml(contentEditableRef.current.innerHTML, sanitizeConf));
    };

    const handleOnBlur = () => {
      isFocused.current = false;
      setInputEmails(formatInnerHTML(contentEditableRef.current.innerHTML));
      onBlur(getEmails());
    };

    const handleOnMouseEnter = () => {
      if (!isFocused.current) {
        setInputEmails(sanitizeHtml(contentEditableRef.current.innerHTML, sanitizeConf));
        onChange(getEmails());
      }
    };

    const handleOnMouseLeave = () => {
      if (!isFocused.current) {
        handleOnBlur();
      }
    };

    const handleKeyDown = e => {
      if ([13].includes(e.keyCode)) e.preventDefault();
    };

    useEffect(() => {
      const div = document.createElement("div");
      div.innerHTML = emails.join(" ");

      setInputEmails(formatInnerHTML(div.innerHTML));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getEmails
    }));

    return (
      <Container className={className} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <Input
          className="multiemail-input"
          innerRef={contentEditableRef}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          html={inputEmails || ""}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
        {getEmails().list.length === 0 && !inputEmails && (
          <Placeholder className="multiemail-placeholder">{emailPlaceholder}</Placeholder>
        )}
      </Container>
    );
  }
);

export default MultiEmailInput;
