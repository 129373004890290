import React from "react";
import styled, { css } from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import { websiteUrls } from "routes";
import i18n from "i18next";
import headsUpDialogIcon from "assets/images/heads_up_dialog_icon.svg";

const Dialog = styled(GenericMessageDialog)`
  min-height: 629px;
  max-width: 612px;
  margin-top: 80px;
`;

const DescriptionStyle = css`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  margin-top: 8px;
  margin-bottom: 12px;
`;

const IconStyle = css`
  width: 100px;
  height: 100px;
`;

class LinkAccountHeadsUpDialog extends React.Component {
  render() {
    return (
      <Dialog
        className={this.props.className}
        title={i18n.t("linkAccount.headsUpDialogTitle")}
        icon={headsUpDialogIcon}
        iconStyle={IconStyle}
        description={i18n.t("linkAccount.headsUpDialogDescription").replace("%s1%", websiteUrls.SECURITY)}
        descriptionStyle={DescriptionStyle}
        positiveButtonTitle={i18n.t("linkAccount.headsUpDialogButtonTitle")}
        onPositiveButtonClick={this.props.onButtonClick}
        canUserDismiss={false}
      />
    );
  }
}

export default LinkAccountHeadsUpDialog;
