import React from "react";
import styled from "styled-components";
import { isMobile } from "@kubera/common";

import { ReactComponent as RadioCheckedIcon } from "assets/images/radio_button_checked.svg";
import { ReactComponent as RadioUncheckedIcon } from "assets/images/radio_button_unchecked.svg";
import { ReactComponent as RadioDisabledIcon } from "assets/images/radio_button_disabled.svg";
import { ReactComponent as RadioDisabledCheckedIcon } from "assets/images/radio_button_disabled_checked.svg";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.labelOnLeft === true ? "row-reverse" : "row")};
  align-items: center;
`;

const RadioCircle = styled.div`
  width: 17px;
  height: 17px;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }
`;

const RadioLabel = isMobileDevice
  ? styled.div`
      flex: 1;
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      font-feature-settings: "ss01" on;
      color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.3)" : "black")};
      cursor: pointer;
    `
  : styled.div`
      flex: 1;
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: "ss01" on;
      color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.3)" : "black")};
      cursor: pointer;
    `;

class RadioButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.checked === true };

    this.handleSelection = this.handleSelection.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (this.state.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  get radioIcon() {
    const { checked, disabled } = this.props;
    if (disabled) {
      return checked ? <RadioDisabledCheckedIcon /> : <RadioDisabledIcon />;
    } else if (checked) {
      return <RadioCheckedIcon />;
    } else {
      return <RadioUncheckedIcon />;
    }
  }

  handleSelection() {
    if (this.props.disabled === true) {
      return;
    }

    const newCheckedState = true;
    this.setState({ checked: newCheckedState });

    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  }

  render() {
    const labelOnLeft = !this.props.labelOnLeft === false;

    return (
      <Container className={this.props.className} labelOnLeft={labelOnLeft}>
        <RadioCircle onClick={this.handleSelection}>{this.radioIcon}</RadioCircle>
        <RadioLabel
          labelOnLeft={labelOnLeft}
          disabled={this.props.disabled}
          onClick={this.handleSelection}
          dangerouslySetInnerHTML={{
            __html: this.props.label
          }}
        ></RadioLabel>
      </Container>
    );
  }
}

export default RadioButton;
