import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import AccountPaymentBlock from "./AccountPaymentBlock";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 18px;
`;

class AccountSubscriptionComponent extends React.Component {
  get subscriptionTitle() {
    return i18n.t("accountSettings.clientBillingAndPaymentsTab");
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Title>{this.subscriptionTitle}</Title>
        <AccountPaymentBlock />
      </Container>
    );
  }
}

export default AccountSubscriptionComponent;
