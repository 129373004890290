import React, { Fragment } from "react";
import styled from "styled-components";
import i18n from "i18next";

import StocksConnectComponent from "./StocksConnectComponent";
import MetalsConnectComponent from "./MetalsConnectComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 20px;
  padding-bottom: 2px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  user-select: none;
`;

const Tabs = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  color: ${props => (props.isDisabled ? "rgba(0, 0, 0, 0.50)" : "#000000")};
  cursor: ${props => (props.isDisabled ? "pointer" : "default")};

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: ${props => (props.isDisabled ? "transparent" : "#000000")};
  }
`;

const ProviderTitle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

class ConnectComponentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: props.tab || 0,
      clickedTab: props.tab || 0,
      newCustodianId: null,
      nextValues: null
    };

    this.selectStocks = this.selectStocks.bind(this);
    this.selectMetals = this.selectMetals.bind(this);
    this.onConfirmSave = this.onConfirmSave.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.setNextValues = this.setNextValues.bind(this);
  }

  selectStocks() {
    this.setState({
      clickedTab: 0
    });
  }

  selectMetals() {
    this.setState({
      clickedTab: 1
    });
  }

  onConfirmSave() {
    this.setState({
      selectedTab: this.state.clickedTab
    });
  }

  onConfirmCancel() {
    this.setState({
      clickedTab: this.state.selectedTab
    });
  }

  setNextValues(nextValues) {
    this.setState({
      nextValues
    });
  }

  render() {
    return (
      <Container>
        <ProviderScreenContainer>
          <Title>
            <Tabs isDisabled={this.state.selectedTab !== 0} onClick={this.selectStocks}>
              {i18n.t("linkAccount.stocksTitle")}
            </Tabs>
            <Tabs isDisabled={this.state.selectedTab !== 1} onClick={this.selectMetals}>
              {i18n.t("linkAccount.metalsTitle")}
            </Tabs>
          </Title>
          {this.state.selectedTab === 0 && (
            <Fragment>
              <ProviderTitle>{i18n.t("linkAccount.stockProviderTitle")}</ProviderTitle>
              <StocksConnectComponent
                searchInputPlaceholder={i18n.t("linkAccount.searchStocksPlaceholder")}
                searchHint={i18n.t("linkAccount.stockSearchHint")}
                custodianId={this.props.custodianId}
                clickedTab={this.state.clickedTab}
                nextValues={this.state.nextValues}
                onConfirmSave={this.onConfirmSave}
                onConfirmCancel={this.onConfirmCancel}
                setNextValues={this.setNextValues}
              />
            </Fragment>
          )}
          {this.state.selectedTab === 1 && (
            <MetalsConnectComponent
              custodianId={this.props.custodianId}
              clickedTab={this.state.clickedTab}
              nextValues={this.state.nextValues}
              onConfirmSave={this.onConfirmSave}
              onConfirmCancel={this.onConfirmCancel}
              setNextValues={this.setNextValues}
            />
          )}
        </ProviderScreenContainer>
      </Container>
    );
  }
}

export default ConnectComponentOptions;
