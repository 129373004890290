import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import requestIdleCallback from "utilities/requestIdleCallback";
import requestAnimationFrame from "utilities/requestAnimationFrame";
import cancelIdleCallback from "utilities/cancelIdleCallback";
import cancelAnimationFrame from "utilities/cancelAnimationFrame";

import ScrollNumber from "./ScrollNumber";

const ScrollContainer = styled.span`
  display: inline-block;
  overflow: hidden;
  contain: layout;
  white-space: nowrap;
  font-size: ${props => props.fontSize + "px"};
  height: ${props => props.height || "44px"};
`;

const NonScrollArea = styled.span`
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
`;

const ScrollCounter = props => {
  const isInitChar = useRef(true);
  const animateOnLoad = useRef(props.animateOnLoad);
  const valueAnimationId = useRef(null);
  const transitionTimeoutId = useRef(null);

  const [scopeValue, setScopeValue] = useState(props.displayValue);
  const [isAnimating, setIsAnimating] = useState(props.preventAnimation ? false : props.animateOnLoad ? true : false);

  let numberIndex = 0;

  const handleTransitionEnd = () => {
    clearTimeout(transitionTimeoutId.current);
    transitionTimeoutId.current = setTimeout(() => {
      setIsAnimating(false);
    }, 500);
  };

  useEffect(() => {
    clearTimeout(transitionTimeoutId.current);
    if (scopeValue !== props.displayValue) {
      setIsAnimating(true);
    }
  }, [scopeValue, props.displayValue]);

  useEffect(() => {
    const cancel = valueAnimationId.current ? cancelAnimationFrame : cancelIdleCallback;
    const request = valueAnimationId.current ? requestAnimationFrame : requestIdleCallback;

    cancel(valueAnimationId.current);
    valueAnimationId.current = request(() => {
      setScopeValue(props.displayValue);
      isInitChar.current = false;
      animateOnLoad.current = false;
    });

    return () => {
      cancel(valueAnimationId.current);
    };
  }, [props.displayValue]);

  return (
    <ScrollContainer {...props} height={props.height} fontSize={props.fontSize} onTransitionEnd={handleTransitionEnd}>
      {("" + scopeValue).split("").map((char, index) => {
        const intVal = parseInt(char, 10);
        if (!isNaN(intVal)) {
          const numberBlock = (
            <ScrollNumber
              {...props}
              key={"number" + numberIndex}
              value={char}
              isInitChar={isInitChar.current}
              animateOnLoad={animateOnLoad.current}
              preventAnimation={props.preventAnimation}
              isAnimating={isAnimating}
            />
          );
          numberIndex++;
          return numberBlock || <NonScrollArea key={index}>{char}</NonScrollArea>;
        }

        if (char === " ") {
          return <NonScrollArea key={index}>&nbsp;</NonScrollArea>;
        }

        return <NonScrollArea key={index}>{char}</NonScrollArea>;
      })}
    </ScrollContainer>
  );
};

export default ScrollCounter;
