import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import emptyDashboardChartbackgroundLeft from "assets/images/dashboard_blank_donut_left.png";
import emptyDashboardChartbackgroundRight from "assets/images/dashboard_blank_donut_right.png";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 336px;
  background: ${props => props.theme.netWorthContainerBG};
  border: ${props => props.theme.netWorthContainerBR};
  box-sizing: border-box;
  justify:content: center
  margin-top: 6px
`;

const EmptyDashboardTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-left: 31px
  margin-top: 28px
  font-feature-settings: "ss01" on;
  color: #aaaaaa;
`;

const EmptyDashboardChartContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const EmptyDashboardChartLeftContainer = styled.div`
  display: flex;
  flex: 1
  padding: 20px;
`;

const EmptyDashboardChartRightContainer = styled.div`
  display: flex;
  flex: 1
  padding: 20px;
`;

const EmptyDashboardChartLeft = styled.div`
  display: flex;
  flex: 1;
  background-repeat: no-repeat
  background-size: contain;
  background-image: ${props => `url(${emptyDashboardChartbackgroundLeft}),
  linear-gradient(${props.theme.emptyDashboardChartBG},${props.theme.emptyDashboardChartBG})`};
    background-blend-mode: overlay;
`;

const EmptyDashboardChartRight = styled.div`
  display: flex;
  flex: 1;
  background-repeat: no-repeat
  background-size: contain;
  background-color: transparent;
  background-image: ${props => `url(${emptyDashboardChartbackgroundRight}),
  linear-gradient(${props.theme.emptyDashboardChartBG},${props.theme.emptyDashboardChartBG})`};
    background-blend-mode: overlay;
`;

const EmptyViewContainer = styled.div`
  display: flex;
  justify-content: center
  align-items: center;
  position: relative;
  top: -30px;
  right: 50%;
`;

const EmptyViewContent = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  max-height: 270px;
  margin-top: 0px;
  padding: 50px;
`;

const EmptyContentDescription = styled.div`
  width: max-content;
  margin-top: 50px;
  padding: 12px;
  padding-left: 60px;
  padding-right: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-line;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  box-sizing: border-box;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
`;

class DashboardChartsNotAvailableComponent extends React.Component {
  render() {
    return (
      <Container>
        <EmptyDashboardTitle>{"Allocations"}</EmptyDashboardTitle>
        <EmptyDashboardChartContainer>
          <EmptyDashboardChartLeftContainer>
            <EmptyDashboardChartLeft />
          </EmptyDashboardChartLeftContainer>
          <EmptyDashboardChartRightContainer>
            <EmptyDashboardChartRight />
          </EmptyDashboardChartRightContainer>
          <EmptyViewContainer>
            <EmptyViewContent>
              <EmptyContentDescription>{i18n.t("emptyDashboardChartDescription")}</EmptyContentDescription>
            </EmptyViewContent>
          </EmptyViewContainer>
        </EmptyDashboardChartContainer>
      </Container>
    );
  }
}

export default DashboardChartsNotAvailableComponent;
