import React from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";
import {
  portfolioTotalForAssets,
  portfolioTotalForDebts,
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  userMaskAllValuesSelector
} from "@kubera/common";

import { category } from "components/dashboard/DashboardComponentExports";
import SheetComponent from "components/mobile_table/MobileSheetComponent";
import SectionComponent from "components/mobile_table/MobileSectionComponent";
import MobileGridTotalChange from "components/grid/MobileGridTotalChange";
import {
  Container,
  CategoryTitle,
  CategoryTotal,
  Sheets,
  Sections
} from "mobile_components/styled-css/PageComponentStyled";
import { useSheetData } from "utilities/CustomHooks";

const MobilePageComponent = ({
  currentSheetId,
  portfolio,
  direction,
  onSheetChange = () => null,
  isRefreshing = false,
  pageCategory = category.ASSET
}) => {
  const pageTotal = useSelector(pageCategory !== category.DEBT ? portfolioTotalForAssets : portfolioTotalForDebts);
  const maskAllValues = useSelector(userMaskAllValuesSelector);

  const { sheets, sections, firstSheet, sheetTotalHashTable } = useSheetData({
    portfolio,
    category: pageCategory
  });

  if (!firstSheet) {
    return null;
  }

  const selectedSheetId = sheetTotalHashTable[currentSheetId] ? currentSheetId : firstSheet.id;
  const currentSections = Object.keys(sheetTotalHashTable[selectedSheetId].sections);

  return (
    <Container direction={direction}>
      <CategoryTitle>{i18n.t(pageCategory !== category.DEBT ? "assets" : "debts")}</CategoryTitle>
      <CategoryTotal
        displayValue={`${getSymbolForTickerUsingShortName(portfolio.currency)}${shortFormatNumberWithCurrency(
          pageTotal,
          portfolio.currency
        )}`}
      />
      <MobileGridTotalChange
        category={pageCategory}
        portfolio={portfolio}
        direction={direction}
        isRefreshing={isRefreshing}
      />
      <Sheets>
        {sheets.map((eachSheet, index) => (
          <SheetComponent
            key={eachSheet.id}
            index={index}
            sheet={eachSheet}
            onSheetChange={onSheetChange}
            currentSheetId={selectedSheetId}
          />
        ))}
      </Sheets>
      <Sections>
        {sections.map((eachSection, index) => (
          <SectionComponent
            portfolio={portfolio}
            key={eachSection.id}
            index={index}
            sheet={firstSheet}
            section={eachSection}
            category={pageCategory}
            hasMoreThanOneSection={currentSections.length > 1}
            isRefreshing={isRefreshing}
            shouldRender={eachSection.sheetId === selectedSheetId}
            maskAllValues={maskAllValues}
          />
        ))}
      </Sections>
    </Container>
  );
};

export default React.memo(MobilePageComponent);
