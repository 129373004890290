import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import TextInput from "components/inputs/TextInput";
import EmailInput from "components/inputs/EmailInput";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  userPhoneSelector,
  userPhoneCountryCodeSelector,
  userProfilePictureTokenSelector,
  getUserProfilePhotoUrl,
  userNameSelector,
  userEmailSelector,
  isAuthenticatedByThirdPartyProvider,
  updateUserAttributes,
  uploadProfilePhoto,
  changePassword,
  removeUserPhoneNumber,
  accountCreationTsSelector,
  getMonthFromDate,
  getPreferredMFA,
  MFA_PREFERENCES,
  isAppInWhiteLabelMode,
  logOutFromAllDevices,
  guessPastDate,
  updateUserPreferences,
  formattedUserDobSelector,
  siteConfigSelector,
  isInvalidDob,
  userShowSetPasswordSelector,
  userOnlyGoogleAuthenticatedSelector,
  userSetPasswordUpdated,
  isMobile
} from "@kubera/common";
import { ReactComponent as UploadImageIcon } from "assets/images/upload_profile_icon.svg";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import PasswordInput from "components/inputs/PasswordInput";
import FileUploadInput from "components/inputs/FileUploadInput";
import { generateThumbnailForFile } from "utilities/FileUtils";
import optionsIcon from "assets/images/options.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import UserPhoneCompnent from "components/beneficiary/UserPhoneComponent";
import TwoFAVerify from "components/dialog/TwoFAVerify";
import AccountEmailComponent from "./AccountEmailComponent";
import DateInput from "components/inputs/DateInput";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CreatePassword from "components/signin/CreatePassword";
import UserProfileImage from "components/inputs/UserProfileImage";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const NameInputErrorText = styled.p`
  visibility: ${props => (!props.error === true ? "hidden" : "visible")}
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

const NameField = styled(TextInput)`
  height: 50px;
  width: 314px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (!props.inputError === false ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  ::placeholder {
    font-weight: 400;
  }
`;

const FieldContainer = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
`;

const DateOfBirthTitle = styled(FieldTitle)`
  margin-top: 10px;
`;

const DateOfBirthDescription = styled.div`
  margin-top: -13px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const DateOfBirthContainer = styled.div`
  margin-bottom: ${props => (!props.errorMessage === false ? "8.5px" : undefined)};
`;

const DateOfBirthInput = styled(DateInput)`
  width: 314px;
  label {
    padding: 0px 0px 0px 7px;
    font-size: 14px;
  }
  input {
    letter-spacing: -0.015em;
    font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  }
`;

const ContextMenuButton = styled.button`
  width: 40px;
  height: 100%;
  right: 0;
  position: absolute;
  text-align: center;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${optionsIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;
`;

const PhoneField = styled(TextInput)`
  height: 50px;
  width: 314px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (!props.inputError === false ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  text-transform: uppercase;
  cursor: ${props => (props.readOnly === true ? "pointer" : "default")}

  ::placeholder {
    color: ${props => props.theme.linkColor};
    text-transform: capitalize;
  }
`;

const PhoneFieldTitle = styled(FieldTitle)`
  margin-top: 9px;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  width: 314px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const GoogleLoginText = styled.div`
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 26px;
  font-feature-settings: "ss01" on;

  a {
    color: ${props => props.theme.linkColor};
  }
`;

const OnlyGoogleLoginText = styled.div`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

const ApiErrorText = styled.p`
  visibility: ${props => (!props.error === true ? "hidden" : "visible")}
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
  margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const SaveButton = styled(PrimaryButton)`
  width: 134px;
  height: 44px;
`;

const CancelButton = styled(SecondaryButton)`
  margin-left: 20px;
  width: 134px;
  height: 44px;
`;

const ProfilePictureContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 58px;
  right: 46px;
`;

const ProfilePicture = styled(UserProfileImage)`
  cursor: pointer;
`;

const IAmKubera = styled.a`
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
`;

const SignUpdateDate = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16.5px;
  text-align: center;
  font-feature-settings: "ss01" on;
  color: #828282;
  text-decoration: none;
`;

const ProfilePictureMenuOptions = styled.div`
  display: flex;
  margin: 20px;
  justify-content: space-evenly;
`;

const ProfilePictureMenuOption = styled.div`
  width: 64px;
  height: 64px;
  background-color: transparent;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 64px 64px;
  cursor: pointer;

  > * {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 3px solid transparent;

    &:hover {
      border-radius: 50%;
      border: 3px solid ${props => props.theme.linkColor};
    }
  }
`;

const ChangePasswordContainer = styled.form`
  width: 314px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ChangePassworActionWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 16px;
`;

const ChangePasswordChangeIndicator = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: #00a707;
  margin-right: 5px;
  white-space: nowrap;
`;

const ChangePasswordButton = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  white-space: nowrap;
`;

const CurrentPasswordField = styled(PasswordInput)`
  width: 314px;
  height: 45px;
`;

const NewPasswordField = styled(PasswordInput)`
  width: 314px;
  height: 45px;
`;

const CancelChangePasswordButton = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #1d74f7;
  cursor: pointer;
`;

const ProfileImageInput = styled(FileUploadInput)`
  display: none;
`;

const ChangeEmailLink = styled.div`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 17px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  text-decoration-line: underline;
`;

const ForgotPasswordContainer = isMobileDevice
  ? styled(DialogOverlay)`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;

      & > div {
        padding: 0 32px;
      }

      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const ForgotPasswordDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${isMobileDevice ? "100%" : "fit-content"};
  margin-top: 164px;
  padding: ${isMobileDevice ? "32px 26px" : "50px"};
  box-sizing: border-box;
`;

const ChangePasswordHiddenEmailField = styled.input`
  display: none;
`;

class AccountComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      thirdPartyAuthenticated: false,
      isSaveEnabled: false,
      name: this.props.userName,
      currentPassword: null,
      newPassword: null,
      nameInputError: null,
      currentPasswordError: null,
      newPasswordError: null,
      isRequestPending: false,
      showChangePassword: false,
      apiErrorMessage: null,
      profileImage: null,
      profileImageFile: null,
      showRemovePhoneNumberDialog: false,
      showEditPhoneNumberDialog: false,
      profilePictureUrl: null,
      isPasswordSubmitted: false,
      isTwoFAVerifyOpen: false,
      isSignOutFromAllDevicesModalOpen: false,
      dobString: props.userDob,
      dobError: false,
      showCreatePasswordField: false,
      showPasswordCreated: false
    };

    this.profilePictureMenuRef = React.createRef();
    this.profileImageInputRef = React.createRef();
    this.contextMenuRef = React.createRef();

    this.handleNameInput = this.handleNameInput.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleProfilePictureClick = this.handleProfilePictureClick.bind(this);
    this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
    this.handleCancelChangePassword = this.handleCancelChangePassword.bind(this);
    this.handleCurrentPasswordInput = this.handleCurrentPasswordInput.bind(this);
    this.handleNewPasswordInput = this.handleNewPasswordInput.bind(this);
    this.handleProfileImageSelection = this.handleProfileImageSelection.bind(this);
    this.handlePhoneContextMenuButtonClick = this.handlePhoneContextMenuButtonClick.bind(this);
    this.handleEmailContextMenuButtonClick = this.handleEmailContextMenuButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDialogNegativeButtonClick = this.handleDialogNegativeButtonClick.bind(this);
    this.handleDialogPositiveButtonClick = this.handleDialogPositiveButtonClick.bind(this);
    this.handlePhoneFieldClick = this.handlePhoneFieldClick.bind(this);
    this.hideUserPhone = this.hideUserPhone.bind(this);
    this.handleDobInputChange = this.handleDobInputChange.bind(this);
    this.handleDobInputBlur = this.handleDobInputBlur.bind(this);

    isAuthenticatedByThirdPartyProvider().then(status => this.setState({ thirdPartyAuthenticated: status }));
  }

  componentDidMount() {
    this.isComponentMounted = true;

    this.getProfilePicture();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.profilePictureToken !== this.props.profilePictureToken) {
      this.getProfilePicture();
    }
  }

  getProfilePicture() {
    this.props.getUserProfilePhotoUrl(
      url => {
        this.setState({ profilePictureUrl: url });
      },
      apiError => {}
    );
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  get kuberaHashUrl() {
    const date = new Date(this.props.accountCreationTs * 1000);
    const sinceTxt = `${getMonthFromDate(date)}+${date.getFullYear()}`;

    return `https://twitter.com/intent/tweet?text=Check+out+Kubera.com+-+a+simple+%26+modern+tool+to+track+your+assets+-+digital+%28crypto%29+and+traditional+%28stocks%2C+bonds%2C+real+estate%29.+Connects+to+international+bank+%26+brokerage+accounts.+Supports+all+major+currencies.+%23iamKubera+since+${sinceTxt}+%40KuberaApp`;
  }

  handleNameInput(e) {
    this.setState({ ...this.state, isSaveEnabled: true, name: e.target.value, nameInputError: null });
  }

  handleDobInputChange(newValue) {
    this.setState({ dobString: newValue, isSaveEnabled: this.props.userDob !== this.state.dobString, dobError: false });
  }

  handleDobInputBlur() {
    this.setState({ dobError: !this.state.dobString === false && isInvalidDob(guessPastDate(this.state.dobString)) });
  }

  handleCurrentPasswordInput(e) {
    this.setState({ ...this.state, isSaveEnabled: true, currentPassword: e.target.value, currentPasswordError: null });
  }

  handleNewPasswordInput(e) {
    this.setState({ ...this.state, isSaveEnabled: true, newPassword: e.target.value, newPasswordError: null });
  }

  handleCancelClick(e) {
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  handleSaveClick(e) {
    this.handleHideCreatePassword();
    const name = this.state.name;
    const currentPassword = this.state.currentPassword;
    const newPassword = this.state.newPassword;
    let nameInputError = null;
    let currentPasswordError = null;
    let newPasswordError = null;
    let errorCount = 0;

    if (!name || name.trim().length === 0) {
      nameInputError = i18n.t("emptyName");
      errorCount++;
    }
    if (this.state.showChangePassword === true) {
      if (!currentPassword || currentPassword.trim().length === 0) {
        currentPasswordError = i18n.t("emptyPassword");
        errorCount++;
      }
      if (!newPassword || newPassword.trim().length === 0) {
        newPasswordError = i18n.t("emptyPassword");
        errorCount++;
      } else if (newPassword.length < PasswordInput.minPasswordLength) {
        newPasswordError = i18n.t("passwordTooShort");
        errorCount++;
      }
    }

    if (errorCount === 0) {
      const guessedDate = guessPastDate(this.state.dobString);
      const invalidDob = isInvalidDob(guessedDate);
      if (!this.state.dobString === true) {
        this.props.updateUserPreferences({ userDob: null });
        this.saveDetails();
      } else if (!invalidDob) {
        this.props.updateUserPreferences({
          userDob: guessedDate.dateString
        });
        this.saveDetails();
      } else if (invalidDob) {
        this.setState({ dobError: true });
      }
    } else {
      this.setState({ ...this.state, isSaveEnabled: false, nameInputError, currentPasswordError, newPasswordError });
    }
  }

  saveDetails() {
    this.setState({ ...this.state, isRequestPending: true, apiErrorMessage: null });

    this.saveProfilePhoto(
      () => {
        this.saveUserDetails(
          () => {
            this.saveChangePassword(
              () => {
                this.setState({
                  isSaveEnabled: false,
                  isRequestPending: false,
                  showChangePassword: false
                });
              },
              error => {
                this.handleApiError(error.message);
              }
            );
          },
          error => {
            this.handleApiError(error.errorMessage);
          }
        );
      },
      error => {
        this.handleApiError(error.errorMessage);
      }
    );
  }

  saveProfilePhoto(onSuccess, onError) {
    const selectedPhoto = this.state.profileImageFile;

    if (selectedPhoto) {
      generateThumbnailForFile(selectedPhoto, thumbnail => {
        if (thumbnail) {
          this.props.uploadProfilePhoto(
            thumbnail,
            user => {
              const img = new Image();
              img.src = this.state.profilePictureUrl;
              img.onload = () => {
                onSuccess();
              };
              img.onerror = () => {
                onSuccess();
              };
            },
            onError
          );
        } else {
          onError("Image upload failed");
        }
      });
    } else {
      onSuccess();
    }
  }

  async saveUserDetails(onSuccess, onError) {
    const name = this.state.name;
    const profileImage = this.state.profileImage;

    var updatedAttributes = {};
    if (name) {
      updatedAttributes.name = name;
    }
    if (profileImage === "") {
      updatedAttributes.picture = profileImage;
    }
    if (Object.keys(updatedAttributes).length === 0) {
      onSuccess();
      return;
    }

    this.props.updateUserAttributes(
      updatedAttributes,
      user => {
        onSuccess();
      },
      error => {
        onError(error.errorMessage);
      }
    );
  }

  saveChangePassword(onSuccess, onError) {
    const currentPassword = this.state.currentPassword;
    const newPassword = this.state.newPassword;

    if (currentPassword && newPassword) {
      changePassword(
        currentPassword,
        newPassword,
        () => {
          this.setState({
            isPasswordSubmitted: true,
            newPassword: "",
            currentPassword: "",
            isSignOutFromAllDevicesModalOpen: true
          });
          onSuccess();
        },
        error => {
          if (error.code === "InvalidPasswordException") {
            error.message = i18n.t("passwordNotMatchingPolicy");
          } else if (error.code === "NotAuthorizedException") {
            error.message = i18n.t("currentPasswordInvalid");
          }
          onError(error);
        }
      );
    } else {
      onSuccess();
    }
  }

  handleApiError(errorMessage) {
    this.setState({ ...this.state, isSaveEnabled: true, isRequestPending: false, apiErrorMessage: errorMessage });
  }

  handleProfilePictureClick(event) {
    if (this.profilePictureMenuRef.current.isVisible() === true) {
      this.profilePictureMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.profilePictureMenuRef.current.show(
      [],
      targetPosition.left - targetPosition.width / 2,
      targetPosition.top + targetPosition.height - 20,
      true,
      event.target
    );
  }

  getProfilePictureMenuItems() {
    return [
      { id: "upload_icon", icon: <UploadImageIcon /> },
      { id: "default_image_icon", icon: <UserProfileImage imageSize={64} userName={this.state.name} /> }
    ];
  }

  handleProfileOption(item) {
    this.profilePictureMenuRef.current.dismiss();

    if (item.id === "default_image_icon") {
      this.setState({ ...this.state, profileImage: "", isSaveEnabled: true });
    } else {
      this.profileImageInputRef.current.upload();
    }
  }

  handleChangePasswordClick(e) {
    this.setState({ ...this.state, showChangePassword: true });
  }

  handleCancelChangePassword(e) {
    this.setState({ ...this.state, showChangePassword: false });
  }

  handleProfileImageSelection(files) {
    const image = files.length === 1 ? files[0] : null;

    if (image) {
      var reader = new FileReader();
      reader.onload = e => {
        this.setState({ ...this.state, profileImage: e.target.result, profileImageFile: image, isSaveEnabled: true });
      };
      reader.readAsDataURL(image);
    }
  }

  getProfileImage() {
    const profileImage = this.state.profileImage;
    if (profileImage) {
      return profileImage;
    } else if (profileImage === "") {
      return "";
    } else {
      return this.state.profilePictureUrl;
    }
  }

  async handlePhoneContextMenuButtonClick(e) {
    e.persist();

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    const preferredMFA = await getPreferredMFA();
    if (preferredMFA.default === MFA_PREFERENCES.SMS) {
      this.contextMenuRef.current.show(
        [[contextMenuItemType.CHANGE_PHONE_NUMBER]],
        targetPosition.left + targetPosition.width - 5,
        targetPosition.top + targetPosition.height - 15,
        false,
        e.target
      );
    } else {
      this.contextMenuRef.current.show(
        [[contextMenuItemType.CHANGE_PHONE_NUMBER], [contextMenuItemType.REMOVE]],
        targetPosition.left + targetPosition.width - 5,
        targetPosition.top + targetPosition.height - 15,
        false,
        e.target
      );
    }
  }

  handleEmailContextMenuButtonClick(e) {
    e.persist();

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      [[contextMenuItemType.CHANGE_EMAIL]],
      targetPosition.left + targetPosition.width - 5,
      targetPosition.top + targetPosition.height - 15,
      false,
      e.target
    );
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.REMOVE.id) {
      this.setState({ ...this.state, showRemovePhoneNumberDialog: true });
    } else if (item.id === contextMenuItemType.CHANGE_PHONE_NUMBER.id) {
      this.editPhoneNumber();
    } else if (item.id === contextMenuItemType.CHANGE_EMAIL.id) {
      this.verifyUser();
    }
  }

  verifyUser = () => {
    this.setState({
      isTwoFAVerifyOpen: true
    });
  };

  onCloseTwoFAVerify = () => {
    this.setState({
      isTwoFAVerifyOpen: false
    });
  };

  onVerifySuccess = () => {
    this.setState({
      isTwoFAVerifyOpen: false
    });
    this.editEmail();
  };

  hideUserPhone() {
    this.setState({ showEditPhoneNumberDialog: false });
  }

  handleDialogNegativeButtonClick() {
    this.setState({ ...this.state, showRemovePhoneNumberDialog: false });
  }

  handleDialogPositiveButtonClick() {
    this.setState({ ...this.state, showRemovePhoneNumberDialog: false });
    this.props.removeUserPhoneNumber();
  }

  handlePhoneFieldClick(e) {
    if (!this.props.userPhone === true) {
      this.editPhoneNumber();
    }
  }

  editPhoneNumber() {
    this.setState({ showEditPhoneNumberDialog: true });
  }

  editEmail = () => {
    this.setState({ showEditEmailDialog: true });
  };

  handleEmailDismiss = () => {
    this.setState({ showEditEmailDialog: false });
  };

  getSignUpDateString() {
    const date = new Date(this.props.accountCreationTs * 1000);
    return i18n.t("userSince").replace("%s%", `${getMonthFromDate(date)} ${date.getFullYear()}`);
  }
  handleSignOutFromAllDevicesModalNegativeButtonClick = e => {
    this.setState({ isSignOutFromAllDevicesModalOpen: false });
  };

  handleSignOutFromAllDevicesModalPositiveButtonClick = e => {
    this.props.logOutFromAllDevices(this.props.userName, this.props.userEmail, () => {
      this.setState({ isSignOutFromAllDevicesModalOpen: false });
    });
  };

  handleShowCreatePassword = () => {
    this.setState({
      showCreatePasswordField: true,
      showPasswordCreated: false
    });
  };

  handleHideCreatePassword = (showPasswordCreated = false) => {
    this.setState({
      showCreatePasswordField: false,
      showPasswordCreated
    });
  };

  handleGoogleLoginTxtClick = e => {
    if (e.target.nodeName === "A") {
      this.handleShowCreatePassword();
    }
  };

  render() {
    const isWhiteLabelledApp = isAppInWhiteLabelMode();
    const showChangePassword = this.state.showChangePassword;
    const isRequestPending = this.state.isRequestPending;
    const nameInputError = this.state.nameInputError;
    const currentPasswordError = this.state.currentPasswordError;
    const newPasswordError = this.state.newPasswordError;
    const name = this.state.name;
    const phoneCountryCode = this.props.userPhoneCountryCode;
    const phone = this.props.userPhone;
    const email = this.props.userEmail;
    const currentPassword = this.state.currentPassword;
    const newPassword = this.state.newPassword;
    const isSaveEnabled = this.state.isSaveEnabled;
    const profileImage = this.getProfileImage();
    const profilePictureMenuItems = this.getProfilePictureMenuItems();
    const apiErrorMessage = this.state.apiErrorMessage;
    const showRemovePhoneNumberDialog = this.state.showRemovePhoneNumberDialog;
    const showEditPhoneNumberDialog = this.state.showEditPhoneNumberDialog;
    const showEditEmailDialog = this.state.showEditEmailDialog;
    const userShowSetPassword = !!this.props.userShowSetPassword;
    const userOnlyGoogleAuthenticated = !!this.props.userOnlyGoogleAuthenticated || this.state.thirdPartyAuthenticated;

    const { showCreatePasswordField, showPasswordCreated } = this.state;

    return (
      <Container className={this.props.className}>
        <Title>{i18n.t("accountSettings.accountTabTitle")}</Title>
        <FieldTitle>{i18n.t("fullname")}</FieldTitle>
        <NameField
          data-private
          data-cy="account_userfullname"
          placeholder={i18n.t("name")}
          inputError={nameInputError}
          value={name}
          onChange={this.handleNameInput}
        />
        <NameInputErrorText error={nameInputError}>
          {!nameInputError === true ? "&nbsp" : nameInputError}
        </NameInputErrorText>
        {this.props.siteConfig.showPlanning !== 0 && (
          <DateOfBirthContainer errorMessage={this.state.dobError === true ? i18n.t("enterPastDate") : null}>
            <DateOfBirthTitle>{i18n.t("dateOfBirthOptional")}</DateOfBirthTitle>
            <DateOfBirthInput
              inputPlaceholder={i18n.t("date")}
              value={this.state.dobString}
              onDateChange={this.handleDobInputChange}
              onBlur={this.handleDobInputBlur}
              isBdayPicker={true}
              isAutofocusDisabled
              data-cy="account_userdob"
            />
            {this.state.dobError === false && (
              <DateOfBirthDescription>{i18n.t("dobDescription")}</DateOfBirthDescription>
            )}
          </DateOfBirthContainer>
        )}
        {!phone === false && (
          <>
            <PhoneFieldTitle>{i18n.t("phone")}</PhoneFieldTitle>
            <FieldContainer>
              <PhoneField
                disabled={!phone === false}
                readOnly={!phone === true}
                value={!phone === true ? "" : phoneCountryCode + " " + phone}
                data-cy="addPhone"
                placeholder={i18n.t("addPhone")}
                onClick={this.handlePhoneFieldClick}
                data-private
              />
              <ContextMenuButton data-cy="phoneContextMenu" onClick={this.handlePhoneContextMenuButtonClick} />
            </FieldContainer>
          </>
        )}
        <FieldTitle>{i18n.t("email")}</FieldTitle>
        <FieldContainer>
          <EmailField data-private disabled={true} value={email} />
          {!userShowSetPassword && !userOnlyGoogleAuthenticated && (
            <ContextMenuButton data-cy="phoneContextMenu" onClick={this.handleEmailContextMenuButtonClick} />
          )}
        </FieldContainer>

        {userShowSetPassword === true && !showPasswordCreated && (
          <>
            <GoogleLoginText
              dangerouslySetInnerHTML={{
                __html: i18n.t("accountSettings.newGoogleLogin")
              }}
              onClick={this.handleGoogleLoginTxtClick}
            />
            {showCreatePasswordField && (
              <ForgotPasswordContainer onDismiss={this.handleHideCreatePassword}>
                <ForgotPasswordDialog>
                  <CreatePassword
                    emailId={this.props.userEmail}
                    onComplete={() => this.handleHideCreatePassword(true)}
                  />
                </ForgotPasswordDialog>
              </ForgotPasswordContainer>
            )}
          </>
        )}

        {userShowSetPassword === false && userOnlyGoogleAuthenticated && (
          <OnlyGoogleLoginText
            dangerouslySetInnerHTML={{
              __html: i18n.t("accountSettings.googleLogin")
            }}
          />
        )}

        {userShowSetPassword === false && !userOnlyGoogleAuthenticated && !showPasswordCreated && (
          <ChangePassworActionWrapper>
            {showChangePassword === false && this.state.isPasswordSubmitted && (
              <ChangePasswordChangeIndicator>{i18n.t("pwdChanged")}</ChangePasswordChangeIndicator>
            )}
            {showChangePassword === false && showPasswordCreated === false && (
              <ChangePasswordButton data-cy="changePassword" onClick={this.handleChangePasswordClick}>
                {!this.state.isPasswordSubmitted ? i18n.t("changePassword") : i18n.t("changePasswordAgain")}
              </ChangePasswordButton>
            )}
          </ChangePassworActionWrapper>
        )}

        {showChangePassword === true && (
          <ChangePasswordContainer>
            <ChangePasswordHiddenEmailField />
            <CancelChangePasswordButton onClick={this.handleCancelChangePassword}>
              {i18n.t("cancel")}
            </CancelChangePasswordButton>
            <FieldTitle>{i18n.t("currentPassword")}</FieldTitle>
            <CurrentPasswordField
              placeholder={i18n.t("password")}
              errorMessage={currentPasswordError}
              value={currentPassword}
              onChange={this.handleCurrentPasswordInput}
              autoComplete="current-password"
            />
            <FieldTitle>{i18n.t("newPassword")}</FieldTitle>
            <NewPasswordField
              placeholder={i18n.t("password")}
              errorMessage={newPasswordError}
              value={newPassword}
              onChange={this.handleNewPasswordInput}
              autoComplete="new-password"
            />
          </ChangePasswordContainer>
        )}

        {showPasswordCreated && !showChangePassword && !this.state.isPasswordSubmitted && (
          <>
            <ChangePassworActionWrapper>
              <ChangePasswordChangeIndicator>{i18n.t("pwdCreated")}</ChangePasswordChangeIndicator>
              <ChangePasswordButton data-cy="changePassword" onClick={this.handleChangePasswordClick}>
                {i18n.t("changePasswordAgain")}
              </ChangePasswordButton>
            </ChangePassworActionWrapper>
            <ChangeEmailLink onClick={this.verifyUser}>{i18n.t("changeEmail")}</ChangeEmailLink>
          </>
        )}

        <ApiErrorText error={apiErrorMessage}>{!apiErrorMessage === true ? "&nbsp" : apiErrorMessage}</ApiErrorText>

        <ButtonContainer>
          <SaveButton
            title={i18n.t("save")}
            data-cy="saveButton"
            isDisabled={isSaveEnabled === false}
            onClick={this.handleSaveClick}
            isLoading={isRequestPending}
          />
          <CancelButton title={i18n.t("cancel")} onClick={this.handleCancelClick} />
        </ButtonContainer>
        <ProfilePictureContainer data-private>
          <ProfilePicture
            imageSize={100}
            userName={this.state.name}
            imageUrl={profileImage}
            onClick={this.handleProfilePictureClick}
          />
          {isWhiteLabelledApp === false && (
            <IAmKubera target="_blank" href={this.kuberaHashUrl} rel="noopener noreferrer">
              {i18n.t("iAmKuberaHash")}
            </IAmKubera>
          )}
          {isWhiteLabelledApp === false && (
            <SignUpdateDate target="_blank" href={this.kuberaHashUrl} rel="noopener noreferrer">
              {this.getSignUpDateString()}
            </SignUpdateDate>
          )}
        </ProfilePictureContainer>
        <ProfileImageInput
          ref={this.profileImageInputRef}
          multiple={false}
          onChange={this.handleProfileImageSelection}
          accept=".png,.jpg,.jpeg,.gif"
        />
        <ContextMenu ref={this.profilePictureMenuRef} width={219} height={100}>
          <ProfilePictureMenuOptions>
            {profilePictureMenuItems.map((item, index) => {
              return (
                <ProfilePictureMenuOption
                  data-cy={"whichOpt_" + item.id}
                  key={index}
                  url={item.icon}
                  onClick={e => {
                    this.handleProfileOption(item);
                  }}
                >
                  {item.icon}
                </ProfilePictureMenuOption>
              );
            })}
          </ProfilePictureMenuOptions>
        </ContextMenu>
        <ContextMenu ref={this.contextMenuRef} onSelection={this.handleContextMenuSelection} />
        {showRemovePhoneNumberDialog === true && (
          <ConfirmationDialog
            title={i18n.t("deletePhoneNumberDialog.title")}
            description={i18n.t("deletePhoneNumberDialog.description")}
            positiveButtonTitle={i18n.t("deletePhoneNumber")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleDialogPositiveButtonClick}
          />
        )}
        {showEditPhoneNumberDialog === true && <UserPhoneCompnent hideUserPhone={this.hideUserPhone} />}
        {showEditEmailDialog && (
          <AccountEmailComponent
            name={name}
            email={email}
            userShowSetPassword={userShowSetPassword}
            onEmailDismiss={this.handleEmailDismiss}
          />
        )}
        <TwoFAVerify
          isOpen={this.state.isTwoFAVerifyOpen}
          onVerifyClose={this.onCloseTwoFAVerify}
          onSuccess={this.onVerifySuccess}
        />
        {this.state.isSignOutFromAllDevicesModalOpen && (
          <ConfirmationDialog
            title={i18n.t("accountSettings.signOutFromAllDevices.alert.title")}
            description={i18n.t("accountSettings.signOutFromAllDevices.alert.description")}
            positiveButtonTitle={i18n.t("accountSettings.signOutFromAllDevices.alert.primaryButton")}
            negativeButtonTitle={i18n.t("accountSettings.signOutFromAllDevices.alert.secondaryButton")}
            handleNegativeButtonClick={this.handleSignOutFromAllDevicesModalNegativeButtonClick}
            handlePositiveButtonClick={this.handleSignOutFromAllDevicesModalPositiveButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userOnlyGoogleAuthenticated: userOnlyGoogleAuthenticatedSelector(state),
  userShowSetPassword: userShowSetPasswordSelector(state),
  userPhone: userPhoneSelector(state),
  userPhoneCountryCode: userPhoneCountryCodeSelector(state),
  profilePictureToken: userProfilePictureTokenSelector(state),
  userName: userNameSelector(state),
  userEmail: userEmailSelector(state),
  accountCreationTs: accountCreationTsSelector(state),
  userDob: formattedUserDobSelector(state),
  siteConfig: siteConfigSelector(state)
});

const mapDispatchToProps = {
  updateUserAttributes: updateUserAttributes,
  uploadProfilePhoto: uploadProfilePhoto,
  removeUserPhoneNumber: removeUserPhoneNumber,
  getUserProfilePhotoUrl: getUserProfilePhotoUrl,
  logOutFromAllDevices: logOutFromAllDevices,
  updateUserPreferences: updateUserPreferences,
  userSetPasswordUpdated: userSetPasswordUpdated
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountComponent));
