import { ApiClient } from "../../api/ApiClient";
import { enqueueItem, SyncItem, SyncItemType } from "./SyncActions";
import { getUuid } from "../../utilities/Number";
import { UPDATE_BENEFICIARY, FETCH_BENEFICIARY_ERROR } from "./Common";

export const fetchBeneficiariesErrorAction = beneficiaryError => ({
  type: FETCH_BENEFICIARY_ERROR,
  beneficiaryError
});

export const updateBeneficiariesUpdateAction = beneficiaries => ({
  type: UPDATE_BENEFICIARY,
  beneficiaries
});

export const fetchBeneficiaries = (onSuccess, onError) => {
  return dispatch => {
    ApiClient.getBeneficiaries(getUuid())
      .then(apiData => {
        dispatch(updateBeneficiariesUpdateAction(apiData.payload));
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        dispatch(fetchBeneficiariesErrorAction(apiError));
        onError(apiError);
      });
  };
};

export const updateBeneficiaries = beneficiaries => {
  return dispatch => {
    dispatch(updateBeneficiariesUpdateAction(beneficiaries));

    const request = idempotentId => ApiClient.updateBeneficiaries(idempotentId, beneficiaries);
    const syncItem = new SyncItem(SyncItemType.UPDATE, "update_beneficiaries", request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const sendTestMail = (portfolioId, type) => {
  return dispatch => {
    const request = idempotentId => ApiClient.sendTestMail(idempotentId, portfolioId, type);
    const syncItem = new SyncItem(
      SyncItemType.UPDATE,
      portfolioId + "_sendTestMail_" + type,
      request,
      0,
      true,
      apiData => {}
    );
    dispatch(enqueueItem(syncItem));
  };
};
