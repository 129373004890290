import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import { routes } from "routes";
import {
  verifyPasscode,
  apiErrorCodes,
  setPasscodeForViewMode,
  siteConfigSelector,
  setSiteConfigAction,
  setUserNameForViewMode,
  isAppInWhiteLabelMode
} from "@kubera/common";
import Logo from "components/labels/AppLogo";
import PrimaryButton from "components/button/PrimaryButton";
import PasswordInput from "components/inputs/PasswordInput";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  background: ${props => props.theme.mobileSignInBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const PasscodeForm = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 561px;
  flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
  padding: 0 30px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
`;

const PasscodeField = styled(PasswordInput)`
  height: 50px;
`;

const EnterButton = styled(PrimaryButton)`
  margin-top: 20px;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 32px;
`;

class PasscodeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passcode: "",
      isRequestPending: false,
      errorMessage: null
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePasscodeInputChange = this.handlePasscodeInputChange.bind(this);
    this.handlePasscodeSubmission = this.handlePasscodeSubmission.bind(this);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handlePasscodeSubmission(e);
    }
  }

  handlePasscodeInputChange(e) {
    this.setState({ passcode: e.target.value, errorMessage: null });
  }

  handlePasscodeSubmission(e) {
    if (!this.state.passcode === true) {
      this.setState({ errorMessage: i18n.t("emptyPasscodeError") });
      return;
    }

    this.setState({ isRequestPending: true });

    this.props.verifyPasscode(
      this.state.passcode,
      config => {
        const newConfig = { ...this.props.siteConfig, ...config };

        setPasscodeForViewMode(this.state.passcode);
        setUserNameForViewMode(newConfig.userName || newConfig.userEmail);

        this.props.setSiteConfig(newConfig);
        this.props.history.replace({ ...this.props.location, pathname: routes.ASSETS });
      },
      apiError => {
        const errorMessage =
          apiError.errorCode === apiErrorCodes.SESSION_EXPIRED ? i18n.t("invalidPasscodeError") : apiError.errorMessage;
        this.setState({ isRequestPending: false, errorMessage: errorMessage });
      }
    );
  }

  render() {
    return (
      <Container>
        <ContentContainer>
          <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
          <PasscodeForm>
            <Title>{i18n.t("passcodeTitleMobile")}</Title>
            <ErrorMessage visible={!this.state.errorMessage === false}>
              {!this.state.errorMessage === true ? "&nbsp;" : this.state.errorMessage}
            </ErrorMessage>
            <PasscodeField
              placeholder={i18n.t("passcode")}
              inputError={this.state.errorMessage}
              value={this.state.passcode}
              onChange={this.handlePasscodeInputChange}
              onKeyDown={this.handleKeyDown}
              hidePasswordStrength={true}
              autoFocus={true}
            />
            <EnterButton
              title={i18n.t("enter")}
              onClick={this.handlePasscodeSubmission}
              isLoading={this.state.isRequestPending}
            />
          </PasscodeForm>
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  siteConfig: siteConfigSelector(state)
});

const mapDispatchToProps = {
  verifyPasscode: verifyPasscode,
  setSiteConfig: setSiteConfigAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PasscodeComponent));
