import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import linkFailureIcon from "assets/images/link_failure_dialog_icon.svg";
import fallbackIcon from "assets/images/link_failure_fallback_icon.svg";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import {
  accountLinkingService,
  getAggregatorName,
  utilityStatus,
  getAccountLinkingService,
  getAmountAndTickerFromInput,
  currentPortfolioSelector,
  getTickerUsingShortName,
  apiErrorCodes
} from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import { ReactComponent as CheckIcon } from "assets/images/tick_icon.svg";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { linkAccountMode } from "components/link_account/LinkAccountComponentExports";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 5px;
`;

const FallbackDescription = styled.div`
  flex: 1;
  margin-top: 20px;
  white-space: pre-wrap;
  font-style: normal;
  font-size: 13px;
  font-feature-settings: "ss01" on;
  line-height: 140%;
`;

const FallbackIcon = styled.div`
  width: 100px;
  height: 100px;
  background-color: transparent;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  margin-top: -20px;
`;

const FailureIcon = styled(FallbackIcon)`
  margin-top: -50px;
`;

const ReauthFailureIcon = styled(FailureIcon)`
  margin-top: -25px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 28px;
`;

const PositiveButton = styled(PrimaryButton)`
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
`;

const NegativeButton = styled(SecondaryButton)``;

const FailureSiteName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  white-space: pre;
`;

const FailureNextStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 17px;
`;

const FailureNextStep = styled.div`
  margin-bottom: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const ReconnectTextContainer = styled.div`
  display: flex;
`;

const ReconnectText = styled.div`
  margin-left: 5px;
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
`;

const ManualEntryContainer = styled.div`
  display: flex;
  height: 43px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  margin-top: -16px;
`;

const ManualEntryNameInput = styled(TextInput)`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  padding: 12px 10px 10px 10px;

  &:focus {
    outline: none;
  }
`;

const ManualEntryValueInput = styled(ManualEntryNameInput)`
  flex: auto;
  width: 102px;
  text-align: right;
`;

const ManualEntryAddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  background: rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const ManualEntryCompletionMessage = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #00a707;
  white-space: pre;
  margin-bottom: 14px;
`;

class LinkAccountFailureComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      manualEntryName: props.providerName || "",
      manualEntryValue: "",
      manualEntryCompleted: false
    };

    this.handleManualEntryNameChange = this.handleManualEntryNameChange.bind(this);
    this.handleManualEntryValueChange = this.handleManualEntryValueChange.bind(this);
    this.handleManualEntryAddClick = this.handleManualEntryAddClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this);

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentDidMount() {
    const blackListedErrorCodes = ["institution_not_found"];
    if (blackListedErrorCodes.includes(this.props.data.errorCode) === false) {
      const providerName = this.props.providerName ? this.props.providerName : "unknown";

      this.props.utilityStatus({
        linkType: getAccountLinkingService(this.props.linkingService),
        providerId: this.props.providerId,
        providerName: providerName,
        requestId: this.props.data.requestId ? this.props.data.requestId : "",
        reason: this.props.data.errorMessage,
        ...this.props.data
      });
    }
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleManualEntryValueChange(e) {
    const value = e.target.value;
    this.setState({ manualEntryValue: value });
  }

  handleManualEntryNameChange(e) {
    this.setState({ manualEntryName: e.target.value });
  }

  handlePositiveButtonClick(e) {
    if (!this.state.manualEntryName === false && !this.state.manualEntryValue === false) {
      this.addManualEntry();
    }

    this.props.onPositiveButtonClick(e);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (this.state.manualEntryCompleted === true) {
        this.handlePositiveButtonClick(e);
      } else if (this.canEnableAddButton() === true) {
        this.handleManualEntryAddClick(e);
      }

      e.preventDefault();
      return true;
    }
    return false;
  }

  handleManualEntryAddClick(e) {
    if (this.state.manualEntryCompleted === true) {
      return true;
    }

    this.setState({ manualEntryCompleted: true });
    this.addManualEntry();
  }

  addManualEntry() {
    const data = getAmountAndTickerFromInput(this.state.manualEntryValue, this.props.portfolio.currency);
    const tickerShortName = !data.tickerInput === true ? this.props.portfolio.currency : data.tickerInput;
    var ticker = getTickerUsingShortName(tickerShortName);
    this.props.onManualEntry(this.state.manualEntryName, data.value, ticker, data.tickerInput);
  }

  getErrorCategory() {
    for (const item in accountLinkingService) {
      if (accountLinkingService[item] === this.props.linkingService) {
        return item + "_ERROR";
      }
    }
    return "Unknown";
  }

  getStatusCodeString() {
    const data = this.props.data;
    if (data.errorCode) {
      return "Error Code: " + data.errorCode;
    }
    if (data.errorMessage) {
      return data.errorMessage;
    }
    return "UNKNOWN";
  }

  getTitle() {
    if (this.props.mode !== linkAccountMode.LINK && this.props.mode !== linkAccountMode.RECONNECT) {
      return i18n.t("linkFailureReauthDialog.title");
    } else if (this.isFallbackAvailable()) {
      return this.props.linkingServiceHistory.length === 2
        ? i18n.t("fallbackLinkFailureDialog.titlePlanC")
        : i18n.t("fallbackLinkFailureDialog.titlePlanB");
    } else if (this.isInstitutionNotAvaialbleError()) {
      return i18n.t("linkFailureDialog.institutioNotFoundError.title");
    }
    return i18n.t("linkFailureReauthDialog.title");
  }

  getCurrentServiceName() {
    return getAggregatorName(this.props.linkingService);
  }

  getFallbackDescription() {
    const fallbackServiceName = this.isCryptoFallback()
      ? "Kubera Crypto Connector"
      : this.getFallbackLinkingServiceName();
    var descriptionString = i18n
      .t("fallbackLinkFailureDialog.description")
      .replace("%s1%", this.getCurrentServiceName())
      .replace("%s2%", fallbackServiceName);

    if (this.props.linkingServiceHistory.length > 0) {
      var serviceList = this.props.linkingServiceHistory.map(serviceType => getAggregatorName(serviceType));
      serviceList.push(fallbackServiceName);

      var historyStrings = serviceList.map((serviceName, index) => {
        var plan = "Plan A";
        if (index === 1) {
          plan = "Plan B";
        } else if (index === 2) {
          plan = "Plan C";
        }
        const connect = `Connect via ${serviceName}`;
        const hasFailed = serviceName !== fallbackServiceName;
        return `<span style='font-family:"Roboto Mono"'>${plan} > ${connect} ${hasFailed ? "> Failed" : ""}</span>`;
      });

      descriptionString = historyStrings.join("\n") + "\n\n" + descriptionString;
    }
    return descriptionString;
  }

  getIcon() {
    if (
      (this.props.mode === linkAccountMode.LINK || this.props.mode === linkAccountMode.RECONNECT) &&
      this.isFallbackAvailable()
    ) {
      return fallbackIcon;
    }
    return linkFailureIcon;
  }

  getPositiveButtonTitle() {
    if (this.props.mode !== linkAccountMode.LINK && this.props.mode !== linkAccountMode.RECONNECT) {
      return this.props.canReconnectAccounts ? i18n.t("tryLater") : i18n.t("ok");
    } else if (this.isFallbackAvailable()) {
      if (this.isCryptoFallback()) {
        return i18n.t("linkFailureDialog.cryptoPositiveButtonTitle");
      }

      const fallbackServiceName = this.getFallbackLinkingServiceName();
      return i18n.t("fallbackLinkFailureDialog.buttonTitle").replace("%s%", fallbackServiceName);
    }
    return i18n.t("linkFailureDialog.positiveButtonTitle");
  }

  isCryptoFallback() {
    return this.props.data && this.props.data.errorCode === apiErrorCodes.CRYPTO_PROVIDER_NOT_SUPPORTED_WITH_AGGREGATOR;
  }

  isFallbackAvailable() {
    if (this.isCryptoFallback()) {
      return true;
    }
    return !this.props.nextAvailableAggregator === false;
  }

  getFallbackLinkingServiceName() {
    if (this.isFallbackAvailable() && this.props.nextAvailableAggregator) {
      return getAggregatorName(this.props.nextAvailableAggregator.linkType);
    }
    return null;
  }

  canEnableAddButton() {
    if (!this.state.manualEntryName === true || this.state.manualEntryName.trim().length === 0) {
      return false;
    }
    const value = this.state.manualEntryValue;
    if (!value === true) {
      return false;
    }
    const data = getAmountAndTickerFromInput(this.state.manualEntryValue, this.props.portfolio?.currency);
    if (!data.value === false) {
      return true;
    }
    return false;
  }

  isInstitutionNotAvaialbleError() {
    if (this.props.data && this.props.data.errorCode === "NO_PROVIDER_DETAILS_FOUND") {
      return true;
    } else if (this.props.linkingService === accountLinkingService.YODLEE) {
      return (
        this.props.data &&
        this.props.data.errorCode === "E801" &&
        this.props.data.errorMessage === "Provider not supported"
      );
    } else if (this.props.linkingService === accountLinkingService.LEAN) {
      return (
        this.props.data &&
        this.props.data.message === "ERROR__BAD_INITIALIZATION_CONFIG: bank_identifier provided is not recognised" &&
        this.props.data.secondary_status === "ERROR__BAD_INITIALIZATION_CONFIG"
      );
    } else if (
      this.props.linkingService === accountLinkingService.SALTEDGE ||
      this.props.linkingService === accountLinkingService.SALTEDGE_EU
    ) {
      //returned by backend api
      return (
        this.props.data &&
        this.props.data.errorCode === 1044 &&
        this.props.data.errorMessage === "Provider not supported"
      );
    } else if (this.props.linkingService === accountLinkingService.PLAID) {
      return (
        this.props.data &&
        this.props.data.error_code === "INSTITUTION_NO_LONGER_SUPPORTED" &&
        this.props.data.error_type === "INSTITUTION_ERROR"
      );
    } else if (this.props.linkingService === accountLinkingService.FINICITY) {
      return this.props.data && this.props.data.code === 4041;
    }
  }

  render() {
    const isFallbackAvailable = this.isFallbackAvailable();
    const siteName = this.props.providerName
      ? `${this.props.providerName} • ${getAggregatorName(this.props.linkingService)}`
      : "";
    const title = this.getTitle();
    const icon = this.getIcon();
    const positiveButtonTitle = this.getPositiveButtonTitle();
    const isInstitutionNotAvaialbleError = this.isInstitutionNotAvaialbleError();
    console.log("isInstitutionNotAvaialbleError", isInstitutionNotAvaialbleError, this.props.data);
    if (
      isInstitutionNotAvaialbleError &&
      this.props.mode !== linkAccountMode.LINK &&
      (this.props.canReconnectAccounts === false || !this.getFallbackLinkingServiceName() === true)
    ) {
      const nextStep = i18n.t("linkFailureDialog.institutioNotFoundError.revive.nextStep");
      return (
        <Container>
          <FailureSiteName>{siteName}</FailureSiteName>
          <Title>{i18n.t("linkFailureDialog.institutioNotFoundError.title")}</Title>
          <DescriptionContainer>
            <FailureNextStepsContainer>
              <FailureNextStep
                dangerouslySetInnerHTML={{
                  __html: nextStep
                }}
              />
            </FailureNextStepsContainer>
            <FailureIcon icon={icon} />
          </DescriptionContainer>
          <ButtonContainer>
            <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
          </ButtonContainer>
        </Container>
      );
    }
    if (
      this.props.data &&
      (this.props.data.errorCode === 109 || this.props.data.additionalStatus === "USER_ACTION_NEEDED_AT_SITE")
    ) {
      if (this.props.canReconnectAccounts === false || !this.getFallbackLinkingServiceName() === true) {
        const nextStep1 = i18n
          .t("linkFailureDialog.userActionNeededError.nextStep1")
          .replace("%s1%", this.props.providerName);
        const nextStep2 =
          this.props.mode === linkAccountMode.RECONNECT
            ? i18n.t("linkFailureDialog.userActionNeededError.reConnect.nextStep2")
            : this.props.mode === linkAccountMode.REFRESH || this.props.mode === linkAccountMode.EDIT
            ? i18n.t("linkFailureDialog.userActionNeededError.revive.nextStep2")
            : i18n.t("linkFailureDialog.userActionNeededError.newConnection.nextStep2");
        const nextStep3 =
          this.props.mode === linkAccountMode.RECONNECT
            ? i18n.t("linkFailureDialog.userActionNeededError.reConnect.nextStep3")
            : this.props.mode === linkAccountMode.REFRESH || this.props.mode === linkAccountMode.EDIT
            ? i18n.t("linkFailureDialog.userActionNeededError.revive.nextStep3")
            : i18n.t("linkFailureDialog.userActionNeededError.newConnection.nextStep3");
        return (
          <Container>
            <FailureSiteName>{siteName}</FailureSiteName>
            <Title>{`${title} \n“User action needed at source site”`}</Title>
            <DescriptionContainer>
              <FailureNextStepsContainer>
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: nextStep1
                  }}
                />
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: nextStep2
                  }}
                />
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: nextStep3
                  }}
                />
              </FailureNextStepsContainer>
              <ReauthFailureIcon icon={icon} />
            </DescriptionContainer>
            <ButtonContainer>
              <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
            </ButtonContainer>
          </Container>
        );
      }
    }

    if (this.props.mode !== linkAccountMode.LINK) {
      if (this.props.canReconnectAccounts === false || !this.getFallbackLinkingServiceName() === true) {
        return (
          <Container>
            <FailureSiteName>{siteName}</FailureSiteName>
            <Title>{title}</Title>
            <DescriptionContainer>
              <FailureNextStepsContainer>
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("linkFailureReauthDialog.description")
                  }}
                />
              </FailureNextStepsContainer>
              <ReauthFailureIcon icon={icon} />
            </DescriptionContainer>
            <ButtonContainer>
              <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
            </ButtonContainer>
          </Container>
        );
      } else if (this.props.mode !== linkAccountMode.RECONNECT) {
        const reconnectService = this.getFallbackLinkingServiceName();

        return (
          <Container>
            <FailureSiteName>{siteName}</FailureSiteName>
            <Title>{title}</Title>
            <DescriptionContainer>
              <FailureNextStepsContainer>
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("linkFailureReconnectDialog.nextStep1")
                  }}
                />
                <FailureNextStep>
                  <ReconnectTextContainer>
                    <div>{i18n.t("linkFailureReconnectDialog.nextStep2")}</div>
                    <ReconnectText onClick={this.props.onNegativeButtonClick}>
                      {i18n.t("reconnectButton").replace("%s%", reconnectService)}
                    </ReconnectText>
                  </ReconnectTextContainer>
                </FailureNextStep>
                <FailureNextStep
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("linkFailureReconnectDialog.nextStep3")
                  }}
                />
              </FailureNextStepsContainer>
              <ReauthFailureIcon icon={icon} />
            </DescriptionContainer>
            <ButtonContainer>
              <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
              <NegativeButton
                onClick={this.props.onNegativeButtonClick}
                title={i18n.t("reconnectButton").replace("%s%", reconnectService)}
              />
            </ButtonContainer>
          </Container>
        );
      }
    }
    if (isFallbackAvailable === true) {
      const description = this.getFallbackDescription();

      return (
        <Container>
          <Title>{title}</Title>
          <DescriptionContainer>
            <FallbackDescription
              descriptionStyle={this.props.descriptionStyle}
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
            <FallbackIcon icon={icon} />
          </DescriptionContainer>
          <ButtonContainer>
            <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
          </ButtonContainer>
        </Container>
      );
    }

    const nextStep1 = isInstitutionNotAvaialbleError
      ? i18n.t("linkFailureDialog.institutioNotFoundError.nextStep1")
      : i18n.t("linkFailureDialog.nextStep1").replace("%s1%", this.getCurrentServiceName());
    const nextStep2 = isInstitutionNotAvaialbleError
      ? i18n.t("linkFailureDialog.institutioNotFoundError.nextStep2")
      : i18n.t("linkFailureDialog.nextStep2");
    const nextStep3 = isInstitutionNotAvaialbleError
      ? i18n.t("linkFailureDialog.institutioNotFoundError.nextStep3")
      : i18n.t("linkFailureDialog.nextStep3");

    const manualEntryName = this.state.manualEntryName;
    const manualEntryValue = this.state.manualEntryValue;
    const addButtonEnabled = this.canEnableAddButton();
    const manualEntryCompleted = this.state.manualEntryCompleted;

    return (
      <Container>
        <FailureSiteName>{siteName}</FailureSiteName>
        <Title>{title}</Title>
        <DescriptionContainer>
          <FailureNextStepsContainer>
            <FailureNextStep
              dangerouslySetInnerHTML={{
                __html: nextStep1
              }}
            />
            <FailureNextStep
              dangerouslySetInnerHTML={{
                __html: nextStep2
              }}
            />
            <ManualEntryContainer>
              <ManualEntryNameInput
                value={manualEntryName}
                placeholder={i18n.t("name")}
                stretch={true}
                onChange={this.handleManualEntryNameChange}
                disabled={manualEntryCompleted === true}
              />
              <ManualEntryValueInput
                value={manualEntryValue}
                placeholder={i18n.t("value")}
                onChange={this.handleManualEntryValueChange}
                disabled={manualEntryCompleted === true}
              />
              <ManualEntryAddButton disabled={!addButtonEnabled} onClick={this.handleManualEntryAddClick}>
                {manualEntryCompleted === true && <CheckIcon />}
                {manualEntryCompleted === true ? "" : i18n.t("add")}
              </ManualEntryAddButton>
            </ManualEntryContainer>
            <ManualEntryCompletionMessage>
              {manualEntryCompleted ? i18n.t("rowAdded") : " "}
            </ManualEntryCompletionMessage>
            <FailureNextStep
              dangerouslySetInnerHTML={{
                __html: nextStep3
              }}
            />
          </FailureNextStepsContainer>
          <FailureIcon icon={icon} />
        </DescriptionContainer>
        <ButtonContainer>
          <PositiveButton onClick={this.handlePositiveButtonClick} title={positiveButtonTitle} />
        </ButtonContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  utilityStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkAccountFailureComponent);
