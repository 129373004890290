import React, { cloneElement } from "react";
import { Draggable } from "@hello-pangea/dnd";

const GridRowsInVisibleArea = ({ children, ...props }) => {
  const isDragEnabled = props.isDragEnabled === true;
  return (
    <Draggable draggableId={props.entry.viewId} index={props.entryIndex} isDragDisabled={isDragEnabled === false}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          {cloneElement(children, { provided, snapshot })}
        </div>
      )}
    </Draggable>
  );
};

export default GridRowsInVisibleArea;
