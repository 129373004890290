import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PercentageInput from "components/inputs/PercentageInput";
import PrimaryButton from "components/button/PrimaryButton";
import DropDown from "components/inputs/DropDown";

const RateDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ChangeByDropDown = styled(DropDown)`
  background: transparent;
  padding: 0;
  border: 0;
`;

const ChangeByContainer = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: white;
  align-items: center;
`;

const ChangeByInput = styled(PercentageInput)`
  flex: 1;
  border: 0;
  outline: 0;
`;

const PerYear = styled.div`
  margin-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 35px;
`;

class PercentageInputDialog extends React.Component {
  constructor(props) {
    super(props);

    const currentValue = this.props.data ? this.props.data.value : null;
    this.state = { value: Math.abs(currentValue), increasing: currentValue > 0 };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChangeByDropDownSelection = this.handleChangeByDropDownSelection.bind(this);
  }

  handleOnChange(e, value) {
    !value === true ? this.setState({ value: 0 }) : this.setState({ value: value });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveClick();
    }
  };

  handleSaveClick(e) {
    this.props.onVariableUpdate({ value: (this.state.increasing === true ? 1 : -1) * this.state.value });
    this.props.onDismiss();
  }

  handleChangeByDropDownSelection(item) {
    this.setState({ increasing: item.id === true });
  }

  getChangeByDropDownData() {
    var data = [
      {
        id: true,
        label: i18n.t("increaseBy"),
        selected: this.state.increasing === true
      },
      {
        id: false,
        label: i18n.t("decreaseBy"),
        selected: this.state.increasing === false
      }
    ];
    return data;
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <RateDialog>
          <Container>
            <ChangeByDropDown
              width={360}
              selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
              items={this.getChangeByDropDownData()}
              onSelection={this.handleChangeByDropDownSelection}
            />
            <ChangeByContainer>
              <ChangeByInput
                supressFocusBehaviour={true}
                inputValidation={value => value < 0}
                value={this.state.value}
                errorMessage={i18n.t("ratePerYearErrorMessage")}
                onChange={this.handleOnChange}
                autoFocus={true}
                onKeyDown={this.handleKeyDown}
              />
              <PerYear>{i18n.t("perYear")}</PerYear>
            </ChangeByContainer>
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </RateDialog>
      </DialogOverlay>
    );
  }
}

export default PercentageInputDialog;
