import { useSelector, useDispatch } from "react-redux";

import {
  accountSubscriptionIsActiveSelector,
  accountRemainingTsSelector,
  accountGraceTsSelector,
  fetchUser
} from "@kubera/common";

let isTimeoutSet = false;

const useShowPaywall = defaultState => {
  const dispatch = useDispatch();

  const accountSubscriptionIsActive = useSelector(accountSubscriptionIsActiveSelector);
  const accountRemainingTs = useSelector(accountRemainingTsSelector);
  const accountGraceTs = useSelector(accountGraceTsSelector);

  const accountGraceTsInMilliseconds = accountGraceTs * 24 * 60 * 60;

  const finalRemainingTs = accountRemainingTs + accountGraceTsInMilliseconds;
  if (!isTimeoutSet && finalRemainingTs >= 0) {
    isTimeoutSet = true;
    setTimeout(() => {
      dispatch(fetchUser());
    }, finalRemainingTs * 1000);
  }

  return !accountSubscriptionIsActive;
};

export default useShowPaywall;
