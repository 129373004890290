import React, { useEffect } from "react";
import { Navigate as Redirect } from "react-router-dom";

import { getQueryParams, signInWithGoogle } from "@kubera/common";
import { routes } from "./Routes";
import InitialLoader from "components/loader/InitialLoader";

export function SignInCallback({ isSignedIn, redirectToHome, handleSignInCallback }) {
  const queryParams = getQueryParams(window.location);
  const error = queryParams.error_description;

  useEffect(() => {
    if (error?.includes("SUCCESSFULLY_LINKED_IDENTITIES")) {
      signInWithGoogle();
    }
  }, [error]);

  useEffect(() => {
    if (isSignedIn === true) {
      handleSignInCallback();
    }

    setTimeout(() => {
      handleSignInCallback();
    }, 1000);
    // eslint-disable-next-line
  }, [isSignedIn]);

  if (error) {
    // This happens if the user signs in using email password
    // and then tries to sign in with Google using that same email.
    // Sending the user back to Google sign in page merges the
    // two accounts and the sign up works.
    if (error.includes("SUCCESSFULLY_LINKED_IDENTITIES")) {
      return <InitialLoader />;
    } else {
      return (
        <Redirect
          to={{
            pathname: routes.SIGNIN,
            state: { socialLoginError: true }
          }}
        />
      );
    }
  } else {
    if (isSignedIn === true) {
      return redirectToHome();
    }
    return <InitialLoader />;
  }
}
