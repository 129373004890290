import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { isMobile } from "@kubera/common";
import i18n from "i18next";

const isMobileDevice = isMobile();

const ConditionalContainerStyled = isMobileDevice
  ? styled(DialogOverlay)`
      width: 100vw;
      height: 100vh;

      & > div {
        padding: 0 36px;
      }

      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const GenericDialog = styled(Dialog)`
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 130px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${isMobileDevice ? "0" : "50px 45px 50px 45px"};
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const AdvisorList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Advisor = styled.div`
  font-style: normal;
  font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
  font-size: 14px;
  line-height: 130%;
  margin-top: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
`;

class FilterClientsByAdvisorDialog extends React.Component {
  render() {
    const managers = this.props.managers.filter(item => !item.userId === false);

    return (
      <ConditionalContainerStyled onDismiss={this.props.onDismiss}>
        <GenericDialog className={this.props.className}>
          <Container>
            <Title>{i18n.t("showClientsOf")}</Title>
            <AdvisorList>
              {managers.map((manager, index) => (
                <Advisor
                  key={index}
                  isSelected={
                    !this.props.selectedManager === false && manager.userId === this.props.selectedManager.userId
                  }
                  onClick={e => {
                    this.props.onAdvisorSelection(manager);
                  }}
                >
                  {manager.name}
                </Advisor>
              ))}
            </AdvisorList>
          </Container>
        </GenericDialog>
      </ConditionalContainerStyled>
    );
  }
}

export default FilterClientsByAdvisorDialog;
