import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import NumberInput from "components/inputs/NumberInput";

const NumberDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Input = styled(NumberInput)`
  height: 24px;
  margin-top: 12px;
  padding: 12px 20px 12px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 25px;
`;

class NumberInputDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: props.data ? props.data.value : null
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleNumberInputChange(e, value) {
    this.setState({ input: value });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveClick();
    }
  };

  handleSaveClick(e) {
    if (this.state.input > 0) {
      this.props.onVariableUpdate({ ...(this.props.data || {}), value: this.state.input });
      this.props.onDismiss();
    }
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <NumberDialog>
          <Container>
            <Title>{this.props.title}</Title>
            <Input
              placeholder={this.props.title}
              autoFocus={true}
              value={this.state.input}
              onChange={this.handleNumberInputChange}
              onKeyDown={this.handleKeyDown}
            />
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </NumberDialog>
      </DialogOverlay>
    );
  }
}

export default NumberInputDialog;
