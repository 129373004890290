import React from "react";
import styled from "styled-components";
import {
  formatNumberWithCurrency,
  shortFormatNumberWithCurrency,
  getPercentageChange,
  isCryptoCurrency,
  getSymbolForTickerUsingShortName,
  isMobile,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";

import LabelUpdateHandler from "./LabelUpdateHandler";

const isMobileDevice = isMobile();

const Label = styled.div`
  color: ${props =>
    !props.change === true || props.disableColor === true
      ? props.disableColorCode
        ? props.disableColorCode
        : isMobileDevice
        ? props.theme.mobileTxtColor
        : props.theme.changeLabelCLR
      : props.change >= 0
      ? props.theme.gridGreenColorForLightBackground
      : props.theme.gridRedColorForLightBackground};

  filter: ${props => (props.isPlaceholder === true ? "opacity(0.4)" : "")};
  font-feature-settings: "ss01", "calt" 0;
`;

const LabelUpdateHandlerComponent = styled(LabelUpdateHandler)`
  left: unset;
  ${props => props.alignPosition || "right"}: 0;
`;

class ChangeLabel extends React.Component {
  getChange(startValue, endValue, isRecapChangeLabel) {
    const change = endValue - startValue;
    if (isRecapChangeLabel && change < 1) {
      return Math.kuberaRoundToDecimalPlaces(change, 2);
    } else if (!this.props.disableRoundDown === false || isCryptoCurrency(this.props.currency) === true) {
      return endValue - startValue;
    }
    return Math.kuberaFloor(endValue - startValue);
  }

  getChangeString(
    currency,
    startValue,
    endValue,
    isChangeCalculatedForPercentageValues,
    shouldShowCurrencySymbol,
    showPercentageInsideParanthesis,
    isRecapChangeLabel,
    shouldShowApproximateSymbol = false,
    changeSymbol
  ) {
    if (isChangeCalculatedForPercentageValues) {
      const change = Math.abs(endValue - startValue);
      return `${formatNumberWithKuberaNumberFormatSettings(parseFloat(change.toFixed(2)))}%`;
    }
    const percentageChange = Math.abs(getPercentageChange(startValue, endValue, !isCryptoCurrency(currency), true));
    const change = Math.abs(this.getChange(startValue, endValue, isRecapChangeLabel));
    if ((percentageChange === 0 || change === 0 || !this.props.hidePercentage === false) && !isRecapChangeLabel) {
      return this.formatChangeNumber(change, currency, shouldShowCurrencySymbol);
    }
    if (isRecapChangeLabel && shouldShowApproximateSymbol) {
      if (change < 1) {
        return `~(${changeSymbol}${formatNumberWithKuberaNumberFormatSettings(change)})`;
      }
      return percentageChange === 0
        ? `~(${changeSymbol}${this.formatChangeNumber(change, currency, shouldShowCurrencySymbol)})`
        : `~(${changeSymbol}${this.formatChangeNumber(
            change,
            currency,
            shouldShowCurrencySymbol
          )} • ${formatNumberWithKuberaNumberFormatSettings(percentageChange)}%)`;
    } else if (showPercentageInsideParanthesis) {
      if (isRecapChangeLabel) {
        return percentageChange === 0
          ? `${this.formatChangeNumber(change, currency, shouldShowCurrencySymbol)}`
          : `${this.formatChangeNumber(
              change,
              currency,
              shouldShowCurrencySymbol
            )} (${formatNumberWithKuberaNumberFormatSettings(percentageChange)}%)`;
      }
      return `${this.formatChangeNumber(
        change,
        currency,
        shouldShowCurrencySymbol
      )} (${formatNumberWithKuberaNumberFormatSettings(percentageChange)}%)`;
    } else {
      if (isRecapChangeLabel) {
        return percentageChange === 0
          ? `${this.formatChangeNumber(change, currency, shouldShowCurrencySymbol)}`
          : `${this.formatChangeNumber(
              change,
              currency,
              shouldShowCurrencySymbol
            )} • ${formatNumberWithKuberaNumberFormatSettings(percentageChange)}%`;
      }
      return `${this.formatChangeNumber(
        change,
        currency,
        shouldShowCurrencySymbol
      )} ${formatNumberWithKuberaNumberFormatSettings(percentageChange)}%`;
    }
  }

  formatChangeNumber(change, currency, shouldShowCurrencySymbol, includePositiveSign = false) {
    if (!this.props.disableShortFormat === false) {
      return formatNumberWithCurrency(
        change,
        currency,
        undefined,
        isCryptoCurrency(currency) ? 4 : undefined,
        undefined,
        !this.props.disableRoundDown === false
      );
    } else {
      return shortFormatNumberWithCurrency(change, currency, true, shouldShowCurrencySymbol, includePositiveSign);
    }
  }

  render() {
    const {
      className,
      currency,
      startValue,
      endValue,
      disableColor,
      disableColorCode,
      prefixText,
      placeholder,
      isAnimationAllowed = true,
      shouldShowCurrencySymbol = true,
      showPercentageInsideParanthesis = true,
      isRecapChangeLabel = false,
      ...other
    } = this.props;

    if (
      !currency === true ||
      startValue === null ||
      startValue === undefined ||
      endValue === null ||
      endValue === undefined
    ) {
      return null;
    }

    if (placeholder === true) {
      return (
        <Label {...other} className={className} disableColor={true} isPlaceholder={true}>
          {getSymbolForTickerUsingShortName(currency) + "XXXX (X%)"}
        </Label>
      );
    }

    const change = this.getChange(startValue, endValue, isRecapChangeLabel);
    if (change === 0 && isRecapChangeLabel) {
      return null;
    }
    const changeSymbol = !change === false ? (change > 0 ? (this.props.minusMinus ? "- -" : "+") : "-") : "";
    const shouldShowApproximateSymbol = Math.abs(change) < 10;
    const changeString =
      isRecapChangeLabel && shouldShowApproximateSymbol && !this.props.isChangeCalculatedForPercentageValues
        ? this.getChangeString(
            currency,
            startValue,
            endValue,
            this.props.isChangeCalculatedForPercentageValues,
            shouldShowCurrencySymbol,
            showPercentageInsideParanthesis,
            isRecapChangeLabel,
            shouldShowApproximateSymbol,
            changeSymbol
          )
        : changeSymbol +
          this.getChangeString(
            currency,
            startValue,
            endValue,
            this.props.isChangeCalculatedForPercentageValues,
            shouldShowCurrencySymbol,
            showPercentageInsideParanthesis,
            isRecapChangeLabel,
            shouldShowApproximateSymbol,
            changeSymbol
          );
    return (
      <Label
        {...other}
        className={className}
        change={change}
        disableColor={disableColor}
        disableColorCode={disableColorCode}
      >
        {!isAnimationAllowed ? (
          `${prefixText || ""}${changeString}`
        ) : (
          <LabelUpdateHandlerComponent
            value={`${prefixText || ""}${changeString}`}
            preventAnimation={this.props.preventAnimation || false}
            alignPosition={this.props.alignPosition}
          />
        )}
        {this.props.children}
      </Label>
    );
  }
}

export default ChangeLabel;
