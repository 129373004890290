import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { currentPortfolioSelector, tickerTypes } from "@kubera/common";

import SearchInput from "components/inputs/SearchInput";
import Autocomplete from "components/inputs/Autocomplete";
import CurrencyInput from "./CurrencyInput";

const OptionsSearchInp = styled(SearchInput)`
  height: 35px;
  font-size: 14px;
`;

const ListContainer = styled.ul`
  position: absolute;
  padding-left: 0;
  margin: 0;
  list-style: none;
  max-height: 515px;
  overflow: auto;
  font-size: 14px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #fff;
  width: 100%;
`;

const AutocompleteSearchInp = ({
  disabled,
  searchValue,
  inputPlaceholder = "",
  selectedIndex = 0,
  className = "",
  options = {},
  onSearchChange = () => null,
  onSelect: onItemSelect = () => null,
  onRenderList = () => null,
  onFocus = () => null,
  onBlur = () => null,
  isLoading = false,
  isAutofocusDisabled = false,
  spellCheck = false,
  preventCloseOnBlur = true,
  isCurrencySearch = false,
  ...otherProps
}) => {
  const portfolio = useSelector(currentPortfolioSelector);
  const [tickerId, setTickerId] = useState();

  const onSearchValChange = value => {
    if (!value && value.trim() !== "") return;
    if (isCurrencySearch) {
      setTickerId(value.tickerId);
      onSearchChange("" + value.value, {
        tickerId: value.tickerId,
        exchangeRate: value.exchangeRate
      });
      return;
    }
    onSearchChange(value);
  };

  const onSelect = selected => {
    onItemSelect(selected);
  };

  const getInput = () => {
    if (isCurrencySearch) {
      return (
        <CurrencyInput
          tickerId={tickerId}
          portfolioCurrency={portfolio.currency}
          value={searchValue}
          placeholder={inputPlaceholder}
          hideExchangeRateBubble={true}
          isReadOnly={false}
          onChange={onSearchValChange}
          isInSingleCellMode={false}
          supportedTickerTypes={[tickerTypes.FIAT, tickerTypes.CRYPTO]}
        />
      );
    }
    return (
      <OptionsSearchInp
        disabled={disabled}
        value={searchValue}
        onChange={onSearchValChange}
        placeholder={inputPlaceholder}
        isLoading={isLoading}
        autoFocus={!isAutofocusDisabled}
        spellCheck={`${spellCheck}`}
        loaderThickness={3}
        {...otherProps}
      />
    );
  };

  return (
    <Autocomplete
      selectedIndex={selectedIndex}
      className={className}
      inpList={options}
      renderInput={getInput}
      renderList={onRenderList}
      getSelection={onSelect}
      listContainer={ListContainer}
      preventCloseOnBlur={preventCloseOnBlur}
      onFocus={onFocus}
      onBlur={onBlur}
      isCurrencySearch={isCurrencySearch}
    />
  );
};

export default AutocompleteSearchInp;
