import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { hashParams } from "routes";
import { getHashParams, useHistory } from "@kubera/common";

const GridPageHandleSheetIdHash = props => {
  const history = useHistory();
  const location = useLocation();

  const prevLocation = useRef(location);

  const urlHashParams = getHashParams(location);
  const currentSheetId = urlHashParams[hashParams.SHEET_ID];

  useEffect(() => {
    props.isComingFromEmptyScreenRef.current = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlHashParams = getHashParams(prevLocation.current);
    const prevSheetId = urlHashParams[hashParams.SHEET_ID];

    if (!currentSheetId && prevSheetId) {
      const existingHash = location.hash ? `${location.hash}&` : "";
      history.replace({
        ...location,
        hash: `${existingHash}${hashParams.SHEET_ID}=${prevSheetId}`
      });
    }

    if (currentSheetId) {
      prevLocation.current = location;
    }
  }, [currentSheetId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default React.memo(GridPageHandleSheetIdHash);
