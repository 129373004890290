import React, { Fragment } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { modalValues } from "routes";

import StocksConnectComponent from "./StocksConnectComponent";
import CoinsConnectComponent from "./CoinsConnectComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
  margin-bottom: 20px;
`;

const Tabs = styled.div`
  display: inline-block;
  margin-right: 16px;
`;

const ProviderTitle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

class SingleComponentOptions extends React.Component {
  render() {
    if ([modalValues.CONNECT_STOCK, modalValues.CONNECT_METAL].includes(this.props.modalValue)) {
      return (
        <Container>
          <ProviderScreenContainer>
            <Title>
              <Tabs>{i18n.t("linkAccount.stocksTitle")}</Tabs>
            </Title>
            <Fragment>
              <ProviderTitle>{i18n.t("linkAccount.stockProviderTitle")}</ProviderTitle>
              <StocksConnectComponent
                searchInputPlaceholder={i18n.t("linkAccount.searchStocksPlaceholder")}
                searchHint={i18n.t("linkAccount.stockSearchHint")}
                custodianId={this.props.custodianId}
                tab={this.props.tab}
              />
            </Fragment>
          </ProviderScreenContainer>
        </Container>
      );
    }

    if (this.props.modalValue === modalValues.CONNECT_CRYPTO) {
      return (
        <Container>
          <ProviderScreenContainer>
            <Title>
              <Tabs>{i18n.t("linkAccount.coinsTitle")}</Tabs>
            </Title>
            <CoinsConnectComponent
              searchInputPlaceholder={i18n.t("linkAccount.searchCoinsPlaceholder")}
              custodianId={this.props.custodianId}
            />
          </ProviderScreenContainer>
        </Container>
      );
    }

    return null;
  }
}

export default SingleComponentOptions;
