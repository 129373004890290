import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/browser";

import {
  wlAdminListChargesSelector,
  wlPaymentMethodSelector,
  siteConfigSelector,
  userSelector,
  fetchUser,
  SUBSCRIPTION_ERROR,
  wlPaymentMethodTypeStrings,
  paymentMethodTypeCorrespondingStripeActions
} from "@kubera/common";

import AccountCardPayment from "./AccountCardPayment";
import AccountSubscribedCard from "./AccountSubscribedCard";
import PaymentsList from "./PaymentsList";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";

const AccountPaymentBlock = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const paymentMethod = useSelector(wlPaymentMethodSelector);
  const user = useSelector(userSelector);
  const wlAdminListCharges = useSelector(wlAdminListChargesSelector);

  const [updateMode, setUpdateMode] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);

  const captureError = useCallback((errorState, reason) => {
    Sentry.withScope(scope => {
      scope.setExtras({
        plan: "monthly",
        reason
      });
      Sentry.captureMessage(errorState);
    });
  }, []);

  const handleSubscribedCardSubmit = () => {
    if (paymentMethod.paymentMethodId) {
      setUpdateMode(false);
    }
  };

  const handlePaymentError = isError => {
    setIsPaymentError(isError);
  };

  const handleTurnOffUpdateMode = () => {
    setUpdateMode(false);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const renderPaymentBlock = () => {
    if (!!paymentMethod.paymentMethodId && !updateMode) {
      return (
        <AccountSubscribedCard
          setUpdateMode={setUpdateMode}
          isAuthenticating={isAuthenticating}
          isPaymentError={isPaymentError}
        />
      );
    }

    return (
      <AccountCardPayment
        updateMode={updateMode}
        setIsSubmitted={handleSubscribedCardSubmit}
        captureError={captureError}
        handleTurnOffUpdateMode={handleTurnOffUpdateMode}
      />
    );
  };

  const handleAuthenticationRequired = async accountPaymentAuth => {
    if (
      accountPaymentAuth &&
      (accountPaymentAuth.intentStatus === "requires_action" ||
        accountPaymentAuth.intentStatus === "requires_payment_method")
    ) {
      setIsAuthenticating(true);
      const stripeMethodToCall =
        stripe[paymentMethodTypeCorrespondingStripeActions[accountPaymentAuth.paymentMethodType]];
      return stripeMethodToCall(accountPaymentAuth.clientSecret, { payment_method: paymentMethod.paymentMethodId })
        .then(async result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsDeclinedError(true);
          } else if (result.paymentIntent.status === "succeeded") {
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_STATUSERROR, result.paymentIntent.last_payment_error);
            setPaymentErrorMsg(result.paymentIntent.last_payment_error);
            setIsDeclinedError(true);
          }
        })
        .catch(error => null);
    }

    return Promise.resolve();
  };

  useEffect(() => {
    const handlePaymentOnVisibilityChange = () => {
      if (!document.hidden) {
        const overlay = document.getElementById("kubera-container-overlay");
        overlay.style.pointerEvents = "none";
        dispatch(
          fetchUser(() => {
            overlay.style.pointerEvents = "auto";
          })
        );
      }
    };

    handlePaymentOnVisibilityChange();
    document.addEventListener("visibilitychange", handlePaymentOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handlePaymentOnVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsAuthenticating(false);
  }, [wlAdminListCharges]);

  return (
    <>
      {renderPaymentBlock()}
      <PaymentsList
        userId={user.id}
        handlePaymentError={handlePaymentError}
        handleAuthenticationRequired={handleAuthenticationRequired}
        hideTitleOnLoad={!paymentMethod.paymentMethodId || updateMode}
      />
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </>
  );
};

let stripePromise = null;
const handleStripeLoad = async stripeAccount => {
  if (stripePromise === null) {
    stripePromise = await loadStripe(`${process.env.REACT_APP_STRIPE_PK}`, { stripeAccount });
  }

  return stripePromise;
};

const options = {
  mode: "setup",
  paymentMethodTypes: ["card"]
};
const ElementsWrapper = props => {
  const siteConfig = useSelector(siteConfigSelector);
  const wlPaymentMethod = useSelector(wlPaymentMethodSelector);

  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const [key, setKey] = useState(0);

  let currentPaymentMethodType = wlPaymentMethod?.type;

  if (!wlPaymentMethod.cardLast4Digits) {
    currentPaymentMethodType = null;
  }

  useEffect(() => {
    const loadStripe = async () => {
      await handleStripeLoad(siteConfig.stripeConnectedAccount.id);
      setIsStripeLoaded(true);
    };
    if (siteConfig.stripeConnectedAccount && !isStripeLoaded) {
      loadStripe();
    }
  }, [siteConfig.stripeConnectedAccount, isStripeLoaded]);

  useEffect(() => {
    if (currentPaymentMethodType) {
      options.paymentMethodTypes = [wlPaymentMethodTypeStrings[currentPaymentMethodType]];
    } else if (siteConfig.paymentMethodType) {
      options.paymentMethodTypes = siteConfig.paymentMethodType.split(",");
    }

    setKey(prevKey => prevKey + 1);
  }, [siteConfig.paymentMethodType, currentPaymentMethodType]);

  if (!isStripeLoaded || !siteConfig.paymentMethodType) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={options} key={key}>
      <AccountPaymentBlock {...props} />
    </Elements>
  );
};

export default ElementsWrapper;
