import { hashParams, modalValues } from "routes";

export const detailsTabs = {
  NOTES: "CustodianNotesComponent",
  DOCUMENTS: "CustodianDocumentsComponent",
  RETURNS: "CustodianReturnsComponent",
  ASSET_VALUE: "CustodianAssetValueComponent",
  HOLDINGS: "CustodianHoldingsComponent",
  HOME: "CustodianHomesComponent",
  PARENT_DETAILS: "CustodianParentDetailsComponent",
  PVST_DETAILS: "CustodianPVSTDetailsComponent",
  DEBT_HISTORY: "CustodianDebtHistoryComponent",
  INSURANCE: "CustodianInsuranceDetailsComponent",
  OWNERSHIP: "CustodianOwnershipComponent",
  ASSORTED: "CustodianAssortedFlagsComponent",
  LINKED_PORTFOLIO: "LinkedPortfolioComponent",
  REPORTING: "CustodianReportingComponent"
};

const staticMethods = {
  show: (history, location, custodianId, shouldReplace = false, holdingIdToFlash = null, otherLocationParts = {}) => {
    var detailsHash = `${hashParams.MODAL}=${modalValues.CUSTODIAN_DETAILS}&${hashParams.ID}=${custodianId}${
      otherLocationParts.hash ? `&${otherLocationParts.hash}` : ""
    }`;

    if (!holdingIdToFlash === false) {
      detailsHash = detailsHash + `&${hashParams.HOLDING_ID}=${holdingIdToFlash}`;
    }

    if (shouldReplace) {
      history.replace({ ...location, ...otherLocationParts, hash: detailsHash });
    } else {
      history.push({ ...location, ...otherLocationParts, hash: detailsHash });
    }
  }
};

export default staticMethods;
