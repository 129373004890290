import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import i18n from "i18next";

import {
  currentPortfolioSelector,
  getExchangeRate,
  shortFormatNumberWithCurrency,
  getTickerUsingShortName,
  getTickerUsingId,
  calcCustodianOwnershipValue,
  getHomeLTV,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";

import { category } from "components/dashboard/DashboardComponentExports";
import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

const LinkDisplays = `
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 29px;
  box-sizing: border-box;
`;

const LinkDisplayContainer = styled.div`
  ${LinkDisplays}
  display: flex;
  margin-left: 14px;
`;

const EquityContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.theme.linkedIconEquityContainerBG};
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${props => props.theme.linkedIconEquityContainerCLR};
  text-transform: uppercase;
  padding: 0 3px;
  white-space: nowrap;
`;

const EquityText = styled.div`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: -0.02em;
`;

const EquityBadge = ({ relatedId, currency, currencyCellValue, showLTV = false }) => {
  const portfolio = useSelector(currentPortfolioSelector);
  const equityAndLTV = useMemo(() => {
    if (relatedId) {
      const debtLinked = filterDataFromPortfolioDetails(
        portfolio.details,
        custodian => relatedId.includes(custodian.id),
        void 0,
        sheet => sheet.category === category.DEBT
      );

      if (debtLinked.rows.length === 0) {
        return null;
      }

      const portfolioValueTickerId = getTickerUsingShortName(portfolio.currency).id;
      const debtLinkedRows = debtLinked.rows.length
        ? debtLinked.rows
        : [
            {
              value: 0,
              valueTickerId: portfolioValueTickerId
            }
          ];

      const cellExchangeRate = getExchangeRate(currency, portfolio.currency);

      const debtExchangeRates = debtLinkedRows.map(debtLinkedRow =>
        getExchangeRate(
          getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
          portfolio.currency
        )
      );

      const debtValueToMinus = debtLinkedRows.reduce((acc, debtLinkedRow, index) => {
        return (
          acc + calcCustodianOwnershipValue(debtLinkedRow.value * debtExchangeRates[index], debtLinkedRow.ownership)
        );
      }, 0);
      //equity is already factored into the cell value
      const diff = (cellExchangeRate ? currencyCellValue * cellExchangeRate : currencyCellValue) - debtValueToMinus;

      return [
        shortFormatNumberWithCurrency(diff, portfolio.currency, true, true),
        getHomeLTV(debtValueToMinus, cellExchangeRate ? currencyCellValue * cellExchangeRate : currencyCellValue)
      ];
    }
  }, [portfolio.currency, portfolio.details, relatedId, currency, currencyCellValue]);

  const equity = equityAndLTV && equityAndLTV[0] ? equityAndLTV[0] : null;
  const ltv =
    equityAndLTV && equityAndLTV[1] ? formatNumberWithKuberaNumberFormatSettings(equityAndLTV[1]) + "%" : null;

  if (equity) {
    return (
      <LinkDisplayContainer>
        <EquityContainer>
          {equity}
          <br />
          <EquityText>{i18n.t("equity")}</EquityText>
        </EquityContainer>
        {showLTV && ltv && (
          <EquityContainer>
            {ltv}
            <br />
            <EquityText>{i18n.t("LTV")}</EquityText>
          </EquityContainer>
        )}
      </LinkDisplayContainer>
    );
  }

  return null;
};

export default EquityBadge;
