import React from "react";
import styled from "styled-components";
import {
  formatNumberWithCurrency,
  getSymbolForTickerUsingShortName,
  getAmountAndTickerFromInput,
  getCharacterForDecimal,
  parseNumberStringToFloat,
  UNKNOWN_TICKER_SHORT_NAME,
  isCryptoCurrency,
  calcCustodianOwnershipValue
} from "@kubera/common";
import { cellMode } from "components/grid/GridCellExports";
import AnimatedNumber from "react-animated-number";
import CurrencyCellUpdateHandler from "./CurrencyCellUpdateHandler";

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const CurrencyContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  order: ${props => props.order};
`;

const CellCurrencyValue = styled.div`
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: ${props => (!props.textAlignment === false ? props.textAlignment : "right")};
  font-feature-settings: "ss01" on;
  color: ${props => (props.hasError === true ? "red" : props.theme.cellCurrencyValueCLR)};
  padding-right: 15px;
  padding-left: 15px;
  z-index: 100;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 12px;
  z-index: 100;
  width: 97px;
  direction: ${props => (window.safari ? "ltr" : "rtl")};
`;

const getChangeIndicatorColor = ({ change = 0, isDebt = false, theme }) => {
  if (!change === true) {
    return "";
  }

  if (isDebt === true) {
    return change > 0 ? theme.gridRedColorForLightBackground : theme.gridGreenColorForLightBackground;
  } else {
    return change > 0 ? theme.gridGreenColorForLightBackground : theme.gridRedColorForLightBackground;
  }
};
const ChangeIndicator = styled.div`
  position: absolute;
  right: 4px;
  top: ${props => (props.showingSubText === true ? "10px" : "17px")};
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid ${props => getChangeIndicatorColor(props)};
  transform: ${props => (props.change >= 0 ? "" : "rotate(180deg)")};
`;

const LinkingAnimatedNumber = styled(AnimatedNumber)`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${props => (!props.textalignment === true || props.textalignment === "right" ? 0 : "")};
  left: ${props => (!props.textalignment === false && props.textalignment === "left" ? 0 : "")};
  top: 0;
  bottom: 0;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  text-align: right;
  font-family: "Roboto Mono";
`;

class CurrencyCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      data: null,
      linkingAnimationNumber: this.getLinkingRandomNumber(),
      linkingAnimationDuration: 300
    };

    this.symbolForGridCurrency = getSymbolForTickerUsingShortName(props.gridCurrency);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleCopy = this.handleCopy.bind(this);

    this.animateNumberIfNeeded();
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    if (!this.animationTimer === false) {
      clearInterval(this.animationTimer);
    }
    this.isComponentMounted = false;
  }

  componentDidUpdate(oldProps) {
    if (this.props.mode === cellMode.SELECT && this.state.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    this.animateNumberIfNeeded();
  }

  animateNumberIfNeeded() {
    const animateNumber = () => {
      if (this.props.loading === true) {
        const newRandomNumber = this.getLinkingRandomNumber();
        const delta = Math.abs(newRandomNumber - this.state.linkingAnimationNumber);
        const duration = delta * 50;

        this.setState({
          ...this.state,
          linkingAnimationNumber: newRandomNumber,
          linkingAnimationDuration: duration
        });
      } else if (!this.props.loading === true || this.isComponentMounted === false) {
        clearInterval(this.animationTimer);
        this.animationTimer = null;
      }
    };

    if (this.props.loading === true && !this.animationTimer === true) {
      this.animationTimer = setInterval(() => {
        animateNumber();
      }, 300);
      setTimeout(() => {
        animateNumber();
      }, 0);
    }
  }

  handleInputChange(e) {
    if (this.props.multiCurrency === false) {
      var input = e.target.value;
      input = input.replace(getSymbolForTickerUsingShortName(this.props.currency), "");

      if (!parseNumberStringToFloat(input) === true) {
        return;
      }
    }

    const fallbackCurrency = this.props.mode === cellMode.EDIT ? this.props.gridCurrency : this.props.currency;
    const data = getAmountAndTickerFromInput(e.target.value, fallbackCurrency);
    const maxAllowedDecimals = getCharacterForDecimal() === "." && data.ticker.shortName.includes(".") ? 2 : 1;

    if (
      e.target.value &&
      e.target.value.split(getCharacterForDecimal()).length > maxAllowedDecimals + 1 &&
      e.target.value.charAt(e.target.value.length - 1) === getCharacterForDecimal()
    ) {
      this.setState({ value: e.target.value.substring(0, e.target.value.length - 1), data: data });
      return;
    }

    this.setState({ value: e.target.value, data: data });

    if (this.props.mode !== cellMode.EDIT || this.props.callOnChangeEverytime === true) {
      this.props.onChange(data.value, data.isTickerInputEmpty || data.isTickerInputValid ? data.ticker : null, e);
    }
  }

  handleBlur(e) {
    const data = this.state.data;
    if (this.props.mode === cellMode.EDIT && !data === false) {
      this.props.onChange(data.value, data.isTickerInputEmpty || data.isTickerInputValid ? data.ticker : null, e);
    }

    this.setState({ ...this.state, data: null });
    this.props.onBlur(e);
  }

  handleCopy(e) {
    if (this.props.mode === cellMode.SELECT) {
      const displayString = this.getDisplayString(
        this.props.value,
        this.props.currency,
        this.props.gridCurrency,
        cellMode.EDIT
      );
      e.clipboardData.setData("text/plain", displayString);
      e.preventDefault();
    }
  }

  getDisplayString(value, currency, gridCurrency, mode) {
    if (!this.props.invalidInputText === false && mode === cellMode.SELECT) {
      return this.props.invalidInputText;
    }
    if (currency === UNKNOWN_TICKER_SHORT_NAME) {
      return this.props.invalidInputText;
    }

    const data = this.state.data;
    if (mode === cellMode.EDIT) {
      if (!this.props.invalidInputText === false) {
        return this.props.value === this.state.value ? this.props.invalidInputText : this.state.value;
      }
      if (this.props.isInSingleCellMode === true && !this.props.value === true && !this.state.value === false) {
        return formatNumberWithCurrency(this.state.value, gridCurrency, false);
      }
      if (this.props.value !== this.state.value) {
        return value;
      }
    } else if (mode === cellMode.UPDATE) {
      // If the user is updating the cell and trying to update currency
      // remove the current currency symbol so that the new input
      // can be parsed correctly
      if (data && data.isTickerInputValid === false) {
        return value.replace(getSymbolForTickerUsingShortName(currency), "");
      }
    }

    if (isNaN(value) === true) {
      return value;
    }
    if (value === null || value === undefined || value === "") {
      return "";
    }

    var applicableCurrency = currency;
    if (mode === cellMode.SELECT) {
      applicableCurrency = gridCurrency;
    }

    var applicableValue = value * this.props.gridCell.getCellExchangeRate(applicableCurrency);
    if (mode === cellMode.SELECT) {
      if (isCryptoCurrency(gridCurrency) === false) {
        applicableValue =
          (applicableValue > 0 && applicableValue < 1) || this.props.showDecimal
            ? Number.isInteger(this.props.decimalPlaces)
              ? applicableValue.toFixed(this.props.decimalPlaces)
              : applicableValue.toFixed(2)
            : Math.kuberaFloor(applicableValue);
      }
    } else if (mode === cellMode.EDIT) {
      applicableValue = value;
    }

    return mode === cellMode.SELECT || this.props.decimalPlaces > 2
      ? formatNumberWithCurrency(
          applicableValue,
          applicableCurrency,
          mode === cellMode.SELECT,
          mode === cellMode.SELECT ? 4 : 8,
          mode !== cellMode.SELECT,
          false,
          true,
          true,
          true
        )
      : formatNumberWithCurrency(
          applicableValue,
          applicableCurrency,
          mode === cellMode.SELECT,
          mode === cellMode.SELECT ? 4 : 8,
          mode !== cellMode.SELECT
        );
  }

  isCellCurrencyDifferent() {
    return this.props.currency !== this.props.gridCurrency;
  }

  doesUserHasFullOwnership() {
    const ownership = parseInt(this.props.ownership, 10);
    if (!this.props.ownership === true && ownership !== 0) {
      return true;
    }
    return ownership === 100;
  }

  getLinkingRandomNumber() {
    return Math.floor(Math.random() * (90000 - 10000 + 1)) + 10000;
  }

  render() {
    const {
      className,
      type,
      onChange,
      currency,
      gridCurrency,
      onBlur,
      loading,
      change,
      isDebt,
      textAlignment,
      accessoryView,
      hasError,
      ownership,
      isEditable,
      ...other
    } = this.props;

    if (loading === true) {
      return (
        <Container className={this.props.className} id={this.props.id} tabIndex={0}>
          <LinkingAnimatedNumber
            stepPrecision={0}
            textalignment={textAlignment}
            value={this.state.linkingAnimationNumber}
            duration={this.state.linkingAnimationDuration}
            formatValue={n =>
              this.symbolForGridCurrency === this.props.gridCurrency
                ? this.symbolForGridCurrency + " " + n
                : this.symbolForGridCurrency + n
            }
          />
        </Container>
      );
    }

    const value = this.props.mode === cellMode.SELECT ? this.props.value : this.state.value;
    const displayString = this.getDisplayString(
      this.props.mode === cellMode.SELECT ? calcCustodianOwnershipValue(value, ownership) : value,
      currency,
      gridCurrency,
      this.props.mode
    );
    const showCellCurrencyValue =
      value &&
      value !== 0 &&
      !this.props.invalidInputText === true &&
      this.props.mode === cellMode.SELECT &&
      (this.isCellCurrencyDifferent() || !this.doesUserHasFullOwnership());
    const cellCurrencyValue =
      showCellCurrencyValue === false
        ? ""
        : currency !== "PVST"
        ? formatNumberWithCurrency(value, currency, false, 4, false, false, true, true, true)
        : formatNumberWithCurrency(value, currency, false, 4, false, false, true, true, true).replace(/PVST\s?/, "#");
    const inputProps = {
      other: other,
      className: className,
      type: "text",
      value: displayString === null || displayString === undefined ? "" : displayString,
      onChange: this.handleInputChange,
      onFocus: this.props.onFocus,
      onBlur: this.handleBlur,
      spellCheck: "false",
      onCopy: this.handleCopy
    };

    return (
      <Container>
        <CurrencyContainer order={this.props.order}>
          <CurrencyCellUpdateHandler
            className={className}
            value={displayString === null || displayString === undefined ? "" : displayString}
            isEditing={[cellMode.EDIT].includes(this.props.mode)}
            inputProps={inputProps}
            isEditable={isEditable}
          />
          {showCellCurrencyValue === true && (
            <CellCurrencyValue updated={this.props.updated} textAlignment={textAlignment} hasError={hasError}>
              &lrm;
              {cellCurrencyValue}
            </CellCurrencyValue>
          )}
          {!change === false && this.props.mode === cellMode.SELECT && (
            <ChangeIndicator isDebt={this.props.isDebt} change={change} showingSubText={showCellCurrencyValue} />
          )}
        </CurrencyContainer>
        {accessoryView}
      </Container>
    );
  }
}

export default CurrencyCell;
