import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import TextInput from "components/inputs/TextInput";

const TextDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Input = styled(TextInput)`
  height: 24px;
  margin-top: 12px;
  padding: 12px 20px 12px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 25px;
`;

class TextInputDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: props.data ? props.data.value : null
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleTextInputChange(e) {
    this.setState({ input: e.target.value });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveClick();
    }
  };

  handleSaveClick(e) {
    this.props.onVariableUpdate({ value: this.state.input });
    this.props.onDismiss();
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <TextDialog>
          <Container>
            <Title>{this.props.title}</Title>
            <Input
              placeholder={this.props.title}
              autoFocus={true}
              value={this.state.input}
              onChange={this.handleTextInputChange}
              onKeyDown={this.handleKeyDown}
            />
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </TextDialog>
      </DialogOverlay>
    );
  }
}

export default TextInputDialog;
