import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  currentPortfolioSelector,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  formatNumberWithCurrency,
  accountLinkingService,
  getUuid,
  insertCustodianAtEndOfSection,
  updateCustodian,
  insertSheet,
  utilityStatus,
  getAccountLinkingService,
  getTickerUsingShortName,
  getExchangeRate,
  getTickerUsingId,
  getExchangeRateDetails,
  tickerSubTypes,
  getSortKeyBetween,
  useHistory
} from "@kubera/common";

import { getEmptySheet, filterDataFromPortfolioDetails } from "components/grid/GridDataModel";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import { category } from "components/dashboard/DashboardComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

import EditableTicker from "./EditableTicker";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const TickersForm = styled.form`
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SearchMoreTxt = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  margin-top: 2px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
`;

const CarsManualComponent = props => {
  const portfolio = useSelector(currentPortfolioSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const isAddressModified = useRef(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // eslint-disable-line
  const [selectedTickers, setSelectedTickers] = useState([
    {
      address: "",
      id: getUuid()
    }
  ]);
  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [tickersWithNoValue, setTickersWithNoValue] = useState([]);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const removeNoValTickerFromIndex = index => {
    const tempTickersWithNoValue = [...tickersWithNoValue];
    const matchingTickerIndex = tempTickersWithNoValue.findIndex(
      ticker => ticker.placeId === selectedTickers[index].placeId
    );
    tempTickersWithNoValue.splice(matchingTickerIndex, 1);
    setTickersWithNoValue(tempTickersWithNoValue);
  };

  const removeTickerFromIndex = index => {
    const tempSelectedTickers = [...selectedTickers];

    if (!tempSelectedTickers[index].tickerBalanceFound) {
      removeNoValTickerFromIndex(index);
    }

    tempSelectedTickers.splice(index, 1);
    setSelectedTickers(tempSelectedTickers);
  };

  const trackAddressChange = value => {
    isAddressModified.current = !!value;
  };

  const onRenderSelectedTicker = (actualTicker, index) => {
    const ticker = actualTicker;
    return (
      <EditableTicker
        key={index}
        ticker={ticker}
        index={index}
        portfolio={portfolio}
        removeTickerFromIndex={removeTickerFromIndex}
        onTickerQuantityChange={onTickerQuantityChange}
        onMortgageSelect={onMortgageSelect}
        isEditMode
        placeholder={i18n.t("linkAccount.carsEditableTickerPlaceholder")}
        removeNoValTickerFromIndex={removeNoValTickerFromIndex}
        onAddressChange={trackAddressChange}
        isCar={true}
      />
    );
  };

  const onTickerSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitted(true);
    let custodianId = (props.nextValues && props.nextValues.custodianId) || currentCustodian.id;
    let sortKey = props.nextValues && props.nextValues.sortKey;
    let nextValues = void 0;

    if (formRef.current.checkValidity()) {
      selectedTickers.forEach(async ticker => {
        const { address = "" } = ticker;

        let relatedId = null;
        const tickerInputvalue = ticker.inputVal ? parseFloat(ticker.inputVal.replace(/[^\d.]/g, ""), 10) : 0;
        if (!ticker.relatedId && tickerInputvalue) {
          const debtSheets = filterDataFromPortfolioDetails(
            portfolio.details,
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.category === category.DEBT
          );

          let debtLoanSection = filterDataFromPortfolioDetails(
            {
              sheet: debtSheets.sheets,
              section: debtSheets.sections,
              custodian: debtSheets.rows
            },
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.name === "Loans"
          );

          if (debtLoanSection.sheets.length === 0) {
            const secondItemSortKey = getSortKeyBetween(
              debtSheets.sheets[0] ? debtSheets.sheets[0].sortKey : null,
              debtSheets.sheets[1] ? debtSheets.sheets[1].sortKey : null
            );
            const newSheet = getEmptySheet("Loans", secondItemSortKey, portfolio.currency, []);
            await dispatch(insertSheet(category.DEBT, newSheet));

            debtLoanSection = filterDataFromPortfolioDetails(
              portfolio.details,
              row => !row.name && !row.value,
              void 0,
              sheet => sheet.category === category.DEBT && sheet.name === "Loans"
            );
          }

          relatedId = (debtLoanSection.rows || []).length > 0 ? debtLoanSection.rows[0].id : getUuid();
          dispatch(insertCustodianAtEndOfSection(portfolio.id, debtLoanSection.sections[0].id, relatedId));

          const tickerId = getTickerUsingShortName(portfolio.currency).id;
          const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;
          dispatch(
            updateCustodian(
              true,
              relatedId,
              {
                name: i18n.t("loan.debtTitle").replace("%s%", address),
                value: tickerInputvalue,
                valueTickerId: ticker.tickerDetails?.tickerId || tickerId,
                isCompleted: 1,
                valueExchangeRate:
                  ticker.tickerDetails?.exchangeRate ||
                  getExchangeRateDetails(
                    tickerId,
                    getExchangeRate(getTickerUsingId(tickerId || portfolioValueTickerId).shortName, portfolio.currency)
                  ),
                past: 0
              },
              true
            )
          );
        }

        const newCustodian = {
          linkType: 0,
          name: address,
          value: ticker.balance ? ticker.balance : 0,
          valueTickerId: ticker.tickerId
            ? ticker.tickerId
            : ticker.accountName
            ? 150
            : getTickerUsingShortName(portfolio.currency).id,
          valueExchangeRate: getExchangeRateDetails(
            ticker.tickerId,
            getExchangeRate(
              getTickerUsingId(ticker.tickerId || getTickerUsingShortName(portfolio.currency).id).shortName,
              portfolio.currency
            )
          ),
          linkProviderAccountId: ticker.providerAccountId || null,
          linkAccountId: ticker.id || null,
          linkAccountMask: "",
          linkAccountName: ticker.accountName || null,
          linkAccountContainer: ticker.accountName ? "other" : null,
          linkProviderName: ticker.providerName || null,
          linkProviderId: ticker.providerName || null,
          relatedId: ticker.relatedId || relatedId,
          subType: tickerSubTypes.CARS,
          type: 1
        };
        nextValues = dispatch(
          insertTickerCustodianInSection(
            portfolio.id,
            currentCustodian.sectionId,
            custodianId,
            newCustodian,
            sortKey,
            custodian => {
              if (!custodian.id) {
                dispatch(
                  utilityStatus({
                    errorMessage: "Failed to link custodian",
                    ...newCustodian,
                    linkType: getAccountLinkingService(ticker.accountName ? accountLinkingService.CARS : 0)
                  })
                );
              }
            }
          )
        );

        custodianId = nextValues.custodianId;
        sortKey = nextValues.sortKey;
      });

      if (e) {
        DialogOverlay.forceDismiss(history, location);
      } else {
        props.setNextValues(nextValues);
      }
    } else {
      setErrorMessage(i18n.t(" "));
      props.onConfirmCancel();
    }
  };

  const onAddMoreClick = () => {
    setSelectedTickers([
      ...selectedTickers,
      {
        address: "",
        id: getUuid()
      }
    ]);
  };

  const saveConfirmModal = () => {
    onTickerSubmit();

    if (formRef.current && formRef.current.checkValidity()) {
      props.onConfirmSave();
    }
    setIsConfirmModalVisible(false);
  };

  const closeConfirmModal = () => {
    props.onConfirmCancel();
    setIsConfirmModalVisible(false);
  };

  const getRenderText = (item, ticker) => {
    const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;

    const debtLinkedRow = item || {
      value: 0,
      valueTickerId: portfolioValueTickerId
    };

    const debtExchangeRate = getExchangeRate(
      getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
      ticker.currency
    );

    return `${formatNumberWithCurrency(item.value * debtExchangeRate, ticker.currency)} (${item.name})`;
  };

  const onMortgageSelect = (item, index, ticker) => {
    if (!item) {
      return;
    }

    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = getRenderText(item, ticker);
    tempSelectedTickers[index].relatedId = item.id;
    setSelectedTickers(tempSelectedTickers);
  };

  const onTickerQuantityChange = (value, index, tickerDetails) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    tempSelectedTickers[index].relatedId = null;
    tempSelectedTickers[index].tickerDetails = tickerDetails;
    setSelectedTickers(tempSelectedTickers);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  const { carsSelectedTab, carsClickedTab, clickedTab, onConfirmSave } = props;
  useEffect(() => {
    if (
      (clickedTab !== 1 || carsSelectedTab !== carsClickedTab) &&
      isAddressModified.current &&
      formRef.current &&
      (selectedTickers && selectedTickers.length > 0)
    ) {
      setIsConfirmModalVisible(true);
    } else {
      onConfirmSave();
    }
  }, [clickedTab, carsSelectedTab, carsClickedTab, onConfirmSave, selectedTickers]);

  if (currentCustodian) {
    return (
      <Container className={props.className}>
        <TickersForm
          ref={formRef}
          onSubmit={onTickerSubmit}
          noValidate
          data-exitconfirm={i18n.t("linkAccount.modalCarsTitle")}
        >
          {selectedTickers.map(onRenderSelectedTicker)}
          <SearchMoreTxt onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</SearchMoreTxt>
          <TickerFormFooter>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} />
          </TickerFormFooter>
        </TickersForm>
        {isConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("linkAccount.saveAddTitle").replace("%s%", i18n.t("linkAccount.modalCarsTitle"))}
            description={i18n.t("linkAccount.saveStocksAddDesc").replace("%s%", i18n.t("linkAccount.modalCarsTitle"))}
            positiveButtonTitle={i18n.t("save")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={closeConfirmModal}
            handlePositiveButtonClick={saveConfirmModal}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default CarsManualComponent;
