import React from "react";
import InitialLoader from "components/loader/InitialLoader";
import { queryParams } from "routes";
import { getQueryParams, isMobile } from "@kubera/common";
import LinkAccountProviderPageComponentExports from "components/link_account/LinkAccountProviderPageComponentExports";

const isMobileDevice = isMobile();

class RedirectHopComponent extends React.Component {
  constructor(props) {
    super(props);

    const params = getQueryParams(window.location);
    const redirectUrl = params[queryParams.REDIRECT_URL];
    const callbackDomain = params[queryParams.CALLBACK_DOMAIN];

    console.log(redirectUrl);
    console.log(callbackDomain);

    LinkAccountProviderPageComponentExports.setRedirectDomain(callbackDomain);
    window.location.href = redirectUrl;
  }

  render() {
    return <InitialLoader hideLogo={!isMobileDevice} />;
  }
}

export default RedirectHopComponent;
