import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/browser";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GAWrapperInit } from "utilities/GAWrapper";
import "array-flat-polyfill";
import structuredClone from "@ungap/structured-clone";
import supportedBrowsers from "utilities/supportedBrowsers";
import BrowserNotSupported from "./BrowserNotSupported";
import "./providersLoadHandler";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.kbStructuredClone = value => {
  return !("structuredClone" in window) ? structuredClone(value) : window.structuredClone(value);
};

if (process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      "WrappedError",
      "callback",
      "Non-Error exception captured with keys: errorCode, errorMessage, extra",
      "Breaking change error",
      "API_ERROR_-10002",
      "API_ERROR_-10001",
      "API_ERROR_1002"
    ]
  });
}

GAWrapperInit();
if (supportedBrowsers.test(navigator.userAgent)) {
  root.render(<App />);
} else {
  root.render(<BrowserNotSupported />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
