import React, { Fragment } from "react";
import i18n from "i18next";
import styled from "styled-components";

import { ReactComponent as OpenLink } from "assets/images/open_link.svg";

const Container = styled.div`
  padding: 20px 0 0;
`;

const ImgContainer = styled.div`
  min-height: 300px;
  margin-bottom: 50px;
  image-rendering: pixelated;
`;

const ImgBlk = styled.img`
  max-height: 300px;
  width: 100%;
  object-fit: contain;
  object-position: left;
`;

const DescContainer = styled.div`
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  margin-bottom: 2px;
`;

const DescRow = styled.div`
  display: flex;
`;

const DescLabel = styled.div`
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
`;

const DescValue = styled.div`
  display: inline-block;
  margin-left: 5px;
  word-break: break-all;
`;

const NFTLink = styled.a`
  font-size: 14px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
`;

const OpenLinkComponent = styled(OpenLink)`
  margin-left: 4px;
`;

const NFTComponent = ({ isLoading = true, detailsInfo = {} }) => {
  const extra = JSON.parse(detailsInfo.extra);
  const defaultImg = "https://cdn.kubera.com/r/clips/NFT.png";

  if (extra) {
    return (
      <Container>
        <ImgContainer>
          {/^video\//.test(extra.urlType) && extra.url ? (
            <video autoPlay muted loop playsInline height="300px" src={extra.url} />
          ) : (
            <ImgBlk src={extra.url || defaultImg} />
          )}
        </ImgContainer>
        <DescContainer>
          {extra.name && (
            <DescRow>
              <DescLabel>{i18n.t("nftComponent.item")}</DescLabel>
              <DescValue>{extra.name}</DescValue>
            </DescRow>
          )}
          <DescRow>
            <DescLabel>{i18n.t("nftComponent.count")}</DescLabel>
            <DescValue>{extra.count || 1}</DescValue>
          </DescRow>
          <DescRow>
            <DescLabel>{i18n.t("nftComponent.collection")}</DescLabel>
            <DescValue>{detailsInfo.description}</DescValue>
          </DescRow>
          <DescRow>
            <DescLabel>{i18n.t("nftComponent.contract")}</DescLabel>
            <DescValue>{extra.contractAddress}</DescValue>
          </DescRow>
          <DescRow>
            <DescLabel>{i18n.t("nftComponent.tokenId")}</DescLabel>
            <DescValue>{extra.tokenId}</DescValue>
          </DescRow>
        </DescContainer>
        {extra.openseaUrl && (
          <Fragment>
            <NFTLink href={extra.openseaUrl} target="_blank" rel="noopener noreferrer">
              {i18n.t("nftComponent.openseaLink")}
            </NFTLink>
            <NFTLink href={extra.openseaUrl} target="_blank" rel="noopener noreferrer">
              <OpenLinkComponent />
            </NFTLink>
          </Fragment>
        )}
        {extra.moreUrl && (
          <Fragment>
            <NFTLink href={extra.moreUrl} target="_blank" rel="noopener noreferrer">
              {i18n.t("nftComponent.moreLink")}
            </NFTLink>
            <NFTLink href={extra.moreUrl} target="_blank" rel="noopener noreferrer">
              <OpenLinkComponent />
            </NFTLink>
          </Fragment>
        )}
      </Container>
    );
  }

  return null;
};

export default NFTComponent;
