import React from "react";
import styled from "styled-components";
import ScrollCounter from "components/scroll_counter/ScrollCounter";
import {
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  userMaskAllValuesSelector,
  store
} from "@kubera/common";

const Container = styled.div`
  display: flex;
  color: ${props => props.theme.gridTitleColor};
`;

const Currency = styled.div`
  font-style: normal;
  font-size: ${props => props.fontSize + "px"};
  font-weight: ${props => props.fontWeight};
  margin-top: ${props => (props.currencyMarginTop ? props.currencyMarginTop + "px" : "4px")};
  margin-right: 3px;
`;

const Value = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight};
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on, "calt" off;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : null)};
`;

const NegativeSymbol = styled(Value)`
  font-size: ${props => `${props.fontSize}px`};
  font-weight: ${props => `${props.fontWeight}px`};
`;

const ValueAdjustSpace = styled.div`
  font-size: ${props => props.fontSize + "px"};
  letter-spacing: ${props => props.letterSpacing};
  margin-bottom: 3px;
`;

const AnimationCounter = styled(ScrollCounter)`
  letter-spacing: ${props => props.letterSpacing};
`;

const CurrencyHeaderLabel = ({
  className,
  value,
  currency,
  currencyFontSize,
  valueFontSize,
  valueMaxWidth,
  showZero,
  defaultValueString,
  abbreviate,
  height = "44px",
  isAnimationAllowed = true,
  preventAnimation = false,
  fontWeight = 600,
  onClick = null,
  showZeroAsText = false,
  currencyMarginTop,
  letterSpacing = "-0.015em"
}) => {
  const getValueString = (value, currency) => {
    if (value === 0 && !showZero === true && !showZeroAsText === true) {
      return defaultValueString;
    }
    const formattedText = `${shortFormatNumberWithCurrency(
      value,
      currency,
      !abbreviate === false,
      undefined,
      undefined,
      undefined,
      showZeroAsText
    )}`;
    return formattedText.replace("-", "");
  };

  const valueString = getValueString(value, currency);
  return (
    <Container className={className} onClick={onClick}>
      {(value !== 0 || !showZero === false || (!showZeroAsText === false && valueString !== "Zero")) && (
        <Currency fontSize={currencyFontSize} fontWeight={fontWeight} currencyMarginTop={currencyMarginTop}>
          {getSymbolForTickerUsingShortName(currency)}
        </Currency>
      )}
      {value < 0 && userMaskAllValuesSelector(store.getState()) === false && (
        <NegativeSymbol fontSize={valueFontSize} fontWeight={fontWeight} minWidth="9">
          {"-"}
        </NegativeSymbol>
      )}
      <Value maxWidth={valueMaxWidth} fontWeight={fontWeight}>
        {!isAnimationAllowed ? (
          <ValueAdjustSpace fontSize={valueFontSize} height={height} letterSpacing={letterSpacing}>
            {valueString}
          </ValueAdjustSpace>
        ) : (
          <AnimationCounter
            displayValue={valueString}
            fontSize={valueFontSize}
            height={height}
            preventAnimation={preventAnimation}
            letterSpacing={letterSpacing}
          />
        )}
      </Value>
    </Container>
  );
};

export default CurrencyHeaderLabel;
