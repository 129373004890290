import React from "react";
import styled from "styled-components";
import DocumentCell, { documentCategories } from "components/documents/DocumentCell";
import i18n from "i18next";
import DragDropInput from "components/inputs/DragDropInput";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";

const DefaultMaxItemsPerRow = 5;

const DefaultDocumentCellSize = 150;

export const gridType = {
  NORMAL: "NORMAL"
};

const Container = styled(DragDropInput)`
  position: relative;
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-wrap;
  margin-top: ${props => (props.compactMode === true ? `${144}px` : `${290}px`)};
`;

const DocumentsGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: ${props => (props.compactMode === true ? `${320}px` : `${580}px`)};
`;

const DocumentsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 ${props => (props.compactMode === true ? "-5px" : "-7px")};
  margin-bottom: ${props => (props.compactMode === true ? "-15px" : "-32px")};
`;

const DocumentGridCell = styled(DocumentCell)`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  min-width: ${props => `${props.size}px`};
  max-width: ${props => `${props.size}px`};
  text-align: center;
  margin: 0 ${props => (props.compactMode === true ? "6px" : "8px")};
  margin-bottom: ${props => (props.compactMode === true ? "15px" : "32px")};
  border: ${props =>
    props.isSelected === true
      ? `2px solid ${props => props.theme.documentCellSelectedOutlineColor}`
      : "2px solid transparent"};
`;

const EmptyGridCell = styled.div`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  margin: 0 ${props => (props.compactMode === true ? "6px" : "8px")};
  margin-bottom: ${props => (props.compactMode === true ? "15px" : "32px")};
`;

class DocumentsGridComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDragging: false, selectedDocuments: [], selectedDocumentNodes: [] };
    this.contextMenuRef = React.createRef();

    this.handlDragStart = this.handlDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleDocumentSelection = this.handleDocumentSelection.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
    document.addEventListener("contextmenu", this.handleRightClick, false);

    this.preloadIcons();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
    document.removeEventListener("contextmenu", this.handleRightClick, false);
  }

  preloadIcons() {
    for (const category of documentCategories) {
      const img = new Image();
      const url = this.props.compactMode === true ? category.compact_icon_url : category.icon_url;
      if (url) {
        img.src = url;
      }
    }
  }

  handleOutsideClick(e) {
    const documentClickedIndex = this.state.selectedDocumentNodes.findIndex(node => node.contains(e.target));

    if (documentClickedIndex === -1) {
      this.setState({ ...this.state, selectedDocuments: [], selectedDocumentNodes: [] });
    }
  }

  getContextMenuItems() {
    if (this.props.isReadOnly === true) {
      return [[contextMenuItemType.DOWNLOAD]];
    }
    if (this.state.selectedDocuments.length > 1) {
      return [[contextMenuItemType.DOWNLOAD]];
    }
    return [[contextMenuItemType.REMOVE, contextMenuItemType.DOWNLOAD]];
  }

  handleRightClick(e) {
    if (this.state.selectedDocuments.length === 0) {
      return;
    }

    e.preventDefault();
    this.contextMenuRef.current.show(this.getContextMenuItems(), e.clientX, e.clientY, true, null);
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.REMOVE.id) {
      if (this.props.onDeleteDocuments) {
        this.props.onDeleteDocuments(this.state.selectedDocuments);
      }
    } else if (item.id === contextMenuItemType.DOWNLOAD.id) {
    }
    this.setState({ ...this.state, selectedDocuments: [], selectedDocumentNodes: [] });
  }

  handlDragStart() {
    this.setState({ ...this.state, isDragging: true });
  }

  handleDragStop() {
    this.setState({ ...this.state, isDragging: false });
  }

  handleFileDrop(files) {
    if (files.length > 0 && this.props.onFileUpload) {
      this.props.onFileUpload(files);
    }
  }

  handleDocumentSelection(e, doc) {
    if (e.shiftKey === true || e.metaKey === true || e.ctrlKey === true) {
      e.nativeEvent.stopImmediatePropagation();

      const documentIndex = this.state.selectedDocuments.findIndex(selectedDocument => selectedDocument.id === doc.id);
      const documentNodeIndex = this.state.selectedDocumentNodes.findIndex(node => node.contains(e.target));

      var newDocuments = this.state.selectedDocuments;
      var newDocumentNodes = this.state.selectedDocumentNodes;
      if (documentIndex === -1) {
        newDocuments.push(doc);
        newDocumentNodes.push(e.target);
      } else {
        newDocuments.splice(documentIndex, 1);
        newDocumentNodes.splice(documentNodeIndex, 1);
      }
      this.setState({ ...this.state, selectedDocuments: newDocuments });
    }
  }

  getDocumentRows() {
    if (this.props.documents === null) {
      return null;
    }

    const maxItemsPerRow = this.props.maxItemsPerRow ? this.props.maxItemsPerRow : DefaultMaxItemsPerRow;

    const docLength = this.props.documents.length;
    const remTobeAMultipleOfFive = Math.ceil(docLength / maxItemsPerRow) * maxItemsPerRow - docLength;

    const remArray = Array(remTobeAMultipleOfFive).fill(null);
    return [...this.props.documents, ...remArray];
  }

  getDocumentCellSize() {
    if (this.props.documentCellSize) {
      return this.props.documentCellSize;
    }
    return DefaultDocumentCellSize;
  }

  isFolder(document) {
    return document.fileType === "folder" || document.fileType === "custodian";
  }

  render() {
    if (this.props.documents === null) {
      return null;
    }

    const documents = this.getDocumentRows();
    const isDragging = this.state.isDragging === true;
    const cellSize = this.getDocumentCellSize();
    const isInMultiSelectMode = this.state.selectedDocuments.length > 0;

    return (
      <Container
        className={this.props.className}
        onDragStart={this.handlDragStart}
        onDragStop={this.handleDragStop}
        onFileDrop={this.handleFileDrop}
        disabled={this.props.isReadOnly === true}
      >
        <DocumentsGrid compactMode={this.props.compactMode}>
          <DocumentsRow compactMode={this.props.compactMode}>
            {documents.map((doc, index) => {
              if (doc === null) {
                return (
                  <EmptyGridCell key={index} size={this.props.documentCellSize} compactMode={this.props.compactMode} />
                );
              } else {
                const isSelected =
                  this.state.selectedDocuments.findIndex(selectedDocument => selectedDocument.id === doc.id) !== -1;

                return (
                  <DocumentGridCell
                    onDocumentSelection={this.handleDocumentSelection}
                    key={doc.id}
                    size={cellSize}
                    compactMode={this.props.compactMode}
                    isSelected={isSelected}
                    isReadOnly={this.props.isReadOnly}
                    disableOptions={
                      isInMultiSelectMode === true ||
                      doc.isUploading === true ||
                      (this.isFolder(doc) === true && this.props.isWlkReadOnly === true)
                    }
                    document={doc}
                    onDocumentRename={this.props.onDocumentRename}
                    onDeleteDocuments={this.props.onDeleteDocuments}
                    onDownloadDocuments={this.props.onDownloadDocuments}
                    onRetryDocumentUpload={this.props.onRetryDocumentUpload}
                    maskThumbnail={this.props.maskThumbnail}
                    handleFolderClick={this.props.handleFolderClick}
                  />
                );
              }
            })}
          </DocumentsRow>
        </DocumentsGrid>
        <DropAreaIndicator isDragging={isDragging}>
          <DropAreaHint compactMode={this.props.compactMode}>{i18n.t("documentsComponent.dropFileHint")}</DropAreaHint>
        </DropAreaIndicator>
        <ContextMenu ref={this.contextMenuRef} onSelection={this.handleContextMenuSelection} />
      </Container>
    );
  }
}

export default DocumentsGridComponent;
