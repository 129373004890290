import React from "react";
import styled, { keyframes } from "styled-components";
import { useDarkModeActive } from "theme";

const defaultThickness = 4;

const spinningAnimation = keyframes`
  0% {
    transform: rotate(0); 
  }
  100% {
    transform: rotate(360deg); 
  } `;

const SpinIt = styled.div`
  border: ${props =>
    props.darkTheme === true
      ? `${props.thickness}px solid ${props.theme.spinnerBR || "rgb(229,229,229)"}`
      : `${props.thickness}px solid ${props.theme.spinnerBR || "rgb(157,157,157)"}`};
  border-top-color: ${props => props.theme.spinnerBRTop};
  border-radius: 50%;
  animation: ${spinningAnimation} 1s linear infinite;
  overflow: hidden;
`;

const HorizontalLoader = styled.div`
  background: ${props => props.theme.horizontalLoaderBG};
  width: 120px;
  overflow: hidden;
`;

const horizontalProgressBar = keyframes`
    0%   {transform: scaleX(0);}
    1%  {transform: scaleX(0.7);}
    3%  {transform: scaleX(0.9);}
    7%  {transform: scaleX(0.93);}
    8%  {transform: scaleX(0.95);}
    100% {transform: scaleX(1);}
`;

const ProgressBar = styled.div`
  animation: ${horizontalProgressBar} 240s ease;
  transform-origin: 0%;
  background: ${props => props.theme.spinnerCLR};
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  z-index: 9999;
  position: relative;

  &::after {
    display: block;
    position: absolute;
    content: "";
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: #000 1px 0 6px 1px;
    opacity: 0.5;
  }
`;

class Spinner extends React.Component {
  render() {
    if (this.props.isHorizontalLoader) {
      return (
        <HorizontalLoader>
          <ProgressBar />
        </HorizontalLoader>
      );
    }

    const darkTheme = this.props.darkTheme;
    const thickness = this.props.thickness || defaultThickness;

    return (
      <SpinIt data-cy="spinIt" className={this.props.className} darkTheme={darkTheme === true} thickness={thickness} />
    );
  }
}

const SpinnerWrapper = ({ darkTheme, ...otherProps }) => {
  const isDarkThemeActive = useDarkModeActive();
  return <Spinner {...otherProps} darkTheme={darkTheme ? darkTheme : isDarkThemeActive === false} />;
};

export default SpinnerWrapper;
