import React, { useRef, useState } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { accountSubscriptionIsActiveSelector } from "@kubera/common";
import { modalValues } from "routes";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";
import SingleComponentOptions from "components/link_account/SingleComponentOptions";
import ConnectComponentOptions from "components/link_account/ConnectComponentOptions";
import PrimaryButton from "components/button/PrimaryButton";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const LinkDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const PayWall = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
`;

const PayWallContent = styled.div`
  margin-top: 141px;
  width: 378px;
  padding: 6px;
`;

const PayWallTxt = styled.div`
  background: #fff;
`;

const PayWallTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 5px;
`;

const PayWallDesc = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 60px 50px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
`;

const MoreDetailsBtn = styled(PrimaryButton)`
  width: 150px;
  height: 43px;
`;

const ConnectComponentModal = props => {
  const containerRef = useRef();

  const accountSubscriptionIsActive = useSelector(accountSubscriptionIsActiveSelector);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState("");
  const tab = props.modalValue === modalValues.CONNECT_METAL ? 1 : void 0;

  const dismissOverlay = () => {
    DialogOverlay.dismiss(props.history, props.location);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalVisible("");
  };

  const handleOverlayDismiss = () => {
    const exitMatchSelector = containerRef.current && containerRef.current.querySelector(`[data-exitconfirm]`);
    const exitMatchValue = exitMatchSelector && exitMatchSelector.getAttribute("data-exitconfirm");
    if (exitMatchValue) {
      setIsConfirmModalVisible(exitMatchValue);
    } else {
      dismissOverlay();
    }
  };

  const showSubscriptionModal = () => {
    AccountSettingsComponentExports.show(props.history, props.location, accountSettingsTabs.SUBSCRIPTION);
  };

  return (
    <DialogOverlay onDismiss={handleOverlayDismiss}>
      <LinkDialog>
        {!accountSubscriptionIsActive && (
          <PayWall>
            <PayWallContent>
              <PayWallTxt>
                <PayWallTitle>{i18n.t("linkAccount.paywallTitle")}</PayWallTitle>
                <PayWallDesc>{i18n.t("linkAccount.paywallDesc")}</PayWallDesc>
              </PayWallTxt>
              <MoreDetailsBtn title="More Details" data-cy="moreDetailsButton" onClick={showSubscriptionModal} />
            </PayWallContent>
          </PayWall>
        )}
        <Container ref={containerRef}>
          {[modalValues.CONNECT_STOCK, modalValues.CONNECT_METAL].includes(props.modalValue) ? (
            <ConnectComponentOptions custodianId={props.custodianId} tab={tab} />
          ) : (
            <SingleComponentOptions custodianId={props.custodianId} modalValue={props.modalValue} tab={tab} />
          )}
        </Container>
      </LinkDialog>
      {isConfirmModalVisible && (
        <ConfirmationDialog
          title={i18n.t("linkAccount.confirmAddTitle").replace("%s%", isConfirmModalVisible)}
          description={i18n.t("linkAccount.confirmStocksAddDesc")}
          positiveButtonTitle={i18n.t("stay")}
          negativeButtonTitle={i18n.t("abort")}
          handleNegativeButtonClick={dismissOverlay}
          handlePositiveButtonClick={closeConfirmModal}
        />
      )}
    </DialogOverlay>
  );
};

export default withRouter(ConnectComponentModal);
