import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import Checkbox from "components/inputs/Checkbox";
import { portfoliosSelector, updatePortfolio } from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import ClickableLink from "components/labels/DelayLink";
import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import RadioButton from "components/inputs/RadioButton";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding: 20px 20px 75px;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HistoryBackContainer = styled(ClickableLink)`
  padding: 20px;
  margin-left: -20px;
  margin-right: -10px;
  border-radius: 50%;
  margin-bottom: 0;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 15px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 10px;
  margin-bottom: 2px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const SectionContentContainer = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
`;

const ShowAssetChangeCheckbox = styled(Checkbox)`
  padding: 20px 15px 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const MinChangeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const MinChangeLabel = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  margin-right: 15px;
  color: #000000;
`;

const MinChangeButton = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const ConnectionErrorCheckbox = styled(Checkbox)`
  padding: 20px 15px 20px 15px;
`;

const MinChangeOverlay = styled(DialogOverlay)`
  & #kubera-container-overlay {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 30px;
  }
`;

const MinChangeDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  padding: 40px 30px;
  overflow: auto;
  max-width: 350px;
`;

const MinChangeTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: #000000;
  white-space: pre-wrap;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const MinChangeRadioButton = styled(RadioButton)`
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: "ss01" on;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
`;

class MobileAccountNotificationsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMinChangeDialogForPortfolio: null };

    this.handleDayChangeAssetsCheckboxChange = this.handleDayChangeAssetsCheckboxChange.bind(this);
    this.handleDayChangeLimitEnabledCheckboxChange = this.handleDayChangeLimitEnabledCheckboxChange.bind(this);
    this.handleConnectionErrorsCheckbox = this.handleConnectionErrorsCheckbox.bind(this);
    this.handleMinChangeClick = this.handleMinChangeClick.bind(this);
    this.dismissMinChangeOverlay = this.dismissMinChangeOverlay.bind(this);
  }

  handleDayChangeAssetsCheckboxChange(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.dayChangeAssets = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  handleConnectionErrorsCheckbox(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.connectionErrors = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  handleDayChangeLimitEnabledCheckboxChange(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.dayChangeLimitEnabled = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  getMinChangeRadioButtonOptions() {
    const notificationSettings = this.getNotificationSettings(this.state.showMinChangeDialogForPortfolio);
    const currentDayChangeLimit = notificationSettings.dayChangeLimitEnabled
      ? notificationSettings.dayChangeLimit
      : "off";

    const options = [
      { value: "off", label: "off", selected: currentDayChangeLimit === "off" },
      { value: "0.5", label: "0.5%", selected: currentDayChangeLimit === 0.5 },
      { value: "1", label: "1%", selected: currentDayChangeLimit === 1 },
      { value: "1.5", label: "1.5%", selected: currentDayChangeLimit === 1.5 },
      { value: "2", label: "2%", selected: currentDayChangeLimit === 2 },
      { value: "5", label: "5%", selected: currentDayChangeLimit === 5 },
      { value: "10", label: "10%", selected: currentDayChangeLimit === 10 },
      { value: "25", label: "25%", selected: currentDayChangeLimit === 25 },
      { value: "50", label: "50%", selected: currentDayChangeLimit === 50 }
    ];
    return options;
  }

  handleMinChangeClick(e, portfolio) {
    this.setState({ showMinChangeDialogForPortfolio: portfolio });
  }

  handleMinChangeSelection(value) {
    const portfolio = this.state.showMinChangeDialogForPortfolio;
    const settings = this.getNotificationSettings(portfolio);
    if (value === "off") {
      settings.dayChangeLimitEnabled = false;
    } else {
      settings.dayChangeLimitEnabled = true;
      settings.dayChangeLimit = parseFloat(value);
    }
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);

    this.setState({ showMinChangeDialogForPortfolio: null });
  }

  dismissMinChangeOverlay() {
    this.setState({ showMinChangeDialogForPortfolio: null });
  }

  getNotificationSettings(portfolio) {
    const defaultSettings = {
      dayChangeAssets: true,
      dayChangeLimitEnabled: false,
      dayChangeLimit: 0.5,
      connectionErrors: true
    };

    try {
      const settings = JSON.parse(portfolio.notificationOption);
      if (!settings === true) {
        return defaultSettings;
      }
      return settings;
    } catch (e) {
      return defaultSettings;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <Container className={this.props.className}>
        <ContainerDialog>
          <HeaderContainer>
            <HistoryBackContainer onClick={e => this.goBack()} direction="right">
              <HistoryBackBtn />
            </HistoryBackContainer>
            <Title>{i18n.t("accountSettings.notificationsTabTitle")}</Title>
          </HeaderContainer>
          {this.props.portfolios?.map((portfolio, index) => {
            const notificationSettings = this.getNotificationSettings(portfolio);

            return (
              <Section key={index}>
                <SectionTitle>{portfolio.name}</SectionTitle>
                <SectionContentContainer>
                  <ShowAssetChangeCheckbox
                    label={i18n.t("accountSettings.dailyDigestChange")}
                    labelOnLeft={true}
                    checked={notificationSettings.dayChangeAssets}
                    onChange={isChecked => this.handleDayChangeAssetsCheckboxChange(isChecked, portfolio)}
                  />
                  <MinChangeContainer>
                    <MinChangeLabel onClick={e => this.handleMinChangeClick(e, portfolio)}>
                      {i18n.t("accountSettings.dailyDigestMinChange")}
                    </MinChangeLabel>
                    <MinChangeButton
                      onClick={e => this.handleMinChangeClick(e, portfolio)}
                      disabled={notificationSettings.dayChangeAssets === false}
                    >
                      {notificationSettings.dayChangeLimitEnabled
                        ? notificationSettings.dayChangeLimit + "%"
                        : i18n.t("off")}
                    </MinChangeButton>
                  </MinChangeContainer>
                  <ConnectionErrorCheckbox
                    label={i18n.t("accountSettings.dailyDigestConnectionError")}
                    labelOnLeft={true}
                    checked={notificationSettings.connectionErrors}
                    onChange={isChecked => this.handleConnectionErrorsCheckbox(isChecked, portfolio)}
                  />
                </SectionContentContainer>
              </Section>
            );
          })}
          {!this.state.showMinChangeDialogForPortfolio === false && (
            <MinChangeOverlay onDismiss={this.dismissMinChangeOverlay}>
              <MinChangeDialog>
                <MinChangeTitle>{i18n.t("accountSettings.dailyDigestMinChange")}</MinChangeTitle>
                <RadioButtonsContainer>
                  {this.getMinChangeRadioButtonOptions().map((option, index) => {
                    return (
                      <MinChangeRadioButton
                        key={index}
                        label={option.label}
                        value={option.value}
                        checked={option.selected}
                        labelOnLeft={true}
                        onChange={value => this.handleMinChangeSelection(value)}
                      />
                    );
                  })}
                </RadioButtonsContainer>
              </MinChangeDialog>
            </MinChangeOverlay>
          )}
        </ContainerDialog>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolios: portfoliosSelector(state)
});

const mapDispatchToProps = {
  updatePortfolio: updatePortfolio
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileAccountNotificationsComponent));
