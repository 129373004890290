import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import localforage from "localforage";
import debounce from "lodash.debounce";

import { Logger } from "../utilities/Logger";
import {
  persistTransforms,
  rootReducer,
  asyncReducers,
  asyncTransforms,
  createAppReducer
} from "../redux/reducers/RootReducer";
import { persistStore, persistReducer, getStoredState } from "redux-persist";
import { setStore, setPersistor, store, persistor } from ".";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { getTokenForViewMode, getSWConsts } from "../utilities/Common";
import {
  loadRecapDataFromLocalForage,
  loadPortfoliosDataFromLocalForage,
  getPortfoliosStorePortfolioSessionUserId,
  getPortfoliosDataStorageKey,
  LAST_FETCH_MAX_AGE
} from "../redux/actions/Common";

const clearViewPersistData = (persistKey, tokenForViewMode) => {
  try {
    let viewUrlData = JSON.parse(localStorage.getItem("viewUrlData"));

    if (tokenForViewMode && (viewUrlData === null || !viewUrlData[persistKey])) {
      viewUrlData = { ...viewUrlData, [persistKey]: new Date().getTime() };
    }

    for (let key in viewUrlData) {
      if (new Date().getTime() - viewUrlData[key] > 3600000) {
        delete viewUrlData[key];
        if (key.startsWith("portfolios")) {
          localforage.removeItem(key);
          continue;
        }
        localforage.removeItem(`persist:${key}`);
      }
    }
    localStorage.setItem("viewUrlData", JSON.stringify(viewUrlData));
  } catch (e) {
    console.log(e);
  }

  return;
};

export const setupStore = async storage => {
  const tokenForViewMode = getTokenForViewMode();
  const version = "v64" + (tokenForViewMode || "");
  const middlewares = [thunk];
  const persistKey = "cuberaroot" + version;
  const persistConfig = {
    key: persistKey,
    storage,
    transforms: persistTransforms(),
    stateReconciler: autoMergeLevel2
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  if (!Logger.reduxMiddleware() === true) {
    setStore(createStore(persistedReducer, applyMiddleware(...middlewares)));
  } else {
    setStore(createStore(persistedReducer, applyMiddleware(...middlewares, Logger.reduxMiddleware())));
  }

  setPersistor(persistStore(store, { manualPersist: true }));

  store.dispatch(loadRecapDataFromLocalForage());
  store.dispatch(loadPortfoliosDataFromLocalForage());
  clearViewPersistData(persistKey, tokenForViewMode);
  if (!tokenForViewMode) {
    const persistDate = localStorage.getItem(persistKey);
    const currentTime = new Date().getTime();

    // If local data is older than 2 days purge local data and persist new data
    if (!persistDate || currentTime - new Date(persistDate).getTime() > LAST_FETCH_MAX_AGE) {
      persistor.purge();
    }

    localStorage.setItem(persistKey, new Date().toISOString());
  }
  persistor.persist();

  const updatedPortfolioReducerTS = {
    portfolios: undefined
  };

  if (!tokenForViewMode) {
    const storePortfolioStateToStorage = debounce(() => {
      const state = store.getState();
      console.log("storePortfolioStateToStorage");
      for (let key in state) {
        if (key.startsWith("portfolios")) {
          const newStatePortfolioTs = state[key].currentPortfolioCustodiansUpdatedTimestamp;
          if (newStatePortfolioTs !== updatedPortfolioReducerTS[key]) {
            const portfolioStorageKey = getPortfoliosDataStorageKey(getPortfoliosStorePortfolioSessionUserId());
            updatedPortfolioReducerTS[key] = newStatePortfolioTs;

            let filteredPortfolioObj = state[key]["portfolios"];

            const SW_CONSTS = getSWConsts();
            clearViewPersistData(portfolioStorageKey, tokenForViewMode);
            navigator.serviceWorker?.controller?.postMessage({
              type: SW_CONSTS.STORE_PORTFOLIO_DATA,
              key: portfolioStorageKey,
              value: filteredPortfolioObj,
              SW_CONSTS
            });
          }
        }
      }
    }, 1000);
    store.subscribe(storePortfolioStateToStorage);
  }

  store.injectReducers = reducers => {
    reducers = reducers.filter(item => !asyncReducers[item.key] === true);

    if (reducers.length === 0) {
      return;
    }

    reducers.forEach(item => {
      console.log("store add reducer with key", item.key);

      asyncReducers[item.key] = item.reducer;
      updatedPortfolioReducerTS[item.key] = undefined;
      asyncTransforms.push(item.transform);
    });

    const persistConfig = {
      key: persistKey,
      storage,
      transforms: persistTransforms(),
      stateReconciler: autoMergeLevel2
    };

    createAppReducer();

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    store.replaceReducer(persistedReducer);
    console.log("store replaceReducer");
    persistor.persist();
  };

  store.getStoredState = async () => {
    const persistConfig = {
      key: persistKey,
      storage,
      transforms: persistTransforms(),
      stateReconciler: autoMergeLevel2
    };

    return await getStoredState(persistConfig);
  };
};
