import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import AppLogo from "components/labels/AppLogo";
import Loader from "components/loader/Loader";
import ClickableLink from "components/labels/ClickableLink";
import { withRouter } from "@kubera/common";
import { routes, websiteUrls, queryParams } from "routes";
import { getQueryParams, isUserSignedIn, signOut, deleteUserAccount } from "@kubera/common";
import { connect } from "react-redux";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 423px;
  height: fit-content;
`;

const Content = styled.div`
  display: flex;
  justify-content: ${props => (props.centerContent === true ? "center" : "flex-start")}
  height: 561px;
  flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
  padding: 50px;
  border: 1px solid #000000;
  box-sizing: border-box;
`;

const DeleteAccountLoader = styled(Loader)`
  height: fit-content;
`;

const DeletingAccountLabel = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const AccountDeletedTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const AccountDeletedDescription = styled.div`
  margin-top: 18px;
  margin-bottom: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const Link = styled(ClickableLink)`
  margin-top: 15px;
`;

class DeleteAccountConfirmationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isRequestPending: false, isAccountDeleted: false, errorMessage: null };

    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
  }

  componentDidMount() {
    this.deleteAccount();
  }

  deleteAccount() {
    this.setState({ ...this.state, isRequestPending: true, errorMessage: null });

    const token = getQueryParams(this.props.location)[queryParams.TOKEN];
    this.props.deleteUserAccount(
      token,
      () => {
        this.setState({ ...this.state, isRequestPending: false, errorMessage: null, isAccountDeleted: true });

        isUserSignedIn().then(status => {
          if (status === true) {
            signOut(false);
          }
        });
      },
      error => {
        this.setState({ ...this.state, isRequestPending: false, errorMessage: error.errorMessage });
      }
    );
  }

  handleLoaderRefreshClick() {
    this.deleteAccount();
  }

  render() {
    const isAccountDeleted = this.state.isAccountDeleted;
    const errorMessage = this.state.errorMessage;

    if (isAccountDeleted === false) {
      const isRequestPending = this.state.isRequestPending;

      return (
        <Container>
          <ContentContainer>
            <AppLogo />
            <Content centerContent={true}>
              <DeleteAccountLoader errorMessage={errorMessage} onRefresh={this.handleLoaderRefreshClick} />
              {isRequestPending === true && <DeletingAccountLabel>{i18n.t("deletingAccount")}</DeletingAccountLabel>}
            </Content>
          </ContentContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <ContentContainer>
            <AppLogo />
            <Content centerContent={false}>
              <AccountDeletedTitle>{i18n.t("accountDeleted").replace(" ", "\n")}</AccountDeletedTitle>
              <AccountDeletedDescription>{i18n.t("accountDeletedDescription")}</AccountDeletedDescription>
              <Link href={websiteUrls.WEB_HOMEPAGE}>{i18n.t("appDomain")}</Link>
              <Link to={routes.SIGNIN}>{i18n.t("signIn")}</Link>
              <Link to={routes.SIGNUP}>{i18n.t("signUp")}</Link>
            </Content>
          </ContentContainer>
        </Container>
      );
    }
  }
}

const mapDispatchToProps = {
  deleteUserAccount: deleteUserAccount
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(DeleteAccountConfirmationComponent));
