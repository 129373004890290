import React, { useState, useMemo, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import i18n from "locale/i18n";
import { useSelector, useDispatch } from "react-redux";

import {
  guessDateInCustodianHistoryUIFormat,
  adminChargeClient,
  adminScheduleClient,
  getAdminListCharges,
  adminDeleteScheduleClient,
  getClientDashboardData,
  wlBillingDisplaySymbolSelector
} from "@kubera/common";

import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/button/PrimaryButton";
import { ReactComponent as DropdownIcon } from "assets/images/sort_documents_icon.svg";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import SecondaryButton from "components/button/SecondaryButton";
import Spinner from "components/loader/Spinner";

const PaymentBlock = styled.div`
  display: flex;
  gap: 13px;
  margin-bottom: 20px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    `}
}`;

const PaymentSetBlock = styled.div`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  min-width: 250px;
  box-sizing: border-box;
`;

const PaymentForm = styled.form`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  min-width: 250px;
  box-sizing: border-box;
`;

const PaymentFormTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-bottom: 11px;
`;

const PaymentFormInputWrapper = styled.div`
  position: relative;
  margin-bottom: 14px;
`;

const PaymentFormInput = styled(TextInput)`
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 45px;
  padding: 14px 15px 14px 12px;
  box-sizing: border-box;
  outline: none;
  color: ${({ isError }) => (isError ? "#FF0000" : null)};
`;

const PaymentFormButtonWrapper = styled.div`
  display: flex;
  column-gap: 7px;
`;

const PaymentFormAmountInput = styled(PaymentFormInput)`
  padding-right: 91px;
`;

const RecurringPaymentInputText = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;

const ErrorBlock = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #ff0000;
  margin-bottom: 14px;
  position: absolute;
  bottom: 100%;
  transform: translateY(100%);
`;

const RecurringPaymentSubmitButton = styled(PrimaryButton)`
  width: 112px;
  height: 43px;
  box-sizing: border-box;
  min-width: unset;
`;

const RecurringPaymentBackButton = styled(SecondaryButton)`
  width: 89px;
  height: 43px;
  box-sizing: border-box;
  min-width: unset;
`;

const AdHocSubmitButton = styled(PrimaryButton)`
  width: 112px;
  height: 43px;
  box-sizing: border-box;
  min-width: unset;
`;

const RecurringValuesBlock = styled.div`
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  line-height: 20px;
  margin-bottom: 16px;
`;

const RecurringValuesTitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const RecurringValuesDropdownValue = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: "Roboto Mono";
  font-size: 14px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  cursor: pointer;
  width: fit-content;
`;

const ClearAction = styled.div`
  font-size: 12px;
  line-height: 140%;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 87, 255, 0.8);
  cursor: pointer;
  margin-top: 5px;
`;

const Loader = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

const RecurringValuesDropdownStatus = styled(RecurringValuesDropdownValue)`
  color: ${({ status }) => {
    switch (status) {
      case "active":
        return "#00A707";
      case "canceled":
        return "#FF0000";
      case "card_error":
        return "#FF0000";
      default:
        return "#000";
    }
  }};
`;

const DropdownAction = styled(DropdownIcon)`
  margin-left: 4px;
`;

const AdminBillingForm = ({ client, userId, isPaymentError, isDisabled = false }) => {
  const dispatch = useDispatch();

  const recurringStatementMenuRef = useRef(null);

  const wlRecurringChargeSchedule = client.recurringChargeSchedule || {};

  const recurringScheduleAmount = wlRecurringChargeSchedule.amount
    ? parseFloat(wlRecurringChargeSchedule.amount) / 100
    : null;
  const recurringScheduleGuessedDate = wlRecurringChargeSchedule.tsNextDate
    ? guessDateInCustodianHistoryUIFormat(wlRecurringChargeSchedule.tsNextDate)
    : null;
  const recurringScheduleDate = wlRecurringChargeSchedule.tsNextDate ? recurringScheduleGuessedDate.dateString : null;

  const symbol = useSelector(wlBillingDisplaySymbolSelector);

  const [recurringAmount, setRecurringAmount] = useState(recurringScheduleAmount);
  const [recurringDate, setRecurringDate] = useState(recurringScheduleDate);
  const [recurringDateObject, setRecurringDateObject] = useState(recurringScheduleGuessedDate);
  const [adHocAmount, setAdHocAmount] = useState("");
  const [adHocDescription, setAdHocDescription] = useState("");
  const [isRecurringFormActive, setIsRecurringFormActive] = useState(true);
  const [isRecurringSubmitted, setIsRecurringSubmitted] = useState(false);
  const [isRecurringLoading, setIsRecurringLoading] = useState(false);
  const [isRecurringCanceling, setIsRecurringCanceling] = useState(false);
  const [isAdhocLoading, setIsAdhocLoading] = useState(false);

  const isRecurringDateAfterToday = useMemo(() => {
    if (!recurringDateObject) {
      return null;
    }
    return new Date(recurringDateObject.date).getTime() > new Date().getTime();
  }, [recurringDateObject]);
  const isRecurringDateError = typeof isRecurringDateAfterToday == "boolean" && !isRecurringDateAfterToday;
  const nextRecurringDate = recurringDateObject ? new Date(recurringDateObject.date) : new Date();
  const nextRecurringDateFormatted = `${nextRecurringDate.getFullYear()}-${nextRecurringDate.getMonth() +
    1}-${nextRecurringDate.getDate()}`;

  const recurringValuesMap = new Map([
    [
      "active",
      {
        amount: `${symbol}${recurringScheduleAmount}/${wlRecurringChargeSchedule.type}`,
        nextBillingDate: recurringDateObject ? recurringDateObject.dateString : recurringScheduleDate,
        label: "ACTIVE",
        status: "active"
      }
    ],
    [
      "canceled",
      {
        amount: `${symbol}${recurringScheduleAmount}/${wlRecurringChargeSchedule.type}`,
        nextBillingDate: "NO FUTURE INVOICES",
        label: "CANCELLED",
        status: "canceled"
      }
    ],
    [
      "card_error",
      {
        amount: `${symbol}${recurringScheduleAmount}/${wlRecurringChargeSchedule.type}`,
        nextBillingDate: "ON HOLD",
        label: "CARD ERROR",
        status: "card_error"
      }
    ]
  ]);
  const currentRecurringValues = isPaymentError
    ? recurringValuesMap.get("card_error")
    : recurringValuesMap.get(wlRecurringChargeSchedule.status);

  const handleRecurringAmount = e => {
    const value = e.target.value;
    setRecurringAmount(value ? value.replace(symbol, "") : value);
  };

  const fillDatePartsWithUpcomingDate = dateParts => {
    try {
      let tempDateParts = dateParts;

      if (dateParts.length < 3) {
        tempDateParts[2] = new Date().getFullYear();

        if (guessDateInCustodianHistoryUIFormat(tempDateParts.join(" ")).date.getTime() < new Date().getTime()) {
          dateParts[2] = new Date().getFullYear() + 1;
          return dateParts;
        } else {
          return tempDateParts;
        }
      } else {
        return dateParts;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleRecurringDateChange = e => {
    setRecurringDate(e.target.value);
    setRecurringDateObject(guessDateInCustodianHistoryUIFormat(e.target.value));
  };

  const handleRecurringDateBlur = e => {
    if (e && !e.target.value) {
      return;
    }
    const dateParts = recurringDate.split(" ").filter(datePart => {
      return datePart !== " " && datePart !== "";
    });
    const newDateParts = fillDatePartsWithUpcomingDate(dateParts);

    if (!newDateParts) {
      return;
    }

    const dateString = newDateParts.join(" ");

    const guessedDate = guessDateInCustodianHistoryUIFormat(dateString);
    setRecurringDate(guessedDate.dateString);
    setRecurringDateObject(guessedDate);
  };

  const handleRecurringDateKeyPress = e => {
    if (e.key === "Enter") {
      handleRecurringDateBlur(e);
    }
  };

  const handleAdHocAmount = e => {
    const value = e.target.value;
    setAdHocAmount(value ? value.replace(symbol, "") : value);
  };

  const handleAdHocDescription = e => {
    setAdHocDescription(e.target.value);
  };

  const handleScheduleClick = async e => {
    if (e) {
      e.preventDefault();
    }
    setIsRecurringSubmitted(true);
    if (!recurringAmount || !recurringDateObject || isRecurringDateError) {
      return;
    }
    setIsRecurringLoading(true);
    if (isRecurringDateError) {
      return;
    }
    const recurringPayment = {
      userId,
      amount: "" + recurringAmount * 100,
      type: "month",
      status: "active",
      tsNextDate: nextRecurringDateFormatted
    };
    await dispatch(adminScheduleClient(recurringPayment));
    setIsRecurringLoading(false);
    setIsRecurringFormActive(true);
  };

  const handleScheduleCancel = async e => {
    setIsRecurringLoading(true);
    if (e) {
      e.preventDefault();
    }
    if (isRecurringDateError) {
      return;
    }
    const recurringPayment = {
      userId,
      amount: "" + recurringAmount * 100,
      type: "month",
      status: "canceled",
      tsNextDate: nextRecurringDateFormatted
    };
    await dispatch(adminScheduleClient(recurringPayment));
    setIsRecurringLoading(false);
    setIsRecurringFormActive(true);
  };

  const handleAdHocChargeClick = async e => {
    e.preventDefault();
    if (!adHocAmount || !adHocDescription) {
      return;
    }
    setIsAdhocLoading(true);
    const adHocPayment = {
      userId,
      amount: "" + adHocAmount * 100,
      description: adHocDescription
    };
    await dispatch(adminChargeClient(adHocPayment));
    dispatch(getAdminListCharges(userId));
    setIsAdhocLoading(false);
    setAdHocAmount("");
    setAdHocDescription("");
  };

  const handleRecurringStatusMenuSelection = menuItem => {
    if (menuItem.id === "canceled") {
      handleScheduleCancel();
    }
  };

  const handleStatusUpdate = e => {
    e.stopPropagation();
    if (
      recurringStatementMenuRef.current.isVisible() === true ||
      currentRecurringValues.status === "canceled" ||
      currentRecurringValues.status === "card_error"
    ) {
      recurringStatementMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    var recurringStatusOptions = [contextMenuItemType.ACTIVE, contextMenuItemType.CANCEL];

    recurringStatementMenuRef.current.show(
      [recurringStatusOptions],
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      e.target
    );
  };

  const handleShowRecurringForm = () => {
    if (recurringStatementMenuRef.current.isVisible()) {
      return;
    }
    setIsRecurringFormActive(false);
  };

  const handleBackRecurringForm = e => {
    e.preventDefault();
    if (currentRecurringValues) {
      setIsRecurringFormActive(true);
    }
  };

  const handleCancelRecurringForm = async e => {
    e.stopPropagation();
    e.preventDefault();
    setIsRecurringCanceling(true);
    await dispatch(adminDeleteScheduleClient(userId));
    setIsRecurringFormActive(true);
    setIsRecurringCanceling(false);
    dispatch(getClientDashboardData(userId));
  };

  const handlePaymentBlockClickCapture = e => {
    if (isDisabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!isRecurringFormActive && recurringDate) {
      handleRecurringDateBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurringFormActive]);

  useEffect(() => {
    setIsRecurringSubmitted(false);
  }, [recurringAmount, recurringDate]);

  return (
    <PaymentBlock onClickCapture={handlePaymentBlockClickCapture} isDisabled={isDisabled}>
      {!isDisabled && isRecurringFormActive && currentRecurringValues ? (
        <PaymentSetBlock>
          <PaymentFormTitle>{i18n.t("adminBillingAndPayments.recurringChargeSetTitle")}</PaymentFormTitle>
          <RecurringValuesBlock>
            <RecurringValuesTitle>{i18n.t("plan")}</RecurringValuesTitle>
            <RecurringValuesDropdownValue onClick={handleShowRecurringForm}>
              {currentRecurringValues.amount}
              <DropdownAction />
            </RecurringValuesDropdownValue>
          </RecurringValuesBlock>
          <RecurringValuesBlock>
            <RecurringValuesTitle>{i18n.t("nextBillingDate")}</RecurringValuesTitle>
            <RecurringValuesDropdownValue onClick={handleShowRecurringForm}>
              {currentRecurringValues.nextBillingDate}
              <DropdownAction />
            </RecurringValuesDropdownValue>
          </RecurringValuesBlock>
          <RecurringValuesBlock>
            <RecurringValuesTitle>{i18n.t("status")}</RecurringValuesTitle>
            <RecurringValuesDropdownStatus status={currentRecurringValues.status} onClick={handleStatusUpdate}>
              {currentRecurringValues.label}
              {currentRecurringValues.status !== "canceled" && <DropdownAction />}
            </RecurringValuesDropdownStatus>
            {currentRecurringValues.status === "canceled" && (
              <ClearAction onClick={handleCancelRecurringForm}>
                {!isRecurringCanceling ? "Clear" : <Loader />}
              </ClearAction>
            )}
            {currentRecurringValues.status === "card_error" && (
              <RecurringValuesDropdownStatus status={currentRecurringValues.status}>
                {i18n.t("willRetryTomorrow")}
              </RecurringValuesDropdownStatus>
            )}
          </RecurringValuesBlock>
          <ContextMenu ref={recurringStatementMenuRef} onSelection={handleRecurringStatusMenuSelection} />
        </PaymentSetBlock>
      ) : (
        <PaymentForm onSubmit={handleScheduleClick}>
          <PaymentFormTitle>{i18n.t("adminBillingAndPayments.recurringChargeFormTitle")}</PaymentFormTitle>
          <PaymentFormInputWrapper>
            <PaymentFormAmountInput
              placeholder={i18n.t("amount")}
              value={recurringAmount ? `${symbol}${recurringAmount}` : ""}
              onChange={handleRecurringAmount}
            />
            <RecurringPaymentInputText>{i18n.t("perMonth")}</RecurringPaymentInputText>
          </PaymentFormInputWrapper>
          <PaymentFormInputWrapper>
            {isRecurringSubmitted && isRecurringDateError && (
              <ErrorBlock>{i18n.t("adminBillingAndPayments.recurringChargeDateError")}</ErrorBlock>
            )}
            <PaymentFormInput
              placeholder={i18n.t("adminBillingAndPayments.recurringChargeAmountPlaceholder")}
              value={recurringDate}
              onChange={handleRecurringDateChange}
              onBlur={handleRecurringDateBlur}
              onKeyPress={handleRecurringDateKeyPress}
              isError={isRecurringSubmitted && isRecurringDateError}
            />
          </PaymentFormInputWrapper>
          <PaymentFormButtonWrapper>
            <RecurringPaymentSubmitButton
              title={i18n.t("schedule")}
              data-cy="scheduleRecurringButton"
              onClick={() => null}
              isLoading={isRecurringLoading}
              isDisabled={!isDisabled && (!parseFloat(recurringAmount) || !recurringDate)}
            />
            {!isDisabled && currentRecurringValues && (
              <RecurringPaymentBackButton
                title={i18n.t("back")}
                data-cy="backRecurringButton"
                onClick={handleBackRecurringForm}
              />
            )}
          </PaymentFormButtonWrapper>
        </PaymentForm>
      )}
      <PaymentForm onSubmit={handleAdHocChargeClick}>
        <PaymentFormTitle>{i18n.t("adminBillingAndPayments.adHocFormTitle")}</PaymentFormTitle>
        <PaymentFormInputWrapper>
          <PaymentFormInput
            placeholder={i18n.t("amount")}
            value={adHocAmount ? `${symbol}${adHocAmount}` : ""}
            onChange={handleAdHocAmount}
          />
        </PaymentFormInputWrapper>
        <PaymentFormInputWrapper>
          <PaymentFormInput
            placeholder={i18n.t("description")}
            value={adHocDescription}
            onChange={handleAdHocDescription}
          />
        </PaymentFormInputWrapper>
        <AdHocSubmitButton
          title={i18n.t("chargeNow")}
          data-cy="adHocChargeButton"
          onClick={() => null}
          isLoading={isAdhocLoading}
          isDisabled={!isDisabled && (!adHocAmount || !adHocDescription)}
        />
      </PaymentForm>
    </PaymentBlock>
  );
};

export default AdminBillingForm;
