import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import CurrencyInput from "components/inputs/CurrencyInput";
import RadioButton from "components/inputs/RadioButton";
import CustodianOwnershipComponent from "./CustodianOwnershipComponent";
import PercentageInput from "components/inputs/PercentageInput";
import {
  custodianTypes,
  getTickerUsingId,
  tickerTypes,
  tickerSubTypes,
  accountLinkingService,
  isCryptoLinkingService,
  getEstimatedTaxOnUnrealizedGainsForCustodian,
  getUnrealizedGainForACustodian,
  getCostBasisForTaxOnUnrealizedGains,
  getDefaultTaxRateForPortfolio,
  getCostExchangeRateForTaxOnUnrealizedGainsForCustodian,
  getCostTickerIdForTaxOnUnrealizedGainsForCustodian,
  getCashHoldingsCost,
  irrTypes,
  custodianSubTypes,
  parseNumberStringToFloat,
  custodianTaxTypes,
  taxOnUnrealizedGainsSelector,
  getTaxableTypeForCustodian,
  store,
  custodianSelector,
  userPreferencesSelector,
  markHoldingsToast
} from "@kubera/common";
import { detailsTabs } from "components/custodian_details/CustodianDetailsComponentExports";
import ResetAlert from "components/dialog/ResetAlert";
import FundScheduleDialog from "./FundScheduleDialog";
import RedDot from "components/indicators/RedDot";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const InvestableAssetTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const InvestableAssetDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const InvestableAssetRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
const InvestableAssetButtonContainer = styled.div`
  display: flex;
  margin-top: 2px;
`;

const InvestableAssetRadioButton = styled(RadioButton)`
  margin-bottom: 20px;
`;

const InvestableAssetButton = styled.div`
  text-decoration: underline;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #0074fc;
  cursor: pointer;
`;

const OwnershipComponent = styled(CustodianOwnershipComponent)``;

const CommittedCapitalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 40px;
`;

const CommittedCapitalTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
`;

const CommittedCapitalInput = styled(CurrencyInput)`
  flex: 1
  margin-top: 8px;
`;

const CommittedCapitalDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-line;
`;

const LearnMore = styled.a`
  margin-left: 5px;
  text-decoration: underline;
  color: ${props => (props.highlight === true ? "#0074FC" : "rgba(0, 0, 0, 0.7)")};
  cursor: pointer;

  &:visited {
    color: #000000;
  }
`;

const CommittedCapitalTip = styled(CommittedCapitalDescription)`
  margin-top: 10px;
  line-height: 100%;
`;

const TaxOnUnrealizedGainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.isHoldingShownOutside ? "25px" : "0px")};
`;
const TaxOnUnrealizedGainTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
`;
const TaxOnUnrealizedGainDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-line;
`;
const TaxOnUnrealizedGainInputContainer = styled.div`
  display: flex;
  margin-top: 6px;
`;

const TaxOnUnrealizedGainInputTitleContainer = styled(TaxOnUnrealizedGainInputContainer)`
  margin-top: 13px
  justify-content: flex-start;
  align-items: flex-start;
`;

const InputTitle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
`;
const CostBasisInputTitle = styled(InputTitle)`
  width: 220px;
`;
const UnrealizedGainInputTitle = styled(InputTitle)`
  width: 190px;
`;
const TaxPercentageInputTitle = styled(InputTitle)`
  width: 220px;
`;
const EstimatedTaxInputTitle = styled(InputTitle)`
  width: 220px;
`;

const CostBasisInput = styled(CurrencyInput)`
  width: 220px;
`;
const UnrealizedGainInput = styled(CurrencyInput)`
  width: 190px;
`;
const TaxPercentageInput = styled(PercentageInput)`
  width: 220px;
  margin-top: -3px;
`;
const EstimatedTaxInput = styled(CurrencyInput)`
  width: 220px;
`;
const ManuallyOverrideContainer = styled.div`
display: flex
font-weight: 400;
font-size: 10px;
line-height: 12px;
margin-top: 9px;
color: rgba(0, 0, 0, 0.5);`;

const ResetContainer = styled.div`
  display: flex;
`;

const ManuallyOverrideTaxRateContainer = styled(ManuallyOverrideContainer)`
  margin-left: ${props => (props.isCostBasisResetShown ? "220px" : "410px")};
`;

const ManuallyOverrideDescription = styled.div``;

const Reset = styled(LearnMore)`
  color: rgba(0, 0, 0, 0.5);
`;
const TaxableAssetsTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaxableAssetTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const TaxableAssetDescription = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const TaxableAssetRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const TaxableAssetRadioButton = styled(RadioButton)`
  margin-bottom: ${props => (props.isLastButton ? "0px" : "20px")};
`;

const FundScheduleRedDot = styled(RedDot)`
  position: static;
  display: inline-block;
  margin-left: -12px;
  margin-right: 5px;
  width: 6px;
  height: 6px;
  background: "#FF6464";
`;

class CustodianAssortedFlagsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCostBasisManuallyOverriddenResetAlert: false,
      showTaxRateManuallyOverriddenResetAlert: false,
      showFundScheduleDialog: false
    };

    this.handleInvestableAssetRadioButtonOnChange = this.handleInvestableAssetRadioButtonOnChange.bind(this);
    this.handleCommittedCapitalInputOnChange = this.handleCommittedCapitalInputOnChange.bind(this);
    this.handleReturnsTabNavigation = this.handleReturnsTabNavigation.bind(this);
    this.handleUnrealisedGainInputOnChange = this.handleUnrealisedGainInputOnChange.bind(this);
    this.handleTaxPercentageChange = this.handleTaxPercentageChange.bind(this);
    this.handleResetManuallyOverridenCostbasis = this.handleResetManuallyOverridenCostbasis.bind(this);
    this.dismissCostBasisResetAlert = this.dismissCostBasisResetAlert.bind(this);
    this.dismissTaxRateResetAlert = this.dismissTaxRateResetAlert.bind(this);
    this.handleTaxableAssetChange = this.handleTaxableAssetChange.bind(this);
    this.handleShowFundScheduleDialogClick = this.handleShowFundScheduleDialogClick.bind(this);

    this.fundScheduleRedDotRef = React.createRef();
  }

  get isParentCustodianTypeHoldings() {
    return this.props.parentCustodian?.irrType === irrTypes.HOLDING && this.props.detailsInfo.hidden === 1;
  }

  handleReturnsTabNavigation() {
    if (this.props.onTabChange) {
      this.props.onTabChange(detailsTabs.RETURNS);
    }
  }

  // getTaxableAssetRadioButtonOptions() {
  //   const taxableAssetType = getTaxableTypeForCustodian(store.getState());
  //   return [
  //     {
  //       value: custodianTaxTypes.TAXABLE,
  //       label: i18n.t("taxableAsset.taxable"),
  //       checked: !taxableAssetType || custodianTaxTypes.TAXABLE === taxableAssetType
  //     },
  //     {
  //       value: custodianTaxTypes.TAX_DEFERRED,
  //       label: i18n.t("taxableAsset.taxDefered"),
  //       checked: custodianTaxTypes.TAX_DEFERRED === taxableAssetType
  //     },
  //     {
  //       value: custodianTaxTypes.TAX_FREE,
  //       label: i18n.t("taxableAsset.taxFree"),
  //       checked: custodianTaxTypes.TAX_FREE === taxableAssetType
  //     }
  //   ];
  // }

  getInvestableRadioButtonOptions() {
    const custodian = this.props.detailsInfo;
    const custodianTicker = getTickerUsingId(custodian.valueTickerId);

    if (
      !custodian.parentId === false ||
      (custodian.subType === custodianSubTypes.INVESTMENT && custodian.holdingsCount === 0) ||
      (!custodian.linkType === true &&
        (custodianTicker.type === tickerTypes.STOCK ||
          custodianTicker.type === tickerTypes.FUND ||
          custodianTicker.type === tickerTypes.CRYPTO ||
          custodianTicker.type === tickerTypes.BOND ||
          custodianTicker.type === tickerTypes.DERIVATIVE ||
          custodianTicker.type === tickerTypes.INDEX))
    ) {
      return [
        {
          value: custodianTypes.CASH_IN_HAND,
          label: i18n.t("investableAsset.cash"),
          checked: custodian.type === custodianTypes.CASH_IN_HAND
        },
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      custodian.subType === custodianSubTypes.HOME ||
      custodian.linkType === accountLinkingService.CARS ||
      custodian.linkType === accountLinkingService.DOMAINS
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      custodianTicker.subType === tickerSubTypes.PRECIOUS_METALS ||
      ["wallet", "locked", "loan"].includes(custodian.linkContainer)
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: i18n.t("investableAsset.investable"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (!custodian.parentId === true && custodian.subType === custodianSubTypes.CASH) {
      return [
        {
          value: custodianTypes.CASH_IN_HAND,
          label: i18n.t("investableAsset.cash"),
          checked: custodian.type === custodianTypes.CASH_IN_HAND
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    if (
      (custodian.subType === custodianSubTypes.INVESTMENT && custodian.holdingsCount > 0) ||
      isCryptoLinkingService(custodian.linkType) === true
    ) {
      return [
        {
          value: custodianTypes.INVESTABLE,
          label: isCryptoLinkingService(custodian.linkType)
            ? i18n.t("investableAsset.cryptoHoldings")
            : i18n.t("investableAsset.cashHoldings"),
          checked: custodian.type === custodianTypes.INVESTABLE
        },
        {
          value: custodianTypes.NON_INVESTABLE,
          label: i18n.t("investableAsset.nonInvestable"),
          checked: custodian.type === custodianTypes.NON_INVESTABLE
        }
      ];
    }

    return [
      {
        value: custodianTypes.CASH_IN_HAND,
        label: i18n.t("investableAsset.cash"),
        checked: custodian.type === custodianTypes.CASH_IN_HAND
      },
      {
        value: custodianTypes.INVESTABLE,
        label: i18n.t("investableAsset.investable"),
        checked: custodian.type === custodianTypes.INVESTABLE
      },
      {
        value: custodianTypes.NON_INVESTABLE,
        label: i18n.t("investableAsset.nonInvestable"),
        checked: custodian.type === custodianTypes.NON_INVESTABLE
      }
    ];
  }

  // getInvestableOptionsForBrokerages() {
  //   return [
  //     {
  //       value: custodianTypes.INVESTABLE,
  //       id: "investable",
  //       label: "Mark holdings as Investable"
  //     },
  //     {
  //       value: custodianTypes.NON_INVESTABLE,
  //       id: "nonInvestable",
  //       label: "Mark holdings as Non Investable"
  //     }
  //   ];
  // }

  canShowCommittedCapital() {
    const custodian = this.props.detailsInfo;
    if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      return false;
    }
    const custodianTicker = getTickerUsingId(custodian.valueTickerId);
    const isManualCustodian = !custodian.linkType === true;
    const accountIsOfSubtypeInvestment = !custodian.parentId && custodian.subType === custodianSubTypes.INVESTMENT;
    const isHoldingShownOutsideAndOfSubtypeInvestmentOrOther =
      custodian.parentId &&
      !custodian.hidden &&
      (custodian.holdingSubType === custodianSubTypes.INVESTMENT ||
        custodian.holdingSubType === custodianSubTypes.OTHER_FIXED);
    if (
      (isManualCustodian || accountIsOfSubtypeInvestment || isHoldingShownOutsideAndOfSubtypeInvestmentOrOther) &&
      (custodianTicker.type === tickerTypes.FIAT || custodianTicker.type === tickerTypes.CRYPTO) &&
      custodianTicker.subType !== tickerSubTypes.PRECIOUS_METALS
    ) {
      return true;
    }
    return false;
  }

  handleInvestableAssetRadioButtonOnChange(value) {
    this.props.onCustodianUpdate({ type: value }, true);
  }

  handleCommittedCapitalInputOnChange(params) {
    this.props.onCustodianUpdate({ cmtdCap: params.value, cmtdCapTickerId: params.tickerId }, true);
  }

  handleUnrealisedGainInputOnChange(params, taxPercentage) {
    const taxDetails = this.props.detailsInfo.taxDetails ? JSON.parse(this.props.detailsInfo.taxDetails) : null;
    this.props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          ...taxDetails,
          costBasis: params.value,
          costBasisTickerId: params.tickerId,
          costBasisExchangeRate: params.exchangeRate,
          taxPercentage: taxDetails && taxDetails.taxPercentage ? taxPercentage : null
        })
      },
      true
    );
  }

  handleTaxPercentageChange(e, costBasis, costBasisTickerId, costBasisExchangeRate, taxDetails) {
    const taxPercentage = parseNumberStringToFloat(e.target.value.replace("%", ""));
    this.props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          ...taxDetails,
          costBasis: costBasis,
          costBasisTickerId,
          costBasisExchangeRate,
          taxPercentage
        })
      },
      true
    );
  }

  handleTaxableAssetChange(value, taxDetailsForCustodian) {
    this.props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          ...taxDetailsForCustodian,
          taxableAssetType: value
        })
      },
      true
    );
  }
  canShowResetCostBasis(cashHoldingsCost) {
    if (!this.props.detailsInfo.taxDetails === false && this.props.detailsInfo.cost) {
      const taxDetails = JSON.parse(this.props.detailsInfo.taxDetails);
      if (taxDetails && (taxDetails.costBasis || taxDetails.costBasis === 0)) {
        if (this.props.detailsInfo.irrType === irrTypes.COSTBASIS && !this.props.detailsInfo.cost === false) {
          return this.props.detailsInfo.cost - cashHoldingsCost !== taxDetails.costBasis;
        }
        return true;
      }
    }
    return false;
  }

  canShowResetDefaultTaxRate(defaultTaxRateForCurrentPortfolio, taxPercentage, taxOnUnrealizedGains) {
    return (
      (taxOnUnrealizedGains && !taxOnUnrealizedGains.taxPercentage === false) ||
      defaultTaxRateForCurrentPortfolio !== taxPercentage
    );
  }

  dismissCostBasisResetAlert() {
    this.setState({ showCostBasisManuallyOverriddenResetAlert: false });
  }

  dismissTaxRateResetAlert() {
    this.setState({ showTaxRateManuallyOverriddenResetAlert: false });
  }

  showCostBasisResetAlert() {
    this.setState({ showCostBasisManuallyOverriddenResetAlert: true });
  }
  showTaxRateResetAlert() {
    this.setState({ showTaxRateManuallyOverriddenResetAlert: true });
  }

  handleResetManuallyOverridenCostbasis(taxPercentage, holdings) {
    const taxDetails = this.props.detailsInfo.taxDetails ? JSON.parse(this.props.detailsInfo.taxDetails) : null;
    const costBasisTickerId = this.props.detailsInfo.costTickerId;
    const costBasisExchangeRate = this.props.detailsInfo.costExchangeRate;
    this.props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          costBasis: null,
          costBasisTickerId,
          costBasisExchangeRate,
          taxPercentage: taxDetails && taxDetails.taxPercentage ? taxPercentage : null,
          taxableAssetType: taxDetails && taxDetails.taxableAssetType ? taxDetails.taxableAssetType : null
        })
      },
      true
    );
    this.dismissCostBasisResetAlert();
  }

  handleResetManuallyOverridenTaxPercentage(taxOnUnrealizedGains) {
    this.props.onCustodianUpdate(
      {
        taxDetails: JSON.stringify({
          costBasis: taxOnUnrealizedGains.costBasis,
          costBasisTickerId: taxOnUnrealizedGains.costBasisTickerId,
          costBasisExchangeRate: taxOnUnrealizedGains.costBasisExchangeRate,
          taxableAssetType: taxOnUnrealizedGains.taxableAssetType
        })
      },
      true
    );
    this.dismissTaxRateResetAlert();
  }

  handleShowFundScheduleDialogClick(e) {
    this.setState({ showFundScheduleDialog: true });

    if (this.props.userPreferences.isFundScheduleTipShown === false) {
      this.fundScheduleRedDotRef.current.setPreferenceFlag(true);
    }
  }

  canShowTaxOnUnrealizedGainSection(taxMetaData) {
    const custodian = this.props.detailsInfo;
    if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO || this.isParentCustodianTypeHoldings) {
      return false;
    }
    const taxableAssetType = taxMetaData ? taxMetaData.taxableAssetType : null;
    return !taxableAssetType ? true : taxableAssetType !== custodianTaxTypes.TAX_FREE;
  }

  handleInvestableButtonClick(selectedOption) {
    if (selectedOption.id === "investable") {
      this.props.onCustodianUpdate({ type: custodianTypes.INVESTABLE }, true, this.props.markHoldingsToast(true));
    } else if (selectedOption.id === "nonInvestable") {
      this.props.onCustodianUpdate({ type: custodianTypes.NON_INVESTABLE }, true, this.props.markHoldingsToast(false));
    }
  }

  // renderInvestableAssetButton(holdings) {
  //   if (holdings && holdings.length > 0) {
  //     return (
  //       <>
  //         <InvestableAssetTitle>{i18n.t("investableAsset.title")}</InvestableAssetTitle>
  //         <InvestableAssetDescription>{i18n.t("investableAsset.brokerage.description")}</InvestableAssetDescription>
  //         <InvestableAssetButtonContainer>
  //           {this.getInvestableOptionsForBrokerages().map(option => {
  //             return (
  //               <InvestableAssetButton
  //                 key={option.id}
  //                 onClick={() => {
  //                   this.handleInvestableButtonClick(option);
  //                 }}
  //               >
  //                 {option.label}
  //               </InvestableAssetButton>
  //             );
  //           })}
  //         </InvestableAssetButtonContainer>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <InvestableAssetTitle>{i18n.t("investableAsset.title")}</InvestableAssetTitle>
  //         <InvestableAssetDescription>{i18n.t("investableAsset.description")}</InvestableAssetDescription>
  //         <InvestableAssetRadioButtonContainer>
  //           {this.getInvestableRadioButtonOptions().map((option, index) => {
  //             return (
  //               <InvestableAssetRadioButton
  //                 key={index}
  //                 label={option.label}
  //                 value={option.value}
  //                 checked={option.checked}
  //                 disabled={this.props.isReadOnly}
  //                 onChange={this.handleInvestableAssetRadioButtonOnChange}
  //               />
  //             );
  //           })}
  //         </InvestableAssetRadioButtonContainer>
  //       </>
  //     );
  //   }
  // }

  render() {
    const custodian = this.props.detailsInfo;
    const taxOnUnrealizedGains = this.props.taxOnUnrealizedGains;
    const defaultTaxRateForCurrentPortfolio = getDefaultTaxRateForPortfolio();
    const holdings = this.props.details && this.props.details.holdings ? this.props.details.holdings : [];
    const taxPercentage =
      taxOnUnrealizedGains &&
      (taxOnUnrealizedGains.taxPercentage !== undefined && taxOnUnrealizedGains.taxPercentage !== null)
        ? taxOnUnrealizedGains.taxPercentage
        : defaultTaxRateForCurrentPortfolio;
    const costBasis =
      custodian.irrType === irrTypes.HOLDING && holdings?.length === 0
        ? null
        : getCostBasisForTaxOnUnrealizedGains(custodian, holdings);
    const cashHoldingsCost = getCashHoldingsCost(holdings, custodian.costTickerId);
    const unrealizedGain = costBasis
      ? this.props.getUnrealizedGainForACustodian(custodian, this.props.category, holdings)
      : null;
    const estimatedTax = getEstimatedTaxOnUnrealizedGainsForCustodian(custodian, unrealizedGain);
    const costTickerId = getCostTickerIdForTaxOnUnrealizedGainsForCustodian(custodian);
    const costExchangeRate = getCostExchangeRateForTaxOnUnrealizedGainsForCustodian(custodian);

    return (
      <Container className={this.props.className}>
        {custodian.linkType !== accountLinkingService.KUBERA_PORTFOLIO && (
          <>
            {/* {this.renderInvestableAssetButton(holdings)} */}
            {!custodian.parentId === true && (
              <OwnershipComponent
                details={this.props.details}
                custodianId={this.props.detailsInfo.id}
                detailsInfo={this.props.detailsInfo}
                ownershipValue={this.props.detailsInfo.ownership}
                isReadOnly={this.props.isReadOnly}
                category={this.props.category}
                chartStartDate={this.props.chartStartDate}
                getCustodianChartData={this.props.getCustodianChartData}
              />
            )}
          </>
        )}

        {this.canShowCommittedCapital() === true && (
          <CommittedCapitalContainer>
            <CommittedCapitalTitle>{i18n.t("committedCapital.title")}</CommittedCapitalTitle>
            <CommittedCapitalDescription>
              {i18n.t("committedCapital.description")}
              <LearnMore target={"_blank"} href={"https://help.kubera.com/article/85-what-is-committed-capital"}>
                {i18n.t("learnMore")}
              </LearnMore>
            </CommittedCapitalDescription>
            <CommittedCapitalInput
              portfolioCurrency={this.props.currency}
              value={custodian.cmtdCap}
              tickerId={custodian.cmtdCapTickerId}
              placeholder={i18n.t("currencyInputPlaceholder")}
              inputStyle={{ display: "flex", flex: 1, height: "43px", border: "1px solid rgba(0, 0, 0, 0.4)" }}
              hideExchangeRateBubble={true}
              isReadOnly={this.props.isReadOnly}
              onChange={this.handleCommittedCapitalInputOnChange}
              isInSingleCellMode={false}
              supportedTickerTypes={[tickerTypes.FIAT, tickerTypes.CRYPTO]}
              unsupportedTickerSubTypes={[tickerSubTypes.PRECIOUS_METALS]}
            />
            <CommittedCapitalTip>
              <FundScheduleRedDot ref={this.fundScheduleRedDotRef} preference="isFundScheduleTipShown" />
              {i18n.t("fundSchedule.tip")}
              <LearnMore highlight={true} onClick={this.handleShowFundScheduleDialogClick}>
                {i18n.t("fundSchedule.tipAction")}
              </LearnMore>
            </CommittedCapitalTip>
            <CommittedCapitalTip>
              {i18n.t("committedCapital.tip")}
              <LearnMore onClick={this.handleReturnsTabNavigation}>{i18n.t("cashflowBreadcrumb")}</LearnMore>
            </CommittedCapitalTip>
          </CommittedCapitalContainer>
        )}

        {this.canShowTaxOnUnrealizedGainSection(taxOnUnrealizedGains) && (
          <>
            <TaxOnUnrealizedGainContainer isHoldingShownOutside={custodian.parentId && !custodian.hidden}>
              <TaxOnUnrealizedGainTitle>{i18n.t("taxOnUnrealizedGain.assortedTab.title")}</TaxOnUnrealizedGainTitle>
              <TaxOnUnrealizedGainDescription>
                {i18n.t("taxOnUnrealizedGain.description")}
                <LearnMore
                  target={"_blank"}
                  href={"https://help.kubera.com/article/107-what-is-tax-on-unrealized-gain"}
                >
                  {i18n.t("learnMore")}
                </LearnMore>
              </TaxOnUnrealizedGainDescription>
              <TaxOnUnrealizedGainInputTitleContainer>
                <CostBasisInputTitle>{"Cost basis"}</CostBasisInputTitle>
                <UnrealizedGainInputTitle>{"Unrealized Gain"}</UnrealizedGainInputTitle>
                <TaxPercentageInputTitle>{"Tax Rate"}</TaxPercentageInputTitle>
                <EstimatedTaxInputTitle>{"Estimated tax"}</EstimatedTaxInputTitle>
              </TaxOnUnrealizedGainInputTitleContainer>
              <TaxOnUnrealizedGainInputContainer>
                <CostBasisInput
                  value={costBasis}
                  tickerId={costTickerId}
                  portfolioCurrency={this.props.currency}
                  placeholder={"Amount"}
                  inputStyle={{
                    display: "flex",
                    flex: 1,
                    height: "43px",
                    border: "1px solid rgba(0, 0, 0, 0.4)",
                    overflow: "hidden",
                    width: "230px"
                  }}
                  hideExchangeRateBubble={true}
                  onChange={params => {
                    this.handleUnrealisedGainInputOnChange(params, taxPercentage, defaultTaxRateForCurrentPortfolio);
                  }}
                  isInSingleCellMode={false}
                  width={220}
                  supportedTickerTypes={[tickerTypes.FIAT, tickerTypes.CRYPTO]}
                  unsupportedTickerSubTypes={[tickerSubTypes.PRECIOUS_METALS]}
                  isReadOnly={this.props.isReadOnly}
                />
                <UnrealizedGainInput
                  value={unrealizedGain}
                  portfolioCurrency={this.props.currency}
                  inputStyle={{
                    display: "flex",
                    flex: 1,
                    height: "43px",
                    border: "1px solid rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#F4F4F4",
                    width: "190px",
                    overflow: "hidden"
                  }}
                  isReadOnly={true}
                  hideExchangeRateBubble={true}
                  isInSingleCellMode={false}
                  width={190}
                />
                <TaxPercentageInput
                  value={taxPercentage}
                  onChange={e => {
                    this.handleTaxPercentageChange(e, costBasis, costTickerId, costExchangeRate, taxOnUnrealizedGains);
                  }}
                  disabled={this.props.isReadOnly}
                />
                <EstimatedTaxInput
                  value={estimatedTax}
                  portfolioCurrency={this.props.currency}
                  inputStyle={{
                    display: "flex",
                    flex: 1,
                    height: "43px",
                    border: "1px solid rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#F4F4F4"
                  }}
                  isReadOnly={true}
                  hideExchangeRateBubble={true}
                  isInSingleCellMode={false}
                  width={220}
                />
              </TaxOnUnrealizedGainInputContainer>
            </TaxOnUnrealizedGainContainer>
            <ResetContainer>
              {this.canShowResetCostBasis(cashHoldingsCost) === true && (
                <ManuallyOverrideContainer>
                  <ManuallyOverrideDescription>
                    {i18n.t("costBasisManuallyOverridden.description")}
                  </ManuallyOverrideDescription>
                  <Reset
                    onClick={() => {
                      this.showCostBasisResetAlert();
                    }}
                  >
                    {"Reset"}
                  </Reset>
                </ManuallyOverrideContainer>
              )}
              {this.canShowResetDefaultTaxRate(
                defaultTaxRateForCurrentPortfolio,
                taxPercentage,
                taxOnUnrealizedGains
              ) === true && (
                <ManuallyOverrideTaxRateContainer
                  isCostBasisResetShown={this.canShowResetCostBasis(cashHoldingsCost) === true}
                >
                  <ManuallyOverrideDescription>
                    {i18n.t("taxRateManuallyOverridden.description")}
                  </ManuallyOverrideDescription>
                  <Reset
                    onClick={() => {
                      this.showTaxRateResetAlert();
                    }}
                  >
                    {"Reset"}
                  </Reset>
                </ManuallyOverrideTaxRateContainer>
              )}
            </ResetContainer>
          </>
        )}
        <ResetAlert
          isOpen={this.state.showCostBasisManuallyOverriddenResetAlert}
          onDismiss={this.dismissCostBasisResetAlert}
          onReset={() => {
            this.handleResetManuallyOverridenCostbasis(
              taxPercentage,
              defaultTaxRateForCurrentPortfolio,
              costBasis,
              costTickerId,
              costExchangeRate
            );
          }}
          title={i18n.t("costBasisManuallyOverriddenAlert.title")}
          description={i18n.t("costBasisManuallyOverriddenAlert.description")}
        />
        <ResetAlert
          isOpen={this.state.showTaxRateManuallyOverriddenResetAlert}
          onDismiss={this.dismissTaxRateResetAlert}
          onReset={() => {
            this.handleResetManuallyOverridenTaxPercentage(taxOnUnrealizedGains, defaultTaxRateForCurrentPortfolio);
          }}
          title={i18n.t("taxRateManuallyOverriddenAlert.title")}
          description={i18n
            .t("taxRateManuallyOverriddenAlert.description")
            .replace("%s%", defaultTaxRateForCurrentPortfolio)}
        />
        {this.state.showFundScheduleDialog && (
          <FundScheduleDialog
            custodian={custodian}
            isReadOnly={this.props.isReadOnly}
            currency={this.props.currency}
            onCashflowLinkClick={this.handleReturnsTabNavigation}
            onDismiss={() => this.setState({ showFundScheduleDialog: false })}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  getUnrealizedGainForACustodian: getUnrealizedGainForACustodian.bind(state),
  taxOnUnrealizedGains: taxOnUnrealizedGainsSelector(state),
  parentCustodian: custodianSelector(state, props.detailsInfo.parentId),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  markHoldingsToast: markHoldingsToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianAssortedFlagsComponent);
