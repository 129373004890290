import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  updateUserPreferences,
  userPreferencesSelector,
  currentPortfolioSelector,
  isPortfolioReadySelector
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import { ReactComponent as Icon } from "assets/images/delorean_time_machine.svg";
import blankstateImage from "assets/images/planning_blankstate.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 45px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 10;
`;

const ContentView = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  padding: 50px 50px 100px 50px;
  background: ${props => props.theme.chartsNotAvailableEmptyViewContainerBG};
  border: ${props => props.theme.chartsNotAvailableEmptyViewContainerBR};
  box-sizing: border-box;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const EmptyIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 50px;
  width: 120px;
  height: 60px;

  path {
    fill: ${props => props.theme.dashboardComponentContainerCLR};
  }
`;

const Description = styled.span`
  width: 75%;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const OkButton = styled(PrimaryButton)`
  height: 44px;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
`;

const SampleImage = styled.div`
  width: 100%;
  height: 1000px;
  margin-top: -90px;
  z-index: 1000;
  background-color: transparent;
  background-image: url(${blankstateImage});
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`;

class PlanningComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleOkButtonClick = this.handleOkButtonClick.bind(this);
  }

  handleOkButtonClick(e) {
    this.props.updateUserPreferences({
      isPlanningIntroScreenShownForPortfolio: [
        ...this.props.userPreferences.isPlanningIntroScreenShownForPortfolio,
        this.props.portfolio.id
      ]
    });
  }

  render() {
    return (
      <Container>
        <ContentContainer>
          <ContentView>
            <Title>{i18n.t("fastForward")}</Title>
            <Description>
              {i18n.t("fastForward.description") +
                " " +
                (this.props.isPortfolioReady === true ? i18n.t("fastForward.ready") : i18n.t("fastForward.empty"))}
            </Description>
            {this.props.isPortfolioReady === true && (
              <OkButton title={i18n.t("fastForward.readyButton")} onClick={this.handleOkButtonClick} />
            )}
            <EmptyIcon />
          </ContentView>
          <SampleImage />
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userPreferences: userPreferencesSelector(state),
  portfolio: currentPortfolioSelector(state),
  isPortfolioReady: isPortfolioReadySelector(state)
});

const mapDispatchToProps = { updateUserPreferences: updateUserPreferences };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlanningComponent));
