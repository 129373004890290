import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { routes, queryParams, hashParams } from "routes";
import {
  connectStripe,
  wlSiteConnectedAccountSelector,
  removeConnectedAccount,
  setSiteConfig,
  fetchWlDashboard,
  getHashParams,
  saveConnectedAccount,
  useHistory
} from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import SecondaryButton from "components/button/SecondaryButton";
import { ReactComponent as StripeLogo } from "assets/images/stripe_logo.svg";
import { ReactComponent as Close } from "assets/images/close.svg";
import { ReactComponent as Exit } from "assets/images/exit.svg";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

const Title = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
`;

const Description = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  color: #000;
  margin-bottom: 20px;
`;

const ConnectToStripeBlock = styled(SecondaryButton)`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 255px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
`;

const StripeLogoComponent = styled(StripeLogo)`
  position: relative;
  top: 1.45px;
  margin-left: 5px;
`;

const ConnectedAccountText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 3px;
`;

const ConnectedAccountStripedCard = styled.div`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 30px;
  position: relative;
  cursor: pointer;
`;

const StripeLogoCardComponent = styled(StripeLogo)`
  height: 25px;
  margin-bottom: 73px;
`;

const CardCloseAction = styled(Close)`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 8px;
  height: 8px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;

const ExitIcon = styled(Exit)`
  display: block;
  height: 9px;
  margin-bottom: 8px;
`;

const AdminStripeCardInfo = styled.div`
  font-family: "Roboto Mono";
  font-size: 14px;
  line-height: 18px;
  text-transform: ${({ isTextUppercase }) => (isTextUppercase ? "uppercase" : null)};
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-bottom: ${({ isLast }) => (!isLast ? "2px" : null)};
`;

const AdminStripeCardInfoEmail = styled(AdminStripeCardInfo)`
  margin-bottom: 12px;
`;

const AdminStripeCardInfoLine = styled.div``;

const AdminClientBilling = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const wlSiteConnectedAccount = useSelector(wlSiteConnectedAccountSelector);

  const handleConnectToStripe = async () => {
    setIsLoading(true);
    const connectResponse = await dispatch(connectStripe());
    const url = `${process.env.REACT_APP_COGNITO_BASE_URL}${routes.REDIRECT_HOP}?${
      queryParams.REDIRECT_URL
    }=${encodeURIComponent(connectResponse.url)}&${queryParams.CALLBACK_DOMAIN}=${encodeURI(window.location.host)}`;
    window.kuberaOpen(url, "_self");
  };

  const handleConnectStripe = () => {
    handleConnectToStripe();
  };

  const handleDeleteConfirmNegativeButtonClick = () => {
    setDeleteConfirmationDialogOpen(false);
  };
  const handleDeleteConfirmPositiveButtonClick = async () => {
    setDeleteConfirmationDialogOpen(false);
    await dispatch(removeConnectedAccount());
    dispatch(setSiteConfig(() => null, () => null));
    dispatch(fetchWlDashboard(true));
  };

  const handleConnectedStripeCardClick = () => {
    window.kuberaOpen(wlSiteConnectedAccount.url, "_blank");
  };

  const openRemoveConnectedAccountConfirmationDialog = e => {
    e.stopPropagation();
    setDeleteConfirmationDialogOpen(true);
  };

  const formattedValuesForCard = useMemo(
    () => ({
      addressCity: wlSiteConnectedAccount.addressCity ? `${wlSiteConnectedAccount.addressCity}, ` : "",
      addressState: wlSiteConnectedAccount.addressState ? `${wlSiteConnectedAccount.addressState} ` : "",
      addressZip: wlSiteConnectedAccount.addressZip ? `${wlSiteConnectedAccount.addressZip}, ` : "",
      bankName: wlSiteConnectedAccount.bankName ? `${wlSiteConnectedAccount.bankName} • ` : ""
    }),
    [wlSiteConnectedAccount]
  );

  useEffect(() => {
    const urlQueryParams = getHashParams(location);
    const state = urlQueryParams[hashParams.STATE];
    const code = urlQueryParams[hashParams.CODE];

    if (state && code) {
      dispatch(saveConnectedAccount(code, state)).catch(() => {});

      history.replace({
        ...location,
        hash: `${hashParams.MODAL}=${urlQueryParams[hashParams.MODAL]}&${hashParams.TAB}=${
          urlQueryParams[hashParams.TAB]
        }`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Container className={className}>
      <TitleContainer>
        <Title>{i18n.t("adminClientBilling.modalTitle")}</Title>
      </TitleContainer>
      <Description>{i18n.t("adminClientBilling.modalDescription")}</Description>
      {wlSiteConnectedAccount && wlSiteConnectedAccount.status === "active" ? (
        <>
          <ConnectedAccountText>{i18n.t("adminClientBilling.connectedAccountText")}</ConnectedAccountText>
          <ConnectedAccountStripedCard onClick={handleConnectedStripeCardClick}>
            <StripeLogoCardComponent />
            <CardCloseAction onClick={openRemoveConnectedAccountConfirmationDialog} />
            <ExitIcon />
            <AdminStripeCardInfo isTextUppercase>
              <AdminStripeCardInfoLine>
                <b>{wlSiteConnectedAccount.addressLine || wlSiteConnectedAccount.name}</b>
              </AdminStripeCardInfoLine>
              <AdminStripeCardInfoLine>{`${formattedValuesForCard.addressCity}${formattedValuesForCard.addressCity}${formattedValuesForCard.addressZip}${wlSiteConnectedAccount.addressCountry}`}</AdminStripeCardInfoLine>
            </AdminStripeCardInfo>
            <AdminStripeCardInfoEmail>
              <AdminStripeCardInfoLine>{wlSiteConnectedAccount.email}</AdminStripeCardInfoLine>
            </AdminStripeCardInfoEmail>
            <AdminStripeCardInfo isLast isTextUppercase>
              <AdminStripeCardInfoLine>
                {formattedValuesForCard.bankName}
                {wlSiteConnectedAccount.currency}
              </AdminStripeCardInfoLine>
            </AdminStripeCardInfo>
          </ConnectedAccountStripedCard>
        </>
      ) : (
        <ConnectToStripeBlock
          title={
            <>
              Connect to
              <StripeLogoComponent />
            </>
          }
          isLoading={isLoading}
          onClick={handleConnectStripe}
        />
      )}
      {deleteConfirmationDialogOpen && (
        <ConfirmationDialog
          title={i18n.t("adminBillingAndPayments.removeStripeConnectedTitle")}
          description={i18n.t("adminBillingAndPayments.removeStripeConnectedDesc")}
          positiveButtonTitle={i18n.t("remove")}
          negativeButtonTitle={i18n.t("cancel")}
          handleNegativeButtonClick={handleDeleteConfirmNegativeButtonClick}
          handlePositiveButtonClick={handleDeleteConfirmPositiveButtonClick}
        />
      )}
    </Container>
  );
};

export default AdminClientBilling;
