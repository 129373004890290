import { tickerTypes, UPDATE_TICKER_DATA, ADD_TICKER_INFO, tickersRehydratedPromise } from "../actions/Common";
import { getDateStringForExchangeRateDate, tickerReducerInitialState as initialState } from "../reducers/Common";
import { REHYDRATE, PURGE } from "redux-persist";
import createWhitelistFilter from "redux-persist-transform-filter";
import { createSelector } from "reselect";

export const exchangeCodeToNameMap = {
  BSEX: "Baku",
  DUB: "Dublin",
  HKHKSG: "Hong Kong - Shanghai - Hong Kong",
  CAR: "Caracas",
  COL: "Colombo",
  ESMSE: "Madrid",
  DFM: "Dubai",
  SAT: "Spotlight",
  BSP: "B3, São Paulo",
  NWTC: "Norwegian OTC",
  BRV: "BRVM",
  WBO: "Vienna",
  USAMEX: "NYSE American",
  GHA: "Ghana",
  MSW: "Malawi",
  MAL: "Malta",
  CSX: "Cambodia",
  DHA: "Dhaka",
  BAH: "Bahrain",
  BDA: "Bermuda",
  KUW: "Kuwait",
  BUL: "Bulgaria",
  BVC: "Colombia",
  STU: "Stuttgart",
  HKHKSZ: "Hong Kong - Shenzhen - Hong Kong",
  RSEX: "Rwanda",
  PAE: "Palestine",
  NAS: "Nasdaq",
  BOT: "Botswana",
  NSE: "NSE",
  CNSGHK: "Shanghai - Shanghai - Hong Kong",
  BOM: "BSE",
  TSE: "Toronto",
  MIL: "AIM Italia",
  BAA: "Bahamas",
  STC: "Hochiminh",
  ESBSE: "Barcelona",
  USPAC: "NYSE Arca",
  ESBBSE: "Bilbao",
  ALG: "Algiers",
  STE: "Tashkent",
  BUE: "Buenos Aires",
  PINX: "US OTC",
  KSE: "Kyrgyz",
  BUD: "Budapest",
  CSE: "Copenhagen",
  UAX: "Ukrain",
  BRA: "Bratislava",
  ASX: "ASX",
  CIE: "TISE",
  CAY: "Cayman Islands",
  TSX: "TSX Venture",
  GTG: "Guatemala",
  ULA: "Mongolia",
  BSE: "Bucharest",
  PHL: "Nasdaq PHLX",
  ROCO: "Taipei",
  QUI: "Quito",
  POR: "Portal",
  BKK: "Thailand",
  KRKSDQ: "Kosdaq",
  TAI: "Taiwan",
  TWSE: "Taiwan",
  CAI: "Egypt",
  RIS: "Riga",
  OME: "Stockholm",
  KRKNX: "Konex",
  NMS: "NMS",
  NAI: "Nairobi",
  KLS: "Malaysia",
  KRX: "Korea",
  BRU: "Brussels",
  JKT: "Indonesia",
  SEPE: "Perspektiva",
  SHE: "Shenzhen",
  DUS: "Düsseldorf",
  LUS: "Lusaka",
  CAS: "Casablanca",
  ADS: "Abu Dhabi",
  MNX: "Montenegro",
  PTY: "Panama",
  SAU: "Saudi",
  SES: "Singapore",
  LDN: "Euronext London",
  DSX: "Douala",
  ICE: "Iceland",
  LSM: "Libya",
  BRN: "BX Swiss",
  BER: "Berlin",
  CYS: "Cyprus",
  UGA: "Uganda",
  FRA: "Frankfurt",
  NSA: "Nigeria",
  TRN: "Trinidad & Tobago",
  HAN: "Boerse Hannover",
  BEL: "Belgrade",
  IST: "Istanbul",
  TAL: "Tallinn",
  GSE: "Georgia",
  LAO: "Lao",
  MIC: "Moscow",
  AMM: "Amman",
  LIT: "Vilnius",
  ARM: "Armenia",
  DSMD: "Qatar",
  ATH: "Athens",
  ESVSE: "Valencia",
  NAM: "Namibia",
  BLB: "Banja Luka ",
  DIFX: "Nasdaq Dubai",
  KAR: "Pakistan",
  BNV: "Costa Rica",
  NGM: "NGM",
  RURTS: "Moscow",
  ZAG: "Zagreb",
  AMS: "Amsterdam",
  LJU: "Ljubljana",
  KZAIX: "Astana",
  USBATS: "BATS",
  BOL: "Bolivia",
  TAE: "Tel Aviv",
  CNQ: "Canadian Sec",
  SSE: "Sarajevo",
  GUA: "Guayaquil",
  MAE: "Macedonia",
  NEP: "Nepal",
  JAM: "Jamaica",
  MAU: "Mauritius",
  LUX: "Luxembourg",
  IQS: "Iraq",
  LIM: "Lima",
  SWX: "SIX Swiss",
  BOG: "Colombia",
  JPOSE: "Osaka",
  ETR: "XETRA",
  HAM: "Hamburg",
  NZE: "NZX",
  DSE: "Damascus",
  JPTSE: "Tokyo",
  MUS: "Muscat",
  CIS: "NYSE National",
  PSE: "Philippines",
  PHS: "Philippines",
  OSL: "Oslo",
  LIS: "Lisbon",
  SHG: "Shanghai",
  MNT: "Montevideo",
  TUN: "Tunis",
  BAB: "Barbados",
  BEY: "Beirut",
  INMCX: "MSE",
  MEX: "Mexico",
  INR: "Finra",
  PRA: "Prague",
  TEH: "Tehran",
  HSTC: "Hanoi",
  DAR: "Dar Es Salaam",
  PLU: "Aquis",
  NEC: "NSX",
  WAR: "Warsaw",
  NYS: "NYSE",
  KAZ: "Kazakhstan",
  SPS: "South Pacific",
  VPA: "BVPASA",
  SCSSE: "Merj",
  PFTS: "PFTS",
  LON: "London",
  HEL: "Helsinki",
  HKG: "Hong Kong",
  CNSZHK: "Shenzhen - Shenzhen - Hong Kong",
  CHG: "Chittagong",
  MOL: "Moldova",
  SWA: "Swaziland",
  BCSE: "Belarus",
  SVA: "El Salvador",
  PAR: "Paris",
  AUSSX: "SSX",
  NEOE: "NEO, Canada",
  MUN: "Munich",
  ZIM: "Zimbabwe",
  AMFI: "AMFI",
  EUFUND: "Europe Fund",
  MI: "Milan",
  JSE: "Johannesburg",
  INDX: "Saudi"
};

export const countryCodeToCurrencyCodeMap = {
  NZ: "NZD",
  CK: "NZD",
  NU: "NZD",
  PN: "NZD",
  TK: "NZD",
  AU: "AUD",
  CX: "AUD",
  CC: "AUD",
  HM: "AUD",
  KI: "AUD",
  NR: "AUD",
  NF: "AUD",
  TV: "AUD",
  AS: "EUR",
  AD: "EUR",
  AT: "EUR",
  BE: "EUR",
  FI: "EUR",
  FR: "EUR",
  GF: "EUR",
  TF: "EUR",
  DE: "EUR",
  GR: "EUR",
  GP: "EUR",
  IE: "EUR",
  IT: "EUR",
  LU: "EUR",
  MQ: "EUR",
  YT: "EUR",
  MC: "EUR",
  NL: "EUR",
  PT: "EUR",
  RE: "EUR",
  WS: "EUR",
  SM: "EUR",
  SI: "EUR",
  ES: "EUR",
  VA: "EUR",
  GS: "GBP",
  GB: "GBP",
  JE: "GBP",
  IO: "USD",
  GU: "USD",
  MH: "USD",
  FM: "USD",
  MP: "USD",
  PW: "USD",
  PR: "USD",
  TC: "USD",
  US: "USD",
  UM: "USD",
  VG: "USD",
  VI: "USD",
  HK: "HKD",
  CA: "CAD",
  JP: "JPY",
  AF: "AFN",
  AL: "ALL",
  DZ: "DZD",
  AI: "XCD",
  AG: "XCD",
  DM: "XCD",
  GD: "XCD",
  MS: "XCD",
  KN: "XCD",
  LC: "XCD",
  VC: "XCD",
  AR: "ARS",
  AM: "AMD",
  AW: "ANG",
  AN: "ANG",
  AZ: "AZN",
  BS: "BSD",
  BH: "BHD",
  BD: "BDT",
  BB: "BBD",
  BY: "BYR",
  BZ: "BZD",
  BJ: "XOF",
  BF: "XOF",
  GW: "XOF",
  CI: "XOF",
  ML: "XOF",
  NE: "XOF",
  SN: "XOF",
  TG: "XOF",
  BM: "BMD",
  BT: "INR",
  IN: "INR",
  BO: "BOB",
  BW: "BWP",
  BV: "NOK",
  NO: "NOK",
  SJ: "NOK",
  BR: "BRL",
  BN: "BND",
  BG: "BGN",
  BI: "BIF",
  KH: "KHR",
  CM: "XAF",
  CF: "XAF",
  TD: "XAF",
  CG: "XAF",
  GQ: "XAF",
  GA: "XAF",
  CV: "CVE",
  KY: "KYD",
  CL: "CLP",
  CN: "CNY",
  CO: "COP",
  KM: "KMF",
  CR: "CRC",
  HR: "HRK",
  CU: "CUP",
  CY: "CYP",
  CZ: "CZK",
  DK: "DKK",
  FO: "DKK",
  GL: "DKK",
  DJ: "DJF",
  DO: "DOP",
  TP: "IDR",
  ID: "IDR",
  EC: "ECS",
  EG: "EGP",
  SV: "SVC",
  ER: "ETB",
  ET: "ETB",
  EE: "EEK",
  FK: "FKP",
  FJ: "FJD",
  PF: "XPF",
  NC: "XPF",
  WF: "XPF",
  GM: "GMD",
  GE: "GEL",
  GI: "GIP",
  GT: "GTQ",
  GN: "GNF",
  GY: "GYD",
  HT: "HTG",
  HN: "HNL",
  HU: "HUF",
  IS: "ISK",
  IR: "IRR",
  IQ: "IQD",
  IL: "ILS",
  JM: "JMD",
  JO: "JOD",
  KZ: "KZT",
  KE: "KES",
  KP: "KPW",
  KR: "KRW",
  KW: "KWD",
  KG: "KGS",
  LV: "LVL",
  LB: "LBP",
  LS: "LSL",
  LR: "LRD",
  LY: "LYD",
  LI: "CHF",
  CH: "CHF",
  LT: "LTL",
  MK: "MKD",
  MG: "MGA",
  MW: "MWK",
  MY: "MYR",
  MV: "MVR",
  MT: "MTL",
  MR: "MRO",
  MU: "MUR",
  MX: "MXN",
  MD: "MDL",
  MN: "MNT",
  MA: "MAD",
  EH: "MAD",
  MZ: "MZN",
  MM: "MMK",
  NA: "NAD",
  NP: "NPR",
  NI: "NIO",
  NG: "NGN",
  OM: "OMR",
  PK: "PKR",
  PA: "PAB",
  PG: "PGK",
  PY: "PYG",
  PE: "PEN",
  PH: "PHP",
  PL: "PLN",
  QA: "QAR",
  RO: "RON",
  RU: "RUB",
  RW: "RWF",
  ST: "STD",
  SA: "SAR",
  SC: "SCR",
  SL: "SLL",
  SG: "SGD",
  SK: "SKK",
  SB: "SBD",
  SO: "SOS",
  ZA: "ZAR",
  LK: "LKR",
  SD: "SDG",
  SR: "SRD",
  SZ: "SZL",
  SE: "SEK",
  SY: "SYP",
  TW: "TWD",
  TJ: "TJS",
  TZ: "TZS",
  TH: "THB",
  TO: "TOP",
  TT: "TTD",
  TN: "TND",
  TR: "TRY",
  TM: "TMT",
  UG: "UGX",
  UA: "UAH",
  AE: "AED",
  UY: "UYU",
  UZ: "UZS",
  VU: "VUV",
  VE: "VEF",
  VN: "VND",
  YE: "YER",
  ZM: "ZMK",
  ZW: "ZWD",
  AX: "EUR",
  AO: "AOA",
  AQ: "AQD",
  BA: "BAM",
  GH: "GHS",
  GG: "GGP",
  IM: "GBP",
  LA: "LAK",
  MO: "MOP",
  ME: "EUR",
  PS: "JOD",
  BL: "EUR",
  SH: "GBP",
  MF: "ANG",
  PM: "EUR",
  RS: "RSD",
  USAF: "USD"
};

export const tickersPersistTransform = () => {
  return createWhitelistFilter("tickers", ["tickerData", "lastTickerDataUpdateTs"]);
};

export const expandTickerKeys = ticker => {
  const tickerWithExpandedKeys = {
    id: ticker.i,
    type: ticker.t,
    code: ticker.c,
    shortName: ticker.s,
    name: ticker.n,
    symbol: ticker.y,
    popular: ticker.p,
    sortKey: ticker.o,
    market: ticker.m,
    subType: ticker.e,
    currency: ticker.u,
    countryCode: ticker.cc
  };
  return tickerWithExpandedKeys;
};

export function tickerReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TICKER_DATA: {
      const data = action.tickerData;

      data.supported = data.supported.map(ticker => {
        return expandTickerKeys(ticker);
      });

      if (state.tickerData) {
        const localTickers = state.tickerData.supported.filter(ticker => ticker.isLocal === true);
        for (const ticker of localTickers) {
          if (!data.supported.find(temp => temp.id === ticker.id) === true) {
            data.supported.push(ticker);
          }
        }
        data.exchange.rates = { ...state.tickerData.exchange.rates, ...data.exchange.rates };
      }

      const newState = {
        ...state,
        tickerData: data,
        lastTickerDataUpdateTs: Math.floor(new Date().getTime() / 1000)
      };

      const tickerMaps = getMapsForSupportedTickers(data.supported, data.defaults);
      newState.tickerIdMap = tickerMaps.idMap || initialState.tickerIdMap;
      newState.tickerShortNameMap = tickerMaps.shortNameMap || initialState.tickerShortNameMap;
      newState.tickerSymbolMap = tickerMaps.symbolMap || initialState.tickerSymbolMap;
      return newState;
    }
    case ADD_TICKER_INFO: {
      const newState = { ...state };
      const tickerInfo = {};
      Object.entries(action.tickerInfo).forEach(([key, value]) => {
        if (value !== undefined) {
          tickerInfo[key] = value;
        } else if (newState.tickerIdMap[tickerInfo.id]) {
          tickerInfo[key] = newState.tickerIdMap[tickerInfo.id][key];
        }
      });
      tickerInfo.isLocal = true;
      if (!newState.tickerData.supported.find(temp => temp.id === tickerInfo.id) === true) {
        newState.tickerData.supported.push(tickerInfo);
      }
      if (!action.date === false) {
        const dateString = getDateStringForExchangeRateDate(action.date);
        newState.tickerData.exchange.rates[`${action.tickerInfo.shortName}-${dateString}`] = action.exchangeRate;
      } else {
        newState.tickerData.exchange.rates[action.tickerInfo.shortName] = action.exchangeRate;
      }
      newState.tickerIdMap[tickerInfo.id] = tickerInfo;
      newState.tickerShortNameMap[tickerInfo.shortName] = tickerInfo;
      return newState;
    }
    case PURGE: {
      tickersRehydratedPromise.resolve();
      return state;
    }
    case REHYDRATE: {
      tickersRehydratedPromise.resolve();
      if (!action.payload === true) {
        return state;
      }
      const newState = action.payload.tickers;
      if (newState.tickerData) {
        const supportedTickers = supportedTickersSelector(action.payload);
        const currencyDefaults = currencyDefaultsSelector(action.payload);

        const tickerMaps = getMapsForSupportedTickers(supportedTickers, currencyDefaults);
        newState.tickerIdMap = tickerMaps.idMap || initialState.tickerIdMap;
        newState.tickerShortNameMap = tickerMaps.shortNameMap || initialState.tickerShortNameMap;
        newState.tickerSymbolMap = tickerMaps.symbolMap || initialState.tickerSymbolMap;
      }

      return newState;
    }
    default:
      return state;
  }
}

const getMapsForSupportedTickers = (supportedTickers, currencyDefaults) => {
  var idMap = {};
  var shortNameMap = {};
  var symbolMap = {};

  for (const ticker of supportedTickers) {
    idMap[ticker.id] = ticker;
    shortNameMap[ticker.shortName] = ticker;

    if (ticker.symbol && Array.isArray(ticker.symbol)) {
      for (const symbol of ticker.symbol) {
        if (!currencyDefaults[symbol] === true || currencyDefaults[symbol] === ticker.shortName) {
          symbolMap[symbol] = ticker;
        }
      }
    }
  }

  return { idMap, shortNameMap, symbolMap };
};

export const tickerDataLastUpdateTsSelector = state => {
  return state.tickers.lastTickerDataUpdateTs;
};

export const supportedTickersSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.supported || []
);

export const supportedTickerSymbolMapSelector = state => {
  return state.tickers.tickerSymbolMap || initialState.tickerSymbolMap;
};

export const cryptoTickerShortNamesSelector = state => {
  const supportedTickers = supportedTickersSelector(state);
  return supportedTickers.filter(ticker => ticker.type === tickerTypes.CRYPTO).map(ticker => ticker.shortName);
};

export const defaultCurrencySelector = state => state.tickers.defaultCurrency;

const defaultsAltObj = {};
export const currencyDefaultsSelector = state => state.tickers.tickerData?.defaults || defaultsAltObj;

export const popularTickersSelector = state => {
  const supportedTickers = supportedTickersSelector(state);
  const popularTickers = supportedTickers
    .filter(ticker => ticker.popular === 1)
    .sort((a, b) => (!a.sortKey === true ? 1 : a.sortKey.localeCompare(b.sortKey)));

  return popularTickers;
};

export const nonPopularTickersSelector = state => {
  const supportedTickers = supportedTickersSelector(state);
  const nonPopularTickers = supportedTickers
    .filter(ticker => ticker.popular === 0)
    .sort((a, b) => (!a.sortKey === true ? 1 : a.sortKey.localeCompare(b.sortKey)));
  return nonPopularTickers;
};

export const supportedIndicesSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.supportedIndices || []
);

export const supportedIndicesIrrSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.supportedIndicesIrr || []
);

export const assetClassesListSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.assetClasses || []
);

export const stockMarketCapListSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.stockMarkeCapList || []
);

export const stockSectorListSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.stockSectorList || []
);

export const stockRegionListSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.worldRegionList || []
);
