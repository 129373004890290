import React from "react";
import styled from "styled-components";

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 50%;
`;

const Badge = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: red;
  width: 8px;
  height: 8px;
`;

class GlowingBadgeTip extends React.Component {
  render() {
    const { className, ...other } = this.props;

    return (
      <BadgeContainer {...other} className={className}>
        <Badge />
      </BadgeContainer>
    );
  }
}

export default GlowingBadgeTip;
