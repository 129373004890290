import { hashParams, modalValues } from "routes";

export const accountSettingsTabs = {
  ACCOUNT: "account",
  SUBSCRIPTION: "subscription",
  MULTI_USER: "multi_user",
  NOTIFICATIONS: "notifications",
  API_ACCESS: "api_access",
  SECURITY: "security",
  GENERAL: "general",
  MANAGE_USERS: "manage_users",
  ADMIN_CLIENT_BILLING: "admin_client_billing",
  CLIENT_BILLING: "client_billing",
  CONNECTION_STATUS: "connection_status"
};

const staticMethods = {
  show: (history, location, selectedTab = accountSettingsTabs.ACCOUNT, action) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.ACCOUNT_SETTINGS}&${hashParams.TAB}=${selectedTab}${
        action ? `&${hashParams.ACTION}=${action}` : ""
      }`
    });
  }
};

export default staticMethods;
