import React, { useState } from "react";
import { withRouter } from "@kubera/common";

import { signInWithEmailPassword } from "@kubera/common";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import VerifyScreen from "components/twoFA/VerifyScreen";
import VerifySignInInternal from "components/twoFA/VerifySignInInternal";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";

const TwoFAVerify = ({
  isOpen = false,
  onDismiss = () => null,
  onSuccess = () => null,
  onVerifyClose = () => null,
  ...props
}) => {
  const [signInUser, setSignInUser] = useState(null);
  const [signInCreds, setSignInCreds] = useState(null);

  const onVerifySuccess = async (data, credentials) => {
    setSignInCreds(credentials);
    // if (data.challengeName === "SMS_MFA" || data.challengeName === "SOFTWARE_TOKEN_MFA") {
    //   setSignInUser(data);
    // } else {
    onSuccess();
    onDismiss();
    // }
  };

  const handleSuccessfulSignIn = () => {
    onSuccess();
    onDismiss();
  };

  const goBackFromVerify = () => {
    setSignInUser(null);
  };

  const onResend = () => {
    signInWithEmailPassword(
      signInCreds.email,
      signInCreds.password,
      data => {
        setSignInUser(data);
        onDismiss();
      },
      () => null
    );
  };

  const onVerifyCancel = () => {
    AccountSettingsComponentExports.show(props.history, props.location, accountSettingsTabs.ACCOUNT);
    onVerifyClose();
  };

  if (!isOpen) {
    return null;
  }

  if (signInUser) {
    return (
      <DialogOverlay>
        <VerifySignInInternal
          user={signInUser}
          onSuccess={handleSuccessfulSignIn}
          onGoBack={goBackFromVerify}
          onResend={onResend}
          email={signInCreds.email}
          password={signInCreds.password}
        />
      </DialogOverlay>
    );
  }

  return (
    <DialogOverlay onDismiss={onVerifyClose}>
      <VerifyScreen onVerifySuccess={onVerifySuccess} onCancel={onVerifyCancel} />
    </DialogOverlay>
  );
};

export default withRouter(TwoFAVerify);
