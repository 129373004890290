import React from "react";
import styled, { css } from "styled-components";
import { getMonthFromDate, getStringFromBytes, ApiClient } from "@kubera/common";
import { ReactComponent as OptionsIcon } from "assets/images/document_options_icon.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import RenameDocumentComponent from "components/documents/RenameDocumentComponent";
import documentErrorIcon from "assets/images/document_error_icon.svg";
import i18n from "locale/i18n";

const documentCategoryType = {
  OTHERS: "OTHERS",
  PDF: "PDF",
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  MOVIE: "MOVIE",
  PRESENTATION: "PRESENTATION",
  MUSIC: "MUSIC",
  COMPRESSED: "COMPRESSED",
  SPREADSHEET: "SPREADSHEET",
  FOLDER: "FOLDER"
};

const defaultDocumentCategory = {
  type: documentCategoryType.OTHERS,
  icon_url: `${process.env.PUBLIC_URL}/images/document_types/other_files.png`,
  compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/other_files_compact.png`,
  color: "#717D84"
};

const folderDocumentCategory = {
  type: documentCategoryType.FOLDER,
  icon_url: `${process.env.PUBLIC_URL}/images/document_types/folder.png`,
  compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/folder.png`,
  color: "#ffca28"
};

export const documentCategories = [
  {
    type: documentCategoryType.PDF,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/pdf.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/pdf_compact.png`,
    color: "#E85E60",
    extensions: ["pdf"]
  },
  {
    type: documentCategoryType.IMAGE,
    icon_url: null,
    compact_icon_url: null,
    color: "#313131",
    extensions: ["jpg", "jpeg", "png", "ai", "bmp", "gif", "ico", "ps", "psd", "svg", "tif", "tiff"]
  },
  {
    type: documentCategoryType.DOCUMENT,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/document.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/document_compact.png`,
    color: "#4488C7",
    extensions: ["doc", "docx", "odt", "rtf", "tex", "txt", "wks", "wps", "wpd"]
  },
  {
    type: documentCategoryType.MOVIE,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/movie.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/movie_compact.png`,
    color: "#C5A943",
    extensions: ["mov", "avi", "flv", "h264", "m4v", "mkv", "mp4", "mpg", "mpeg", "rm", "swf", "vob", "wmv"]
  },
  {
    type: documentCategoryType.PRESENTATION,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/presentation.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/presentation_compact.png`,
    color: "#CB4E37",
    extensions: ["key", "odp", "pps", "ppt", "pptx"]
  },
  {
    type: documentCategoryType.MUSIC,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/music.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/music_compact.png`,
    color: "#77628E",
    extensions: ["mp3", "mpa", "aif", "cda", "mid", "midi", "ogg", "wav", "wma", "wpl"]
  },
  {
    type: documentCategoryType.COMPRESSED,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/compressed.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/compressed_compact.png`,
    color: "#59A592",
    extensions: ["zip", "7z", "arj", "deb", "pkg", "rar", "rpm", "gz", "z", "tar"]
  },
  {
    type: documentCategoryType.SPREADSHEET,
    icon_url: `${process.env.PUBLIC_URL}/images/document_types/spreadsheet.png`,
    compact_icon_url: `${process.env.PUBLIC_URL}/images/document_types/spreadsheet_compact.png`,
    color: "#EA8929",
    extensions: ["xls", "ods", "xlr", "xlsx"]
  }
];

const Container = styled.div`
  display: flex;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  outline: ${props => (props.isImage === true ? "1px solid rgba(0, 0, 0, 0.4)" : "none")};
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${props => (props.isImage === true ? props.backgroundColor : "transparent")};
  cursor: pointer;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    visibility: ${props => (props.isImageLoaded === true ? "visible" : "hidden")};

    ${Container}:hover & {
      background-color: ${props => (props.isImage === true ? "rgba(0, 0, 0, 0.7)" : "transparent")};
    }
  }
`;

const LoaderOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  width: 100%;
  height: ${props => 100 - props.percentage + "%"}
  top: 0;
  position: absolute;
  transition: ${props =>
    props.uploadFailed === true ? "" : props.percentage <= 60 ? "height 1s linear" : "height 10s linear"};
`;

const ImageOverlay = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  visibility: ${props => (props.isImageLoaded === true ? "visible" : "hidden")};
  object-fit: cover;
`;

const DetailsContainer = styled.div`
  padding: ${({ isFolder }) => (!isFolder ? "8px 12px 14px 10px" : "8px 12px 14px")};
  display: flex;
  align-items: flex-end;
  background-color: transparent
  margin-top: 10px; 
  visibility: ${props => (props.isImageLoaded === true ? "hidden" : "visible")}

  ${Container}:hover & {
    visibility: visible;
    z-index: 1;
  }
`;

const DocumentTextDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OptionsIconWrapper = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.isCompactCell === true ? `${14}px` : `${34}px`)};
  min-width: ${props => (props.isCompactCell === true ? `${14}px` : `${34}px`)};
  height: ${props => (props.isCompactCell === true ? `${24}px` : `${34}px`)};
  padding-right: ${props => (props.isCompactCell === true ? `${0}px` : `${5}px`)};
  margin-right: -10px;
  margin-bottom: ${props => (props.isCompactCell === true ? `${-2}px` : `${-5}px`)};
  background-color: ${props => (props.isImage === true ? "transparent" : props.backgroundColor)};

  ${({ isFolder }) =>
    isFolder &&
    css`
      position: absolute;
      bottom: 14px;
      right: 12px;
    `}

  ${props =>
    props.disableOptions === false &&
    css`
      ${Container}:hover & {
        visibility: visible;
        cursor: pointer;
      }
    `}

  path {
    fill: ${props => (props.isFolder ? "rgba(0, 0, 0, 0.4)" : "#FFF")};
  }
`;

const DocumentErrorIcon = styled.div`
  position: absolute;
  right: ${props => (props.isCompactCell === true ? `${8}px` : `${14}px`)};
  bottom: ${props => (props.isCompactCell === true ? `${10}px` : `${12}px`)};
  width: ${props => (props.isCompactCell === true ? `${24}px` : `${32}px`)};
  min-width: ${props => (props.isCompactCell === true ? `${24}px` : `${32}px`)};
  height: ${props => (props.isCompactCell === true ? `${24}px` : `${32}px`)};
  background-repeat: no-repeat;
  background-image: url(${documentErrorIcon});
  background-position: center;
  background-color: transparent;
  background-size: cover;
  z-index: 1001;
  cursor: pointer;
`;

const DocumentName = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  color: ${props => (props.isFolder ? "#000" : "#fff")};
  margin-bottom: 8px;
  text-align: left;
  white-space: pre-line;
  hyphens: auto;
  max-height: ${props => `${props.size - 52}px`};
  overflow: hidden;
  ${props => props.propname} : ${props => props.splitprop};

  &::after {
    position: absolute;
    content: "...";
    right: 2px;
    top: ${props => `${props.size - 52}px`};
    background: ${props => props.background};
    background-color: ${props => (props.isImage === true ? props.backgroundColor : "transparent")};
    width: 17px;
    text-align: right;
    letter-spacing: 2px;
    transform: translateY(-12px);
  }

  &::before {
    content: " ";
    position: absolute;
    right: 0;
    top: ${props => `${props.size - 52}px`};
    background: ${props => props.background};
    background-color: ${props => (props.isImage === true ? props.backgroundColor : "transparent")};
    width:  100%;
    height: 50px;
  }

  ${({ isFolder }) =>
    isFolder &&
    css`
      max-height: ${props => `${props.size - 77}px`};

      &::after {
        top: ${props => `${props.size - 77}px`};
      }

      &::before {
        top: ${props => `${props.size - 77}px`};
      }
    `}
`;

const DocumentMetaData = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-align: left;
  white-space: pre-wrap;

  color: ${({ isFolder }) => (isFolder ? "rgba(0, 0, 0, 0.7)" : null)};
`;

class DocumentsGridComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isRenaming: false, isImageLoaded: false, thumbnail: this.getThumbnail() };
    this.contextMenuRef = React.createRef();
    this.containerRef = React.createRef();

    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleRenameDialogDismiss = this.handleRenameDialogDismiss.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDocumentRename = this.handleDocumentRename.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleRetryDocumentUpload = this.handleRetryDocumentUpload.bind(this);
  }

  componentDidMount() {
    this.containerRef.current.addEventListener("contextmenu", this.handleRightClick, false);
    this.preloadIcons();
  }

  componentWillUnmount() {
    this.containerRef.current.removeEventListener("contextmenu", this.handleRightClick, false);
  }

  preloadIcons() {
    const icons = [documentErrorIcon];

    for (const icon of icons) {
      const img = new Image();
      if (icon) {
        img.src = icon;
      }
    }
  }

  getContextMenuItems() {
    if (this.props.isReadOnly === true) {
      return [[contextMenuItemType.DOWNLOAD]];
    }
    if (this.props.document.fileType === "custodian") {
      return [[contextMenuItemType.DOWNLOAD]];
    }
    return [[contextMenuItemType.RENAME, contextMenuItemType.DOWNLOAD, contextMenuItemType.REMOVE]];
  }

  handleRightClick(e) {
    if (this.props.disableOptions === true) {
      return;
    }
    if (!this.containerRef) {
      return;
    }
    if (this.containerRef.current.contains(e.target) === false) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    e.preventDefault();
    this.contextMenuRef.current.show(this.getContextMenuItems(), e.clientX, e.clientY, true, null);
  }

  handleOptionsClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();

    this.contextMenuRef.current.show(
      this.getContextMenuItems(),
      targetPosition.left,
      targetPosition.top,
      true,
      e.target
    );
    e.stopPropagation();
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.RENAME.id) {
      this.setState({ ...this.state, isRenaming: true });
    } else if (item.id === contextMenuItemType.REMOVE.id) {
      if (this.props.onDeleteDocuments) {
        this.props.onDeleteDocuments([this.props.document]);
      }
    } else if (item.id === contextMenuItemType.DOWNLOAD.id) {
      if (this.props.onDownloadDocuments) {
        this.props.onDownloadDocuments([this.props.document]);
      }
    }
  }

  handleDocumentClick(e) {
    if (e.metaKey === true || e.ctrlKey === true) {
      this.props.onDocumentSelection(e, this.props.document);
      return;
    }
    if (this.props.onClick) {
      this.props.onClick(e);
      return;
    }

    const documentCategory = this.getDocumentCategory();

    if (documentCategory.type === documentCategoryType.FOLDER && this.props.handleFolderClick) {
      this.props.handleFolderClick(this.props.document);
      return;
    }

    if (this.isPreviewable(documentCategory)) {
      ApiClient.getFileDownloadUrl(this.props.document, true, false)
        .then(url => {
          window.kuberaOpen(url);
        })
        .catch(apiError => {});
    } else {
      // download if the file type is not previewable
      this.props.onDownloadDocuments([this.props.document]);
    }
  }

  isPreviewable(documentCategory) {
    if (
      documentCategory.type === documentCategoryType.PDF ||
      documentCategory.type === documentCategoryType.IMAGE ||
      !this.props.onClick === false
    ) {
      return true;
    }
    return false;
  }

  getDocumentName() {
    const extensionIndex = this.props.document.name.lastIndexOf(".");
    if (extensionIndex === -1 && extensionIndex !== this.props.document.name.length - 1) {
      return this.props.document.name;
    }
    return this.props.document.name.substring(0, extensionIndex);
  }

  getDocumentExtension() {
    const extensionIndex = this.props.document.name.lastIndexOf(".");
    if (extensionIndex === -1 && extensionIndex !== this.props.document.name.length - 1) {
      return "";
    }
    return this.props.document.name.substring(extensionIndex + 1, this.props.document.name.length).toLowerCase();
  }

  getDocumentIcon(category) {
    if (category) {
      return this.props.compactMode === true ? category.compact_icon_url : category.icon_url;
    }
    return null;
  }

  getDocumentCategory() {
    const extension = this.getDocumentExtension();

    if (extension !== null) {
      for (const category of documentCategories) {
        if (category.extensions.includes(extension)) {
          return category;
        }
      }
    }

    if (this.isFolder()) {
      return folderDocumentCategory;
    }

    return defaultDocumentCategory;
  }

  isImage() {
    const fileExtension = this.getDocumentExtension();
    const imageDocumentCategory = documentCategories.find(category => category.type === documentCategoryType.IMAGE);
    const isImage = imageDocumentCategory.extensions.includes(fileExtension);
    return isImage;
  }

  isFolder() {
    return this.props.document.fileType === "folder" || this.props.document.fileType === "custodian";
  }

  getLastModifiedDateString() {
    const lastModified = this.props.document.tsModified * 1000;
    const date = lastModified > new Date().getTime() ? new Date() : new Date(lastModified);

    if (new Date().getTime() - date.getTime() > 365 * 24 * 60 * 60 * 1000) {
      return date.getFullYear();
    } else {
      return date.getDate() + " " + getMonthFromDate(date);
    }
  }

  getDocumentDetails = () => {
    if (this.isFolder()) {
      const { filesCount, category } = this.props.document;
      const fileOrFiles = i18n.t(filesCount && filesCount > 1 ? "files" : "file").toUpperCase();

      return category
        ? `${category}\n${filesCount} ${fileOrFiles}`
        : filesCount
        ? `${filesCount} ${fileOrFiles}`
        : i18n.t("empty").toUpperCase();
    }

    const fileExtension = this.getDocumentExtension();
    const fileSize = getStringFromBytes(this.props.document.size);
    const fileLastModified = this.getLastModifiedDateString();
    return `${fileExtension}\n${fileSize} • ${fileLastModified}`;
  };

  getThumbnail() {
    const doc = this.props.document;

    if (doc.thumbnail) {
      return doc.thumbnail;
    } else if (doc.thumbnailToken) {
      ApiClient.getFileDownloadUrl(doc, true, true)
        .then(url => {
          const img = new Image();
          img.src = url;

          this.setState({ thumbnail: url });
        })
        .catch(apiError => {});
    }
    return "";
  }

  handleRenameDialogDismiss() {
    this.setState({ ...this.state, isRenaming: false });
  }

  handleDocumentRename(newName) {
    const updatedDocument = this.props.document;
    const extension = this.getDocumentExtension();
    updatedDocument.name = newName + (extension ? "." + extension : "");
    this.props.onDocumentRename(updatedDocument);
  }

  handleRetryDocumentUpload(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onRetryDocumentUpload) {
      this.props.onRetryDocumentUpload(this.props.document);
    }
  }

  handleImageLoad() {
    this.setState({ ...this.state, isImageLoaded: true });
  }

  handleImageError() {
    this.setState({ ...this.state, isImageLoaded: false });
  }

  render() {
    const shouldMaskDetails = this.props.maskThumbnail;
    const documentCategory = this.getDocumentCategory();
    const name = shouldMaskDetails ? "xxxx" : this.getDocumentName();
    const words = name ? name.split(/\s+/) : [];
    let propname = "word-wrap";
    let splitprop = "break-word";
    words.forEach(word => {
      if (word.length > 11) {
        propname = "word-break";
        splitprop = "break-all";
      }
    });
    const details = this.getDocumentDetails();
    const iconUrl = this.getDocumentIcon(documentCategory);
    const isImage = this.isImage();
    const isFolder = this.isFolder();
    const isImageLoaded = this.state.isImageLoaded;
    const uploadPercentage = this.props.document.uploadProgress;
    const thumbnail = this.state.thumbnail;
    const isRenaming = this.state.isRenaming;
    const isCompactCell = this.props.compactMode === true;
    const error = this.props.document.uploadError;

    return (
      <Container data-private className={this.props.className}>
        <ContentContainer
          isCompactCell={isCompactCell}
          isImageLoaded={isImageLoaded}
          isImage={isImage}
          backgroundColor={documentCategory.color}
          background={iconUrl}
          onClick={this.handleDocumentClick}
          isPreviewable={this.isPreviewable(documentCategory)}
          ref={this.containerRef}
        >
          <DetailsContainer isImage={isImage} isFolder={isFolder}>
            <DocumentTextDetails>
              <DocumentName
                data-cy="documentName"
                propname={propname}
                splitprop={splitprop}
                background={`url("${iconUrl}")`}
                isImage={isImage}
                backgroundColor={documentCategory.color}
                size={isFolder ? this.props.size - 25 : this.props.size}
                isFolder={isFolder}
              >
                {" "}
                {name}
              </DocumentName>
              <DocumentMetaData isFolder={isFolder}>{details}</DocumentMetaData>
            </DocumentTextDetails>
            <OptionsIconWrapper
              disableOptions={this.props.disableOptions === true}
              isCompactCell={isCompactCell}
              data-cy="optionsIcon"
              onClick={e => this.handleOptionsClick(e)}
              isImage={isImage}
              isFolder={isFolder}
              backgroundColor={documentCategory.color}
            >
              <OptionsIcon />
            </OptionsIconWrapper>
          </DetailsContainer>
          {isImage === true && thumbnail && shouldMaskDetails === false && (
            <ImageOverlay
              src={thumbnail}
              isImageLoaded={isImageLoaded}
              onLoad={this.handleImageLoad}
              onError={this.handleImageError}
            />
          )}
          <LoaderOverlay uploadFailed={error !== null} percentage={uploadPercentage}></LoaderOverlay>
          {error && <DocumentErrorIcon isCompactCell={isCompactCell} onClick={this.handleRetryDocumentUpload} />}
        </ContentContainer>
        <ContextMenu ref={this.contextMenuRef} onSelection={this.handleContextMenuSelection} />
        {isRenaming === true && (
          <RenameDocumentComponent
            name={name}
            onChange={this.handleDocumentRename}
            onDismiss={this.handleRenameDialogDismiss}
            isFolder={isFolder}
          />
        )}
      </Container>
    );
  }
}

export default DocumentsGridComponent;
