import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatNumberWithCurrency, shortFormatNumberWithCurrency, isCryptoCurrency } from "@kubera/common";

import ScrollCounter from "components/scroll_counter/ScrollCounter";

import LabelUpdateHandler from "./LabelUpdateHandler";

const Label = styled.div`
  visibility: ${props => (props.visible === true ? "unset" : "hidden")};
`;

const LabelUpdateHandlerComponent = styled(LabelUpdateHandler)`
  left: unset;
  ${props => props.alignPosition || "right"}: 0;
`;

class CurrencyLabel extends React.Component {
  static defaultProps = {
    maxLongFormatValue: null,
    hideEllipsis: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isValueVisible: !this.props.hideEllipsis === true
    };

    this.currencyLabelRef = React.createRef();
  }

  hideEllipsisIfNeeded() {
    if (!this.props.hideEllipsis === true) {
      return;
    }

    setTimeout(() => {
      if (!this.currencyLabelRef === true || !this.currencyLabelRef.current === true) {
        return;
      }

      const label = this.currencyLabelRef.current;
      if (label.scrollWidth > label.offsetWidth) {
        label.innerHTML = shortFormatNumberWithCurrency(
          this.getApplicableValue(),
          this.props.currency,
          this.props.abbreviate,
          true
        );
      }
      if (!this.state.isValueVisible) {
        this.setState({ isValueVisible: true });
      }
    }, 0);
  }

  getApplicableValue() {
    const value = this.props.value;

    if (isCryptoCurrency(this.props.currency)) {
      return value;
    }
    return this.props.roundDown ? (value && value > 0 && value < 1 ? value : Math.kuberaFloor(value)) : value;
  }

  getValueString(value, currency, showCurrencyAsSymbol) {
    const applicableValue = this.getApplicableValue();
    if (!this.props.maxLongFormatValue === false && Math.abs(applicableValue) > this.props.maxLongFormatValue) {
      return shortFormatNumberWithCurrency(applicableValue, currency, this.props.abbreviate, true);
    }
    return formatNumberWithCurrency(
      applicableValue,
      currency,
      showCurrencyAsSymbol,
      isCryptoCurrency(currency) ? 4 : undefined
    );
  }

  render() {
    const {
      className,
      value,
      currency,
      roundDown,
      showCurrencyAsSymbol,
      hideEllipsis,
      height = "44px",
      fontSize = 36,
      isAnimationAllowed = false,
      ...other
    } = this.props;
    const valueString = this.getValueString(value, currency, showCurrencyAsSymbol);
    const isValueVisible = this.state.isValueVisible;

    this.hideEllipsisIfNeeded();

    return (
      <Label {...other} className={className} ref={this.currencyLabelRef} visible={isValueVisible}>
        {!isAnimationAllowed ? (
          valueString
        ) : !this.props.useScrollAnimation ? (
          <LabelUpdateHandlerComponent
            value={valueString}
            preventAnimation={this.props.preventAnimation}
            alignPosition={this.props.alignPosition}
          />
        ) : (
          <ScrollCounter displayValue={valueString} height={height} fontSize={fontSize} />
        )}
      </Label>
    );
  }
}

CurrencyLabel.defaultProps = {
  abbreviate: true
};

CurrencyLabel.propTypes = {
  abbreviate: PropTypes.bool
};

export default CurrencyLabel;
