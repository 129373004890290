import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import { withRouter, showToastTip, ApiClient, currentPortfolioSelector } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { downloadFile } from "utilities/FileUtils";

const ChatbotsDialog = styled(Dialog)`
  width: 707px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 60px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.015em;
  text-align: left;
  white-space: pre-wrap;
`;

const Description = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  text-align: left;
`;

const PositiveButton = styled(PrimaryButton)`
  margin-top: 25px
  margin-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
`;

const Examples = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  ul {
    padding-inline-start: 20px;
    margin-top: 15px;
  }

  li {
    margin-bottom: 10px;
  }
`;

class DownloadChatbotsDataDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.DOWNLOAD_DATA_CHATBOTS}`
    });
  };

  constructor(props) {
    super(props);

    this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handlePositiveButtonClick();
      return true;
    }
  }

  handlePositiveButtonClick() {
    this.props.showToastTip("TIP", i18n.t("downloadingChatbotsData"), null, 3000);

    ApiClient.getLllmDataDownloadUrl(this.props.currentPortfolio.id)
      .then(apiResponse => {
        downloadFile(apiResponse.payload.url, this.props.currentPortfolio.name);
      })
      .catch(apiError => {
        this.props.showToastTip("TIP", apiError.errorMessage, null, 3000);
      });
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ChatbotsDialog className={this.props.className}>
          <Container>
            <Title>{i18n.t("chatbotsDataDialog.title")}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: i18n.t("chatbotsDataDialog.description")
              }}
            />
            <PositiveButton title={i18n.t("chatbotsDataDialog.button")} onClick={this.handlePositiveButtonClick} />
            <Examples
              dangerouslySetInnerHTML={{
                __html: i18n.t("chatbotsDataDialog.examples")
              }}
            />
          </Container>
        </ChatbotsDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DownloadChatbotsDataDialog));
