import React from "react";
import styled from "styled-components";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import EditableLabel from "components/inputs/EditableLabel";
import CurrencyLabel from "components/labels/CurrencyLabel";

const Container = styled.div``;

const SheetTitleContainer = styled.div`
  display: flex;
`;

const SheetTitle = styled(EditableLabel)`
  padding-bottom: 3px;
  padding-top: 3px;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: ${props =>
    props.isSelected === true ? props.theme.gridSheetTitleSelectedColor : props.theme.gridSheetTitleColor};
`;

const SheetContextMenuButton = styled.div`
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};
  width: 19px;
  min-width: 19px;
  margin-top: 2px;
  height: ${props => (props.isSelected === true ? "19px" : "0px")};
  border-radius: 2px;
  margin-left: 2px;
  cursor: pointer;
  color: red;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0px 0px;
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)});

  ${Container}:hover & {
    background-size: 9px 9px;
  }

  &:hover {
    background-color: ${props => props.theme.contextMenuButtonBackgroundColor};
  }

  background-size: ${props => (props.isSheetsOverflowing || props.isMenuVisible ? "9px 9px" : "")};
  background-color: ${props => (props.isMenuVisible ? props.theme.contextMenuButtonBackgroundColor : "transparent")};
`;

const SheetTotal = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  font-feature-settings: "ss01" on, "liga" off;
  height: 20px;
  margin-right: 19px;
  color: ${props =>
    props.isSelected === true ? props.theme.gridSheetTotalSelectedColor : props.theme.gridSheetTotalColor};

  .child-container {
    justify-content: flex-start;
  }
`;

class GridSheetTitle extends React.PureComponent {
  render() {
    const {
      className,
      isSelected,
      displaySheet,
      displayIndex,
      datacyPrefix,
      isDragging,
      currency,
      isSheetsOverflowing,
      isShowingSheetContextMenu,
      isEditable,
      value,
      sheetTitleRefs,
      onSheetTitleChange,
      onMenuButtonClick,
      ...other
    } = this.props;

    return (
      <Container className={className} {...other}>
        <SheetTitleContainer>
          <SheetTitle
            isSelected={isSelected}
            isClickable={true}
            value={displaySheet.name}
            data-cy={datacyPrefix + "SheetTitle" + displayIndex}
            onChange={value => {
              onSheetTitleChange(displayIndex, value);
            }}
            ref={element => {
              sheetTitleRefs[displayIndex] = element;
            }}
            cursor={isDragging ? "grab" : "pointer"}
          />
          <SheetContextMenuButton
            isSelected={isSelected}
            id={"section_menu_button"}
            data-cy={datacyPrefix + "SheetContextMenuButton" + displayIndex}
            isSheetsOverflowing={isSheetsOverflowing}
            isMenuVisible={isShowingSheetContextMenu}
            isHidden={isEditable === false}
            onClick={onMenuButtonClick}
          />
        </SheetTitleContainer>
        <SheetTotal
          data-cy={"sheetTotal" + displayIndex}
          isSelected={isSelected}
          value={value}
          currency={currency}
          maxLongFormatValue={1}
          abbreviate={false}
          alignPosition="left"
        />
      </Container>
    );
  }
}

export default GridSheetTitle;
