import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { isAppInWhiteLabelMode, siteConfigSelector } from "@kubera/common";

import Logo from "components/labels/AppLogo";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.mobileBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 32px;
`;

const ThankYouTxt = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 30px;
  margin-bottom: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  margin-left: 30px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const MessageBlock = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColor};
  padding: 0 30px;
  margin-bottom: 40px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const BackToHome = styled.a`
  font-size: 13px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColorGrey};
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-left: 30px;
`;

const SignUpThanks = () => {
  const siteConfig = useSelector(siteConfigSelector);

  const siteName = siteConfig ? siteConfig.name : "";

  return (
    <Container>
      <ContentContainer>
        <AppLogo />
        <ThankYouTxt>{i18n.t("mobileNotSupportedComponent.thankYou")}</ThankYouTxt>
        <Title>{i18n.t("headsUpMsg.msg").replace(/%s%/g, siteName)}</Title>
        <MessageBlock
          dangerouslySetInnerHTML={{
            __html: i18n.t("signUpThanks").replace(/%s%/g, window.location.host)
          }}
        />
        {isWhiteLabelledApp === false && (
          <BackToHome href="https://www.kubera.com/?r">Back to Kubera Home Page</BackToHome>
        )}
      </ContentContainer>
    </Container>
  );
};

export default React.memo(SignUpThanks);
