import React, { useState, useEffect, useCallback } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  currentPortfolioSelector,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  setLinkType,
  utilityStatus,
  useHistory
} from "@kubera/common";

import NumberInput from "components/inputs/NumberInput";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const TickerContainer = styled.div`
  margin-bottom: 15px;
`;

const TickerHead = styled.div`
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 150%;
`;

const TickerInput = styled(NumberInput)`
  padding: 18px 14px 15px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const TickersForm = styled.form`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: -35px;
  left: 0;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
`;

const MetalsConnectComponent = props => {
  const { renderSelectedTicker = null } = props;

  const portfolio = useSelector(currentPortfolioSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [selectedTickers, setSelectedTickers] = useState([
    {
      code: "XAU",
      id: 160,
      name: "Gold",
      shortName: "XAU"
    },
    {
      code: "XAG",
      id: 159,
      name: "Silver",
      shortName: "XAG"
    },
    {
      code: "XPD",
      id: 164,
      name: "Palladium",
      shortName: "XPD"
    },
    {
      code: "XPT",
      id: 166,
      name: "Platinum",
      shortName: "XPT"
    }
  ]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const onTickerQuantityChange = (value, index) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    setSelectedTickers(tempSelectedTickers);
  };

  const onRenderSelectedTicker = (ticker, index) => {
    if (!ticker) {
      return null;
    }

    if (renderSelectedTicker) {
      return renderSelectedTicker(ticker);
    } else {
      const { inputVal = "" } = selectedTickers[index];

      return (
        <TickerContainer key={index}>
          <TickerHead>
            <strong>{ticker.name}</strong> {ticker.code}
          </TickerHead>
          <TickerInput
            placeholder={i18n.t("unitInOunce")}
            value={inputVal}
            onChange={e => {
              onTickerQuantityChange(e.target.value, index);
            }}
            required
            autoFocus={index === 0}
          ></TickerInput>
        </TickerContainer>
      );
    }
  };

  const hasInputVal = useCallback(() => {
    return !!selectedTickers.find(ticker => ticker.inputVal);
  }, [selectedTickers]);

  const onTickerSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    let custodianId = (props.nextValues && props.nextValues.custodianId) || props.custodianId;
    let sortKey = props.nextValues && props.nextValues.sortKey;
    let nextValues = void 0;

    selectedTickers.forEach(ticker => {
      if (!ticker.inputVal) {
        return;
      }

      const newCustodian = {
        name: ticker.name,
        value: ticker.inputVal,
        valueTickerId: ticker.id,
        past: 0
      };
      nextValues = dispatch(
        insertTickerCustodianInSection(
          portfolio.id,
          currentCustodian.sectionId,
          custodianId,
          newCustodian,
          sortKey,
          custodian => {
            if (!custodian.id) {
              dispatch(
                utilityStatus({
                  errorMessage: "Failed to link custodian",
                  ...newCustodian,
                  linkType: "metals"
                })
              );
            }
          },
          ticker.shortName
        )
      );

      custodianId = nextValues.custodianId;
      sortKey = nextValues.sortKey;
    });

    dispatch(setLinkType("metals"));
    if (e) {
      DialogOverlay.forceDismiss(history, location);
    } else {
      props.setNextValues(nextValues);
    }
  };

  const saveConfirmModal = () => {
    onTickerSubmit();

    props.onConfirmSave();
    setIsConfirmModalVisible(false);
  };

  const closeConfirmModal = () => {
    props.onConfirmCancel();
    setIsConfirmModalVisible(false);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  const { clickedTab, onConfirmSave } = props;
  useEffect(() => {
    if (clickedTab !== 1 && hasInputVal()) {
      setIsConfirmModalVisible(true);
    } else {
      onConfirmSave();
    }
  }, [clickedTab, onConfirmSave, hasInputVal]);

  if (currentCustodian) {
    return (
      <Container className={props.className}>
        <TickersForm onSubmit={onTickerSubmit} noValidate>
          {selectedTickers.map(onRenderSelectedTicker)}
          <TickerFormFooter>
            <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} />
          </TickerFormFooter>
        </TickersForm>
        {isConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("linkAccount.saveAddTitle").replace("%s%", i18n.t("linkAccount.modalMetalsTitle"))}
            description={i18n.t("linkAccount.saveStocksAddDesc").replace("%s%", i18n.t("linkAccount.modalMetalsTitle"))}
            positiveButtonTitle={i18n.t("save")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={closeConfirmModal}
            handlePositiveButtonClick={saveConfirmModal}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default MetalsConnectComponent;
