import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useLocation } from "react-router-dom";

import { modalValues } from "routes";
import { useHistory } from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";

const Dialog = styled(ConfirmationDialog)`
  width: 655px;
  padding-left: 15px;

  ol {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    row-gap: 6px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 0;
  }

  button {
    margin-bottom: 5px;

    &[data-cy="positiveButton"] {
      width: 210px;
    }

    &[data-cy="negativeButton"] {
      min-width: 119px;
      margin-left: 17px;
    }

    &[data-cy="tertiaryButton"] {
      min-width: 116px;
      margin-left: 17px;
    }
  }
`;

const UpgradeToBlackPaywall = ({ onMoreDetailsClick, onDismiss = () => null }) => {
  const history = useHistory();
  const location = useLocation();

  const handlePositiveButtonClick = () => {
    onDismiss();
    AccountSettingsComponentExports.show(
      history,
      location,
      accountSettingsTabs.SUBSCRIPTION,
      modalValues.SUBSCRIPTION_EDIT
    );
  };

  return (
    <Dialog
      title={i18n.t("blackPaywalls.confirmUpgradeTitle")}
      description={`<ol>${i18n.t("blackPaywalls.confirmUpgradeDesc")}</ol>`}
      positiveButtonTitle={i18n.t("multiuserUpgradeDialog.action")}
      negativeButtonTitle={i18n.t("moreDetails")}
      tertiaryButtonTitle={i18n.t("cancel")}
      handlePositiveButtonClick={handlePositiveButtonClick}
      handleNegativeButtonClick={onMoreDetailsClick}
      handleTertiaryButtonClick={onDismiss}
    />
  );
};

export default UpgradeToBlackPaywall;
