import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import { getAggregatorName } from "@kubera/common";
import { ReactComponent as GrabIcon } from "assets/images/drag_drop_grabbar.svg";
import { ReactComponent as DuplicateIcon } from "assets/images/duplicate_icon.svg";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import SecondaryButton from "components/button/SecondaryButton";

const MatchAccountsDialog = styled(Dialog)`
  width: 870px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;
  padding: 60px;
  background: #fcfcfc;
`;

const ProviderName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-top: 3px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  margin-top: 10px;
`;

const MatchingTablesContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

const ExistingTable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const IncomingTable = styled(ExistingTable)`
  border-left: 0px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TableTitleRow = styled(Row)`
  height: 30px;
  padding-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ExistingRow = styled(Row)``;

const IncomingRow = styled(Row)`
  padding-left: 0px;
`;

const GrabRowIcon = styled(GrabIcon)`
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  cursor: grab;
  padding-top: 1px;
`;

const AccountName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: nowrap;
  direction: rtl;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AccountNumber = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const BlankRowName = styled.div`
  flex: 1;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.3);
  text-transform: lowercase;
`;

const DuplicateRowButton = styled(DuplicateIcon)`
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  padding-top: 1px;
`;

const SubmitButton = styled(PrimaryButton)``;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 33px;
`;

const AbortReconnectionButton = styled(SecondaryButton)`
  margin-left: 22px;
  width: 198px;
`;

class MatchAccountsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { existingCustodians: props.existingCustodians, incomingAccounts: this.getMatchingIncomingAccounts() };

    this.handleDuplicateRowClick = this.handleDuplicateRowClick.bind(this);
    this.handleIncomingDragEnd = this.handleIncomingDragEnd.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnAbortReconnection = this.handleOnAbortReconnection.bind(this);
  }

  getMatchingIncomingAccounts() {
    var accounts = [...this.props.incomingAccounts];
    var matchingAccounts = [];

    for (const custodian of this.props.existingCustodians) {
      const matchingAccountIndex = accounts.findIndex(
        item => custodian.linkContainer === item.container && custodian.name.includes(item.accountName)
      );
      if (matchingAccountIndex === -1) {
        matchingAccounts.push(null);
      } else {
        matchingAccounts.push(accounts[matchingAccountIndex]);
        accounts.splice(matchingAccountIndex, 1);
      }
    }

    matchingAccounts.push(...accounts);
    if (matchingAccounts.includes(null) === false) {
      matchingAccounts.push(null);
    }
    return matchingAccounts;
  }

  handleDuplicateRowClick(e, index) {
    const newIncomingAccounts = this.state.incomingAccounts;
    newIncomingAccounts.splice(index, 0, null);
    this.setState({ incomingAccounts: newIncomingAccounts });
  }

  handleIncomingDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = this.reorder(this.state.incomingAccounts, result.source.index, result.destination.index);

    this.setState({
      incomingAccounts: reorderedItems
    });
  }

  handleOnSubmit() {
    this.props.onSubmit(this.state.existingCustodians, this.state.incomingAccounts);
  }
  handleOnAbortReconnection() {
    this.props.closeWindow();
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  render() {
    return (
      <DialogOverlay>
        <MatchAccountsDialog className={this.props.className}>
          <ProviderName>{this.props.providerName}</ProviderName>
          <Title>{i18n.t("matchAccountsDialog.title")}</Title>
          <Description>{i18n.t("matchAccountsDialog.description")}</Description>
          <MatchingTablesContainer>
            <ExistingTable>
              <TableTitleRow>{`${i18n.t("existing")} (${getAggregatorName(
                this.props.existingLinkingService
              )})`}</TableTitleRow>
              {this.state.existingCustodians.map((custodian, index) => (
                <ExistingRow key={index}>
                  <AccountName>{custodian.name}</AccountName>
                </ExistingRow>
              ))}
            </ExistingTable>
            <IncomingTable>
              <TableTitleRow>{`${i18n.t("incoming")} (${getAggregatorName(
                this.props.incomingLinkingService
              )})`}</TableTitleRow>

              <DragDropContext onDragEnd={this.handleIncomingDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.incomingAccounts.map((account, index) => (
                        <Draggable key={index} draggableId={`${index}`} index={index}>
                          {(provided, snapshot) => (
                            <IncomingRow
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <GrabRowIcon />
                              {!account === false && <AccountName>{`${account.accountName}`}</AccountName>}
                              {!account === false && (
                                <AccountNumber>{account.accountNumber.replace(/xxxx/, " - xxxx")}</AccountNumber>
                              )}
                              {!account === true && <BlankRowName>{`(${i18n.t("blank")})`}</BlankRowName>}
                              {!account === true && (
                                <DuplicateRowButton onClick={e => this.handleDuplicateRowClick(e, index)} />
                              )}
                            </IncomingRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </IncomingTable>
          </MatchingTablesContainer>
          <ButtonsContainer>
            <SubmitButton title={i18n.t("looksGood")} onClick={this.handleOnSubmit} />
            <AbortReconnectionButton title={i18n.t("abortReconnection")} onClick={this.handleOnAbortReconnection} />
          </ButtonsContainer>
        </MatchAccountsDialog>
      </DialogOverlay>
    );
  }
}

export default MatchAccountsComponent;
