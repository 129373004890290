import { useNavigate, useLocation } from "react-router-dom";

const historyObj = {};
export function useHistory() {
  const navigate = useNavigate();
  const location = useLocation();

  historyObj.push = navigate;
  historyObj.replace = pathParam => {
    navigate(pathParam, { replace: true });
  };
  historyObj.go = navigate;
  historyObj.goBack = () => navigate(-1);
  historyObj.goForward = () => navigate(1);
  historyObj.location = location;

  return historyObj;
}
