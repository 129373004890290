import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  updateUserPreferences,
  planningChartTypeSelector,
  planningNetworthChartScenariosSelector,
  updateNetworthChartSelectedScenarios
} from "@kubera/common";
import ContextMenu from "components/contextmenu/ContextMenu";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import NetworthChartComponent from "./charts/NetworthChartComponent";
import NetworthCashChartComponent from "./charts/NetworthCashChartComponent";
import CashflowChartComponent from "./charts/CashflowChartComponent";
import Checkbox from "components/inputs/Checkbox";

const chartTypes = {
  NETWORTH: "networth",
  NETWORTH_CASH: "networth_cash",
  CASHFLOW: "cashflow"
};

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 359px;
  border: ${props => `1px solid ${props.theme.gridSectionBorderColor}`};
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ChartActionsContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  top: 25px;
  right: 25px;
`;

const ChartTypeButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  margin-left: 15px;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
  color: ${props => props.theme.selectTimeRangeButtonCLR};
`;

const ScenarioSelectionButton = styled(ChartTypeButton)`
  margin-right: 5px;
`;

const DownArrowButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const ScenarioList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const ScenarioItem = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  cursor: pointer;
  border-top: ${props => (props.isFirst ? "" : "1px solid rgba(0, 0, 0, 0.1);")};

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const ScenarioName = styled.div`
  padding-left: 10px;
`;

const ScenarioColor = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 2px;
  background: ${props => props.color};
  border-radius: 50%;
`;

class PlanningChartComponent extends React.Component {
  constructor(props) {
    super(props);
    const defaultChartType =
      props.selectedChartType === chartTypes.CASHFLOW
        ? chartTypes.NETWORTH_CASH
        : props.selectedChartType || chartTypes.NETWORTH;

    this.state = {
      selectedChartType: defaultChartType
    };

    this.handleChartTypeButtonClick = this.handleChartTypeButtonClick.bind(this);
    this.handleChartTypeMenuSelection = this.handleChartTypeMenuSelection.bind(this);
    this.handleSelectScenariosButtonClick = this.handleSelectScenariosButtonClick.bind(this);
    this.handleScenarioCheckboxChange = this.handleScenarioCheckboxChange.bind(this);

    this.chartTypeMenuRef = React.createRef();
    this.scenariosMenuRef = React.createRef();
  }

  handleSelectScenariosButtonClick(event) {
    if (!this.scenariosMenuRef.current) {
      return;
    }
    if (this.scenariosMenuRef.current.isVisible() === true) {
      this.scenariosMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.scenariosMenuRef.current.show(
      [],
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      event.target
    );
  }

  handleScenarioCheckboxChange(checked, scenarioId) {
    var newSelectedScenarios = this.props.selectedNetworthScenarios;
    if (checked) {
      newSelectedScenarios = [...newSelectedScenarios, scenarioId];
    } else {
      newSelectedScenarios = newSelectedScenarios.filter(item => item !== scenarioId);
    }
    this.setState({ selectedScenarios: newSelectedScenarios });
    this.props.updateNetworthChartSelectedScenarios(newSelectedScenarios);
  }

  handleChartTypeButtonClick(event) {
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = this.getChartTypesMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.state.selectedChartType) {
        item.selected = true;
      }
    }

    this.chartTypeMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      event.target
    );
  }

  handleChartTypeMenuSelection(item) {
    this.setState({ selectedChartType: item.id });

    this.props.updateUserPreferences({
      selectedPlanningChartType: item.id
    });
  }

  getChartTypesMenuItems() {
    return [
      [
        { id: chartTypes.NETWORTH, label: i18n.t("networth") },
        { id: chartTypes.NETWORTH_CASH, label: i18n.t("networthCash") }
      ]
    ];
  }

  getCurrentChartTypeLabel() {
    return this.getChartTypesMenuItems()[0].find(item => item.id === this.state.selectedChartType).label;
  }

  getChartComponent() {
    switch (this.state.selectedChartType) {
      case chartTypes.NETWORTH:
        return (
          <NetworthChartComponent
            selectedScenarios={this.props.selectedNetworthScenarios}
            selectedScenarioIndex={this.props.selectedScenarioIndex}
            planningData={this.props.planningData}
            colorPallete={this.props.colorPallete}
            handleChartHover={this.props.handleChartHover}
          />
        );
      case chartTypes.NETWORTH_CASH:
        return (
          <NetworthCashChartComponent
            selectedScenarioIndex={this.props.selectedScenarioIndex}
            planningData={this.props.planningData}
            colorPallete={this.props.colorPallete}
            handleChartHover={this.props.handleChartHover}
          />
        );
      case chartTypes.CASHFLOW:
        return (
          <CashflowChartComponent
            selectedScenarioIndex={this.props.selectedScenarioIndex}
            planningData={this.props.planningData}
            colorPallete={this.props.colorPallete}
            handleChartHover={this.props.handleChartHover}
          />
        );
      default:
        return null;
    }
  }

  getSelectedScenariosLabel() {
    const selectedScenarios = this.props.planningData.filter(item =>
      this.props.selectedNetworthScenarios.includes(item.scenario.id)
    );

    if (selectedScenarios.length === 1) {
      return selectedScenarios[0].scenario.name;
    } else if (selectedScenarios.length > 1 && selectedScenarios.length === this.props.planningData.length) {
      return `${i18n.t("all")} ${i18n.t("scenarios")}`;
    }
    return `${selectedScenarios.length} ${i18n.t("scenarios")}`;
  }

  getScenariosCheckboxItems() {
    return this.props.planningData.map((item, index) => {
      return {
        id: item.scenario.id,
        title: item.scenario.name,
        checked: this.props.selectedNetworthScenarios.includes(item.scenario.id),
        color: this.props.colorPallete[index].color
      };
    });
  }

  render() {
    if (!this.props.planningData === true) {
      return null;
    }

    return (
      <Container>
        <ContentContainer>
          <ChartActionsContainer>
            {this.state.selectedChartType === chartTypes.NETWORTH && this.getScenariosCheckboxItems().length > 1 && (
              <ScenarioSelectionButton onClick={this.handleSelectScenariosButtonClick}>
                {this.getSelectedScenariosLabel()}
                <DownArrowButtonIcon />
              </ScenarioSelectionButton>
            )}
            <ChartTypeButton onClick={this.handleChartTypeButtonClick}>
              {this.getCurrentChartTypeLabel()}
              <DownArrowButtonIcon />
            </ChartTypeButton>
          </ChartActionsContainer>
          {this.getChartComponent()}
          <ContextMenu width={162} ref={this.chartTypeMenuRef} onSelection={this.handleChartTypeMenuSelection} />

          {this.state.selectedChartType === chartTypes.NETWORTH && (
            <ContextMenu ref={this.scenariosMenuRef} width={200} hideOnScroll={false}>
              <ScenarioList>
                {this.getScenariosCheckboxItems().map((item, index) => {
                  return (
                    <ScenarioItem
                      key={index}
                      isFirst={index === 0}
                      onClick={e => this.handleScenarioCheckboxChange(!item.checked, item.id)}
                    >
                      <Checkbox
                        checked={item.checked}
                        onChange={(checked, e) => {
                          this.handleScenarioCheckboxChange(checked, item.id);
                        }}
                      />
                      <ScenarioColor color={item.color}></ScenarioColor>
                      <ScenarioName>{item.title}</ScenarioName>
                    </ScenarioItem>
                  );
                })}
              </ScenarioList>
            </ContextMenu>
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  selectedChartType: planningChartTypeSelector(state),
  selectedNetworthScenarios: planningNetworthChartScenariosSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  updateNetworthChartSelectedScenarios: updateNetworthChartSelectedScenarios
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlanningChartComponent));
