import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import styled from "styled-components";
import {
  updateCustodian,
  updateCustodianLocal,
  getTickerDescription,
  formatNumberWithCurrency,
  convertCurrency,
  isCryptoCurrency,
  getExchangeRateDetails,
  getExchangeRate,
  getTickerUsingId,
  getTickerUsingShortName,
  UNKNOWN_TICKER_SHORT_NAME,
  fetchTickerDetails
} from "@kubera/common";
import NumberInput from "components/inputs/NumberInput";

const Container = styled.div`
  padding-top: 23px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 5px;
`;

const TickerContainer = styled.form`
  margin-bottom: 20px;
`;

const TickerHead = styled.div`
  position: relative;
  padding: 20px;
  background: #efefef;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin-bottom: 5px;
`;

const TickerHeadSymbol = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  margin-bottom: 1px;
`;

const TickerHeadFooter = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  max-width: 477px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
`;

const TickerInput = styled(NumberInput)`
  padding: 18px 14px 15px;
  box-sizing: border-box;
  width: 100%;
  border: ${props =>
    props.isError
      ? "1px solid #ff0000"
      : props.disabled
      ? "1px solid rgba(0, 0, 0, 0.2)"
      : "1px solid rgba(0, 0, 0, 0.4)"};
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  background: ${props => (props.disabled === true ? "#ebebeb" : "")};

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const TickerPrice = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const TickerOriginalPrice = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2px;
`;

const StocksAndCryptoHomeComponent = ({
  portfolio,
  detailsInfo,
  tickerInfo,
  isQuantityField = false,
  handleDetailsCustodianChange = () => null,
  isReadOnly = false
}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(detailsInfo.value);
  const [isUnknownTickerFetched, setIsUnknownTickerFetched] = useState(false);

  const updateLocalTimeoutId = useRef(null);
  const valueChangeTimoutId = useRef(null);
  const runEffectOnceFlag = useRef(false);
  const quantityInpRef = useRef();

  const tickerDescription = getTickerDescription(tickerInfo);
  const isPortfolioCurrencyCrypto = isCryptoCurrency(portfolio.currency);
  const actualValue = convertCurrency(1, tickerInfo.shortName, portfolio.currency);
  const originalValue = convertCurrency(1, tickerInfo.shortName, tickerInfo.currency);
  const originalValueFormatted = formatNumberWithCurrency(
    originalValue,
    tickerInfo.currency,
    false,
    isCryptoCurrency(tickerInfo.currency) ? 4 : 2
  );
  const { shortName } = getTickerUsingShortName(tickerInfo.currency);
  const isTickerCurrencyUnknown = shortName === UNKNOWN_TICKER_SHORT_NAME;

  const updateCustodianValue = useCallback(
    value => {
      const exchangeRate = getExchangeRate(getTickerUsingId(detailsInfo.valueTickerId).shortName, portfolio.currency);

      dispatch(
        updateCustodian(
          false,
          detailsInfo.id,
          {
            value: value,
            valueTickerId: detailsInfo.valueTickerId,
            valueExchangeRate: getExchangeRateDetails(getTickerUsingShortName(portfolio.currency).id, exchangeRate),
            past: 0
          },
          false,
          () => {
            handleDetailsCustodianChange({ value: value }, true);
          }
        )
      );
    },
    [dispatch, handleDetailsCustodianChange, detailsInfo.id, detailsInfo.valueTickerId, portfolio.currency]
  );

  const onValueChange = (e, numberValue) => {
    const value = numberValue === null ? 0 : numberValue;
    setValue(value);

    clearTimeout(updateLocalTimeoutId.current);
    updateLocalTimeoutId.current = setTimeout(() => {
      dispatch(
        updateCustodianLocal({
          id: detailsInfo.id,
          value
        })
      );
    }, 300);

    clearTimeout(valueChangeTimoutId.current);
    valueChangeTimoutId.current = setTimeout(() => {
      updateCustodianValue(value);
    }, 2000);
  };

  const onValueChangeBlur = (_, numberValue) => {
    const value = numberValue === null ? 0 : numberValue;
    handleDetailsCustodianChange({ value }, false);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    if (document.activeElement !== quantityInpRef.current && detailsInfo.value) {
      setValue(detailsInfo.value);
    }
  }, [detailsInfo.value]);

  useEffect(() => {
    if (runEffectOnceFlag.current) return;

    if (isTickerCurrencyUnknown) {
      dispatch(
        fetchTickerDetails(
          tickerInfo.currency,
          undefined,
          () => {
            setIsUnknownTickerFetched(true);
          },
          () => {
            setIsUnknownTickerFetched(true);
          }
        )
      );
    }
  }, [dispatch, isTickerCurrencyUnknown, tickerInfo.currency]);

  if (detailsInfo) {
    return (
      <Container>
        <TickerContainer noValidate onSubmit={handleOnSubmit}>
          <TickerHead>
            <TickerHeadSymbol>{tickerInfo.code}</TickerHeadSymbol>
            <TickerHeadFooter>{tickerDescription}</TickerHeadFooter>
            <TickerPrice>
              {formatNumberWithCurrency(actualValue, portfolio.currency, true, isPortfolioCurrencyCrypto ? 4 : 2)}
            </TickerPrice>
            {(!isTickerCurrencyUnknown || isUnknownTickerFetched) &&
            portfolio.currency !== tickerInfo.currency &&
            originalValueFormatted ? (
              <TickerOriginalPrice>{originalValueFormatted}</TickerOriginalPrice>
            ) : null}
          </TickerHead>
          <Title>{i18n.t("custodianDetails.quantity")}</Title>
          <TickerInput
            ref={quantityInpRef}
            disabled={isReadOnly}
            placeholder={!isQuantityField ? i18n.t("linkAccount.stockQuantity") : i18n.t("quantity")}
            value={value}
            onChange={onValueChange}
            onBlur={onValueChangeBlur}
            required
          ></TickerInput>
        </TickerContainer>
      </Container>
    );
  }

  return null;
};

export default StocksAndCryptoHomeComponent;
