import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { useHistory } from "./useHistory";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const routerProps = { location, history, params };
    return <Component {...props} {...routerProps} />;
  }

  const allStaticMethods = Object.getOwnPropertyNames(Component).filter(prop => typeof Component[prop] === "function");

  allStaticMethods.forEach(method => {
    if (["getDerivedStateFromProps"].includes(method)) return;
    ComponentWithRouterProp[method] = Component[method];
  });

  return ComponentWithRouterProp;
}
