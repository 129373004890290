import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import i18n from "i18next";
import {
  accountLinkingService,
  currentPortfolioSelector,
  getExchangeRate,
  shortFormatNumberWithCurrency,
  tickerSubTypes,
  formatNumberWithCurrency,
  isAppInViewMode,
  portfoliosSelector
} from "@kubera/common";
import linkedAccountIcon from "assets/images/linked_account_icon.svg";
import linkedAccountIconDark from "assets/images/linked_account_icon_dark.svg";
import { ReactComponent as VerifiedIcon } from "assets/images/linked_account_verified.svg";
import EquityBadge from "./EquityBadge";
import { ReactComponent as OpenPortfolioIcon } from "assets/images/open_link.svg";
import { queryParams } from "routes";

const LinkedIcon = styled.div`
  display: inline-block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  background-image: url(${props => (props.theme.mode === "default" ? linkedAccountIcon : linkedAccountIconDark)});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 12px 12px;
  margin-left: 5px;
  margin-right: ${({ isBesideText }) => (isBesideText ? "0" : "5px")};
  transform: translateY(2px);
`;

const LinkDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const LinkDisplays = `
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 29px;
  box-sizing: border-box;
`;

const AvailableCreditContainer = styled.div`
  ${LinkDisplays}
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.theme.linkedIconEquityContainerBG};
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${props => props.theme.linkedIconEquityContainerCLR};
  text-transform: uppercase;
  padding: 0 3px;
  white-space: nowrap;
`;

const UnfundedCapital = styled(AvailableCreditContainer)`
  white-space: pre-line;
  margin-left: 0px;
`;

const BadgeLabel = styled.div`
  font-size: 9px;
`;

const AvailableCreditText = styled.div`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: -0.02em;
`;

const OpenPortfolioLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid transparent;
  border-radius: 12px;
`;

const PortfolioLinkContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 44px;
  height: 30px;
  margin-left: -14px;
  cursor: pointer;

  &:hover ${OpenPortfolioLink} {
    background-color: ${props => props.theme.borderColorHelpMenuButton};
    border: ${props => `1px solid ${props.theme.textCellLinkedHoldingsCount}`};
    margin-left: 0px;
  }
`;

const OpenPortfolio = styled(OpenPortfolioIcon)`
  path {
    fill: ${props => props.theme.textCellLinkedHoldingsCount};
  }
  height: 9px;
  width: 9px;
`;

const LinkedIconComponent = props => {
  const allPortfolios = useSelector(portfoliosSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const applicableAvailableCreditValue =
    props.type === accountLinkingService.IN_HOUSE_OAUTH
      ? props.availableCredit
      : props.availableCredit * getExchangeRate(props.currency, portfolio.currency);

  if (props.type === accountLinkingService.KUBERA_PORTFOLIO) {
    return (
      <LinkDisplayContainer>
        <LinkedIcon showDescription={props.showDescription} title={props.title} />
        {isAppInViewMode() === false && allPortfolios.find(item => item.id === props.linkProviderAccountId) && (
          <PortfolioLinkContainer
            onClick={e => {
              window.kuberaOpen(
                `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryParams.PORTFOLIO_ID}=${props.linkProviderAccountId}`
              );
            }}
            title={props.title}
          >
            <OpenPortfolioLink>
              <OpenPortfolio />
            </OpenPortfolioLink>
          </PortfolioLinkContainer>
        )}
      </LinkDisplayContainer>
    );
  }

  const isHomes = [tickerSubTypes.HOME, tickerSubTypes.CARS].includes(props.subType);
  const isLinkedRowWithEquity = [accountLinkingService.ZILLOW, accountLinkingService.CARS].includes(props.type);
  if (isHomes || isLinkedRowWithEquity) {
    return (
      <>
        {props.isBesideText && isLinkedRowWithEquity && (
          <LinkedIcon showDescription={props.showDescription} title={props.title} />
        )}
        {!props.isBesideText && (
          <EquityBadge
            relatedId={props.relatedId}
            currency={props.currency}
            currencyCellValue={props.currencyCellValue}
            showLTV={props.subType === tickerSubTypes.HOME}
          />
        )}
      </>
    );
  }

  if (props.isVerified === true) {
    return (
      <LinkDisplayContainer>
        <VerifiedIcon title={props.title} />
      </LinkDisplayContainer>
    );
  }
  if (
    props.availableCredit &&
    (props.linkContainer === "investment" ||
      props.linkContainer === "locked" ||
      props.linkContainer === "credit" ||
      props.linkContainer === "creditCard" ||
      props.linkContainer === "loan" ||
      props.type === accountLinkingService.IN_HOUSE_OAUTH)
  ) {
    return (
      <LinkDisplayContainer>
        <AvailableCreditContainer>
          {props.type === accountLinkingService.IN_HOUSE_OAUTH
            ? props.availableCredit
            : shortFormatNumberWithCurrency(applicableAvailableCreditValue, portfolio.currency, false, true)}
          <br />
          <AvailableCreditText>
            {props.linkContainer === "investment" ||
            props.linkContainer === "locked" ||
            props.type === accountLinkingService.IN_HOUSE_OAUTH
              ? i18n.t("unvested")
              : props.linkContainer === "credit" ||
                props.linkContainer === "loan" ||
                props.linkContainer === "creditCard"
              ? i18n.t("available")
              : null}
          </AvailableCreditText>
        </AvailableCreditContainer>
      </LinkDisplayContainer>
    );
  }

  if (props.isBesideText) {
    return <LinkedIcon isBesideText showDescription={props.showDescription} title={props.title} />;
  }

  if (props.unfundedCapital) {
    return (
      <LinkDisplayContainer>
        <UnfundedCapital>
          {`${formatNumberWithCurrency(Math.kuberaFloor(props.unfundedCapital), portfolio.currency)}`}
          <BadgeLabel>{i18n.t("unfunded")}</BadgeLabel>
        </UnfundedCapital>
      </LinkDisplayContainer>
    );
  }

  return null;
};

export default LinkedIconComponent;
