export const getLocalTimeStamp = ({ hrs = 0, mins = 0, secs = 0, ms = 0 }, daysFromToday = 0) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + daysFromToday);

  const timeStamp = tomorrow.setHours(hrs, mins, secs, ms);

  return timeStamp;
};

export const isWithinTimeRange = (
  { hrs: startHrs = 0, mins: startMins = 0, secs: startSecs = 0, ms: startMs = 0 },
  { hrs: endHrs = 0, mins: endMins = 0, secs: endSecs = 0, ms: endMs = 0 },
  startDaysGap = 0,
  endDaysGap = 0
) => {
  const today = getLocalTimeStamp({ hrs: startHrs, mins: startMins, secs: startSecs, ms: startMs }, startDaysGap);
  const tomorrow = getLocalTimeStamp({ hrs: endHrs, mins: endMins, secs: endSecs, ms: endMs }, endDaysGap);

  const currentTs = Date.now();

  if (currentTs >= today && currentTs <= tomorrow) {
    return true;
  }

  if (currentTs > tomorrow) {
    return Infinity;
  }

  return -Infinity;
};
