import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import TextInput from "components/inputs/TextInput";

const Description = styled(TextInput)`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  background-color: transparent;
  border: 0;
  padding: 5px;
  margin-left: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: 2px solid ${props => props.theme.focusOutlineColor};
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }
`;

const CustodianDetailsDesc = ({ isReadOnly, value, placeholder, onChange, onKeyDown }) => {
  const inputValueRef = useRef(value);
  const [descValue, setDescValue] = useState(value);

  const handleChange = e => {
    inputValueRef.current = e.target.value;
    setDescValue(e.target.value);
  };

  const handleBlur = () => {
    onChange(descValue);
  };

  useEffect(() => {
    inputValueRef.current = value;
    setDescValue(value);
  }, [value]);

  return (
    <Description
      data-cy="detailsDescription"
      readOnly={isReadOnly}
      value={inputValueRef.current}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
    />
  );
};

export default CustodianDetailsDesc;
