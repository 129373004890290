import { store } from "../../store";
import { ApiClient } from "../../api/ApiClient";
import { archivedCustodianSelector, archivedPortfolioDetailsSelector } from "../reducers/ArchivedPortfolioReducer";
import { enqueueItem, SyncItem, SyncItemType } from "./SyncActions";
import { insertCustodianAction, insertSectionAction, insertSheetAction, updateSectionIrr } from "./PortfolioActions";
import {
  updateDashboardAction,
  FETCH_ARCHIVED_PORTFOLIO,
  FETCH_ARCHIVED_PORTFOLIO_SUCCESS,
  FETCH_ARCHIVED_PORTFOLIO_ERROR,
  RESET_ARCHIVED_PORTFOLIO,
  DELETE_ARCHIVED_CUSTODIAN
} from "./Common";
import { getUuid } from "../../utilities/Number";

export const fetchArchivedPortfolioAction = portfolioId => ({
  type: FETCH_ARCHIVED_PORTFOLIO,
  portfolioId
});

export const fetchArchivedPortfolioSuccessAction = details => ({
  type: FETCH_ARCHIVED_PORTFOLIO_SUCCESS,
  details
});

export const fetchArchivedPortfolioErrorAction = error => ({
  type: FETCH_ARCHIVED_PORTFOLIO_ERROR,
  error
});

export const resetArchivedPortfolioAction = () => ({
  type: RESET_ARCHIVED_PORTFOLIO
});

export const deleteArchivedCustodianAction = custodianId => ({
  type: DELETE_ARCHIVED_CUSTODIAN,
  custodianId
});

export const fetchArchivedPortfolio = (portfolioId, category) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchArchivedPortfolioAction(portfolioId));

      ApiClient.getArchivedPortfolio(getUuid(), portfolioId, category)
        .then(apiData => {
          dispatch(fetchArchivedPortfolioSuccessAction(apiData.payload));
          resolve(apiData.payload);
        })
        .catch(apiError => {
          dispatch(fetchArchivedPortfolioErrorAction(apiError));
          reject(apiError);
        });
    });
  };
};

export const unarchiveCustodian = (portfolioId, custodianId) => {
  return (dispatch, getState) => {
    const portfolioDetails = archivedPortfolioDetailsSelector(getState());
    if (portfolioDetails) {
      const custodian = portfolioDetails.custodian.find(custodian => custodian.id === custodianId);
      const section = custodian ? portfolioDetails.section.find(section => section.id === custodian.sectionId) : null;
      const sheet = section ? portfolioDetails.sheet.find(sheet => sheet.id === section.sheetId) : null;
      const entitiesToUpdate = [];

      if (custodian) {
        const request = idempotentId => ApiClient.restoreCustodian(idempotentId, custodian.sectionId, [custodianId]);
        const syncItem = new SyncItem(SyncItemType.UPDATE, custodianId, request, 0, false, apiData => {
          dispatch(updateSectionIrr([custodian.sectionId]));
        });
        dispatch(enqueueItem(syncItem));

        custodian.tsArchive = null;
        dispatch(insertCustodianAction(portfolioId, custodian));
        entitiesToUpdate.push(custodian.id);
      }
      if (section) {
        dispatch(insertSectionAction(portfolioId, section));
        entitiesToUpdate.push(section.id);
      }
      if (sheet) {
        dispatch(insertSheetAction(portfolioId, sheet));
        entitiesToUpdate.push(sheet.id);
      }

      dispatch(updateDashboardAction(entitiesToUpdate));
      dispatch(deleteArchivedCustodianAction(custodianId));
    }
  };
};

export const deleteCustodianPermanently = custodianId => {
  return dispatch => {
    const custodian = archivedCustodianSelector(store.getState()).find(custodian => custodian.id === custodianId);
    if (custodian) {
      const request = idempotentId => ApiClient.deleteCustodian(idempotentId, custodian.sectionId, [custodianId]);
      const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, false, apiData => {});
      dispatch(enqueueItem(syncItem));

      dispatch(deleteArchivedCustodianAction(custodianId));
    }
  };
};
