import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import Checkbox from "components/inputs/Checkbox";
import {
  portfoliosSelector,
  updatePortfolio,
  isSharedPortfolioUserSelector,
  userSelector,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";
import ContextMenu from "components/contextmenu/ContextMenu";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: -100px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-bottom: 34px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.8);
`;

const ShowAssetChangeCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
`;

const MinChangeContainer = styled.div`
  display: flex;
`;

const MinChangeButton = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  text-decoration: underline;
  pointer-events: ${props => (!props.isDisabled ? null : "none")};
  color: ${props => (!props.isDisabled === true ? "black" : "rgba(0, 0, 0, 0.5)")};
  user-select: none;
  cursor: pointer;
  margin-left: 3px;
`;

const AssetMinChangeCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
  margin-left: 18px;
  pointer-events: ${props => (!props.disabled ? null : "none")};
`;

const ConnectionErrorCheckbox = styled(Checkbox)``;

class AccountNotificationsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleDayChangeAssetsCheckboxChange = this.handleDayChangeAssetsCheckboxChange.bind(this);
    this.handleDayChangeLimitEnabledCheckboxChange = this.handleDayChangeLimitEnabledCheckboxChange.bind(this);
    this.handleConnectionErrorsCheckbox = this.handleConnectionErrorsCheckbox.bind(this);
    this.handleMinChangeClick = this.handleMinChangeClick.bind(this);
    this.handleMinChangeContextMenuSelection = this.handleMinChangeContextMenuSelection.bind(this);

    this.contextMenuRef = React.createRef();
  }

  handleDayChangeAssetsCheckboxChange(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.dayChangeAssets = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  handleConnectionErrorsCheckbox(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.connectionErrors = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  handleDayChangeLimitEnabledCheckboxChange(isChecked, portfolio) {
    const settings = this.getNotificationSettings(portfolio);
    settings.dayChangeLimitEnabled = isChecked;
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);
  }

  handleMinChangeClick(e, portfolio) {
    const notificationSettings = this.getNotificationSettings(portfolio);
    const currentDayChangeLimit = notificationSettings.dayChangeLimit;
    const targetPosition = e.target.getBoundingClientRect();
    const menuItems = [
      [
        { id: "0.5", label: "0.5%", selected: currentDayChangeLimit === 0.5, value: 0.5 },
        { id: "1", label: "1%", selected: currentDayChangeLimit === 1, value: 1 },
        { id: "1.5", label: "1.5%", selected: currentDayChangeLimit === 1.5, value: 1.5 },
        { id: "2", label: "2%", selected: currentDayChangeLimit === 2, value: 2 },
        { id: "5", label: "5%", selected: currentDayChangeLimit === 5, value: 5 },
        { id: "10", label: "10%", selected: currentDayChangeLimit === 10, value: 10 },
        { id: "25", label: "25%", selected: currentDayChangeLimit === 25, value: 25 },
        { id: "50", label: "50%", selected: currentDayChangeLimit === 50, value: 50 }
      ]
    ];
    this.minChangePortfolio = portfolio;
    menuItems[0] = menuItems[0].map(item => {
      return { ...item, label: `${formatNumberWithKuberaNumberFormatSettings(item.value)}%` };
    });
    this.contextMenuRef.current.show(menuItems, targetPosition.left, targetPosition.bottom - 15, true, e.target);
  }

  handleMinChangeContextMenuSelection(menuItem) {
    if (!this.minChangePortfolio === true) {
      return;
    }

    const portfolio = this.minChangePortfolio;
    const settings = this.getNotificationSettings(portfolio);
    settings.dayChangeLimit = parseFloat(menuItem.id);
    portfolio.notificationOption = JSON.stringify(settings);
    this.props.updatePortfolio(portfolio);

    this.minChangePortfolio = null;
  }

  getNotificationSettings(portfolio) {
    const defaultSettings = {
      dayChangeAssets: true,
      dayChangeLimitEnabled: false,
      dayChangeLimit: 0.5,
      connectionErrors: false
    };

    try {
      const settings = JSON.parse(portfolio.notificationOption);

      if (!settings === true) {
        return defaultSettings;
      }
      if (this.props.isSharedPortfolioAccountUser === true) {
        const userSettings = settings[this.props.user.id];
        if (!userSettings === false) {
          return userSettings;
        }
      } else if (settings.dayChangeAssets === undefined) {
        return defaultSettings;
      }
      return settings;
    } catch (e) {
      return defaultSettings;
    }
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Title>{i18n.t("accountSettings.notificationsTabTitle")}</Title>
        {this.props.portfolios?.map((portfolio, index) => {
          const notificationSettings = this.getNotificationSettings(portfolio);

          return (
            <Section key={index}>
              <SectionTitle>{portfolio.name}</SectionTitle>
              <ShowAssetChangeCheckbox
                label={i18n.t("accountSettings.dailyDigestChange")}
                checked={notificationSettings.dayChangeAssets}
                onChange={isChecked => this.handleDayChangeAssetsCheckboxChange(isChecked, portfolio)}
              />
              <MinChangeContainer>
                <AssetMinChangeCheckbox
                  label={i18n.t("accountSettings.dailyDigestMinChange")}
                  checked={notificationSettings.dayChangeLimitEnabled}
                  disabled={notificationSettings.dayChangeAssets === false}
                  onChange={isChecked => this.handleDayChangeLimitEnabledCheckboxChange(isChecked, portfolio)}
                  noPreWrap={true}
                />
                <MinChangeButton
                  isDisabled={notificationSettings.dayChangeAssets === false}
                  onClick={e => this.handleMinChangeClick(e, portfolio)}
                >
                  {formatNumberWithKuberaNumberFormatSettings(notificationSettings.dayChangeLimit) + "%"}
                </MinChangeButton>
              </MinChangeContainer>
              <ConnectionErrorCheckbox
                label={i18n.t("accountSettings.dailyDigestConnectionError")}
                checked={notificationSettings.connectionErrors}
                onChange={isChecked => this.handleConnectionErrorsCheckbox(isChecked, portfolio)}
              />
            </Section>
          );
        })}
        <ContextMenu ref={this.contextMenuRef} width={130} onSelection={this.handleMinChangeContextMenuSelection} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolios: portfoliosSelector(state),
  user: userSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state)
});

const mapDispatchToProps = {
  updatePortfolio: updatePortfolio
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountNotificationsComponent);
