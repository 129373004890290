import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getLinkedAccounts,
  currentPortfolioSelector,
  getCurrentCustodianFromCustodianId,
  convertCurrency,
  formatNumberWithCurrency,
  store,
  unmarkCustodianAsLinking,
  SyncComponent,
  accountLinkingService,
  apiErrorCodes
} from "@kubera/common";

import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import SearchInput from "components/inputs/SearchInput";
import Loader from "components/loader/Loader";
import PrimaryButton from "components/button/PrimaryButton";
import FancyLoader from "components/loader/FancyLoader";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import Checkbox from "components/inputs/Checkbox";

const LinkDialog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  //padding: 60px;
  box-sizing: border-box;
  z-index: 1;
  //background: #fff;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  height: 33px;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
`;

const TitleImage = styled.img`
  height: 33px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Form = styled.form`
  width: 100%;
  margin-bottom: 11px;
`;

const InputTitle = styled.div`
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 5px;
`;

const BlockchainAddrWrapper = styled.div`
  display: flex;
  width: 100%;

  > div {
    width: 100%;
  }
`;

const BlockchainAddrField = styled(SearchInput)`
  width: 100%;
  height: 35px;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  padding-left: 4px;

  div:nth-child(1) {
    visibility: hidden;
    margin-right: -18px;
  }
`;

const GetAccountsButton = styled.div`
  max-width: 91px;
  height: 35px;
  cursor: pointer;
  font-size: 10px;
  line-height: 110%;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${props =>
    props.isLoading === true || props.isDisabled === true
      ? props.theme.primaryButtonLoadingBackgroundColor
      : props.theme.primaryButtonBackgroundColor};
  color: ${props =>
    props.isDisabled === true ? props.theme.primaryButtonDisabledColor : props.theme.primaryButtonColor};
`;

const LinkedAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 29px;
`;

const LinkedAccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const LinkedAccountName = styled.div`
  text-transform: capitalize;
  white-space: pre;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;
const LinkedAccountNameCheckBox = styled(Checkbox)`
  text-transform: capitalize;
  white-space: pre;
`;
const TitleMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  margin-bottom: 20px;
`;

const LinkedAssetNames = styled.div`
  margin-top: 2px;
  margin-left: ${props => (props.isManageFlow ? "23px" : "0px")};
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;
const MessageContainer = styled.div`
  margin-top: 2px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ContinueButton = styled(PrimaryButton)``;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.errorCLR};
`;

const ErrorRetryMsg = styled.div`
  font-feature-settings: "ss01" on;
  margin-top: 14px;
`;
const ErrorRetryTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 13px;
`;
const ErrorRetryDesc = styled.div`
  font-size: 14px;
  line-height: 150%;
`;

const ModalLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  width: 30px;
  height: 30px;
`;

const FancyGetAccountsLoader = styled(FancyLoader)``;

const ZerionComponent = ({
  details,
  onMetamaskError = () => null,
  onDismiss = () => null,
  isMetamaskNotSupported = false,
  onZerionModalLoadingChange = () => null,
  id,
  handleLinkAccount,
  mode,
  custodian,
  closeWindow = () => {
    window.close();
  }
}) => {
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);
  const iteratorRef = useRef(null);
  const iteratorRespAggregator = useRef([]);
  const submitCallRef = useRef(null);
  const clearTimerIntervalId = useRef(null);
  const timerDateRef = useRef(null);

  const { ethereum = { isMetamask: false } } = window;
  const [blockchainAddr, setBlockchainAddr] = useState("");
  const [titleImageLoadState, setTitleImageLoadState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAllAccountsAddedDialog, setShowAllAccountsAddedDialog] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState(null);
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = useState(null);
  const [errorRetryMsg, setErrorRetryMsg] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [timerValue, setTimerValue] = useState(45);
  const portfolio = useSelector(currentPortfolioSelector);
  const portfolioId = portfolio.id;

  const custodianId = id;
  const originalCustodian = getCurrentCustodianFromCustodianId(custodianId);
  const isMetamask = [
    "metamask_ethereum",
    "metamask_bsc",
    "metamask_arbitrum",
    "metamask_avalanche",
    "metamask_optimism",
    "metamask_polygon"
  ].includes(details.linkProviderId);
  const inputTitle = `${details.name} ${i18n.t("zerionmodal.address")}`;
  const titleTxt = isMetamask ? "MetaMask" : details.linkProviderId;
  const imgUrl = `${process.env.PUBLIC_URL}/images/connection_icons/${details.icon ||
    details.searchIcon ||
    "ethereum.png"}`;

  const showTitleImg = titleImageLoadState === null || titleImageLoadState;
  const showTitleText = titleImageLoadState === null || !titleImageLoadState;
  const canContinue = !linkedAccounts === false && !Array.isArray(blockchainAddr);

  const titleImageError = () => {
    setTitleImageLoadState(false);
  };
  const titleImageLoaded = () => {
    setTitleImageLoadState(true);
  };

  const handleAddrChange = (value = "") => {
    setBlockchainAddr(value.trim());
    setErrorMessage("");
    setErrorRetryMsg(null);
  };

  const handleAddrKeyPress = e => {
    if (e.key === "Enter") {
      onAddrSubmit(e);
    }
  };

  const resetTimerDate = () => {
    const today = new Date();
    timerDateRef.current = today.setSeconds(today.getSeconds() + 46);
    setTimerValue(45);
  };

  useEffect(() => {
    const clearTimerInterval = () => {
      clearInterval(clearTimerIntervalId.current);
    };

    if (errorRetryMsg) {
      if (!timerDateRef.current) {
        resetTimerDate();
        setTimerValue(45);
      }
      clearTimerIntervalId.current = setInterval(() => {
        const diff = Math.floor((timerDateRef.current - new Date()) / 1000);
        setTimerValue(diff > 0 ? diff : 0);
      }, 1000);
    } else {
      clearTimerInterval();
    }

    return clearTimerInterval;
  }, [errorRetryMsg]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (mode === linkAccountMode.MANAGE) {
      setBlockchainAddr(custodian.linkProviderAccountId);
      setIsLoading(true);
      onSubmit(portfolioId, details, custodian.linkProviderAccountId, response => {
        if (response.length === 1 && !response[0].linkedCustodianId === true) {
          handleLinkAccount(response);
          return;
        }

        setIsLoading(false);
        setLinkedAccounts(response);
        setSelectedLinkedAccounts(response.filter(item => !item.linkedCustodianId === true));
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (portfolioId, details, blockchainAddr, onSuccess = () => null, onError = () => null) => {
      dispatch(
        getLinkedAccounts(
          details.linkType || accountLinkingService.ZERION,
          "All",
          {
            portfolioId: portfolioId,
            providerId: details.linkProviderId,
            providerName: details.name,
            metaData: JSON.stringify({ address: blockchainAddr })
          },
          onSuccess,
          onError,
          () => {
            return isMountedRef.current;
          }
        )
      );
    },
    [dispatch]
  );

  const onContinueAccountsClicked = e => {
    handleLinkAccount(selectedLinkedAccounts);
  };

  const onAllAccountsAddedClick = e => {
    dispatch(unmarkCustodianAsLinking(portfolioId, originalCustodian));
    closeWindow();
  };

  const onAddrSubmit = useCallback(
    e => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!blockchainAddr || isLoading) {
        return;
      }

      resetTimerDate();
      clearTimeout(submitCallRef.current);
      setIsLoading(true);
      onZerionModalLoadingChange(true);
      setErrorMessage("");
      setErrorRetryMsg(null);

      const isAddrArray = Array.isArray(blockchainAddr);
      const currentAddr = isAddrArray ? iteratorRef.current.next().value : blockchainAddr;

      if (isAddrArray && !currentAddr) {
        setLinkedAccounts(iteratorRespAggregator.current);
        setSelectedLinkedAccounts(iteratorRespAggregator.current);
        return;
      }

      const submitCall = (tries = 0) => {
        onSubmit(
          portfolioId,
          details,
          currentAddr,
          response => {
            const isContinueBtnShown = document.querySelector(".zerion-component-continuebtn");
            if (isContinueBtnShown) {
              return;
            }

            if (isAddrArray) {
              onAddrSubmit();
              iteratorRespAggregator.current.push(...response);
              return;
            }

            if (response.filter(item => !item.linkedCustodianId === true).length === 0) {
              setShowAllAccountsAddedDialog(true);
              return;
            }

            const selectableAccounts = response.filter(item => !item.linkedCustodianId === true);
            if (selectableAccounts.length > 0) {
              handleLinkAccount(selectableAccounts);
            } else {
              setErrorMessage("");
              setErrorRetryMsg(null);
              setIsLoading(false);
              setLinkedAccounts(response);
              setSelectedLinkedAccounts(selectableAccounts);
            }
          },
          error => {
            const isContinueBtnShown = document.querySelector(".zerion-component-continuebtn");
            if (isContinueBtnShown) {
              return;
            }

            if (isAddrArray) {
              onAddrSubmit();
            }

            setIsLoading(false);
            onZerionModalLoadingChange(false);
            if (/.eth$/.test(blockchainAddr)) {
              setErrorMessage(i18n.t("zerionmodal.ensError"));
            } else if (error.errorCode === 1023) {
              setErrorMessage(i18n.t("zerionmodal.emptyError"));
            } else if (error.errorCode === 1003) {
              setErrorMessage(i18n.t("zerionmodal.invalidError"));
            } else if (error.errorCode === apiErrorCodes.TIMEOUT) {
              if (tries < 3) {
                resetTimerDate();
                submitCall(tries + 1);
                setErrorRetryMsg({
                  title: i18n.t("zerionmodal.genericErrorTitle"),
                  description: i18n.t("zerionmodal.genericErrorDesc")
                });
              } else {
                clearInterval(clearTimerIntervalId.current);
                setErrorRetryMsg({
                  title: i18n.t("zerionmodal.nothingGenericErrorTitle"),
                  description: i18n.t("zerionmodal.nothingGenericErrorDesc")
                });
              }
            } else {
              setErrorMessage(i18n.t("zerionmodal.genericError"));
            }
          }
        );
      };

      submitCall();
    },
    [blockchainAddr, details, isLoading, onSubmit, portfolioId, handleLinkAccount, onZerionModalLoadingChange]
  );

  useEffect(() => {
    const connectMetamask = () => {
      try {
        ethereum
          .request({ method: "wallet_requestPermissions", params: [{ eth_accounts: {} }] })
          .then(async permissionRes => {
            const [firstRespItem] = permissionRes;
            const { caveats } = firstRespItem || { caveats: [] };

            let exposedAccounts = null;
            caveats.forEach(item => {
              if (item.name === "exposedAccounts") {
                exposedAccounts = item.value;
              }
            });

            if (exposedAccounts) {
              setBlockchainAddr(exposedAccounts);
              return;
            }

            const accounts = await ethereum.request({ method: "eth_accounts" });
            setBlockchainAddr(accounts[0]);
          })
          .catch(err => {
            setErrorMessage(i18n.t("zerionmodal.genericError"));
          });
      } catch (err) {
        onMetamaskError();
      }
    };

    if (isMetamask && ethereum.isMetaMask) {
      connectMetamask();
    }
  }, [isMetamask, ethereum, onMetamaskError]);

  useEffect(() => {
    function* makeRangeIterator() {
      for (let i = 0; !!blockchainAddr[i]; i += 1) {
        yield blockchainAddr[i];
      }
      return null;
    }

    if (isMetamask && !isMetamaskNotSupported && blockchainAddr) {
      iteratorRef.current = makeRangeIterator();
      onAddrSubmit();
    }
    // eslint-disable-next-line
  }, [blockchainAddr]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "Escape") {
        onDismiss();
        return true;
      }
      return false;
    };

    addKeyboardEventListener(handleKeyDown);

    return () => {
      removeKeyboardEventListener(handleKeyDown);
    };
  }, [onDismiss]);

  useEffect(() => {
    const isAddrArray = Array.isArray(blockchainAddr);

    if (isAddrArray && selectedLinkedAccounts) {
      handleLinkAccount(selectedLinkedAccounts);
    }
  }, [blockchainAddr, selectedLinkedAccounts, handleLinkAccount]);

  const getAccountAssetNamesString = assetNames => {
    if (!assetNames === true) {
      return "";
    }
    var assets = assetNames.length <= 3 ? assetNames : assetNames.slice(0, 4);
    return assets.join(", ") + (assetNames.length > 3 ? " & more" : "");
  };
  const onLinkedAccountSelectionChange = (isChecked, account) => {
    if (isChecked === true) {
      setSelectedLinkedAccounts([...selectedLinkedAccounts, account]);
    } else {
      setSelectedLinkedAccounts(selectedLinkedAccounts.filter(item => item.id !== account.id));
    }
  };

  const getAccountLists = () => {
    if (!linkedAccounts === false && !selectedLinkedAccounts === false && isLoading === false) {
      if (mode === linkAccountMode.MANAGE) {
        return (
          <>
            <LinkedAccountsContainer>
              <TitleMessage>{i18n.t("manageAccount.newAccountsTitle")}</TitleMessage>
              {linkedAccounts.filter(item => !item.linkedCustodianId === true).length > 0 ? (
                linkedAccounts
                  .filter(item => !item.linkedCustodianId === true)
                  .map((account, index) => (
                    <LinkedAccountDetails key={index}>
                      <LinkedAccountNameCheckBox
                        label={
                          account.container.replace(/nft/i, "NFT") +
                          " • " +
                          formatNumberWithCurrency(
                            convertCurrency(account.balance, account.currency, portfolio.currency),
                            portfolio.currency
                          )
                        }
                        checked={selectedLinkedAccounts.findIndex(a => a.id === account.id) !== -1}
                        //||
                        //!account.linkedCustodianId === false}
                        //disabled={!account.linkedCustodianId === false}
                        onChange={isChecked => onLinkedAccountSelectionChange(isChecked, account)}
                      ></LinkedAccountNameCheckBox>
                      <LinkedAssetNames isManageFlow={true}>
                        {getAccountAssetNamesString(account.assetNames)}
                      </LinkedAssetNames>
                    </LinkedAccountDetails>
                  ))
              ) : (
                <MessageContainer>{i18n.t("manageAccount.noNewAccountsMessage")}</MessageContainer>
              )}
            </LinkedAccountsContainer>
            <LinkedAccountsContainer>
              <TitleMessage>{i18n.t("manageAccount.alreadyAddedAccountsTitle")}</TitleMessage>
              {linkedAccounts
                .filter(item => !item.linkedCustodianId === false)
                .map((account, index) => (
                  <LinkedAccountDetails key={index}>
                    <LinkedAccountName>
                      {account.container.replace(/nft/i, "NFT") +
                        " • " +
                        formatNumberWithCurrency(
                          convertCurrency(account.balance, account.currency, portfolio.currency),
                          portfolio.currency
                        )}
                    </LinkedAccountName>
                    <LinkedAssetNames>{getAccountAssetNamesString(account.assetNames)}</LinkedAssetNames>
                  </LinkedAccountDetails>
                ))}
            </LinkedAccountsContainer>
          </>
        );
      } else {
        return (
          <LinkedAccountsContainer>
            <TitleMessage>{i18n.t("zerionmodal.successMessage")}</TitleMessage>
            {linkedAccounts.map((account, index) => (
              <LinkedAccountDetails key={index}>
                <LinkedAccountName>
                  {account.container.replace(/nft/i, "NFT") +
                    " • " +
                    formatNumberWithCurrency(
                      convertCurrency(account.balance, account.currency, portfolio.currency),
                      portfolio.currency
                    )}
                </LinkedAccountName>
                <LinkedAssetNames>{getAccountAssetNamesString(account.assetNames)}</LinkedAssetNames>
              </LinkedAccountDetails>
            ))}
          </LinkedAccountsContainer>
        );
      }
    }
  };

  return (
    <LinkDialog>
      <SyncComponent store={store} />
      <Title>
        {showTitleImg && (
          <TitleImage src={imgUrl} alt={details.linkProviderId} onLoad={titleImageLoaded} onError={titleImageError} />
        )}
        {showTitleText && titleTxt}
      </Title>
      <FormContainer>
        {isMetamask &&
        (!blockchainAddr || Array.isArray(blockchainAddr)) &&
        !isMetamaskNotSupported &&
        (!errorMessage && !errorRetryMsg) ? (
          <ModalLoader />
        ) : (
          <Form>
            <InputTitle>{i18n.t(inputTitle)}</InputTitle>
            <BlockchainAddrWrapper>
              <BlockchainAddrField
                data-private
                value={blockchainAddr}
                placeholder={i18n.t(`zerionmodal.inputPlaceholder${isMetamask ? "MetaMask" : ""}`)}
                onChange={handleAddrChange}
                onKeyDown={handleAddrKeyPress}
                isLoading={isLoading}
                readOnly={(isMetamask && !isMetamaskNotSupported) || !linkedAccounts === false}
                disabled={isLoading}
                autoFocus
              />
              {(!isMetamask || isMetamaskNotSupported) && (!isLoading && !linkedAccounts === true) && (
                <GetAccountsButton
                  data-cy="getAccountsButton"
                  onClick={onAddrSubmit}
                  isDisabled={!blockchainAddr}
                  isLoading={isLoading}
                >
                  {i18n.t("add")}
                </GetAccountsButton>
              )}
            </BlockchainAddrWrapper>
            {getAccountLists()}
          </Form>
        )}
        {!canContinue &&
          (errorMessage ? (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          ) : (
            errorRetryMsg && (
              <ErrorRetryMsg>
                <ErrorRetryTitle>{errorRetryMsg.title}</ErrorRetryTitle>
                <ErrorRetryDesc
                  dangerouslySetInnerHTML={{
                    __html: errorRetryMsg.description.replace(/%s%/, timerValue)
                  }}
                />
              </ErrorRetryMsg>
            )
          ))}
        {isLoading === true && <FancyGetAccountsLoader initialMessage={i18n.t("zerionmodal.loadingMessage")} />}
      </FormContainer>
      {canContinue && (
        <ContinueButton
          className="zerion-component-continuebtn"
          title={i18n.t("continue")}
          isDisabled={isLoading === true}
          //|| !selectedLinkedAccounts === true || selectedLinkedAccounts.length === 0}
          onClick={onContinueAccountsClicked}
        />
      )}
      {showAllAccountsAddedDialog === true && (
        <ConfirmationDialog
          title={i18n.t("zerionmodal.allAccountsConnectedTitle")}
          description={i18n.t("zerionmodal.allAccountsConnectedDescription")}
          positiveButtonTitle={i18n.t("ok")}
          handlePositiveButtonClick={onAllAccountsAddedClick}
        />
      )}
    </LinkDialog>
  );
};

export default ZerionComponent;
