import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { isAppInWhiteLabelMode } from "@kubera/common";

const isWhiteLabelSite = isAppInWhiteLabelMode();

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 17px 0 20px;
  min-width: 222px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.kuberaHeartsDesktopBannerCLR};
`;

const Desc = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.kuberaHeartsDesktopBannerCLR};

  a {
    color: ${props => props.theme.kuberaHeartsDesktopBannerCLR};
  }
`;

const KuberaHeartsBanner = () => {
  const showBanner = !isWhiteLabelSite;

  if (showBanner) {
    return (
      <Container>
        <Title>{i18n.t("kuberaHeartsBanner.title")}</Title>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("kuberaHeartsBanner.desc")
          }}
        ></Desc>
      </Container>
    );
  }

  return null;
};

export default KuberaHeartsBanner;
