import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";

const ComponentDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 680px;
  margin-top: 164px;
  padding: 50px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 30px;
  white-space: pre-wrap;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PrimaryBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
`;

const MFADisabledAlert = ({ isOpen = false, onDismiss = () => null }) => {
  if (isOpen) {
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <ComponentDialog>
          <Title>{i18n.t("twoFA.disabledAlertTitle")}</Title>
          <Desc>{i18n.t("twoFA.disabledAlertDesc")}</Desc>
          <BtnBlock>
            <PrimaryBtn title={i18n.t("twoFA.disabledAlertPrimaryBtn")} onClick={onDismiss} />
          </BtnBlock>
        </ComponentDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default MFADisabledAlert;
