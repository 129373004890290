import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Line, Chart } from "react-chartjs-2";
import { useTheme } from "theme";
import { isMobile, userMaskAllValuesSelector } from "@kubera/common";

const isMobileDevice = isMobile();

const Container = styled.div`
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  transform: translate3d(0, 0, 0);
`;

const ChartBuffer = styled.div`
  // width: 100%;
  min-height: 15px;
  background: ${props => props.theme[props.bgThemeColor]};
`;

class LineChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);

    this.chartRef = React.createRef();
    this.containerRef = React.createRef();
    this.chartPadding = React.createRef();
    this.isTouching = false;

    this.dottedLinePlugin = {
      afterDraw: (chart, easing) => {
        if (
          chart.tooltip._active &&
          chart.tooltip._active.length &&
          (isMobileDevice === false || this.isTouching === true)
        ) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = 25;
          const bottomY = chart.height;
          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([2, 1]);
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = this.isDarkModeActive() ? "rgba(255, 255, 255,0.4)" : "rgba(0,0,0,0.4)";
          ctx.stroke();
          ctx.restore();
        }
      }
    };

    // Draw dotted line on the data points user is hovering on
    Chart.pluginService.register(this.dottedLinePlugin);

    // Always show tooltip near first data set
    Chart.Tooltip.positioners.custom = function(elements, eventPosition) {
      return {
        x: elements[0]._model.x,
        y: 0
      };
    };
  }

  componentWillUnmount() {
    Chart.pluginService.unregister(this.dottedLinePlugin);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.data !== nextProps.data || this.props.bgcolor !== nextProps.bgcolor;
  }

  handleMouseLeave() {
    if (this.props.onHoverOverSection) {
      this.props.onHoverOverSection(null);
    }
  }

  handleTouchEnd(id) {
    setTimeout(() => {
      const element = document.getElementById(`chartjs-tooltip-${id}`);
      if (!element === false && !element.parentNode === false) {
        element.parentNode.removeChild(element);
      }

      this.isTouching = false;
      this.handleMouseLeave();
    }, 300);
  }

  handleTouchStart() {
    this.isTouching = true;
    this.chartRef.current.chartInstance.update();
  }

  datasetKeyProvider() {
    return window.btoa(Math.random()).substring(0, 12);
  }

  getChartPadding() {
    // This is done to ensure the hover points near the
    // container boundaries don't get cut off
    if (this.chartPadding.current) {
      return this.chartPadding.current;
    }
    var padding = this.props.chartPadding;
    for (const key in padding) {
      if (key === "left" || key === "right") {
        padding[key] = padding[key] + 5;
      }
    }
    this.chartPadding.current = padding;
    return padding;
  }

  chartDataHasNegativeValue(chartData) {
    for (const dataset of chartData.datasets) {
      for (const data of dataset.data) {
        if (data < 0) {
          return true;
        }
      }
    }
    return false;
  }

  getOptions(id, chartData, scaleType = undefined) {
    const showZeroLine = this.props.showZeroLine === true && this.chartDataHasNegativeValue(chartData);
    return {
      responsive: true,
      animation: {
        duration: 0
      },
      layout: {
        padding: this.getChartPadding()
      },
      maintainAspectRatio: false,
      title: {
        display: false
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false,
        mode: "index",
        position: "custom",
        intersect: false,
        caretPadding: 0,
        xPadding: 0,
        yPadding: 8,
        bodyFontSize: 12,
        callbacks: {
          title: function(tooltipItems, data) {
            const label = tooltipItems[0].label;
            if (!data.labelFormat === false) {
              return data.labelFormat(label);
            }
            return label;
          },
          label: (tooltipItem, data) => {
            return "";
          }
        },
        custom: tooltipModel => {
          // Tooltip Element
          var tooltipEl = document.getElementById(`chartjs-tooltip-${id}`);

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.style["background"] = "transparent";
            tooltipEl.style["z-index"] = 100000;
            tooltipEl.id = `chartjs-tooltip-${id}`;
            tooltipEl.innerHTML = "<div></div>";
            this.containerRef.current.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          // Set Text
          if (tooltipModel.body) {
            var container = tooltipEl.querySelector("div");
            container.innerHTML = tooltipModel.title[0];
          }

          // `this` will be the overall tooltip
          const chartInstance = this.chartRef.current.chartInstance;

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            chartInstance.canvas.offsetLeft + tooltipModel.caretX - tooltipEl.offsetWidth / 2 + "px";
          tooltipEl.style.top = 0;
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.color = this.isDarkModeActive() ? "white" : "black";
          tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
        }
      },
      hover: {
        mode: "index",
        intersect: false,
        animationDuration: 0
      },
      onHover: (e, element) => {
        if (this.props.onHoverOverSection && element.length > 0) {
          this.props.onHoverOverSection(element[0]._index);
        }
      },
      onClick: (e, element) => {
        if (this.props.onClickOverSection && element.length > 0) {
          this.props.onClickOverSection(element[0]._index);
        }
      },
      scales: {
        xAxes: [
          {
            display: false,
            type: scaleType
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: showZeroLine,
              drawTicks: false,
              lineWidth: 0,
              zeroLineWidth: 2,
              zeroLineColor: this.props.theme.lineChartZeroLineColor,
              zeroLineBorderDash: [5, 5]
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    };
  }

  getDataWithOptions(data) {
    const defaultOptions = {
      borderColor: isMobileDevice ? "rgba(0, 0, 0, 0.4)" : "rgba(128, 0, 255, 1)",
      backgroundColor: !this.props.bgcolor ? "#855CF828" : this.props.bgcolor,
      fill: "start",
      borderWidth: 1,
      pointRadius: 4,
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBorderColor: "rgba(0, 0, 0, 0)"
    };
    const options = {};

    data.datasets = data.datasets.map(dataSet => {
      const temp = { ...dataSet, ...defaultOptions, ...options };
      return temp;
    });

    if (data.options) {
      data.datasets = data.datasets.map((dataSet, index) => {
        return { ...dataSet, ...data.options[index] };
      });
    }
    return data;
  }

  isDarkModeActive() {
    return !this.props.isWithinDetails ? this.props.themeMode === "dark" : false;
  }

  render() {
    const {
      className,
      chartHeight,
      data,
      onHoverOverSection,
      onClickOverSection,
      themeMode,
      showBuffer = false,
      id = 1,
      scaleType,
      ...other
    } = this.props;
    if (!data === true) {
      return null;
    }

    const chartData = this.getDataWithOptions(data);

    return (
      <>
        <Container
          ref={this.containerRef}
          className={`chartjs-container ${className} ${themeMode}`}
          {...other}
          onMouseLeave={this.handleMouseLeave}
          onTouchEnd={() => {
            this.handleTouchEnd(id);
          }}
          onTouchStart={this.handleTouchStart}
        >
          <Line
            ref={this.chartRef}
            data={chartData}
            options={this.getOptions(id, chartData, scaleType)}
            height={chartHeight}
            datasetKeyProvider={this.datasetKeyProvider}
          />
        </Container>
        {showBuffer &&
          (chartData.datasets && chartData.datasets.length === 1 ? (
            <ChartBuffer className="chartjs-buffer" bgThemeColor="chartBgColor" />
          ) : (
            <ChartBuffer
              className="chartjs-buffer"
              bgThemeColor={isMobileDevice ? "chartBgOverlapColor" : "networthChartBgOverlapColor"}
            />
          ))}
      </>
    );
  }
}

const LineChartComponentWrapper = props => {
  const theme = useTheme();
  const useMaskedValues = useSelector(userMaskAllValuesSelector);

  return (
    <LineChartComponent
      {...props}
      theme={theme}
      themeMode={theme.mode}
      bgcolor={props.chartBgColor || theme.chartBgColor}
      showZeroLine={useMaskedValues === false}
    />
  );
};

export default LineChartComponentWrapper;
