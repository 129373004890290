import React, { useMemo } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import { currentPortfolioSelector } from "@kubera/common";
import { useParentBeneficiaryDetails } from "utilities/CustomHooks";

const Content = styled.div`
  width: 316px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19.6px;
  letter-spacing: normal;
  padding-top: 10px;
  white-space: pre-wrap;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const LinkedBeneficiaryDetails = () => {
  const { id: currentPortfolioId } = useSelector(currentPortfolioSelector);

  const beneficiaryDetails = useParentBeneficiaryDetails(currentPortfolioId);
  return (
    <Content>
      {i18n
        .t("beneficiary.introWhenLinked")
        .replace(/%portfolios%/, beneficiaryDetails.portfolioNames.join(", "))
        .replace(/%beneficiaries%/, beneficiaryDetails.beneficiaryNames.join(", "))
        .replace(/%angels%/, beneficiaryDetails.angelNames.join(", "))}
    </Content>
  );
};

export default LinkedBeneficiaryDetails;
