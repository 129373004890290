import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useTheme } from "theme";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType,
  getGridDataFromPortfolio,
  filterDataFromPortfolioDetails
} from "components/grid/GridDataModel";
import {
  updateCustodian,
  getUuid,
  insertCustodian,
  updateSection,
  lastDashboardUpdateTsSelector,
  dashboardEntitesToUpdateSelector,
  currentPortfolioSelector,
  archiveCustodian,
  bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus,
  bulkActionSource,
  archiveSection,
  archiveSheet,
  insertSection,
  ApiClient,
  userPreferencesSelector,
  updateUserPreferences,
  moveCustodian,
  deleteCustodian,
  deleteCustodianAction,
  getTickerUsingId,
  isInitialTipShown,
  fetchCustodianTickerDetails,
  showToastTip,
  isAppInViewMode,
  currentPortfolioCurrencySelector,
  getTickerUsingShortName,
  userMaskAllValuesSelector,
  isReadOnlyWlClient
} from "@kubera/common";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import ArchivedComponent from "components/archived/ArchivedComponent";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { downloadFile } from "utilities/FileUtils";
import EmptyGridComponent from "components/grid/EmptyGridComponent";
import CustodianDetailsWrapperComponent from "components/custodian_details/CustodianDetailsWrapperComponent";
import { category } from "components/dashboard/DashboardComponentExports";
import { DialogOverlay } from "components/dialog/DialogOverlay";

const Grid = styled(GridComponentWrapper)`
  width: 100%;
  padding-bottom: 44px;
`;

const EmptyGridMessage = styled(EmptyGridComponent)`
  margin-top: 50px;
`;

const EmptyGridDescription = styled.div`
  margin-top: -45px;
`;

class InsuranceComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { gridData: this.getGridData(props.portfolio) };

    this.handleInitialTipClick = this.handleInitialTipClick.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleToggleSectionStarStatus = this.handleToggleSectionStarStatus.bind(this);
    this.handleToggleSectionUpdatedStatus = this.handleToggleSectionUpdatedStatus.bind(this);
    this.handleToggleSheetUpdatedStatus = this.handleToggleSheetUpdatedStatus.bind(this);
    this.handleToggleSheetStarStatus = this.handleToggleSheetStarStatus.bind(this);
    this.handleAddNewSection = this.handleAddNewSection.bind(this);
    this.handleRemoveSection = this.handleRemoveSection.bind(this);
    this.handleRemoveSheet = this.handleRemoveSheet.bind(this);
    this.handleGridContextMenuSelection = this.handleGridContextMenuSelection.bind(this);
    this.handleSheetContextMenuSelection = this.handleSheetContextMenuSelection.bind(this);
    this.handleSectionContextMenuSelection = this.handleSectionContextMenuSelection.bind(this);
    this.handleReorderRowsForSections = this.handleReorderRowsForSections.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.getEmptySection = this.getEmptySection.bind(this);

    this.CustodianDetailsWrapperRef = React.createRef();
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.portfolio.details !== this.props.portfolio.details) {
      this.setState({ gridData: this.getGridData(this.props.portfolio) });
    }
    if (this.state.gridData.currency !== this.props.portfolio.currency) {
      this.setState({ gridData: this.getGridData(this.props.portfolio) });
    }
    if (oldProps.lastDashboardUpdateTs !== this.props.lastDashboardUpdateTs) {
      const entitiesToUpdate = this.props.dashboardEntitesToUpdate;
      const newGridData = this.getGridData(this.props.portfolio, this.state.gridData, entitiesToUpdate);
      this.setState({ gridData: newGridData });
    }
    if (oldProps.maskAllValues !== this.props.maskAllValues) {
      this.setState({ gridData: this.getGridData(this.props.portfolio) });
    }
  }

  getGridData(portfolio, currentGridData = null, entitiesToUpdate = []) {
    const maskValues = this.props.maskAllValues;
    const gridData = getGridDataFromPortfolio(
      portfolio,
      category.INSURANCE,
      this.props.portfolio.currency,
      (sheet, sections) => {
        const currentSheet = this.getEmptySheet(sheet.sortKey);
        currentSheet.id = sheet.id;
        currentSheet.name = sheet.name;
        currentSheet.sections = sections;
        return currentSheet;
      },
      (index, section, rows) => {
        const currentSection = this.getEmptySection(index, section.sortKey, this.isReadOnly());
        currentSection.id = section.id;
        currentSection.name = section.name;
        currentSection.columnSortKey = section.columnSortKey;
        currentSection.columnSortOrder = section.columnSortOrder;
        currentSection.forceShowTitle = true;
        if (rows.length > 0) {
          currentSection.rows = rows;
        }
        return currentSection;
      },
      custodian => {
        const currentRow = this.getEmptyRow(custodian.sortKey, this.isReadOnly());
        currentRow.id = custodian.id;
        currentRow.cells[1].value = custodian.name;
        currentRow.cells[1].description = custodian.description;
        currentRow.cells[2].value = custodian.accountNumber
          ? maskValues
            ? "XXXX"
            : custodian.accountNumber
          : custodian.accountNumber;
        currentRow.cells[3].value = custodian.cost;
        currentRow.cells[3].invalidInputText = custodian.costInvalidInputText;
        currentRow.cells[3].tickerInfo = custodian.costTickerInfo;
        currentRow.cells[3].loading = custodian.fetchingCostTickerInfo === true;
        currentRow.cells[4].value = custodian.value;
        currentRow.cells[4].invalidInputText = custodian.valueInvalidInputText;
        currentRow.cells[4].tickerInfo = custodian.valueTickerInfo;
        currentRow.cells[4].loading = custodian.fetchingValueTickerInfo === true;

        currentRow.cells.forEach(cell => {
          cell.isEditable = this.isReadOnly() === false && this.props.maskAllValues === false;
        });

        if (custodian.costTickerId) {
          currentRow.cells[3].currency = getTickerUsingId(custodian.costTickerId).shortName;
        }
        if (custodian.costExchangeRate) {
          currentRow.cells[3].exchangeRateDetails = custodian.costExchangeRate;
        }
        if (custodian.valueTickerId) {
          currentRow.cells[4].currency = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[4].exchangeRateDetails = custodian.valueExchangeRate;
        }

        if (!custodian.valueTickerInfo === false) {
          const tickerErrorCell = new GridCellData(cellType.TICKER_ERROR, "", null);
          currentRow.cells[5] = tickerErrorCell;
        }

        currentRow.cells[3].invalidInputText = custodian.costInvalidInputText;
        currentRow.cells[3].tickerInfo = custodian.costTickerInfo;
        currentRow.cells[3].loading = custodian.fetchingCostTickerInfo === true;

        currentRow.cells[4].invalidInputText = custodian.valueInvalidInputText;
        currentRow.cells[4].tickerInfo = custodian.valueTickerInfo;
        currentRow.cells[4].loading = custodian.fetchingValueTickerInfo === true;

        return currentRow;
      },
      currentGridData,
      entitiesToUpdate
    );

    gridData.forceShowSheetsTitles = false;
    gridData.isEditable = this.isReadOnly() === false && this.props.maskAllValues === false;
    gridData.forceShowArchivedItems = this.props.isReadOnlyWlClient;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection = (forIndex, sortKey, isViewOnlyMode = false) => {
    const padColumn = new GridColumnData(null, false, false, true);
    const nameColumn = new GridColumnData("", true, isViewOnlyMode === false, false);
    const policyNumberColumn = new GridColumnData("Policy Number", true, isViewOnlyMode === false, false);
    const premiumColumn = new GridColumnData("Annual Premium", true, isViewOnlyMode === false, false);
    const insuredValueColumn = new GridColumnData("Insured Value", true, isViewOnlyMode === false, false);
    const detailsColumn = new GridColumnData(null, true, false, true);
    const optionsColumn = new GridColumnData(null, true, false, true);

    var columns = [
      padColumn,
      nameColumn,
      policyNumberColumn,
      premiumColumn,
      insuredValueColumn,
      detailsColumn,
      optionsColumn
    ];
    if (isViewOnlyMode === true) {
      columns = [
        padColumn,
        nameColumn,
        policyNumberColumn,
        premiumColumn,
        insuredValueColumn,
        detailsColumn,
        padColumn
      ];
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [
        this.getEmptyRow("1", this.isReadOnly()),
        this.getEmptyRow("2", this.isReadOnly()),
        this.getEmptyRow("3", this.isReadOnly())
      ],
      columns,
      4,
      4,
      false
    );
    sectionData.getContextMenuItems = section => {
      const InsurancePortolioData = filterDataFromPortfolioDetails(
        this.props.portfolio.details,
        void 0,
        void 0,
        sheet => sheet.category === category.INSURANCE
      );

      if (InsurancePortolioData.sections.length > 1) {
        return [[contextMenuItemType.RENAME, contextMenuItemType.REMOVE_SECTION]];
      } else {
        return [[contextMenuItemType.RENAME]];
      }
    };
    sectionData.getFooterContextMenuItems = () => {
      return [
        [contextMenuItemType.SECTION_ADD_ROW],
        [{ ...contextMenuItemType.SECTION_ADD_SECTION, description: "To group similar insurances" }]
      ];
    };
    return sectionData;
  };

  getEmptyRow(sortKey, isViewOnlyMode = false) {
    const detailsCell = new GridCellData(cellType.DETAILS, "", null);
    detailsCell.showHint = false;
    detailsCell.toolTip = i18n.t("gridCell.detailsButtonToolTip");
    const nameCell = new GridCellData(cellType.TEXT, "Policy Name", null);
    nameCell.showHint = false;
    const policyNumberCell = new GridCellData(cellType.TEXT, "Number", null);
    const premiumCell = new CurrencyCellData(cellType.CURRENCY, "Premium", null, this.props.portfolio.currency);
    premiumCell.useRateFromExchangeRateDetails = true;
    const padCell = new GridCellData(cellType.PADDING, "", null);
    padCell.toolTip = i18n.t("gridCell.starButtonToolTip");
    padCell.padding = "0";
    const insuredValueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.portfolio.currency);
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    optionsCell.toolTip = i18n.t("gridCell.insuranceOptionsButtonToolTip");

    var cells = [padCell, nameCell, policyNumberCell, premiumCell, insuredValueCell, detailsCell, optionsCell];
    if (isViewOnlyMode === true || this.props.maskAllValues === true) {
      padCell.padding = "0 14px 0 0";

      const rightPadCell = new GridCellData(cellType.PADDING, "", null);
      rightPadCell.padding = "0 14px 0 0";
      cells = [padCell, nameCell, policyNumberCell, premiumCell, insuredValueCell, detailsCell, rightPadCell];
    }

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      const name = cells[1].value;
      const insuredValue = cells[4].value;

      if (name && name.length > 0 && (insuredValue !== null && insuredValue !== undefined)) {
        return true;
      }
      return false;
    });
    rowData.getContextMenuItems = row => {
      if (isViewOnlyMode === true || this.props.maskAllValues === true) {
        return [];
      }

      const REMOVE_OPTION = [contextMenuItemType.DELETE];

      return [[contextMenuItemType.INSERT_ABOVE], REMOVE_OPTION];
    };
    rowData.isDragEnabled = isViewOnlyMode === false;
    rowData.defaultCellIndexToSelect = 1;
    return rowData;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    const portfolioTicker = getTickerUsingShortName(this.props.portfolio.currency);

    this.props.updateCustodian(isFirstEdit, updatedRow.id, {
      name: updatedRow.cells[1].value,
      accountNumber: updatedRow.cells[2].value,
      cost: updatedRow.cells[3].value,
      costTickerId: updatedRow.cells[3].getTickerId() || portfolioTicker.id,
      costExchangeRate: updatedRow.cells[3].exchangeRateDetails,
      costInvalidInputText: updatedRow.cells[3].invalidInputText,
      costTickerInfo: updatedRow.cells[3].tickerInfo,
      value: updatedRow.cells[4].value,
      valueTickerId: updatedRow.cells[4].getTickerId() || portfolioTicker.id,
      valueExchangeRate: updatedRow.cells[4].exchangeRateDetails,
      valueInvalidInputText: updatedRow.cells[4].invalidInputText,
      valueTickerInfo: updatedRow.cells[4].tickerInfo,
      currency: updatedRow.cells[4].currency,
      star: !updatedRow.cells[0].value ? 0 : 1,
      past: updatedRow.isUpdated === true ? 0 : 1,
      sortKey: updatedRow.sortKey,
      isCompleted: updatedRow.wasRowEverComplete === true ? 1 : 0
    });
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {
    const sectionId = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].id;
    this.props.insertCustodian(this.props.portfolio.id, sectionId, row.id, row.sortKey);
  }

  handleRemoveRow(sheetIndex, sectionIndex, rowIndex, row) {
    if (row.isEmpty() === true && !row.tsModified === true) {
      this.props.deleteLocalCustodian(this.props.portfolio.id, row.id);
      return;
    }
  }

  handleSectionUpdate(sheetIndex, sectionIndex, updatedSection) {
    const sheetId = this.state.gridData.sheets[sheetIndex].id;
    this.props.updateSection(this.props.portfolio.id, sheetId, updatedSection.id, {
      name: updatedSection.name,
      sortKey: updatedSection.sortKey,
      expanded: updatedSection.isCollapsed === true ? 0 : 1,
      columnSortKey: updatedSection.columnSortKey,
      columnSortOrder: updatedSection.columnSortOrder
    });
  }

  handleAddNewSection(sheetIndex, sectionIndex, section) {
    const sheetId = this.state.gridData.sheets[sheetIndex].id;
    this.props.insertSection(this.props.portfolio.id, sheetId, section);
  }

  handleRemoveSection(sheetIndex, sectionIndex, section) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    this.props.archiveSection(sheet.id, section.id);
  }

  handleSheetUpdate(sheetIndex, updatedSheet) {
    this.props.updateSheet(
      this.props.portfolio.id,
      updatedSheet.id,
      updatedSheet.name,
      updatedSheet.sortKey,
      category.INSURANCE
    );
  }

  handleRemoveSheet(sheetIndex, sheet) {
    const isLastSheet = this.state.gridData.sheets.length === 0;

    this.props.archiveSheet(sheet.id, isLastSheet);
  }

  handleReorderRowsForSections(sheetIndex, sourceSection, destinationSection, updatedRow) {
    this.props.moveCustodian(sourceSection.id, destinationSection.id, updatedRow.id, updatedRow.sortKey);
  }

  handleDetailsClick(sheetIndex, sectionIndex, rowIndex, row) {
    this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row);
  }

  handleToggleSectionStarStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianStarStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSheetStarStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianStarStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleToggleSheetUpdatedStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleGridContextMenuSelection(menuItem) {
    if (menuItem.id === contextMenuItemType.ARCHIVED.id) {
      ArchivedComponent.show(this.props.history, this.props.location);
    }
  }

  handleSheetContextMenuSelection(sheetIndex, menuItem) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    if (menuItem.id === contextMenuItemType.DOWNLOADEXCEL.id) {
      ApiClient.getSheetDownloadUrl(this.props.portfolio.id, sheet.id)
        .then(url => {
          downloadFile(url, sheet.name);
        })
        .catch(apiError => {});
    } else if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSheetUpdatedStatus(sheetIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSheetStarStatus(sheetIndex);
    }
  }

  handleSectionContextMenuSelection(sheetIndex, sectionIndex, menuItem) {
    if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSectionStarStatus(sheetIndex, sectionIndex);
    }
  }

  handleInitialTipClick() {
    this.props.updateUserPreferences({
      isInsuranceInitialTipShownForPortfolio: [
        ...this.props.userPreferences.isInsuranceInitialTipShownForPortfolio,
        this.props.portfolio.id
      ]
    });

    const newGridData = this.state.gridData;
    newGridData.autoFocusFirstCell = true;
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.ARCHIVE.id) {
      const sectionId = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].id;
      this.props.archiveCustodian(sectionId, row.id, row.isEmpty());
    } else if (menuItem.id === contextMenuItemType.DELETE.id) {
      const sheet = this.state.gridData.sheets[sheetIndex];
      const section = sheet.sections[sectionIndex];

      if (section.rows.length === 1) {
        return;
      }

      DialogOverlay.forceDismiss(this.props.history, this.props.location);
      this.props.deleteCustodian(row.id, row.isEmpty());
    }
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === true) {
      setTimeout(() => {
        this.props.showToastTip("TIP", i18n.t("stockNotSupportedTip"), null, 10000);
      }, 500);
    } else {
      this.props.fetchCustodianTickerDetails(row.id, cellIndex === 3);
    }
  }

  shouldShowEmptyState() {
    return this.props.isInitialTipShown === false && this.state.gridData.isEmpty() === true;
  }

  render() {
    const gridData = this.state.gridData;
    const shouldShowEmptyState = this.shouldShowEmptyState();

    if (this.props.detailsOnlyRef) {
      return (
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.props.detailsOnlyRef}
          category={category.INSURANCE}
        />
      );
    }

    if (shouldShowEmptyState === true) {
      return (
        <EmptyGridMessage
          title={i18n.t("insuranceComponent.emptyTitle")}
          titleMargin={{ marginBottom: "30px", marginLeft: "0", marginRight: "0" }}
          description={<EmptyGridDescription>{i18n.t("insuranceComponent.emptyDescription")}</EmptyGridDescription>}
          buttonTitle={i18n.t("insuranceComponent.emptyButtonTitle")}
          onClick={this.handleInitialTipClick}
          isInsuranceComponent={true}
          isReadOnly={this.isReadOnly()}
        />
      );
    }

    return (
      <>
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.CustodianDetailsWrapperRef}
          category={category.INSURANCE}
        />
        <Grid
          title={i18n.t("debtsComponent.title")}
          gridData={gridData}
          getEmptyRow={this.getEmptyRow}
          getEmptySection={this.getEmptySection}
          onChange={this.handleChange.bind(this)}
          onRowUpdate={this.handleRowUpdate}
          onAddNewRow={this.handleAddNewRow}
          onRemoveRow={this.handleRemoveRow}
          onSectionUpdate={this.handleSectionUpdate}
          onReorderRows={this.handleReorderRowsForSections}
          onAddNewSection={this.handleAddNewSection}
          onRemoveSection={this.handleRemoveSection}
          onSheetUpdate={this.handleSheetUpdate}
          onRemoveSheet={this.handleRemoveSheet}
          onDetailsClick={this.handleDetailsClick}
          onSheetContextMenuSelection={this.handleSheetContextMenuSelection}
          onGridContextMenuSelection={this.handleGridContextMenuSelection}
          onSectionContextMenuSelection={this.handleSectionContextMenuSelection}
          onRowContextMenuSelection={this.handleRowContextMenuSelection}
          userPreferences={this.props.userPreferences}
          updateUserPreferences={this.props.updateUserPreferences}
          onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
          location={this.props.location}
          history={this.props.history}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  lastDashboardUpdateTs: lastDashboardUpdateTsSelector(state),
  dashboardEntitesToUpdate: dashboardEntitesToUpdateSelector(state),
  portfolio: currentPortfolioSelector(state),
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  userPreferences: userPreferencesSelector(state),
  isInitialTipShown: isInitialTipShown(state, currentPortfolioSelector(state), category.INSURANCE),
  maskAllValues: userMaskAllValuesSelector(state),
  isReadOnlyWlClient: isReadOnlyWlClient(state)
});

const mapDispatchToProps = {
  updateCustodian: updateCustodian,
  insertCustodian: insertCustodian,
  archiveCustodian: archiveCustodian,
  deleteCustodian: deleteCustodian,
  deleteLocalCustodian: deleteCustodianAction,
  updateSection: updateSection,
  insertSection: insertSection,
  bulkChangeCustodianStarStatus: bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus: bulkChangeCustodianUpdatedStatus,
  archiveSection: archiveSection,
  archiveSheet: archiveSheet,
  updateUserPreferences: updateUserPreferences,
  moveCustodian: moveCustodian,
  fetchCustodianTickerDetails: fetchCustodianTickerDetails,
  showToastTip: showToastTip
};

const InsuranceComponentWrapper = props => {
  const theme = useTheme();

  return <InsuranceComponent {...props} theme={theme} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InsuranceComponentWrapper));
