import React from "react";
import styled from "styled-components"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { dismissToastAction, toastSelector, toastType, getMetaKeyName, isMobile } from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import dismissIcon from "assets/images/dismiss_toast_icon.svg";
import dismissIconDark from "assets/images/dismiss_toast_icon_dark.svg";
import { withRouter } from "@kubera/common";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import requestIdleCallback from "utilities/requestIdleCallback";

const isMobileDevice = isMobile();

const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: ${isMobileDevice ? "-1px" : "50px"};
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10000000;
`;

const ToastContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${isMobileDevice ? "100%" : "fit-content"};
  max-width: ${props => (props.isMobile ? "100%" : "80%")};
  height: fit-content;
  min-height: ${props => (props.isMobile ? "70px" : "")};
  padding-top: ${isMobileDevice ? "27px" : "8px"};
  padding-bottom: ${isMobileDevice ? "35px" : "8px"};
  padding-left: ${isMobileDevice ? "20px" : "15px"};
  padding-right: ${isMobileDevice ? "20px" : "15px"};
  background: ${props => props.theme.mobileBackgroundIOSBanner};
  color: ${props => props.theme.mobileTxtColorIOS};
  border-radius: ${props => (props.isMobile ? "0" : "30px")};
  font-style: normal;
  font-weight: normal;
  font-size: ${isMobileDevice ? "16px" : "13px"};
  line-height: ${isMobileDevice ? "19.36px" : "16px"};
  cursor: default;
`;

const DismissButton = styled.div`
  width: 18px;
  height: 18px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${props => (props.theme.mode === "default" ? dismissIcon : dismissIconDark)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${isMobileDevice ? "12px 12px" : "8px 8px"};
  cursor: pointer;
`;

const MessageContainer = isMobileDevice
  ? styled.span`
      flex: 1;
    `
  : styled.span``;

const Message = styled.span``;

const MessageButton = styled.span`
  border-bottom: ${props => `1px solid ${isMobile ? props.theme.mobileTxtColorIOS : "white"}`};
  cursor: pointer;
  margin-left: 5px;
`;

class ToastComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentToast: props.toast, isMouseInsideToast: false };
    this.timer = null;
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.containerRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.toast !== null && this.props.toast === null) {
      this.dismiss(null);
      return;
    }
    if (this.props.toast === null) {
      return;
    }

    if (oldProps.toast === null || oldProps.toast.id !== this.props.toast.id) {
      if (this.state.currentToast !== null && !this.state.currentToast.onNegativeAction === false) {
        this.handleNegativeAction(this.state.currentToast);
      }
      this.show(this.props.toast);
    }

    if (this.state.currentToast && this.state.currentToast.dismissOnNavigationToCategory) {
      if (this.state.currentToast.dismissOnNavigationToCategory === DashboardComponentExports.getCurrentCategory()) {
        this.handleNegativeAction(this.state.currentToast);
      }
    }
  }

  handleOutsideClick(e) {
    if (!this.containerRef || !this.containerRef.current) {
      return;
    }
    if (this.containerRef.current.contains(e.target)) {
      return;
    }
    if (this.state.currentToast.dismissOnOutsideClick === false) {
      return;
    }

    setTimeout(() => {
      this.handleNegativeAction(this.state.currentToast);
    }, 50);
  }

  handlePositiveAction(toast) {
    if (toast === null) {
      return;
    }
    if (toast.onPositiveAction) {
      toast.onPositiveAction();
    }
    if (toast.dismissOnActionButtonClick) {
      this.dismiss(toast);
    }
  }

  handleNegativeAction(toast, isDismissedByUser = false) {
    if (toast === null) {
      return;
    }
    if (toast.onNegativeAction) {
      toast.onNegativeAction(isDismissedByUser);
    }
    this.dismiss(toast);
  }

  show(toast) {
    this.setState({ ...this.state, currentToast: toast, isMouseInsideToast: false }, () => {
      requestIdleCallback(() => {
        if (toast.duration > 0) {
          this.setExpiryTimer(toast);
        }
        addKeyboardEventListener(this.handleKeyDown);
        document.addEventListener("click", this.handleOutsideClick, false);
      });
    });
  }

  setExpiryTimer(toast) {
    this.timer = setTimeout(() => {
      if (this.state.isMouseInsideToast === true) {
        clearTimeout(this.timer);
        this.setExpiryTimer(toast);
      } else {
        this.handleNegativeAction(toast);
      }
    }, toast.duration);
  }

  dismiss(toast) {
    clearTimeout(this.timer);
    this.setState({ ...this.state, currentToast: null }, () => {
      requestIdleCallback(() => {
        if (toast !== null) {
          this.props.dismissToast();
        }

        removeKeyboardEventListener(this.handleKeyDown);
        document.removeEventListener("click", this.handleOutsideClick, false);
      });
    });
  }

  handleKeyDown(event) {
    if (this.state.currentToast.type === toastType.UNDO) {
      if ((event.metaKey === true || event.ctrlKey === true) && (event.key === "z" || event.key === "Z")) {
        this.handleUndo(this.state.currentToast);
        event.preventDefault();
        return true;
      }
    }
    return false;
  }

  handleUndo(toast) {
    this.handlePositiveAction(toast);
  }

  handleActionButton(toast) {
    this.handlePositiveAction(toast);
  }

  handleMouseEnter() {
    this.setState({ ...this.state, isMouseInsideToast: true });
  }

  handleMouseLeave() {
    this.setState({ ...this.state, isMouseInsideToast: false });
  }

  getToastContent(toast) {
    if (toast.type === toastType.UNDO) {
      return (
        <MessageContainer isDismissButtonVisible={toast.showDismissButton}>
          <Message>{`${toast.message}. ${getMetaKeyName()} Z to`}</Message>
          <MessageButton onClick={e => this.handleUndo(toast)} isMobile={isMobileDevice}>
            {"Undo"}
          </MessageButton>
        </MessageContainer>
      );
    } else if (
      toast.type === toastType.SYNC_ERROR ||
      toast.type === toastType.SYNC_RESUMING ||
      toast.type === toastType.GENERIC_ERROR ||
      toast.type === toastType.TIP
    ) {
      return (
        <MessageContainer isDismissButtonVisible={toast.showDismissButton}>
          <Message
            dangerouslySetInnerHTML={{
              __html: toast.message
            }}
          />
          {!toast.actionButtonTitle === false && (
            <MessageButton onClick={e => this.handleActionButton(toast)} isMobile={isMobileDevice}>
              {toast.actionButtonTitle}
            </MessageButton>
          )}
        </MessageContainer>
      );
    }
    return null;
  }

  render() {
    const currentToast = this.state.currentToast;
    if (currentToast === null) {
      return null;
    }

    const toastContent = this.getToastContent(currentToast);

    return (
      <Container data-cy="toastContainer" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <ToastContentContainer ref={this.containerRef} isMobile={isMobileDevice}>
          {toastContent}
          {currentToast.showDismissButton === true && (
            <DismissButton data-cy="dismissButton" onClick={e => this.handleNegativeAction(currentToast, true)} />
          )}
        </ToastContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  toast: toastSelector(state)
});

const mapDispatchToProps = {
  dismissToast: dismissToastAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ToastComponent));
