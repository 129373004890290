import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PercentageInput from "components/inputs/PercentageInput";
import PrimaryButton from "components/button/PrimaryButton";
import ExpandingTaxInput from "components/inputs/ExpandingTaxInput";
import { planningVariables } from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const RateDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Input = styled(PercentageInput)`
  width: 100%;
  margin-top: 8px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 35px;
`;

class PercentageInputDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.data ? props.data.value : null,
      [planningVariables.TAX]: props.data ? props.data[planningVariables.TAX] : undefined,
      [planningVariables.TAX_DEDUCTION_AMOUNT]: props.data
        ? props.data[planningVariables.TAX_DEDUCTION_AMOUNT]
        : undefined,
      [planningVariables.TAX_DEDUCTION_RATE]: props.data ? props.data[planningVariables.TAX_DEDUCTION_RATE] : undefined
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.updateTaxParams = this.updateTaxParams.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSaveClick();
    }
  }

  handleOnChange(e, value) {
    !value === true ? this.setState({ value: 0 }) : this.setState({ value: value });
  }

  updateTaxParams(params) {
    this.setState({ ...params });
  }

  handleSaveClick(e) {
    this.props.onVariableUpdate({
      value: this.state.value,
      [planningVariables.TAX]: this.state[planningVariables.TAX],
      [planningVariables.TAX_DEDUCTION_AMOUNT]: this.state[planningVariables.TAX_DEDUCTION_AMOUNT],
      [planningVariables.TAX_DEDUCTION_RATE]: this.state[planningVariables.TAX_DEDUCTION_RATE]
    });
    this.props.onDismiss();
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <RateDialog>
          <Container>
            <Title>{this.props.title}</Title>
            <Input
              supressFocusBehaviour={true}
              inputValidation={value => value < 0}
              value={this.state.value}
              errorMessage={i18n.t("ratePerYearErrorMessage")}
              onChange={this.handleOnChange}
              autoFocus={true}
            />
            <ExpandingTaxInput
              tax={this.state[planningVariables.TAX]}
              taxDeductionAmount={this.state[planningVariables.TAX_DEDUCTION_AMOUNT]}
              taxDeductionRate={this.state[planningVariables.TAX_DEDUCTION_RATE]}
              defaultVar={planningVariables.TAX_DEDUCTION_RATE}
              updateParams={this.updateTaxParams}
              portfolioCurrency={this.props.portfolioCurrency}
              tickerId={this.props.portfolioTickerId}
              onGainTitle={true}
              deductionDescriptionNoun={this.props.deductionDescriptionNoun}
            />
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </RateDialog>
      </DialogOverlay>
    );
  }
}

export default PercentageInputDialog;
