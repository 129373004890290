import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { siteConfigSelector, isAppInWhiteLabelMode, isMobile } from "@kubera/common";

import { useTheme } from "theme";

const isMobileDevice = isMobile();

const Logo = styled.div`
  height: ${props => `${props.logoHeight}px`};
  background-color: transparent;
  background-image: url(${props => props.logoUrl});
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  cursor: ${props => (!props.hasNoLink ? "pointer" : null)};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
`;

const AppLogo = props => {
  const siteConfig = useSelector(siteConfigSelector);

  const theme = useTheme();

  const handleLogoClick = e => {
    if (props.hasNoLink) {
      return;
    }

    window.kuberaOpen(siteConfig.website);
  };

  const getLogoUrl = () => {
    if (!siteConfig === true) {
      return "";
    }
    if (isMobileDevice || !props.regularSize === false) {
      return theme.mode === "dark" && !props.forceDefault
        ? siteConfig.darkLogoUrl || siteConfig.logoUrl
        : siteConfig.logoUrl;
    }
    return siteConfig.bigLogoUrl;
  };

  const getLogoHeight = () => {
    const height = siteConfig && !siteConfig.logoHeight === false ? siteConfig.logoHeight : 50;

    if (isMobileDevice === true) {
      return 0.8 * height;
    }
    if (props.regularSize === true) {
      return Math.min(0.6 * height, 60);
    }
    return height;
  };

  const { className, regularSize, ...other } = props;
  const siteLogoUrl = getLogoUrl();
  const siteLogoHeight = getLogoHeight();
  const siteName = siteLogoUrl ? "" : siteConfig ? siteConfig.shortName : "";
  const isWhiteLabelledApp = isAppInWhiteLabelMode();

  return (
    <Logo
      {...other}
      className={className}
      isWhiteLabelledApp={isWhiteLabelledApp}
      regularSize={regularSize}
      logoUrl={siteLogoUrl}
      logoHeight={siteLogoHeight}
      onClick={handleLogoClick}
    >
      {siteName}
    </Logo>
  );
};

export default AppLogo;
