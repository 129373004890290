import React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";

import { getAppVersion } from "@kubera/common";
import BadgeTip from "components/tooltip/BadgeTip";

const Container = styled.div`
  display: flex;
`;

const MainImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  box-shadow: ${props => `0 0 0 ${props.borderSize || 3}px ${props.theme.userImagePlaceholderBG}`};
  background: ${props => props.theme.userImagePlaceholderBG};
  border-radius: 50%;
  z-index: 10;
`;

const ProfileImage = styled.img`
  position: absolute;
  object-fit: cover;
  display: flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background: ${props => props.theme.userImagePlaceholderBG};
`;

const UserInitial = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  font-family: PT Serif Caption;
  font-size: ${props => props.size / 2}px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.userImagePlaceholderColor};
`;

const SecondaryImageContainer = styled(MainImageContainer)`
  margin-left: -${props => props.size / 4}px;
  z-index: 1;
`;

const Badge = styled(BadgeTip)`
  position: absolute;
  bottom: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
`;

// Retry loading image on error make calls until exponential
const retryLoadOnTarget = (target, imageUrl, timeout = 0) => {
  if (process.env.REACT_APP_ENV === "local") return;

  if (timeout > 48000) {
    Sentry.withScope(scope => {
      scope.setTag("global_error", "ProfileImgError");
      scope.setExtras({
        appVersion: getAppVersion()
      });
      Sentry.captureMessage("Profile image failed to load after retries");
    });
    return;
  }
  setTimeout(() => {
    const img = new Image();
    img.src = `${imageUrl}?t=${new Date().getTime()}`;
    img.onload = () => {
      target.style.display = "flex";
      target.src = `${imageUrl}?t=${new Date().getTime()}`;
    };
    img.onerror = () => {
      retryLoadOnTarget(target, imageUrl, timeout * 2 || 3000);
    };
  }, timeout);
};

class UserProfileImage extends React.Component {
  handleImageError = e => {
    e.target.style.display = "none";

    const { imageUrl } = this.props;
    retryLoadOnTarget(e.target, imageUrl);
  };

  handleSecondaryImageError = e => {
    e.target.style.display = "none";

    const { secondaryImageUrl: imageUrl } = this.props;
    retryLoadOnTarget(e.target, imageUrl);
  };

  render() {
    const {
      className,
      imageSize,
      userName,
      imageUrl,
      secondaryUserName,
      secondaryImageUrl,
      borderSize,
      showBadge,
      ...other
    } = this.props;

    return (
      <Container {...other} className={className} data-private data-cy="profileImage">
        <MainImageContainer size={imageSize} borderSize={borderSize}>
          <UserInitial size={imageSize}>{userName.substring(0, 1).toLowerCase()}</UserInitial>
          {!imageUrl === false && <ProfileImage src={imageUrl} size={imageSize} onError={this.handleImageError} />}
          {showBadge && <Badge />}
        </MainImageContainer>
        {!secondaryUserName === false && (
          <SecondaryImageContainer size={imageSize} borderSize={borderSize}>
            <UserInitial size={imageSize}>{secondaryUserName.substring(0, 1).toLowerCase()}</UserInitial>
            {!secondaryImageUrl === false && (
              <ProfileImage src={secondaryImageUrl} size={imageSize} onError={this.handleSecondaryImageError} />
            )}
          </SecondaryImageContainer>
        )}
      </Container>
    );
  }
}

export default UserProfileImage;
