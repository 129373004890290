import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import NumberInput from "components/inputs/NumberInput";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { formatNumberWithKuberaNumberFormatSettings, getCustodianHistoryFormattedDateString } from "@kubera/common";

const RateDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 163px;
  padding: 60px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ExchangeRateDate = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const RateDetailsContainer = styled.div`
  display: flex;
  margin-top: 12px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
`;

const Currencies = styled.div`
  align-items: center;
  display: flex;
  padding: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on, "calt" off;
  background: #ebebeb;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
`;

const RateInput = styled(NumberInput)`
  height: 61px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on, "calt" off;
  background-color: white;
  border: 0;
  outline: 0;
  padding-left: 15px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const RevertOriginalButton = styled.div`
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

class ExchangeRateChangeDialog extends React.Component {
  constructor(props) {
    super(props);

    const rateData = this.props.rateData;
    this.state = { rate: rateData.rate };

    this.handleInputchange = this.handleInputchange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleOriginalRateButtonClick = this.handleOriginalRateButtonClick.bind(this);
  }

  handleInputchange = e => {
    this.setState({ rate: e.target.value });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveButtonClick();
    }
  };

  handleSaveButtonClick = () => {
    if (this.props.rateData.rate !== this.state.rate) {
      this.props.onRateChange(this.state.rate);
    }
    this.props.onDismiss();
  };

  handleOverlayDismiss = () => {
    this.handleSaveButtonClick();
  };

  handleOriginalRateButtonClick = () => {
    this.setState({ rate: this.props.rateData.rate });
    setTimeout(() => {
      this.handleSaveButtonClick();
    }, 0);
  };

  render() {
    const rateData = this.props.rateData;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <RateDialog>
          <Title>{i18n.t("exchangeRate")}</Title>
          <ExchangeRateDate>{getCustodianHistoryFormattedDateString(rateData.exchangeRateDate)}</ExchangeRateDate>
          <RateDetailsContainer>
            <Currencies>{`${rateData.fromCurrency} 1 = ${rateData.toCurrency}`}</Currencies>
            <RateInput
              value={this.state.rate}
              onChange={this.handleInputchange}
              onKeyDown={this.handleKeyDown}
              autoFocus
              readOnly={this.props.readOnly}
            />
          </RateDetailsContainer>
          <RevertOriginalButton
            visible={!this.props.rateData.rate === false && this.props.rateData.rate !== this.state.rate}
            onClick={this.handleOriginalRateButtonClick}
          >
            {i18n.t("revertExchangeRate") +
              ": " +
              rateData.toCurrency +
              " " +
              formatNumberWithKuberaNumberFormatSettings(parseFloat(this.props.rateData.rate), {
                maximumFractionDigits: 8
              })}
          </RevertOriginalButton>
        </RateDialog>
      </DialogOverlay>
    );
  }
}

export default ExchangeRateChangeDialog;
