import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PrimaryButton from "components/button/PrimaryButton";
import slideAnimation from "utilities/slideAnimation";
import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import ClickableLink from "components/labels/DelayLink";
import { portfolioNetWorth, store, shortFormatNumberWithCurrency } from "@kubera/common";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  position: relative;
  width: 100%;
  height: 100vh
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding-top: 60px;
  animation: ${slideAnimation};
`;

const HistoryBackContainer = styled(ClickableLink)`
  position: absolute;
  left: -1px;
  top: 4px;
  padding: 20px;
  border-radius: 50%;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 16px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
`;

const TotalValue = styled(CurrencyLabel)`
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.044em;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
`;

const Description = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.scorecardChangeTitleColor};
`;

const ListContainer = styled.div`
  margin-top: 15px;
  margin-left: -20px;
  margin-right: -20px;
  border: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
`;

const ListRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 16px 20px;
  background: ${props => props.theme.mobileBackgroundLight};
  color: ${props => props.theme.mobileTxtColor};
  border-top: ${props => (props.isFirst === true ? "" : `1px solid ${props.theme.mobileSeperatorColor}`)};
  word-break: break-word;
`;

const CustodianName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const CustodianValue = styled(CurrencyLabel)`
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const TipTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
`;

const TipDescription = styled.div`
  flex: 1;
  margin-top: 20px;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColor};
`;

const OkButton = styled(PrimaryButton)`
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background: ${props => props.theme.emptyGridComponentOkBtnBG};
`;

const KnowMore = styled.a`
  margin-left: 5px;
  text-decoration: underline;
  color: ${props => props.theme.scorecardChangeTitleColor};
  cursor: pointer;
  text-transform: capitalize;

  &:visited {
    color: ${props => props.theme.linkVisitedColor};
  }
`;

const AdjustNetWorthContainer = styled.div`
  margin-top: 19px;
  margin-bottom: 5px;
`;
const AdjustNetWorthDescription = styled.div`
  color: ${props => props.theme.mobileAdjustedNetworthColor};
`;
const AdjustNetWorthValue = styled.div`
  font-weight: 700;
  color: ${props => props.theme.mobileAdjustedNetworthColor};
`;

class MobileCustodianListDialog extends React.Component {
  constructor(props) {
    super(props);

    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
    this.handleTipDismiss = this.handleTipDismiss.bind(this);
  }

  handleDialogDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    } else {
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }
  }

  handleTipDismiss() {
    if (this.props.showTip === true && !this.props.total === false) {
      this.props.onTipDismiss();
    } else {
      this.handleDialogDismiss();
    }
  }

  render() {
    const showTip = this.props.showTip === true || !this.props.total === true;
    const netWorthValue = portfolioNetWorth(store.getState());

    return (
      <Container onDismiss={this.handleDialogDismiss}>
        <ContainerDialog direction={"left"}>
          <HistoryBackContainer onClick={this.handleDialogDismiss} direction="right">
            <HistoryBackBtn />
          </HistoryBackContainer>
          {showTip === true && (
            <ContentContainer>
              <TipTitle>{this.props.title}</TipTitle>
              <TipDescription
                dangerouslySetInnerHTML={{
                  __html: this.props.tip
                }}
              />
              <OkButton title={i18n.t("gotIt")} onClick={this.handleTipDismiss} />
            </ContentContainer>
          )}
          {showTip === false && (
            <ContentContainer>
              <Title>{this.props.title}</Title>
              <TotalValue
                value={this.props.total}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                useScrollAnimation={false}
              />
              <Description>
                {/* On mobile decided to remove this text */}
                {/* {this.props.description} */}
                {/* <KnowMore target={"_blank"} href={this.props.knowMore}>
                  {i18n.t("knowMore")}
                </KnowMore> */}
                {this.props.showAdjustedNetWorth === true && (
                  <AdjustNetWorthContainer>
                    <AdjustNetWorthDescription>{i18n.t("adjustedNetworth.description")}</AdjustNetWorthDescription>
                    <AdjustNetWorthValue>
                      {shortFormatNumberWithCurrency(
                        netWorthValue - this.props.total,
                        this.props.currency,
                        false,
                        true,
                        undefined,
                        undefined,
                        true
                      )}
                    </AdjustNetWorthValue>
                  </AdjustNetWorthContainer>
                )}
              </Description>
              <ListContainer>
                {this.props.listItems.map((item, index) => {
                  return (
                    <ListRow key={index} isFirst={index === 0}>
                      <CustodianName>{item.name}</CustodianName>
                      <CustodianValue
                        value={item.value}
                        roundDown={true}
                        currency={this.props.currency}
                        abbreviate={false}
                        useScrollAnimation={false}
                      />
                    </ListRow>
                  );
                })}
              </ListContainer>
            </ContentContainer>
          )}
        </ContainerDialog>
      </Container>
    );
  }
}

export default withRouter(MobileCustodianListDialog);
