import React from "react";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  months,
  store,
  userAgeAtDate,
  portfolioNetWorth,
  getFriendlyDateStringForFutureDate,
  userPreferencesSelector,
  updateUserPreferences,
  parseKuberaDateString
} from "@kubera/common";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import NetworthBreakdownComponent from "./NetworthBreakdownComponent";

const BreakdownDialog = styled(Dialog)`
  position: relative;
  width: 720px;
  min-height: 790px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 40px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TimePeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.03em;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TimePeriod = styled.div`
  display: flex;
`;

const TimePeriodDescription = styled.div`
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const Age = styled.div`
  font-weight: 400;
  white-space: pre;
`;

const ScenarioTabs = styled(Tabs)`
  margin-top: 28px;
  margin-bottom: 34px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ScenarioTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const ScenarioTabHeaderContainer = styled.div`
  display: flex;
`;

const ScenarioTabHeader = styled(Tab)`
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0.35;
  display: ${props => (props.hidden === 1 ? "none" : "auto")};

  &.is-selected {
    opacity: 1;
  }
`;

const TabHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5px;
  border-left: ${props => `6px solid ${props.color}`};
`;

const TabHeaderTotal = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
  color: black;
`;

const TabHeaderChange = styled(ChangeLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ScenarioTabPanel = styled(TabPanel)`
  margin-top: 30px;
  display: none;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const TimePeriodStringContainer = styled.div``;

class NetworthBreakdownDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedScenarioIndex: this.getDefaultSelectedScenario() };

    this.handleScenarioChange = this.handleScenarioChange.bind(this);
  }

  componentDidMount() {
    if (!!this.props.userPreferences.planningChartClickTip === false) {
      this.props.updateUserPreferences({ planningChartClickTip: true });
    }
  }

  getDefaultSelectedScenario() {
    if (this.props.selectedScenarioIndex !== null) {
      return this.props.selectedScenarioIndex;
    }
    return this.props.planningData.findIndex(item => this.props.selectedScenarios.includes(item.scenario.id));
  }

  handleScenarioChange(index) {
    this.setState({ selectedScenarioIndex: index });
  }

  getTimePeriodString() {
    const date = parseKuberaDateString(this.props.groupedData[0].data[this.props.dataIndex].date);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  getUserAgeString() {
    const date = new Date(this.props.groupedData[0].data[this.props.dataIndex].date);
    const ageAtDate = userAgeAtDate(store.getState(), date);
    if (!ageAtDate === true) {
      return null;
    }
    return ` • ${i18n.t("age")} ${ageAtDate}`;
  }

  getTimePeriodDescription() {
    const date = parseKuberaDateString(this.props.groupedData[0].data[this.props.dataIndex].date);
    const lastDayNextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0, 0, 0, 0, 0);
    return date.getTime() === lastDayNextMonth.getTime()
      ? i18n.t("nextMonth")
      : getFriendlyDateStringForFutureDate(date); // patch added to provide extra clarity in one month tile, since it is the last day of the next month
  }

  getTabs() {
    return this.props.planningData.map((dataForScenario, index) => {
      const panel = (
        <NetworthBreakdownComponent
          dataForScenario={dataForScenario}
          scenario={dataForScenario.scenario}
          groupedData={this.props.groupedData[index]}
          dataIndex={this.props.dataIndex}
        />
      );
      return {
        scenarioGroupedData: this.props.groupedData[index],
        key: index,
        panelComponent: panel
      };
    });
  }

  render() {
    const tabs = this.getTabs();
    const userAgeString = this.getUserAgeString();

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <BreakdownDialog>
          <Container>
            <TimePeriodContainer>
              <TimePeriod>
                <TimePeriodStringContainer>{this.getTimePeriodString()}</TimePeriodStringContainer>
                {!userAgeString === false && <Age>{userAgeString}</Age>}
              </TimePeriod>
              <TimePeriodDescription>{this.getTimePeriodDescription()}</TimePeriodDescription>
            </TimePeriodContainer>
            <ScenarioTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.selectedScenarioIndex}
              onSelect={this.handleScenarioChange}
            >
              <ScenarioTabList>
                {tabs.map((tab, index) => (
                  <ScenarioTabHeader
                    data-cy={"breakdownTab" + index}
                    key={index}
                    hidden={this.props.selectedScenarios.includes(tab.scenarioGroupedData.id) === false ? 1 : 0}
                  >
                    <ScenarioTabHeaderContainer>
                      <TabHeader color={this.props.colorPallete[index].color}>
                        <TabHeaderTotal
                          currency={tab.scenarioGroupedData.currency}
                          value={this.props.getEndValue(this.props.groupedData, index, this.props.dataIndex)}
                          currencyFontSize={14}
                          valueFontSize={22}
                          fontWeight={700}
                          height={"24px"}
                          showZero={true}
                          preventAnimation={true}
                          currencyMarginTop={2}
                        />
                        <TabHeaderChange
                          currency={tab.scenarioGroupedData.currency}
                          startValue={this.props.portfolioNetWorth}
                          endValue={this.props.getEndValue(this.props.groupedData, index, this.props.dataIndex)}
                          preventAnimation={true}
                        />
                      </TabHeader>
                    </ScenarioTabHeaderContainer>
                  </ScenarioTabHeader>
                ))}
              </ScenarioTabList>
              {tabs.map((tab, index) => (
                <ScenarioTabPanel key={`scenarioTabPanel${index}`}>{tab.panelComponent}</ScenarioTabPanel>
              ))}
            </ScenarioTabs>
          </Container>
        </BreakdownDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  portfolioNetWorth: portfolioNetWorth(state),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetworthBreakdownDialog));
