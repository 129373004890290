import React from "react";
import styled from "styled-components";
import ChangeLabel from "components/labels/ChangeLabel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 22px;
  border-bottom: ${props => (props.isLast === true ? "" : `1px solid ${props.theme.mobileSeperatorColor}`)};
  background: ${props => props.theme.mobileBackgroundLight};
  word-break: break-word;
`;

const Name = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  font-feature-settings: "ss01" on, "calt" off;
  margin-bottom: 10px;
`;

const ValueChange = styled(ChangeLabel)`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  .child-container {
    justify-content: flex-start;
  }
`;

class ValueChangeTable extends React.Component {
  render() {
    const { className, currency, rows, disableColor, disableRoundDown, ...other } = this.props;

    if (rows.length === 0) {
      return null;
    }

    return (
      <Container {...other} className={className}>
        {rows.map((row, index) => (
          <Row key={index} isLast={index === rows.length - 1}>
            <Name>{row.isArchived === true ? `${row.name} (Archived)` : row.name}</Name>
            <ValueChange
              currency={currency}
              startValue={row.startValue}
              endValue={row.currentValue}
              hidePercentage={true}
              disableShortFormat={true}
              disableColor={!disableColor === false || row.isArchived === true}
              disableRoundDown={disableRoundDown}
              alignPosition="left"
            />
          </Row>
        ))}
      </Container>
    );
  }
}

export default ValueChangeTable;
