import React from "react";
import styled from "styled-components";

const MaxItemsPerRow = 4;

const Container = styled.div`
  position: relative;
  margin-left: -10px;
  margin-right: -10px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const ProviderTable = styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
  border: 0;
  margin: 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 10px;
  margin-bottom: 15px;
`;

const ProviderTableBody = styled.tbody``;

const ProviderRow = styled.tr`
  padding-top: 24px;
`;

const ProviderCell = styled.td`
  padding: 15px;
  background: transparent;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  height: 110px;
  box-sizing: border-box;
`;

const ProviderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const ProviderHead = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  height: 100%;
`;

const ProviderFooter = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 11px;
  line-height: 110%;
  height: 100%;
`;

const SearchHint = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  white-space: pre-wrap;
`;

class StockProvidersListComponent extends React.Component {
  getProviderRows() {
    const providers = this.props.providers;
    var rows = [];
    var currentRow = [];

    for (const provider of providers) {
      if (currentRow.length === MaxItemsPerRow) {
        rows.push(currentRow);
        currentRow = [];
      }
      currentRow.push(provider);
    }
    if (currentRow.length <= MaxItemsPerRow) {
      rows.push(currentRow);
    }
    while (currentRow.length < MaxItemsPerRow) {
      currentRow.push(null);
    }
    return rows;
  }

  render() {
    const providerRows = this.getProviderRows();
    const searchHint = this.props.searchHint;

    return (
      <Container className={this.props.className} disabled={this.props.disabled === true}>
        <ProviderTable>
          <ProviderTableBody>
            {providerRows.map((row, index) => {
              return (
                <ProviderRow key={index}>
                  {row.map((provider, index) => {
                    if (!provider === true) {
                      return <ProviderCell key={index}></ProviderCell>;
                    } else {
                      const { symbol = "" } = provider;

                      return (
                        <ProviderCell key={index}>
                          <ProviderContainer onClick={event => this.props.onClick(provider)}>
                            <ProviderHead>{symbol}</ProviderHead>
                            <ProviderFooter>{provider.name}</ProviderFooter>
                          </ProviderContainer>
                        </ProviderCell>
                      );
                    }
                  })}
                </ProviderRow>
              );
            })}
          </ProviderTableBody>
        </ProviderTable>
        {searchHint && <SearchHint>{searchHint}</SearchHint>}
      </Container>
    );
  }
}

export default StockProvidersListComponent;
