import React from "react";
import i18n from "i18next";
import styled, { css } from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import MultiEmailInput from "components/inputs/MultiEmailInput";
import TextArea from "components/inputs/TextArea";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import {
  inviteWlClient,
  inviteWlSubUser,
  isEmailValid,
  siteConfigSelector,
  wlSetupType,
  isMobile,
  wlManagersSelector
} from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import Spinner from "components/loader/Spinner";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import EmailInput from "components/inputs/EmailInput";
import RadioButton from "components/inputs/RadioButton";

const INVITATION_OPTIONS = {
  LATER: {
    label: i18n.t("inviteLater"),
    value: "later"
  },
  NOW: {
    label: i18n.t("inviteNow"),
    value: "now"
  }
};

const isMobileDevice = isMobile();

const InviteClientDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  min-height: 400px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 55px 60px 67px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => (isMobileDevice ? props.theme.mobileBackgroundOverlay : props.theme.dialogContentContainerBG)};
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  font-feature-settings: "ss01" on;
  color: #000000;
  flex-direction: column;
`;

const Loader = styled(Spinner)`
  margin-bottom: 15px;
  width: 30px;
  height: 30px;
`;

const Title = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const SendInviteRadioButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
`;

const MultiEmailField = styled(MultiEmailInput)`
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;

  .multiemail-input {
    min-height: 45px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
  }

  .exists-error,
  .invalid-error {
    color: #ff0000;
  }

  &:focus .invalid-error {
    color: inherit;
  }
`;

const MessageTextArea = styled(TextArea)`
  min-height: 70px;
  margin-top: -1px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 13px 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const SubmitErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const InviteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;

const InviteButton = styled(PrimaryButton)`
  margin-top: 40px;
  height: 40px;
`;

const EmailErrorMessage = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #ff0000;
`;

const EmailNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

class CreateClientDialogComponent extends React.Component {
  static show = (history, location) => {
    const hash = `${hashParams.MODAL}=${modalValues.WL_CREATE_CLIENT}`;
    history.push({
      ...location,
      hash: hash
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      nameErrorMessage: null,
      primaryEmail: null,
      primaryEmailErrorMessage: null,
      subUserEmails: [],
      subUserEmailsErrorMessage: null,
      inviteMessage: (this.props.siteConfig.inviteCustomMessage || "").replace(/\\r?\\n/g, "\r\n"),
      isSubmitting: false,
      submittingEmail: null,
      submitErrorMessage: null,
      selectedInvitationOption: INVITATION_OPTIONS.LATER.value
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handlePrimaryEmailInputChange = this.handlePrimaryEmailInputChange.bind(this);
    this.handleInvitationOptionSelection = this.handleInvitationOptionSelection.bind(this);
    this.handleMultiEmailOnChange = this.handleMultiEmailOnChange.bind(this);
    this.handleInviteButtonClick = this.handleInviteButtonClick.bind(this);
    this.handleMultiEmailOnBlur = this.handleMultiEmailOnBlur.bind(this);
    this.handleInviteMessageInputChange = this.handleInviteMessageInputChange.bind(this);

    this.multiInputEmailRef = React.createRef();
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss(this.props.history, this.props.location);
      return;
    }
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleInviteButtonClick(event);
    }
  }

  handleNameInputChange(e) {
    this.setState({ name: e.target.value, nameErrorMessage: null });
  }

  handlePrimaryEmailInputChange(e) {
    this.setState({ primaryEmail: e.target.value, primaryEmailErrorMessage: null });
  }

  handleInvitationOptionSelection(value) {
    this.setState({ selectedInvitationOption: value });
  }

  handleMultiEmailOnChange(multiEmails) {
    this.setState({ subUserEmails: multiEmails.list, subUserEmailsErrorMessage: null });
  }

  handleMultiEmailOnBlur = multiEmails => {
    if (multiEmails.list.some(email => isEmailValid(email) === false)) {
      this.setState({
        subUserEmailsErrorMessage: i18n.t("invalidMultiEmailErrorMsg")
      });
    } else if (
      multiEmails.list.some(email =>
        this.props.wlManagers.find(manager => manager.email.toLowerCase() === email.toLowerCase())
      )
    ) {
      this.setState({
        subUserEmailsErrorMessage: i18n.t("wlkAdminAsClientError")
      });
    }
  };

  handleInviteMessageInputChange(value) {
    this.setState({ inviteMessage: value });
  }

  async handleInviteButtonClick(e) {
    if (!this.state.name === true) {
      this.setState({ nameErrorMessage: i18n.t("emptyClientName") });
      return;
    }

    const customMessage = this.state.inviteMessage.replaceAll(/\r?\n/g, "<br/>");
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      if (!this.state.primaryEmail === false && isEmailValid(this.state.primaryEmail) === false) {
        this.setState({ primaryEmailErrorMessage: i18n.t("wrongEmailFormat") });
        return;
      }

      if (
        !this.state.primaryEmail === false &&
        this.props.wlManagers.find(
          manager => manager.email.toLowerCase() === this.state.primaryEmail.trim().toLowerCase()
        )
      ) {
        this.setState({ emailErrorMessage: i18n.t("wlkAdminAsClientError") });
      }

      this.setState({ isSubmitting: true });

      this.props.inviteWlClient(
        this.state.name,
        this.state.primaryEmail,
        customMessage,
        !this.props.primaryEmail === false,
        user => {
          this.handleOverlayDismiss();
        },
        apiError => {
          this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
        }
      );
    } else if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_WR) {
      const inviteNow = this.state.selectedInvitationOption === INVITATION_OPTIONS.NOW.value;

      if (inviteNow) {
        if (!this.state.primaryEmail === true) {
          this.setState({ primaryEmailErrorMessage: i18n.t("emptyEmail") });
          return;
        } else if (isEmailValid(this.state.primaryEmail) === false) {
          this.setState({ primaryEmailErrorMessage: i18n.t("wrongEmailFormat") });
          return;
        } else if (
          this.props.wlManagers.find(
            manager => manager.email.toLowerCase() === this.state.primaryEmail.trim().toLowerCase()
          )
        ) {
          this.setState({ primaryEmailErrorMessage: i18n.t("wlkAdminAsClientError") });
          return;
        } else if (!this.state.subUserEmailsErrorMessage === false) {
          return;
        }
      }

      this.setState({ isSubmitting: true });
      this.props.inviteWlClient(
        this.state.name,
        this.state.primaryEmail,
        customMessage,
        inviteNow,
        async user => {
          const inviteSubUsers = this.state.subUserEmails.length > 0;
          if (inviteSubUsers) {
            await this.handleSubUserInvite(user, this.state.subUserEmails, customMessage);
          }
          this.handleOverlayDismiss();
        },
        apiError => {
          this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
        }
      );
    }
  }

  async handleSubUserInvite(clientUser, subUserEmails, customMessage) {
    for (let email of subUserEmails) {
      this.setState({
        submittingEmail: email
      });
      await this.props
        .inviteWlSubUser(clientUser, {
          parentUserId: clientUser.userId,
          email: email.toLowerCase(),
          allowAll: 1,
          write: 1,
          message: customMessage
        })
        .catch(error => {
          this.setState({
            submitErrorMessage: error.errorMessage
          });
        });
    }
    return true;
  }

  handleTextAreaAdjust = ref => {
    ref.style.height = "1px";
    ref.style.height = 5 + ref.scrollHeight + "px";
  };

  renderEmails = (email, isFocused) => {
    const trimmedEmail = email.trim();
    if (
      (!isEmailValid(trimmedEmail) ||
        !this.props.wlManagers.find(manager => manager.email.toLowerCase() === trimmedEmail.toLowerCase()) === false) &&
      !isFocused
    ) {
      return `<span class="invalid-error">${email}</span>`;
    }

    return `<span>${email}</span>`;
  };

  render() {
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <InviteClientDialog>
          <Container>
            <Title>{i18n.t("createNewClient")}</Title>
            <FormContainer>
              <FieldTitle>{i18n.t("name")}</FieldTitle>
              <NameField
                placeholder={i18n.t("namePlaceholder")}
                value={this.state.name}
                errorMessage={this.state.nameErrorMessage}
                onChange={this.handleNameInputChange}
                onKeyDown={this.handleKeyDown}
                autoFocus
              />
              {this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO && (
                <>
                  <FieldTitle>{`${i18n.t("email")} ${i18n.t("optional")}`}</FieldTitle>
                  <EmailField
                    placeholder={i18n.t("emailId")}
                    errorMessage={this.state.primaryEmailErrorMessage}
                    value={this.state.primaryEmail}
                    onChange={this.handlePrimaryEmailInputChange}
                    onKeyDown={this.handleKeyDown}
                    autoFocus={true}
                  />
                </>
              )}
              {this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_WR && (
                <>
                  <FieldTitle>{i18n.t("inviteClientOption")}</FieldTitle>
                  <SendInviteRadioButtonsContainer>
                    <RadioButton
                      label={INVITATION_OPTIONS.LATER.label}
                      value={INVITATION_OPTIONS.LATER.value}
                      checked={this.state.selectedInvitationOption === INVITATION_OPTIONS.LATER.value}
                      onChange={this.handleInvitationOptionSelection}
                    />
                    <RadioButton
                      label={INVITATION_OPTIONS.NOW.label}
                      value={INVITATION_OPTIONS.NOW.value}
                      checked={this.state.selectedInvitationOption === INVITATION_OPTIONS.NOW.value}
                      onChange={this.handleInvitationOptionSelection}
                    />
                  </SendInviteRadioButtonsContainer>
                  {this.state.selectedInvitationOption === INVITATION_OPTIONS.NOW.value && (
                    <>
                      <FieldTitle>{i18n.t("wlkInviteDialog.primaryEmail")}</FieldTitle>
                      <EmailField
                        placeholder={i18n.t("emailId")}
                        errorMessage={this.state.primaryEmailErrorMessage}
                        value={this.state.primaryEmail}
                        onChange={this.handlePrimaryEmailInputChange}
                        onKeyDown={this.handleKeyDown}
                        autoFocus={true}
                      />
                      <FieldTitle>{i18n.t("wlkInviteDialog.additionalEmails")}</FieldTitle>
                      <MultiEmailField
                        ref={this.multiInputEmailRef}
                        placeholder={i18n.t("emailId")}
                        emails={this.state.subUserEmails}
                        limitTo={this.props.siteConfig.subUserLimit}
                        renderEmails={this.renderEmails}
                        onChange={this.handleMultiEmailOnChange}
                        onBlur={this.handleMultiEmailOnBlur}
                      />
                      {this.state.subUserEmailsErrorMessage ? (
                        <EmailErrorMessage>{this.state.subUserEmailsErrorMessage}</EmailErrorMessage>
                      ) : (
                        <EmailNote>{i18n.t("wlkMultiEmailInput.note")}</EmailNote>
                      )}
                      <FieldTitle>{i18n.t("wlkInviteDialog.message")}</FieldTitle>
                      <MessageTextArea
                        placeholder={i18n.t("message")}
                        value={this.state.inviteMessage}
                        onChange={this.handleInviteMessageInputChange}
                        onMount={this.handleTextAreaAdjust}
                      />
                    </>
                  )}
                </>
              )}
              <InviteButtonContainer>
                <InviteButton
                  title={i18n.t("createClient")}
                  onClick={this.handleInviteButtonClick}
                  isLoading={this.state.isSubmitting}
                />
                {!this.state.submitErrorMessage === false && (
                  <SubmitErrorMessage
                    dangerouslySetInnerHTML={{
                      __html: this.state.submitErrorMessage
                    }}
                  />
                )}
              </InviteButtonContainer>
              {this.state.isSubmitting && (
                <LoadingContainer>
                  <Loader />
                  {this.state.submittingEmail
                    ? i18n.t(`wlkInviteDialog.creatingClientWithEmail`).replace("{email}", this.state.submittingEmail)
                    : i18n.t(`wlkInviteDialog.creatingClientWithoutEmail`)}
                </LoadingContainer>
              )}
            </FormContainer>
          </Container>
        </InviteClientDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  siteConfig: siteConfigSelector(state),
  wlManagers: wlManagersSelector(state)
});

const mapDispatchToProps = {
  inviteWlClient,
  inviteWlSubUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateClientDialogComponent));
