import React from "react";
import styled from "styled-components";
import { isMobile } from "@kubera/common";

import Spinner from "components/loader/Spinner";

const isMobileDevice = isMobile();

const Button = styled.button`
  position: relative;
  width: fit-content;
  height: 44px;
  min-width: ${props => (props.isMobileDevice ? "126px" : "155px")};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.backgroundSecondaryBtn};
  color: ${props => props.theme.txtColorSecondaryBtn};
  border: ${props => `1px solid ${props.theme.borderColorSecondaryBtn}`};
  box-sizing: border-box;
  cursor: pointer;
`;

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

const DisabledOverlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
`;

class SecondaryButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (this.props.isLoading === true || this.props.isDisabled === true) {
      return;
    }
    this.props.onClick(e);
  }

  render() {
    const { className, title, isLoading, isDisabled, onClick, ...other } = this.props;

    return (
      <Button
        className={className}
        {...other}
        tabIndex={"0"}
        onClick={this.handleClick}
        isLoading={isLoading}
        isMobileDevice={isMobileDevice}
      >
        {isLoading === true ? "" : title}
        {isLoading === true && <ButtonSpinner />}
        {isDisabled === true && <DisabledOverlay>{""}</DisabledOverlay>}
      </Button>
    );
  }
}

export default SecondaryButton;
