import React from "react";
import InitialLoader from "components/loader/InitialLoader";
import LinkAccountProviderPageComponentExports from "components/link_account/LinkAccountProviderPageComponentExports";
import { routes } from "routes";

class RedirectCallbackComponent extends React.Component {
  constructor(props) {
    super(props);

    const redirectHost = LinkAccountProviderPageComponentExports.getRedirectDomain();
    console.log("Redirect callback:" + redirectHost);
    if (!redirectHost === false) {
      const redirectUrl = `${window.location.protocol}//${redirectHost}${routes.LINK_PROVIDER_PAGE}${window.location.search}${window.location.hash}`;
      console.log("Redirect callback:" + redirectUrl);
      window.location.href = redirectUrl;
    }
  }

  render() {
    return <InitialLoader />;
  }
}

export default RedirectCallbackComponent;
