import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { getNonEmptyAssetTypes, planningAssetTypes } from "@kubera/common";

const TypeDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const AssetTypeList = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssetTypeName = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  cursor: pointer;
`;

class AssetTypeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.handleTypeclick = this.handleTypeclick.bind(this);
  }

  handleTypeclick(e, value) {
    this.props.onVariableUpdate({ value: value });
    this.props.onDismiss();
  }

  applicableAssetTypes() {}

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <TypeDialog>
          <Container>
            <AssetTypeList>
              {getNonEmptyAssetTypes().map((assetType, index) => {
                if (assetType.key === planningAssetTypes.all.key) {
                  return null;
                }
                return (
                  <AssetTypeName key={index} onClick={e => this.handleTypeclick(e, assetType.key)}>
                    {assetType.label}
                  </AssetTypeName>
                );
              })}
            </AssetTypeList>
          </Container>
        </TypeDialog>
      </DialogOverlay>
    );
  }
}

export default AssetTypeDialog;
