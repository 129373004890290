import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { hashParams, modalValues } from "routes";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  useHistory,
  insertTickerCustodianInSection,
  currentPortfolioSelector,
  getExchangeRateDetails,
  getCurrentCustodianFromCustodianId,
  getTickerUsingShortName,
  blockDetailsLoading,
  unblockDetailsLoading
} from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import EditableQtyPrice from "components/link_account/EditableQtyPrice";
import PrimaryButton from "components/button/PrimaryButton";

const LinkDialog = styled(Dialog)`
  width: 612px;
  min-height: 633px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 80px;
  padding: 58px;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28.6px;
  font-feature-settings: "ss01" on;
  margin-bottom: 1px;
  user-select: none;
`;

const Desc = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #00000099;
  margin-bottom: 17px;
`;

const AddMoreBtn = styled.div`
  display: inline-flex;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  margin-top: 4px;
  height: 25px;
  margin-bottom: 35px;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;
`;

const FormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const PVSTConnectModal = ({ custodianId }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const allFormRefs = useRef([]);

  const portfolio = useSelector(currentPortfolioSelector);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [pvstCustodians, setPvstCustodians] = useState([1]);
  const [errorMessage, setErrorMessage] = useState("");

  const custodian = getCurrentCustodianFromCustodianId(custodianId);

  const dismissOverlay = () => {
    DialogOverlay.dismiss(history, location);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalVisible(false);
  };

  const formCheckValidity = () => {
    let isValid = true;
    for (let i = 0; i < allFormRefs.current.length; i++) {
      if (allFormRefs.current[i] && !allFormRefs.current[i].checkValidity()) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleOverlayDismiss = () => {
    if (formCheckValidity()) {
      setIsConfirmModalVisible(true);
      return;
    }

    dismissOverlay();
  };

  const onAddMoreClick = () => {
    setPvstCustodians([...pvstCustodians, pvstCustodians[pvstCustodians.length - 1] + 1]);
  };

  const handleRemoveEntry = index => {
    pvstCustodians.splice(index, 1);
    allFormRefs.current.pop();
    setPvstCustodians([...pvstCustodians]);
  };

  const handleSubmitForEachForm = (values, nextValues) => {
    const tickerId = getTickerUsingShortName(portfolio.currency).id;
    const updatedCell = values.priceCell;
    const newCustodian = {
      name: values.custodianName,
      value: values.quantity,
      valueTickerId: 171,
      isCompleted: 1,
      valueExchangeRate: getExchangeRateDetails(tickerId, 1),
      past: 0,
      isLinking: false,
      rate: JSON.stringify({
        u: 1,
        t: updatedCell.valueTickerId ? updatedCell.valueTickerId : getTickerUsingShortName(updatedCell.currency).id,
        p: updatedCell.value
      })
    };
    return dispatch(
      insertTickerCustodianInSection(
        portfolio.id,
        custodian.sectionId,
        nextValues?.custodianId || custodian.id,
        newCustodian,
        nextValues?.sortKey,
        _ => {
          unblockDetailsLoading();
        }
      )
    );
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    blockDetailsLoading();

    if (!formCheckValidity()) {
      setErrorMessage(i18n.t("formError"));
      return;
    }

    let nextValues = void 0;
    allFormRefs.current.forEach(ref => {
      if (ref) {
        nextValues = handleSubmitForEachForm(ref.getValues(), nextValues);
      }
    });
    dismissOverlay();
  };

  const setRef = (ref, index) => {
    allFormRefs.current[index] = ref;
  };

  const resetErrorMessage = () => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (!custodian) {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  return (
    <DialogOverlay onDismiss={handleOverlayDismiss}>
      <LinkDialog>
        <Container>
          <Title>{i18n.t("qtyAndPriceModal.title")}</Title>
          <Desc>{i18n.t("qtyAndPriceModal.desc")}</Desc>
          <Form onSubmit={handleFormSubmit} noValidate>
            {pvstCustodians.map((item, index) => (
              <EditableQtyPrice
                key={item}
                index={index}
                ref={el => setRef(el, index)}
                showRemoveBtn={pvstCustodians.length > 1}
                onRemove={handleRemoveEntry}
                resetErrorMessage={resetErrorMessage}
                custodian={index === 0 ? custodian : null}
              />
            ))}
            <FormFooter>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} tabIndex={0} />
            </FormFooter>
            <div>
              <AddMoreBtn onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</AddMoreBtn>
            </div>
          </Form>
        </Container>
      </LinkDialog>
      {isConfirmModalVisible && (
        <ConfirmationDialog
          title={i18n.t("linkAccount.confirmAddTitle").replace("%s%", i18n.t("qtyAndPriceModal.title"))}
          description={i18n.t("linkAccount.confirmStocksAddDesc")}
          positiveButtonTitle={i18n.t("stay")}
          negativeButtonTitle={i18n.t("abort")}
          handleNegativeButtonClick={dismissOverlay}
          handlePositiveButtonClick={closeConfirmModal}
        />
      )}
    </DialogOverlay>
  );
};

PVSTConnectModal.show = (history, location, rowId) => {
  const hash = `${hashParams.MODAL}=${modalValues.QTY_AND_PRICE_CONNECT}&${hashParams.CATEGORY}=${category.ASSET}&${hashParams.ID}=${rowId}`;
  history.replace({ ...location, hash });
};

export default PVSTConnectModal;
