import React from "react";
import styled from "styled-components";
import ChangeLabel from "components/labels/ChangeLabel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  padding: 10px;
  border-bottom: ${props => (props.isLast === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  background: ${props => props.theme.detailsDialogGridRowBG};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  word-break: break-word;
`;

const Name = styled.div`
  flex: 1;
`;

const ValueChange = styled(ChangeLabel)`
  text-align: right;
  min-width: 120px;
`;

class ValueChangeTable extends React.Component {
  render() {
    const { className, currency, rows, disableColor, disableRoundDown, ...other } = this.props;

    if (rows.length === 0) {
      return null;
    }

    return (
      <Container {...other} className={className}>
        {rows.map((row, index) => (
          <Row key={index} isLast={index === rows.length - 1}>
            <Name>{row.isArchived === true ? `${row.name} (Archived)` : row.name}</Name>
            <ValueChange
              currency={currency}
              startValue={row.startValue}
              endValue={row.currentValue}
              hidePercentage={true}
              disableShortFormat={true}
              disableColor={!disableColor === false || row.isArchived === true}
              disableRoundDown={disableRoundDown}
            />
          </Row>
        ))}
      </Container>
    );
  }
}

export default ValueChangeTable;
