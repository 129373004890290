import React from "react";
import styled from "styled-components";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import { hashParams, modalValues } from "routes";
import i18n from "i18next";

const DownloadDialog = styled(GenericMessageDialog)``;

class DocumentsDownloadDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.DOWNLOAD_DOCUMENTS}`
    });
  };

  render() {
    if (this.props.isDataDownload) {
      return (
        <DownloadDialog
          title={i18n.t("downloadDataDialog.title")}
          description={i18n.t("downloadDataDialog.description")}
        />
      );
    }

    return (
      <DownloadDialog
        title={i18n.t("downloadDocumentsDialog.title")}
        description={i18n.t("downloadDocumentsDialog.description")}
      />
    );
  }
}

export default DocumentsDownloadDialog;
