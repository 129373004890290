import React, { forwardRef, useState, useMemo, useImperativeHandle, useRef } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import i18n from "i18next";
import defaultTheme, { useTheme, dialogGridTheme } from "theme";
import { useSelector, useDispatch } from "react-redux";

import {
  currentPortfolioSelector,
  getCapitalizedStr,
  convertCurrency,
  formatNumberWithCurrency,
  getTickerUsingId,
  fetchTickerDetails,
  showToastTip,
  UNKNOWN_TICKER_SHORT_NAME,
  tickerSubTypes,
  tickerTypes
} from "@kubera/common";

import { CurrencyCellData, cellType } from "components/grid/GridDataModel";
import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";
import TextInput from "components/inputs/TextInput";
import GridCell from "components/grid/GridCell";
import { category } from "components/dashboard/DashboardComponentExports";
import NumberInput from "components/inputs/NumberInput";

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const CustodianEntry = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
`;

const QtyPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

const Input = css`
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  border-right: 0;
  height: 50px;
  padding: 0 14px;
`;

const EditableTextInput = styled(TextInput)`
  ${Input}
  border-right: 1px solid rgba(0, 0, 0, 0.4);
`;

const QtyInput = styled(NumberInput)`
  ${Input}
  width: 167px;
`;

const ValueDiv = styled(TextInput)`
  ${Input};
  width: 162px;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  background: #ebebeb;
  text-align: right;
  cursor: default;
`;

const EditableValueWrapper = styled.div`
  width: ${({ width }) => width};
  display: flex;
  align-items: stretch;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  border-right: ${({ showRightBorder }) => (showRightBorder ? null : 0)};

  input {
    z-index: 0;
    box-sizing: border-box;
    border: 0;

    :focus {
      outline: none;
    }
  }

  input:disabled {
    background: #ebebeb;
  }

  div,
  input {
    width: 100%;
    box-sizing: border-box;
    border: 0;
  }
`;

const EditableRemoveContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 50px;
  cursor: pointer;
`;

const QtyCustodian = (
  {
    custodianCategory = category.ASSET,
    index = 0,
    showRemoveBtn = true,
    custodian,
    onRemove = () => null,
    resetErrorMessage = () => null
  },
  ref
) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const modifiedTheme = { ...defaultTheme, ...dialogGridTheme(theme.mode) };

  const isFocusSet = useRef(false);

  const portfolio = useSelector(currentPortfolioSelector);

  const [custodianName, setCustodianName] = useState(custodian ? custodian.name : "");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(custodian ? custodian.value : null);
  const [invalidTickerUpdatedTs, setInvalidTickerUpdatedTs] = useState(new Date().getTime());

  const priceCell = useMemo(() => {
    const cell = new CurrencyCellData(cellType.CURRENCY, "Price", null, portfolio.currency);
    cell.value = price;
    cell.ownership = 100;
    cell.isEditable = true;
    cell.textAlignment = "right";
    cell.showDecimal = true;
    cell.decimalPlaces = 4;
    cell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    cell.unsupportedTickerSubTypes = [tickerSubTypes.PRECIOUS_METALS];
    cell.callOnChangeEverytime = true;
    const custodianShortName = custodian
      ? getTickerUsingId(custodian.valueTickerId).shortName
      : UNKNOWN_TICKER_SHORT_NAME;
    if (custodian && custodianShortName !== UNKNOWN_TICKER_SHORT_NAME) {
      cell.currency = getTickerUsingId(custodian.valueTickerId).shortName;
    }

    return cell;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.currency]);

  const resultPriceCell = useMemo(() => {
    const cell = new CurrencyCellData(cellType.CURRENCY, "Price", null, portfolio.currency);
    const resultPrice = price ? price * quantity : 0;

    for (let prop in priceCell) {
      cell[prop] = priceCell[prop];
    }

    cell.value = !isNaN(resultPrice) ? resultPrice : 0;

    return cell;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.currency, price, quantity]);

  const handleRemoveEntry = () => {
    onRemove(index);
    resetErrorMessage();
  };

  const handleNameChange = e => {
    setCustodianName(e.target.value);
    resetErrorMessage();
  };

  const handlePriceChange = (_, newCell) => {
    setPrice(newCell.value);
    resetErrorMessage();
  };

  const handleQuantityChange = (_, value) => {
    setQuantity(value);
    resetErrorMessage();
  };

  const moveToNextInput = (e, increment = 1) => {
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    if (!form.elements[index + increment].disabled) {
      form.elements[index + increment].focus();
    } else {
      moveToNextInput(e, increment + 1);
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      moveToNextInput(e);

      return false;
    }

    return true;
  };

  const setRef = ref => {
    if (!ref || isFocusSet.current) return;
    try {
      if (!custodianName) ref.querySelector("input").focus();
      else ref.querySelectorAll("input")[1].focus();
    } catch (_) {
      ref.querySelector("input").focus();
    } finally {
      isFocusSet.current = true;
    }
  };

  const handleCellInvalidTickerAdded = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
    const cell = priceCell;

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      setInvalidTickerUpdatedTs(new Date().getTime());

      dispatch(
        fetchTickerDetails(
          cell.invalidInputText,
          undefined,
          result => {
            const cell = priceCell;

            cell.loading = false;
            if (!result === true) {
              dispatch(showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000));
              setInvalidTickerUpdatedTs(new Date().getTime());
              return;
            }

            const validTickers = result.tickers.filter(
              ticker =>
                cell.supportedTickerTypes.includes(ticker.info.type) &&
                !cell.unsupportedTickerSubTypes.includes(ticker.info.subType)
            );
            if (validTickers.length > 1) {
              cell.tickerInfo = validTickers;
            } else if (validTickers.length === 1) {
              cell.exchangeRateDetails = result.exchangeRateDetails;
              cell.currency = result.tickerShortName;
              cell.invalidInputText = null;
            } else {
              dispatch(showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000));
            }
            setInvalidTickerUpdatedTs(new Date().getTime());
          },
          error => {
            const cell = priceCell;
            cell.loading = false;
            setInvalidTickerUpdatedTs(new Date().getTime());
          }
        )
      );
    }
  };

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return {
        priceCell,
        custodianName,
        quantity
      };
    },
    checkValidity: () => {
      return (custodianName?.length || 0) > 0 && quantity > 0;
    }
  }));

  return (
    <ThemeProvider theme={modifiedTheme}>
      <Container ref={setRef}>
        <CustodianEntry>
          <EditableTextInput
            stretch
            value={custodianName}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            placeholder={`${getCapitalizedStr(custodianCategory, true)} ${i18n.t("name")}`}
          />
          <QtyPriceContainer>
            <QtyInput
              value={quantity}
              placeholder={i18n.t("editableQtyPriceForm.qtyInpPlaceholder")}
              onChange={handleQuantityChange}
            />
            <EditableValueWrapper width="164px">
              <GridCell
                key={invalidTickerUpdatedTs}
                isEditable={true}
                cell={priceCell}
                cellIndex={index}
                cellIndexToStretch={0}
                currency={portfolio.currency}
                onFocus={() => null}
                onBlur={() => null}
                wasRowEverComplete={true}
                isRowUpdated
                onCellUpdate={handlePriceChange}
                onKeyDown={handleKeyDown}
                onCellInvalidTickerAdded={handleCellInvalidTickerAdded}
                isEnterModeOnFocus
              />
            </EditableValueWrapper>
            {price ? (
              <EditableValueWrapper width="162px" showRightBorder>
                <GridCell
                  key={`${invalidTickerUpdatedTs}valueDisplay`}
                  isEditable={false}
                  cell={resultPriceCell}
                  cellIndex={index}
                  cellIndexToStretch={0}
                  currency={portfolio.currency}
                  onFocus={() => null}
                  onBlur={() => null}
                  wasRowEverComplete={true}
                  isRowUpdated
                  onCellUpdate={handlePriceChange}
                  onKeyDown={handleKeyDown}
                  onCellInvalidTickerAdded={handleCellInvalidTickerAdded}
                  isEnterModeOnFocus
                />
              </EditableValueWrapper>
            ) : (
              <ValueDiv
                stretch
                value={
                  priceCell.value ||
                  formatNumberWithCurrency(
                    !priceCell.invalidInputText
                      ? convertCurrency(price, priceCell.currency, portfolio.currency) * quantity
                      : 0,
                    !priceCell.invalidInputText ? priceCell.currency : portfolio.currency,
                    true,
                    8,
                    false,
                    false,
                    true,
                    true,
                    true
                  )
                }
                readOnly
                tabIndex={-1}
              />
            )}
            {showRemoveBtn && (
              <EditableRemoveContainer onClick={handleRemoveEntry}>
                <RemoveIcon />
              </EditableRemoveContainer>
            )}
          </QtyPriceContainer>
        </CustodianEntry>
      </Container>
    </ThemeProvider>
  );
};

export default forwardRef(QtyCustodian);
