import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { calcCustodianOwnershipValue, isPortfolioReadySelector, recapDataSelector, withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  fetchNetWorthDataForPortfolio,
  currentPortfolioNetWorthDataSelector,
  doughnutDataForPortfolioSelector,
  chartTimeRange,
  isAppInViewMode,
  isMobile,
  userMaskAllValuesSelector,
  currentPortfolioCurrencySelector,
  userPreferencesSelector,
  updateUserPreferences,
  portfolioTaxOnUnrealizedGains,
  getEstimatedTaxOnUnrealizedGainsForCustodian,
  store,
  portfolioNetWorth,
  shortFormatNumberWithCurrency,
  getUnrealizedGainForACustodian,
  categoryType,
  getDefaultTaxRateForPortfolio,
  getHashParams,
  kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies,
  recapDataCashOnHandTotalSelector,
  recapDataCashOnHandCustodiansSelector,
  recapDataTaxOnUnrealisedGainsSelector,
  recapDataCustodiansWithCommitedCapitalSelector,
  portfolioGroupedFundScheduleSelector,
  fundScheduleDurations,
  getFundScheduleTitle
} from "@kubera/common";
import Loader from "components/loader/Loader";
import ChartsNotAvailableComponent from "components/net_worth/ChartsNotAvailableComponent";
import withInitialFetchHandle from "utilities/withInitialFetchHandle";
import MobileNetWorthComponent from "./MobileNetWorthComponent";
import NetWorthScorecard from "./NetWorthScorecard";
import AssetsScorecard from "./AssetsScorecard";
import DebtsScorecard from "./DebtsScorecard";
import NetWorthGreetingComponent from "./NetWorthGreetingComponent";
import DiyDashboardChartsComponent from "./DiyDashboardChartsComponent";
import { ReactComponent as QuestionIcon } from "assets/images/question_mark.svg";
import CustodianListDialog from "./CustodianListDialog";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import { hashParams } from "routes";
import ChangeLabel from "components/labels/ChangeLabel";
import FundScheduleSummaryDialog from "./FundScheduleSummaryDialog";

export const cardComponents = {
  CASH_ON_HAND: "cash_on_hand",
  TAX_UNREALIZED_GAINS: "tax_unrealized_gains",
  FUND_SCHEDULE: "fund_schedule",
  NONE: "none"
};

export const MIN_CHART_DATA_POINTS = 5;

const isMobileDevice = isMobile();

const Container = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 44px;
`;

const Greeting = styled(NetWorthGreetingComponent)`
  width: 100%;
  margin-bottom: 10px;
  font-size: 26px;
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ScorecardsContainer = styled.div`
  display: flex;
  flex: 1
  margin-bottom: 6px;
  gap: 6px;
  width: 100%;
`;

const AssetsAndDebtsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  flex: 2;
`;

const AssetsAndDebtsScoreCardRow = styled.div`
  display: flex;
  flex: 1
  gap: 6px;
`;

const AssetsCard = styled(AssetsScorecard)`
  display: flex;
  flex: 1;
`;

const DebtsCard = styled(DebtsScorecard)`
  display: flex;
  flex: 1;
`;

const OtherCardsContainer = styled.div`
  display: flex;
  flex: 1
  gap: 6px;
  max-height: 170px
`;

const NetWorthCard = styled(NetWorthScorecard)`
  flex: 1;
`;

const NetWorthLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1000;
`;

const Title = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  white-space: pre-line;
  cursor: pointer;
`;

const FieldValue = styled(CurrencyHeaderLabel)`
  cursor: ${props => (!props.onClick === false ? "pointer" : "auto")};
  width: fit-content;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: ${props => props.theme.netWorthContainerBR};
  background: ${props => props.theme.netWorthContainerBG};
`;

const CardTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CardTitle = styled(Title)`
  display: flex;
  align-items: flex-end;
  min-height: 34px;
  margin-bottom: 2px;
  cursor: pointer;
`;

const QuestionMarkIcon = styled(QuestionIcon)`
  width: 14px;
  height: 14px;
  margin-left: 3px;
  margin-bottom: 3px;
  cursor: pointer;

  path {
    fill: ${props => props.theme.dashboardComponentContainerCLR};
  }
`;

const FundScheduleValue = styled(ChangeLabel)`
  padding-top: 2px;
  width: fit-content;
  font-size: 15px;
  line-height: 121%;
  color: ${props => props.theme.adjustedNetWorthColor};
`;

const FundScheduleContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;

  &:hover ${FundScheduleValue} {
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-decoration-line: underline;
  }
`;

const FundScheduleTitle = styled.div`
  font-size: 11px;
  padding-top: 2px;
  line-height: 121%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${props => props.theme.adjustedNetWorthColor};
`;

const AdjustNetWorthValue = styled.div`
  width: fit-content;
  padding-top: 4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 121%;
  color: ${props => props.theme.adjustedNetWorthColor};
`;

const AdjustedNetworthContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover ${AdjustNetWorthValue} {
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-decoration-line: underline;
  }
`;

const AdjustNetWorthDescription = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 121%;
  color: ${props => props.theme.adjustedNetWorthColor};
`;

const PlaceHolderValue = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  font-feature-settings: "ss01" on;
  filter: opacity(0.3);
`;

class NetWorthComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardChartIds: this.props.dashboardChartIds,
      showCashOnHandDialog: false,
      showTaxOnUnrealizedGainsDialog: false,
      showFundScheduleSummaryDialog: false
    };

    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.handleCashOnHandClick = this.handleCashOnHandClick.bind(this);
    this.handleCashOnHandDialogTipClick = this.handleCashOnHandDialogTipClick.bind(this);
    this.handleCashOnHandDialogDismiss = this.handleCashOnHandDialogDismiss.bind(this);

    this.handleTaxOnUnrealizedGainsClick = this.handleTaxOnUnrealizedGainsClick.bind(this);
    this.handleTaxOnUnrealizedGainsDialogTipClick = this.handleTaxOnUnrealizedGainsDialogTipClick.bind(this);
    this.handleTaxOnUnrealizedGainsDialogDismiss = this.handleTaxOnUnrealizedGainsDialogDismiss.bind(this);

    this.handleFundScheduleClick = this.handleFundScheduleClick.bind(this);
    this.handleFundScheduleDialogDismiss = this.handleFundScheduleDialogDismiss.bind(this);

    this.openCards = this.openCards.bind(this);
    this.modifyCardHashParam = this.modifyCardHashParam.bind(this);
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.currentPortfolio.write === 0;
  }

  componentDidMount() {
    this.fetchData();
    this.openCards();
  }

  openCards() {
    const cardHashParam = getHashParams(window.location)[hashParams.CARD];
    if (cardHashParam === cardComponents.TAX_UNREALIZED_GAINS) {
      this.setState({ showTaxOnUnrealizedGainsDialog: true });
    } else if (cardHashParam === cardComponents.CASH_ON_HAND) {
      this.setState({ showCashOnHandDialog: true });
    } else if (cardHashParam === cardComponents.FUND_SCHEDULE) {
      this.setState({ showFundScheduleSummaryDialog: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.currentPortfolio.id !== nextProps.currentPortfolio.id ||
      this.props.portfolioCurrency !== nextProps.portfolioCurrency ||
      !this.props.netWorthData === true ||
      this.props.netWorthData.isFetching !== nextProps.netWorthData?.isFetching ||
      this.props.userMaskAllValues !== nextProps.userMaskAllValues ||
      this.state.showCashOnHandDialog !== nextState.showCashOnHandDialog ||
      this.state.showFundScheduleSummaryDialog !== nextState.showFundScheduleSummaryDialog ||
      this.state.showTaxOnUnrealizedGainsDialog !== nextState.showTaxOnUnrealizedGainsDialog ||
      this.props.userPreferences.isTaxOnUnrealizedGainsTipShown !==
        nextProps.userPreferences.isTaxOnUnrealizedGainsTipShown ||
      this.props.defaultTaxRateForCurrentPortfolio !== nextProps.defaultTaxRateForCurrentPortfolio ||
      this.props.userPreferences.isCashOnHandTipShown !== nextProps.userPreferences.isCashOnHandTipShown ||
      this.props.userPreferences.isUnfunedTipShown !== nextProps.userPreferences.isUnfunedTipShown ||
      this.props.kuberaNumberFormat !== nextProps.kuberaNumberFormat ||
      this.props.totalCashOnHand !== nextProps.totalCashOnHand ||
      this.props.totalTaxOnUnrealizedGains !== nextProps.totalTaxOnUnrealizedGains
    );
  }

  fetchData() {
    setTimeout(() => {
      this.props.fetchNetWorthData(this.props.currentPortfolio.id);
    }, 1000);
  }

  handleLoaderRefreshClick() {
    this.fetchData();
  }

  areAllChartsEmpty() {
    const isDoughnutDataEmpty = this.areDoughnutsEmpty();
    const isNetWorthDataEmpty = this.isNetWorthChartEmpty();
    return isDoughnutDataEmpty && isNetWorthDataEmpty;
  }

  getWeeklyNetWorthDataPointsCount() {
    if (!this.props.netWorthData.data === true) {
      return 0;
    }
    return this.props.netWorthData.data[chartTimeRange.WEEKLY].netWorth.length;
  }

  getNetWorthChartProgress() {
    const weeklyDataPoints = this.getWeeklyNetWorthDataPointsCount();
    if (weeklyDataPoints === 0) {
      return 1 / MIN_CHART_DATA_POINTS;
    }
    return (weeklyDataPoints / MIN_CHART_DATA_POINTS) * 100;
  }

  isNetWorthChartEmpty() {
    return this.getWeeklyNetWorthDataPointsCount() < MIN_CHART_DATA_POINTS;
  }

  areDoughnutsEmpty() {
    return this.props.doughnutData.length === 0;
  }

  handleCashOnHandClick(e) {
    this.modifyCardHashParam(cardComponents.CASH_ON_HAND);
    this.setState({ showCashOnHandDialog: true });
    e.stopPropagation();
  }

  handleCashOnHandDialogDismiss() {
    this.modifyCardHashParam(cardComponents.NONE);
    this.setState({ showCashOnHandDialog: false });
  }

  handleCashOnHandDialogTipClick(e) {
    this.props.updateUserPreferences({
      isCashOnHandTipShown: true
    });
  }

  handleTaxOnUnrealizedGainsClick(e) {
    this.modifyCardHashParam(cardComponents.TAX_UNREALIZED_GAINS);
    this.setState({ showTaxOnUnrealizedGainsDialog: true });
  }

  handleTaxOnUnrealizedGainsDialogDismiss() {
    this.modifyCardHashParam(cardComponents.NONE);
    this.setState({ showTaxOnUnrealizedGainsDialog: false });
  }

  handleTaxOnUnrealizedGainsDialogTipClick(e) {
    this.props.updateUserPreferences({
      isTaxOnUnrealizedGainsTipShown: true
    });
  }

  handleFundScheduleClick(e) {
    this.modifyCardHashParam(cardComponents.FUND_SCHEDULE);
    this.setState({ showFundScheduleSummaryDialog: true });
  }

  handleFundScheduleDialogDismiss() {
    this.modifyCardHashParam(cardComponents.NONE);
    this.setState({ showFundScheduleSummaryDialog: false });
  }

  modifyCardHashParam(card) {
    const detailsHash = card === cardComponents.NONE ? "" : `${hashParams.CARD}=${card}`;
    this.props.history.push({ ...this.props.location, hash: detailsHash });
  }

  getTaxOnUnrealizedGainsListItems() {
    const result = this.props.custodiansWithTaxOnUnrealizedGains.map(custodian => {
      const holdingsForACustodian = this.props.currentPortfolio.details.custodian.filter(
        holding => holding.parentId === custodian.id
      );
      const unrealizedGain = this.props.getUnrealizedGainForACustodian(
        custodian,
        categoryType.ASSET,
        holdingsForACustodian
      );
      return {
        name: custodian.name,
        value: custodian.linkedOwnership
          ? calcCustodianOwnershipValue(
              getEstimatedTaxOnUnrealizedGainsForCustodian(custodian, unrealizedGain),
              custodian.linkedOwnership
            )
          : getEstimatedTaxOnUnrealizedGainsForCustodian(custodian, unrealizedGain),
        id: custodian.id
      };
    });
    result.sort((a, b) => {
      return b.value - a.value;
    });
    return result.filter(item => !item.value === false);
  }

  getFundScheduleSummaryDuration() {
    const groupedFundSchedule = this.props.portfolioGroupedFundSchedule.data;
    for (const key in fundScheduleDurations) {
      if (
        groupedFundSchedule[fundScheduleDurations[key]] &&
        Object.keys(groupedFundSchedule[fundScheduleDurations[key]].data).length > 0 &&
        !groupedFundSchedule[fundScheduleDurations[key]].total === false
      ) {
        return fundScheduleDurations[key];
      }
    }
    return null;
  }

  render() {
    const netWorthData = this.props.netWorthData;
    const netWorthTotal = portfolioNetWorth(store.getState());
    if (!netWorthData === true) {
      return <NetWorthLoader />;
    }

    const error = netWorthData.error;
    const data = netWorthData.data;

    if (!data === true) {
      if (!error === false) {
        return (
          <NetWorthLoader errorMessage={netWorthData.error.errorMessage} onRefresh={this.handleLoaderRefreshClick} />
        );
      }
    }

    const areAllChartsEmpty = this.areAllChartsEmpty();
    if (this.props.isPortfolioReady === false) {
      if (this.isReadOnly()) {
        return <ChartsNotAvailableComponent isReadOnly={true} />;
      } else {
        return (
          <Container>
            <ChartsNotAvailableComponent isReadOnly={false} />
          </Container>
        );
      }
    }

    const fundScheduleDuration = this.getFundScheduleSummaryDuration();
    const fundScheduleValue = fundScheduleDuration
      ? this.props.portfolioGroupedFundSchedule.data[fundScheduleDuration].total
      : 0;

    const isNetWorthChartEmpty = this.isNetWorthChartEmpty();
    return (
      <Container>
        <ChartsContainer>
          <Greeting />
          <ScorecardsContainer>
            <NetWorthCard />
            <AssetsAndDebtsCardsContainer>
              <AssetsAndDebtsScoreCardRow>
                <AssetsCard />
                <DebtsCard />
              </AssetsAndDebtsScoreCardRow>
              <OtherCardsContainer>
                <CardContainer>
                  <CardTitleContainer>
                    <CardTitle onClick={this.handleCashOnHandClick}>{i18n.t("cashOnHand")}</CardTitle>
                    <QuestionMarkIcon onClick={this.handleCashOnHandClick} />
                  </CardTitleContainer>
                  {this.props.recapData !== 0 && (
                    <FieldValue
                      value={this.props.totalCashOnHand}
                      currency={this.props.portfolioCurrency}
                      currencyFontSize={18}
                      valueFontSize={36}
                      fontWeight={400}
                      onClick={this.handleCashOnHandClick}
                      showZeroAsText={true}
                      letterSpacing="-1.86px"
                    />
                  )}
                  {!this.props.recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
                  {fundScheduleDuration && (
                    <FundScheduleContainer onClick={this.handleFundScheduleClick}>
                      <FundScheduleTitle>{getFundScheduleTitle(fundScheduleDuration)}</FundScheduleTitle>
                      <FundScheduleValue
                        currency={this.props.portfolioCurrency}
                        startValue={0}
                        endValue={fundScheduleValue}
                        disableColor={true}
                        disableColorCode={"rgba(0,0,0,0.5)"}
                        alignPosition={"left"}
                      />
                    </FundScheduleContainer>
                  )}
                </CardContainer>
                <CardContainer>
                  <CardTitleContainer>
                    <CardTitle onClick={this.handleTaxOnUnrealizedGainsClick}>
                      {i18n.t("taxOnUnrealizedGain.title")}
                    </CardTitle>
                    <QuestionMarkIcon onClick={this.handleTaxOnUnrealizedGainsClick} />
                  </CardTitleContainer>
                  {this.props.recapData !== 0 && (
                    <>
                      <FieldValue
                        value={this.props.totalTaxOnUnrealizedGains}
                        currency={this.props.portfolioCurrency}
                        currencyFontSize={18}
                        valueFontSize={36}
                        fontWeight={400}
                        onClick={this.handleTaxOnUnrealizedGainsClick}
                        showZeroAsText={true}
                        letterSpacing="-1.86px"
                      />
                      {!this.props.totalTaxOnUnrealizedGains === false && (
                        <AdjustedNetworthContainer onClick={this.handleTaxOnUnrealizedGainsClick}>
                          <AdjustNetWorthDescription>{`Adjusted Net Worth`}</AdjustNetWorthDescription>
                          <AdjustNetWorthValue>{`${shortFormatNumberWithCurrency(
                            netWorthTotal - this.props.totalTaxOnUnrealizedGains,
                            this.props.portfolioCurrency,
                            false,
                            true,
                            undefined,
                            undefined,
                            true
                          )}`}</AdjustNetWorthValue>
                        </AdjustedNetworthContainer>
                      )}
                    </>
                  )}
                  {!this.props.recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
                </CardContainer>

                {this.state.showCashOnHandDialog === true && (
                  <CustodianListDialog
                    title={i18n.t("cashOnHand")}
                    description={i18n.t("cashOnHandDialog.description")}
                    tip={i18n.t("cashOnHandDialog.tip")}
                    knowMore={"https://help.kubera.com/article/86-what-is-cash-on-hand"}
                    currency={this.props.portfolioCurrency}
                    total={this.props.totalCashOnHand}
                    listItems={this.props.custodiansWithCashOnHand}
                    showTip={this.props.userPreferences.isCashOnHandTipShown === false}
                    onTipDismiss={this.handleCashOnHandDialogTipClick}
                    onDismiss={this.handleCashOnHandDialogDismiss}
                  />
                )}
                {this.state.showTaxOnUnrealizedGainsDialog === true && (
                  <CustodianListDialog
                    title={i18n.t("taxOnUnrealizedGainDialog.title")}
                    description={i18n.t("taxOnUnrealizedGainDialog.description")}
                    tip={i18n.t("taxOnUnrealizedGainDialog.tip")}
                    knowMore={"https://help.kubera.com/article/107-what-is-tax-on-unrealized-gain"}
                    currency={this.props.portfolioCurrency}
                    total={this.props.totalTaxOnUnrealizedGains}
                    listItems={this.getTaxOnUnrealizedGainsListItems()}
                    showTip={this.props.userPreferences.isTaxOnUnrealizedGainsTipShown === false}
                    onTipDismiss={this.handleTaxOnUnrealizedGainsDialogTipClick}
                    onDismiss={this.handleTaxOnUnrealizedGainsDialogDismiss}
                    isTaxOnUnrealizedGainsDialog={true}
                    defaultTaxRateForCurrentPortfolio={this.props.defaultTaxRateForCurrentPortfolio}
                  />
                )}
                {this.state.showFundScheduleSummaryDialog === true && (
                  <FundScheduleSummaryDialog
                    isReadOnly={this.isReadOnly()}
                    onDismiss={this.handleFundScheduleDialogDismiss}
                  />
                )}
              </OtherCardsContainer>
            </AssetsAndDebtsCardsContainer>
          </ScorecardsContainer>

          <DiyDashboardChartsComponent
            netWorthData={netWorthData}
            areAllChartsEmpty={areAllChartsEmpty}
            isNetWorthChartEmpty={isNetWorthChartEmpty}
            portfolioId={this.props.currentPortfolio.id}
          />
        </ChartsContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentPortfolio: currentPortfolioSelector(state),
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  netWorthData: currentPortfolioNetWorthDataSelector(state),
  doughnutData: doughnutDataForPortfolioSelector(state),
  userMaskAllValues: userMaskAllValuesSelector(state),
  custodiansWithCashOnHand: recapDataCashOnHandCustodiansSelector(state),
  custodiansWithCommittedCapital: recapDataCustodiansWithCommitedCapitalSelector(state),
  custodiansWithTaxOnUnrealizedGains: recapDataTaxOnUnrealisedGainsSelector(state),
  totalCashOnHand: recapDataCashOnHandTotalSelector(state),
  totalTaxOnUnrealizedGains: portfolioTaxOnUnrealizedGains(state),
  defaultTaxRateForCurrentPortfolio: getDefaultTaxRateForPortfolio(state),
  getUnrealizedGainForACustodian: getUnrealizedGainForACustodian.bind(state),
  kuberaNumberFormat: kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies(state),
  userPreferences: userPreferencesSelector(state),
  recapData: recapDataSelector(state),
  isPortfolioReady: isPortfolioReadySelector(state),
  portfolioGroupedFundSchedule: portfolioGroupedFundScheduleSelector(state)
});

const mapDispatchToProps = {
  fetchNetWorthData: fetchNetWorthDataForPortfolio,
  updateUserPreferences: updateUserPreferences
};

export default isMobileDevice
  ? MobileNetWorthComponent
  : connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(withInitialFetchHandle(NetWorthComponent)));
