import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  getMonthAndYearFromDate,
  nextMonthEnd,
  getMonthFromDate,
  getMonthEndDateAfterMonths,
  currentYearEnd,
  currentQuarterEnd,
  getRulesCashBreakdown,
  getKuberaDateString,
  portfolioCashOnHand,
  getUuid,
  formatMonthYearString,
  planningVariables,
  getRuleText,
  planningAssetTypes,
  portfolioCashForecastDurationSelector,
  updateCashForecastDurationAction,
  currentPortfolioSelector,
  store,
  nextYearEnd,
  getNewCustodianName
} from "@kubera/common";
import ContextMenu from "components/contextmenu/ContextMenu";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import ScenariosComponent from "./ScenariosComponent";
import CurrencyLabel from "components/labels/CurrencyLabel";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import { ReactComponent as PrintIcon } from "assets/images/printer.svg";

const cashForecastDuration = {
  MONTH_1: "month_1",
  QUARTER: "quarter",
  MONTH_3: "month_3",
  CURRENT_YEAR: "current_year",
  YEAR_1: "year_1"
};

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 359px;
  font-feature-settings: "ss01" on;

  @page {
    size: A4 portrait;
    margin: 0 !important;
  }

  @media print {
    width: 100% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    @media print {
      width: 95% !important;
      margin-left: 0px !important;
      margin-right: 50px !important;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const PrintTitle = styled.div`
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  text-transform: uppercase;
  height: 0px;
  visibility: hidden;

  @media print {
    visibility: visible !important;
    height: auto !important;
    margin-bottom: 5px !important;
  }
`;

const PrintPortfolioName = styled(PrintTitle)`
  @media print {
    margin-bottom: 5px;
  }
`;

const DurationContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  width: fit-content;
`;

const DurationDates = styled.div`
  font-size: 30px;
  font-weight: 300;
  line-height: 36.31px;
  letter-spacing: -0.08em;
  text-align: left;
  pointer-events: none;
`;

const DurationDescription = styled.div`
  margin-left: 9px;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  letter-spacing: -0.04em;
  text-align: left;
  opacity: 0.5;
  pointer-events: none;
`;

const DurationSelectionIcon = styled(DownArrowIcon)`
  width: 12px;
  height: 12px;
  margin-left: 7px;
  margin-bottom: 10px;
  pointer-events: none;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }

  @media print {
    display: none !important;
  }
`;

const Scenarios = styled(ScenariosComponent)`
    margin-top: 13px
    margin-bottom: 0px;

    @media print {
      display: none !important;
    }
`;

const CashflowHeader = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`;

const CashflowSummaryContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
`;

const PrintButton = styled(PrintIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: rgba(115, 115, 115, 1);
  }

  @media print {
    display: none !important;
  }
`;

const CashflowTotalContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    margin-top: 15px !important;
  }
`;

const CashflowTotalTitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  opacity: 0.5;
  text-transform: uppercase;
`;

const CashflowTotal = styled(CurrencyLabel)`
  margin-top: 4px;
`;

const CashflowSymbol = styled.div`
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 12px;

  @media print {
    margin-top: 30px !important;
  }
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: 11px;
`;

class CashForecastComponent extends React.Component {
  constructor(props) {
    super(props);

    const defaultDuration =
      portfolioCashForecastDurationSelector(store.getState(), props.currentPortfolio.id) ||
      cashForecastDuration.MONTH_1;
    const breakdownData = this.getBreakdowns(defaultDuration);

    this.state = {
      currentDuration: defaultDuration,
      breakdownData: breakdownData,
      gridData: this.getGridData(breakdownData)
    };

    this.handleDurationClick = this.handleDurationClick.bind(this);
    this.handleDurationSelection = this.handleDurationSelection.bind(this);
    this.durationMenuRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.portfolioCash !== this.props.portfolioCash ||
      oldProps.selectedScenarioIndex !== this.props.selectedScenarioIndex
    ) {
      const breakdownData = this.getBreakdowns(this.state.currentDuration);
      const gridData = this.getGridData(breakdownData);
      this.setState({ breakdownData, gridData });

      if (oldProps.currentPortfolio.id !== this.props.currentPortfolio.id) {
        this.props.updateCashForecastDuration(this.props.currentPortfolio.id, this.state.currentDuration);
      }
    }
  }

  handleDurationClick(event) {
    const menuItems = [];
    for (const key in cashForecastDuration) {
      menuItems.push({
        id: cashForecastDuration[key],
        label: `${this.getForecastDateString(cashForecastDuration[key])} (${this.getForecastDescription(
          cashForecastDuration[key]
        )})`,
        selected: cashForecastDuration[key] === this.state.currentDuration
      });
    }

    const targetPosition = event.target.getBoundingClientRect();
    this.durationMenuRef.current.show(
      [menuItems],
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      event.target
    );
  }

  handleDurationSelection(item) {
    const breakdownData = this.getBreakdowns(item.id);
    const gridData = this.getGridData(breakdownData);
    this.setState({ currentDuration: item.id, breakdownData, gridData });
    this.props.updateCashForecastDuration(this.props.currentPortfolio.id, item.id);
  }

  getDurationStartDate() {
    return nextMonthEnd;
  }

  getDurationEndDate(duration) {
    switch (duration) {
      case cashForecastDuration.MONTH_1:
        return nextMonthEnd;
      case cashForecastDuration.MONTH_3:
        return getMonthEndDateAfterMonths(3);
      case cashForecastDuration.YEAR_1:
        return getMonthEndDateAfterMonths(12);
      case cashForecastDuration.QUARTER: {
        if ((new Date().getMonth() + 1) % 3 === 0) {
          return getMonthEndDateAfterMonths(3);
        } else {
          return currentQuarterEnd();
        }
      }
      case cashForecastDuration.CURRENT_YEAR:
        if (new Date().getMonth() === 11) {
          return nextYearEnd;
        }
        return currentYearEnd;
      default:
        return null;
    }
  }

  getForecastDateString(duration) {
    const startDate = this.getDurationStartDate();
    const endDate = this.getDurationEndDate(duration);

    if (!endDate === true) {
      return null;
    }

    if (getKuberaDateString(startDate) === getKuberaDateString(endDate)) {
      return getMonthAndYearFromDate(startDate);
    }
    return `${getMonthFromDate(startDate)} - ${getMonthAndYearFromDate(endDate)}`;
  }

  getForecastDescription(duration) {
    switch (duration) {
      case cashForecastDuration.MONTH_1:
        return `1 ${i18n.t("month")}`;
      case cashForecastDuration.MONTH_3:
        return `3 ${i18n.t("month")}s`;
      case cashForecastDuration.YEAR_1:
        return `1 ${i18n.t("year")}`;
      case cashForecastDuration.QUARTER:
        return `${(new Date().getMonth() + 1) % 3 === 0 ? "Next" : "This"} ${i18n.t("quarter")}`;
      case cashForecastDuration.CURRENT_YEAR:
        if (new Date().getMonth() === 11) {
          return `Next ${i18n.t("year")}`;
        }
        return `This ${i18n.t("year")}`;
      default:
        return "";
    }
  }

  getGroupedData(currentDuration, dataForSelectedScenario) {
    const endDate = this.getDurationEndDate(currentDuration);
    const endDateDataPointIndex = dataForSelectedScenario.data.findIndex(
      item => item.date === getKuberaDateString(endDate)
    );

    const groupedData = { currency: dataForSelectedScenario.currency, data: [] };
    for (let i = 0; i <= endDateDataPointIndex - 1; i++) {
      groupedData.data.push({
        startData: dataForSelectedScenario.data[i],
        endData: dataForSelectedScenario.data[i + 1]
      });
    }
    return groupedData;
  }

  getBreakdowns(currentDuration) {
    const dataForSelectedScenario = this.props.planningData[this.props.selectedScenarioIndex];
    const rules = dataForSelectedScenario.processedRules;
    const groupedData = this.getGroupedData(currentDuration, dataForSelectedScenario);

    // gets the cash breakdown and splits it up into inflow and outflow
    const retVar = [];
    let startBreakdown = null;

    for (const dp of groupedData.data) {
      const ruleIdToCD = new Map(); // combining inflow and outflow for the same rule and only showing in one place

      const inflow = { breakdown: {}, total: 0 };
      const outflow = { breakdown: {}, total: 0 };

      const endBreakdown = getRulesCashBreakdown(rules, dp.endData);
      const keys = Object.keys(endBreakdown);
      for (const key of keys) {
        const inflowKeyObj = { ...endBreakdown[key], rules: [], total: 0 };
        const outflowKeyObj = { ...endBreakdown[key], rules: [], total: 0 };

        for (const endRule of endBreakdown[key].rules) {
          const { id } = endRule;
          const netRule = window.kbStructuredClone(endRule);
          const startRule = startBreakdown && startBreakdown[key]?.rules.find(s => s.id === id);
          const isOutflow = endRule.changes.cumulativeDelta < 0;
          let delta =
            (isOutflow ? -1 : 1) * (endRule.changes.cumulativeDelta - (startRule?.changes.cumulativeDelta || 0));
          const destinationObj = isOutflow ? outflowKeyObj : inflowKeyObj;

          if (ruleIdToCD.has(id)) {
            // rule has a pre existing inflow or outflow, need to combine
            const otherChange = ruleIdToCD.get(id);
            const sourceObj = otherChange.isOutflow ? outflow : inflow;
            sourceObj.total -= otherChange.delta;

            const combinedChange = (otherChange.isOutflow ? -1 : 1) * otherChange.delta + (isOutflow ? -1 : 1) * delta;
            const finalObj = combinedChange < 0 ? outflow : inflow;
            const absChange = Math.abs(combinedChange);

            const sourceKeyObj = sourceObj.breakdown[otherChange.key];
            if (!sourceKeyObj === true) {
              continue;
            }
            if (sourceObj === finalObj && absChange) {
              const sourceRule = sourceKeyObj.rules.find(r => r.id === id);
              sourceRule.changes.cumulativeDelta = absChange;
              sourceObj.total += absChange;
              continue;
            } else {
              delta = absChange;
              sourceKeyObj.rules = sourceKeyObj.rules.filter(r => r.id !== id);
            }
          } else if (delta) {
            ruleIdToCD.set(endRule.id, { isOutflow, key, delta });
          }

          netRule.changes.cumulativeDelta = delta;
          destinationObj.total += delta;
          destinationObj.rules.push(netRule);
        }

        const addKeyObjToObj = (keyObj, obj) => {
          obj.breakdown[key] = keyObj;
          obj.total += keyObj.total;
          delete keyObj.total; // not needed anymore
        };

        addKeyObjToObj(inflowKeyObj, inflow);
        addKeyObjToObj(outflowKeyObj, outflow);
      }
      retVar.push({ inflow, outflow, date: dp.endData.date });
      startBreakdown = endBreakdown; // updating end point of previous month
    }
    return retVar;
  }

  getInflowTotal(breakdownData) {
    return breakdownData.reduce((acc, curr) => acc + curr.inflow.total, 0);
  }

  getOutflowTotal(breakdownData) {
    return breakdownData.reduce((acc, curr) => acc + curr.outflow.total, 0);
  }

  getRuleText(ruleId) {
    if (ruleId === planningVariables.TAX) return "Tax";

    const dataForSelectedScenario = this.props.planningData[this.props.selectedScenarioIndex];
    const rule = dataForSelectedScenario.processedRules.find(item => item.id === ruleId);
    if (!rule === true) {
      return "";
    }

    var ruleText = getRuleText(
      rule.type,
      rule.data,
      getNewCustodianName(rule, dataForSelectedScenario.processedRules),
      planningAssetTypes.cash.label,
      true
    );
    return ruleText;
  }

  getEmptyRow(sortKey) {
    const selectedScenario = this.props.planningData[this.props.selectedScenarioIndex];
    const cellStyle = (rowIndex, cellIndex) => {
      if (rowIndex === 0 || rowIndex === this.state.gridData.sheets[0].sections[0].rows.length - 1) {
        return {
          fontWeight: 600
        };
      }
      return null;
    };

    const dateCell = new GridCellData(cellType.TEXT, i18n.t("date"), null);
    dateCell.textAlignment = "left";
    const ruleCell = new GridCellData(cellType.TEXT, "", null);
    ruleCell.getCellStyles = (rowIndex, cellIndex) => {
      return cellStyle(rowIndex, cellIndex);
    };
    const inflowCell = new CurrencyCellData(cellType.CURRENCY, i18n.t("inflow"), null, selectedScenario.currency);
    const outflowCell = new CurrencyCellData(cellType.CURRENCY, i18n.t("outflow"), null, selectedScenario.currency);
    const balanceCell = new CurrencyCellData(cellType.CURRENCY, i18n.t("balance"), null, selectedScenario.currency);
    balanceCell.getCellStyles = (rowIndex, cellIndex) => {
      return cellStyle(rowIndex, cellIndex);
    };
    const cells = [dateCell, ruleCell, inflowCell, outflowCell, balanceCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => true);
    rowData.showHint = false;
    return rowData;
  }

  getGridData(breakdownData) {
    const selectedScenario = this.props.planningData[this.props.selectedScenarioIndex];
    let runningBalance = this.props.portfolioCash;
    let index = 0;
    let rows = [];

    const openingRow = this.getEmptyRow(`${index}`);
    openingRow.cells[0].value = formatMonthYearString(selectedScenario.data[0].date);
    openingRow.cells[1].value = i18n.t("cashOpeningBalance");
    openingRow.cells[4].value = this.props.portfolioCash;
    rows.push(openingRow);
    index++;

    const addRow = (date, rule, isOutflow) => {
      const row = this.getEmptyRow(`${index}`);
      row.cells[0].value = formatMonthYearString(date);
      row.cells[1].value = this.getRuleText(rule.id);
      if (isOutflow) {
        row.cells[3].value = rule.changes.cumulativeDelta;
      } else {
        row.cells[2].value = rule.changes.cumulativeDelta;
      }
      runningBalance += (isOutflow ? -1 : 1) * rule.changes.cumulativeDelta;
      row.cells[4].value = runningBalance;
      rows.push(row);
      index++;
    };

    for (const breakdown of breakdownData) {
      let inflowRules = Object.keys(breakdown.inflow.breakdown)
        .map(key => breakdown.inflow.breakdown[key].rules)
        .flat()
        .filter(rule => !rule.changes.cumulativeDelta === false);
      let outflowRules = Object.keys(breakdown.outflow.breakdown)
        .map(key => breakdown.outflow.breakdown[key].rules)
        .flat()
        .filter(rule => !rule.changes.cumulativeDelta === false);

      for (const rule of inflowRules) {
        addRow(breakdown.date, rule, false);
      }
      for (const rule of outflowRules) {
        addRow(breakdown.date, rule, true);
      }
    }

    if (breakdownData.length > 0) {
      const closingRow = this.getEmptyRow(`${index}`);
      closingRow.cells[0].value = formatMonthYearString(breakdownData[breakdownData.length - 1].date);
      closingRow.cells[1].value = i18n.t("cashClosingBalance");
      closingRow.cells[4].value = Math.round(runningBalance);
      rows.push(closingRow);
    }

    const inflowTotal = this.getInflowTotal(breakdownData);
    const outflowTotal = this.getOutflowTotal(breakdownData);

    const dateColumn = new GridColumnData(i18n.t("date"), true, false, false);
    const ruleColumn = new GridColumnData("", true, false, false);
    const inflowColumn = new GridColumnData(i18n.t("inflow"), true, false, false);
    inflowColumn.total = Math.round(inflowTotal);
    const outflowColumn = new GridColumnData(i18n.t("outflow"), true, false, false);
    outflowColumn.total = Math.round(outflowTotal);
    const balanceColumn = new GridColumnData(i18n.t("balance"), true, false, false);
    balanceColumn.hideTotalForColumn = true;
    const columns = [dateColumn, ruleColumn, inflowColumn, outflowColumn, balanceColumn];
    const section = new GridSectionData(getUuid(), "1", "Section 1", [], columns, undefined, undefined, false);
    section.showAddNewInFooter = false;
    section.rows = rows;
    section.disableRowWindowing = true;

    const sheet = new GridSheetData(getUuid(), "1", null, []);
    sheet.sections = [section];

    const gridData = new GridData(selectedScenario.currency, [sheet]);
    gridData.currentSheetIndex = 0;
    gridData.forceShowSheetsTitles = false;
    gridData.isEditable = false;
    return gridData;
  }

  render() {
    if (!this.props.planningData === true) {
      return null;
    }
    const dataForSelectedScenario = this.props.planningData[this.props.selectedScenarioIndex];
    const inflowTotal = this.getInflowTotal(this.state.breakdownData);
    const outflowTotal = this.getOutflowTotal(this.state.breakdownData);

    return (
      <Container id="cash-forecast-statement">
        <ContentContainer>
          <PrintPortfolioName>{this.props.currentPortfolio.name}</PrintPortfolioName>
          <PrintTitle>{`${dataForSelectedScenario.scenario.name} / ${i18n.t("cashForecast")}`}</PrintTitle>
          <DurationContainer onClick={this.handleDurationClick}>
            <DurationDates>{this.getForecastDateString(this.state.currentDuration)}</DurationDates>
            <DurationDescription>{this.getForecastDescription(this.state.currentDuration)}</DurationDescription>
            <DurationSelectionIcon />
          </DurationContainer>

          <Scenarios
            selectedScenarioIndex={this.props.selectedScenarioIndex}
            onScenarioChange={this.props.onScenarioChange}
            planningData={this.props.planningData}
            colorPallete={this.props.colorPallete}
            hideRules={true}
            disableAddScenario={true}
            showValueForDate={this.getDurationEndDate(this.state.currentDuration)}
          />

          <CashflowHeader>
            <CashflowSummaryContainer>
              <CashflowTotalContainer>
                <CashflowTotalTitle>{i18n.t("opening")}</CashflowTotalTitle>
                <CashflowTotal
                  currency={dataForSelectedScenario.currency}
                  value={Math.round(this.props.portfolioCash)}
                />
              </CashflowTotalContainer>
              <CashflowSymbol>{"+"}</CashflowSymbol>
              <CashflowTotalContainer>
                <CashflowTotalTitle>{i18n.t("inflow")}</CashflowTotalTitle>
                <CashflowTotal currency={dataForSelectedScenario.currency} value={Math.round(inflowTotal)} />
              </CashflowTotalContainer>
              <CashflowSymbol>{"-"}</CashflowSymbol>
              <CashflowTotalContainer>
                <CashflowTotalTitle>{i18n.t("outflow")}</CashflowTotalTitle>
                <CashflowTotal currency={dataForSelectedScenario.currency} value={Math.round(outflowTotal)} />
              </CashflowTotalContainer>
              <CashflowSymbol>{"="}</CashflowSymbol>
              <CashflowTotalContainer>
                <CashflowTotalTitle>{i18n.t("closing")}</CashflowTotalTitle>
                <CashflowTotal
                  currency={dataForSelectedScenario.currency}
                  value={Math.round(this.props.portfolioCash + inflowTotal - outflowTotal)}
                />
              </CashflowTotalContainer>
            </CashflowSummaryContainer>
            <PrintButton onClick={e => window.print()} />
          </CashflowHeader>

          <Grid gridData={this.state.gridData} showGridTitle={false} />

          <ContextMenu width={250} ref={this.durationMenuRef} onSelection={this.handleDurationSelection} />
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolioCash: portfolioCashOnHand(state),
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  updateCashForecastDuration: updateCashForecastDurationAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashForecastComponent));
