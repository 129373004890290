import React, { useMemo, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import defaultTheme from "theme";
import * as Sentry from "@sentry/browser";
import { Provider, useSelector } from "react-redux";

import {
  signOut,
  store,
  getAppVersion,
  userSelector,
  isAppInWhiteLabelMode,
  isMobile,
  siteConfigSelector
} from "@kubera/common";
import { ReactComponent as MoneyBag } from "assets/images/moneybag.svg";
import kuberaLogo from "assets/images/kubera_logo.svg";
import i18n from "locale/i18n";
import PrimaryButton from "components/button/PrimaryButton";

const isMobileDevice = isMobile();

// Capture global error and send to sentry
export const captureGlobalError = (error, { componentStack }) => {
  Sentry.withScope(scope => {
    scope.setTag("global_error", "GlobalOnError");
    scope.setExtras({
      userId: userSelector(store.getState()) ? userSelector(store.getState()).id : "",
      appVersion: getAppVersion()
    });
    Sentry.captureException(error, {
      extra: {
        componentStack
      }
    });
  });
};

// Troubleshoot component
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  padding: 0 10px;
`;

const KuberaLogo = styled.img`
  width: 90px;
  margin-top: 35px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  margin-bottom: 10px;
  text-align: center;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : null)};
`;

const Desc = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 30px;
  color: ${props => props.theme.headsUpDesktopBannerCLR};

  a {
    color: inherit;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
`;

const isWhiteLabelSite = isAppInWhiteLabelMode();
function TroubleShoot() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(userSelector);
  const siteConfig = useSelector(siteConfigSelector);

  const isUserObjectPresent = useMemo(() => !!user?.id, [user]);
  const { supportEmail } = useMemo(() => siteConfig || {}, [siteConfig]);

  const refreshPage = () => {
    setIsRefreshing(true);
    setIsDisabled(true);
    window.location.reload();
  };

  const clearSiteData = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    signOut();
  };

  if (isWhiteLabelSite) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Container id="troubleshoot-app">
          <MoneyBag />
          <Title marginTop="20">{i18n.t("troubleshoot.title")}</Title>
          <Desc
            dangerouslySetInnerHTML={{
              __html: i18n
                .t("troubleshoot.wlkdesc")
                .replace(supportEmail ? /%support%|%\/support%/g : /%support%.*?%\/support%/g, "")
                .replace(/%email%/g, supportEmail)
                .replace(isMobileDevice ? /%m%|%\/m%/g : /%m%.*?%\/m%/g, "")
                .replace(isUserObjectPresent ? /%u%|%\/u%/g : /%u%.*?%\/u%/g, "")
            }}
          />
          <BtnContainer>
            <PrimaryButton
              title={i18n.t("refresh")}
              isLoading={isRefreshing}
              isDisabled={isDisabled}
              onClick={refreshPage}
            />
            {isUserObjectPresent && (
              <PrimaryButton
                title={i18n.t("signOut")}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onClick={clearSiteData}
              />
            )}
          </BtnContainer>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container id="troubleshoot-app">
        <MoneyBag />
        <KuberaLogo src={kuberaLogo} />
        <Title>{i18n.t("troubleshoot.title")}</Title>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n
              .t("troubleshoot.desc")
              .replace(isMobileDevice ? /%m%|%\/m%/g : /%m%.*?%\/m%/g, "")
              .replace(isUserObjectPresent ? /%u%|%\/u%/g : /%u%.*?%\/u%/g, "")
          }}
        />
        <BtnContainer>
          <PrimaryButton
            title={i18n.t("refresh")}
            isLoading={isRefreshing}
            isDisabled={isDisabled}
            onClick={refreshPage}
          />
          {isUserObjectPresent && (
            <PrimaryButton
              title={i18n.t("signOut")}
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={clearSiteData}
            />
          )}
        </BtnContainer>
      </Container>
    </ThemeProvider>
  );
}

function TroubleShootWrapper() {
  return (
    <Provider store={store}>
      <TroubleShoot />
    </Provider>
  );
}

export default TroubleShootWrapper;
