import React from "react";
import styled from "styled-components";
import ChangeLabel from "components/labels/ChangeLabel";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import i18n from "i18next";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import {
  currentPortfolioSelector,
  pastValueInterval,
  updateUserPreferences,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  showCopiedToast
} from "@kubera/common";
import { connect } from "react-redux";

const GridTotalContainer = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

const GridTotal = styled(CurrencyHeaderLabel)`
  margin-right: 20px;
  cursor: pointer;
`;

const TotalChangeContainer = styled.div`
  margin-right: 15px;
`;

const TotalChangeTitleContainer = styled.div`
  display: flex;
`;

const TotalChangeTitle = styled.div`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  color: ${props => props.theme.totalChangeTitleCLR};
  white-space: nowrap;
`;

const TotalChangeLabelContainer = styled.div`
  display: flex;
  position: relative;
`;

const TotalChangeLabel = styled(ChangeLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
  white-space: nowrap;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};

  ${TotalChangeContainer}:hover & {
    text-decoration: underline;
  }
`;

class GridTotalHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changeToolTipRef = React.createRef();
    this.dayChangeToolTipRef = React.createRef();
  }

  componentDidMount() {
    this.showDayChangeToolTip();
    this.showChangeToolTip();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.totalValue !== this.props.totalValue) {
      if (!this.props.onTotalChange === false) {
        this.props.onTotalChange();
      }
    }

    this.showDayChangeToolTip();
    this.showChangeToolTip();
  }

  getTargetIdForToolTip() {
    if (
      !this.props.changeTotalsAndContributors.changes.year.total === true &&
      !this.props.changeTotalsAndContributors.changes.month.total === true &&
      !this.props.changeTotalsAndContributors.changes.week.total === false
    ) {
      return "week_change_label";
    } else if (
      !this.props.changeTotalsAndContributors.changes.year.total === true &&
      !this.props.changeTotalsAndContributors.changes.month.total === false
    ) {
      return "month_change_label";
    } else if (!this.props.changeTotalsAndContributors.changes.year.total === false) {
      return "year_change_label";
    } else {
      return null;
    }
  }

  showDayChangeToolTip() {
    if (
      this.props.showChangeBadge &&
      !this.props.changeTotalsAndContributors.changes.year.total === true &&
      !this.props.changeTotalsAndContributors.changes.month.total === true &&
      !this.props.changeTotalsAndContributors.changes.week.total === true
    ) {
      this.dayChangeToolTipRef.current.show(i18n.t("changeLabelTip"), 15, -26);
    } else {
      this.dayChangeToolTipRef.current.dismiss();
      return;
    }
  }

  showChangeToolTip() {
    if (
      this.props.showChangeBadge &&
      (!this.props.changeTotalsAndContributors.changes.week.total === false ||
        !this.props.changeTotalsAndContributors.changes.month.total === false ||
        !this.props.changeTotalsAndContributors.changes.year.total === false)
    ) {
      this.changeToolTipRef.current.show(i18n.t("changeLabelTip"), 15, -26);
    } else {
      this.changeToolTipRef.current.dismiss();
      return;
    }
  }

  getWeekOrMonthlyOrYearlyChangelabel = () => {
    if (
      this.props.changeTotalsAndContributors.changes.year.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.year.total === false ||
        (this.props.changeData && this.props.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.year.total === false &&
        !this.props.changeData === false &&
        this.props.changeData.isAllChangeFetching;
      return (
        <TotalChangeContainer>
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("yearChange")}</TotalChangeTitle>
          </TotalChangeTitleContainer>
          <TotalChangeLabelContainer>
            <TotalChangeLabel
              id="year_change_label"
              currency={this.props.currency}
              startValue={this.props.changeTotalsAndContributors.changes.year.total}
              endValue={this.props.changeTotalsAndContributors.currentTotal}
              disableColor={this.props.disableTotalChangeColor}
              isUpdating={isUpdating}
              placeholder={
                !this.props.changeTotalsAndContributors.changes.year.total === true &&
                this.props.changeData.isAllChangeFetching
              }
              onClick={e => this.props.onTotalChangeClick(e, pastValueInterval.YEAR)}
              isAnimationAllowed={false}
              alignPosition="left"
            />
          </TotalChangeLabelContainer>
        </TotalChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.month.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.month.total === false ||
        (this.props.changeData && this.props.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.month.total === false &&
        !this.props.changeData === false &&
        this.props.changeData.isAllChangeFetching;
      return (
        <TotalChangeContainer>
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("monthChange")}</TotalChangeTitle>
          </TotalChangeTitleContainer>
          <TotalChangeLabelContainer>
            <TotalChangeLabel
              id="month_change_label"
              currency={this.props.currency}
              startValue={this.props.changeTotalsAndContributors.changes.month.total}
              endValue={this.props.changeTotalsAndContributors.currentTotal}
              disableColor={this.props.disableTotalChangeColor}
              isUpdating={isUpdating}
              placeholder={
                !this.props.changeTotalsAndContributors.changes.month.total === true &&
                this.props.changeData.isAllChangeFetching
              }
              onClick={e => this.props.onTotalChangeClick(e, pastValueInterval.MONTH)}
              isAnimationAllowed={false}
              alignPosition="left"
            />
          </TotalChangeLabelContainer>
        </TotalChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.week.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.week.total === false ||
        (this.props.changeData && this.props.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.week.total === false &&
        !this.props.changeData === false &&
        this.props.changeData.isAllChangeFetching;
      return (
        <TotalChangeContainer>
          <TotalChangeTitleContainer>
            <TotalChangeTitle>{i18n.t("weekChange")}</TotalChangeTitle>
          </TotalChangeTitleContainer>
          <TotalChangeLabelContainer>
            <TotalChangeLabel
              id="week_change_label"
              currency={this.props.currency}
              startValue={this.props.changeTotalsAndContributors.changes.week.total}
              endValue={this.props.changeTotalsAndContributors.currentTotal}
              disableColor={this.props.disableTotalChangeColor}
              isUpdating={isUpdating}
              placeholder={
                !this.props.changeTotalsAndContributors.changes.week.total === true &&
                this.props.changeData.isAllChangeFetching
              }
              onClick={e => this.props.onTotalChangeClick(e, pastValueInterval.WEEK)}
              isAnimationAllowed={false}
              alignPosition="left"
            />
          </TotalChangeLabelContainer>
        </TotalChangeContainer>
      );
    }
  };

  handleGridTotalClick = () => {
    if (!this.props.totalValue) {
      return;
    }
    navigator.clipboard.writeText(this.props.totalValue);
    this.props.showCopiedToast();
  };

  render() {
    const isUpdating =
      !this.props.changeTotalsAndContributors.changes.day.total === false &&
      this.props.changeData &&
      this.props.changeData.isDayChangeFetching;
    return (
      <GridTotalContainer>
        <GridTotal
          currency={this.props.currency}
          value={this.props.totalValue}
          defaultValueString={this.props.title}
          currencyFontSize={16}
          valueFontSize={36}
          onClick={this.handleGridTotalClick}
        />
        <ToolTip
          ref={this.dayChangeToolTipRef}
          targetId={"day_change_label"}
          align={toolTipAlignment.RIGHT}
          arrowOffset={30}
          onPositiveClick={() => {
            this.dayChangeToolTipRef.current.dismiss();
            this.props.updateUserPreferences({ isContributingCustodiansTipShown: true });
          }}
        />
        <ToolTip
          ref={this.changeToolTipRef}
          targetId={this.getTargetIdForToolTip()}
          align={toolTipAlignment.RIGHT}
          arrowOffset={30}
          onPositiveClick={() => {
            this.changeToolTipRef.current.dismiss();
            this.props.updateUserPreferences({ isContributingCustodiansTipShown: true });
          }}
        />
        {this.props.changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === true && (
          <TotalChangeContainer>
            <TotalChangeTitleContainer>
              <TotalChangeTitle>{i18n.t("dayChange")}</TotalChangeTitle>
            </TotalChangeTitleContainer>
            <TotalChangeLabelContainer>
              <TotalChangeLabel
                id="day_change_label"
                currency={this.props.currency}
                startValue={this.props.changeTotalsAndContributors.changes.day.total}
                endValue={this.props.changeTotalsAndContributors.currentTotal}
                disableColor={this.props.disableTotalChangeColor}
                placeholder={
                  !this.props.changeTotalsAndContributors.changes.day.total === true &&
                  this.props.changeData &&
                  this.props.changeData.isDayChangeFetching
                }
                onClick={e => this.props.onTotalChangeClick(e, pastValueInterval.DAY)}
                isUpdating={isUpdating}
                isAnimationAllowed={false}
                alignPosition="left"
              />
            </TotalChangeLabelContainer>
          </TotalChangeContainer>
        )}
        {this.getWeekOrMonthlyOrYearlyChangelabel()}
      </GridTotalContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  changeTotalsAndContributors: getChangeTotalsWithContributorsForCurrentPortfolio(state, props.category)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  showCopiedToast: showCopiedToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridTotalHeader);
