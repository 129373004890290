import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import FileUploadInput from "components/inputs/FileUploadInput";
import DragDropInput from "components/inputs/DragDropInput";
import { generateThumbnailForFile } from "utilities/FileUtils";
import PrimaryButton from "components/button/PrimaryButton";

const NoDocumentsContainer = styled(DragDropInput)`
  display: flex;
  flex-direction: column;
  width: 436px;
  height: 472px;
  margin: auto;
  background: ${props => props.theme.noDocumentsContainerBG};
  border: ${props => props.theme.noDocumentsContainerBR};
  box-sizing: border-box;
  margin-top: 60px;
  padding-left: 50px;
`;

const CommandArea = styled.div`
  position: absolute;
  width: 350px;
  height: ${props => (props.isDragging === false ? "350px" : "0px")};
  display: flex;
  align-content: start;
  visibility: ${props => (props.isDragging === false ? "visible" : "hidden")};
`;

const Title = styled.div`
  position: absolute;
  top: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const Description = styled.div`
  position: absolute;
  top: 140px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  font-feature-settings: "ss01" on;
  width: 300px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  text-align: left;
  white-space: pre-wrap;
`;

const ButtonStyle = css`
  position: absolute;
  top: 351px;
  width: 134px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.primaryButtonColor};
  background-color: ${props => props.theme.primaryButtonBackgroundColor};
  cursor: pointer;
  justify-content: center;
  outline: 0;
  border: 0;
  margin-top: 18px;
`;

const UploadButton = styled(FileUploadInput)`
  ${ButtonStyle}
`;

const ShowFilesBtn = styled(PrimaryButton)`
  ${ButtonStyle}
`;

const UploadHint = styled.div`
  position: absolute;
  top: 413px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.emptySafeDepositComponentHintCLR};
  margin-top: 8px;
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  margin-top: 70px;
  width: 336px;
  height: 360px;
  height: ${props => (props.isDragging === true ? "330px" : "0px")};
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  height: 15px;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.emptySafeDepositComponentHintCLR};
  white-space: pre-wrap;
  margin-top: 144px;
`;

class EmptySafeDepositComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isDragging: false
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragStop() {
    this.setState({ isDragging: false });
  }

  handleFileUpload(files) {
    const { handleOpenDocument = () => null } = this.props;
    handleOpenDocument();
    if (this.props.onDocumentUpload) {
      for (const file of files) {
        generateThumbnailForFile(file, thumbnail => {
          this.props.onDocumentUpload(file, thumbnail);
        });
      }
    }
  }

  render() {
    const isDragging = this.state.isDragging;

    const { showFilesBtn, handleOpenDocument = () => null } = this.props;

    return (
      <NoDocumentsContainer
        onDragStart={this.handleDragStart}
        onDragStop={this.handleDragStop}
        onFileDrop={this.handleFileUpload}
        disabled={this.props.isReadOnly === true}
      >
        <CommandArea isDragging={isDragging}>
          <Title>{i18n.t("emptySafeDepositComponent.title")}</Title>
          <Description>{i18n.t("emptySafeDepositComponent.description")}</Description>
          {!this.props.isReadOnly && (
            <>
              {!showFilesBtn ? (
                <UploadButton label={i18n.t("upload")} multiple={true} onChange={this.handleFileUpload} />
              ) : (
                <ShowFilesBtn title={i18n.t("emptySafeDepositComponent.showFilesBtn")} onClick={handleOpenDocument} />
              )}
              {!showFilesBtn && <UploadHint>{i18n.t("emptySafeDepositComponent.uploadHint")}</UploadHint>}
            </>
          )}
        </CommandArea>
        <DropAreaIndicator isDragging={isDragging}>
          <DropAreaHint>{i18n.t("documentsComponent.dropFileHint")}</DropAreaHint>
        </DropAreaIndicator>
      </NoDocumentsContainer>
    );
  }
}

export default EmptySafeDepositComponent;
