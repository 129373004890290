import React, { useEffect, useImperativeHandle } from "react";
import {
  showRecaptchaV2Widget,
  verifyV3Token,
  getRecaptchaStoredResponse,
  setRecaptchaVerifyResponse
} from "@kubera/common";

const handleConditionalRenderRecaptchaV2 = (action, onClick) => {
  verifyV3Token(action).catch(() => {
    showRecaptchaV2Widget(onClick);
  });
};

const RecaptchaV2Render = React.forwardRef(({ action, verifyOnLoad = true, onClick = () => null }, ref) => {
  useEffect(() => {
    if (!verifyOnLoad && !getRecaptchaStoredResponse()) return () => null;
    const renderRecaptchaV2Element = async () => {
      await window.recaptchaV2LoadPromise;
      handleConditionalRenderRecaptchaV2(action, onClick);
    };

    renderRecaptchaV2Element();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, verifyOnLoad]);

  useImperativeHandle(ref, () => ({
    showRecaptchaV2Widget: error => {
      if (error?.code === "UserLambdaValidationException") {
        setRecaptchaVerifyResponse(error, "reject");
      }
      showRecaptchaV2Widget(onClick);
    }
  }));

  return <div id="html_element" style={{ marginTop: "5px" }}></div>;
});

export default RecaptchaV2Render;
