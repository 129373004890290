import React from "react";
import styled from "styled-components";

import { ReactComponent as CheckboxCheckedIcon } from "assets/images/checkbox_checked.svg";
import { ReactComponent as CheckboxUncheckedIcon } from "assets/images/checkbox_unchecked.svg";
import { ReactComponent as CheckboxDisabledCheckedIcon } from "assets/images/checkbox_disabled.svg";
import { ReactComponent as CheckboxDisabledUncheckedIcon } from "assets/images/checkbox_disabled_unchecked.svg";

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.labelOnLeft === true ? "row-reverse" : "row")};
  align-items: center;
  cursor: pointer;
`;

const CheckboxSquare = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
`;

const CheckboxChecked = styled(CheckboxCheckedIcon)`
  path {
    fill: ${props => props.theme.checkboxBG} !important;
  }
`;

const CheckboxUnchecked = styled(CheckboxUncheckedIcon)`
  path {
    fill: ${props => props.theme.checkboxBG} !important;
  }
`;

const CheckboxLabel = styled.div`
  flex: 1;
  margin-left: ${props => (props.labelOnLeft === true ? "0ox" : "8px")};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.5)" : "black")};
  ${props => (props.noPreWrap ? "" : "white-space: pre-wrap;")}
`;

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.checked === true };

    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (this.state.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  toggleCheckbox(e) {
    if (this.props.disabled === true) {
      return;
    }
    const newCheckedState = !this.state.checked;
    this.setState({ checked: newCheckedState });

    if (this.props.onChange) {
      this.props.onChange(newCheckedState, e);
    }
    e.stopPropagation();
  }

  getCheckboxIcon(checked, disabled) {
    if (disabled) {
      return checked === true ? <CheckboxDisabledCheckedIcon /> : <CheckboxDisabledUncheckedIcon />;
    } else if (checked) {
      return <CheckboxChecked />;
    } else {
      return <CheckboxUnchecked />;
    }
  }

  render() {
    const icon = this.getCheckboxIcon(this.props.checked, this.props.disabled);
    const labelOnLeft = !this.props.labelOnLeft === false;

    return (
      <Container className={this.props.className} labelOnLeft={labelOnLeft}>
        <CheckboxSquare onClick={this.toggleCheckbox}>{icon}</CheckboxSquare>
        <CheckboxLabel
          disabled={this.props.disabled}
          onClick={this.toggleCheckbox}
          labelOnLeft={labelOnLeft}
          dangerouslySetInnerHTML={{
            __html: this.props.label
          }}
          noPreWrap={this.props.noPreWrap}
        ></CheckboxLabel>
      </Container>
    );
  }
}

export default Checkbox;
