import React from "react";
import AutocompleteSearchInp from "./AutocompleteSearchInp";
import {
  guessFutureDate,
  guessDate,
  parseKuberaDateString,
  isInCustodianHistoryFormat,
  getCustodianHistoryFormattedDateString,
  formatDateOfYearToMonth,
  isInvalidDob,
  minAge,
  dateRange,
  isInFullMonthsFormat,
  isInMonthYearFormat,
  formatMonthYearString
} from "@kubera/common";
import styled from "styled-components";
import { useState } from "react";
import i18n from "i18next";
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DayInputContainer = styled.div`
  position: relative;
`;

const DayInputLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: #000000;
  text-transform: lowercase;
  position: absolute;
  top: 57.5%;
  transform: translate(0%, -57.5%);
  right: 15px;
`;

const DateSuggestionInput = styled(AutocompleteSearchInp)`
  label {
    height: 50px;
    padding: 12px 20px 12px 16px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    outline: none;
    font-weight: 400;
  }

  label > div:first-child {
    display: none;
  }

  input {
    color: ${props => (!props.hasError === true ? "inherit" : props.theme.errorCLR)};
  }
`;

const DateValueContainer = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  &.is-active {
    background: rgba(196, 196, 196, 0.3);
  }
`;

const DateValue = styled.div`
  font-size: ${props => props.suggestionFontSize};
  line-height: 130%;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 14px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => (!props.hasError ? "transparent" : props.theme.errorCLR)};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

const DateInput = ({
  inputPlaceholder,
  value,
  allowPastDate = () => true,
  onDateChange,
  onValidDate = () => {},
  monthMode = false,
  monthYearMode = false,
  errorMessage = null,
  onBlur = () => {},
  isBdayPicker = false,
  rightLabelText = "",
  updateErrorState = () => {},
  suggestionFontSize = "14px",
  ...other
}) => {
  const [resolvedDates, setResolvedDates] = useState([]);
  const [hasError, setHasError] = useState(false);

  const isValidFormat = value => {
    if (monthMode) {
      return isInFullMonthsFormat(value);
    } else if (monthYearMode) {
      return isInMonthYearFormat(value);
    }
    return isInCustodianHistoryFormat(value);
  };

  const formatDateString = dateString => {
    if (monthMode) {
      return formatDateOfYearToMonth(dateString);
    } else if (monthYearMode) {
      return formatMonthYearString(dateString);
    }
    return getCustodianHistoryFormattedDateString(parseKuberaDateString(dateString).getTime());
  };

  const onChange = (newValue = "") => {
    setHasError(false);
    const guessedDate = allowPastDate() ? guessDate(newValue) : guessFutureDate(newValue);
    if (isBdayPicker) {
      guessedDate.isInvalid = isInvalidDob(guessedDate);
    }
    if (guessedDate.isInvalid === false && !isValidFormat(newValue)) {
      setResolvedDates([guessedDate.dateString]);
    } else {
      setResolvedDates([]);
    }
    onDateChange(newValue);
  };

  const onRenderList = item => {
    return (
      <DateValueContainer key={0}>
        <DateValue suggestionFontSize={suggestionFontSize}>{formatDateString(item)}</DateValue>
      </DateValueContainer>
    );
  };

  const resolveOnBlur = () => {
    const guessedDate = allowPastDate() ? guessDate(value) : guessFutureDate(value);
    if (isBdayPicker) {
      guessedDate.isInvalid = isInvalidDob(guessedDate);
    }
    if (guessedDate.isInvalid === false) {
      setResolvedDates([]);
      onDateChange(monthMode || monthYearMode ? guessedDate.dateString : formatDateString(guessedDate.dateString));
      setHasError(false);
      updateErrorState(false);
    } else {
      setHasError(value && true);
      updateErrorState(value && true);
    }
    onBlur();
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      resolveOnBlur();
      if (isValidFormat(value)) onValidDate();
    }
  };

  return (
    <Container>
      <DayInputContainer>
        <DateSuggestionInput
          {...other}
          inputPlaceholder={inputPlaceholder || i18n.t("dateInputPlaceholder")}
          searchValue={value}
          options={resolvedDates}
          onSearchChange={onChange}
          onRenderList={onRenderList}
          onSelect={() => {}}
          onBlur={resolveOnBlur}
          onKeyDown={handleKeyDown}
          hasError={hasError}
          selectedIndex={-1}
        />
        {rightLabelText && <DayInputLabel>{rightLabelText}</DayInputLabel>}
      </DayInputContainer>
      <ErrorMessage hasError={hasError}>
        {isBdayPicker
          ? i18n
              .t("enterValidDOB")
              .replace("{endYear}", new Date().getFullYear() - minAge)
              .replace("{startYear}", new Date().getFullYear() - dateRange)
          : errorMessage}
      </ErrorMessage>
    </Container>
  );
};

export default DateInput;
