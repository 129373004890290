import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { hashParams, modalValues, baseRoutes, queryParams } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  currentPortfolioSelector,
  updatePortfolioShareLink,
  deletePortfolioShareLink,
  getShareLinkFormattedDateString,
  showToastTip,
  toastType,
  isAppInWhiteLabelMode
} from "@kubera/common";
import PortfolioLinkDetailsDialog from "./PortfolioLinkDetailsDialog";
import ToggleSwitch from "components/inputs/ToggleSwitch";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { copyToClipboard } from "utilities/FileUtils";

const ShareDialog = styled(Dialog)`
  width: 700px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 60px 80px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.6);
`;

const CreateLinkButton = styled.button`
  margin: 0;
  margin-left: 5px;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: ${props => props.theme.linkColor};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-feature-settings: "ss01" on;
`;

const EmptyLinksContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-top: 30px;
`;

const EmptyLinksCreateLinkButton = styled(CreateLinkButton)`
  color: black;
  font-weight: normal;
`;

const SharedLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 30px;
`;

const SharedLink = styled.div`
  padding: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: ${props => (props.isLast === true ? null : "20px")};
  background: #ebebeb;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${props => (props.isActive === true ? "#ffffff" : "none")};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const Link = styled.div`
  flex: 1;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => (props.isActive === true ? "black" : "rgba(0, 0, 0, 0.6)")};
`;

const CopyButton = styled.div`
  visibility: ${props => (props.isActive === true ? "visible" : "hidden")};
  padding: 10px;
  margin-right: -10px;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  cursor: pointer;
`;

const LinkDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const LinkDetails = styled.div`
  flex: 1;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.6);
  cursor: ${props => (props.isActive === true ? "pointer" : "auto")};
`;

const DeleteLinkButton = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ShareROLinkHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 1);
  margin-top: 16px;
`;

class ShareROLinkComponent extends React.Component {
  static show = (history, location, custodianId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.SHARE_PORTFOLIO_RO_LINK}`
    });
  };

  static getViewUrl = linkId => {
    const location = window.location;
    var viewUrl = `${location.protocol}//${location.host}${baseRoutes.VIEW}?${queryParams.SHARE_TOKEN}=${linkId}`;

    if (isAppInWhiteLabelMode() === false && location.host === "app.kubera.com") {
      viewUrl = `https://kubera.com${baseRoutes.VIEW}?${queryParams.SHARE_TOKEN}=${linkId}`;
    }
    return viewUrl;
  };

  constructor(props) {
    super(props);

    this.state = {
      showLinkDetailsDialog: false,
      selectedLinkDetails: null,
      showDeleteLinkDialog: false,
      linkToDelete: null
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleCreateShareLink = this.handleCreateShareLink.bind(this);
    this.handleDetailsDialogOnDismiss = this.handleDetailsDialogOnDismiss.bind(this);
    this.handleLinkEnableChange = this.handleLinkEnableChange.bind(this);
    this.handleLinkDeleteButtonClick = this.handleLinkDeleteButtonClick.bind(this);
    this.handleLinkDetailsClick = this.handleLinkDetailsClick.bind(this);
    this.handleLinkCopy = this.handleLinkCopy.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleDeleteLinkDialogOnDismiss = this.handleDeleteLinkDialogOnDismiss.bind(this);
    this.handleDeleteLinkDialogPositiveButtonClick = this.handleDeleteLinkDialogPositiveButtonClick.bind(this);
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  handleCreateShareLink() {
    this.setState({ showLinkDetailsDialog: true, selectedLinkDetails: null });
  }

  handleDetailsDialogOnDismiss() {
    this.setState({ showLinkDetailsDialog: false, selectedLinkDetails: null });
  }

  handleLinkEnableChange(isEnabled, link) {
    link.isEnabled = isEnabled ? 1 : 0;
    this.props.updatePortfolioShareLink(this.props.currentPortfolio, link);
  }

  handleLinkDeleteButtonClick(e, link) {
    this.setState({ showDeleteLinkDialog: true, linkToDelete: link });
  }

  handleDeleteLinkDialogPositiveButtonClick(e) {
    this.props.deletePortfolioShareLink(this.props.currentPortfolio, this.state.linkToDelete.id);
    this.setState({ showDeleteLinkDialog: false, linkToDelete: null });
  }

  handleDeleteLinkDialogOnDismiss(e) {
    this.setState({ showDeleteLinkDialog: false, linkToDelete: null });
  }

  handleLinkDetailsClick(e, link) {
    if (this.isLinkExpired(link)) {
      return;
    }
    this.setState({ showLinkDetailsDialog: true, selectedLinkDetails: link });
  }

  handleLinkCopy(e, link) {
    const viewUrl = ShareROLinkComponent.getViewUrl(link.id);

    copyToClipboard(viewUrl);

    this.props.showToastTip(
      toastType.TIP,
      i18n.t("linkCopied"),
      undefined,
      5000,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      true
    );
  }

  handleLinkClick(e) {
    window.getSelection().selectAllChildren(e.target);
  }

  getLinkDetails(link) {
    var detailString = link.name;

    if (this.isLinkExpired(link) === true) {
      return (detailString += " • " + i18n.t("expired"));
    }
    if (!link.tsExpiry === false) {
      return (detailString += " • " + i18n.t("expiry") + ": " + getShareLinkFormattedDateString(link.tsExpiry * 1000));
    }
    return detailString;
  }

  isLinkExpired(link) {
    if (!link.tsExpiry === true) {
      return false;
    }
    return link.tsExpiry * 1000 < new Date().getTime();
  }

  isLinkActive(link) {
    return link.isEnabled === 1 && this.isLinkExpired(link) === false;
  }

  getReadOnlyPanel() {
    const portfolio = this.props.currentPortfolio;
    const sharedLinks = portfolio.details.share;
    const showLinkDetailsDialog = this.state.showLinkDetailsDialog;

    return (
      <>
        <ShareROLinkHeader>{i18n.t("shareROLink.title")}</ShareROLinkHeader>
        <ContentContainer>
          <DescriptionContainer>
            <Description>{i18n.t("sharePortfolio.readOnlyDescription")}</Description>
            {sharedLinks.length > 0 && (
              <CreateLinkButton onClick={this.handleCreateShareLink}>{i18n.t("createShareLink")}</CreateLinkButton>
            )}
          </DescriptionContainer>

          {sharedLinks.length === 0 && (
            <EmptyLinksContainer>
              <EmptyLinksCreateLinkButton onClick={this.handleCreateShareLink}>
                {i18n.t("createShareLink")}
              </EmptyLinksCreateLinkButton>
            </EmptyLinksContainer>
          )}

          {sharedLinks.length > 0 && (
            <SharedLinksContainer>
              {sharedLinks.map((link, index) => (
                <SharedLink key={index} isLast={index + 1 === sharedLinks.length}>
                  <LinkContainer isActive={this.isLinkActive(link)}>
                    <Link isActive={this.isLinkActive(link)} onClick={this.handleLinkClick}>
                      {ShareROLinkComponent.getViewUrl(link.id)}
                    </Link>
                    <CopyButton isActive={this.isLinkActive(link)} onClick={e => this.handleLinkCopy(e, link)}>
                      {i18n.t("copy")}
                    </CopyButton>
                  </LinkContainer>
                  <LinkDetailsContainer>
                    <LinkDetails
                      isActive={this.isLinkExpired(link) === false}
                      onClick={e => this.handleLinkDetailsClick(e, link)}
                    >
                      {this.getLinkDetails(link)}
                    </LinkDetails>
                    <ToggleSwitch
                      isChecked={this.isLinkActive(link)}
                      onChange={checked => this.handleLinkEnableChange(checked, link)}
                    />
                    <DeleteLinkButton onClick={e => this.handleLinkDeleteButtonClick(e, link)}>
                      <DeleteIcon />
                    </DeleteLinkButton>
                  </LinkDetailsContainer>
                </SharedLink>
              ))}
            </SharedLinksContainer>
          )}

          {showLinkDetailsDialog === true && (
            <PortfolioLinkDetailsDialog
              portfolio={portfolio}
              sharedLinks={sharedLinks}
              linkDetails={this.state.selectedLinkDetails}
              onDismiss={this.handleDetailsDialogOnDismiss}
            />
          )}

          {this.state.showDeleteLinkDialog === true && (
            <ConfirmationDialog
              canUserDismiss={true}
              title={i18n.t("deleteShareLinkDialog.title")}
              description={i18n.t("deleteShareLinkDialog.description").replace("%s", this.state.linkToDelete.name)}
              positiveButtonTitle={i18n.t("deleteLink")}
              negativeButtonTitle={i18n.t("cancel")}
              onDismiss={this.handleDeleteLinkDialogOnDismiss}
              handlePositiveButtonClick={this.handleDeleteLinkDialogPositiveButtonClick}
              handleNegativeButtonClick={this.handleDeleteLinkDialogOnDismiss}
            />
          )}
        </ContentContainer>
      </>
    );
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ShareDialog>
          <Container>{this.getReadOnlyPanel()}</Container>
        </ShareDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  updatePortfolioShareLink: updatePortfolioShareLink,
  deletePortfolioShareLink: deletePortfolioShareLink,
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShareROLinkComponent));
