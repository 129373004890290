import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  irrTypes,
  getUuid,
  updateCustodianCashflow,
  convertCustodianHistoryApiDateFormatToUIFormat,
  custodianSelector
} from "@kubera/common";
import CostbasisReturnsComponent from "./CostbasisReturnsComponent";
import CashflowReturnsComponent from "./CashflowReturnsComponent";
import HoldingsReturnsComponent from "./HoldingsReturnsComponent";
import AccountReturnsComponent from "./AccountReturnsComponent";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AccountReturnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CustodianReturnsComponent = ({ className, hasHoldings, ...otherProps }) => {
  const dispatch = useDispatch();

  const {
    irrType,
    cashflows: defaultCashflows,
    cost,
    costExchangeRate,
    costTickerId,
    history: costHistory,
    custodianId,
    parentId,
    detailsInfo,
    holdings
  } = otherProps;

  const parentCustodian = useSelector(state => custodianSelector(state, parentId || custodianId));

  const isCostBasisShowAsFirstEntry = !((defaultCashflows && defaultCashflows.length > 0) || !cost);
  let cashflows = defaultCashflows || [];
  let costBasisRow = null;
  const historyDate = CostbasisReturnsComponent.getNewestCostHistoryDate(costHistory);
  if (isCostBasisShowAsFirstEntry && historyDate) {
    costBasisRow = {
      cashIn: cost,
      cashInExchangeRate: costExchangeRate,
      cashInTickerId: costTickerId,
      date: historyDate,
      id: getUuid(),
      note: "Cost Basis"
    };
    cashflows = [costBasisRow];
  }

  const showCostWhenIrrTypeIsHolding = useMemo(() => {
    return (
      irrType === irrTypes.HOLDING && holdings && !holdings.find(holding => holding.irrType !== irrTypes.COSTBASIS)
    );
  }, [irrType, holdings]);

  useEffect(() => {
    const getNewestCostHistoryDate = () => {
      return convertCustodianHistoryApiDateFormatToUIFormat(historyDate);
    };

    if (irrType === irrTypes.CASHFLOW && isCostBasisShowAsFirstEntry && historyDate) {
      dispatch(
        updateCustodianCashflow(true, custodianId, {
          ...costBasisRow,
          date: getNewestCostHistoryDate(),
          irrType
        })
      );
    }
  }, [irrType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (parentId && parentCustodian.irrType !== irrTypes.HOLDING && detailsInfo.hidden === 1) {
    return (
      <AccountReturnsContainer>
        <AccountReturnsComponent {...otherProps} />
      </AccountReturnsContainer>
    );
  }

  const showSwitchToHoldings = !parentId
    ? parentCustodian.irrType !== irrTypes.HOLDING && hasHoldings
    : parentCustodian.irrType !== irrTypes.HOLDING && detailsInfo.hidden === 1;
  return (
    <Container className={className}>
      {irrType === irrTypes.HOLDING && (
        <HoldingsReturnsComponent
          {...otherProps}
          cashflows={cashflows}
          showCostWhenIrrTypeIsHolding={showCostWhenIrrTypeIsHolding}
        />
      )}
      {irrType === irrTypes.COSTBASIS && (
        <CostbasisReturnsComponent showSwitchToHoldings={showSwitchToHoldings} {...otherProps} cashflows={cashflows} />
      )}
      {irrType === irrTypes.CASHFLOW && (
        <CashflowReturnsComponent showSwitchToHoldings={showSwitchToHoldings} {...otherProps} cashflows={cashflows} />
      )}
    </Container>
  );
};

export default CustodianReturnsComponent;
