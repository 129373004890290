import { useState, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";
import { setSlideDirection, undoQueue, redoQueue, getForceErrorFlag, FORCE_ERROR_TYPES } from "@kubera/common";

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

const handleUndoRedoOnLocationChange = () => {
  undoQueue.length = 0;
  redoQueue.length = 0;
};

const HandleHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navType = useNavigationType();
  const { showBoundary } = useErrorBoundary();

  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    const isForceErrorFlagSet = getForceErrorFlag() === FORCE_ERROR_TYPES.ERROR_BOUNDARY;
    if (isForceErrorFlagSet) {
      showBoundary("Forced error for testing");
    }
  }, [showBoundary]);

  useEffect(() => {
    if (navType === "PUSH") {
      setLocationKeys([location.key]);
    }

    if (navType === "POP") {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([_, ...keys]) => keys);

        // Handle forward event
        dispatch(setSlideDirection(!iOS ? "right" : null));
      } else {
        setLocationKeys(keys => [location.key, ...keys]);

        // Handle back event
        dispatch(setSlideDirection(!iOS ? "right" : null));
      }
    }

    handleUndoRedoOnLocationChange();
    // eslint-disable-next-line
  }, [location]);

  return null;
};

export default HandleHistory;
