import React from "react";
import styled from "styled-components";

const Badge = styled.div`
  width: 6px;
  height: 6px;
  border: 2px solid ${props => props.theme.badgeBorderCLR};
  border-radius: 50%;
  background-color: red;
  z-index: 999;
`;

class BadgeTip extends React.Component {
  render() {
    const { className, ...other } = this.props;

    return <Badge {...other} className={className} />;
  }
}

export default BadgeTip;
