import { queryParams, routes } from "routes";
import * as Sentry from "@sentry/browser";
import { isAppInViewMode, getAppVersion } from "@kubera/common";

export const MOBILE_PWA_POPUP_BYPASS_KEY = "MOBILE_PWA_POPUP_BYPASS_KEY";

export const category = {
  ASSET: "Asset",
  DEBT: "Debt",
  INSURANCE: "Insurance",
  ALL: "All"
};

const staticMethods = {
  showPortfolio: (history, location, portfolioId) => {
    history.push({
      ...location,
      hash: "",
      search: `${queryParams.PORTFOLIO_ID}=${portfolioId}`
    });
  },

  getCurrentCategory: () => {
    const pathName = window.location.pathname;
    if (pathName.includes(routes.ASSETS)) {
      return category.ASSET;
    } else if (pathName.includes(routes.DEBTS)) {
      return category.DEBT;
    } else if (pathName.includes(routes.INSURANCE)) {
      return category.INSURANCE;
    }
    return null;
  },

  getPathnameForCategory: categoryName => {
    if (categoryName === category.ASSET) {
      return routes.ASSETS;
    } else if (categoryName === category.DEBT) {
      return routes.DEBTS;
    } else if (categoryName === category.INSURANCE) {
      return routes.INSURANCE;
    }
    return null;
  },

  trackSession: (userId, user, sessionURL = window.location.href) => {
    if (isAppInViewMode() === false) {
      window.Beacon("identify", {
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: user.tsCreated,
        sessionURL: sessionURL,
        appVersion: getAppVersion()
      });
    }

    Sentry.configureScope(function(scope) {
      scope.setUser({
        id: userId
      });
      scope.setExtra("sessionURL", sessionURL);
      scope.setExtra("appVersion", getAppVersion());
    });
  }
};

export default staticMethods;
