import React from "react";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "i18next";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import {
  pastValueInterval,
  portfolioTotalForCategory,
  getChangeTotalsWithContributorsForCurrentPortfolio
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponentExports";
import ValueChangeTable from "components/table/MobileValueChangeTable";
import ClickableLink from "components/labels/DelayLink";
import slideAnimation from "utilities/slideAnimation";
import { connect } from "react-redux";
import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import Loader from "components/loader/Loader";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ChangeLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
`;

const ContainerDialog = styled(Dialog)`
  position: relative;
  width: 100%;
  height: 100vh
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding-top: 60px;
  animation: ${slideAnimation};
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HistoryBackContainer = styled(ClickableLink)`
  position: absolute;
  left: -1px;
  top: 4px;
  padding: 20px;
  border-radius: 50%;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 16px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const CurrentTotal = styled(CurrencyLabel)`
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.044em;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 20px;
  margin-bottom: 28px;

  .child-container {
    justify-content: flex-start;
  }
`;

const ChangeTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
`;

const ChangeTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
  padding-left: 20px;
`;

const ChangeTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${props => props.theme.mobileTxtColorLight};
  padding-bottom: 5px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: ${props => props.theme.mobileTxtColor};
    border-bottom: ${props => `2px solid ${props.theme.mobileTxtColor}`};
  }
`;

const ChangeTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TotalChange = styled(ChangeLabel)`
  margin-top: 26px;
  margin-bottom: 10px;
  margin-left: 22px;
  font-size: 22px;

  .child-container {
    justify-content: flex-start;
  }
`;

const Updating = styled.div`
  display: flex;
  visibility: ${props => (props.hidden === true ? "hidden" : "visible")};
  margin-left: 22px;
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileGridTotalUpdatingTxt};
`;

class MobilePortfolioTotalChangeDialog extends React.Component {
  constructor(props) {
    super(props);

    const selectedIndex = this.tabs.findIndex(item => item.interval === props.interval);
    this.state = { tabIndex: selectedIndex };

    this.setTab = this.setTab.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    } else {
      DialogOverlay.dismiss(this.props.history, this.props.location);
    }
  }

  setTab(tabIndex) {
    this.setState({
      tabIndex
    });
  }

  getChangeDataKey(forPastInterval) {
    switch (forPastInterval) {
      case pastValueInterval.DAY:
        return "day";
      case pastValueInterval.WEEK:
        return "week";
      case pastValueInterval.MONTH:
        return "month";
      case pastValueInterval.YEAR:
        return "year";
      case pastValueInterval.YTD:
        return "ytd";
      default:
        return null;
    }
  }

  isUpdating(forInterval) {
    if (forInterval === pastValueInterval.DAY) {
      return this.props.changeData.isDayChangeFetching;
    }
    return this.props.changeData.isAllChangeFetching;
  }

  get tabs() {
    var tabs = [];

    for (const key of [
      pastValueInterval.DAY,
      pastValueInterval.WEEK,
      pastValueInterval.MONTH,
      pastValueInterval.YEAR,
      pastValueInterval.YTD
    ]) {
      const total = this.props.changeTotalsAndContributors.changes[this.getChangeDataKey(key)].total;

      if (
        this.props.changeTotalsAndContributors.changes[this.getChangeDataKey(key)].isInsidePortfolioStartDate &&
        (!total === false || !this.props.changeTotalsAndContributors.currentTotal === false || this.isUpdating(key))
      ) {
        tabs.push({
          interval: key,
          panelComponent: this.getTabPanel(key)
        });
      }
    }
    return tabs;
  }

  getTabPanel(forInterval) {
    const currency = this.props.currency;
    const changeDataKey = this.getChangeDataKey(forInterval);
    const totalStartValue = this.props.changeTotalsAndContributors.changes[changeDataKey].total;

    if (!this.props.changeData.data === true || (this.isUpdating(forInterval) === true && !totalStartValue === true)) {
      return (
        <PanelContainer>
          <ChangeLoader />
        </PanelContainer>
      );
    }

    const valueChangeContributions = this.props.changeTotalsAndContributors.changes[changeDataKey].contributors;
    const totalEndValue = this.props.changeTotalsAndContributors.currentTotal;

    return (
      <PanelContainer>
        <TotalChange
          currency={this.props.currency}
          startValue={totalStartValue}
          endValue={totalEndValue}
          disableShortFormat={true}
          disableColor={this.props.category === category.DEBT}
          alignPosition="left"
        />
        <Updating hidden={this.isUpdating(forInterval) === false}>{i18n.t("updating")}</Updating>
        <ValueChangeTable
          rows={valueChangeContributions}
          currency={currency}
          disableColor={this.props.category === category.DEBT}
          disableRoundDown={true}
        />
      </PanelContainer>
    );
  }

  getTabTitle(forInterval) {
    switch (forInterval) {
      case pastValueInterval.DAY:
        return i18n.t("dayChange");
      case pastValueInterval.WEEK:
        return i18n.t("weekChange");
      case pastValueInterval.MONTH:
        return i18n.t("monthChange");
      case pastValueInterval.YEAR:
        return i18n.t("yearChange");
      default:
        return forInterval;
    }
  }

  render() {
    const currency = this.props.currency;
    const tabs = this.tabs;

    return (
      <Container onDismiss={this.handleOverlayDismiss}>
        <ContainerDialog className={this.props.className} direction={this.props.direction}>
          <HistoryBackContainer onClick={this.handleOverlayDismiss} direction="right">
            <HistoryBackBtn />
          </HistoryBackContainer>
          <ChangeContainer>
            <CurrentTotal
              currency={currency}
              value={this.props.totalValue}
              maxLongFormatValue={99999}
              abbreviate={false}
              roundDown={true}
              isAnimationAllowed
              useScrollAnimation
            />
            <ChangeTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={this.setTab}
            >
              <ChangeTabList>
                {tabs.map((tab, index) => (
                  <ChangeTab data-cy={"changeTab" + index} key={index}>
                    {this.getTabTitle(tab.interval)}
                  </ChangeTab>
                ))}
              </ChangeTabList>
              {tabs.map((tab, index) => (
                <ChangeTabPanel key={index}>{tab.panelComponent}</ChangeTabPanel>
              ))}
            </ChangeTabs>
          </ChangeContainer>
        </ContainerDialog>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  totalValue: portfolioTotalForCategory(state, undefined, props.category),
  changeTotalsAndContributors: getChangeTotalsWithContributorsForCurrentPortfolio(state, props.category)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobilePortfolioTotalChangeDialog));
