import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import PrimaryButton from "components/button/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import PasswordInput from "components/inputs/PasswordInput";
import ClickableLink from "components/labels/ClickableLink";
import { apiErrorCodes } from "@kubera/common";
import { connect } from "@casainc/browser";
import Loader from "components/loader/Loader";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const ProviderContainer = styled.div`
  position: relative;
  height: 35px;
`;

const ProviderName = styled.div`
  height: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ProviderLogo = styled.img`
  width: auto;
  height: 100%;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 46px;
  text-align: left;
  position: absolute;
`;

const FieldsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const FieldLabel = styled.div`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: ${props => (props.inputError === true ? "red" : "#000000")};
`;

const TextField = styled(TextInput)`
  height: 45px;
  padding: 13px;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  background: #fff;
`;

const SecureField = styled(PasswordInput)`
  height: 45px;
  background: #fff;

  + div {
    background: #fff;
  }
`;

const ErrorLabel = styled.div`
  min-height: 21px;
  margin-top: 11px;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
`;

const ConnectButton = styled(PrimaryButton)`
  height: 45px;
`;

const InstructionsLinkContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const InstructionsLink = styled(ClickableLink)`
  color: ${props => props.theme.linkColor};
  font-size: 14px;
`;

const LoaderForSeparateWindow = styled(Loader)`
  margin-bottom: 14px;
  height: calc(50% - 60px);
  justify-content: flex-end;
`;

const NoteForSeparateWindow = styled.div`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  font-feature-settings: "ss01" on;
`;

const KnowMore = styled.a`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
`;

class CryptoApiConnectionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hideProviderLogo: !this.getSearchIcon() === true, hideProviderText: false };

    this.handleLogoImageError = this.handleLogoImageError.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleConnectButtonClick = this.handleConnectButtonClick.bind(this);
  }

  componentDidMount() {
    if (this.isCasa) {
      this.handleCasaConnect();
    }
  }

  get isCasa() {
    const { providerDetails } = this.props;
    return providerDetails && providerDetails.linkProviderId === "casa";
  }

  handleCasaConnect = async () => {
    const apiToken = await connect({
      appId: process.env.REACT_APP_CASA_APP_ID
    });

    if (apiToken) {
      this.setState(
        {
          key: apiToken
        },
        () => {
          this.handleConnectButtonClick();
        }
      );
    } else {
      window.close();
    }
  };

  getSearchIcon() {
    return this.props.providerDetails.searchIcon || this.props.providerDetails.icon;
  }

  handleLogoImageError(e) {
    this.setState({ hideProviderLogo: true, hideProviderText: false });
  }

  handleImageLoaded(e) {
    this.setState({ hideProviderLogo: false, hideProviderText: true });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [this.getFieldErrorName(e.target.name)]: false
    });
  }

  getFieldErrorName(fieldName) {
    return `${fieldName}Error`;
  }

  handleConnectButtonClick(e) {
    const connectParams = this.getConnectParams();
    var metaData = {};

    for (const param of connectParams) {
      const value = this.state[param.name];
      if (!value === true || value.trim().length === 0) {
        this.setState({ [this.getFieldErrorName(param.name)]: true });
      } else {
        metaData[param.name] = this.state[param.name];
      }
    }

    if (Object.keys(metaData).length === connectParams.length) {
      this.props.onSubmit({ metaData: metaData });
    }
  }

  getConnectParams() {
    return JSON.parse(this.props.providerDetails.connectParam);
  }

  getErrorMessage(connectParams) {
    if (!this.props.error === true) {
      return "";
    }
    if (this.props.error.errorCode === apiErrorCodes.INVALID_INPUT) {
      return i18n.t(connectParams.length <= 1 ? "zerionmodal.invalidError" : "cryptoApiInvalidInputError");
    }
    return i18n.t("cryptoApiConnectionError");
  }

  render() {
    const providerDetails = this.props.providerDetails;
    const connectParams = this.getConnectParams();

    return (
      <Container>
        <ProviderContainer>
          {this.state.hideProviderLogo === false && (
            <ProviderLogo
              src={process.env.PUBLIC_URL + "/images/connection_icons/" + this.getSearchIcon()}
              onError={this.handleLogoImageError}
              onLoad={this.handleImageLoaded}
            />
          )}
          {this.state.hideProviderText === false && <ProviderName>{providerDetails.name}</ProviderName>}
        </ProviderContainer>

        {this.isCasa ? (
          <>
            <LoaderForSeparateWindow />
            <NoteForSeparateWindow>
              {i18n.t("noteForSeparateWindow")}
              <br />
              <KnowMore target="_blank" href="https://help.kubera.com/article/101-allow-pop-ups">
                {i18n.t("knowMore")}
              </KnowMore>
            </NoteForSeparateWindow>
          </>
        ) : (
          <>
            <FieldsContainer>
              {connectParams.map((field, index) => (
                <Field key={index}>
                  <FieldLabel inputError={this.state[this.getFieldErrorName(field.name)]}>{field.label}</FieldLabel>
                  {!field.isSecure === false && (
                    <SecureField
                      autoFocus={index === 0}
                      name={field.name}
                      value={this.state[field.name] || ""}
                      onChange={this.handleInputChange}
                      hidePasswordStrength={true}
                      placeholder={field.label}
                      inputError={this.state[this.getFieldErrorName(field.name)]}
                      disableAutoComplete={true}
                    />
                  )}
                  {!field.isSecure === true && (
                    <TextField
                      autoFocus={index === 0}
                      name={field.name}
                      value={this.state[field.name] || ""}
                      onChange={this.handleInputChange}
                      placeholder={field.label}
                      inputError={this.state[this.getFieldErrorName(field.name)]}
                    />
                  )}
                </Field>
              ))}
            </FieldsContainer>
            <ErrorLabel>{this.getErrorMessage(connectParams)}</ErrorLabel>

            <ConnectButton
              title={i18n.t("connect")}
              isLoading={this.props.isPending}
              onClick={this.handleConnectButtonClick}
              enableEnterKey={true}
            />
            {!providerDetails.helpUrl === false && (
              <InstructionsLinkContainer>
                <InstructionsLink target="_blank" href={providerDetails.helpUrl}>
                  {i18n.t("cryptoConnectionInstructions")}
                </InstructionsLink>
              </InstructionsLinkContainer>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default CryptoApiConnectionComponent;
