import React from "react";
import styled from "styled-components";
import { CardElement } from "@stripe/react-stripe-js";

import { ReactComponent as StripePowered } from "assets/images/stripe.svg";

const Wrapper = styled.div`
  display: inline-block;
`;

const CardContainer = styled(CardElement)`
  width: 100%;
  height: 50px;
  padding: 16px 5px;
  border: ${props => props.theme.accountCardElementCardContainerBR};
  background: ${props => props.theme.accountCardElementCardContainerBG};
  box-sizing: border-box;
  margin-bottom: 5px;
`;

const StripeBlk = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StripeLink = styled.a`
  text-decoration: none;
`;

const AccountCardElement = props => (
  <Wrapper className={props.className} data-private>
    <CardContainer>
      <CardElement options={props.options} />
    </CardContainer>
    <StripeBlk>
      <StripeLink href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
        <StripePowered />
      </StripeLink>
    </StripeBlk>
  </Wrapper>
);

export { CardElement };

export default AccountCardElement;
