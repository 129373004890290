import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import i18n from "i18next";
import QRCode from "qrcode.react";
import { useDispatch } from "react-redux";

import {
  getQRStr,
  verifyTotpToken,
  setPreferredMFA,
  userSetMFA,
  MFA_TYPES,
  isAppInWhiteLabelMode,
  store,
  siteConfigSelector
} from "@kubera/common";
import { Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import TextInput from "components/inputs/TextInput";

const ComponentDialog = styled(Dialog)`
  width: 416px;
  margin-top: 80px;
  background: #ffffff;
  padding: 45px 48px 57px 50px;
  box-sizing: border-box;
`;

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  height: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 17px;
  white-space: pre-wrap;
`;

const QrContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  row-gap: 14px;
`;

const QrContainer = styled.div`
  display: inline-block;
  width: 160px;
  min-width: 160px;
  min-height: 160px;
`;

const QrTxt = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 150%;
`;

const QrInpTitle = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 14px;
`;

const VerifyCodeInp = styled(TextInput)`
  width: 100%;
  height: 45px;
  border: ${props => props.inputBorder};
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 34px;
  outline: 0;
`;

const BtnWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(PrimaryButton)`
  min-width: 134px;
  margin-right: 19px;
`;

const GoBackBtn = styled(SecondaryButton)`
  min-width: 134px;
`;

const Authenticator = ({ onTOTPVerifySuccess = () => null, goBack = () => null, userAttrs }) => {
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [verifyCode, setVerifyCode] = useState("");
  const isInit = useRef(true);

  const handleSave = async e => {
    e.preventDefault();

    setIsLoading(true);
    const verifyStatus = await verifyTotpToken(verifyCode);

    if (verifyStatus && verifyStatus.Status === "SUCCESS") {
      await setPreferredMFA(MFA_TYPES.TOTP);
      dispatch(userSetMFA(MFA_TYPES.TOTP.toLowerCase()));
      onTOTPVerifySuccess();
    } else {
      setErrorMsg("Error");
      setIsLoading(false);
    }
  };

  const onGoBack = e => {
    e.preventDefault();

    goBack();
  };

  const setQRDisplay = async () => {
    const code = await getQRStr().catch(err => null);
    setQRCode(code);
    isInit.current = false;
  };

  const onVerifyCodeChange = e => {
    setErrorMsg("");
    setVerifyCode(e.target.value.replace(/\s/g, ""));
  };

  useEffect(() => {
    if (isInit.current) {
      setQRDisplay();
    }
  });

  const issuerEnvPart = process.env.REACT_APP_ENV === "prod" ? "" : ":" + process.env.REACT_APP_ENV;
  var issuer = isAppInWhiteLabelMode() === true ? siteConfigSelector(store.getState()).name : i18n.t("appName");
  const qrStr =
    "otpauth://totp/" +
    encodeURIComponent(`${issuer}${issuerEnvPart}:${userAttrs && userAttrs.email}`) +
    "?secret=" +
    encodeURIComponent(qrCode) +
    "&issuer=" +
    encodeURIComponent(issuer);

  let inputBorder = "1px solid rgba(0, 0, 0, 0.4)";

  if (errorMsg) {
    inputBorder = "solid 1px rgba(255, 0, 0, 0.4)";
  }

  return (
    <ComponentDialog>
      <Form onSubmit={handleSave}>
        <ContentWrapper>
          <Title>{i18n.t("twoFA.authenticatorAppTitle")}</Title>
          <QrContentWrapper>
            <QrTxt>{i18n.t("twoFA.qrTxtDesc")}</QrTxt>
            <QrContainer>
              {qrCode && <QRCode value={qrStr} size={160} level={"L"} includeMargin={false} renderAs={"svg"} />}
            </QrContainer>
          </QrContentWrapper>
          <QrInpTitle>{i18n.t("twoFA.qrInpTitle")}</QrInpTitle>
          <VerifyCodeInp
            value={verifyCode}
            onChange={onVerifyCodeChange}
            inputBorder={inputBorder}
            autoFocus
            autoComplete="off"
            placeholder={i18n.t("accountSettings.confirmEmailVerificationCode")}
            data-private
          />
        </ContentWrapper>
        <BtnWrapper>
          <SaveButton id="save" data-cy="save" title={i18n.t("save")} onClick={() => null} isLoading={isLoading} />
          <GoBackBtn
            id="goBack"
            data-cy="goBack"
            title={i18n.t("goBackLink")}
            onClick={onGoBack}
            disabled={isLoading}
          />
        </BtnWrapper>
      </Form>
    </ComponentDialog>
  );
};

export default Authenticator;
