import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { isMobile } from "@kubera/common";

import BlockerPaywall from "components/blocker_paywall";

import sideMenuImg from "assets/images/sidemenu.png";
import topRightHeaderImg from "assets/images/topright.png";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  padding: 0 10px;
  box-sizing: border-box;

  ${!isMobileDevice &&
    css`
      &.cancelled-with-paywall + .dashboard-container {
        display: none;
      }
    `}
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const Sidebar = styled.div`
  width: 252px;
  background: #ededed;
  min-height: 100vh;
  margin-left: -10px;
`;

const SidebarImg = styled.img`
  width: 100%;
`;

const TopRight = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  background: rgb(252, 252, 252);
`;

const TopRightImg = styled.img`
  height: 80px;
`;

const AccountCancelledSubscription = props => {
  useEffect(() => {
    props.onMount();
  }, [props]);

  return (
    <Container className="cancelled-with-paywall">
      {isMobileDevice ? (
        <BlockerPaywall />
      ) : (
        <>
          <Background>
            <Sidebar>
              <SidebarImg src={sideMenuImg} alt="Sidebar" />
            </Sidebar>
            <TopRight>
              <TopRightImg src={topRightHeaderImg} alt="Top Header" />
            </TopRight>
          </Background>
          <BlockerPaywall />
        </>
      )}
    </Container>
  );
};

export default AccountCancelledSubscription;
