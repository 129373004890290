import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { siteConfigSelector, isAppInWhiteLabelMode } from "@kubera/common";
import ClickableLink from "components/labels/ClickableLink";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.mobileBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const WelcomeTxt = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 20px;
  margin-bottom: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  margin-left: 20px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const MessageBlock = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColor};
  padding: 0 20px;
  margin-bottom: 39px;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const ActionBtns = styled(ClickableLink)`
  font-size: 13px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColorGrey};
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const RefreshBtn = styled(ActionBtns)`
  margin-left: 20px;
  margin-bottom: 35px;
`;

const BackToHome = styled.a`
  font-size: 13px;
  line-height: 130%;
  color: ${props => props.theme.mobileTxtColorGrey};
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-left: 20px;
`;

const HeadsUpComponent = ({ user }) => {
  const siteConfig = useSelector(siteConfigSelector);

  const onRefresh = () => {
    window.location.reload();
  };

  const siteName = siteConfig ? siteConfig.name : "";

  return (
    <Container>
      <ContentContainer>
        <WelcomeTxt>Hi {user.name},</WelcomeTxt>
        <Title>{i18n.t("headsUpMsg.msg").replace(/%s%/g, siteName)}</Title>
        <MessageBlock
          dangerouslySetInnerHTML={{
            __html: i18n.t("emptyPortfolioTxt").replace(/%s%/g, window.location.host)
          }}
        />
        <RefreshBtn onClick={onRefresh}>Refresh</RefreshBtn>
        {isWhiteLabelledApp === false && (
          <BackToHome href="https://www.kubera.com/?r">Back to Kubera Home Page</BackToHome>
        )}
      </ContentContainer>
    </Container>
  );
};

export default React.memo(HeadsUpComponent);
