import React from "react";
import styled from "styled-components";

import { isMobile } from "@kubera/common";

import dinosaurImg from "assets/images/dinosaur.png";
import kuberaLogo from "assets/images/kubera_logo.svg";

const isMobileDevice = isMobile();

const Container = styled.div`
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 15px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -120px;
`;

const ContentImg = styled.img`
  width: 120px;
  margin-bottom: 28px;
`;

const KuberaLogo = styled.img`
  width: 90px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  margin-bottom: 10px;
`;

const Desc = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 9px;
`;

const LinksContainer = styled.div`
  display: flex;
  column-gap: 25px;
`;

const UpdateLinks = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: center;
  color: #000;
`;

function BrowserNotSupported() {
  return (
    <Container>
      <ContentContainer>
        <ContentImg src={dinosaurImg} />
        <KuberaLogo src={kuberaLogo} />
        <Title>Update your Browser</Title>
        <Desc>
          {!isMobileDevice && (
            <>
              Your browser is so old, it remembers when the internet was called 'the information superhighway'.
              <br />
            </>
          )}
          Kubera is not compatible with your current browser version. Please update your browser to the latest version.
        </Desc>
        <LinksContainer>
          <UpdateLinks href="https://support.google.com/chrome/answer/95414" target="_blank" rel="noopener noreferrer">
            Chrome
          </UpdateLinks>
          <UpdateLinks href="https://support.apple.com/en-us/102665" target="_blank" rel="noopener noreferrer">
            Safari
          </UpdateLinks>
          <UpdateLinks
            href="https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edge
          </UpdateLinks>
          <UpdateLinks
            href="https://support.mozilla.org/en-US/kb/update-firefox-latest-release"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox
          </UpdateLinks>
        </LinksContainer>
      </ContentContainer>
    </Container>
  );
}

export default BrowserNotSupported;
