import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import { isAmcUser as isAmcUserSelector, siteConfigSelector } from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Dialog = styled(ConfirmationDialog)`
  width: 655px;
  padding-left: 15px;

  ol {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    row-gap: 10px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 21px;
    margin-bottom: 0;
  }

  button {
    margin-bottom: 5px;

    &[data-cy="positiveButton"] {
      width: 170px;
    }
  }
`;

const WhiteLabelLinkPortfolios = ({ onPositiveButtonClick }) => {
  const isAmcUser = useSelector(isAmcUserSelector);
  const siteConfig = useSelector(siteConfigSelector);

  if (isAmcUser) {
    return (
      <Dialog
        title={i18n.t("blackPaywalls.linkingNotAllowedDialog.title")}
        description={`${i18n.t("blackPaywalls.linkingNotAllowedDialog.adminDesc")}`}
        positiveButtonTitle={i18n.t("gotIt")}
        handlePositiveButtonClick={onPositiveButtonClick}
      />
    );
  }

  return (
    <Dialog
      title={i18n.t("blackPaywalls.linkingNotAllowedDialog.title")}
      description={`${i18n
        .t("blackPaywalls.linkingNotAllowedDialog.clientDesc")
        .replace(/%orgName%/, siteConfig.name)}`}
      positiveButtonTitle={i18n.t("gotIt")}
      handlePositiveButtonClick={onPositiveButtonClick}
    />
  );
};

export default WhiteLabelLinkPortfolios;
