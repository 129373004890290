import React from "react";
import styled from "styled-components";
import DoughnutChartComponent from "components/charts/DoughnutChartComponent";
import { useSelector } from "react-redux";
import {
  dashboardChartsDataSelector,
  chartStyle,
  parseParams,
  isAppInViewMode,
  chartContent,
  portfolioSelector,
  userMaskAllValuesSelector
} from "@kubera/common";
import DiyDashboardLineChartComponent from "./DiyDashboardLineChartComponent";
import ConnectivityWidget from "./ConnectivityWidget";
const Container = styled.div`
  display: inline-block;
  margin-right: 5px;
  min-width: 497px;
`;

const DoughnutChart = styled(DoughnutChartComponent)`
  height: 370px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const ColumnsComponent = props => {
  const {
    handleRemoveChartSelection,
    chartIds,
    handleRearrangeChartSelection,
    handleChartClick,
    setShowRearrangeView,
    column,
    handleConnectivityWidgetClick,
    hideRearrangeOption,
    portfolioId
  } = props;
  const chartsData = useSelector(state => dashboardChartsDataSelector(state, chartIds));
  const portfolio = useSelector(state => portfolioSelector(state, portfolioId));
  const isInViewMode = isAppInViewMode() === true || (portfolio && portfolio.write === 0);
  const useMaskedValues = useSelector(userMaskAllValuesSelector);

  // console.log("column", column, chartIds);
  // console.log("chartsData", chartsData);
  return (
    <>
      <Container>
        {chartsData.map((chart, index) => {
          if (chart && chart.isChecked) {
            if (chart.chartStyle === chartStyle.DOUGHNUT) {
              return (
                <DoughnutChart
                  key={index}
                  id={`${column}-${index}`}
                  chartData={chart}
                  useMaskedValues={useMaskedValues}
                  chartWidth={497}
                  chartHeight={320}
                  chartPadding={"30px 0px 30px 0px"}
                  shouldShowContextMenu={!isInViewMode}
                  handleRemoveChartSelection={handleRemoveChartSelection}
                  handleRearrangeChartSelection={handleRearrangeChartSelection}
                  handleChartClick={handleChartClick}
                  index={index}
                  setShowRearrangeView={setShowRearrangeView}
                  hideRearrangeOption={hideRearrangeOption}
                />
              );
            } else if (chart.chartStyle === chartStyle.LINE) {
              const chartParams = parseParams(chart.id);
              const reportId = chartParams.report_id;
              return (
                <DiyDashboardLineChartComponent
                  key={index}
                  id={`${column}-${index}`}
                  reportLineChartData={chart}
                  handleRemoveChartSelection={handleRemoveChartSelection}
                  handleRearrangeChartSelection={handleRearrangeChartSelection}
                  reportId={reportId}
                  handleChartClick={handleChartClick}
                  shouldShowContextMenu={!isInViewMode}
                  hideRearrangeOption={hideRearrangeOption}
                />
              );
            } else if (chart.chartStyle === chartStyle.OTHER) {
              if (chart.chartContent === chartContent.CONNECTIVITY_WIDGET) {
                return (
                  <ConnectivityWidget
                    key={index}
                    id={`${column}-${index}`}
                    chartData={chart}
                    handleRemoveChartSelection={handleRemoveChartSelection}
                    handleRearrangeChartSelection={handleRearrangeChartSelection}
                    shouldShowContextMenu={!isInViewMode}
                    handleConnectivityWidgetClick={handleConnectivityWidgetClick}
                    hideRearrangeOption={hideRearrangeOption}
                  />
                );
              }
            }
            return null;
          } else {
            return null;
          }
        })}
      </Container>
    </>
  );
};
export default ColumnsComponent;
