const setReloadCount = prop => {
  const reloadCurrentState = JSON.parse(sessionStorage.getItem("IS_RELOAD_DONE")) || {};
  const count = (prop in reloadCurrentState ? reloadCurrentState[prop] : 0) + 1;
  sessionStorage.setItem(
    "IS_RELOAD_DONE",
    JSON.stringify({
      ...reloadCurrentState,
      [prop]: count
    })
  );

  return count;
};

export default setReloadCount;
