import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";

import DragDropInput from "components/inputs/DragDropInput";
import FileUploadInput from "components/inputs/FileUploadInput";

const Container = styled(DragDropInput)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const UploadAction = styled(FileUploadInput)`
  display: inline;
  padding: 0;
  cursor: pointer;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  margin-right: 5px;
  color: ${props => props.theme.linkColor};
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  margin-top: 100px;
  width: 100%;
  height: ${props => (props.isDragging === true ? "430px" : "0px")};
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  height: 15px;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.emptySafeDepositComponentHintCLR};
  white-space: pre-wrap;
  margin-top: 185px;
`;

const EmptyDocuments = ({ className = "", onUploadAction = () => null, isReadOnly = false }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  return (
    <Container
      className={className}
      onDragStart={handleDragStart}
      onDragStop={handleDragStop}
      onFileDrop={onUploadAction}
    >
      <UploadAction
        data-cy="moreFileUpload"
        label={i18n.t("upload")}
        multiple={true}
        onChange={onUploadAction}
        disabled={isReadOnly}
      />
      or Drag & Drop files here
      <DropAreaIndicator isDragging={isDragging}>
        <DropAreaHint>{i18n.t("documentsComponent.dropFileHint")}</DropAreaHint>
      </DropAreaIndicator>
    </Container>
  );
};

export default EmptyDocuments;
