import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate as Redirect } from "react-router-dom";
import { routes } from "./Routes";

import { getPasscodeForViewMode, siteConfigSelector } from "@kubera/common";

import InitialLoader from "components/loader/InitialLoader";

export function ViewRoute() {
  const siteConfig = useSelector(siteConfigSelector);
  const location = useLocation();

  if (!siteConfig === true) {
    return <InitialLoader />;
  }

  const savedPassCode = getPasscodeForViewMode();
  const isPasscodeEnabled = siteConfig.isPasscodeEnabled === 1;

  if (isPasscodeEnabled === true && !savedPassCode === true) {
    return <Redirect to={{ ...location, pathname: routes.PASSCODE }} />;
  } else {
    return <Redirect to={{ ...location, pathname: routes.ASSETS }} />;
  }
}
