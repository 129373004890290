import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { getCustodianHistoryFormattedDateString, sanitizeIrr } from "@kubera/common";
import Checkbox from "components/inputs/Checkbox";
import PercentageLabel from "components/labels/PercentageLabel";
import DropDown from "components/inputs/DropDown";

const MaxSelectedIndexes = 3;

export const timePeriods = {
  START_DATE: "start_date",
  YEAR: "year",
  YTD: "ytd"
};

const SettingsDialog = styled(Dialog)`
  width: 500px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const IrrLabel = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const IrrValue = styled(PercentageLabel)`
  width: fit-content;
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on;
`;

const VsContainer = styled.div`
  position: relative
  margin-top: 25px;
`;

const Separator = styled.div`
  width: 100%;
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
`;

const VsLabel = styled.div`
  position: absolute;
  left: 171px;
  bottom: -11px;
  background: ${props => props.theme.vsLabelBG};
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const TimePeriodTitle = styled.div`
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const TimePeriodDropDown = styled(DropDown)`
  margin-top: 4px;
`;

const IndexCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const IndexCheckboxItemContainer = styled.div`
  display: flex;
  margin-top: 18px;
  font-size: 14px;
`;

const IndexIrrValue = styled(PercentageLabel)`
  margin-left: 5px;
`;

const IndexCheckbox = styled(Checkbox)``;

class IrrSettingsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexes: props.selectedIndexes,
      selectedTimePeriod: props.selectedTimePeriod
    };

    this.handleIndexCheckboxChange = this.handleIndexCheckboxChange.bind(this);
    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
    this.handleTimePeriodDropDownSelection = this.handleTimePeriodDropDownSelection.bind(this);
  }

  handleIndexCheckboxChange(checked, indexId) {
    var newSelectedIndexes = this.state.selectedIndexes;

    if (checked === true) {
      newSelectedIndexes.push(indexId);
    } else {
      newSelectedIndexes = newSelectedIndexes.filter(item => item !== indexId);
    }

    this.setState({ selectedIndexes: newSelectedIndexes });
  }

  handleDialogDismiss() {
    this.props.onDismiss(this.state.selectedIndexes, this.state.selectedTimePeriod);
  }

  handleTimePeriodDropDownSelection(item) {
    this.setState({ selectedTimePeriod: item.id });
  }

  getTimePeriodDropDownData() {
    return [
      {
        id: timePeriods.START_DATE,
        label: `Since ${getCustodianHistoryFormattedDateString(new Date(this.props.irrDetails.all.marketDateStart))}`,
        selected: this.state.selectedTimePeriod === timePeriods.START_DATE
      },
      {
        id: timePeriods.YEAR,
        label: i18n.t("yearChange"),
        selected: this.state.selectedTimePeriod === timePeriods.YEAR
      },
      {
        id: timePeriods.YTD,
        label: i18n.t("ytdChange"),
        selected: this.state.selectedTimePeriod === timePeriods.YTD
      }
    ];
  }

  getIndexCheckboxItems() {
    var items = [];

    for (const index of this.props.supportedIndexes) {
      const isSelected = this.state.selectedIndexes.findIndex(temp => temp === index.id) !== -1;

      items.push({
        id: index.id,
        title: index.longName,
        irr: sanitizeIrr(this.getMarketIrr(index.id)),
        checked: isSelected,
        disabled: isSelected === false && this.state.selectedIndexes.length === MaxSelectedIndexes
      });
    }
    return items;
  }

  getMarketIrr(indexId) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return this.props.irrDetails.all.market[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return this.props.supportedIndexesIrr.year[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return this.props.supportedIndexesIrr.ytd[indexId];
    }
    return null;
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleDialogDismiss}>
        <SettingsDialog>
          <Container>
            <Title>{i18n.t("irrSettings")}</Title>
            <IrrLabel>{`${i18n.t("irr")} • ${this.props.custodianName}`}</IrrLabel>
            <IrrValue value={this.props.irr} darkBackground={false} updated={true} />
            <VsContainer>
              <Separator />
              <VsLabel>{i18n.t("vs")}</VsLabel>
            </VsContainer>
            <TimePeriodTitle>{i18n.t("timePeriod")}</TimePeriodTitle>
            <TimePeriodDropDown
              width={380}
              items={this.getTimePeriodDropDownData()}
              onSelection={this.handleTimePeriodDropDownSelection}
            />
            <IndexCheckboxContainer>
              {this.getIndexCheckboxItems().map((data, index) => (
                <IndexCheckboxItemContainer key={index}>
                  <IndexCheckbox
                    label={data.title}
                    checked={data.checked}
                    disabled={data.disabled}
                    onChange={(checked, e) => {
                      this.handleIndexCheckboxChange(checked, data.id);
                    }}
                  />
                  <IndexIrrValue value={data.irr} darkBackground={false} updated={true} />
                </IndexCheckboxItemContainer>
              ))}
            </IndexCheckboxContainer>
          </Container>
        </SettingsDialog>
      </DialogOverlay>
    );
  }
}

export default IrrSettingsDialog;
