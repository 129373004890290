import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  planningRules,
  planningRuleCategories,
  planningRuleGroup,
  getUuid,
  getRuleVariableDisplayValue,
  planningVariables
} from "@kubera/common";
import { ReactComponent as ExpandIcon } from "assets/images/expandable_indicator.svg";

const AddRuleDialog = styled(Dialog)`
  position: relative;
  width: 870px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 45px 50px 45px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ScenarioName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Title = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: 'ss01' on;
  color: #000000;
  text-transform; capitalize;
`;

const AddRuleTabs = styled(Tabs)`
  margin-top: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const AddRuleTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const AddRuleTabHeader = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const AddRuleTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const RuleSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RuleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const RuleSectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: ${props => (props.isCollapsable === true ? "pointer" : "auto")};
`;

const ExpandArrow = styled(ExpandIcon)`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  transform: ${props => (props.iscollapsed === 1 ? "rotate(-90deg)" : null)};
`;

const RuleSectionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const RuleList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const RuleItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: ${props => (props.isLast ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  cursor: pointer;
`;

const RuleIndex = styled.div`
  min-width: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.3);
`;

const RuleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
`;

const RuleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const RuleDescription = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const AddButton = styled.div`
  margin-left: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #0074fc;
`;

class AddRuleDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
      collapsedGroups: [
        planningRuleGroup.ASSET_BUY_CONTRIBUTE,
        planningRuleGroup.ASSET_SELL_LIQUIDATE,
        planningRuleGroup.ASSET_PRIVATE_FUNDS,
        planningRuleGroup.ASSET_VESTING
      ]
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleSectionTitleClick = this.handleSectionTitleClick.bind(this);
  }

  handleSectionTitleClick(e, sectionGroup) {
    if (!sectionGroup === true) {
      return;
    }

    const newCollapsedGroups = this.state.collapsedGroups;
    if (newCollapsedGroups.includes(sectionGroup)) {
      newCollapsedGroups.splice(newCollapsedGroups.indexOf(sectionGroup), 1);
    } else {
      newCollapsedGroups.push(sectionGroup);
    }
    this.setState({ collapsedGroups: newCollapsedGroups });
  }

  handleAddButtonClick(rule) {
    const scenarioRule = {
      type: rule.type,
      id: getUuid(),
      data: rule.data,
      isDisabled: false,
      scenarioId: this.props.scenario.id
    };
    this.props.onAddScenarioRule(scenarioRule);
    this.props.onDismiss();
  }

  handleTabChange(index) {
    this.setState({ selectedTabIndex: index });
  }

  getPanelForRuleCategory(category) {
    switch (category) {
      case planningRuleCategories.ASSETS: {
        let ruleSections = [];

        ruleSections.push({
          title: i18n.t("ruleGroup.change_asset"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.CHANGE_ASSET
          ),
          group: planningRuleGroup.CHANGE_ASSET,
          isCollapsable: false
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.asset_buy_contribute"),
          rules: planningRules.filter(
            item =>
              item.category === category && item.group === planningRuleGroup.ASSET_BUY_CONTRIBUTE && !item.isHidden
          ),
          group: planningRuleGroup.ASSET_BUY_CONTRIBUTE,
          isCollapsable: true
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.asset_sell_liquidate"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.ASSET_SELL_LIQUIDATE
          ),
          group: planningRuleGroup.ASSET_SELL_LIQUIDATE,
          isCollapsable: true
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.asset_private_funds"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.ASSET_PRIVATE_FUNDS
          ),
          group: planningRuleGroup.ASSET_PRIVATE_FUNDS,
          isCollapsable: true
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.asset_vesting"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.ASSET_VESTING
          ),
          group: planningRuleGroup.ASSET_VESTING,
          isCollapsable: true
        });
        return this.getComponentForRuleSections(ruleSections);
      }
      case planningRuleCategories.DEBTS: {
        let ruleSections = [];

        ruleSections.push({
          title: i18n.t("ruleGroup.change_debt"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.CHANGE_DEBT
          )
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.decrease_debt_cash"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.DECREASE_DEBTS_CASH
          )
        });
        ruleSections.push({
          title: i18n.t("ruleGroup.increase_debt_cash"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.INCREASE_DEBTS_CASH
          )
        });
        return this.getComponentForRuleSections(ruleSections);
      }
      case planningRuleCategories.INCOME: {
        let ruleSections = [];

        ruleSections.push({
          title: i18n.t("ruleGroup.increase_cash"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.INCREASE_CASH
          )
        });
        return this.getComponentForRuleSections(ruleSections);
      }
      case planningRuleCategories.EXPENSE: {
        let ruleSections = [];

        ruleSections.push({
          title: i18n.t("ruleGroup.decrease_cash"),
          rules: planningRules.filter(
            item => item.category === category && item.group === planningRuleGroup.DECREASE_CASH
          )
        });
        return this.getComponentForRuleSections(ruleSections);
      }
      default:
        return <></>;
    }
  }

  getFormattedRuleText(ruleObject) {
    var label = ruleObject.label(ruleObject.data);
    for (const key in ruleObject.data) {
      const linkTemplate = "<u><b>#variable_string#</b></u>";
      var formattedVariable = "";
      if (key === planningVariables.REPEAT || key === planningVariables.DATE_AGE_REVISED) {
        formattedVariable = getRuleVariableDisplayValue(key, ruleObject, linkTemplate);
      } else {
        formattedVariable = linkTemplate.replace("#variable_string#", getRuleVariableDisplayValue(key, ruleObject));
      }
      label = label.replace(`#${key}#`, formattedVariable);
    }
    return label;
  }

  getComponentForRuleSections(sections) {
    var ruleIndex = 1;

    return (
      <RuleSectionsContainer>
        {sections.map((section, index) => {
          if (this.state.collapsedGroups.includes(section.group) === true) {
            ruleIndex += section.rules.length;
          }

          return (
            <RuleSection key={index}>
              <RuleSectionTitleContainer
                isCollapsable={section.isCollapsable === true}
                onClick={e => this.handleSectionTitleClick(e, section.group)}
              >
                {section.isCollapsable === true && (
                  <ExpandArrow iscollapsed={this.state.collapsedGroups.includes(section.group) ? 1 : 0} />
                )}
                <RuleSectionTitle>{section.title}</RuleSectionTitle>
              </RuleSectionTitleContainer>
              {this.state.collapsedGroups.includes(section.group) === false && (
                <RuleList>
                  {section.rules
                    .filter(rule => !rule.isHidden === true)
                    .map((rule, index) => {
                      return (
                        <RuleItem
                          key={index}
                          isLast={index === section.rules.length - 1}
                          onClick={e => this.handleAddButtonClick({ ...rule })}
                        >
                          <RuleIndex>{ruleIndex++}</RuleIndex>
                          <RuleTextContainer>
                            <RuleText dangerouslySetInnerHTML={{ __html: this.getFormattedRuleText(rule) }} />
                            {!rule.description === false && <RuleDescription>{rule.description}</RuleDescription>}
                          </RuleTextContainer>
                          <AddButton>{i18n.t("add")}</AddButton>
                        </RuleItem>
                      );
                    })}
                </RuleList>
              )}
            </RuleSection>
          );
        })}
      </RuleSectionsContainer>
    );
  }

  getTabs() {
    var ruleTabs = [];
    for (const category in planningRuleCategories) {
      ruleTabs.push({
        title: planningRuleCategories[category],
        key: planningRuleCategories[category],
        panelComponent: this.getPanelForRuleCategory(planningRuleCategories[category])
      });
    }
    return ruleTabs;
  }

  render() {
    const tabs = this.getTabs();

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <AddRuleDialog>
          <Container>
            <ScenarioName>{this.props.scenario.name}</ScenarioName>
            <Title>{i18n.t("addRuleDialogTitle")}</Title>

            <AddRuleTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.selectedTabIndex}
              onSelect={this.handleTabChange}
            >
              <AddRuleTabList>
                {tabs.map((tab, index) => (
                  <AddRuleTabHeader data-cy={"detailsTab" + index} key={index}>
                    {tab.title}
                  </AddRuleTabHeader>
                ))}
              </AddRuleTabList>
              {tabs.map((tab, index) => (
                <AddRuleTabPanel key={`addRuleTabPanel${index}`}>{tab.panelComponent}</AddRuleTabPanel>
              ))}
            </AddRuleTabs>
          </Container>
        </AddRuleDialog>
      </DialogOverlay>
    );
  }
}

export default AddRuleDialogComponent;
