import React from "react";
import styled from "styled-components";
import { isMobile } from "@kubera/common";

import Spinner from "components/loader/Spinner";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const isMobileDevice = isMobile();

const Button = styled.button`
  position: relative;
  width: fit-content;
  height: 44px;
  min-width: ${props => (isMobileDevice ? "126px" : "155px")};
  background-color: ${props =>
    props.isDestructive === true
      ? props.isDisabled
        ? props.theme.primaryButtonDestructiveDisabledBackgroundColor
        : props.theme.primaryButtonDestructiveBackgroundColor
      : props.isLoading === true || props.isDisabled === true
      ? props.theme.primaryButtonLoadingBackgroundColor
      : props.theme.primaryButtonBackgroundColor};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props =>
    props.isDestructive === true
      ? props.isDisabled
        ? props.theme.primaryButtonDestructiveDisabledColor
        : props.theme.primaryButtonDestructiveColor
      : props.isDisabled === true
      ? props.theme.primaryButtonDisabledColor
      : props.theme.primaryButtonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  white-space: pre;
`;

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

class PrimaryButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (!this.props.enableEnterKey === false) {
      addKeyboardEventListener(this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClick(e);
      e.preventDefault();
    }
  }

  handleClick(e) {
    if (this.props.isLoading === true || this.props.isDisabled === true) {
      return;
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { className, title, isLoading, isDisabled, isDestructive, onClick, ...other } = this.props;

    return (
      <Button
        className={className}
        {...other}
        tabIndex={"0"}
        onClick={this.handleClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isDestructive={isDestructive}
      >
        {isLoading === true ? <ButtonSpinner /> : title}
      </Button>
    );
  }
}

export default PrimaryButton;
