import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import RecapDefaultMessageComponent from "./RecapDefaultMessageComponent";
import {
  store,
  currentPortfolioSelector,
  isRecapIntroScreenShownForPortfolio,
  isAppInViewMode,
  recapChartTypes,
  RECAP_CATEGORY_TYPE_NETWORTH,
  chartTimeRange,
  recapDataSelector,
  getRecapChartOptionForPortfolio,
  userMaskAllValuesSelector,
  currentPortfolioNetWorthDataSelector,
  recapChartOptions,
  portfolioNetWorth,
  portfoliosSelector
} from "@kubera/common";
import { useSelector } from "react-redux";
import ColumnarChartComponent from "./ColumnarChartComponent";
import Loader from "components/loader/Loader";
import { withRouter } from "@kubera/common";

const RecapLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1000;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2px;
  padding-bottom: 44px;
`;

const RecapComponent = props => {
  const recapData = useSelector(recapDataSelector);
  const portfolios = useSelector(portfoliosSelector);
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const userMaskAllValues = useSelector(userMaskAllValuesSelector);
  const selectedChartOptions = useSelector(getRecapChartOptionForPortfolio);
  const netWorthData = useSelector(currentPortfolioNetWorthDataSelector);
  const netWorthTotal = useSelector(portfolioNetWorth);

  const isRecapDataPresent = useMemo(() => {
    return (
      recapData &&
      recapData.data &&
      recapData.data[chartTimeRange.DAILY] &&
      recapData.data[chartTimeRange.DAILY][selectedChartOptions] &&
      recapData.data[chartTimeRange.DAILY][selectedChartOptions][recapChartTypes.TOTALS] &&
      recapData.data[chartTimeRange.DAILY][selectedChartOptions][recapChartTypes.TOTALS][
        RECAP_CATEGORY_TYPE_NETWORTH
      ] &&
      recapData.data[chartTimeRange.DAILY][selectedChartOptions][recapChartTypes.TOTALS][
        RECAP_CATEGORY_TYPE_NETWORTH
      ][0].values.length > 0
    );
  }, [recapData, selectedChartOptions]);

  const showRecapPage = () => {
    const networthReportTotalsData =
      recapData &&
      recapData.data &&
      recapData.data[chartTimeRange.TODAY] &&
      recapData.data[chartTimeRange.TODAY][recapChartOptions.NETWORTH.id] &&
      recapData.data[chartTimeRange.TODAY][recapChartOptions.NETWORTH.id][recapChartTypes.TOTALS];
    let recapCustodians = [];
    if (networthReportTotalsData && networthReportTotalsData.Assets) {
      recapCustodians.push(networthReportTotalsData.Assets);
    }
    if (networthReportTotalsData && networthReportTotalsData.Debts) {
      recapCustodians.push(networthReportTotalsData.Debts);
    }
    recapCustodians = recapCustodians
      .flat()
      .filter(custodian => custodian.type !== "header" && custodian.values[0].value && !custodian.isArchived);
    return portfolios.length > 1 ? recapCustodians.length >= 1 : recapCustodians.length >= 5;
  };

  const isRecapIntroShown = isRecapIntroScreenShownForPortfolio(store.getState(), currentPortfolio);
  const isFetching = recapData && recapData.isFetching && (netWorthData && netWorthData.isFetching);
  const shouldShowRecapPage = showRecapPage();
  const renderRecapComponent = useCallback(() => {
    if (recapData && !isFetching) {
      if (isRecapDataPresent && shouldShowRecapPage && netWorthTotal !== 0) {
        if (isRecapIntroShown || isAppInViewMode() || currentPortfolio.write === 0) {
          return (
            <ColumnarChartComponent
              noOfColumns={3}
              portfolioId={currentPortfolio.id}
              userMaskAllValues={userMaskAllValues}
            />
          );
        } else {
          return <RecapDefaultMessageComponent isRecapDataPresent={true} />;
        }
      } else {
        return <RecapDefaultMessageComponent isRecapDataPresent={false} />;
      }
    } else {
      return <RecapLoader />;
    }
  }, [
    isRecapDataPresent,
    isRecapIntroShown,
    recapData,
    currentPortfolio.id,
    currentPortfolio.write,
    isFetching,
    userMaskAllValues,
    shouldShowRecapPage,
    netWorthTotal
  ]);
  return <Container>{renderRecapComponent()}</Container>;
};
export default withRouter(React.memo(RecapComponent));
