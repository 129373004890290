import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  userDiscountSelector,
  userDiscountInfoSelector,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  accountPlanSelector
} from "@kubera/common";

const Container = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.nftDiscountOrTrialMessageColor};
  margin-top: 8px;

  + .trial-message {
    display: none;
  }
`;

const TrialMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  justify-content: flex-end;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.nftDiscountOrTrialMessageColor};
  margin-top: 8px;

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 12px;
  }
`;

const Desc2Message = styled.span`
  white-space: pre-wrap;
`;

const NFTDiscountMessage = ({ className = "", isSubscribed = false, accountPlan }) => {
  const userDiscount = useSelector(userDiscountSelector);
  const userDiscountInfo = useSelector(userDiscountInfoSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const currentPlanSelector = useSelector(accountPlanSelector);

  if (
    !userDiscount ||
    [SUBSCRIPTION_PLANS.YEARLY_FAMILY, SUBSCRIPTION_PLANS.MONTHLY, SUBSCRIPTION_PLANS.MONTHLY_FAMILY].includes(
      accountPlan
    )
  ) {
    if (currentPlanSelector === SUBSCRIPTION_PLANS.TRIAL && accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
      return (
        <TrialMessage
          className="trial-message"
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountCardPayment.trialMessage")
          }}
        ></TrialMessage>
      );
    }

    return null;
  }

  if (isSubscribed) {
    const showNextRenewalTxt = userDiscountInfo.remain > 0 || userDiscountInfo.remain === -1;
    const renewalOrRenewals =
      userDiscountInfo.remain > 1 ? "discountMsg.descSubscribed3s" : "discountMsg.descSubscribed3";
    return (
      <Container className={className}>
        {i18n.t("discountMsg.desc1").replace("{discountName}", userDiscountInfo.name)}
        <br />
        {userDiscount}
        {`${i18n.t("discountMsg.descSubscribed2")}${i18n
          .t(showNextRenewalTxt ? renewalOrRenewals : "")
          .replace(/%remains%/, userDiscountInfo.remain)}.`}
      </Container>
    );
  }

  const showNextRenewalTxt = userDiscountInfo.remain >= 2;
  const renewalOrRenewals = userDiscountInfo.remain > 2 ? "discountMsg.desc3s" : "discountMsg.desc3";

  if (userDiscountInfo.remain === -1) {
    return (
      <Container className={className}>
        {i18n.t("discountMsg.desc1").replace("{discountName}", userDiscountInfo.name)}
        <br />
        <Desc2Message
          dangerouslySetInnerHTML={{
            __html: `${i18n.t("discountMsg.desc2Infinite").replace(/\{discount\}/, userDiscount)}.`
          }}
        />
      </Container>
    );
  }

  return (
    <Container className={className}>
      {i18n.t("discountMsg.desc1").replace("{discountName}", userDiscountInfo.name)}
      <br />
      <Desc2Message
        dangerouslySetInnerHTML={{
          __html: `${i18n.t("discountMsg.desc2").replace(/\{discount\}/, userDiscount)}${i18n
            .t(showNextRenewalTxt ? renewalOrRenewals : "")
            .replace(/%remains%/, userDiscountInfo.remain - 1)}.`
        }}
      />
    </Container>
  );
};

export default NFTDiscountMessage;
