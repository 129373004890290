import { store, unmarkCustodianAsLinking, isCustomWhiteLabelHost } from "@kubera/common";
import { routes, queryParams } from "routes";

import Cookies from "js-cookie";
import DeferredPromise from "utilities/DeferredPromise";

import { linkAccountMode } from "./LinkAccountComponentExports";
import LinkAccountProviderPageListener, { linkAccountMessageAction } from "./LinkAccountProviderPageListener";

export const LINK_CUSTODIAN_ID_KEY = "LINK_CUSTODIAN_ID_KEY";
export const LINK_DOMAIN_REDIRECT_KEY = "LINK_DOMAIN_REDIRECT_KEY";
const PLAID_LINK_TOKEN_SUFFIX = "PLAID_LINK_TOKEN_SUFFIX";

const activeLinkingChannel = {};
const staticMethods = {
  open: (
    regionCode,
    linkMode,
    category,
    portfolioId,
    custodian,
    linkingService,
    selectedProviderDetails,
    provider,
    providerAccountId,
    inline = false
  ) => {
    var url = `${window.location.protocol}//${window.location.host}${routes.LINK_PROVIDER_PAGE}?${queryParams.ID}=${custodian.id}`;
    if (isCustomWhiteLabelHost() === true) {
      url = `${process.env.REACT_APP_COGNITO_BASE_URL}${routes.REDIRECT_HOP}?${queryParams.REDIRECT_URL}=${encodeURI(
        url
      )}&${queryParams.CALLBACK_DOMAIN}=${encodeURI(window.location.host)}`;
    } else {
      staticMethods.setRedirectDomain(window.location.host);
    }

    const linkingWindow = inline === true ? window : window.kuberaOpen(url, custodian.id);

    const details = {
      regionCode: regionCode,
      mode: linkMode,
      category: category,
      portfolioId: portfolioId,
      custodian: custodian,
      linkingService: linkingService,
      selectedProviderDetails: selectedProviderDetails,
      selectedProvider: provider,
      providerAccountId: providerAccountId,
      linkedAccounts: null,
      inline: inline,
      linkedConnections: null,
      currentProviderPlanType: selectedProviderDetails ? selectedProviderDetails.plan : undefined,
      linkingServiceHistory: linkMode === linkAccountMode.RECONNECT ? [] : [linkingService]
    };

    staticMethods.setDetails(custodian.id, details);

    const windowTimer = setInterval(() => {
      if (linkingWindow && linkingWindow.closed === true) {
        store.dispatch(
          unmarkCustodianAsLinking(
            portfolioId,
            custodian,
            !provider === false ? provider.name : custodian.linkProviderName,
            category
          )
        );
        clearInterval(windowTimer);
      }
    }, 400);

    if (linkingWindow) {
      try {
        linkingWindow.onload = () => {
          clearInterval(windowTimer);
        };
      } catch (e) {
        console.log(e);
        clearInterval(windowTimer);
      }
    }

    if (inline === true) {
      setTimeout(() => {
        window.location.href = url;
      }, 500);
    }

    const channelName = `linking_channel_${custodian.id}`;
    if (!activeLinkingChannel[channelName]) {
      const channel = new BroadcastChannel(channelName);
      channel.addEventListener("message", message => {
        const data = message.data;
        LinkAccountProviderPageListener.sendMessage(window, data.details, data.action, data.data);

        if (data.action === linkAccountMessageAction.EXIT) {
          channel.close();
          delete activeLinkingChannel[channelName];
        }
      });
    }
    activeLinkingChannel[channelName] = 1;

    return linkingWindow;
  },

  setRedirectDomain: host => {
    Cookies.set(LINK_DOMAIN_REDIRECT_KEY, host, { path: "/", domain: ".kubera.com", expires: 1 });
  },

  getRedirectDomain: () => {
    return Cookies.get(LINK_DOMAIN_REDIRECT_KEY) || window.location.host;
  },

  setPlaidLinkToken: (custodianId, token) => {
    localStorage.setItem(custodianId + PLAID_LINK_TOKEN_SUFFIX, token);
  },

  getPlaidLinkToken: custodianId => {
    return localStorage.getItem(custodianId + PLAID_LINK_TOKEN_SUFFIX);
  },

  setDetails: (custodianId, details) => {
    localStorage.setItem(custodianId, JSON.stringify(details));
  },

  loadLeanScript: () => {
    const isLoadedPromise = new DeferredPromise();
    const script = document.createElement("script");
    script.src = "https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js";
    script.async = true;
    script.onload = () => {
      isLoadedPromise.resolve(true);
    };
    script.onerror = () => {
      isLoadedPromise.reject("This provider is facing some issues please try connecting later");
    };
    document.body.appendChild(script);
    return isLoadedPromise;
  }
};

export default staticMethods;
