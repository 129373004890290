import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  currentPortfolioSelector,
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  sanitizeIrr
} from "@kubera/common";
import { useTheme } from "theme";

import PercentageLabel from "components/labels/PercentageLabel";

const Container = styled.div`
  padding: 10px;
  contain: layout, style;
`;

const SheetName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  text-transform: capitalize;
  color: ${props => (props.isSelected ? props.theme.mobileTxtColor : props.theme.mobileTxtColorLight)};
  margin-bottom: 4px;
  white-space: nowrap;
`;

const SheetTotal = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.isSelected ? props.theme.mobileTxtColorSemiLight : props.theme.mobileTxtColorLight)};
  white-space: nowrap;
`;

const PercentageValue = styled(PercentageLabel)`
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  max-width: 70px;
  margin-left: 6px;
  font-weight: 400;
  transform: translateY(-2px);
  height: 13px;
  color: ${props => !props.isSelected && props.theme.mobileTxtColorLight};
`;

const MobileSheetComponent = ({
  index,
  currentSheetId,
  sheet: thisSheet,
  filteredPortfolioDetails,
  onSheetChange = () => null
}) => {
  const theme = useTheme();

  const portfolio = useSelector(currentPortfolioSelector);

  const handleSheetChange = () => {
    onSheetChange(thisSheet.id);
  };

  const percentageValue = useMemo(() => {
    try {
      const irrDetails = JSON.parse(thisSheet.irr);
      const sanitizedIrr = sanitizeIrr(irrDetails.all.value);
      return Math.abs(sanitizedIrr) > 0 && Math.abs(sanitizedIrr) < 1 ? sanitizedIrr : Math.kuberaFloor(sanitizedIrr);
    } catch (e) {
      return null;
    }
  }, [thisSheet.irr]);

  const sheetTotalFormatted = `${getSymbolForTickerUsingShortName(portfolio.currency)}${shortFormatNumberWithCurrency(
    thisSheet.total,
    portfolio.currency
  )}`;

  if (thisSheet.hasRowWithValue) {
    return (
      <Container onClick={handleSheetChange}>
        <SheetName isSelected={thisSheet.id === currentSheetId}>{thisSheet.name}</SheetName>
        <SheetTotal isSelected={thisSheet.id === currentSheetId}>
          {sheetTotalFormatted}
          <PercentageValue
            isSelected={thisSheet.id === currentSheetId}
            value={percentageValue}
            darkBackground={theme.mode === "dark"}
          />
        </SheetTotal>
      </Container>
    );
  }

  return null;
};

export default React.memo(MobileSheetComponent);
