import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import i18n from "i18next";
import { useTheme } from "theme";

export const toolTipAlignment = {
  RIGHT: "right",
  LEFT: "left",
  LEFT_BOTTOM: "left_bottom",
  RIGHT_BOTTOM: "right_bottom",
  BOTTOM_RIGHT: "bottom_right",
  TOP_LEFT: "top_left",
  TOP_RIGHT: "top_right",
  BOTTOM_LEFT: "bottom_left"
};

const DefaultWidth = 125;
const DefaultArrowOffset = 10;
const ArrowSize = 14;

const Container = styled.div`
  position: relative;
  width: ${props => (props.noArrowNoButton ? "100%" : props.width + "px")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${props => props.yOffset + "px"};
  ${props =>
    props.align === toolTipAlignment.TOP_RIGHT
      ? `margin-right: ${props.xOffset + "px"}`
      : `margin-left: ${props.xOffset + "px"}`};
  background-color: ${props => props.theme.toolTipBG};
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1000;
  cursor: auto;
`;

const Arrow = styled.div`
  position: absolute;
  width: ${ArrowSize}px;
  height: ${ArrowSize}px;
  transform: rotate(45deg);
  transform-origin: center;
  background-color: ${props => props.theme.toolTipBG};
  border-right: ${props =>
    props.align === toolTipAlignment.RIGHT || toolTipAlignment.TOP_LEFT
      ? props.theme.toolTipArrowBorder
      : "1px solid rgba(0, 0, 0, 0.2)"};
  border-top: ${props =>
    props.align === toolTipAlignment.RIGHT || toolTipAlignment.TOP_LEFT
      ? props.theme.toolTipArrowBorder
      : "1px solid rgba(0, 0, 0, 0.2)"};

  ${props => {
    return props.align === toolTipAlignment.LEFT_BOTTOM || props.align === toolTipAlignment.RIGHT_BOTTOM
      ? `bottom: ${props.offset}px`
      : `top: ${props.offset}px`;
  }}

  ${props => {
    return props.align === toolTipAlignment.LEFT || props.align === toolTipAlignment.LEFT_BOTTOM
      ? `right: -8px;`
      : `left: -8px;`;
  }};

  ${props => {
    return props.align === toolTipAlignment.BOTTOM_RIGHT
      ? `
      top: -${ArrowSize / 2}px;
      right: 17px;
      left: auto;
      border-top: ${props.theme.toolTipArrowBorder};
      border-left: ${props.theme.toolTipArrowBorder};
      border-right: 0;
    `
      : null;
  }}

  ${props => {
    return props.align === toolTipAlignment.BOTTOM_LEFT
      ? `
      top: -${ArrowSize / 2}px;
      left: 17px;
      right: auto;
      border-top: ${props.theme.toolTipArrowBorder};
      border-left: ${props.theme.toolTipArrowBorder};
      border-right: 0;
    `
      : null;
  }}

  ${props => {
    return props.align === toolTipAlignment.TOP_LEFT ? `top: -7px; left: 10%;` : null;
  }}
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.toolTipDescCLR};
  text-align: left;
  white-space: ${props => (props.noArrowNoButton ? "nowrap" : "pre-wrap")};
`;

const PositiveButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.toolTipBtnCLR};
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin-top: 15px;
  cursor: pointer;
`;

class ToolTip extends React.Component {
  constructor(props) {
    super(props);

    this.tipNodeId = null;
    this.containerRef = React.createRef();
    this.tooltipState = React.createRef({ visible: false, xOffset: 0, yOffset: 0, description: null, onDismiss: null });

    this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.targetId !== this.props.targetId || oldProps.theme.mode !== this.props.theme.mode) {
      const tooltipState = this.tooltipState.current;
      if (this.isVisible() === true) {
        this.show(tooltipState.description, tooltipState.xOffset, tooltipState.yOffset, tooltipState.onDismiss);
      }
    }
  }

  isVisible() {
    return this.tooltipState.current ? this.tooltipState.current.visible : false;
  }

  dismiss() {
    if (this.isVisible() === false) {
      return;
    }
    if (!this.tipNodeId === true) {
      return;
    }

    const tip = document.getElementById(this.tipNodeId);
    this.tipNodeId = null;
    if (tip) {
      tip.remove();
      if (!this.tooltipState.current.onDismiss === false) {
        setTimeout(() => {
          if (!this.tooltipState.current.onDismiss === false) {
            this.tooltipState.current.onDismiss();
          }
          this.tooltipState.current = { visible: false, xOffset: 0, yOffset: 0, description: null, onDismiss: null };
        }, 0);
      }
    }
  }

  show(description, xOffset, yOffset, onDismiss) {
    if (!description) {
      return;
    }
    this.tooltipState.current = {
      visible: true,
      description: description,
      xOffset: xOffset,
      yOffset: yOffset,
      onDismiss: onDismiss
    };

    const target = document.getElementById(this.props.targetId);
    if (!target === true) {
      return;
    }
    const parentTargetNode = target.parentNode;
    const Tip = () => this.getTip();
    const tipParent = document.createElement("div");
    tipParent.id = this.getTipId();
    this.tipNodeId = tipParent.id;

    if (document.getElementById(tipParent.id)) {
      document.getElementById(tipParent.id).remove();
    }
    tipParent.style.position = "absolute";
    if (this.props.align === toolTipAlignment.LEFT) {
      tipParent.style.left = `-${this.getWidth()}px`;
    } else if (this.props.align === toolTipAlignment.LEFT_BOTTOM) {
      tipParent.style.left = `-${this.getWidth()}px`;
      tipParent.style.bottom = `0px`;
    } else if (this.props.align === toolTipAlignment.RIGHT_BOTTOM) {
      tipParent.style.left = `${target.getBoundingClientRect().width + ArrowSize * 2}px`;
      tipParent.style.bottom = `0px`;
    } else if (
      this.props.align === toolTipAlignment.BOTTOM_RIGHT ||
      this.props.align === toolTipAlignment.BOTTOM_LEFT
    ) {
      tipParent.style.top = `100%`;
    } else if (this.props.align === toolTipAlignment.TOP_RIGHT) {
      tipParent.style.right = `0px`;
      tipParent.style.top = `0px`;
    } else {
      tipParent.style.left = `${target.getBoundingClientRect().width}px`;
    }
    parentTargetNode.appendChild(tipParent);
    ReactDOM.createRoot(tipParent).render(<Tip />);
  }

  handlePositiveButtonClick(e) {
    if (this.props.onPositiveClick) {
      this.props.onPositiveClick();
    } else {
      this.dismiss();
      e.stopPropagation();
    }
  }

  getArrowOffset() {
    const anchorElement = document.getElementById(this.props.targetId);
    if (!anchorElement === true) {
      return DefaultArrowOffset;
    }
    const anchorPosition = anchorElement.getBoundingClientRect();
    if (anchorPosition) {
      return (anchorPosition.height - ArrowSize) / 2;
    }
    return DefaultArrowOffset;
  }

  getTipId() {
    return this.props.targetId + "-tip";
  }

  getWidth() {
    if (!this.props.width) {
      return DefaultWidth;
    }
    return this.props.width;
  }

  getTip() {
    const width = this.getWidth();
    const { xOffset, yOffset, description } = this.tooltipState.current;
    const arrowOffset = this.props.arrowOffset || this.getArrowOffset();
    const { align, theme } = this.props;

    return (
      <Container
        xOffset={xOffset}
        yOffset={yOffset}
        width={width}
        ref={this.containerRef}
        tabIndex={"0"}
        align={align}
        theme={theme}
        noArrowNoButton={this.props.noArrowNoButton}
      >
        {!this.props.noArrowNoButton && !this.props.noArrow && (
          <Arrow offset={arrowOffset} align={align} theme={theme} />
        )}
        <Description
          dangerouslySetInnerHTML={{
            __html: description
          }}
          theme={theme}
          noArrowNoButton={this.props.noArrowNoButton}
        />
        {!this.props.noArrowNoButton && (
          <PositiveButton tabIndex={"0"} onClick={this.handlePositiveButtonClick} theme={theme}>
            {i18n.t("gotIt")}
          </PositiveButton>
        )}
      </Container>
    );
  }

  render() {
    return null;
  }
}

ToolTip.defaultProps = {
  align: "right",
  noArrowNoButton: false
};

ToolTip.propTypes = {
  align: PropTypes.string,
  noArrowNoButton: PropTypes.bool
};

const ToolTipWrapper = React.forwardRef((props, ref) => {
  const theme = useTheme();
  return <ToolTip ref={ref} {...props} theme={theme} />;
});

export default ToolTipWrapper;
