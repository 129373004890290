import React from "react";
import i18n from "i18next";
import styled, { css } from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import TextArea from "components/inputs/TextArea";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import {
  whiteLabelUserInfoSelector,
  inviteWlSubUser,
  wlClientSelector,
  wlClientAndSubUserSelector,
  updateWlClientInvite,
  siteConfigSelector,
  wlSetupType,
  isMobile,
  isEmailValid,
  apiErrorCodes,
  wlManagersSelector,
  isWlEmailPlaceholder
} from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import Spinner from "components/loader/Spinner";
import EmailInput from "components/inputs/EmailInput";
import DropDown from "components/inputs/DropDown";

const isMobileDevice = isMobile();

const InviteClientDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  min-height: 451px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 55px 60px 67px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => (isMobileDevice ? props.theme.mobileBackgroundOverlay : props.theme.dialogContentContainerBG)};
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  font-feature-settings: "ss01" on;
  color: #000000;
  flex-direction: column;
`;

const Loader = styled(Spinner)`
  margin-bottom: 15px;
  width: 30px;
  height: 30px;
`;

const Title = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ClientName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const FieldTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const PasscodeField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const EmailField = styled(EmailInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  outline: "";
`;

const PermissionDropDown = styled(DropDown)`
  text-transform: capitalize;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
  opacity: ${props => props.disabled === true && "0.5"};
`;

const MessageTextArea = styled(TextArea)`
  min-height: 70px;
  margin-top: -1px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 13px 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const SubmitErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const InviteButtonContainer = styled.div`
  flex: 1;
`;

const InviteButton = styled(PrimaryButton)`
  margin-top: 40px;
  height: 40px;
`;

class InviteClientDialogComponent extends React.Component {
  static show = (history, location, userId = null) => {
    var hash = `${hashParams.MODAL}=${modalValues.WL_INVITE_CLIENT}`;
    if (!userId === false) {
      hash += `&${hashParams.ID}=${userId}`;
    }

    history.push({
      ...location,
      hash: hash
    });
  };

  constructor(props) {
    super(props);

    const email = () => {
      const userEmail = this.userInfo?.email;
      if (userEmail) {
        return isWlEmailPlaceholder(userEmail) ? null : userEmail;
      }
      return null;
    };

    this.state = {
      name: this.userInfo?.clientName || this.userInfo?.name,
      email: email(),
      emailErrorMessage: null,
      write: this.isPrimaryUserFlow ? 1 : this.userInfo?.write || 1,
      passcode: this.userInfo?.share?.passcode,
      inviteMessage: (this.props.siteConfig.inviteCustomMessage || "").replace(/\\r?\\n/g, "\r\n"),
      isSubmitting: false,
      submitErrorMessage: null
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.handlePermissionDropDownSelection = this.handlePermissionDropDownSelection.bind(this);
    this.handlePasscodeInputChange = this.handlePasscodeInputChange.bind(this);
    this.handleMessageInputChange = this.handleMessageInputChange.bind(this);
    this.handleInviteButtonClick = this.handleInviteButtonClick.bind(this);
  }

  get userInfo() {
    if (this.isSubUserFlow === true) {
      if (!this.props.subUserId === true) {
        return null;
      }
      return this.props.clientAndSubUser.find(user => user.userId === this.props.subUserId);
    }
    return this.props.client;
  }

  get isSubUserFlow() {
    return !this.props.subUserId === false || this.props.isAddSubUserFlow === true;
  }

  get isPrimaryUserFlow() {
    return (
      (!this.props.subUserId === true && !this.props.isAddSubUserFlow === true) ||
      this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO
    );
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleInviteButtonClick(event);
    }
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss(this.props.history, this.props.location);
      return;
    }
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  handleEmailInputChange(e) {
    this.setState({ email: e.target.value, emailErrorMessage: null });
  }

  handleMessageInputChange(value) {
    this.setState({ inviteMessage: value });
  }

  handlePasscodeInputChange(e) {
    this.setState({ passcode: e.target.value });
  }

  handleInviteButtonClick(e) {
    if (!this.state.email === true) {
      this.setState({ emailErrorMessage: i18n.t("emptyEmail") });
      return;
    }

    if (isEmailValid(this.state.email) === false) {
      this.setState({ emailErrorMessage: i18n.t("wrongEmailFormat") });
      return;
    }

    if (this.props.wlManagers.find(manager => manager.email.toLowerCase() === this.state.email.toLowerCase())) {
      this.setState({ emailErrorMessage: i18n.t("wlkAdminAsClientError") });
      return;
    }

    const customMessage = this.state.inviteMessage.replaceAll(/\r?\n/g, "<br/>");
    const userInfo = this.userInfo;
    if (this.isPrimaryUserFlow) {
      this.setState({ isSubmitting: true });
      this.props.updateWlClientInvite(
        this.state.name,
        userInfo?.email,
        this.state.email,
        customMessage,
        true,
        this.state.passcode,
        user => {
          this.handleOverlayDismiss();
        },
        apiError => {
          if (apiError.errorCode === apiErrorCodes.EMAIL_IN_USE) {
            this.setState({ isSubmitting: false, emailErrorMessage: i18n.t("emailInUse") });
          } else if (apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY) {
            this.setState({ isSubmitting: false, emailErrorMessage: i18n.t("wlkUserAlreadyExists") });
          } else {
            this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
          }
        }
      );
    } else if (this.isSubUserFlow) {
      const subUser = {
        parentUserId: this.props.client.userId,
        name: this.state.name,
        email: this.state.email.toLowerCase(),
        allowAll: !this.props.portfolioId === true ? 1 : 0,
        write: this.state.write,
        message: customMessage
      };

      if (this.props.portfolioId) {
        subUser.portfolioId = this.props.portfolioId;
      }
      if (userInfo?.familyId) {
        subUser.familyId = userInfo.familyId;
      }

      this.setState({ isSubmitting: true });
      this.props
        .inviteWlSubUser(this.props.client, subUser)
        .then(user => {
          if (this.props.onCompletion) {
            this.props.onCompletion(user.subUser.find(item => item.email === this.state.email));
          }
          this.handleOverlayDismiss();
        })
        .catch(apiError => {
          if (apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY) {
            this.setState({ isSubmitting: false, emailErrorMessage: i18n.t("wlkUserAlreadyExists") });
          } else {
            this.setState({ isSubmitting: false, submitErrorMessage: apiError.errorMessage });
          }
        });
    }
  }

  getTitle() {
    if (!this.props.portfolioId === false) {
      return i18n.t("sharePortfolioWith");
    }
    return i18n.t("addUser");
  }

  handleTextAreaAdjust = ref => {
    ref.style.height = "1px";
    ref.style.height = 5 + ref.scrollHeight + "px";
  };

  handlePermissionDropDownSelection(item) {
    this.setState({ write: item.id });
  }

  getPermissionDropDownData() {
    return [
      {
        id: 1,
        label: i18n.t("addUserPermission.writable"),
        selected: this.state.write === 1
      },
      {
        id: 0,
        label: i18n.t("addUserPermission.readOnly"),
        selected: this.state.write === 0
      }
    ];
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <InviteClientDialog>
          <Container>
            <Title>{this.getTitle()}</Title>
            {!this.props.portfolioId === true && <ClientName>{this.props.client.clientName}</ClientName>}
            <FormContainer>
              <FieldTitle>{i18n.t("email")}</FieldTitle>
              <EmailField
                placeholder={i18n.t("email")}
                value={this.state.email}
                errorMessage={this.state.emailErrorMessage}
                onChange={this.handleEmailInputChange}
                onKeyDown={this.handleKeyDown}
              />

              {this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_WR && (
                <>
                  <FieldTitle>{i18n.t("permission")}</FieldTitle>
                  <PermissionDropDown
                    disabled={this.isPrimaryUserFlow === true}
                    hideIndicatorForDisabled={true}
                    width={492}
                    items={this.getPermissionDropDownData()}
                    onSelection={item => this.handlePermissionDropDownSelection(item)}
                  />
                </>
              )}

              {this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO && (
                <>
                  <FieldTitle>{i18n.t("passcode") + " " + i18n.t("optional")}</FieldTitle>
                  <PasscodeField
                    placeholder={i18n.t("passcode")}
                    value={this.state.passcode}
                    onChange={this.handlePasscodeInputChange}
                  />
                </>
              )}

              <FieldTitle>{i18n.t("wlkInviteDialog.message")}</FieldTitle>
              <MessageTextArea
                placeholder={i18n.t("message")}
                value={this.state.inviteMessage}
                onChange={this.handleMessageInputChange}
                onMount={this.handleTextAreaAdjust}
              />
              <InviteButtonContainer>
                <InviteButton
                  title={i18n.t("sendInvite")}
                  onClick={this.handleInviteButtonClick}
                  isLoading={this.state.isSubmitting}
                />
                {!this.state.submitErrorMessage === false && (
                  <SubmitErrorMessage
                    dangerouslySetInnerHTML={{
                      __html: this.state.submitErrorMessage
                    }}
                  />
                )}
              </InviteButtonContainer>
              {this.state.isSubmitting && (
                <LoadingContainer>
                  <Loader />
                  {i18n.t(`wlkInviteDialog.invitingClientWithEmail`).replace("{email}", this.state.email)}
                </LoadingContainer>
              )}
            </FormContainer>
          </Container>
        </InviteClientDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  wlUserInfo: whiteLabelUserInfoSelector(state),
  client: wlClientSelector(state, props.userId),
  clientAndSubUser: wlClientAndSubUserSelector(state, props.userId),
  siteConfig: siteConfigSelector(state),
  wlManagers: wlManagersSelector(state)
});

const mapDispatchToProps = {
  updateWlClientInvite: updateWlClientInvite,
  inviteWlSubUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InviteClientDialogComponent));
