import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import { withRouter } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { isMobile } from "@kubera/common";

const isMobileDevice = isMobile();

const GenericDialog = styled(Dialog)`
  width: ${props => (props.isMobile ? "90%" : props.dialogWidth ? props.dialogWidth : "550px")};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 163px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${props => (props.isMobile ? "30px" : "50px 60px 50px 60px")};
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${props => (props.isMobile ? "15px" : "22px")};
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 5px;
`;

const Description = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  ${props => (props.descriptionStyle ? props.descriptionStyle : "")}
`;

const Icon = styled.div`
  background-color: transparent;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 30px;
  ${props => (props.iconStyle ? props.iconStyle : "")}
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 28px;
`;

const PositiveButton = styled(PrimaryButton)`
  margin-right: 20px;
  font-size: ${isMobileDevice ? "14px" : "12px"};
`;

const NegativeButton = styled(SecondaryButton)``;

class GenericMessageDialog extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.GENERIC_ERROR}`
    });
  };

  constructor(props) {
    super(props);

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this);
    this.handleNegativeButtonClick = this.handleNegativeButtonClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handlePositiveButtonClick();
      return true;
    }
  }

  handleOverlayDismiss() {
    if (this.props.canUserDismiss === false) {
      return;
    }

    if (this.props.onOverlayDismissClick) {
      this.props.onOverlayDismissClick();
    } else {
      DialogOverlay.dismiss(this.props.history, this.props.location);
    }
  }

  handlePositiveButtonClick() {
    if (this.props.onPositiveButtonClick) {
      this.props.onPositiveButtonClick();
    } else {
      this.handleOverlayDismiss();
    }
  }

  handleNegativeButtonClick() {
    if (this.props.onNegativeButtonClick) {
      this.props.onNegativeButtonClick();
    } else {
      this.handleOverlayDismiss();
    }
  }

  render() {
    const title = this.props.title;
    const description = this.props.description;
    const positiveButtonTitle = this.props.positiveButtonTitle
      ? this.props.positiveButtonTitle
      : i18n.t("ok").toUpperCase();
    const negativeButtonTitle = this.props.negativeButtonTitle;
    const icon = this.props.icon;
    const dialogWidth = this.props.dialogWidth;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <GenericDialog className={this.props.className} isMobile={isMobileDevice} dialogWidth={dialogWidth}>
          <Container isMobile={isMobileDevice}>
            <Title isMobile={isMobileDevice}>{title}</Title>
            <DescriptionContainer>
              <Description
                descriptionStyle={this.props.descriptionStyle}
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
              <Icon icon={icon} iconStyle={this.props.iconStyle} />
            </DescriptionContainer>
            <ButtonContainer>
              <PositiveButton
                data-cy="genericPositive"
                onClick={this.handlePositiveButtonClick}
                title={positiveButtonTitle}
              />
              {!negativeButtonTitle === false && (
                <NegativeButton
                  data-cy="genericNegative"
                  onClick={this.handleNegativeButtonClick}
                  title={negativeButtonTitle}
                />
              )}
            </ButtonContainer>
          </Container>
        </GenericDialog>
      </DialogOverlay>
    );
  }
}

export default withRouter(GenericMessageDialog);
