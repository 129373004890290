import React, { useState } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import DropDown from "components/inputs/DropDown";

const CustomDialog = styled(Dialog)`
  position: relative;
  width: 500px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 11px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const Input = styled(TextInput)`
  margin-bottom: 20px;
  padding: 14px 14px 14px 14px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
`;

const StyledDropDown = styled(DropDown)`
  height: 45px;
  margin-bottom: 20px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 24px;
`;

const NewKeyDialog = ({ onDismiss, onSave, defaultName }) => {
  const [name, setName] = useState(defaultName);
  const [readonly, setReadonly] = useState(1);
  const [allowedIp, setAllowedIp] = useState();

  const handleSave = () => {
    onSave({ name, readonly, allowedIp });
    onDismiss();
  };

  const readOnlyOptions = [
    { label: i18n.t("readOnlyAPIAccess"), value: 1, selected: readonly === 1, id: "r" },
    { label: i18n.t("readWriteAPIAccess"), value: 0, selected: readonly === 0, id: "rw" }
  ];

  return (
    <DialogOverlay onDismiss={onDismiss}>
      <CustomDialog>
        <Container>
          <Title>{i18n.t("createAPIKey")}</Title>

          <InputLabel>{i18n.t("name")}</InputLabel>
          <Input value={name} onChange={e => setName(e.target.value)} />

          <InputLabel>{i18n.t("permission")}</InputLabel>
          <StyledDropDown items={readOnlyOptions} onSelection={item => setReadonly(item.value)} width={400} />

          <InputLabel>{i18n.t("allowedIPsLabel")}</InputLabel>
          <Input
            placeholder={i18n.t("allowedIPsPlaceholder")}
            value={allowedIp}
            onChange={e => setAllowedIp(e.target.value)}
          />

          <SaveButton title={i18n.t("save")} onClick={handleSave} />
        </Container>
      </CustomDialog>
    </DialogOverlay>
  );
};

export default NewKeyDialog;
