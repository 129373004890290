import pako from "pako";
import { getUuid } from "./Number";
import { getSWConsts } from "./Common";

// Function to compress an object to a compressed string
export function compressObject(obj) {
  // Convert object to JSON string
  var jsonString = JSON.stringify(obj);
  // Compress JSON string using LZString library
  var compressedString = pako.deflate(jsonString, { to: "string", level: 5 });
  return compressedString;
}

// Function to decompress a compressed string to an object
export function decompressString(compressedString) {
  // Decompress compressed string using LZString library
  var decompressedString = pako.inflate(compressedString, { to: "string", level: 5 });
  // Parse decompressed JSON string to object
  var obj = JSON.parse(decompressedString);
  return obj;
}

export const deepCopyFromSW = obj => {
  return new Promise((resolve, reject) => {
    const swConsts = getSWConsts();

    if (!navigator.serviceWorker?.controller) {
      console.log("No sw spotted. So using parse-stringify");
      resolve(JSON.parse(JSON.stringify(obj)));
      return;
    }

    const uniqueId = getUuid();
    navigator.serviceWorker.controller.postMessage({
      type: swConsts.GET_CLONE_OBJ + uniqueId,
      transferId: uniqueId,
      SW_CONSTS: swConsts,
      obj
    });

    let isResolved = false;
    const handleSWMessage = event => {
      if (event.data.type === `${swConsts.GET_CLONE_OBJ}${uniqueId}`) {
        isResolved = true;
        resolve(event.data.obj);
        navigator.serviceWorker.removeEventListener("message", handleSWMessage);
      }
    };

    setTimeout(() => {
      if (!isResolved) {
        console.log("No response from SW. So using parse-stringify");
        resolve(JSON.parse(JSON.stringify(obj)));
        navigator.serviceWorker.removeEventListener("message", handleSWMessage);
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (let registration of registrations) {
            registration.update().then(() => {
              console.log("Service Worker update triggered");
            });
          }
        });
      }
    }, 3000);

    navigator.serviceWorker.addEventListener("message", handleSWMessage);
  });
};
