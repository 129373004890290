import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import i18n from "i18next";

import {
  wlAdminListChargesSelector,
  wlPaymentMethodSelector,
  siteConfigSelector,
  useHistory
  // userSelector,
  // SUBSCRIPTION_ERROR
} from "@kubera/common";

import AccountCardPayment from "./AccountCardPayment";
import AccountSubscribedCard from "./AccountSubscribedCard";
// import PaymentsList from "./PaymentsList";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import { Dialog, DialogOverlay } from "components/dialog/DialogOverlay";
import ClickableLink from "components/labels/DelayLink";

import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding: 20px 20px 45px;
  overflow: auto;
`;

const HistoryBackContainer = styled(ClickableLink)`
  padding: 20px;
  margin: -20px;
  margin-right: -10px;
  border-radius: 50%;
  margin-bottom: 0;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 15px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const SubscriptionTxt = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 12px;
`;

const AccountPaymentBlock = () => {
  // const stripe = useStripe();
  const history = useHistory();

  const paymentMethod = useSelector(wlPaymentMethodSelector);
  // const user = useSelector(userSelector);
  const wlAdminListCharges = useSelector(wlAdminListChargesSelector);

  const [updateMode, setUpdateMode] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false); // eslint-disable-line
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);

  const captureError = useCallback((errorState, reason) => {
    Sentry.withScope(scope => {
      scope.setExtras({
        plan: "monthly",
        reason
      });
      Sentry.captureMessage(errorState);
    });
  }, []);

  const handleSubscribedCardSubmit = () => {
    if (paymentMethod.paymentMethodId) {
      setUpdateMode(false);
    }
  };

  // const handlePaymentError = isError => {
  //   setIsPaymentError(isError);
  // };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const renderPaymentBlock = () => {
    if (!!paymentMethod.paymentMethodId && !updateMode) {
      return (
        <AccountSubscribedCard
          setUpdateMode={setUpdateMode}
          isAuthenticating={isAuthenticating}
          isPaymentError={isPaymentError}
        />
      );
    }

    return <AccountCardPayment setIsSubmitted={handleSubscribedCardSubmit} captureError={captureError} />;
  };

  // const handleAuthenticationRequired = async accountPaymentAuth => {
  //   if (
  //     accountPaymentAuth &&
  //     (accountPaymentAuth.intentStatus === "requires_action" ||
  //       accountPaymentAuth.intentStatus === "requires_payment_method")
  //   ) {
  //     setIsAuthenticating(true);
  //     return stripe
  //       .confirmCardPayment(accountPaymentAuth.clientSecret, { payment_method: paymentMethod.paymentMethodId })
  //       .then(async result => {
  //         if (result.error) {
  //           captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_ERROR, result.error);
  //           setPaymentErrorMsg(result.error.message);
  //           setIsDeclinedError(true);
  //         } else if (result.paymentIntent.status === "succeeded") {
  //         } else {
  //           captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_STATUSERROR, result.paymentIntent.last_payment_error);
  //           setPaymentErrorMsg(result.paymentIntent.last_payment_error);
  //           setIsDeclinedError(true);
  //         }
  //       })
  //       .catch(error => null);
  //   }

  //   return Promise.resolve();
  // };

  useEffect(() => {
    setIsAuthenticating(false);
  }, [wlAdminListCharges]);

  return (
    <Container>
      <ContainerDialog>
        <div>
          <HistoryBackContainer onClick={goBack} direction="right">
            <HistoryBackBtn />
          </HistoryBackContainer>
        </div>
        <SubscriptionTxt>{i18n.t("accountSettings.subscriptionTab")}</SubscriptionTxt>
        {renderPaymentBlock()}
        <AccountDeclinedCardModal
          isOpen={isDeclinedError}
          message={paymentErrorMsg}
          onDismiss={onDeclinedModalDismiss}
        />
      </ContainerDialog>
    </Container>
  );
};

let stripePromise = null;
const handleStripeLoad = async stripeAccount => {
  if (stripePromise === null) {
    stripePromise = await loadStripe(`${process.env.REACT_APP_STRIPE_PK}`, { stripeAccount });
  }

  return stripePromise;
};

const ElementsWrapper = props => {
  const siteConfig = useSelector(siteConfigSelector);

  const [isStripeLoaded, setIsStripeLoaded] = useState(false);

  useEffect(() => {
    const loadStripe = async () => {
      await handleStripeLoad(siteConfig.stripeConnectedAccount.id);
      setIsStripeLoaded(true);
    };
    if (siteConfig.stripeConnectedAccount && !isStripeLoaded) {
      loadStripe();
    }
  }, [siteConfig.stripeConnectedAccount, isStripeLoaded]);

  if (!isStripeLoaded) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <AccountPaymentBlock {...props} />
    </Elements>
  );
};

export default ElementsWrapper;
