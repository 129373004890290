import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  getTickerUsingId,
  tickerTypes,
  getSortKeyBetween,
  fetchCustodianDetailsHistory,
  deleteCostBasisHistoryForCustodian,
  updateCostBasisHistoryForCustodian,
  guessDateInCustodianHistoryUIFormat,
  updateCustodian,
  refreshCustodian,
  shortenTickerIfNeeded,
  getSanitizedExchangeRate,
  formatNumberAsCurrency,
  getDateStringForExchangeRateDate,
  fetchTickerDetails,
  showToastTip
} from "@kubera/common";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import InViewPort from "components/in_viewport/InViewPort";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";

const CostHistoryDialog = styled(Dialog)`
  width: 620px;
  min-height: 600px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 60px;
`;

const GridTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const GridContainer = styled.div`
  margin-top: 10px;
  border: ${props => (props.theme.mode === "default" ? "1px solid rgba(0, 0, 0, 0.1)" : null)};
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCellsContainer = styled.div`
  display: flex;
  position: relative;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: pointer;
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

class CustodianAssetCostComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.onScrollToBottom = this.onScrollToBottom.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);

    props.history.sort((a, b) => b.date.localeCompare(a.date));
    this.state = {
      gridData: this.getGridData(props.currency, props.history),
      showRateCellDialog: false,
      rateCellDialogData: null
    };
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (
      this.state.gridData.currency !== this.props.currency ||
      oldProps.history.length !== this.props.history.length ||
      oldProps.isHistoryUpdating !== this.props.isHistoryUpdating
    ) {
      this.setState({ gridData: this.getGridData(this.props.currency, this.props.history) });
    }
  }

  getGridData(currency, history) {
    var rows = [];
    let nextSortKey = null;
    for (const [, historyEntry] of history.entries()) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey);
      row.id = historyEntry.id;
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(historyEntry.date);
      row.cells[1].value = historyEntry.cost;

      if (historyEntry.costTickerId) {
        row.cells[1].currency = getTickerUsingId(historyEntry.costTickerId).shortName;
      }
      if (historyEntry.costExchangeRate) {
        row.cells[1].exchangeRateDetails = historyEntry.costExchangeRate;
      }
      row.cells[1].exchangeRateDate = new Date(historyEntry.date);
      row.tsModified = historyEntry.lastModified;
      rows.push(row);
    }
    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const costColumn = new GridColumnData("Cost", true, this.props.isReadOnly === false, false);

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      [dateColumn, costColumn],
      1,
      1,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    dateCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const costCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1];
      if (
        this.props.currency === costCell.currency ||
        !costCell.exchangeRateDate === true ||
        costCell.loading === true
      ) {
        return null;
      }

      const cellData = {
        exchangeRateDate: costCell.exchangeRateDate,
        fromCurrency: costCell.currency,
        toCurrency: this.props.currency,
        rate: costCell.getCellExchangeRate(this.props.currency),
        sanitizedRate: getSanitizedExchangeRate(costCell.getCellExchangeRate(this.props.currency), this.props.currency),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 1
      };

      const rateCellId = `${rowIndex}-${cellIndex}-ratecell`;
      if (!this.firstRateCellId === true) {
        this.firstRateCellId = rateCellId;
      }

      return (
        <ExchangeRateCellsContainer>
          <ExchangeRateCell id={rateCellId} onClick={e => this.handleRateCellClick(e, cellData)}>
            <ExchangeCurrencies>{`${shortenTickerIfNeeded(costCell.currency)}/${shortenTickerIfNeeded(
              this.props.currency
            )}`}</ExchangeCurrencies>
            <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.currency)}</ExchangeRate>
          </ExchangeRateCell>
        </ExchangeRateCellsContainer>
      );
    };

    const costCell = new CurrencyCellData(cellType.CURRENCY, "Cost", null, this.props.currency);
    costCell.useRateFromExchangeRateDetails = true;
    costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    const cells = [dateCell, costCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      const date = cells[0].value;
      const cost = cells[1].value;
      const guessedDate = guessDateInCustodianHistoryUIFormat(date);

      if (guessedDate.isInvalid === false && cost !== null && cost !== undefined) {
        return true;
      }
      return false;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true) {
        return null;
      }
      if (rowIndex !== 0) {
        return [[contextMenuItemType.REMOVE]];
      }
      return [];
    };
    rowData.onUpdateDelay = 5000;
    return rowData;
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
      const date = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;
      this.props.fetchTickerDetails(
        cell.invalidInputText,
        date,
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.exchangeRateDetails = result.exchangeRateDetails;
          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  onScrollToBottom() {
    if (!this.props.isFetchPending && !this.props.isHistoryLoaded) {
      const detailsHistory = this.props.history;

      if (detailsHistory && detailsHistory.length > 0) {
        const { timestamp } = detailsHistory[detailsHistory.length - 1];
        this.props.fetchDetailsHistory(this.props.custodianId, timestamp, true);
      }
    }
  }

  handleRateCellClick(e, cellData) {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.handleHistoryUpdate(rateData.sheetIndex, rateData.sectionIndex, rateData.rowIndex, row);
  }

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      row => row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.REMOVE.id) {
      this.props.deleteCostBasisHistoryForCustodian(this.props.sectionId, this.props.custodianId, row.id);
    }
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      return;
    }

    this.handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow);
  }

  handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow) {
    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }

    const costCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[1];
    if (
      costCell.currency !== this.props.currency &&
      getDateStringForExchangeRateDate(costCell.exchangeRateDate) !== getDateStringForExchangeRateDate(guessedDate.date)
    ) {
      costCell.invalidInputText = costCell.currency;
      costCell.exchangeRateDate = guessedDate.date;
      this.handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, 1);
      return;
    }

    const updateHistory = () => {
      this.props.updateCostBasisHistoryForCustodian(this.props.sectionId, this.props.custodianId, updatedRow.id, {
        id: updatedRow.id,
        date: guessedDate.dateString,
        cost: updatedRow.cells[1].value,
        costTickerId: updatedRow.cells[1].getTickerId(),
        costExchangeRate: updatedRow.cells[1].exchangeRateDetails
      });
    };
    const shouldUpdateCustodian = this.shouldUpdateCustodian(updatedRow.cells[0].value);
    if (shouldUpdateCustodian) {
      updateHistory();

      const propertiesToUpdate = {
        cost: updatedRow.cells[1].value,
        costTickerId: updatedRow.cells[1].getTickerId(),
        costExchangeRate: updatedRow.cells[1].exchangeRateDetails,
        past: 0
      };
      this.props.updateCustodian(false, this.props.custodianId, propertiesToUpdate);
      this.props.onCustodianUpdate(propertiesToUpdate);
    } else {
      updateHistory();
    }
  }

  shouldUpdateCustodian(updatedRowDateString) {
    if (!updatedRowDateString === true) {
      return false;
    }

    const updatedRowDate = guessDateInCustodianHistoryUIFormat(updatedRowDateString);
    if (updatedRowDate.isInvalid === true) {
      return false;
    }

    for (const row of this.state.gridData.sheets[0].sections[0].rows) {
      if (!row.cells[0].value === false) {
        const rowDate = new Date(row.cells[0].value);

        if (rowDate.getTime() > updatedRowDate.date.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      var dateString = guessedDate.dateString;
      const newGridData = this.state.gridData;

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = null;
      }
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].value = dateString;
      this.setState({ ...this.state, gridData: newGridData });
    }

    // If the oldest row is edited / added fire a custodian refresh
    // to ensure the marketDateStart is updated correctly
    var min = null;
    for (const row of this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows) {
      if (!row.cells[0].value === false) {
        const tempDate = new Date(row.cells[0].value);
        if (!min === true) {
          min = row.cells[0].value;
        }

        if (tempDate.getTime() < new Date(min).getTime()) {
          min = row.cells[0].value;
        }
      }
    }

    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    if (min === row.cells[0].value && !row.cells[1].value === false) {
      setTimeout(() => {
        this.props.refreshCustodian(this.props.custodianId);
      }, 1000);
    }
  }

  render() {
    if (!this.props.history === true || this.props.history.length === 0 || this.props.isHistoryUpdating === true) {
      return (
        <DialogOverlay onDismiss={this.props.onDismiss}>
          <CostHistoryDialog>
            <Container>
              <Loader />
            </Container>
          </CostHistoryDialog>
        </DialogOverlay>
      );
    }

    const gridData = this.state.gridData;
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <CostHistoryDialog>
          <Container>
            <GridTitle>{i18n.t("costBasis")}</GridTitle>
            <GridContainer>
              <Grid
                title={i18n.t("assetsComponent.title")}
                gridData={gridData}
                getEmptyRow={this.getEmptyRow}
                onRowContextMenuSelection={this.handleRowContextMenuSelection}
                onChange={this.handleChange}
                onRowUpdate={this.handleRowUpdate}
                onCellBlur={this.handleCellBlur}
                onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
              />
            </GridContainer>
            {this.props.isFetchPending && !this.props.isHistoryLoaded && <Loader />}
            <InViewPort onVisible={this.onScrollToBottom} />
            {!this.state.showRateCellDialog === false && (
              <ExchangeRateChangeDialog
                rateData={this.state.rateCellDialogData}
                onRateChange={this.handleRateCellDialogOnChange}
                onDismiss={this.handleRateCellDialogOnDismiss}
              />
            )}
          </Container>
        </CostHistoryDialog>
      </DialogOverlay>
    );
  }
}

const mapDispatchToProps = {
  fetchDetailsHistory: fetchCustodianDetailsHistory,
  deleteCostBasisHistoryForCustodian: deleteCostBasisHistoryForCustodian,
  updateCostBasisHistoryForCustodian: updateCostBasisHistoryForCustodian,
  updateCustodian: updateCustodian,
  refreshCustodian: refreshCustodian,
  fetchTickerDetails: fetchTickerDetails,
  showToastTip: showToastTip
};

export default connect(
  null,
  mapDispatchToProps
)(CustodianAssetCostComponent);
