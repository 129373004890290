import React from "react";
import styled from "styled-components";
import kuberaLogo from "assets/images/kubera_logo.svg";
import { websiteUrls } from "routes";

const Logo = styled.div`
  width: 69px;
  height: 11px;
  background-color: transparent;
  background-image: url(${kuberaLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

class KuberaLogo extends React.Component {
  render() {
    const { className, ...other } = this.props;

    return (
      <Logo
        className={className}
        {...other}
        onClick={e => {
          if (this.props.onClick) {
            this.props.onClick(e);
          } else {
            window.kuberaOpen(websiteUrls.WEB_HOMEPAGE);
          }
        }}
      />
    );
  }
}

export default KuberaLogo;
