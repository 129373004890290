import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { store, getCustodiansLinkedWithProviderAccountId } from "@kubera/common";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const Description = styled.div`
  margin-top: 2px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #333333;
`;

const ConnectionContainer = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

const ConnectionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #333333;
`;

const ConnectionDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.3);
`;

const OrText = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #333333;
  text-transform: uppercase;
`;

class SelectLinkedConnectionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleConnectionSelection = this.handleConnectionSelection.bind(this);
  }

  handleConnectionSelection(connection) {
    this.props.onConnectionSelection(connection);
  }

  getLinkedAccountsString(linkProviderAccountId) {
    const custodiansLinkedWithProviderAccountId = getCustodiansLinkedWithProviderAccountId(
      store.getState(),
      linkProviderAccountId
    );
    var string = "Added Accounts: ";

    for (const custodian of custodiansLinkedWithProviderAccountId) {
      string = string + `${custodian.name}, `;
    }
    return string.slice(0, -2);
  }

  render() {
    const connections = this.props.connections;

    return (
      <Container>
        <Title>{i18n.t("linkAccount.selectConnectionsTitle")}</Title>
        <Description>{i18n.t("linkAccount.selectConnectionsDescription")}</Description>
        {connections.map((connection, index) => {
          return (
            <ConnectionContainer key={index} onClick={e => this.handleConnectionSelection(connection)}>
              <ConnectionTitle>{connection.linkProviderName}</ConnectionTitle>
              <ConnectionDescription>
                {this.getLinkedAccountsString(connection.linkProviderAccountId)}
              </ConnectionDescription>
            </ConnectionContainer>
          );
        })}
        <OrText>{i18n.t("or")}</OrText>
        <ConnectionContainer onClick={e => this.handleConnectionSelection(null)}>
          <ConnectionTitle>{i18n.t("linkAccount.selectConnectionsNewConnection")}</ConnectionTitle>
        </ConnectionContainer>
      </Container>
    );
  }
}

export default SelectLinkedConnectionsComponent;
