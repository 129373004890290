export var store;
export var persistor;

export const setStore = newStore => {
  store = newStore;
};

export const setPersistor = newPersistor => {
  persistor = newPersistor;
};
