import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import {
  whiteLabelUserInfoSelector,
  wlManagersSelector,
  userTypes,
  userTypeToString,
  userTypeToDescriptionString,
  deleteWlManager,
  updateWlManager,
  archiveWlManager,
  unarchiveWlManager
} from "@kubera/common";
import AddUserDialogComponent from "components/account_settings/AddUserDialogComponent";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";
import { ReactComponent as DropDownIcon } from "assets/images/expandable_indicator.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import ToggleSwitch from "components/inputs/ToggleSwitch";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import DeferredPromise from "utilities/DeferredPromise";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
`;

const AddUserButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const UserDetailsItem = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: ${props => (props.isFirst === true ? "18px" : "-1px")};
  padding: 10px 20px 10px 15px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const UserDetailsContainer = styled.div`
  flex: 1
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const UserEmail = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const UserRoleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => (props.isEditable === true ? "pointer" : "")};
  pointer-events: ${props => (props.isEditable === true ? "" : "none")};
`;

const UserRole = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
  pointer-events: none;
`;

const UserRoleDropDownIndicator = styled(DropDownIcon)`
  width: 8px;
  height: 8px
  margin-left: 3px;
  margin-top: 2px;
  transform: rotate(0deg);
  pointer-events: none;
`;

const ArchiveUserSwitch = styled(ToggleSwitch)`
  margin-left: 20px;
  margin-top: 2px;
`;

const DeleteUserButton = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteUserIcon = styled(DeleteIcon)`
  width: 8px;
  height: 8px;
`;

class ManageUsersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddUserClick = this.handleAddUserClick.bind(this);
    this.handleAddUserDialogDismiss = this.handleAddUserDialogDismiss.bind(this);
    this.handleRoleDropDownClick = this.handleRoleDropDownClick.bind(this);
    this.handleDropDownSelection = this.handleDropDownSelection.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleUserArchiveStatusChange = this.handleUserArchiveStatusChange.bind(this);
    this.deleteBtnPromise = new DeferredPromise();

    this.state = {
      showAddUserDialog: false,
      isDeleteConfirmationOpen: false
    };

    this.dropDownContextMenuRef = React.createRef();
    this.contextMenuShownForUser = null;
  }

  handleAddUserClick(e) {
    this.setState({ showAddUserDialog: true });
  }

  handleAddUserDialogDismiss() {
    this.setState({ showAddUserDialog: false });
  }

  handleDropDownSelection(item) {
    const updatedManager = this.contextMenuShownForUser;

    if (updatedManager.type === item.id) {
      return;
    }
    updatedManager.type = item.id;
    this.props.updateWlManager(updatedManager);
  }

  async handleDeleteButtonClick(e, manager) {
    this.setState({
      isDeleteConfirmationOpen: true
    });
    const confirmResponse = await this.deleteBtnPromise.catch(err => {});

    if (confirmResponse) {
      this.props.deleteWlManager(manager);
    }
  }

  handleUserArchiveStatusChange(isArchived, manager) {
    if (isArchived === true) {
      this.props.archiveWlManager(manager);
    } else {
      this.props.unarchiveWlManager(manager);
    }
  }

  getRoleContextMenuItems(forUser) {
    return [
      [
        {
          id: userTypes.MANAGER,
          label: userTypeToString(userTypes.MANAGER),
          description: userTypeToDescriptionString(userTypes.MANAGER),
          selected: userTypes.MANAGER === forUser.type
        },
        {
          id: userTypes.ADMIN,
          label: userTypeToString(userTypes.ADMIN),
          description: userTypeToDescriptionString(userTypes.ADMIN),
          selected: userTypes.ADMIN === forUser.type
        }
      ]
    ];
  }

  handleRoleDropDownClick(event, user) {
    if (this.dropDownContextMenuRef.current.isVisible() === true) {
      this.dropDownContextMenuRef.current.dismiss();
    }

    this.contextMenuShownForUser = user;

    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = this.getRoleContextMenuItems(user);
    this.dropDownContextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 5,
      false,
      event.target
    );
  }

  getManagers() {
    const currentManager = this.props.wlManagers.find(
      manager => manager.userId === this.props.wlCurrentUserInfo.userId
    );
    const otherActiveManagers = this.props.wlManagers.filter(
      manager => !manager.userId === false && manager.userId !== this.props.wlCurrentUserInfo.userId
    );
    const otherInvitedManagers = this.props.wlManagers.filter(manager => !manager.userId === true);
    return [currentManager, ...otherActiveManagers, ...otherInvitedManagers];
  }

  canAddUser() {
    return (
      this.props.wlCurrentUserInfo.type === userTypes.OWNER || this.props.wlCurrentUserInfo.type === userTypes.ADMIN
    );
  }

  canEditUser(user) {
    return this.props.wlCurrentUserInfo.userId !== user.userId && user.type !== userTypes.OWNER;
  }

  isUserActive(user) {
    return !user.userId === false;
  }

  getUserNameString(user) {
    if (!user.userId === true) {
      return `${user.name} (Invited)`;
    } else if (user.userId === this.props.wlCurrentUserInfo.userId) {
      return `${user.name} (You)`;
    }
    return user.name;
  }

  deleteUserConfirmationResolveWith = val => {
    this.deleteBtnPromise.resolve(val);
    this.deleteBtnPromise = new DeferredPromise();
  };

  deleteConfirmationPositiveClick = () => {
    this.setState({
      isDeleteConfirmationOpen: false
    });

    this.deleteUserConfirmationResolveWith(true);
  };

  deleteConfirmationNegativeClick = () => {
    this.setState({
      isDeleteConfirmationOpen: false
    });

    this.deleteUserConfirmationResolveWith(false);
  };

  render() {
    const managers = this.getManagers();
    const canAddUser = this.canAddUser();
    const showAddUserDialog = this.state.showAddUserDialog;

    return (
      <Container className={this.props.className}>
        <TitleContainer>
          <Title>{i18n.t("manageUsers")}</Title>
          {canAddUser === true && <AddUserButton onClick={this.handleAddUserClick}>{i18n.t("addUser")}</AddUserButton>}
        </TitleContainer>
        {managers.map((manager, index) => (
          <UserDetailsItem isFirst={index === 0} key={index}>
            <UserDetailsContainer>
              <UserName>{this.getUserNameString(manager)}</UserName>
              <UserEmail>{manager.email}</UserEmail>
            </UserDetailsContainer>
            <UserRoleContainer
              isEditable={this.canEditUser(manager)}
              onClick={e => this.handleRoleDropDownClick(e, manager)}
            >
              <UserRole>{userTypeToString(manager.type)}</UserRole>
              {this.canEditUser(manager) === true && this.isUserActive(manager) === true && (
                <UserRoleDropDownIndicator />
              )}
            </UserRoleContainer>
            {this.canEditUser(manager) === true && this.isUserActive(manager) === true && (
              <ArchiveUserSwitch
                isChecked={!manager.tsArchive === true}
                onChange={checked => this.handleUserArchiveStatusChange(!checked, manager)}
              />
            )}
            {this.canEditUser(manager) === true && (
              <DeleteUserButton onClick={e => this.handleDeleteButtonClick(e, manager)}>
                <DeleteUserIcon />
              </DeleteUserButton>
            )}
          </UserDetailsItem>
        ))}
        {showAddUserDialog === true && <AddUserDialogComponent onDialogDismiss={this.handleAddUserDialogDismiss} />}
        <ContextMenu ref={this.dropDownContextMenuRef} width={233} onSelection={this.handleDropDownSelection} />
        {this.state.isDeleteConfirmationOpen === true && (
          <ConfirmationDialog
            title={i18n.t("accountSettings.manageModalDeleteTitle")}
            description={i18n.t("accountSettings.manageModalDeleteDesc")}
            positiveButtonTitle={i18n.t("delete")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.deleteConfirmationNegativeClick}
            handlePositiveButtonClick={this.deleteConfirmationPositiveClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  wlCurrentUserInfo: whiteLabelUserInfoSelector(state),
  wlManagers: wlManagersSelector(state)
});

const mapDispatchToProps = {
  deleteWlManager: deleteWlManager,
  updateWlManager: updateWlManager,
  archiveWlManager: archiveWlManager,
  unarchiveWlManager: unarchiveWlManager
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsersComponent);
