import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useDispatch } from "react-redux";

import { optInForBlackTrial } from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Dialog = styled(ConfirmationDialog)`
  width: 655px;
  padding-left: 15px;

  ol {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    row-gap: 6px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 0;
  }

  button {
    margin-bottom: 5px;

    &[data-cy="positiveButton"] {
      width: 170px;
    }

    &[data-cy="negativeButton"] {
      min-width: 119px;
      margin-left: 17px;
    }

    &[data-cy="tertiaryButton"] {
      min-width: 116px;
      margin-left: 17px;
    }
  }
`;

const OptInPaywall = ({ onPositiveButtonClick, onMoreDetailsClick, onDismiss = () => null }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handlePositiveButtonClick = () => {
    setIsLoading(true);

    dispatch(
      optInForBlackTrial(() => {
        setIsLoading(false);
        onPositiveButtonClick();
      })
    );
  };

  return (
    <Dialog
      title={i18n.t("blackPaywalls.confirmUpgradeTitle")}
      description={`<ol>${i18n.t("blackPaywalls.confirmUpgradeDesc")}</ol>`}
      positiveButtonTitle={i18n.t("blackPaywalls.optInBtn")}
      negativeButtonTitle={i18n.t("moreDetails")}
      tertiaryButtonTitle={i18n.t("cancel")}
      handlePositiveButtonClick={handlePositiveButtonClick}
      handleNegativeButtonClick={onMoreDetailsClick}
      handleTertiaryButtonClick={onDismiss}
      isLoading={isLoading}
      note={i18n.t("blackPaywalls.optInNote")}
    />
  );
};

export default OptInPaywall;
