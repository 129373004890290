import React from "react";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";

class ZaboReconnectDialog extends React.Component {
  static show = (history, location, isSupported) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.ZABO_RECONNECT}&${hashParams.SUPPORTED}=${isSupported}`
    });
  };

  constructor(props) {
    super(props);

    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
  }

  handleDialogDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  getTitle() {
    return this.props.supported === true ? i18n.t("zaboReconnectDialog.title") : i18n.t("zaboUnsupportedDialog.title");
  }

  getDescription() {
    return this.props.supported === true
      ? i18n.t("zaboReconnectDialog.description")
      : i18n.t("zaboUnsupportedDialog.description");
  }

  render() {
    return (
      <ConfirmationDialog
        canUserDismiss={true}
        title={this.getTitle()}
        description={this.getDescription()}
        positiveButtonTitle={i18n.t("okGotIt")}
        onDismiss={this.handleDialogDismiss}
        handlePositiveButtonClick={this.handleDialogDismiss}
      />
    );
  }
}

export default withRouter(ZaboReconnectDialog);
