import React, { useRef, useEffect, useContext, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import defaultTheme, { useTheme, dialogGridTheme } from "theme";
import { useSelector, useDispatch } from "react-redux";
import {
  currentPortfolioSelector,
  updateSectionBulk,
  updateCustodianBulk,
  updateSection,
  deleteSheet,
  archiveSection,
  useHistory
} from "@kubera/common";
import { GridThemeInsideDialog } from "utilities/Contexts";

import GridComponent from "components/grid/GridComponent";

const GridComponentWrapper = React.forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();

  const themeContext = useContext(GridThemeInsideDialog);
  const theme = useTheme();

  const dispatch = useDispatch();

  const currentPortfolio = useSelector(currentPortfolioSelector);

  const gridRef = useRef();
  const prevPortfolio = useRef();

  const handleUpdateCustodianBulk = custodians => {
    dispatch(updateCustodianBulk(custodians));
  };

  const handleUpdateSectionBulk = sections => {
    dispatch(updateSectionBulk(sections));
  };

  const handleDeleteSheet = sheetId => {
    dispatch(deleteSheet(sheetId));
  };

  const handleDeleteSection = (sheetId, sectionId) => {
    dispatch(archiveSection(sheetId, sectionId));
  };

  const handleSectionSort = sectionId => {
    dispatch(
      updateSection(currentPortfolio.id, props.gridData.sheets[props.gridData.currentSheetIndex].id, sectionId, {
        columnSortKey: null,
        columnSortOrder: null
      })
    );
  };

  useEffect(() => {
    if (!prevPortfolio.current || currentPortfolio.id !== prevPortfolio.current.id) {
      prevPortfolio.current = currentPortfolio;
      gridRef.current.init();
    }
  }, [currentPortfolio]);

  useImperativeHandle(ref, () => gridRef.current);

  if (themeContext === "default") {
    const modifiedTheme = { ...defaultTheme, ...dialogGridTheme(theme.mode) };
    return (
      <ThemeProvider theme={modifiedTheme}>
        <GridComponent
          {...props}
          updateCustodianBulk={handleUpdateCustodianBulk}
          updateSectionBulk={handleUpdateSectionBulk}
          removeSectionSort={handleSectionSort}
          onDeleteSheet={handleDeleteSheet}
          onDeleteSection={handleDeleteSection}
          history={history}
          location={location}
          ref={gridRef}
        />
      </ThemeProvider>
    );
  }

  return (
    <GridComponent
      {...props}
      updateCustodianBulk={handleUpdateCustodianBulk}
      updateSectionBulk={handleUpdateSectionBulk}
      removeSectionSort={handleSectionSort}
      onDeleteSheet={handleDeleteSheet}
      onDeleteSection={handleDeleteSection}
      history={history}
      location={location}
      ref={gridRef}
    />
  );
});

export default GridComponentWrapper;
