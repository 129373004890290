import { useEffect } from "react";
import { useSelector } from "react-redux";

import { accountPlanSelector, plansWithPriceSelector, currentPlansSelector } from "@kubera/common";

const useOptionsRadioButtonSelectionHandler = handleRadioButtonSelection => {
  const accountPlan = useSelector(accountPlanSelector);
  const plansWithPrice = useSelector(plansWithPriceSelector);
  const currentPlans = useSelector(currentPlansSelector);

  useEffect(() => {
    if (plansWithPrice.includes(accountPlan)) {
      handleRadioButtonSelection(accountPlan);
    } else {
      handleRadioButtonSelection(currentPlans[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountPlan, plansWithPrice, currentPlans]);
};

export default useOptionsRadioButtonSelectionHandler;
