import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useDispatch } from "react-redux";

import { setPreferredMFA, userSetMFA, MFA_TYPES } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";

const ComponentDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 680px;
  margin-top: 164px;
  padding: 50px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 30px;
  white-space: pre-wrap;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PrimaryBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
`;

const SecondaryBtn = styled(SecondaryButton)`
  display: inline-flex;
`;

const TurnOffModal = ({ isOpen = false, onRemove2FA = () => null, onDismiss = () => null }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState();

  const remove2FA = async () => {
    setIsLoading(true);
    await setPreferredMFA(MFA_TYPES.NONE);
    dispatch(userSetMFA(MFA_TYPES.NONE.toLowerCase()));
    onRemove2FA();
  };

  if (isOpen) {
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <ComponentDialog>
          <Title>{i18n.t("twoFA.modalTitle")}</Title>
          <Desc>{i18n.t("twoFA.modalDesc")}</Desc>
          <BtnBlock>
            <PrimaryBtn title={i18n.t("twoFA.modalPrimaryBtn")} onClick={remove2FA} isLoading={isLoading} />
            <SecondaryBtn title={i18n.t("goBackLink")} onClick={onDismiss} disabled={isLoading} />
          </BtnBlock>
        </ComponentDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default TurnOffModal;
