import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LineChartComponent from "components/charts/LineChartComponent";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import {
  currentPortfolioSelector,
  convertCurrency,
  getMonthFromDate,
  currentPortfolioCurrencySelector,
  chartTimeRange,
  recapReportValueSelector,
  getNetWorthChartTimeRangeForPortfolio,
  recapDataCurrencySelector,
  getDateInKuberaFormat
} from "@kubera/common";
import ChangeLabel from "components/labels/ChangeLabel";
import i18n from "i18next";

const Container = styled.div`
  display: flex;
  flex-direction : column
  height: 370px;
  margin-bottom: 5px;
  box-sizing: border-box;
  border: ${props => props.theme.netWorthContainerBR};
  background: ${props => props.theme.netWorthContainerBG};
  cursor: pointer
`;

const LineChartConatiner = styled.div`
  display: flex;
  flex-direction: column
  padding: 20px;
`;

const LineChart = styled(LineChartComponent)`
  margin-top: 32px;
  padding-left: 5px;
  padding-right: 5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;

const TitleContainer = styled.div`
display: flex
flex-direction : column`;

const Title = styled.div`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ActionButton = styled.button`
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const LineChartOptionButton = styled(ActionButton)`
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
  margin-right: -12px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  display: flex;
`;

const ValueSubText = styled.div`
  margin-left: 4px;
  padding-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ChartHeader = styled.div`
  display: flex;
  //margin-top: 18px;
`;

const ChartChangeContainer = styled.div`
  visibility: ${props => (!props.hidden === false ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ChartChangeTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

const ChartChangeLabel = styled(ChangeLabel)`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  .child-container {
    justify-content: flex-start;
  }
`;

const DiyDashboardLineChartComponent = props => {
  const {
    index,
    id,
    reportLineChartData,
    handleRemoveChartSelection,
    reportId,
    handleChartClick,
    handleRearrangeChartSelection,
    shouldShowContextMenu,
    hideRearrangeOption
  } = props;
  const [currentChartDataPointHoverIndex, setCurrentChartDataPointHoverIndex] = useState(null);
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const reportCurrentValue = useSelector(state => recapReportValueSelector(state, reportId));
  const recapCurrency = useSelector(recapDataCurrencySelector);
  const selectedChartTimeRange = useSelector(state => getNetWorthChartTimeRangeForPortfolio(state, currentPortfolio));
  const custodianValue = convertCurrency(reportCurrentValue, recapCurrency, portfolioCurrency);
  const contextMenuRef = useRef();

  const handleContextMenuClick = event => {
    if (contextMenuRef.current.isVisible() === true) {
      contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = hideRearrangeOption
      ? [[contextMenuItemType.REMOVE]]
      : [[contextMenuItemType.REARRANGE, contextMenuItemType.REMOVE]];

    contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
  };

  const getLineChartData = lineChartData => {
    if ((!lineChartData === true) | (!lineChartData.chart === true)) {
      return null;
    }

    const chartData = lineChartData.chart[selectedChartTimeRange];
    const chartDataPoints = chartData.data;
    if (selectedChartTimeRange !== chartTimeRange.YTD && chartDataPoints.length < 7) {
      return null;
    }

    const portfolioTicker = currentPortfolio.currency;
    const data = {
      labels: chartDataPoints.map((dataPoint, index) => {
        return getLabelDateString(getDateInKuberaFormat(dataPoint.date));
      }),
      datasets: [
        {
          data: chartDataPoints.map((dataPoint, index) => {
            let dataPointVal = dataPoint.value;
            return convertCurrency(dataPointVal, chartData.currency, portfolioTicker);
          })
        }
      ],
      currency: currentPortfolio.currency
    };
    return data;
  };

  const getLabelDateString = date => {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  };

  const getPreviousDaysValue = chartData => {
    if (!chartData === true || !chartData.chart === true) {
      return null;
    }
    const weeklyData = chartData.chart[chartTimeRange.WEEKLY];
    if (!weeklyData === true || weeklyData.data.length < 2) {
      return null;
    }
    return convertCurrency(weeklyData.data[weeklyData.data.length - 2].value, weeklyData.currency, portfolioCurrency);
  };

  const getChartTimeRangeMenuItems = () => {
    return [
      [
        { id: chartTimeRange.WEEKLY, label: "1 Week" },
        { id: chartTimeRange.MONTHLY, label: "1 Month" },
        { id: chartTimeRange.QUARTERLY, label: "3 Months" },
        { id: chartTimeRange.YEARLY, label: "1 Year" },
        { id: chartTimeRange.YTD, label: "YTD" },
        { id: chartTimeRange.ALL, label: "All-time" }
      ]
    ];
  };

  const getCurrentlySelectedChartTimeRangeText = () => {
    const menuItems = getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedChartTimeRange) {
        return item.label;
      }
    }
  };

  const getStartValueForCustodianForSelectedTimeRange = (chartData, reportLineChartData) => {
    if (!chartData === true || !reportLineChartData.chart[selectedChartTimeRange].data[0]) {
      return null;
    }
    const timeRangeDataPoint = reportLineChartData.chart[selectedChartTimeRange];
    return convertCurrency(timeRangeDataPoint.data[0].value, timeRangeDataPoint.currency, portfolioCurrency);
  };

  const valueForCustodian = (considerChartSelection = true, reportLineChartData, selectedCustodianValue) => {
    const currentlySelectedChartDataPoint = getCurrentlySelectedChartDataPoint(reportLineChartData);
    if (considerChartSelection === true && !currentlySelectedChartDataPoint === false) {
      const chartData = reportLineChartData.chart[selectedChartTimeRange];
      return convertCurrency(currentlySelectedChartDataPoint.value, chartData.currency, portfolioCurrency);
    } else {
      return selectedCustodianValue;
    }
  };

  const getCurrentlySelectedChartDataPoint = reportLineChartData => {
    if (currentChartDataPointHoverIndex === null) {
      return null;
    }

    const dataPoints = reportLineChartData.chart[selectedChartTimeRange].data;
    return dataPoints[currentChartDataPointHoverIndex];
  };

  // const chartData = getLineChartData(reportLineChartData);
  const [chartData, setChartData] = useState(getLineChartData(reportLineChartData));
  const currentChartTimeRangeText = getCurrentlySelectedChartTimeRangeText();
  const value = valueForCustodian(true, reportLineChartData, custodianValue);
  const startValue = getStartValueForCustodianForSelectedTimeRange(chartData, reportLineChartData);
  const handleChartHoverSelection = index => {
    const dataPoints = reportLineChartData.chart[selectedChartTimeRange].data;
    let hoverIndex = index;

    if (index === dataPoints.length - 1) {
      hoverIndex = null;
    }
    setCurrentChartDataPointHoverIndex(hoverIndex);
  };

  useEffect(() => {
    setChartData(getLineChartData(reportLineChartData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLineChartData]);

  if (!chartData === true) {
    return null;
  }

  return (
    <Container
      onClick={() => {
        handleChartClick(reportLineChartData.id);
      }}
    >
      <LineChartConatiner>
        <HeaderContainer>
          <TitleContainer>
            <Title>{reportLineChartData.title}</Title>
            <ValueContainer>
              <ValueLabel
                value={value}
                currency={portfolioCurrency}
                currencyFontSize={13}
                valueFontSize={24}
                height={"29px"}
                showZero={true}
                preventAnimation
              />
              {!chartData.totalSubText === false && <ValueSubText>{chartData.totalSubText}</ValueSubText>}
            </ValueContainer>
          </TitleContainer>
          {shouldShowContextMenu && (
            <LineChartOptionButton
              onClick={e => {
                e.stopPropagation();
                handleContextMenuClick(e);
              }}
            />
          )}
        </HeaderContainer>

        <ChartHeader>
          <ChartChangeContainer hidden={!chartData === true}>
            <ChartChangeTitle>{i18n.t("dayChange")}</ChartChangeTitle>
            <ChartChangeLabel
              currency={portfolioCurrency}
              startValue={getPreviousDaysValue(reportLineChartData)}
              endValue={Math.floor(custodianValue)}
              alignPosition="left"
            />
          </ChartChangeContainer>
          <ChartChangeContainer hidden={!chartData === true}>
            <ChartChangeTitle>{currentChartTimeRangeText}</ChartChangeTitle>
            <ChartChangeLabel
              currency={portfolioCurrency}
              startValue={startValue}
              endValue={Math.floor(custodianValue)}
              alignPosition="left"
            />
          </ChartChangeContainer>
        </ChartHeader>
        <ContextMenu
          ref={contextMenuRef}
          onSelection={selectedItem => {
            if (selectedItem.id === contextMenuItemType.REMOVE.id) {
              handleRemoveChartSelection(reportLineChartData.id);
            } else if (selectedItem.id === contextMenuItemType.REARRANGE.id) {
              handleRearrangeChartSelection();
            }
          }}
        />
      </LineChartConatiner>
      <LineChart
        key={index}
        id={id}
        data={chartData}
        chartHeight={170}
        adjustPageScroll={true}
        onHoverOverSection={handleChartHoverSelection}
        showBuffer={true}
        chartPadding={{
          top: 30
        }}
      />
    </Container>
  );
};
export default DiyDashboardLineChartComponent;
