import React from "react";
import styled from "styled-components";
import i18n from "locale/i18n";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 9px;
  padding: 8px 16px 8px 0;
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${props => props.theme.gridSectionAddActionColor};
`;

const NewAction = ({ onClick = () => null, isReadOnly = false }) => {
  if (!isReadOnly) {
    return (
      <Wrapper onClick={onClick}>
        <Text>{i18n.t("more")}</Text>
      </Wrapper>
    );
  }

  return null;
};

export default NewAction;
