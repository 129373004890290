import React from "react";
import { connect } from "react-redux";

import { initialFetchSuccessSelector } from "@kubera/common";

const mapStateToProps = state => ({
  initialFetchSuccess: initialFetchSuccessSelector(state)
});

const mapDispatchToProps = {};

const withInitialFetchHandle = WrappedComponent => {
  class HandleInitialFetchComponent extends React.Component {
    shouldComponentUpdate(nextProps) {
      if (!nextProps.initialFetchSuccess) {
        return false;
      }

      return true;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(HandleInitialFetchComponent);
};

export default withInitialFetchHandle;
