import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import i18n from "i18next";
import RadioButton from "components/inputs/RadioButton";
import { DialogUpdateFrequency } from "components/grid/GridDataModel";
import { connect } from "react-redux";
import { currentPortfolioSelector, updateSheet, sheetSelector } from "@kubera/common";

const FrequencyDialog = styled(Dialog)`
  width: 495px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 50px 60px 50px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const Description = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  white-space: pre-wrap;
  font-feature-settings: "ss01" on;

  a {
    color: #000;
  }
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const RadioOptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 26px;
`;

const RadioOptionDescription = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.4);
`;

const RadioOption = styled(RadioButton)``;

class SheetUpdateFrequencyDialog extends React.Component {
  static show = (history, location, sheetId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.SHEET_FREQUENCY}&${hashParams.ID}=${sheetId}`
    });
  };

  constructor(props) {
    super(props);

    this.state = { selectedFrequencyValue: 0 };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleRadioButtonSelection = this.handleRadioButtonSelection.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, selectedFrequencyValue: this.props.sheet.updateFrequency });
  }

  handleOverlayDismiss() {
    this.props.updateSheet(this.props.portfolio.id, this.props.sheetId, {
      updateFrequency: this.state.selectedFrequencyValue
    });
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  getFrequencyOptions() {
    return DialogUpdateFrequency;
  }

  handleRadioButtonSelection(value) {
    this.setState({ ...this.state, selectedFrequencyValue: value });
  }

  render() {
    const frequencyOptions = this.getFrequencyOptions();
    const selectedFrequencyValue = this.state.selectedFrequencyValue;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <FrequencyDialog className={this.props.className}>
          <Container>
            <Title>{i18n.t("sheetUnupdateFrequencyDialog.title")}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: i18n.t("sheetUnupdateFrequencyDialog.description")
              }}
            />
            <RadioButtonsContainer>
              {frequencyOptions.map((option, index) => {
                return (
                  <RadioOptionContainer key={index}>
                    <RadioOption
                      label={option.label}
                      value={option.value}
                      checked={selectedFrequencyValue === option.value}
                      onChange={this.handleRadioButtonSelection}
                    />
                    {option.description && <RadioOptionDescription>{`(${option.description})`}</RadioOptionDescription>}
                  </RadioOptionContainer>
                );
              })}
            </RadioButtonsContainer>
          </Container>
        </FrequencyDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  portfolio: currentPortfolioSelector(state),
  sheet: sheetSelector(state, props.sheetId)
});

const mapDispatchToProps = {
  updateSheet: updateSheet
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SheetUpdateFrequencyDialog));
