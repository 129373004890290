import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { siteConfigSelector, isMobile, isPwaApp, isiOS, isiOSSafari, isChrome } from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import slideAnimation from "utilities/slideAnimation";

import iphoneShareIcon from "assets/images/pwaios.png";
import iphoneAndriodShareIcon from "assets/images/pwaandroid.png";
import { ReactComponent as kuberaLogo } from "assets/images/kubera_logo.svg";

const Overlay = styled(DialogOverlay)`
  z-index: 10001;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const OverlayContent = styled(Dialog)`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  background: #1d1d1d;
  padding-top: 42px;
  animation: ${slideAnimation};
`;

const OverlayMessageBlk = styled.div`
  padding: 0 28px;
  color: #fff;
`;

const OverlayMessageTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: ${({ lineHeight }) => `${lineHeight || 37}px`};
  white-space: pre-wrap;
`;

const OrderedList = styled.ol`
  font-size: 18px;
  line-height: 30px;
  padding: 0 24px;
  margin-bottom: 49px;
`;

const OrderedListItems = styled.li``;

const ImgBlock = styled.img`
  width: ${props => (props.isHidden ? "1px" : "100%")};
  height: ${props => (props.isHidden ? "1px" : null)};
  opacity: ${props => (props.isHidden ? "0" : null)};
  align-self: flex-end;
`;

const KuberaLogo = styled(kuberaLogo)`
  width: 102px;
  margin-bottom: 33px;
  margin-left: -11px;
  height: 12px;

  path {
    fill: #fff;
  }
`;

const OpenInstalledBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 169px;
  height: 56px;
  border-radius: 40px;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  text-decoration: none;
`;

const InstallBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 169px;
  height: 56px;
  border-radius: 40px;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 30px;
`;

var deferredPromptCaptured = null;
if (isMobile() && !isPwaApp()) {
  function captureInstallPromptIfFiredEarly(e) {
    e.preventDefault();
    // Handling this setting of deferred prompt in this file itself so all logic stays in this file
    deferredPromptCaptured = e;
  }

  window.addEventListener("beforeinstallprompt", captureInstallPromptIfFiredEarly);
}

const InstallPWAComponent = ({ direction }) => {
  const siteConfig = useSelector(siteConfigSelector);
  const siteName = siteConfig ? siteConfig.name : "";

  const deferredPrompt = useRef(deferredPromptCaptured);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [isInstalling, setIsInstalling] = useState(false);
  const [showInstallButton, setShowInstallButton] = useState(deferredPromptCaptured);
  const [showAndroidMessage, setShowAndroidMessage] = useState(false);

  const handleInstallClick = () => {
    if (isInstalling) return;
    deferredPrompt.current.prompt();
    handleInstallSuccess();
  };

  // Check in intervals if install was successfull
  const handleInstallSuccess = e => {
    setTimeout(() => {
      if (isPWAInstalled) return;
      checkIfAppIsAlreadyInstalled();
      handleInstallSuccess();
    }, 1000);
  };
  /////////////////////////////////////////////////

  // Check if the app is already installed
  const checkIfAppIsAlreadyInstalled = () => {
    if ("getInstalledRelatedApps" in navigator) {
      navigator.getInstalledRelatedApps().then(relatedApps => {
        if (relatedApps.length > 0) {
          setIsPWAInstalled(true);
          setIsInstalling(false);
        }
      });
    }
  };
  //////////////////////////////////////////////////

  useEffect(() => {
    checkIfAppIsAlreadyInstalled();

    // Delay showing on Android
    setTimeout(() => {
      setShowAndroidMessage(true);
    }, 1500);

    // Handling this setting of deferred prompt in this file itself so all logic stays in this file
    // This will set the deferred propt to any captured early
    if (deferredPromptCaptured) {
      deferredPrompt.current = deferredPromptCaptured;
      setShowInstallButton(true);
    }

    const handleBeforeInstallPrompt = e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt.current = deferredPromptCaptured || e;
      setShowInstallButton(true);
      deferredPrompt.current.userChoice.then(choiceResult => {
        if (choiceResult?.outcome === "accepted") {
          setIsInstalling(true);
        }
        if (!choiceResult || choiceResult.outcome === "dismissed") {
          setIsInstalling(false);
        }
      });
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  if (isiOSSafari()) {
    return (
      <Overlay>
        <OverlayContent direction={direction}>
          <OverlayMessageBlk>
            <KuberaLogo src={kuberaLogo} />
            <OverlayMessageTitle>{i18n.t("installPWA.modalTitle").replace(/%s%/g, siteName)}</OverlayMessageTitle>
            <OrderedList>
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem1")
                }}
              />
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem2")
                }}
              />
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem3")
                }}
              />
            </OrderedList>
          </OverlayMessageBlk>
          <ImgBlock src={iphoneShareIcon} alt="Iphone Share" />
        </OverlayContent>
      </Overlay>
    );
  }

  if (isiOS()) {
    return (
      <Overlay>
        <OverlayContent direction={direction}>
          <OverlayMessageBlk>
            <KuberaLogo src={kuberaLogo} />
            <OverlayMessageTitle>{i18n.t("installPWA.modalTitle").replace(/%s%/g, siteName)}</OverlayMessageTitle>
            <OrderedList>
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem0").replace(/%s%/, window.location.host)
                }}
              />
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem1")
                }}
              />
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem2")
                }}
              />
              <OrderedListItems
                dangerouslySetInnerHTML={{
                  __html: i18n.t("iosInstallPWA.orderedListItem3")
                }}
              />
            </OrderedList>
          </OverlayMessageBlk>
          <ImgBlock src={iphoneShareIcon} alt="Iphone Share" />
        </OverlayContent>
      </Overlay>
    );
  }

  if (isPWAInstalled) {
    return (
      <Overlay>
        <OverlayContent direction={direction}>
          <OverlayMessageBlk>
            <KuberaLogo src={kuberaLogo} />
            <OverlayMessageTitle lineHeight={36}>
              {i18n.t("installPWA.openKuberaModalTitle").replace(/%s%/g, siteName)}
            </OverlayMessageTitle>
            <OpenInstalledBtn rel="noopener noreferrer" target="_blank" href={window.location.origin}>
              Open Kubera
            </OpenInstalledBtn>
          </OverlayMessageBlk>
          <ImgBlock src={iphoneAndriodShareIcon} alt="Android Share" />
        </OverlayContent>
      </Overlay>
    );
  }

  if (isChrome()) {
    return (
      <Overlay>
        <OverlayContent direction={direction}>
          <OverlayMessageBlk>
            <KuberaLogo src={kuberaLogo} />
            <OverlayMessageTitle>{i18n.t("installPWA.modalTitle").replace(/%s%/g, siteName)}</OverlayMessageTitle>
            {!showAndroidMessage ? null : showInstallButton ? (
              <InstallBtn onClick={handleInstallClick}>{isInstalling ? "Installing..." : "Install"}</InstallBtn>
            ) : (
              <OrderedList>
                <OrderedListItems
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("androidInstallPWA.chromrOrderedListItem1")
                  }}
                />
                <OrderedListItems
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("androidInstallPWA.chromrOrderedListItem2")
                  }}
                />
                <OrderedListItems
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("androidInstallPWA.chromrOrderedListItem3")
                  }}
                />
              </OrderedList>
            )}
          </OverlayMessageBlk>
          <ImgBlock src={iphoneAndriodShareIcon} alt="Android Share" />
        </OverlayContent>
      </Overlay>
    );
  }

  return (
    <Overlay>
      <OverlayContent direction={direction}>
        <OverlayMessageBlk>
          <KuberaLogo src={kuberaLogo} />
          <OverlayMessageTitle>{i18n.t("installPWA.modalTitle").replace(/%s%/g, siteName)}</OverlayMessageTitle>
          <OrderedList>
            <OrderedListItems
              dangerouslySetInnerHTML={{
                __html: i18n.t("androidInstallPWA.orderedListItem1").replace(/%s%/, window.location.host)
              }}
            />
            <OrderedListItems
              dangerouslySetInnerHTML={{
                __html: i18n.t("androidInstallPWA.orderedListItem2")
              }}
            />
          </OrderedList>
        </OverlayMessageBlk>
        <ImgBlock src={iphoneAndriodShareIcon} alt="Android Share" />
      </OverlayContent>
    </Overlay>
  );
};

export default InstallPWAComponent;
