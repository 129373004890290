import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import { isMobile } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";

const isMobileDevice = isMobile();

const Container = isMobileDevice
  ? styled(DialogOverlay)`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;

      & > div {
        padding: 0 32px;
      }

      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const DeclinedDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${isMobileDevice ? "100%" : "680px"};
  margin-top: 164px;
  padding: ${isMobileDevice ? "32px 26px" : "50px"};
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: ${isMobileDevice ? "2px" : "5px"};
`;

const Desc = styled.div`
  font-size: ${isMobileDevice ? "14px" : "13px"};
  line-height: 150%;
  margin-bottom: ${isMobileDevice ? "90px" : "30px"};
  white-space: pre-wrap;
  font-feature-settings: "ss01" on;

  a {
    color: ${props => props.theme.linkColor};
  }
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CloseModalBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
`;

const AccountDeclinedCardModal = ({
  isOpen = false,
  message = i18n.t("accountCardDeclined.description"),
  onDismiss = () => null
}) => {
  if (isOpen) {
    return (
      <Container onDismiss={onDismiss}>
        <DeclinedDialog>
          <Title>{i18n.t("accountCardDeclined.title")}</Title>
          <Desc
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
          <BtnBlock>
            <CloseModalBtn title={i18n.t("ok")} onClick={onDismiss} />
          </BtnBlock>
        </DeclinedDialog>
      </Container>
    );
  }

  return null;
};

export default AccountDeclinedCardModal;
