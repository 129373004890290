import React from "react";
import styled from "styled-components";

const MaxItemsPerRow = 3;

const Container = styled.div`
  position: relative;
  margin-left: -13px;
  margin-right: -13px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 13px;
    right: 13px;
    bottom: 0;
    content: "";
  }
`;

const ProviderTable = styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
  border: 0;
  margin: 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 13px 24px;
`;

const ProviderTableBody = styled.tbody``;

const ProviderRow = styled.tr`
  padding-top: 24px;
`;

const ProviderCell = styled.td`
  height: 46px;
  padding: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
`;

const ProviderLogo = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 46px;
  text-align: center;
`;

const SearchHint = styled.div`
  margin-left: 13px;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  white-space: pre-wrap;
`;

class PopularProvidersListComponent extends React.Component {
  getProviderRows() {
    const providers = this.props.providers;
    var rows = [];
    var currentRow = [];

    for (const provider of providers) {
      if (currentRow.length === MaxItemsPerRow) {
        rows.push(currentRow);
        currentRow = [];
      }
      currentRow.push(provider);
    }
    if (currentRow.length <= MaxItemsPerRow) {
      rows.push(currentRow);
    }
    while (currentRow.length < MaxItemsPerRow) {
      currentRow.push(null);
    }
    return rows;
  }

  render() {
    const providerRows = this.getProviderRows();
    const searchHint = this.props.searchHint;
    return (
      <Container disabled={this.props.disabled === true}>
        <ProviderTable>
          <ProviderTableBody>
            {providerRows.map((row, index) => {
              return (
                <ProviderRow key={index}>
                  {row.map((provider, index) => {
                    const logoUrl = this.props.isProviderTypeCoin
                      ? "/images/popular_cryptocoin_icons/"
                      : "/images/provider_logo_icons/";
                    if (!provider === true) {
                      return <ProviderCell key={index}></ProviderCell>;
                    } else {
                      const { name = "", symbol = "" } = provider;
                      const altText = `${name}${symbol ? ` (${symbol})` : ""}`;
                      return (
                        <ProviderCell key={index}>
                          <ProviderLogo
                            src={process.env.PUBLIC_URL + logoUrl + provider.icon}
                            alt={altText}
                            onClick={event => this.props.onClick(provider)}
                          />
                        </ProviderCell>
                      );
                    }
                  })}
                </ProviderRow>
              );
            })}
          </ProviderTableBody>
        </ProviderTable>
        {searchHint && <SearchHint>{searchHint}</SearchHint>}
      </Container>
    );
  }
}

export default PopularProvidersListComponent;
