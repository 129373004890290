import React from "react";
import styled from "styled-components";
import theme from "theme";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { hashParams, modalValues, queryParams } from "routes";
import {
  getQueryParams,
  getMetaKeyName,
  searchPortfolioForText,
  currentPortfolioSelector,
  getTickerUsingId,
  getTickerUsingShortName,
  recentSearchesSelector,
  updateUserPreferences,
  updateSection,
  userPreferencesSelector,
  refreshCustodianTimeSelector,
  portfolioLastForceUpdateTsSelector
} from "@kubera/common";
import SearchInput from "components/inputs/SearchInput";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import CustodianDetailsComponentExports from "components/custodian_details/CustodianDetailsComponentExports";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { ReactComponent as LinkedIcon } from "assets/images/linked_account_icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/search_icon.svg";
import { ReactComponent as ClearIcon } from "assets/images/close.svg";
import DashboardComponentExports, { category } from "components/dashboard/DashboardComponentExports";

const SearchDialog = styled(Dialog)`
  width: 870px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-right: 5px;
`;

const ShortCut = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  font-feature-settings: "ss01" on;
  color: ${theme.searchInputPlaceholderColor};
`;

const SearchBox = styled(SearchInput)`
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: #ffffff;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Result = styled.div`
  display: flex;
  padding: 12px 18px 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "#ffffff")};
  cursor: pointer;
`;

const ResultTip = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: pre;
  color: rgba(0, 0, 0, 0.6);
`;

const ResultDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ResultName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultMeta = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const LinkedCustodianIcon = styled(LinkedIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
`;

const ResultDescription = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultPath = styled.div`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    text-decoration: underline;
  }
`;

const ResultValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ResultValue = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultValueOriginalCurrency = styled(CurrencyLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultTotalContainer = styled.div`
  padding: 7px 18px 7px 18px;
  background: rgba(0, 0, 0, 0.6);
`;

const ResultTotal = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "liga" off;
  color: #ffffff;
`;

const RecentSearchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const RecentSearchesTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
  margin-left: 10px;
`;

const RecentSearchTermContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 10px;
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "transparent")};
  cursor: pointer;
`;

const RecentSearchIcon = styled(SearchIcon)`
  width: 12px;
  height: 12px;
`;

const RecentSearchTerm = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-left: 10px;
`;

const ClearRecentSearchButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -9px;
  margin-bottom: -9px;
  cursor: pointer;
`;

const ClearRecentSearchIcon = styled(ClearIcon)`
  width: 8px;
  height: 8px;
  color: #000;

  path {
    fill: #aaaaaa;
  }
`;

const IndexingMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #00000099;
  margin-top: 16px;
  padding-left: 2px;
`;

const IndexingMessageTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const IndexingMessageDesc = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

class SearchPortfolioComponent extends React.Component {
  static show = (history, location) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.SEARCH}`
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      searchResults: null,
      currentlySelectedResultIndex: null,
      portfolioLastForceUpdateTs: props.portfolioLastForceUpdateTs,
      showIndexing: false
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResultInteraction = this.handleResultInteraction.bind(this);
    this.handleRecentSearchResultClick = this.handleRecentSearchResultClick.bind(this);
    this.handleRecentSearchClearClick = this.handleRecentSearchClearClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.portfolioLastForceUpdateTs || props.portfolioLastForceUpdateTs !== state.portfolioLastForceUpdateTs) {
      return {
        portfolioLastForceUpdateTs: props.portfolioLastForceUpdateTs,
        showIndexing: true
      };
    }

    if (props.refreshCustodianTime) {
      return {
        showIndexing: false
      };
    }

    return null;
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);

    const params = getQueryParams(this.props.location);
    const querySearchText = !params === false && !params[queryParams.QUERY] === false ? params[queryParams.QUERY] : "";

    if (!querySearchText === false) {
      this.setState({ searchText: querySearchText });
      this.handleSearchInputChange(querySearchText);

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(queryParams.QUERY);
      this.props.history.replace({ ...this.props.location, search: searchParams.toString() });
    }
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      this.selectPreviousResult();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown") {
      this.selectNextResult();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      if (!this.state.searchResults === true) {
        this.handleRecentSearchResultClick(e, this.props.recentSearches[this.state.currentlySelectedResultIndex]);
      } else {
        const result = this.state.searchResults[this.state.currentlySelectedResultIndex];
        this.handleResultInteraction(result, e.shiftKey === true);
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  handleResultInteraction(result, openPath) {
    this.updateRecentSearches();

    if (!result === true) {
      return;
    }

    if (result.isCustodian === true) {
      if (result.section.expanded === 0) {
        this.expandSection(result.section);
      }

      const isCustodianHidden = !result.parentId === false && result.hidden === 1;
      const custodianId = isCustodianHidden === true ? result.parentId : result.id;
      const path = DashboardComponentExports.getPathnameForCategory(result.sheet.category);
      if (openPath === true) {
        const hash = `${hashParams.SHEET_ID}=${result.sheet.id}&${hashParams.SECTION_ID}=${result.sectionId}&${hashParams.ID}=${custodianId}`;
        this.props.history.replace({ ...this.props.location, pathname: path, hash: hash });
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(queryParams.QUERY, this.state.searchText);

        this.props.history.replace({ ...this.props.location, search: searchParams.toString() });

        searchParams.delete(queryParams.QUERY);
        CustodianDetailsComponentExports.show(
          this.props.history,
          this.props.location,
          custodianId,
          false,
          isCustodianHidden ? result.id : null,
          {
            pathname: path,
            search: searchParams.toString()
          }
        );
      }
    } else if (result.isSheet === true) {
      const hash = `${hashParams.SHEET_ID}=${result.id}`;
      const path = DashboardComponentExports.getPathnameForCategory(result.category);
      this.props.history.replace({ ...this.props.location, pathname: path, hash: hash });
    } else if (result.isSection === true) {
      if (result.expanded === 0) {
        this.expandSection(result);
      }

      const hash = `${hashParams.SHEET_ID}=${result.sheetId}&${hashParams.SECTION_ID}=${result.id}`;
      const path = DashboardComponentExports.getPathnameForCategory(result.sheet.category);
      this.props.history.replace({ ...this.props.location, pathname: path, hash: hash });
    }
  }

  updateRecentSearches() {
    const sanitizedSearchTerm = this.state.searchText.trim().toLowerCase();
    const recentSearchIndex = this.props.recentSearches.findIndex(
      term => term.trim().toLowerCase() === sanitizedSearchTerm
    );
    var newRecentSearches = [];

    if (recentSearchIndex === -1) {
      newRecentSearches = [this.state.searchText, ...this.props.recentSearches];
    } else {
      newRecentSearches = [
        this.state.searchText,
        ...this.props.recentSearches.filter(item => item.trim().toLowerCase() !== sanitizedSearchTerm)
      ];
    }

    if (newRecentSearches.length > 10) {
      newRecentSearches = newRecentSearches.slice(0, 10);
    }

    const recentSearchesMap = this.props.userPreferences.recentSearchesPortfolioMap;
    recentSearchesMap[this.props.portfolioId] = newRecentSearches;
    this.props.updateUserPreferences({
      recentSearchesPortfolioMap: recentSearchesMap
    });
  }

  expandSection(section) {
    this.props.updateSection(this.props.currentPortfolio.id, section.sheetId, section.id, { expanded: 1 });
  }

  selectNextResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;

    if (!this.state.searchResults === true) {
      if (currentIndex === this.props.recentSearches.length - 1) {
        return;
      }
    } else {
      if (currentIndex === this.state.searchResults.length - 1) {
        return;
      }
    }
    this.setState({ currentlySelectedResultIndex: currentIndex === null ? 0 : currentIndex + 1 });
  }

  selectPreviousResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;
    if (currentIndex === null || currentIndex === 0) {
      return;
    }
    this.setState({ currentlySelectedResultIndex: currentIndex - 1 });
  }

  selectResult(index) {
    this.setState({ currentlySelectedResultIndex: index });
  }

  handleSearchInputChange(searchText) {
    this.setState({ searchText: searchText });

    if (!this.searchTimer === false) {
      clearTimeout(this.searchTimer);
    }

    if (!searchText === true) {
      this.setState({ searchText: searchText, searchResults: null, currentlySelectedResultIndex: null });
      return;
    } else {
      this.setState({ searchText: searchText });
    }

    this.searchTimer = setTimeout(() => {
      this.props.searchPortfolioForText(searchText, undefined, data => {
        if (data.searchText !== searchText) {
          return;
        }
        this.setState({ searchResults: data.results, currentlySelectedResultIndex: null });
      });
      this.searchTimer = null;
    }, 300);
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  handleRecentSearchResultClick(e, searchText) {
    this.handleSearchInputChange(searchText);
  }

  handleRecentSearchClearClick(e, searchText) {
    const recentSearchesMap = this.props.userPreferences.recentSearchesPortfolioMap;
    recentSearchesMap[this.props.portfolioId] = recentSearchesMap[this.props.portfolioId].filter(
      item => item !== searchText
    );
    this.props.updateUserPreferences({
      recentSearchesPortfolioMap: recentSearchesMap
    });
  }

  getResultPath(result) {
    if (!result === true) {
      return "";
    }
    if (result.isCustodian === true) {
      if (!result.parentId === false && result.hidden === 1) {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        } / ${result.parent.name} >`;
      } else if (result.sheet.category === category.INSURANCE) {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.section.name} >`;
      } else {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        } >`;
      }
    } else if (result.isSection === true) {
      return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} >`;
    } else if (result.isSheet === true) {
      return `${this.getCategoryNameForCategory(result.category)} >`;
    }
    return "";
  }

  getCategoryNameForCategory(categoryName) {
    if (categoryName === category.ASSET) {
      return i18n.t("assets");
    } else if (categoryName === category.DEBT) {
      return i18n.t("debts");
    } else if (categoryName === category.INSURANCE) {
      return i18n.t("insurance");
    }
    return null;
  }

  getResultsTotal(results) {
    return results.reduce((total, temp) => total + (temp.isCustodian === true ? temp.total : 0), 0);
  }

  render() {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <SearchDialog>
          <Container>
            <TitleContainer>
              <Title>{i18n.t("search")}</Title>
              <ShortCut>{`${getMetaKeyName()} G`}</ShortCut>
            </TitleContainer>
            <SearchBox
              autoFocus={true}
              value={this.state.searchText}
              onChange={this.handleSearchInputChange}
              placeholder={i18n.t("searchPlaceholder")}
            />
            {!this.state.searchResults === false && this.state.searchResults.length > 0 && (
              <ResultsContainer>
                {this.state.searchResults.map((result, index) => {
                  const showCustodianTotal =
                    result.isCustodian === true &&
                    (index === this.state.searchResults.length - 1 ||
                      !this.state.searchResults[index + 1].isCustodian === true);

                  return (
                    <div key={index}>
                      <Result
                        isFocused={this.state.currentlySelectedResultIndex === index}
                        onClick={e => this.handleResultInteraction(result, false)}
                        onMouseEnter={() => {
                          this.selectResult(index);
                        }}
                      >
                        <ResultDetailsContainer>
                          <ResultNameContainer>
                            <ResultName>{result.name}</ResultName>
                            {!result.linkType === false && <LinkedCustodianIcon />}
                            {result.isSection === true && <ResultMeta>{"(Section)"}</ResultMeta>}
                            {result.isSheet === true && <ResultMeta>{"(Sheet)"}</ResultMeta>}
                          </ResultNameContainer>
                          {!result.description === false && <ResultDescription>{result.description}</ResultDescription>}
                          <ResultPath
                            onClick={e => {
                              this.handleResultInteraction(result, true);
                              e.stopPropagation();
                            }}
                          >
                            {this.getResultPath(result)}
                          </ResultPath>
                        </ResultDetailsContainer>
                        <ResultValueContainer>
                          <ResultValue
                            currency={this.props.currentPortfolio.currency}
                            value={result.total}
                            abbreviate={false}
                            roundDown={true}
                          />
                          {result.isCustodian === true && portfolioTicker.id !== result.valueTickerId && (
                            <ResultValueOriginalCurrency
                              currency={getTickerUsingId(result.valueTickerId).shortName}
                              value={result.value}
                              abbreviate={false}
                              roundDown={true}
                              showCurrencyAsSymbol={false}
                            />
                          )}
                        </ResultValueContainer>
                      </Result>
                      {showCustodianTotal === true && (
                        <ResultTotalContainer>
                          <ResultTotal
                            currency={this.props.currentPortfolio.currency}
                            value={this.getResultsTotal(this.state.searchResults)}
                            abbreviate={false}
                            roundDown={true}
                          />
                        </ResultTotalContainer>
                      )}
                    </div>
                  );
                })}
              </ResultsContainer>
            )}

            {this.state.showIndexing ? (
              <IndexingMessageContainer>
                <IndexingMessageTitle>{i18n.t("searchIndexing")}</IndexingMessageTitle>
                <IndexingMessageDesc>{i18n.t("searchIndexingDesc")}</IndexingMessageDesc>
              </IndexingMessageContainer>
            ) : (
              !this.state.searchText === true &&
              this.props.recentSearches.length > 0 && (
                <RecentSearchesContainer>
                  <RecentSearchesTitle>{i18n.t("recentSearches")}</RecentSearchesTitle>
                  {this.props.recentSearches.map((term, index) => {
                    return (
                      <RecentSearchTermContainer
                        key={index}
                        onClick={e => this.handleRecentSearchResultClick(e, term)}
                        onMouseEnter={() => {
                          this.selectResult(index);
                        }}
                        isFocused={this.state.currentlySelectedResultIndex === index}
                      >
                        <RecentSearchIcon />
                        <RecentSearchTerm>{term}</RecentSearchTerm>
                        {this.state.currentlySelectedResultIndex === index && (
                          <ClearRecentSearchButton
                            onClick={e => {
                              this.handleRecentSearchClearClick(e, term);
                              e.stopPropagation();
                            }}
                          >
                            <ClearRecentSearchIcon />
                          </ClearRecentSearchButton>
                        )}
                      </RecentSearchTermContainer>
                    );
                  })}
                </RecentSearchesContainer>
              )
            )}
            <ResultTip
              dangerouslySetInnerHTML={{
                __html: i18n.t("searchPortfolioTip")
              }}
            />
          </Container>
        </SearchDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentPortfolio = currentPortfolioSelector(state);
  return {
    currentPortfolio,
    recentSearches: recentSearchesSelector(state, props.portfolioId),
    userPreferences: userPreferencesSelector(state),
    refreshCustodianTime: refreshCustodianTimeSelector(state),
    portfolioLastForceUpdateTs: portfolioLastForceUpdateTsSelector(state, currentPortfolio.id)
  };
};

const mapDispatchToProps = {
  searchPortfolioForText: searchPortfolioForText,
  updateUserPreferences: updateUserPreferences,
  updateSection: updateSection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchPortfolioComponent));
