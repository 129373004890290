import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import { showCopiedToast } from "@kubera/common";
import { useDispatch } from "react-redux";

const CustomDialog = styled(Dialog)`
  position: relative;
  width: 500px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 11px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const CopyField = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const FieldText = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  font-size: 9px;
  text-transform: uppercase;
  position: absolute;
  right: 55px;
`;

const AdditionalInfoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const AdditionalInfo = styled.p`
  margin: 0;
  line-height: 21px;
  font-size: 14px;
`;

const ImportantContainer = styled.div`
  margin-bottom: 40px;
`;

const ImportantText = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

const APIField = ({ label, value }) => {
  const dispatch = useDispatch();
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    dispatch(showCopiedToast());
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <CopyField>
        <FieldText>{value}</FieldText>
        <CopyButton onClick={handleCopy}>{i18n.t("copy")}</CopyButton>
      </CopyField>
    </>
  );
};

const permissionsText = [i18n.t("readWriteAPIAccess"), i18n.t("readOnlyAPIAccess")];

const DownloadKeyDialog = ({ onDismiss, data }) => {
  const readablePermission = permissionsText[data.readonly];
  const readableIPAddress = data.allowedIp || i18n.t("noRestriction");
  const handleDownload = () => {
    const contentJson = {
      apiKey: data.key,
      secret: data.secret
    };
    const content = JSON.stringify(contentJson, null, 2);
    const blob = new Blob([content], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${i18n.t("apiDownloadFileName")}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <DialogOverlay onDismiss={onDismiss}>
      <CustomDialog>
        <Container>
          <Title>{data.name}</Title>
          <APIField label={i18n.t("apiKey")} value={data.key} />
          <APIField label={i18n.t("secret")} value={data.secret} />
          <AdditionalInfoContainer>
            <AdditionalInfo>{`${i18n.t("Permissions")}: ${readablePermission}`}</AdditionalInfo>
            <AdditionalInfo>{`${i18n.t("ipAddress")}: ${readableIPAddress}`}</AdditionalInfo>
          </AdditionalInfoContainer>
          <ImportantContainer>
            <ImportantText>
              <strong>{i18n.t("Important")}</strong>
            </ImportantText>
            <ImportantText>{i18n.t("apiAccessImportant")}</ImportantText>
          </ImportantContainer>
          <PrimaryButton title={i18n.t("download")} onClick={handleDownload} />
        </Container>
      </CustomDialog>
    </DialogOverlay>
  );
};

export default DownloadKeyDialog;
