import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";

import Logo from "components/labels/AppLogo";
import { confirmSignin, verifyUserBackupCode, MFA_PREFERENCES, isAppInWhiteLabelMode } from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import KuberaPoweredLogo from "components/labels/KuberaPoweredLogo";

const ComponentDialog = styled.div`
  display: flex;
  min-height: 100%;
  background: ${props => props.theme.mobileSignInBackground};
`;

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  height: 528px;
  box-sizing: border-box;
  padding: 0 30px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 13px;
`;

const ErrorDisplay = styled.div`
  display: inline-block;
  height: 18px;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  margin-bottom: 11px;
`;

const VerifyCodeInp = styled(TextInput)`
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  margin-bottom: 21px;
`;

const BtnWrapper = styled.div`
  display: flex;
  margin-bottom: 26px;
`;

const SaveButton = styled(PrimaryButton)`
  min-width: 134px;
  margin-right: 19px;
`;

const ResendLink = styled.span`
  display: inline-block;
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
`;

const GoBackLink = styled.span`
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
  font-feature-settings: "ss01" on;
`;

const PoweredByKuberaLogo = styled(KuberaPoweredLogo)`
  position: absolute;
  right: 50px;
  bottom: 50px;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 32px;
`;

const VerifySignIn = ({ user, email, password, onSuccess, onGoBack = () => null, onResend = () => null }) => {
  const isInit = useRef(true);
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [isResendLinkVisible, setIsResendLinkVisible] = useState(false);

  const onVerifyCodeChange = e => {
    setIsError(false);
    setVerifyCode(e.target.value.replace(/\s/g, ""));
  };

  const handleSave = async e => {
    e.preventDefault();

    setIsLoading(true);

    if (verifyCode.length === 9) {
      const resp = await dispatch(verifyUserBackupCode(email, password, verifyCode)).catch(err => {
        setIsError(true);
        setVerifyCode("");
        setIsLoading(false);
      });

      if (resp) {
        await onResend();
        localStorage.setItem("mfa_disabled", true);
      }

      return;
    }

    const confirmSigninResp = await confirmSignin(user, verifyCode, user.challengeName);

    if (confirmSigninResp) {
      onSuccess();
    } else {
      setIsError(true);
      setVerifyCode("");
      setIsLoading(false);
    }
  };

  const onClickResend = () => {
    setIsResendLinkVisible(false);
    setTimeout(() => {
      setIsResendLinkVisible(true);
    }, 10000);

    onResend();
  };

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      setTimeout(() => {
        setIsResendLinkVisible(true);
      }, 10000);
    }
  });

  const maskedPhoneNo = user && user.challengeParam && user.challengeParam.CODE_DELIVERY_DESTINATION;

  return (
    <ComponentDialog>
      <div>
        <AppLogo forceDefault />
        <Form onSubmit={handleSave}>
          <Title>{i18n.t("twoFA.verificationCodeTitle")}</Title>
          <ErrorDisplay>{isError && i18n.t("wrongCodeTry")}</ErrorDisplay>
          {user.challengeName === MFA_PREFERENCES.SMS && (
            <Desc>
              {i18n.t("twoFA.verificationCodeSmsMobile")} {maskedPhoneNo.replace(/\+/, "")}.{" "}
              {isResendLinkVisible && <ResendLink onClick={onClickResend}>{i18n.t("resend")}</ResendLink>}
            </Desc>
          )}
          {user.challengeName === MFA_PREFERENCES.TOTP && <Desc>{i18n.t("twoFA.verificationCodeTotp")}</Desc>}
          <VerifyCodeInp
            value={verifyCode}
            onChange={onVerifyCodeChange}
            placeholder={i18n.t("verificationCodeHint")}
            autoFocus
            data-private
            autoComplete="one-time-code"
          />
          <BtnWrapper>
            <SaveButton
              id="signIn"
              data-cy="signIn"
              title={i18n.t("signIn")}
              onClick={() => null}
              isLoading={isLoading}
            />
          </BtnWrapper>
          <GoBackLink onClick={onGoBack}>{i18n.t("goBackLink")}</GoBackLink>
          {isAppInWhiteLabelMode() === true && <PoweredByKuberaLogo />}
        </Form>
      </div>
    </ComponentDialog>
  );
};

export default VerifySignIn;
