import React, { Component, cloneElement } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import requestIdleCallback from "utilities/requestIdleCallback";

const noop = () => void 0;

const AutocompleteWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const ListContainer = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  overflow: auto;
  margin: 0 0;
  width: 100%;
  padding: 5px 0;
  background: #fff;
`;

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedListIndex: props.selectedIndex,
      isActive: false
    };

    this.navigateTimeoutId = null;
    this.listNode = [];
    this.listNodeItem = [];

    this.renderInput = this.renderInput.bind(this);
    this.renderListItems = this.renderListItems.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.selectAndHideList = this.selectAndHideList.bind(this);
    this.onUserInput = this.onUserInput.bind(this);
    this.scrollHighlightedElemInView = this.scrollHighlightedElemInView.bind(this);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.isCurrencySearch) {
      requestIdleCallback(() => {
        this.containerRef.current.querySelector("input").addEventListener("focus", this.handleFocus);

        this.containerRef.current.querySelector("input").addEventListener("keydown", this.handleKeyDown);

        this.containerRef.current.querySelector("input").addEventListener("blur", this.handleBlur);
      });
    }
  }

  componentWillUnmount() {
    if (!this.containerRef.current.querySelector("input")) return;

    this.containerRef.current.querySelector("input").addEventListener("focus", this.handleFocus);

    this.containerRef.current.querySelector("input").addEventListener("keydown", this.handleKeyDown);

    this.containerRef.current.querySelector("input").addEventListener("blur", this.handleBlur);
  }

  handleFocus = e => {
    this.props.onFocus(e);
    this.toggleDisplay(true);
  };

  handleKeyDown = e => {
    this.onUserInput(e);
    if (e.key === "Enter") {
      this.containerRef.current.querySelector("input").blur();
    }
  };

  handleBlur = e => {
    if (this.props.preventCloseOnBlur) {
      return;
    }
    setTimeout(() => {
      if ("onBlur" in this.props) this.props.onBlur(e);
      this.toggleDisplay(false);
    }, 300);
  };

  onUserInput(e) {
    if (e.key !== "Escape") {
      e.stopPropagation();
      // if it is escape, bubble up so that a dialog can be closed
    }
    const listNodeLength = Object.keys(this.listNode).length - 1;
    let isActive = true;
    let { selectedListIndex } = this.state;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        selectedListIndex = this.state.selectedListIndex > 0 ? this.state.selectedListIndex - 1 : 0;
        break;
      case "ArrowDown":
        selectedListIndex =
          listNodeLength !== 0 && this.state.selectedListIndex < listNodeLength
            ? this.state.selectedListIndex + 1
            : listNodeLength;
        break;
      case "ArrowRight":
      case "ArrowLeft":
        break;
      case "Enter":
      case "Tab":
        isActive = false;
        const { inpList } = this.props;
        if (!inpList || (inpList && inpList.length === 0)) {
          return;
        }
        this.selectAndHideList(this.state.selectedListIndex);
        return;
      default:
        selectedListIndex = this.props.selectedIndex;
    }

    this.setState(
      {
        selectedListIndex,
        isActive
      },
      () => {
        this.scrollHighlightedElemInView();
      }
    );
  }

  scrollHighlightedElemInView() {
    if (this.listNode[`item-${this.state.selectedListIndex}`]) {
      this.listNode[`item-${this.state.selectedListIndex}`].scrollIntoView({ block: "nearest" });
    }
  }

  selectAndHideList(index) {
    this.props.getSelection(this.listNodeItem[index]);
    this.setState({
      selectedListIndex: index,
      isActive: false
    });
  }

  toggleDisplay(bool) {
    this.setState(
      {
        isActive: bool
      },
      () => {
        this.scrollHighlightedElemInView();
      }
    );
  }

  renderListItems(inpVal, inpList, renderList) {
    const { minTextLength } = this.props;

    this.listNode = [];
    const list = [];
    let skipped = 0;

    inpList.forEach((item, index) => {
      const elem = renderList(item, index);
      const newIndex = index - skipped;

      if (!elem) {
        skipped += 1;
        return;
      }

      const onSelectFn = elem.props.onSelect || noop;
      let addClass = "";
      if (newIndex === this.state.selectedListIndex) {
        addClass = "is-active";
      }
      this.listNodeItem[newIndex] = item;
      const clonedElem = cloneElement(elem, {
        key: index,
        ...elem.props,
        className: `${elem.props.className || ""} ${addClass}`,
        ref: context => {
          this.listNode[`item-${newIndex}`] = context;
        },
        onClick: e => {
          onSelectFn(e);
          this.selectAndHideList(newIndex);
        },
        onMouseDown: e => {
          onSelectFn(e);
          this.selectAndHideList(newIndex);
        },
        onMouseEnter: e => {
          this.setState({
            selectedListIndex: newIndex
          });
        }
      });

      list.push(clonedElem);
    });

    const ListContainerRender = this.props.listContainer || ListContainer;
    return (this.state.isActive || this.props.isVisibleList) && list.length > 0 && inpVal.length >= minTextLength ? (
      <ListContainerRender
        ref={context => {
          this.listNodeWrapperRef = context;
        }}
      >
        {list}
      </ListContainerRender>
    ) : null;
  }

  renderInput(elem) {
    if (this.props.isCurrencySearch) return elem;

    const { id } = elem.props;
    const onKeyDownFn = elem.props.onKeyDown || noop;
    const onFocusFn = elem.props.onFocus || noop;
    const onBlurFn = elem.props.onBlur || noop;

    return cloneElement(elem, {
      id: id || this.inputId,
      onKeyDown: e => {
        onKeyDownFn(e);
        this.onUserInput(e);
      },
      onFocus: e => {
        onFocusFn(e);
        this.toggleDisplay(true);
      },
      onBlur: e => {
        if (this.props.preventCloseOnBlur) {
          return;
        }
        onBlurFn(e);
        setTimeout(() => {
          this.toggleDisplay(false);
        }, 300);
      },
      ref: context => {
        this.inputNode = context;
      }
    });
  }

  render() {
    const { inpList, getSelection, renderInput, renderList, minTextLength, selectedIndex, ...otherProps } = this.props;

    const elem = renderInput();

    return (
      <AutocompleteWrapper
        onTouchStart={e => {
          e.stopPropagation();
        }}
        ref={this.containerRef}
        {...otherProps}
      >
        {this.renderInput(elem)}
        {this.renderListItems(elem.props.value, inpList, renderList)}
      </AutocompleteWrapper>
    );
  }
}

Autocomplete.defaultProps = {
  selectedIndex: 0,
  getSelection: noop,
  minTextLength: 0,
  isVisibleList: false,
  listContainer: null,
  preventCloseOnBlur: false
};

Autocomplete.propTypes = {
  selectedIndex: PropTypes.number,
  inpList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  getSelection: PropTypes.func,
  renderInput: PropTypes.func.isRequired,
  renderList: PropTypes.func.isRequired,
  minTextLength: PropTypes.number,
  isVisibleList: PropTypes.bool,
  listContainer: PropTypes.shape({}),
  preventCloseOnBlur: PropTypes.bool
};

export default Autocomplete;
