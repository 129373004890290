import React, { useRef } from "react";
import styled from "styled-components";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import i18n from "i18next";

import { ReactComponent as BanksIcon } from "assets/images/banks.svg";
import { ReactComponent as StockTickersIcon } from "assets/images/stocktickers.svg";
import { ReactComponent as CryptoTickersIcon } from "assets/images/cryptotickers.svg";
import { ReactComponent as CryptoWalletIcon } from "assets/images/cryptowallet.svg";
import { ReactComponent as HomesIcon } from "assets/images/homes.svg";
import { ReactComponent as CarsIcon } from "assets/images/cars.svg";
import { ReactComponent as DomainsIcon } from "assets/images/domains.svg";
import { ReactComponent as MetalsIcon } from "assets/images/metals.svg";
import { ReactComponent as PortfolioLinkingIcon } from "assets/images/portfolio_linking.svg";
import { ReactComponent as CartaIcon } from "assets/images/cartaicon.svg";
import RedDot from "components/indicators/RedDot";

const EmptyGridView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  flex-wrap: wrap;
`;

const EmptyGridViewConnectAccountView = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ isColumnLayout }) => (!isColumnLayout ? "row" : "column")};
  height: ${props => (props.height ? props.height : "120px")};
  width: ${props => props.width};
  margin: 6px;
  background: ${props => props.theme.emptyGridViewTitleBG};
  border: ${props => props.theme.emptyGridViewTitleBR};
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  &: hover {
    background: ${props => props.theme.emptyGridViewTitleBGHover};
  }
`;

const EmptyGridViewConnectAccountTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  padding: ${props => props.padding};
  align-self: ${props => (props.position === "top" ? "flex-start" : "flex-end")};
`;

const EmptyGridViewConnectAccountIcon = styled.div`
  outline: 0;
  padding: ${props => props.padding};
  cursor: pointer;
  align-self: ${props => (props.position === "top" ? "flex-start" : "flex-end")};
  text-align: ${props => (props.align === "left" ? "left" : "right")};
`;

const EmptyGridViewConnectExtrasContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props => props.height || "120px"};
  width: ${props => props.width || "240px"};
  margin: 6px;
  background: ${props => props.theme.emptyGridViewTitleBG};
  border: ${props => props.theme.emptyGridViewTitleBR};
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  &: hover {
    background: ${props => props.theme.emptyGridViewTitleBGHover};
  }
`;

const EmptyGridViewConnectExtrasTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  padding: 16px;
  align-self: ${props => (props.position === "left" ? "flex-start" : "flex-end")};
`;

const EmptyGridViewConnectExtrasIcon = styled.div`
  outline: 0;
  padding: ${props => props.padding || "10px"};
  cursor: pointer;
  align-self: ${props => (props.position === "left" ? "flex-start" : "flex-end")};
`;

const EmptyGridViewTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12.1px;
  font-feature-settings: "ss01" on;
  padding: 13px 11px 0;
  white-space: pre;
  align-self: flex-start;
  color: ${props => props.theme.emptyGridViewTitleColor};
`;

const EmptyGridViewEnterManually = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15.6px;
  font-feature-settings: "ss01" on;
  padding: 0 11px 12px;
  white-space: pre;
  word-wrap: break-word;
  align-self: flex-end;
  width: 100%;
  box-sizing: border-box;
`;

const AddViewEnterManuallyTip = styled.div`
  width: 554px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  padding: 3px 6px 0;
  color: rgba(0, 0, 0, 0.5);

  a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: underline;
  }
`;

const EmptyGridViewLinkPortfoliosContainer = styled.div`
  display: flex;
  width: 100%;
  height:  100px
  margin-right: ${props => (props.isAddFlow ? "0px" : "34px")};
`;

const EmptyGridViewLinkPortfoliosView = styled(EmptyGridViewConnectAccountView)`
  flex: 1;
  margin: 6px;
  background: ${props => props.theme.emptyGridViewTitleBG};
  border: ${props => props.theme.emptyGridViewTitleBR};
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  &: hover {
    background: ${props => props.theme.emptyGridViewTitleBGHover};
  }
`;

const BanksIconComponent = styled(BanksIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const StockTickersIconComponent = styled(StockTickersIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CartaIconComponent = styled(CartaIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CryptoTickersIconComponent = styled(CryptoTickersIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CryptoWalletIconComponent = styled(CryptoWalletIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const HomesIconComponent = styled(HomesIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CarsIconComponent = styled(CarsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DomainsIconComponent = styled(DomainsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const MetalsIconComponent = styled(MetalsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const PortfolioLinkingIconComponent = styled(PortfolioLinkingIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

function EmptyGridViewAssetsComponent(props) {
  const redDotRef = useRef(null);

  const onInitialTipClick = menuItem => {
    redDotRef.current.setPreferenceFlag(true);
    props.handleInitialTipClick(menuItem);
  };

  const readOnlyWlClient = props.isReadOnlyWlClient;
  const showCartaTile = process.env.REACT_APP_ENV !== "prod";

  const banksTileWidth = !showCartaTile ? "349px" : "270px";
  const stocksTileWidth = !showCartaTile ? "193px" : "130px";

  return (
    <EmptyGridView className={props.className}>
      {readOnlyWlClient === false && (
        <EmptyGridViewConnectAccountView
          onClick={e => props.handleInitialTipClick(contextMenuItemType.LINK)}
          width={banksTileWidth}
        >
          <EmptyGridViewConnectAccountTitle position="top" padding="18px">
            {i18n.t("assetsComponent.emptyViewConnectBanksTitle")}
          </EmptyGridViewConnectAccountTitle>
          <EmptyGridViewConnectAccountIcon position="bottom" padding="14px">
            <BanksIconComponent />
          </EmptyGridViewConnectAccountIcon>
        </EmptyGridViewConnectAccountView>
      )}
      <EmptyGridViewConnectAccountView
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_STOCK)}
        width={readOnlyWlClient === true ? "347px" : stocksTileWidth}
      >
        <EmptyGridViewConnectAccountTitle padding="18px 0 18px 18px">
          {i18n.t("assetsComponent.emptyViewConnectStockTitle")}
        </EmptyGridViewConnectAccountTitle>
        <EmptyGridViewConnectAccountIcon position="top" padding="12px 12px 12px 0">
          <StockTickersIconComponent />
        </EmptyGridViewConnectAccountIcon>
      </EmptyGridViewConnectAccountView>
      {showCartaTile && readOnlyWlClient === false && (
        <EmptyGridViewConnectAccountView
          onClick={e => props.handleInitialTipClick(contextMenuItemType.LINK_CARTA)}
          width="130px"
        >
          <EmptyGridViewConnectAccountTitle position="top" padding="18px 0 18px 18px">
            {i18n.t("assetsComponent.emptyViewConnectCartaTitle")}
          </EmptyGridViewConnectAccountTitle>
          <EmptyGridViewConnectAccountIcon position="bottom" padding="12px 12px 12px 0">
            <CartaIconComponent />
          </EmptyGridViewConnectAccountIcon>
        </EmptyGridViewConnectAccountView>
      )}
      <EmptyGridViewConnectAccountView
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_CRYPTO)}
        width="195px"
      >
        <EmptyGridViewConnectAccountTitle padding="18px">
          {i18n.t("assetsComponent.emptyViewConnectCoinTitle")}
        </EmptyGridViewConnectAccountTitle>
        <EmptyGridViewConnectAccountIcon position="top" padding="12px">
          <CryptoTickersIconComponent />
        </EmptyGridViewConnectAccountIcon>
      </EmptyGridViewConnectAccountView>
      <EmptyGridViewConnectAccountView
        onClick={e => props.handleInitialTipClick(contextMenuItemType.LINK_CRYPTO)}
        width={readOnlyWlClient === true ? "412px" : "347px"}
      >
        <EmptyGridViewConnectAccountTitle position="top" padding="18px">
          {i18n.t("assetsComponent.emptyViewConnectCryptoTitle")}
        </EmptyGridViewConnectAccountTitle>
        <EmptyGridViewConnectAccountIcon position="bottom" padding="14px">
          <CryptoWalletIconComponent />
        </EmptyGridViewConnectAccountIcon>
      </EmptyGridViewConnectAccountView>
      <EmptyGridViewConnectExtrasContainer
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_HOMES)}
        width="130px"
      >
        <EmptyGridViewConnectExtrasIcon position="left" padding="16px">
          <HomesIconComponent />
        </EmptyGridViewConnectExtrasIcon>
        <EmptyGridViewConnectExtrasTitle position="left">
          {i18n.t("assetsComponent.emptyViewConnectHomesTitle")}
        </EmptyGridViewConnectExtrasTitle>
      </EmptyGridViewConnectExtrasContainer>
      <EmptyGridViewConnectExtrasContainer
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_CARS)}
        width={readOnlyWlClient === true ? "272px" : "130px"}
      >
        <EmptyGridViewConnectExtrasTitle position="left" padding="16px">
          {i18n.t("assetsComponent.emptyViewConnectCarsTitle")}
        </EmptyGridViewConnectExtrasTitle>
        <EmptyGridViewConnectExtrasIcon padding="16px 16px 10px 16px">
          <CarsIconComponent />
        </EmptyGridViewConnectExtrasIcon>
      </EmptyGridViewConnectExtrasContainer>
      <EmptyGridViewConnectExtrasContainer
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_METAL)}
        width="130px"
        direction="column"
      >
        <EmptyGridViewConnectExtrasIcon position="left" padding="10px 16px 0px 16px">
          <MetalsIconComponent />
        </EmptyGridViewConnectExtrasIcon>
        <EmptyGridViewConnectExtrasTitle position="left" padding="14px">
          {i18n.t("assetsComponent.emptyViewConnectPreciousMetalsTitle")}
        </EmptyGridViewConnectExtrasTitle>
      </EmptyGridViewConnectExtrasContainer>
      <EmptyGridViewConnectExtrasContainer
        onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_DOMAINS)}
        width="128px"
      >
        <EmptyGridViewConnectExtrasTitle position="left" padding="14px">
          {i18n.t("assetsComponent.emptyViewConnectDomainsTitle")}
        </EmptyGridViewConnectExtrasTitle>
        <EmptyGridViewConnectExtrasIcon padding="16px">
          <DomainsIconComponent />
        </EmptyGridViewConnectExtrasIcon>
      </EmptyGridViewConnectExtrasContainer>
      <EmptyGridViewLinkPortfoliosContainer isAddFlow={props.isAddNewFlow}>
        {readOnlyWlClient === false && (
          <EmptyGridViewLinkPortfoliosView
            onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_PORTFOLIOS)}
            height="100%"
            width="272px"
          >
            <EmptyGridViewConnectExtrasTitle position="left">
              {i18n.t("assetsComponent.emptyViewConnectPortfolios")}
            </EmptyGridViewConnectExtrasTitle>
            <EmptyGridViewConnectExtrasIcon>
              <PortfolioLinkingIconComponent />
            </EmptyGridViewConnectExtrasIcon>
          </EmptyGridViewLinkPortfoliosView>
        )}
        <EmptyGridViewConnectAccountView
          onClick={e => onInitialTipClick(contextMenuItemType.ENTER_QTY_PRICE)}
          height="100%"
          width={readOnlyWlClient ? "50%" : "130px"}
          isColumnLayout
        >
          <EmptyGridViewTitle>{i18n.t("generic")}</EmptyGridViewTitle>
          <EmptyGridViewEnterManually>{i18n.t("assetsComponent.addPVSTManuallyDesc")}</EmptyGridViewEnterManually>
          <RedDot
            ref={redDotRef}
            preference="emptyGridAssetPreferences"
            childPreference={contextMenuItemType.ENTER_QTY_PRICE.id}
          />
        </EmptyGridViewConnectAccountView>
        <EmptyGridViewConnectAccountView
          onClick={e => props.handleInitialTipClick(null)}
          height="100%"
          width={readOnlyWlClient ? "50%" : "130px"}
          isColumnLayout
        >
          <EmptyGridViewTitle>{i18n.t("generic")}</EmptyGridViewTitle>
          <EmptyGridViewEnterManually>{i18n.t("assetsComponent.addViewManuallyDesc1")}</EmptyGridViewEnterManually>
        </EmptyGridViewConnectAccountView>
      </EmptyGridViewLinkPortfoliosContainer>
      {props.isAddNewFlow && (
        <AddViewEnterManuallyTip
          dangerouslySetInnerHTML={{
            __html: i18n.t("assetsComponent.addViewManuallyDesc2")
          }}
        />
      )}
    </EmptyGridView>
  );
}

export default EmptyGridViewAssetsComponent;
