import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import { SUBSCRIPTION_PLANS, accountPlanSelector, userPriceOptionSelector } from "@kubera/common";
import NFTDiscountMessage from "./NFTDiscountMessage";

const PersonalToFamilyNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  justify-content: flex-end;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 8px;
`;

const UpdateSubscriptionMessage = ({ accountPlan }) => {
  const currentAccountPlan = useSelector(accountPlanSelector);
  const userPrice = useSelector(userPriceOptionSelector);

  if (
    [SUBSCRIPTION_PLANS.MONTHLY, SUBSCRIPTION_PLANS.YEARLY].includes(currentAccountPlan) &&
    accountPlan === SUBSCRIPTION_PLANS.YEARLY_FAMILY
  ) {
    return (
      <PersonalToFamilyNote
        dangerouslySetInnerHTML={{
          __html: i18n.t("accountCardPayment.personalToFamilyNote").replace("%s%", userPrice.yearly_family)
        }}
      />
    );
  }

  return <NFTDiscountMessage accountPlan={accountPlan} />;
};

export default UpdateSubscriptionMessage;
