import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { sanitizeIrr, isAppInWhiteLabelMode, getCustodianHistoryFormattedDateString } from "@kubera/common";
import Checkbox from "components/inputs/Checkbox";
import PercentageLabel from "components/labels/PercentageLabel";
import DropDown from "components/inputs/DropDown";

export const timePeriods = {
  START_DATE: "start_date",
  YEAR: "year",
  YTD: "ytd"
};

const MaxSelectedIndexes = 3;

const SettingsDialog = styled(Dialog)`
  width: 500px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const TimePeriodTitle = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #000000;
`;

const TimePeriodDropDown = styled(DropDown)`
  margin-top: 4px;
`;

const VsContainer = styled.div`
  position: relative;
  margin-top: 25px;
`;

const Separator = styled.div`
  width: 100%;
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
`;

const VsLabel = styled.div`
  position: absolute;
  left: 171px;
  bottom: -11px;
  background: ${props => props.theme.vsLabelBG};
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ChangeContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ChangeDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
`;

const ChangeTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const NetWorthCagr = styled(PercentageLabel)`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on, "calt" off;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};
`;

const IndexCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const IndexCheckboxItemContainer = styled.div`
  display: flex;
  margin-top: 18px;
  font-size: 14px;
`;

const IndexIrrValue = styled(PercentageLabel)`
  margin-left: 5px;
`;

const IndexCheckbox = styled(Checkbox)``;

const CagrExplanation = styled.div`
  margin-top: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const LearnMore = styled.a`
  margin-left: 5px;
  text-decoration: underline;
  color: ${props => props.theme.linkColor};

  &:visited {
    color: #000000;
  }
`;

class PortfolioIrrDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTimePeriod: props.selectedTimePeriod,
      selectedIndexes: props.selectedIndexes
    };

    this.handleIndexCheckboxChange = this.handleIndexCheckboxChange.bind(this);
    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
    this.handleTimePeriodDropDownSelection = this.handleTimePeriodDropDownSelection.bind(this);
  }

  handleIndexCheckboxChange(checked, indexId) {
    var newSelectedIndexes = this.state.selectedIndexes;

    if (checked === true) {
      newSelectedIndexes.push(indexId);
    } else {
      newSelectedIndexes = newSelectedIndexes.filter(item => item !== indexId);
    }

    this.setState({ selectedIndexes: newSelectedIndexes });
  }

  handleTimePeriodDropDownSelection(item) {
    this.setState({ selectedTimePeriod: item.id });
    this.props.onTimePeriodSelection(item.id);
  }

  handleDialogDismiss() {
    this.props.onDismiss(this.state.selectedIndexes, this.state.selectedTimePeriod);
  }

  getTimePeriodDropDownData() {
    return [
      {
        id: timePeriods.START_DATE,
        label: `Since ${getCustodianHistoryFormattedDateString(this.props.netWorthChartStartDate)}`,
        selected: this.state.selectedTimePeriod === timePeriods.START_DATE
      },
      {
        id: timePeriods.YEAR,
        label: i18n.t("yearChange"),
        selected: this.state.selectedTimePeriod === timePeriods.YEAR
      },
      {
        id: timePeriods.YTD,
        label: i18n.t("ytdChange"),
        selected: this.state.selectedTimePeriod === timePeriods.YTD
      }
    ];
  }

  getIndexCheckboxItems() {
    var items = [];
    for (const index of this.props.supportedIndexes) {
      const isSelected = this.state.selectedIndexes.findIndex(temp => temp === index.id) !== -1;

      items.push({
        id: index.id,
        title: index.longName,
        irr: sanitizeIrr(this.getMarketIrr(index.id)),
        checked: isSelected,
        disabled: isSelected === false && this.state.selectedIndexes.length === MaxSelectedIndexes
      });
    }
    return items;
  }

  getMarketIrr(indexId) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return this.props.supportedIndexesIrr.start_date[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return this.props.supportedIndexesIrr.year[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return this.props.supportedIndexesIrr.ytd[indexId];
    }
    return null;
  }

  shouldHideCagr() {
    return isAppInWhiteLabelMode() === true && this.props.siteConfig.showCagr === 0;
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.handleDialogDismiss}>
        <SettingsDialog>
          <Container>
            <Title>{i18n.t("netWorthGains")}</Title>
            <TimePeriodTitle>{i18n.t("timePeriod")}</TimePeriodTitle>
            <TimePeriodDropDown
              width={380}
              items={this.getTimePeriodDropDownData()}
              onSelection={this.handleTimePeriodDropDownSelection}
            />
            <ChangeContainer>
              <ChangeDetails>
                <ChangeTitle>{i18n.t("networth")}</ChangeTitle>
                <NetWorthCagr
                  value={this.props.netWorthCagr}
                  darkBackground={false}
                  updated={true}
                  alignPosition="left"
                  preventAnimation={true}
                />
              </ChangeDetails>
              <ChangeDetails>
                <ChangeTitle>{i18n.t("investable")}</ChangeTitle>
                <NetWorthCagr
                  value={this.props.investableCagr}
                  darkBackground={false}
                  updated={true}
                  alignPosition="left"
                  preventAnimation={true}
                />
              </ChangeDetails>
            </ChangeContainer>
            {this.shouldHideCagr() === false && (
              <VsContainer>
                <Separator />
                <VsLabel>{i18n.t("vs")}</VsLabel>
              </VsContainer>
            )}
            {this.shouldHideCagr() === false && (
              <IndexCheckboxContainer>
                {this.getIndexCheckboxItems().map((data, index) => (
                  <IndexCheckboxItemContainer key={index}>
                    <IndexCheckbox
                      label={data.title}
                      checked={data.checked}
                      disabled={data.disabled}
                      onChange={(checked, e) => {
                        this.handleIndexCheckboxChange(checked, data.id);
                      }}
                    />
                    <IndexIrrValue value={data.irr} darkBackground={false} updated={true} showZero={true} />
                  </IndexCheckboxItemContainer>
                ))}
              </IndexCheckboxContainer>
            )}
            <CagrExplanation>
              {i18n.t("cagrExplanation")}
              <LearnMore target={"_blank"} href={"https://help.kubera.com/article/83-cagr-vs-irr"}>
                {i18n.t("learnMore")}
              </LearnMore>
            </CagrExplanation>
          </Container>
        </SettingsDialog>
      </DialogOverlay>
    );
  }
}

export default PortfolioIrrDialog;
