import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import {
  withRouter,
  getQueryParams,
  setSignInRedirectPathAction,
  fetchUser,
  setPortfolioSessionUserId,
  isMobile,
  isAppInWhiteLabelMode
} from "@kubera/common";
import { accpetMultiuserInvite } from "@kubera/common";
import { connect } from "react-redux";
import { routes, queryParams } from "routes";
import InitialLoader from "components/loader/InitialLoader";
import Logo from "components/labels/AppLogo";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 423px;
  height: fit-content;
`;

const InviteContainer = styled(Container)`
  justify-content: flex-start;
`;

const InviteMessageBlock = styled.div`
  margin-top: 30px;
  padding: 0 30px;
`;

const AppLogo = styled(Logo)`
  margin-bottom: 32px;
`;

const InviteMessageTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.39px;
  margin-bottom: 27px;
`;

const InviteMessageDesc = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.27px;
`;

class FamilyInvitationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isRequestPending: false, errorMessage: null, invitedByUser: null };
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
  }

  componentDidMount() {
    this.acceptInvite();
  }

  acceptInvite() {
    if (isMobile()) {
      return;
    }

    this.setState({ isRequestPending: true, errorMessage: null });

    const fetchUserAndSetPortfolioUser = invitedByUser => {
      this.props.fetchUser(
        () => {
          setPortfolioSessionUserId(invitedByUser.id);
          const portfolioId = getQueryParams(this.props.location)[queryParams.PORTFOLIO_ID];
          this.props.history.replace({
            ...this.props.location,
            pathname: routes.HOME,
            search: !portfolioId === false ? `?${queryParams.PORTFOLIO_ID}=${portfolioId}` : ""
          });
        },
        apiError => {
          this.setState({ isRequestPending: false, errorMessage: apiError.errorMessage });
        }
      );
    };

    if (!this.state.invitedByUser === false) {
      fetchUserAndSetPortfolioUser(this.state.invitedByUser);
      return;
    }

    const token = getQueryParams(this.props.location)[queryParams.INVITATION_ID];
    this.props.accpetMultiuserInvite(
      token,
      invitedByUser => {
        this.setState({ invitedByUser: invitedByUser });
        this.props.setSignInRedirectPathAction(null);
        fetchUserAndSetPortfolioUser(invitedByUser);
      },
      error => {
        this.setState({ isRequestPending: false, errorMessage: error.errorMessage });
      }
    );
  }

  handleLoaderRefreshClick() {
    this.acceptInvite();
  }

  render() {
    if (isMobile()) {
      return (
        <InviteContainer>
          <InviteMessageBlock>
            <AppLogo hasNoLink={isAppInWhiteLabelMode()} forceDefault />
            <InviteMessageTitle>{`${i18n.t("sorry")}!`}</InviteMessageTitle>
            <InviteMessageDesc>{i18n.t("mobileFamilyInviteMessage")}</InviteMessageDesc>
          </InviteMessageBlock>
        </InviteContainer>
      );
    }

    return (
      <Container>
        <ContentContainer>
          <InitialLoader
            errorMessage={this.state.errorMessage}
            overrideGenericError={true}
            onRefresh={this.handleLoaderRefreshClick}
          />
        </ContentContainer>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  setSignInRedirectPathAction: setSignInRedirectPathAction,
  accpetMultiuserInvite: accpetMultiuserInvite,
  fetchUser: fetchUser
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(FamilyInvitationComponent));
