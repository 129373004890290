import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  userEmailSelector,
  getSubscriptionDateString,
  accountEndTsSelector,
  accountSubscriptionStatusSelector,
  accountPlanSelector,
  updateSubscriptionCard,
  retrySubscription,
  setupPaymentIntent,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_ERROR_CODES,
  SUBSCRIPTION_ERROR,
  isMobile
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountCardElement, { CardElement } from "./AccountCardElement";
import MobileAccountCardChange from "./MobileAccountCardChange";

const isMobileDevice = isMobile();

const CardContainer = styled(AccountCardElement)`
  width: 396px;
  margin-bottom: 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 136px;
  height: 43px;
  margin-right: 18px;
  color: ${props => props.theme.accountCardChangeSubscribeBtnCLR};
  background: ${props => props.theme.accountCardChangeSubscribeBtnBG};
`;

const CancelBtn = styled(SecondaryButton)`
  width: 136px;
  height: 43px;
`;

const UpdateMessage = styled.div`
  font-size: 13px;
`;

const options = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const AccountCardChange = ({
  setUpdateCardMode = () => null,
  setIsSubmitted = () => null,
  captureError = () => null
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const userEmail = useSelector(userEmailSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const endDate = getSubscriptionDateString(accountEndTs);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      let isDeclinedSubscription;

      const onPaymentError = err => {
        setIsLoading(false);
        if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
          setIsDeclinedError(true);
          isDeclinedSubscription = true;
        }
      };
      if (accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE) {
        await dispatch(retrySubscription(paymentMethodId)).catch(onPaymentError);
      } else {
        await dispatch(updateSubscriptionCard(paymentMethodId)).catch(onPaymentError);
      }

      if (!isDeclinedSubscription) {
        setUpdateCardMode(false);
      }

      isDeclinedSubscription = false;
    },
    [dispatch, accountSubscriptionStatus, setUpdateCardMode]
  );

  const handleUpdateCardClick = async e => {
    e.preventDefault();
    setIsLoading(true);

    const paymentIntent = await dispatch(setupPaymentIntent(accountPlan)).catch(() => {
      setIsLoading(false);
    });

    if (paymentIntent) {
      stripe
        .confirmCardSetup(paymentIntent.payload.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: userEmail
            }
          }
        })
        .then(result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsLoading(false);
            setIsDeclinedError(true);
          } else if (result.setupIntent.status === "succeeded") {
            onSubscriptionChange(result.setupIntent.payment_method);
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
            setPaymentErrorMsg(result.setupIntent.last_setup_error);
            setIsDeclinedError(true);
          }
        });
    }

    setIsSubmitted(true);
  };

  const onCancelAction = () => {
    setUpdateCardMode(false);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  return (
    <>
      <form onSubmit={handleUpdateCardClick}>
        <CardContainer options={options} />
        <ActionButtonContainer>
          <SubscribeBtn
            title={i18n.t("update")}
            data-cy="updateCardButton"
            onClick={handleUpdateCardClick}
            isLoading={isLoading}
          />
          <CancelBtn title={i18n.t("cancel")} data-cy="cancelButton" onClick={onCancelAction} />
        </ActionButtonContainer>
        {accountSubscriptionStatus !== SUBSCRIPTION_STATUS.PAST_DUE && (
          <UpdateMessage>Changes come into effect only on {endDate}</UpdateMessage>
        )}
      </form>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </>
  );
};

export default isMobileDevice ? MobileAccountCardChange : AccountCardChange;
