import React from "react";
import styled, { css } from "styled-components";
import PrimaryButton from "components/button/PrimaryButton";
import i18n from "i18next";

const Container = styled.div`
  width: ${props => (props.isInsuranceComponent ? "416px" : "600px")};
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  visibility: ${props => (props.isHidden === true ? "hidden" : "visible")};
  background: ${props => (props.isInsuranceComponent ? props.theme.emptyInsuranceGridBG : "none")};
  border: ${props => (props.isInsuranceComponent ? props.theme.emptyInsuranceGridBR : "none")};
  padding: ${props => (props.isInsuranceComponent ? "50px" : "none")};
`;

const TitleContainer = styled.div`
  display: flex;
  white-space: nowrap;
  margin-left: 6px;
  margin-right: 6px;

  ${props => css`
    margin-top: ${props.titleMargin.marginTop};
    margin-right: ${props.titleMargin.marginRight};
    margin-bottom: ${props.titleMargin.marginBottom};
    margin-left: ${props.titleMargin.marginLeft};
  `}
`;

const Title = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  max-width: 219px;
  white-space: pre-line;
`;

const Icon = styled.div`
  width: 80px;
  height: 80px;
  margin-top: -20px;
  background-color: transparent;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const Description = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const OkButton = styled(PrimaryButton)`
  margin-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
  background: ${props => props.theme.emptyGridComponentOkBtnBG};
`;

const ViewModeContainer = styled.div`
  position: absolute;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ViewModeMessage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

class EmptyGridComponent extends React.Component {
  render() {
    if (this.props.isReadOnly) {
      return (
        <ViewModeContainer>
          <ViewModeMessage>{i18n.t("viewModeEmptyGrid")}</ViewModeMessage>
        </ViewModeContainer>
      );
    }

    const isHidden = this.props.isHidden;
    const title = this.props.title;
    const description = this.props.description;
    const icon = this.props.icon;
    const buttonTitle = this.props.buttonTitle;
    const customView = this.props.customView;
    const titleMargin = this.props.titleMargin || {};

    return (
      <Container
        className={this.props.className}
        isHidden={isHidden}
        isInsuranceComponent={this.props.isInsuranceComponent}
      >
        <TitleContainer titleMargin={titleMargin}>
          <Title>{title}</Title>
          <Icon icon={icon} />
        </TitleContainer>
        {!description === false && <Description> {description}</Description>}
        {!customView === false && customView}
        {!buttonTitle === false && <OkButton onClick={this.props.onClick} title={buttonTitle} />}
      </Container>
    );
  }
}

export default EmptyGridComponent;
