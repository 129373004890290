import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import LinkAccountComponentExports, { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import { userCountryCodeSelector, store, getServiceNameForCountry } from "@kubera/common";

const RestOfWorldRegionCode = "ROW";

const PickCountryDialog = styled(Dialog)`
  width: 450px;
  min-height: 275px;
  margin-top: 200px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 60px 50px 60px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
`;

const Description = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

const SupportedRegionsContainer = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Region = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.isFirst === true ? "0px" : "40px")};
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
`;

const RegionLogo = styled.div`
  width: 70px;
  height: 70px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px 70px;
  border-radius: 37px;
  border: ${props => (props.isSelected === true ? "2px solid black" : "2px solid white")};

  ${Region}:hover & {
    border: 2px solid rgba(0, 0, 0, 0.5);
  }
`;

const RegionLabel = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
  font-size: 11px;
  text-align: center;
  font-feature-settings: "ss01" on;
`;

const RegionProvider = styled.div`
  font-size: 9px;
  line-height: 200%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`;

class PickLinkAccountCountry extends React.Component {
  static show = (history, location, custodianId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.PICK_LINK_COUNTRY}&${hashParams.ID}=${custodianId}`
    });
  };

  constructor(props) {
    super(props);

    const baseIconPath = process.env.PUBLIC_URL + "/images/supported_regions/";
    this.supportedRegions = [
      { code: "US", label: "USA", provider: getServiceNameForCountry("US"), iconUrl: baseIconPath + "usa.png" },
      { code: "CA", label: "Canada", provider: getServiceNameForCountry("CA"), iconUrl: baseIconPath + "canada.png" },
      {
        code: RestOfWorldRegionCode,
        label: "Worldwide",
        provider: "YODLEE / SALT-EDGE",
        iconUrl: baseIconPath + "rest_of_the_world.png"
      }
    ];

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleRegionSelection = this.handleRegionSelection.bind(this);
  }

  handleRegionSelection(region) {
    LinkAccountComponentExports.linkAccount(
      this.props.history,
      this.props.location,
      this.props.custodianId,
      linkAccountMode.LINK,
      true,
      region.code
    );
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  getUserRegionCode() {
    const userCountryCode = userCountryCodeSelector(store.getState());

    for (const region of this.supportedRegions) {
      if (region.code.toLowerCase() === userCountryCode.toLowerCase()) {
        return region.code;
      }
    }
    return RestOfWorldRegionCode;
  }

  render() {
    const userRegionCode = this.getUserRegionCode();

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <PickCountryDialog>
          <Container>
            <Title>{i18n.t("linkAccountPickCountryDialog.title")}</Title>
            <Description>{i18n.t("linkAccountPickCountryDialog.description")}</Description>
            <SupportedRegionsContainer>
              {this.supportedRegions.map((region, index) => (
                <Region key={region.code} isFirst={index === 0} onClick={e => this.handleRegionSelection(region)}>
                  <RegionLogo icon={region.iconUrl} isSelected={region.code === userRegionCode} />
                  <RegionLabel isSelected={region.code === userRegionCode}>{region.label}</RegionLabel>
                  <RegionProvider>{region.provider}</RegionProvider>
                </Region>
              ))}
            </SupportedRegionsContainer>
          </Container>
        </PickCountryDialog>
      </DialogOverlay>
    );
  }
}

export default withRouter(PickLinkAccountCountry);
