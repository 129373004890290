import createWhitelistFilter from "redux-persist-transform-filter";
import { SHOW_TOAST, DISMISS_TOAST, toastType } from "../actions/Common";

const initialState = {
  toast: null
};

export const toastPersistTransform = () => {
  return createWhitelistFilter("toast", []);
};

export function toastReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case DISMISS_TOAST:
      return {
        ...state,
        toast: null
      };
    default:
      return state;
  }
}

export const toastSelector = state => state.toast.toast;

export const shouldToastBlockReload = state => {
  const currentToast = state.toast.toast;

  if (currentToast && currentToast.type === toastType.UNDO) {
    return true;
  }
  return false;
};
