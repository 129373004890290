import React from "react";
import styled from "styled-components";
import i18n from "locale/i18n";

import { ReactComponent as HeartIcon } from "assets/images/heart.svg";

const ExtendTrialBlk = styled.div`
  position: absolute;
  bottom: -90px;
  left: 0;
  font-size: 13px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.8);
`;

const ExtendTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ExtendDesc = styled.div`
  margin-bottom: ${props => (props.hasItemBelow ? "20px" : null)};

  a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline;
  }
`;

const HeartIconComponent = styled(HeartIcon)`
  margin-top: 1.8px;
  margin-right: 4px;
`;

const ExtendTrialBlock = () => {
  return (
    <ExtendTrialBlk>
      <ExtendTitle>
        <HeartIconComponent />
        {i18n.t("accountSettings.extendTitle")}
      </ExtendTitle>
      <ExtendDesc
        dangerouslySetInnerHTML={{
          __html: i18n.t("accountSettings.extendDesc").replace(/%s%/g, "hello@kubera.com")
        }}
      />
    </ExtendTrialBlk>
  );
};

export default ExtendTrialBlock;
