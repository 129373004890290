import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  guessPastDate,
  updateUserPreferences,
  isInvalidDob,
  parseKuberaDateString,
  getKuberaDateString
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import DateInput from "components/inputs/DateInput";

const DateDialog = styled(Dialog)`
  position: relative;
  width: 506px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  margin: 50px;
`;

const DateOfBirthTitle = styled.div`
  margin-top: 6px;
  color: #000;
  font-feature-settings: "ss01" on;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.6px;
`;

const DateOfBirthInput = styled(DateInput)`
  margin-top: 6px;
  label {
    height: 53px;
    padding: 0px 0px 0px 7px;
  }
  input,
  input::placeholder {
    font-weight: 400;
    line-height: 150%;
    font-size: 22px;
    font-feature-settings: "ss01" on;
  }
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 40px;
  min-width: 105px;
`;

class DobDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dobString: null
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleDobInputChange = this.handleDobInputChange.bind(this);
  }

  handleDobInputChange(value) {
    this.setState({ errorMessage: null, dobString: value });
  }

  handleSaveClick(e) {
    const guessedDate = guessPastDate(this.state.dobString);
    if (!isInvalidDob(guessedDate)) {
      this.props.updateUserPreferences({
        userDob: guessedDate.dateString
      });
      if (this.props.onVariableUpdate) {
        const targetDate = parseKuberaDateString(guessedDate.dateString);
        targetDate.setFullYear(guessedDate.date.getFullYear() + this.props.age);
        const targetDateString = getKuberaDateString(targetDate.getTime());
        this.props.onVariableUpdate({ ...(this.props.data || {}), date: targetDateString });
      } // apply the age to the new bday to find target
      this.props.onDismiss(guessedDate.dateString);
    } else {
      this.setState({ errorMessage: i18n.t("enterPastDate") });
    }
  }

  render() {
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <DateDialog>
          <Container>
            <DateOfBirthTitle>{`${i18n.t("your")} ${i18n.t("dateOfBirth")}`}</DateOfBirthTitle>{" "}
            <DateOfBirthInput
              inputPlaceholder={i18n.t("dateOfBirth")}
              value={this.state.dobString}
              onDateChange={this.handleDobInputChange}
              autoFocus={true}
              isBdayPicker={true}
              onValidDate={this.handleSaveClick}
            />
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveClick} />
          </Container>
        </DateDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DobDialog);
