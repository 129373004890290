export const ANALYTICS = {
  CATEGORY: {
    APP: "APP",
    USER: "USER",
    PORTFOLIO: "PORTFOLIO",
    CUSTODIAN: "CUSTODIAN",
    BENEFICIARY: "BENEFICIARY",
    SAFE_DEPOSIT_BOX: "SAFE_DEPOSIT_BOX",
    LOG_ROCKET: "LogRocket",
    ALL: "All"
  },
  ACTION: {
    ADD: "ADD",
    EDIT: "EDIT",
    EDIT_TRUSTED_ANGEL: "EDIT_TRUSTED_ANGEL",
    EDIT_PHONE: "EDIT_PHONE",
    REMOVE: "REMOVE",
    EXPORT: "EXPORT",
    SHEET_ADD: "SHEET_ADD",
    SIGNED_UP: "Signed Up",
    START_TRIAL: "Start Trial",
    EMAIL_SUBMITTED: "Email Submitted",
    SUBSCRIBED: "Subscribed",
    SUBSCRIBED_REPEAT: "Subscribed Repeat",
    APP_LOADED: "K_APP_LOADED",
    PAGE_LOADED: "K_PAGE_LOADED"
  }
};
