import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import i18n from "i18next";

import {
  getSubscriptionEndDateString,
  accountCardDetailsSelector,
  accountPlanSelector,
  accountEndTsSelector,
  accountSubscriptionStatusSelector,
  userPriceOptionSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS
} from "@kubera/common";

import Spinner from "components/loader/Spinner";

import NFTDiscountMessage from "./NFTDiscountMessage";

const Container = styled.div``;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 231px;
  background: #e3e3e3;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
`;

const CardDetails = styled.div`
  font-size: 16px;
  line-height: 125%;
`;

const CardName = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
`;
const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const CardPlan = styled.div`
  font-family: Roboto Mono;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
`;

const CardPlanDue = styled.div`
  font-family: Roboto Mono;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: #ff3333;
`;

const UpdateLink = styled.div`
  display: inline-block;
  text-decoration: underline;
  color: #2d73f9;
  cursor: pointer;
`;

const ChangeAction = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
`;

const Loader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`;

const AuthLoader = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const AccountType = styled(CardName)`
  height: 100%;
  white-space: pre-wrap;
  font-size: 13px;
`;

const AddCardBlock = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  text-decoration: underline;
  margin-bottom: 5px;
  cursor: pointer;
`;

const AccountSubscribedCard = ({
  setUpdateMode = () => null,
  setUpdateCardMode = () => null,
  isAuthenticating = false
}) => {
  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const userPrice = useSelector(userPriceOptionSelector);
  const endDate = getSubscriptionEndDateString(accountEndTs);

  const plan = [SUBSCRIPTION_PLANS.MONTHLY].includes(accountPlan)
    ? i18n.t("accountSubscribed.plan1").replace("%s%", userPrice[accountPlan])
    : i18n.t("accountSubscribed.plan2").replace("%s%", userPrice[accountPlan]);

  const onUpdateCardAction = () => {
    setUpdateCardMode(true);
  };

  const onChangeAction = () => {
    setUpdateMode(true);
  };

  if (accountCardDetails.cardBrand) {
    return (
      <Container>
        <CardContainer onClick={onChangeAction}>
          <AccountType>{i18n.t(`accountSettings.accountType_${accountPlan}`)}</AccountType>
          <CardDetails>
            {!accountCardDetails.cardLast4 === false ? (
              <>
                <CardName>{accountCardDetails.cardBrand}</CardName>
                <CardInfo>
                  xxxx {accountCardDetails.cardLast4} • Exp {accountCardDetails.cardExpiryMonth}/
                  {accountCardDetails.cardExpiryYear}
                </CardInfo>
              </>
            ) : (
              <AddCardBlock onClick={onChangeAction}>Add Card</AddCardBlock>
            )}
            {accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ? (
              <CardPlan>
                {i18n.t("accountSubscribed.startsOn").replace("%s%", plan)}
                {endDate}
              </CardPlan>
            ) : accountSubscriptionStatus !== SUBSCRIPTION_STATUS.PAST_DUE ? (
              <CardPlan>
                {i18n.t("accountSubscribed.renewsOn").replace("%s%", plan)}
                {endDate}
              </CardPlan>
            ) : (
              <CardPlanDue>
                {i18n.t("accountSubscribed.cardError").replace("%s%", plan)}
                <br />
                {i18n.t("accountSubscribed.retryTomorrowMsg")}{" "}
                <UpdateLink onClick={onUpdateCardAction}>{i18n.t("accountSubscribed.updateCardLink")}</UpdateLink>
              </CardPlanDue>
            )}
          </CardDetails>
          {!accountCardDetails.cardLast4 === false && <ChangeAction onClick={onChangeAction}>CHANGE</ChangeAction>}
          {isAuthenticating && (
            <Loader>
              <AuthLoader />
            </Loader>
          )}
        </CardContainer>
        <NFTDiscountMessage isSubscribed accountPlan={accountPlan} />
      </Container>
    );
  }

  return null;
};

export default AccountSubscribedCard;
