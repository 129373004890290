import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";

import {
  userCountryCodeSelector,
  verifyPhone,
  verifyPhoneCode,
  setPreferredMFA,
  userSetMFA,
  userPhoneSet,
  fetchUser,
  MFA_TYPES,
  validatePhone,
  userPhoneSelector
} from "@kubera/common";
import { Dialog } from "components/dialog/DialogOverlay";
import PhoneInputComponent from "components/beneficiary/PhoneInputComponent";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import TextInput from "components/inputs/TextInput";

const ComponentDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 416px;
  min-height: 513px;
  margin-top: 80px;
  padding: 45px 48px 57px 50px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 3px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 23px;
`;

const FieldTitle = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  text-transform: uppercase;
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

const PhoneInputWrapper = styled.div`
  margin-bottom: ${props => (props.isPhoneSubmitted ? "30px" : "225px")};
`;

const VerifyInputWrapper = styled.div`
  margin-bottom: 117px;
`;

const BtnWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(PrimaryButton)`
  min-width: 134px;
  margin-right: 19px;
`;

const GoBackBtn = styled(SecondaryButton)`
  min-width: 134px;
`;

const VerifyCodeInp = styled(TextInput)`
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
`;

const ResendLink = styled.span`
  display: inline-block;
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
`;

const Private = styled.span``;

const SmsAuth = ({ onSmsVerifySuccess = () => null, goBack = () => null, userAttrs }) => {
  const userCountryCode = useSelector(userCountryCodeSelector);
  const userPhone = useSelector(userPhoneSelector);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState(userAttrs ? userAttrs.phone_number || userPhone : void 0);
  const [isPhoneSubmitted, setisPhoneSubmitted] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isResendLinkVisible, setIsResendLinkVisible] = useState(false);

  const handlePhoneChange = value => {
    setPhone(value);
    setErrorMsg(false);
  };

  const sendVerificationCode = async () => {
    setIsResendLinkVisible(false);
    const verifyMobile = await verifyPhone(phone).catch(err => {
      setErrorMsg(err.message);
    });

    if (verifyMobile) {
      setisPhoneSubmitted(true);
      setTimeout(() => {
        setIsResendLinkVisible(true);
      }, 10000);
    }

    return verifyMobile;
  };

  const handleSave = async e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    if (isPhoneSubmitted) {
      const verifyMobileCode = await verifyPhoneCode(verifyCode).catch(e => {
        setIsLoading(false);
        setVerifyCode("");
        setErrorMsg(e.message);
      });

      if (verifyMobileCode) {
        await dispatch(userPhoneSet(phone));
        await setPreferredMFA(MFA_TYPES.SMS);
        dispatch(userSetMFA(MFA_TYPES.SMS.toLowerCase()));
        dispatch(fetchUser(() => null, () => null));
        onSmsVerifySuccess();
      }
    } else if (userAttrs && userAttrs.phone_number_verified && userAttrs.phone_number === phone) {
      await dispatch(userPhoneSet(phone));
      const preferredMFA = await setPreferredMFA(MFA_TYPES.SMS);
      if (preferredMFA) {
        dispatch(userSetMFA(MFA_TYPES.SMS.toLowerCase()));
        dispatch(fetchUser(() => null, () => null));
        onSmsVerifySuccess();
      } else {
        await sendVerificationCode();
        setIsLoading(false);
      }
    } else {
      const { valid: isPhoneValid } = await dispatch(validatePhone(phone));

      if (isPhoneValid) {
        await sendVerificationCode();
      } else {
        setErrorMsg(i18n.t("wrongFormat"));
      }

      setIsLoading(false);
    }
  };

  const onGoBack = e => {
    e.preventDefault();

    goBack();
  };

  const onVerifyCodeChange = e => {
    setVerifyCode(e.target.value.replace(/\s/g, ""));
    setErrorMsg(false);
  };

  let phoneInputBorder = "solid 1px rgba(0, 0, 0, 0.4)";

  if (!isPhoneSubmitted && errorMsg) {
    phoneInputBorder = "solid 1px rgba(255, 0, 0, 0.4)";
  }

  // TODO: patch
  let phoneStr = phone;
  if (!phone) {
    phoneStr = "";
  }

  return (
    <ComponentDialog>
      <form onSubmit={handleSave}>
        <Title>{i18n.t("twoFA.smsAuthTitle")}</Title>
        <Desc>{i18n.t("twoFA.smsAuthDesc")}</Desc>
        <FieldTitle>{i18n.t("yourPhone")}</FieldTitle>
        <PhoneInputWrapper isPhoneSubmitted={isPhoneSubmitted}>
          <Private data-private>
            <PhoneInputComponent
              countryCode={userCountryCode.toLowerCase()}
              phone={phoneStr}
              height={"45px"}
              width={"318px"}
              handlePhoneChange={handlePhoneChange}
              border={phoneInputBorder}
              autofocus={true}
              disabled={isPhoneSubmitted}
            />
          </Private>
        </PhoneInputWrapper>
        {isPhoneSubmitted && (
          <>
            <FieldTitle>
              {i18n.t("twoFA.smsAuthVerifyCode")}{" "}
              {isResendLinkVisible && <ResendLink onClick={sendVerificationCode}>{i18n.t("resend")}</ResendLink>}
            </FieldTitle>
            <VerifyInputWrapper>
              <VerifyCodeInp
                value={verifyCode}
                onChange={onVerifyCodeChange}
                autoComplete="one-time-code"
                data-private
              />
            </VerifyInputWrapper>
          </>
        )}
        <BtnWrapper>
          <SaveButton id="save" data-cy="save" title={i18n.t("save")} onClick={() => null} isLoading={isLoading} />
          <GoBackBtn
            id="goBack"
            data-cy="goBack"
            title={i18n.t("goBackLink")}
            onClick={onGoBack}
            disabled={isLoading}
          />
        </BtnWrapper>
      </form>
    </ComponentDialog>
  );
};

export default SmsAuth;
