import createWhitelistFilter from "redux-persist-transform-filter";
import { SET_LONG_EQUITY_DATA, SAVE_PLUGIN_DATA, SET_PLUGIN_CURRENCY } from "../actions/Common";

const initialState = {
  currencyMap: {},
  longEquityDataMap: {},
  pluginDataMap: {}
};

export const pluginsPersistTransform = () => {
  return createWhitelistFilter("plugins", ["plugins", "longEquityDataMap", "pluginDataMap", "currencyMap"]);
};

export function pluginsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PLUGIN_CURRENCY: {
      const newState = { ...state };

      if (!action.currency) {
        return state;
      }

      newState.currencyMap = { ...newState.currencyMap };
      newState.currencyMap[`${action.portfolioId}${action.pluginId}`] = action.currency;

      return newState;
    }
    case SET_LONG_EQUITY_DATA: {
      const newState = { ...state };

      if (!action.longEquityData) {
        return state;
      }

      newState.longEquityDataMap = { ...newState.longEquityDataMap };
      newState.longEquityDataMap[`${action.portfolioId}${action.pluginId}`] = action.longEquityData;

      return newState;
    }
    case SAVE_PLUGIN_DATA: {
      const newState = { ...state };

      if (!action.pluginData) {
        return state;
      }

      newState.pluginDataMap = { ...newState.pluginDataMap };
      newState.pluginDataMap[`${action.portfolioId}${action.pluginId}`] = action.pluginData;

      return newState;
    }
    default:
      return state;
  }
}

export const longEquityDataSelector = state => {
  return state.plugins.longEquityDataMap;
};

export const pluginDataMapSelector = state => {
  return state.plugins.pluginDataMap;
};

export const pluginCurrencySelector = state => {
  return state.plugins.currencyMap;
};
