import React, { useState } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { ReactComponent as ClearIcon } from "assets/images/delete_user_icon.svg";
import { planningVariables } from "@kubera/common";
import PercentageInput from "./PercentageInput";
import CurrencyInput from "./CurrencyInput";
import DropDown from "./DropDown";
import PrimaryButton from "components/button/PrimaryButton";

const Container = styled.div`
  margin-top: ${props => props.marginTop};
`;

const EstimatedTaxLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  width: fit-content;
`;

const TaxTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputTitle = styled.div`
  margin-top: 35px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TaxTitle = styled(InputTitle)`
  margin-top: 0;
  flex: 1;
`;

const HitArea = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const ClearButton = styled(ClearIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const RateInput = styled(PercentageInput)`
  width: 100%;
  margin-top: ${props => (props.lessMargin ? "0px" : "8px")};
`;

const Description = styled.p`
  font-size: 11px;
  opacity: 0.5;
  margin-top: 14px;
`;

const AmountInput = styled(CurrencyInput)`
  margin-top: ${props => (props.lessMargin ? "0px" : "8px")};
`;

const TaxDeductionDropDown = styled(DropDown)`
  margin-top: 35px;
  background: transparent;
  padding: 0;
  border: 0;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 35px;
`;

const ExpandingTaxInput = ({
  tax,
  taxDeductionAmount = { value: null, tickerId: null },
  taxDeductionRate = { value: null },
  defaultVar = planningVariables.TAX_DEDUCTION_AMOUNT,
  deductionDescriptionNoun = "yourIncome",
  updateParams,
  portfolioCurrency,
  tickerId,
  onGainTitle,
  marginTop = "35px",
  dropdownWidth = 360,
  collapsedTitle = "estimatedTax",
  onSaveClick
}) => {
  const isAmountDefault = defaultVar === planningVariables.TAX_DEDUCTION_AMOUNT;
  const secondVar = isAmountDefault ? planningVariables.TAX_DEDUCTION_RATE : planningVariables.TAX_DEDUCTION_AMOUNT;
  const [primaryOption, secondaryOption] = isAmountDefault
    ? [taxDeductionAmount, taxDeductionRate]
    : [taxDeductionRate, taxDeductionAmount];

  const [expand, setExpand] = useState(!isNaN(tax) && tax > 0);
  const [dropdownValue, setDropdownValue] = useState(
    primaryOption.value === null && secondaryOption.value !== null ? secondVar : defaultVar
  );

  const handleClear = () => {
    setExpand(false);
    updateParams({
      [planningVariables.TAX]: 0,
      [planningVariables.TAX_DEDUCTION_AMOUNT]: { value: null, tickerId: null },
      [planningVariables.TAX_DEDUCTION_RATE]: { value: null }
    });
  };

  const handleDropdownSelection = item => {
    setDropdownValue(item.id);
  };

  const getDropdownData = () => [
    {
      id: planningVariables.TAX_DEDUCTION_AMOUNT,
      label: i18n.t("taxDeductionAmount"),
      selected: dropdownValue === planningVariables.TAX_DEDUCTION_AMOUNT
    },
    {
      id: planningVariables.TAX_DEDUCTION_RATE,
      label: i18n.t("taxDeductionRate"),
      selected: dropdownValue === planningVariables.TAX_DEDUCTION_RATE
    }
  ];
  return (
    tax !== undefined && (
      <Container marginTop={marginTop}>
        {!expand && <EstimatedTaxLabel onClick={() => setExpand(true)}>{i18n.t(collapsedTitle)}</EstimatedTaxLabel>}
        {expand && (
          <>
            <>
              <TaxTitleContainer>
                <TaxTitle>{onGainTitle ? i18n.t("taxRateOnGain") : i18n.t("taxRate")}</TaxTitle>
                <HitArea onClick={handleClear}>
                  <ClearButton />
                </HitArea>
              </TaxTitleContainer>
              <RateInput
                value={tax}
                onChange={(e, parsedInput) => updateParams({ [planningVariables.TAX]: parsedInput })}
                callOnChangeOnClear={true}
                inputValidation={value => value < 0 || value > 100}
              />
            </>
            <TaxDeductionDropDown
              width={dropdownWidth}
              selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
              items={getDropdownData()}
              onSelection={handleDropdownSelection}
            />
            {dropdownValue === planningVariables.TAX_DEDUCTION_AMOUNT ? (
              <AmountInput
                portfolioCurrency={portfolioCurrency}
                value={taxDeductionAmount.value}
                tickerId={tickerId}
                onChange={val =>
                  updateParams({
                    [planningVariables.TAX_DEDUCTION_AMOUNT]: val,
                    [planningVariables.TAX_DEDUCTION_RATE]: { value: null }
                  })
                }
                inputStyle={{ display: "flex", flex: 1, height: "45px", border: "1px solid rgba(0, 0, 0, 0.4);" }}
                hideExchangeRateBubble={true}
                isInSingleCellMode={true}
                isDisabled={!tax}
                lessMargin={true}
              />
            ) : (
              <RateInput
                value={taxDeductionRate.value}
                onChange={(e, parsedInput) =>
                  updateParams({
                    [planningVariables.TAX_DEDUCTION_RATE]: { value: parsedInput },
                    [planningVariables.TAX_DEDUCTION_AMOUNT]: { value: null, tickerId: null }
                  })
                }
                callOnChangeOnClear={true}
                inputValidation={value => value < 0 || value > 100}
                isDisabled={!tax}
                lessMargin={true}
              />
            )}
            <Description
              dangerouslySetInnerHTML={{
                __html: i18n
                  .t(
                    dropdownValue === planningVariables.TAX_DEDUCTION_AMOUNT
                      ? "taxDeductionAmountDescription"
                      : "taxDeductionRateDescription"
                  )
                  .replaceAll("{noun}", i18n.t(deductionDescriptionNoun))
              }}
            />
            {!!onSaveClick && <SaveButton title={i18n.t("save")} onClick={onSaveClick} />}
          </>
        )}
      </Container>
    )
  );
};

export default ExpandingTaxInput;
