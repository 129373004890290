import { store } from "../../store";
import { toastSelector } from "../reducers/ToastReducer";
import { isMobile } from "../../utilities/Location";
import { setPageReloadingAction, SHOW_TOAST, DISMISS_TOAST, toastType } from "./Common";
import { getClientInfo, setShowBreakingChangesError } from "./AuthActions";
import { isAppVersionLesserThan } from "../../utilities/Number";

export class Toast {
  constructor(type, message, duration = 10000, onPositiveAction, onNegativeAction) {
    this.id = Math.random();
    this.type = type;
    this.message = message;
    this.duration = duration;
    this.onPositiveAction = onPositiveAction;
    this.onNegativeAction = onNegativeAction;
    this.dismissOnOutsideClick = true;
    this.actionButtonTitle = null;
    this.dismissOnActionButtonClick = true;
    this.showDismissButton = true;
    this.dismissOnNavigationToCategory = null;
  }
}

export const showToastAction = toast => ({
  type: SHOW_TOAST,
  toast
});

export const dismissToastAction = toast => ({
  type: DISMISS_TOAST
});

export const showToastTip = (
  type,
  message,
  didShow = null,
  tipDuration = -1,
  actionButtonTitle = null,
  onPositiveAction = null,
  onNegativeAction = null,
  removeCurrentToast = false,
  dismissOnActionButtonClick = false,
  dismissOnOutsideClick = false
) => {
  return dispatch => {
    const currentToast = toastSelector(store.getState());
    if (currentToast && removeCurrentToast === false) {
      if (didShow) {
        didShow(false);
      }
      return;
    } else if (currentToast && removeCurrentToast === true) {
      dispatch(dismissToastAction());
    }

    const toast = new Toast(toastType[type], message, tipDuration, onPositiveAction, onNegativeAction);
    toast.actionButtonTitle = actionButtonTitle;
    toast.dismissOnActionButtonClick = dismissOnActionButtonClick;
    toast.dismissOnOutsideClick = dismissOnOutsideClick;
    dispatch(showToastAction(toast));
    if (didShow) {
      didShow(true);
    }
  };
};

const showAppUpdateToast = () => {
  return dispatch => {
    const toast = new Toast(
      toastType.TIP,
      isMobile() ? "New Version Available." : "Get latest improvements.",
      -1,
      () => {
        if (isMobile()) {
          dispatch(setPageReloadingAction(true));
        }
        setTimeout(() => {
          window.location.reload();
        }, 100);
      },
      null
    );
    toast.actionButtonTitle = isMobile() ? "Update" : "Refresh";
    toast.showDismissButton = isMobile();
    toast.dismissOnOutsideClick = false;
    dispatch(showToastAction(toast));
  };
};

export const showAppUpdateToastIfNeeded = () => {
  return dispatch => {
    dispatch(
      getClientInfo(apiData => {
        if (isAppVersionLesserThan(apiData.lastBreakingVersion) === true) {
          dispatch(setShowBreakingChangesError());
        } else if (isAppVersionLesserThan(apiData.version) === true) {
          dispatch(showAppUpdateToast());
        }
      }, null)
    );
  };
};
