import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import TextInput from "components/inputs/TextInput";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";

const RenameDocumentDialog = styled(Dialog)`
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 163px;
`;

const NameInput = styled(TextInput)`
  height: 61px;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: transparent;
  margin: 54px 54px 29px 54px;
  border: 0;
  outline: 0;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const SaveButton = styled.div`
  width: 134px;
  height: 44px;
  background-color: #000000;
  margin-left: 54px;
  margin-bottom: 42px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

class RenameDocumentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: props.name };

    this.handleInputchange = this.handleInputchange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleInputchange = e => {
    this.setState({ ...this.state, name: e.target.value });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveButtonClick();
    }
  };

  handleSaveButtonClick = () => {
    const name = this.state.name;
    if (name === undefined || name.length === 0) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(name);
    }
    this.dismiss();
  };

  handleOverlayDismiss = () => {
    this.dismiss();
  };

  dismiss = () => {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  render() {
    const name = this.state.name;

    const { isFolder } = this.props;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <RenameDocumentDialog>
          <NameInput
            data-cy="newNameInput"
            placeholder={i18n.t(!isFolder ? "documentName.namePlaceholder" : "documentName.folderPlaceholder")}
            value={name}
            onChange={this.handleInputchange}
            onKeyDown={this.handleKeyDown}
            autoFocus
          />
          <SaveButton data-cy="newNameSave" onClick={this.handleSaveButtonClick}>
            {i18n.t("rename")}
          </SaveButton>
        </RenameDocumentDialog>
      </DialogOverlay>
    );
  }
}

export default RenameDocumentComponent;
