import React, { Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { modalValues, hashParams } from "routes";
import { getHashParams, useHistory } from "@kubera/common";

import MobileCustodianDetailsComponent from "components/custodian_details/MobileCustodianDetailsComponent";
import { category } from "components/dashboard/DashboardComponentExports";

import MobilePageComponent from "./MobilePageComponent";

const MobileWrapperComponent = ({ portfolio, direction, isRefreshing = false, pageCategory = category.ASSET }) => {
  const history = useHistory();
  const location = useLocation();

  const prevLocation = useRef(location);

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.MODAL];
  const rowId = urlHashParams[hashParams.ID];
  const currentSheetId = urlHashParams[hashParams.SHEET_ID];

  const onSheetChange = sheetId => {
    history.replace({ ...location, hash: `${hashParams.SHEET_ID}=${sheetId}` });
  };
  const pageProps = { currentSheetId, portfolio, onSheetChange, direction, isRefreshing, pageCategory };

  useEffect(() => {
    const urlHashParams = getHashParams(prevLocation.current);
    const prevSheetId = urlHashParams[hashParams.SHEET_ID];

    if (!currentSheetId && prevSheetId) {
      const existingHash = location.hash ? `${location.hash}&` : "";
      history.replace({
        ...location,
        hash: `${existingHash}${hashParams.SHEET_ID}=${prevSheetId}`
      });
    }

    prevLocation.current = location;
  }, [currentSheetId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {modalValue === modalValues.CUSTODIAN_DETAILS && (
        <MobileCustodianDetailsComponent
          portfolio={portfolio}
          custodianId={rowId}
          category={pageCategory}
          direction={direction}
          isRefreshing={isRefreshing}
        />
      )}
      <MobilePageComponent {...pageProps} />
    </Fragment>
  );
};

export default React.memo(MobileWrapperComponent);
